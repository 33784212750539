import { CARRITO_ACTION_TYPES } from "./carritoTypes";

export const CARRITO_INITIAL_STATE = {
  carrito: [],
  isLoading: true,
  error: null,
};

export const carritoReducer = (state = CARRITO_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CARRITO_ACTION_TYPES.FETCH_CARRITO_START:
      return { ...state, isLoading: payload };
    case CARRITO_ACTION_TYPES.FETCH_CARRITO_SUCCESS:
      return { ...state, carrito: payload, isLoading: false };
    case CARRITO_ACTION_TYPES.FETCH_CARRITO_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
