import React from "react";
import ProductoImage from "../../../../assets/img/producto.png";
import PaqueteImage from "../../../../assets/img/paquetesMarketplace.png";

const ProductosPaqueteRow = ({ nombre, cantidad, estatus, imagen, carrusel, esPaquete }) => {
  return (
    <tr className={`text-left select-none h-16 border-gray-300 ${estatus ? "opacity-100" : "opacity-40"}`}>
      <td>
        <div className="flex gap-3 items-center">
          <img
            className="h-12 w-12 object-contain ml-4"
            src={imagen === "" ? (carrusel.length > 0 ? carrusel[0] : (esPaquete ? PaqueteImage : ProductoImage)) : imagen}
            alt=""
          />
          <p className="ml-4">{nombre}</p>
        </div>
      </td>
      <td className="">
        <p className="ml-4 text-right">x{cantidad}</p>
      </td>
    </tr>
  );
};

export default ProductosPaqueteRow;
