import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faShoppingCart,faDollarSign} from "@fortawesome/free-solid-svg-icons";
import "./Style.css";

import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { contador,eliminarfav,set_tipo} from "../../redux/Actions/ChangeAction";
import { deleteFavorite } from "../../../services/api";
import { UserContext } from '../../../services/user';


const CardsG = ({id, item, tipo, especifico, SuperTipo = "", CardType }) => {
  const dispatch=useDispatch();
  const navigate = useNavigate();

  let result, picture, comprobar, precio, ancho, arreglo, type, band, cardType;


  if (tipo === "") {
    if (item.logo !== undefined) {
      type = "Academias";
    } else {
      type = "Cursos";
    }
  } else {
    type = tipo;
  }
  
  if (item.descuento > 0) {
    precio = item?.costo - item?.costo * (0.01 * item?.descuento);
    comprobar = true;
  } else {
    precio = item.costo;
    comprobar = false;
  }
  if (item.tipo === "Cursos") {
    picture = item.imagen;
  } else if(item.tipo === 'RutasAprendizaje') {
    picture = item.logo;
  }
  else if(item.tipo === 'Items'){
    picture = item.logo;
  }


  switch (CardType) {
    case "Favoritos":
      cardType = "Favoritos";
      break;
    case "Compadros":
      cardType = "Comprados";
      break;
    default:
      console.log("Algo ha pasado");
      break;
  }


  let cont = 0;
  const HandleClick = (cadena, word) => {
    if (cont === 0) {

      if (cadena === "Especifico") {
        let tipo = ''
        switch (item.tipo) {
          case 'Cursos':
            tipo = 'Curso'
            break;
          case 'RutasAprendizaje':
            tipo = 'Academia'
            break;
          case 'Items':
            tipo = 'Item'
            break;
        
          default:
            break;
        }
        navigate(`/MarketPlace/DetallesProductoSpecific/${item._id}/${tipo}/${word}`);
        cont++;
      } else if (cadena === "Delete") {
        console.log("vamos a eliminar ",id);
        dispatch(contador(1));
        dispatch(set_tipo(true));
        dispatch(eliminarfav(id));
        deleteFavorite(id);
        cont++;
      } else {

        navigate(`/MarketPlace/DetallesProducto/:${item._id}/:${type}/:${SuperTipo}/:${true}`);
      }
    } else {
      cont = 0;
    }
  };
  return (
    <div
      className={cardType === "Favoritos" ? "tarjetas__fav" : "tarjetas"}

    >
      <div className="user__img">
        <img src={picture} alt="Imagen MarketPlace" className="user_photo2" />
        {CardType === "Favoritos" ? (
          <div className="boton_oculto">
            <button
              onClick={() => {
                HandleClick("Especifico", "carro");
              }}
            >
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="tabla____button__design__icon"
              ></FontAwesomeIcon>
            </button>
            <button
              onClick={() => {
                HandleClick("Especifico", "ahora");
              }}
            >
              <FontAwesomeIcon
                icon={faDollarSign}
                className="tabla____button__design__icon"
              ></FontAwesomeIcon>
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="user__txt">
        {band ? (
          <div className="bg-slate-400 p-2">
            <p
              className="font-sans font-semibold text-base "
              style={{ width: "48px", marginBottom: "2px" }}
            >
              {type}
            </p>
            <p className="font-sans font-semibold text-base " style={{ width: "120px" }}>
              {result}
            </p>
          </div>
        ) : (
          <div className="flex flex-row gap-1 items-center ">
            <div className="w-auto h-auto bg-[#838383] text-white p-1">
                <p className="font-sans font-semibold text-sm align-text-bottom"> 
                  {item.tipo === 'RutasAprendizaje' ? 'Rutas' : item.tipo === 'Items' ? 'Item' : 'Cursos'}
                </p>
            </div>
            {item.tipo !== 'Items' &&
            <div className="w-auto h-auto bg-[#838383] text-white p-1">
                <p className="font-sans truncate w-20 font-semibold text-sm align-middle">
                {item.tipo === 'Cursos' ?
                item?.categoria?.nombre
                :(item.tipo === 'RutasAprendizaje') ?
                item?.rutaAprendizajeCategoria?.nombre
                : null
                }
                </p>
            </div>
            }
        </div>
        )}
        <div className="flex items-center justify-center font-sans font-semibold text-base uppercase text-black">
            {item.nombre}
        </div>
        {comprobar ? (
          <div className="contedor__btn__oferta w-5/6">
          <div>
          <h3 className="font-semibold text-xl" style={{ color: "#FF0000",  }}>
            ${precio}
          </h3>
          </div>
          <div>
          <h3 className="font-semibold text-sm line-through text-[#838383]">
            ${item.costo}
          </h3>
          </div>
          
        </div>
        ) : (
          <h3 className="font-sans text-lg text-center font-semibold">${precio}</h3>
        )}
        <div className="contedor__btn__oferta">
          {comprobar ? (
            <div className="flex flex-row gap-x-1">
            <div className="font-sans text-xs text-center font-semibold bg-[#FF0000] text-white p-1 cursor-default">¡Oferta!</div>
            <button
              // className="font-sans text-xs text-center font-semibold"
              className="font-sans text-xs font-semibold bg-secundary text-gray-500 p-1"
              onClick={() => {
                HandleClick("Especifico", "carro");
              }}
            >
              Añadir al carrito
            </button>
          </div>
          ) : (
            <div className="flex justify-center w-full">
              <button
                className="font-sans text-xs font-semibold bg-nimbusDark text-white p-1"
                onClick={() => {
                  HandleClick("Especifico", "carro");
                }}
              >
                Añadir al carrito
              </button>
            </div>
          )}
        </div>
      </div>
      {CardType === "Favoritos" && (
        <>
          <div className=" flex justify-end w-full"></div>
          <div className=" flex justify-end w-full">
            <span
              title="Eliminar"
              onClick={() => HandleClick("Delete", item?._id)}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-nimbus col-span-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default CardsG;
