import React, { useMemo } from "react";
import { ReactComponent as MisComprasCircleIcon } from "../../../../../assets/icons/misComprasCircleIcon.svg";
import { ReactComponent as View } from "../../../../../assets/icons/view.svg";
import { formatCurrency } from "../../../../utils/productoUtils";
import { useNavigate } from "react-router-dom";
import { RUTAS_MAP } from "../../../../utils/tiendaUtils";

const PaquetesAsignadosRow = ({ articuloInfo, cambiarEstatusArticuloAsignado }) => {
  const { _id, asignacionarticulogrupo, grupo, articuloadquirido, esAdquirido } = articuloInfo;

  const articulo = useMemo(() => {
    return Object.keys(asignacionarticulogrupo.paquete).length === 0
      ? asignacionarticulogrupo.producto
      : asignacionarticulogrupo.paquete;
  }, [asignacionarticulogrupo]);

  const tipoArticulo = useMemo(() => {
    return articulo.paquete ? "paquete" : "producto";
  }, [articulo]);

  const categoria = useMemo(() => {
    if (articulo.paquete) {
      return "Paquetes";
    }

    return articulo?.producto?.categoria === "otro"
      ? "Otro"
      : Object.entries(RUTAS_MAP).find((categoria) => categoria[1] === articulo?.producto?.categoria)[0];
  }, [articulo]);

  const navigate = useNavigate();

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-lg border-r-0 font-sans text-center">
        <input
          type="checkbox"
          name={_id}
          id={_id}
          className="h-6 w-6 text-[#2563EB] border-gray-300 rounded-md"
          checked={esAdquirido}
          onChange={() => cambiarEstatusArticuloAsignado(asignacionarticulogrupo._id)}
        />
      </td>
      <td className="border-y-2 text-left font-sans">
        <MisComprasCircleIcon className="mx-auto" />
      </td>
      <td className="border-y-2 font-sans text-left">
        {articulo.producto ? articulo.producto.nombre : articulo.paquete.nombre}
      </td>
      <td className="border-y-2 font-sans text-left">{grupo.nombre}</td>
      <td className="border-y-2 font-sans text-left">
        {articuloadquirido ? (articuloadquirido.esAdquirido ? "Adquirido" : "Sin adquirir") : "Sin adquirir"}
      </td>
      <td className="border-y-2 font-sans text-right">{formatCurrency(articulo.precioVenta)}</td>
      <td className="border-2 rounded-r-md border-l-0 min-h-[102px] md:min-h-[50px] flex justify-around items-center">
        <View
          className="cursor-pointer"
          title="Ver artículo en la tienda"
          onClick={() => navigate(`/marketplace/tienda/${categoria}/${tipoArticulo}/${articulo._id}`)}
        />
      </td>
    </tr>
  );
};

export default PaquetesAsignadosRow;
