import React, { useCallback, useEffect, useState } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import { LISTA_PRECIOS, TIPOS_ESTATUS } from "../../../utils/productoUtils";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import { getNivelAcademico, getNivelesAcademico } from "../../../services/api";
import { useSelector } from "react-redux";
import { selectBuscar } from "../../../../reducers/marketplace/marketplaceSelector";

const FILTRO_INITIAL_VALUES = {
  busqueda: "",
  precio: "",
  estatus: "",
  nivelEducativo: "",
  gradoAcademico: "",
};

const ProductosFilter = ({ productos, productosFiltro, setProductosFiltro }) => {
  const [nivelesEducativos, setNivelesEducativos] = useState([]);
  const [gradosAcademicos, setGradosAcademicos] = useState([]);
  const [filtro, setFiltro] = useState(FILTRO_INITIAL_VALUES);
  const buscar = useSelector(selectBuscar);

  const getData = useCallback(async () => {
    const nivelesAcademicosResponse = await getNivelesAcademico();
    setNivelesEducativos(nivelesAcademicosResponse.data);
  }, []);

  const filterDataBusqueda = (productos, busqueda, buscar) => {
    if (busqueda === "" && buscar === "") return productos;
    let newProductosFiltro = productos;
    if (busqueda === "") {
      newProductosFiltro = productos.filter(
        (producto) =>
          producto?.producto?.nombre.toLowerCase().includes(buscar.toLowerCase()) ||
          producto?.paquete?.nombre.toLowerCase().includes(buscar.toLowerCase())
      );
    } else if (buscar === "") {
      newProductosFiltro = productos.filter(
        (producto) =>
          producto?.producto?.nombre.toLowerCase().includes(busqueda) ||
          producto?.paquete?.nombre.toLowerCase().includes(busqueda)
      );
    } else {
      newProductosFiltro = productos.filter(
        (producto) =>
          producto?.producto?.nombre.toLowerCase().includes(busqueda) ||
          producto?.paquete?.nombre.toLowerCase().includes(busqueda) ||
          producto?.producto?.nombre.toLowerCase().includes(buscar.toLowerCase()) ||
          producto?.paquete?.nombre.toLowerCase().includes(buscar.toLowerCase())
      );
    }
    return newProductosFiltro;
  };

  const filterSelectPrecio = (productos, precio) => {
    if (precio === "") return productos;

    switch (precio) {
      case "Menos de 500.00":
        return productos.filter((producto) => producto.precioVenta < 500);
      case "500.00 - 1,000.00":
        return productos.filter((producto) => producto.precioVenta >= 5000 && producto.precioVenta <= 1000);
      case "1,000.00 - 2,000.00":
        return productos.filter((producto) => producto.precioVenta >= 1000 && producto.precioVenta <= 2000);
      case "Más de 2,000.00":
        return productos.filter((producto) => producto.precioVenta > 2000);
      default:
        return productos;
    }
  };

  const filterSelectEstatus = (productos, estatus) => {
    if (estatus === "") return productos;
    estatus = estatus === "true";
    const newProductosFiltro = productos.filter(
      (producto) => producto?.producto?.estatus === estatus || producto?.paquete?.estatus === estatus
    );

    return newProductosFiltro;
  };

  const filterSelectNivelEducativo = (productos, nivelEducativo) => {
    if (nivelEducativo === "") return productos;
    const newProductosFiltro = productos.filter(
      (producto) =>
        producto?.producto?.nivelAcademico._id === nivelEducativo ||
        producto?.paquete?.nivelAcademico._id === nivelEducativo
    );

    return newProductosFiltro;
  };

  const filterSelectGradoAcademico = (productos, gradoAcademico) => {
    if (gradoAcademico === "") return productos;
    const newProductosFiltro = productos.filter(
      (producto) =>
        producto?.producto?.gradoAcademico._id === gradoAcademico ||
        producto?.paquete?.gradoAcademico._id === gradoAcademico
    );

    return newProductosFiltro;
  };

  useEffect(() => {
    let newProductosFiltro = productos;
    newProductosFiltro = filterDataBusqueda(newProductosFiltro, filtro.busqueda.toLowerCase(), buscar);
    newProductosFiltro = filterSelectPrecio(newProductosFiltro, filtro.precio);
    newProductosFiltro = filterSelectEstatus(newProductosFiltro, filtro.estatus);
    newProductosFiltro = filterSelectNivelEducativo(newProductosFiltro, filtro.nivelEducativo);
    newProductosFiltro = filterSelectGradoAcademico(newProductosFiltro, filtro.gradoAcademico);
    setProductosFiltro(newProductosFiltro);
  }, [filtro, productos, buscar]);

  useEffect(() => {
    if (filtro.nivelEducativo !== "") {
      getNivelAcademico(filtro.nivelEducativo).then((valores) => {
        setGradosAcademicos(valores.data.gradosAcademicos);
      });
    }else{
      setGradosAcademicos([])
    }
    setFiltro({ ...filtro, gradoAcademico: "" });
  }, [filtro.nivelEducativo]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <div className="h-16 bg-gray-200 mb-8 flex items-center justify-center">
        <h3 className="text-xl">Filtros</h3>
      </div>
      <ul className="space-y-8 text-sm font-medium text-gray-900 h-full px-6">
        <li>
          <InputBuscar
            value={filtro.busqueda}
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
          />
        </li>
        <li>
          <InputSelect
            label={"Precio"}
            topLabel
            options={LISTA_PRECIOS}
            styles="!w-full"
            value={filtro.precio}
            onChange={(e) => setFiltro({ ...filtro, precio: e.target.value })}
          />
        </li>
        <li>
          <InputSelect
            label={"Estatus"}
            topLabel
            options={TIPOS_ESTATUS}
            styles="!w-full"
            value={filtro.estatus}
            onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
          />
        </li>
        <li>
          <InputSelect
            label={"Nivel educativo"}
            topLabel
            options={nivelesEducativos}
            styles="!w-full"
            value={filtro.nivelEducativo}
            onChange={(e) => setFiltro({ ...filtro, nivelEducativo: e.target.value })}
          />
        </li>
        <li>
          <InputSelect
            label={"Grado académico"}
            topLabel
            options={gradosAcademicos}
            styles="!w-full"
            value={filtro.gradoAcademico}
            onChange={(e) => setFiltro({ ...filtro, gradoAcademico: e.target.value })}
          />
        </li>
      </ul>
    </div>
  );
};

export default ProductosFilter;
