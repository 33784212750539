import React from 'react';
import EditarIcon from '../../../../assets/icons/Edit.svg';
import VisualizarIcon from '../../../../assets/icons/viewIcon.svg';
import { ReactComponent as DirectorIcon } from '../../../../assets/icons/directorList.svg';
import { useNavigate } from 'react-router-dom';

const FormadorRow = ({ formador }) => {
  const navigate = useNavigate();
  const { _id, nombre, primerApellido, segundoApellido, correo, telefono, esActivo } = formador;
  
  return (
    <tr className='p-2 text-left bg-white'>
      <td className='border-2 rounded-l-lg border-r-0 text-left font-sans'>
        <DirectorIcon className='mx-auto' />
      </td>
      <td className='border-y-2 font-sans text-left'>{nombre} {primerApellido} {segundoApellido}</td>
      <td className='border-y-2 font-sans text-center'>{correo}</td>
      <td className='border-y-2 font-sans text-center'>{telefono}</td>
      <td className={`border-y-2 font-sans text-center ${esActivo ? 'text-green-500' : 'text-red-500'}`}>{esActivo ? 'Activo' : 'Inactivo'}</td>
      <td className='border-2 rounded-r-md border-l-0 text-center font-sans flex justify-around min-h-[48px]'>
        <button
          title='Editar autor'
          className='w-5'
          onClick={() => navigate(`/herramientas/autores/${_id}/editar`)}
        >
          <img src={EditarIcon} className='w-5 h-5' />
        </button>
        <button
          title='Ver autor'
          className='w-5'
          onClick={() => navigate(`/herramientas/autores/${_id}/ver`)}
        >
          <img src={VisualizarIcon} className='w-5 h-5' />
        </button>
      </td>
    </tr>
  );
};

export default FormadorRow;
