import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../../fragments/SubHeader";

const SubHeaderReporteAvisosDetalles = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className="flex align-middle justify-between items-center">
      <SubHeader styles="!bg-backgroundColor flex-col sm:flex-col">
        <BreadCrumbContainer>
          <BreadCrumb
            route={"Reporte de avisos /"}
            onClick={() => navigate("/herramientas/reporte-avisos")}
          />
          <BreadCrumb route={"Detalle del reporte"} active />
        </BreadCrumbContainer>

        <div className="w-full">
          <span className="flex text-base md:text-lg text-transparent bg-clip-text bg-gradient-to-r from-[#18BAFB] to-[#3D90E1] items-center">
            {title}
          </span>
        </div>
      </SubHeader>
    </div>
  );
};

export default SubHeaderReporteAvisosDetalles;
