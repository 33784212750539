import React, { Fragment, useContext, useEffect, useState } from 'react';
import SubHeaderPaises from './fragments/SubHeaderPaises';
import PaisesFilter from './fragments/PaisesFilter';
import PaisesTable from './fragments/PaisesTable';
import TablePagination from '../../fragments/TablePagination';
import { MESSAGES } from '../../utils/messagesUtils';
import NoDataMessage from '../../fragments/NoDataMessage';
import { usePagination } from '../../hooks/usePagination';
import { UserContext } from '../../services/user';
import { getPaises } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import withUserAccesability from '../../hoc/withUserAccesability';
import { PaisesContext } from '../../../contexts/PaisesContext';
import LoadingScreen from '../../fragments/LoadingScreen';

const PaisesHome = ({ isUserAllowed }) => {
  const { activeRole } = useContext(UserContext);
  const { paises, obtenerPaises } = useContext(PaisesContext);
  const navigate = useNavigate();
  const [paisesFiltro, setPaisesFiltro] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isUserAllowed) {
      navigate('/NotFound');
    }
  }, [activeRole]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    paisesFiltro,
    10
  );

  const obtenerInfoPaises = async () => {
    try {
      await obtenerPaises();
      setPaisesFiltro(paises);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(activeRole !== ""){
      obtenerInfoPaises();
    }
  },[activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen /> 
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderPaises agregarPais={activeRole === "ADMIN" ? true : false}/>
          <div
            className={`${
              paisesFiltro.length !== 0 && 'justify-between'
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <PaisesFilter
                paisesList={paises}
                paisesFiltro={paisesFiltro}
                setPaisesFiltro={setPaisesFiltro}
              />
              <PaisesTable paisesList={currentData()} />
            </div>
            <div>
              {paisesFiltro.length === 0 ? (
                <NoDataMessage message={activeRole === "ADMIN" ? MESSAGES.MESSAGES_PAISES_TABLE_ADMIN : MESSAGES.MESSAGES_PAISES_TABLE} />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )
      }
    </Fragment>
  );
};

export default withUserAccesability(PaisesHome);
