import { _get, _post, _put } from '../../../http';

export const getPedidosPorEstructura = (tipoEstructura, estructuraId) =>
  _get(`/market-place/pedidos/${tipoEstructura}/${estructuraId}`);

export const getPedidoDetalles = (pedidoId) =>
  _get(`/market-place/pedidos/${pedidoId}`);

export const putCompletarPedido = (pedidoId) =>
  _put(`market-place/pedidos/${pedidoId}/marcarCompletado`);

export const putCompraEntregada = (compraId) =>
  _put(`market-place/compras/${compraId}/marcarEntregado`);

export const putReabrirCompra = (compraId) =>
  _put(`market-place/compras/${compraId}/reabrirCompra`);

export const getPedidosPorCompra = (compraId) =>
  _get(`market-place/compras/${compraId}/obtenerPedidosPorIdCompra`);

export const getComprasPorEstructura = (tipoEstructura, estructuraId) =>
  _get(`/market-place/compras/${tipoEstructura}/${estructuraId}`);

export const putCompletarCompra = (compraId) =>
  _put(`/market-place/compras/${compraId}/marcarCompletado`);
