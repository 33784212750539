import { _delete, _get, _post, _put } from "../../http";

export const getGrupos = (idEscuela, idCampus) => _get(`/escuelas/${idEscuela}/campus/${idCampus}/grupos`);

export const getGruposPlantel = (escuelaId, campusId) =>
  _get(`/escuelas/${escuelaId}/campus/${campusId}/grupos/gruposCampus`);

export const getGruposPlantelPaqueteEscolar = (escuelaId, campusId) =>
  _get(`/escuelas/${escuelaId}/campus/${campusId}/obtenerSoloGruposInfo`);

export const getGruposReportes = (escuelaId, campusId) =>
  _get(`/escuelas/${escuelaId}/campus/${campusId}/grupos/gruposReportes`);

export const getGrupoDetalle = (idEscuela, idCampus, idGrupo) =>
  _get(`/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}`);

export const getGrupoPlantelDetalle = (escuelaId, campusId, grupoId) =>
  _get(`/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/grupoSeleccionado`);

export const getGrupoPlantelReportes = (escuelaId, campusId, grupoId) =>
  _get(`/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/reportes`);

export const getAlumnosGrupoPlantelDetalle = (escuelaId, campusId, grupoId) =>
  _get(`/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/alumnosSeleccionado`);

export const postGrupo = (idEscuela, idCampus, idGrupo, body) =>
  _post(`/escuela/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}`, body);

export const putGrupo = (idEscuela, idCampus, idGrupo, body) =>
  _put(`/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}`, body);

export const putAsignaturaGrupoPlantel = (escuelaId, campusId, grupoId, asignaturaId, body) =>
  _put(`/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/asignaturas/${asignaturaId}/actualizarAsignatura`, body);

export const deleteGrupo = (idEscuela, idCampus, idGrupo) =>
  _delete(`/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}`);
