import { deleteListaDifusion } from "../../../services/Api/ListasDifusion";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const DeleteLista = ({ oCloseModal, id, getListasDifusion }) => {
  const onDeleteList = async () => {
    try {
      await deleteListaDifusion(id);
      getListasDifusion();
      toast.success('Se eliminó correctamente la lista de difusión', alertConfiguration);
      oCloseModal();
    }
    catch(error){
      console.log(error)
      toast.error('Ocurrió un error al eliminar la lista de difusión', alertConfiguration);
    }
  };

  return (
    <div className="grid text-center gap-y-4">
      <h1 className="text-[#1A3D82] font-semibold text-2xl px-20 text-wrap max-w-2xl mb-3">
        ¿Está seguro que desea eliminar la lista de difusión de forma
        definitiva?
      </h1>
      <div className="flex justify-center gap-x-4">
        <button
          className="flex items-center justify-center bg-nimbusDark w-32 h-10 rounded-lg text-white font-semibold cursor-pointer mr-5"
          onClick={onDeleteList}
        >
          <span>Confirmar</span>
        </button>

        <button
          className="flex items-center justify-center bg-gray-200 w-32 h-10 rounded-lg text-nimbusDark font-semibold cursor-pointer mr-5"
          onClick={oCloseModal}
        >
          <span>Cancelar</span>
        </button>
      </div>
    </div>
  );
};

export default DeleteLista;
