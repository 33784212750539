import React, { useContext } from "react";
import Tarjeta from "../../../../assets/img/visa-mastercard-american-express.png";
import Oxxo from "../../../../assets/img/oxxoLogo.png";
import Spei from "../../../../assets/img/spei.png";
import TarjetaPago from "./fragments/TarjetaPago";
import OxxoPago from "./fragments/OxxoPago";
import TransferenciaPago from "./fragments/TransferenciaPago";
import OxxoPagoExitoso from "./fragments/OxxoPagoExitoso";
import TarjetaPagoExitoso from "./fragments/TarjetaPagoExitoso";
import { TransferenciaPagoExitoso } from "./fragments/TransferenciaPagoExitoso";
import { useSelector } from "react-redux";
import { selectResumenCompraPrecioTotal } from "../../../../reducers/carrito/carritoSelector";
import { UserContext } from "../../../services/user";

const MEXICO_ID = "6213a5815e1e2a212c63b6fc"

const MetodoDePago = ({ estatus, metodoPago, setMetodoPago, datosEnvio }) => {
  const precioTotal = useSelector(selectResumenCompraPrecioTotal);
  const { user } = useContext(UserContext);

  const handleSeleccionarMetodoPago = (e) => {
    let metodoDePago = e.target.value;
    setMetodoPago(metodoDePago);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="h-12 bg-gray-100 px-4 flex items-center">
        <p className="text-gray-500 font-bold">
          {estatus === "Compra exitosa" ? "Método de pago" : " Selecciona el método de pago"}
        </p>
      </div>
      {estatus === "Pago" ? (
        <div className="flex flex-col px-4 pt-4 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className="border-2 border-gray-300 cursor-pointer"
                value={"TARJETA_CREDITO_DEBITO"}
                checked={metodoPago === "TARJETA_CREDITO_DEBITO"}
                onChange={handleSeleccionarMetodoPago}
              />
              <label>Tarjeta de crédito / Tarjeta de débito</label>
            </div>
            <img className="h-5 md:h-8" src={Tarjeta} alt="" />
          </div>
          {metodoPago === "TARJETA_CREDITO_DEBITO" && <TarjetaPago datosEnvio={datosEnvio} />}
          {/* UNICAMENTE MOSTRAR LA OPCION DE OXXO SI EL PAIS ES MEXICO */}
          {precioTotal <= 10000 && user.escuela.campus.dirFiscalPais._id === MEXICO_ID && (
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  className="border-2 border-gray-300 cursor-pointer"
                  value={"EFECTIVO"}
                  checked={metodoPago === "EFECTIVO"}
                  onChange={handleSeleccionarMetodoPago}
                />
                <label>Pago en efectivo Oxxo</label>
              </div>
              <img className="h-5 md:h-8" src={Oxxo} alt="" />
            </div>
          )}
          {metodoPago === "EFECTIVO" && <OxxoPago datosEnvio={datosEnvio} />}
          {user.escuela.campus.dirFiscalPais._id === MEXICO_ID && (
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  className="border-2 border-gray-300 cursor-pointer"
                  value={"TRANSFERENCIA"}
                  checked={metodoPago === "TRANSFERENCIA"}
                  onChange={handleSeleccionarMetodoPago}
                />
                <label>Transferencia interbancaria</label>
              </div>
              <img className="h-5 md:h-8" src={Spei} alt="" />
            </div>
          )}
          {metodoPago === "TRANSFERENCIA" && <TransferenciaPago datosEnvio={datosEnvio} />}
        </div>
      ) : (
        <>
          {metodoPago === "TARJETA_CREDITO_DEBITO" ? (
            <TarjetaPagoExitoso />
          ) : metodoPago === "EFECTIVO" ? (
            <OxxoPagoExitoso />
          ) : (
            <TransferenciaPagoExitoso />
          )}
        </>
      )}
    </div>
  );
};

export default MetodoDePago;
