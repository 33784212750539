import React from "react";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputText from "../../../fragments/Inputs/InputText";

const FacturaForm = ({ useData, handleUseData, handleChange, factura, errors }) => {
  let { email, nombreCompleto } = factura;

  return (
    <div className="flex flex-col items-center w-[26rem] sm:w-[30rem] mx-auto">
      <div className="flex flex-col w-full text-left mb-6">
        <InputSelect
          name={"compra"}
          label={"Id de la compra"}
          placeholder={"una compra"}
          disableFirstOption
          id={"compra"}
          styles="!w-full"
        />
      </div>
      <div className="flex w-full text-left mb-6 gap-3 flex-row align-middle">
        <div>
          <input
            type="checkbox"
            name={`useData`}
            id={`useData`}
            className="rounded-md border-2 border-gray-300 my-0 "
            checked={useData}
            onChange={(e) => handleUseData(e)}
          />
        </div>
        <label htmlFor={`useData`} className="cursor-pointer">
          <p className="text-md 2xl:text-base text-left">
            Deseo usar mis datos de facturación configurados en el perfil
          </p>
        </label>
      </div>
      <div className="flex flex-col w-full text-left mb-6">
        <InputText
          id={"email"}
          name={"email"}
          label={"Correo electrónico de facturación"}
          placeholder={"Ingrese un correo electrónico"}
          value={email}
          onChange={(e) => handleChange(e, "email")}
          error={errors["email"]}
          styles="!w-full"
        />
      </div>
      <div className="flex flex-col w-full text-left mb-6">
        <InputText
          id={"nombreCompleto"}
          name={"nombreCompleto"}
          label={"Nombre completo o razón social"}
          placeholder={"Ingrese su nombre completo"}
          value={nombreCompleto}
          onChange={(e) => handleChange(e, "nombreCompletoFactura")}
          error={errors["nombreCompleto"]}
          styles="!w-full"
        />
      </div>
    </div>
  );
};

export default FacturaForm;
