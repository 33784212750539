import React, { useState } from "react";
import ActivityImage from "./ActividadesUtils/ActImage";
import ImagenValidada from "./ActividadesUtils/RecursosValidados/ImagenValidada";

function Tarjetas({ tarjetas, instrucciones }) {
  const [temporaryFlipped, setTemporaryFlipped] = useState([]);

  const handleFlip = (itemToFlip) => {
    const isFlipped = temporaryFlipped.some((el) => el._id === itemToFlip._id);
    if (isFlipped)
      setTemporaryFlipped(
        temporaryFlipped.filter((el) => el._id !== itemToFlip._id)
      );
    else setTemporaryFlipped([...temporaryFlipped, itemToFlip]);
  };

  return (
    <div className="my-10 md:my-20">
      {instrucciones && (
        <div className="mb-4 block-instructions px-1">{instrucciones}</div>
      )}
      <div className="cards-block-container flex justify-center max-w-[1280px] items-center content-center flex-wrap">
        {tarjetas.map((item, index) => {
          return (
            <>
              <div
                className={`relative memory-card !aspect-square cursor-pointer w-[45%] md:w-[30%] ${
                  temporaryFlipped.map((el) => el._id).includes(item._id)
                    ? "flip"
                    : ""
                }`}
                key={index}
                onClick={() => handleFlip(item)}
              >
                {item.texto ? (
                  <div className="front-face flex justify-center text-p-2 !p-4 text-justify text-nimbusLight overflow-auto">
                    {item.texto}
                  </div>
                ) : (
                  ""
                )}
                <ImagenValidada
                  className={
                    "back-face w-full h-full absolute top-0 right-0 bottom-0 left-0 !object-cover !bg-white border shadow-md !p-2 !rounded-xl overflow-hidden"
                  }
                  src={item.imagen}
                />
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Tarjetas;
