import BgImage from '../../../assets/img/defaultBgImg.svg'
import {ReactComponent as DelIcon} from '../../../assets/icons/deleteIconCampus.svg'
import {ReactComponent as DelIconDisabled} from '../../../assets/icons/deleteIconCampusDisabled.svg'
import { toast } from 'react-toastify'
import alertConfiguration from '../../utils/alertsUtils'

export default function InputFileCard({name,id,img = '', onChange, deleteImage, error, errorStyles, disabled=false,accept=".jpg", bgImage=BgImage, styles = "", imageStyles, allowMultipleExtensions = false}) {

    const validateFile = (e) =>{
        if(allowMultipleExtensions){
            let allowedExtensionsFile = accept.replace(/\s/g, "").split(",").map(extension => extension.toLowerCase())
            let fileExtension = "."+e.target.files[0].name.split(".").pop().toLowerCase()
            if (allowedExtensionsFile.includes(fileExtension)) {
                onChange(e)
            }else{
                  let extensionsMessage = allowedExtensionsFile.join().replace(/,/g, ", ");
                  toast.warning(`Sólo puede cargar imágenes ${extensionsMessage}`, alertConfiguration)
              }
        }else{
            if(e.target.files[0].type === 'image/jpeg'){
                onChange(e)
            }else{
                toast.error('Sólo puedes cargar imágenes .jpg', alertConfiguration)
            }
        }
    }

    return(
        <div className={`flex flex-col bg-white shadow-lg rounded-xl w-64 h-56 justify-center p-4 my-10 ${styles}`}>
            <div className="flex place-content-center my-4 h-full w-full">
                <div className="grid place-content-center mt-2 ml-3 relative">
                    <img
                        className={`w-24 h-24 bg-gray-200 rounded-full border-4 ${imageStyles} ${img instanceof File || img!=='' ? 'object-cover' : 'object-scale-down'}`}
                        src={img instanceof File ? URL.createObjectURL(img) : img==='' ? bgImage : img}
                        alt={name}/>
                </div>
            </div>
            {!disabled && <div className="flex flex-wrap gap-x-2 justify-evenly items-center mb-4 relative">
                <label htmlFor={id} className='bg-nimbusDark text-white rounded-lg px-1 relative cursor-pointer text-sm py-2'>Subir imagen</label>
                <input
                    type="file"
                    name={name}
                    id={id}
                    className='-z-50 opacity-0 absolute top-0 left-0 right-0 bottom-0 w-full h-full'
                    onChange={validateFile}
                    accept={accept}
                />
                <div className='flex rounded w-10 h-11 justify-center items-center' onClick={deleteImage}>
                    {img instanceof File || img!== '' ? <DelIcon className='cursor-pointer'/> : <DelIconDisabled/>}
                </div>
            </div>}
            <p className={`font-thin text-xs text-red-400 ml-[20%] ${errorStyles}`}>{error}</p>
        </div>
    )
}