
import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
const CardComprados = ({ id, item, tipo, especifico, SuperTipo = "" }) => {
  let result, picture, comprobar, precio, ancho, arreglo, type, band, size;
  const navigate = useNavigate();
    arreglo = especifico?.map((item2) => {
      let nombre;
      if (item2._id === item?.categoria) {
        nombre = item2.nombre;
      }
      return nombre;
    });
    if (tipo === "") {
      if (item?.logo !== undefined) {
        type = "Academias";
      } else {
        type = "Cursos";
      }
    } else {
      type = tipo;
    }
    result = arreglo?.filter((dato) => dato !== undefined);
    //console.log("ver el resultado", result?.length,tipo,result);
    if (result?.length !== 0) {
      if (result[0]?.length > 13) {
        ancho = "120px";
        band = true;
      } else {
        ancho = "80px";
        band = false;
      }
    }
    if (item?.descuento > 0) {
      precio = (item.descuento * item.costo) / 100;
      comprobar = true;
    } else {
      precio = item.costo;
      comprobar = false;
    }
    if (type === "Cursos") {
      picture = item.imagen;
    } else {
      picture = item.logo;
    }
    if (item?.nombre?.length > 25) {
      size = "10px";
    } else {
      size = "13px";
    }
    const Read = () => {
      //aqui el botón ver más
      //redireccionaremso al modulo de detalles
      //dispatch(set_tipo(tipo));
      console.log("ver el id y tipo", tipo, id); //con esto indicamos que sera de solo lectura ocultar btn
      /*localStorage.setItem("tipo", tipo); //que tipo de dato se envia
      localStorage.setItem("Comprado",true);*/
      navigate(`/MarketPlace/DetallesProduct/:${id}/:${tipo}/:${true}`); // y la ruta especifica a la cual se envia
    };
  
  //let size;
  return  item?.nombre === undefined ? (
    <div></div>
  ) : (
    <div className="tarjetas">
      <div className="user__img">
        <img src={picture} alt="Imagen MarketPlace" className="user_photo" />
      </div>
      <div className="cardbuy">
        {band ? (
          <div className="container_carousel_btn2">
            <p
              className="caja__txt__p "
              style={{ width: "48px", marginBottom: "2px" }}
            >
              {type}
            </p>
            <p className="caja__txt__p lg:w-20 xl:w-20 2xl:w-20" >
              {result}
            </p>
          </div>
        ) : (
          <div className="container_carousel_btn">
            <p className="caja__txt__p " style={{ width: "48px" }}>
              {type}
            </p>
            <p className="caja__txt__p lg:w-20 xl:w-20 2xl:w-20" style={{ width: "80px" }} >
              {result}
            </p>
          </div>
        )}
        <div>
          <p className="texto__titulo" style={{ fontSize: size }}>
            {item?.nombre}
          </p>
        </div>
        <div className="contedor__btn__oferta">
          <div className="flex justify-center w-full">
            <button
              className="btn__curos"
              onClick={() => Read()}
            >
              Ver mas
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComprados;
