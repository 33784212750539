export default function CleanButton({ handleClick }) {
  return (
    <div
      id="cleanBtn"
      className="flex justify-center p-2 font-bold bg-gray-100 text-nimbusDark cursor-pointer rounded"
      onClick={handleClick}
    >
      <p>Limpiar</p>
    </div>
  );
}
