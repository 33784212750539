import { ReactComponent as ArrowUp } from "../../../assets/icons/arrowUpSimple.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDownSimple.svg";

export default function InputNumber({
  value,
  onBlur,
  onChange,
  name,
  id,
  disabled = false,
  label,
  placeholder,
  error = "",
  styles = "",
  maxLength = undefined,
  incrementValue = () => {},
  decrementValue = () => {},
  enableArrows = false,
  ...additionalProps
}) {
  return (
    <div className="grid gap-y-2">
      <p className="font-semibold text-base text-[#0D0C22] truncate">{label}</p>
      <div className="relative">
        <input
          className={`peer border-2 h-[40px] focus:outline-none rounded-lg p-2 w-80 lg:w-full pr-8 inputNumber ${styles} ${
            error === "" ? "border-gray-300" : "border-red-400"
          }`}
          type="tel"
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          id={id}
          disabled={disabled}
          maxLength={maxLength}
          {...additionalProps}
        />
        {!disabled && enableArrows && (
          <>
            <ArrowUp
              className="absolute top-2 right-2 cursor-pointer hover:bg-gray-200 select-none"
              onClick={incrementValue}
            />
            <ArrowDown
              className="absolute top-[22px] right-2 cursor-pointer hover:bg-gray-200 select-none"
              onClick={decrementValue}
            />
          </>
        )}
        <p className="font-thin text-xs text-red-400">{error}</p>
      </div>
    </div>
  );
}
