import React from 'react';
import InputText from '../../../fragments/Inputs/InputText';
import InputSelect from '../../../fragments/Inputs/InputSelect';
import { ICONOS_CATEGORIAS, ICONOS_CATEGORIAS_VISUALIZACION, OPTIONS_SELECT } from '../../../utils/categoriaNotificacionesUtils';
import '../../../../colors.css';

const CategoriaNotificacionesForm = ({
  categoriaNotificacion,
  errors,
  handleChange,
  setCategoriaNotificacion,
  ver,
  editar,
}) => {

  return (
    <div className='flex flex-col'>
      <div className='grid grid-cols-1 gap-2 w-full text-left mb-6'>
        <InputText
          id={'nombre'}
          name={'nombre'}
          label={'Nombre'}
          placeholder={'Ingrese el nombre de la categoría'}
          value={categoriaNotificacion.nombre}
          onChange={(e) => handleChange(e, 'nombre')}
          error={errors['nombre']}
          styles='!w-full'
          disabled={ver}
        />
      </div> 
      <p className={`font-semibold text-base text-[#0D0C22] mb-2`}>Icono</p>
      <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-10 gap-2 w-full text-left mb-6'>
        {ICONOS_CATEGORIAS_VISUALIZACION.map((icono) => (
          <button 
            key={icono._id} 
            type="button"
            className={`flex items-center gap-2 h-20 w-20 ${categoriaNotificacion.icono === icono._id && 'selected rounded-md'}`} 
            onClick={() => !ver && setCategoriaNotificacion({
              ...categoriaNotificacion,
              icono: icono._id,
            })}
          >
            <img
              src={icono.icono}
              className='object-cover fondo-azul-folder'
              title={icono.nombre}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategoriaNotificacionesForm;
