import React, { useContext } from "react";
import NimbusModal from "../../../../../fragments/NimbusModal";
import CancelButton from "../../../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../../../fragments/Buttons/SubmitButton";
import useFormSubmit from "../../../../../hooks/useFormSubmit";
import { PaqueteProductosContext } from "../../../../../../contexts/PaqueteProductosContext";
import { eliminarProducto } from "../../../../../../reducers/paquetesProductos/paquetesProductosAction";

const EliminarProductoDelPaqueteModal = ({ isOpen, setIsOpen, productoInfo, setIsEnableSubmitButton}) => {
  const { productoListaId } = productoInfo;
  const { productos, dispatch } = useContext(PaqueteProductosContext);
  const eliminarProductoSubmit = async () => {
    dispatch(eliminarProducto(productos, productoListaId));
    setIsEnableSubmitButton(true)
    setIsOpen(false);
  };
  const [formStatus, handleSubmit] = useFormSubmit(eliminarProductoSubmit);
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Eliminar producto del paquete</h1>
      <p className="w-96 px-4 sm:px-2">
        Al eliminar este producto, podrá volver a añadirlo pero deberá configurar de nuevo la cantidad. Al eliminarlo,
        el precio de venta sugerido podrá disminuir, por lo cual, le sugerimos revisar el precio de venta.
      </p>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea eliminar este producto del paquete?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default EliminarProductoDelPaqueteModal;
