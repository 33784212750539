import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../fragments/LoadingScreen";
import { UserContext } from "../../services/user";
import SubHeaderMisFavoritos from "./SubHeaderMisFavoritos";
import { useNavigate } from "react-router-dom";
import withUserAccesability from "../../hoc/withUserAccesability";
import { MESSAGES } from "../../utils/messagesUtils";
import NoDataMessage from "../../fragments/NoDataMessage";
import MisFavoritosFilter from "./fragments/MisFavoritosFilter";
import ProductoCard from "../Tienda/fragments/ProductoCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchFavouritesAsync } from "../../../reducers/marketplace/marketplaceAction";
import { selectFavourites, selectFavouritesIsLoading } from "../../../reducers/marketplace/marketplaceSelector";
import { usePagination } from "../../hooks/usePagination";

const MisFavoritos = ({ isUserAllowed }) => {
  const { user, activeRole } = useContext(UserContext);
  const [productos, setProductos] = useState([]);
  const [productosFiltro, setProductosFiltro] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favoritos = useSelector(selectFavourites);
  const loading = useSelector(selectFavouritesIsLoading);
  const [paginacion, setPaginacion] = useState(20);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(activeRole !== ""){
      setIsLoading(false)
      if (!isUserAllowed) {
        navigate("/NotFound");
      } else {
        dispatch(fetchFavouritesAsync());
      }
    }
  }, [activeRole]);

  useEffect(() => {
    setProductos(favoritos);
    setProductosFiltro(favoritos);
  }, [favoritos]);

  useEffect(() => {
      setPaginacion(20)
  }, [productosFiltro])

  return (
    <Fragment>
      {loading || isLoading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderMisFavoritos />
            <div
              className={`${productos.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}
            >
              <div className="mb-6">
                <MisFavoritosFilter
                  productos={productos}
                  productosFiltro={productosFiltro}
                  setProductosFiltro={setProductosFiltro}
                />
                <div className="grid justify-center sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                  {productosFiltro.slice(0, paginacion).map((producto) => (
                    <ProductoCard
                      key={producto._id}
                      productoInfo={producto.producto ? producto.producto : producto.paquete}
                      esPaquete={producto.producto === undefined}
                      esFavorito
                      favoritoId={producto._id}
                    />
                  ))}
                </div>
                {productos.length !== 0 && productosFiltro.length === 0 && (
                  <NoDataMessage message={MESSAGES.MESSAGES_FILTER_PRODUCTOS_FAVORITOS} />
                )}
              </div>
              {paginacion < productosFiltro.length && (
                <div className="flex justify-center mt-auto">
                  <button
                    className="border-2 rounded-md text-nimbusDark border-nimbusDark px-4 py-2"
                    onClick={() => setPaginacion((paginacion) => paginacion + 20)}
                  >
                    Mostrar más artículos
                  </button>
                </div>
              )}
              <div>{productos.length === 0 && <NoDataMessage message={MESSAGES.MESSAGES_PRODUCTOS_FAVORITOS} />}</div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withUserAccesability(MisFavoritos);
