import React, { useContext, useEffect, useState } from "react";
import { ESTATUS, ESTATUS_ENTREGA, METODO_DE_PAGO } from "../../../utils/misComprasUtils";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import { formatDate } from "../../../utils/dateFunctions";
import { UserContext } from "../../../services/user";

const FILTRO_COMPRAS_INITIAL_VALUES = {
  busqueda: "",
  estatusEntrega: "",
  estatusPago: "",
  metodoDePago: "",
};

const MisComprasFilter = ({ compras, comprasFiltro, setComprasFiltro }) => {
  const { user } = useContext(UserContext)
  const [filtro, setFiltro] = useState(FILTRO_COMPRAS_INITIAL_VALUES);

  const filterDataBusqueda = (compras, busqueda) => {
    if (busqueda === "") return compras;

    const newComprasFiltros = compras.filter(
      (compra) =>
        compra._id.toLowerCase().startsWith(busqueda) ||
        parseFloat(compra.total).toString().startsWith(parseFloat(busqueda).toString()) ||
        formatDate(new Date(compra.fechaCreacion)).toString().startsWith(busqueda)
    );

    return newComprasFiltros;
  };

  const filterSelectMetodoDePago = (compras, metodo) => {
    if (metodo === "") return compras;
    const newComprasFiltros = compras.filter((compra) => compra.metodoDePago === metodo);

    return newComprasFiltros;
  };

  const filterSelectEstatusDePago = (compras, estatus) => {
    if (estatus === "") return compras;

    if (estatus === "PAGO_FALLIDO") {
      return compras.filter((compra) => compra.estatus === estatus);
    }

    const newComprasFiltros = compras.filter(
      (compra) => compra.esPagado.toString() === estatus && compra.estatus !== "PAGO_FALLIDO"
    );

    return newComprasFiltros;
  };

  const filterSelectEstatusEntrega = (compras, estatusEntrega) => {
    if (estatusEntrega === "") return compras;
    const newComprasFiltros = compras.filter((compra) => compra.estatus === estatusEntrega);

    return newComprasFiltros;
  };

  useEffect(() => {
    let newComprasFiltro = compras;
    newComprasFiltro = filterSelectEstatusEntrega(newComprasFiltro, filtro.estatusEntrega);
    newComprasFiltro = filterSelectMetodoDePago(newComprasFiltro, filtro.metodoDePago);
    newComprasFiltro = filterSelectEstatusDePago(newComprasFiltro, filtro.estatusPago);
    newComprasFiltro = filterDataBusqueda(newComprasFiltro, filtro.busqueda.toLowerCase());
    setComprasFiltro(newComprasFiltro);
  }, [filtro, compras]);

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {comprasFiltro.length}
          </p>
          <p className="text-black">Compras</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className="flex flex-col lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 w-full">
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Estatus de entrega"
              options={ESTATUS_ENTREGA}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, estatusEntrega: e.target.value })}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Método de pago"
              options={user?.escuela?.campus?.dirFiscalPais?.nombre === "México" ? METODO_DE_PAGO : METODO_DE_PAGO.filter(metodo => metodo._id !== "oxxo")}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, metodoDePago: e.target.value })}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Estatus de pago"
              options={ESTATUS}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, estatusPago: e.target.value })}
            />
          </div>
        </div>
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
          <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} value={filtro.busqueda}/>
        </div>
      </div>
    </div>
  );
};

export default MisComprasFilter;
