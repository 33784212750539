import React, { Fragment, useContext, useEffect, useState } from 'react';
import LoadingScreen from '../../fragments/LoadingScreen';
import SubHeaderCategoriaNotificaciones from './fragments/SubHeaderCategoriaNotificaciones';
import { usePagination } from '../../hooks/usePagination';
import { UserContext } from '../../services/user';
import CategoriasNotificacionesFilter from './fragments/CategoriasNotificacionesFilter';
import CategoriasNotificacionesTable from './fragments/CategoriasNotificacionesTable';
import NoDataMessage from '../../fragments/NoDataMessage';
import { MESSAGES } from '../../utils/messagesUtils';
import TablePagination from '../../fragments/TablePagination';
import alertConfiguration from '../../utils/alertsUtils';
import { toast } from 'react-toastify';
import { getCategoriasNotificaciones } from '../../services/Api/CategoriaNotificaciones';

const CategoriasNotificacionesHome = () => {
  const { user, activeRole } = useContext(UserContext);
  const [categoriasNotificaciones, setCategoriasNotificaciones] = useState([]);
  const [categoriasNotificacionesFiltro, setCategoriasNotificacionesFiltro] = useState([]);
  const [loading, setLoading] = useState(false);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    categoriasNotificacionesFiltro,
    20
  );

  const obtenerCategoriasNotificaciones = async () => {
    try {
      setLoading(true);      
      let categoriasNotificacionesRes = await getCategoriasNotificaciones(
        user._id
      );
      categoriasNotificacionesRes = categoriasNotificacionesRes.sort( (a, b) => {
        if (a.fechaCreacion > b.fechaCreacion) return 1;        
        if (a.fechaCreacion < b.fechaCreacion) return -1;
        return 0;
      });
      setCategoriasNotificaciones(categoriasNotificacionesRes);
      setCategoriasNotificacionesFiltro(categoriasNotificacionesRes);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(
        'Error al obtener las categorias de notificaciones',
        alertConfiguration
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    if (activeRole !== '') {
      obtenerCategoriasNotificaciones();
    }
  }, [activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderCategoriaNotificaciones />
          <div
            className={`${
              categoriasNotificacionesFiltro.length !== 0 && 'justify-between'
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <CategoriasNotificacionesFilter
                categoriasNotificaciones={categoriasNotificaciones}
                categoriasNotificacionesFiltro={categoriasNotificacionesFiltro}
                setCategoriasNotificacionesFiltro={
                  setCategoriasNotificacionesFiltro
                }
              />
              <CategoriasNotificacionesTable
                categoriasNotificaciones={currentData()}
                obtenerCategoriasNotificaciones={
                  obtenerCategoriasNotificaciones
                }
              />
            </div>
            <div>
              {categoriasNotificacionesFiltro.length === 0 ? (
                <NoDataMessage
                  message={MESSAGES.MESSAGES_CATEGORIAS_NOTIFICACIONES_TABLE}
                />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CategoriasNotificacionesHome;
