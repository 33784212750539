import React, { useEffect, useState } from "react";
import logoNimbus from "../../../../assets/icons/icons-bloques/logoNimbus.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { useContext } from "react";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import ReactAudioPlayer from 'react-audio-player';
import CerrarActButton from "../ActividadesUtils/CerrarActButton";
import ImagenValidadaComparador from "./ActividadesUtils/RecursosValidados/ImagenValidadaComparador";

const Memorama = ({ data, evaluable, umaBloque, guardarCalificacion, instrucciones }) => {
  const [foundItems, setFoundItems] = useState([])
  const [temporaryFlipped, setTemporaryFlipped] = useState([])
  const [audioPlaying, setAudioPlaying] = useState()
  const activityContext = useContext(ActividadContext)

  const playAudio = (audio) =>{
      if(typeof audio === 'object'){
        const audioObject = URL.createObjectURL(audio)
        setAudioPlaying(audioObject)
      }else{
        setAudioPlaying(audio)
      }
  }

  const handleFlip = (itemToFlip) =>{
    setTemporaryFlipped([...temporaryFlipped, itemToFlip])
  }

  const calificacion =  (data.length / foundItems.length) * 100

  const calificar = () =>{
    activityContext.postCalificacionActividad(calificacion)
    if(umaBloque)guardarCalificacion(umaBloque, foundItems.length / 2, JSON.stringify(foundItems))
  }

  useEffect(()=>{
    //revisa si los dos items coinciden
    if(temporaryFlipped.length >= 2){
      if(temporaryFlipped[0].orden === temporaryFlipped[1].orden){
        setFoundItems([...foundItems,...temporaryFlipped])
      }
      setTimeout(()=>{
        setTemporaryFlipped([])
        setAudioPlaying('')
      },1000)
    }
    //mensaje al terminar
    if((foundItems.length === data.length) && evaluable){
      if(activityContext !== undefined) calificar()
      
    }
  },[temporaryFlipped])

  const handleCerrarAct = () =>{
    activityContext.postCalificacionActividad(null)
  }

  return (
    <div className="my-5 w-full">
      {instrucciones && <div className="max-w-activity act-instructions mb-6">{instrucciones}</div>}
      <section className="memory-game relative max-w-activity p-2">
        {audioPlaying === '' ? '' :
        <ReactAudioPlayer
          src={typeof audioPlaying === 'object' ? URL.createObjectURL(audioPlaying) : audioPlaying }
          autoPlay
          controls
          className="absolute -top-[9999px] -left-[9999px] opacity-0"
        />
        }
        {data.map((item, index) => (
          <div 
            className={`relative memory-card ${temporaryFlipped.map((el)=>(el._id)).includes(item._id) || foundItems.map((el)=>el._id).includes(item._id) || !evaluable ? 'flip pointer-events-none' : ''}`} 
            key={index} 
            onClick={temporaryFlipped.length <= 1 ? ()=>handleFlip(item) : undefined}
          >
            {!evaluable ? <div className="absolute top-1 left-1 z-10 !bg-[#3ea34d] font-bold text-sm !w-6 !h-6 !text-white flex justify-center align-center !rounded-full front-face">{item.orden}</div> : ''}
            {item.imagen ? <ImagenValidadaComparador className="front-face" src={item.imagen} alt={item._id}/> : '' }
            {item.texto ? <div className="front-face flex justify-center text-sm md:text-xl text-nimbus text-center p-2 md:p-4 overflow-auto">{item.texto}</div> : ''}
            {item.audio ? <FontAwesomeIcon className="front-face !box-border !p-[2.5rem]" color={'#1c7ccc'} icon={faVolumeHigh}></FontAwesomeIcon> : '' }
            <img
              className="back-face"
              src={logoNimbus}
              alt=""
              onClick={item.audio ? ()=>playAudio(item.audio) : undefined}
            />
          </div>
        ))}
      </section>
      <CerrarActButton onClick={handleCerrarAct}/>
    </div>
  );
};

Memorama.defaultProps= {
  evaluable:true, 
  data:[]
}

export default Memorama;
