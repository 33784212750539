import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState } from "react";
import { Editor as ClassicEditor } from "ckeditor5-resize-nimbus-build/build/ckeditor";
import { MyUploadAdapter } from "../../../../constructor/umas/MyUploadAdapter";
import {
  customEditorToolbar,
  editorImageConfig,
  listProperties,
  errorToast,
  successToast,
  editorLinkConfig,
} from "../../../../constructor/umas/ActividadesUtils/FormularioUtils";
import TextEditorButtons from "../../../../constructor/umas/TextEditorButtons";
import { useDispatch, useSelector } from "react-redux";
import { selectContent } from "../../../../../reducers/avisoData/avisoDataSelector";
import { setContenidoText } from "../../../../../reducers/avisoData/avisoDataAction";
import { useGestorAvisosDetalles } from "../GestorAvisosDetallesContext";

function TextoComponent({ setCkEditorText, ckEditorText, contenidoId, texto }) {
  const { editandoTexto, ver } = useGestorAvisosDetalles();
  const [text, setText] = useState(texto ?? "");
  const [infoHolder, setInfoHolder] = useState(texto ?? "");
  //permite la edición del bloque colpsable
  const [textOption, setTextOption] = useState(null);
  const [colapsable1, setColapsable1] = useState("");
  const [colapsable2, setColapsable2] = useState("");
  //permite el cambio del toolbar a título dentro del editor
  const [titleOption, setTitleOption] = useState(false);
  const dispatch = useDispatch();
  const contenido = useSelector(selectContent);

  //configuracion para tipos de fuente

  const fontOptions = [
    "default",
    "Arial, Helvetica, sans-serif",
    "Courier New, Courier, monospace",
    "Georgia, serif",
    "Lucida Sans Unicode, Lucida Grande, sans-serif",
    "Tahoma, Geneva, sans-serif",
    "Times New Roman, Times, serif",
    "fantasy",
    "cursive",
    "monospace",
  ];

  //configuracion de la tabla

  const tableConfig = {
    table: {
      tableProperties: {
        // The default styles for tables in the editor.
        // They should be synchronized with the content styles.
        defaultProperties: {
          borderStyle: "dashed",
          borderColor: "hsl(90, 75%, 60%)",
          borderWidth: "3px",
          alignment: "left",
          width: "550px",
          height: "450px",
        },
        // The default styles for table cells in the editor.
        // They should be synchronized with the content styles.
        tableCellProperties: {
          defaultProperties: {
            horizontalAlignment: "center",
            verticalAlignment: "bottom",
            padding: "10px",
          },
        },
      },
    },
  };
  //Configuracion para links

  const linkConfig = {
    link: {
      addTargetToExternalLinks: true,
      // Let the users control the "download" attribute of each link.
      decorators: [
        {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "download",
          },
        },
      ],
    },
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  const handleCancelarText = () => {
    setCkEditorText(true);
    setText(infoHolder);
  };

  const handleCerrarText = () => {
    setCkEditorText(true);

    if (!ckEditorText) {
      setTitleOption(false);

      if (textOption !== null) {
        const cleanColapsable1 = colapsable1.replace(/&nbsp;/g, "");
        const cleanColapsable2 = colapsable2.replace(/&nbsp;/g, "");
        const currentText = cleanColapsable1.concat("|", cleanColapsable2);
        setText(currentText);
        setTextOption(null);
        if (currentText !== "") setText(currentText);
        dispatch(setContenidoText(currentText, contenido, contenidoId));
      } else {
        const cleanText = text.replace(/&nbsp;/g, "");
        setText(cleanText);
        dispatch(setContenidoText(cleanText, contenido, contenidoId));
      }
    }
  };

  const internetMessage =
    "Hubo un problema al guardar. Asegúrate de estar conectado a internet.";

  return (
    <>
      <div
        className={`${
          ver ? "cursor-not-allowed" : "cursor-pointer"
        } hover:border-[#c6c6c6] transition-all rounded-md pl-5 pb-5 pr-5 p-2 mb-4 border`}
      >
        <div className="flex justify-end pb-2">
          <div className="border rounded-l-md rounded-b-md bg-nimbus text-white px-2">
            Texto
          </div>
        </div>
        <button
          onClick={() => {
            setCkEditorText(false);
            setText(text);
          }}
          className={`break-words p-2 h-auto border border-dashed border-3 border-blue-400 w-full ${
            ver ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          disabled={ver || editandoTexto}
        >
          {text === "" ? (
            <div className={`editor-styles`}>Editar Texto</div>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className={`editor-styles`}
            />
          )}
        </button>
      </div>
      {!ckEditorText && (
        <div className="fixed z-20 bottom-0 bg-slate-500 p-4 left-64 right-[15rem]">
          <div
            className="bg-white p-4 max-h-[calc(100vh_-_95px)] overscroll-contain"
            style={{ overflow: "overlay" }}
          >
            <div className={`editor-wrapper relative constructor-editor`}>
              <CKEditor
                editor={ClassicEditor}
                data={text ? text : ""}
                config={{
                  image: editorImageConfig,
                  link: editorLinkConfig,
                  fontFamily: { options: fontOptions },
                  fontSize: {
                    options: [9, 11, 13, "default", 17, 19, 25, 30, 35, 40],
                  },
                  tableConfig,
                  linkConfig,
                  extraPlugins: [MyCustomUploadAdapterPlugin],
                  removePlugins: ["Title"],
                  list: listProperties,
                  mediaEmbed: { previewsInData: true },
                  toolbar: {
                    viewportTopOffset: 0,
                    items: titleOption
                      ? [
                          "bold",
                          "italic",
                          "fontFamily",
                          "fontColor",
                          "fontBackgroundColor",
                          "fontSize",
                          "alignment",
                        ]
                      : customEditorToolbar,
                  },
                  heading: {
                    options: titleOption
                      ? [
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                        ]
                      : [
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                        ],
                  },
                }}
                onReady={(editor, event) => {
                  setInfoHolder(editor.getData());
                  //en caso de elegir un heading se cambian sus propiedades
                  if (titleOption) {
                    editor.execute("fontColor", { value: "#1a3d82" });
                    editor.execute("bold");
                    if (text === "<h2></h2>")
                      editor.execute("alignment", { value: "center" });
                  }

                  editor.focus();
                  //Elimina el texto basura generado por ckEditor
                  const data = editor.getData();
                  const colapsable = data.split("|");
                  const text1 = colapsable[0].replace("<p>&nbsp;</p>", "");
                  const text2 = colapsable[1].replace("<p>&nbsp;</p>", "");
                  setColapsable1(text1);
                  setColapsable2(text2);

                  //elige entre texto normal o texto para colapsable
                  if (textOption == 0) {
                    editor.setData(text1);
                  } else if (textOption == 1) {
                    if (colapsable2 == null) {
                      editor.setData("");
                    } else {
                      editor.setData(text2);
                    }
                  }
                }}
                onChange={(event, editor) => {
                  const className = editor.config.get("bg-red-500");
                  editor.ui.view.body._bodyCollectionContainer.classList.add(
                    className
                  );
                }}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  if (textOption == 0) {
                    setColapsable1(data);
                  } else if (textOption == 1) {
                    setColapsable2(data);
                  } else {
                    setText(data);
                  }
                }}
              />
              <TextEditorButtons
                handleCancelar={() => handleCancelarText()}
                handleSave={
                  window.navigator.onLine
                    ? () => handleCerrarText()
                    : () => errorToast(internetMessage)
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TextoComponent;
