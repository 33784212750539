import React,{useState, useEffect} from 'react'
import TooltipLabel from '../../../utils/TooltipLabel'
import FormularioEvidencias from '../../../Herramientas/FormularioEvidencias'
import {darAltaEvidencia, enviarTareaEvidencia} from '../../../services/api'
import ReactLoading from "react-loading"
import CaratulaEvidencias from '../../../../assets/icons/icons-bloques/caratulaEvidencias.png'
import FolderIcon from '../../../../assets/icons/folderIcon.svg'
import { cancelarEnvio } from '../../../services/Api/Evidencias'
import { customToast } from '../ActividadesUtils/FormularioUtils'
import ConfirmModal from '../../../utils/ConfirmModal'
import { Trans } from 'react-i18next'
import { formatearFecha, replaceTextLinks, roundTwoDecimals } from './ActividadesUtils/ActividadesUtils'
import { v4 as uuidv4 } from "uuid";
import ComentariosBox from '../../../Herramientas/examenes/Tareas/TareasUtils/ComentariosBox'
import CalificacionesBox from '../../../Herramientas/examenes/Tareas/TareasUtils/CalificacionesBox'

function EvidenciaBox({ bloque, evidencia, seguimientoEvidencia, libro, materia, alumno, evaluable, nombre, descripcion, rubricas}) {
    const [inputList, setInputList] = useState([])
    const [textareaValue, setTextareaValue] = useState('')
    const [comentarios, setComentarios] = useState([])
    const [evidenciaStatus, setEvidenciaStatus] = useState('PENDIENTE')
    const [evidenciaId, setEvidenciaId] = useState('')
    const [calificacionRubricas, setCalificacionRubricas] = useState([])
    const [fecha, setFecha] = useState('')
    const [loading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [esBloqueado, setEsBloqueado] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const getAvances = () =>{
        const seguimiento = seguimientoEvidencia[0]
        if(!seguimiento)return
        const comentariosAlumno = seguimiento.comentariosAlumnoStr ? JSON.parse(seguimiento.comentariosAlumnoStr) : []
        const comentariosProfesor = seguimiento.comentariosProfesorStr ? JSON.parse(seguimiento.comentariosProfesorStr) : []
        const archivosCargados = seguimiento.cargaEvidenciasStr ? JSON.parse(seguimiento.cargaEvidenciasStr) : []
        const sortedComments = [...comentariosAlumno, ...comentariosProfesor].sort((a, b)=>{
            if(a.fecha > b.fecha){
                return 1
            }else{
                return -1
            }
        })
        setEsBloqueado(seguimiento.esBloqueado)
        setComentarios(sortedComments)
        setInputList(archivosCargados)
        setEvidenciaStatus(seguimiento.estatus)
        setEvidenciaId(seguimiento._id)
        setFecha(seguimiento.fechaEnvio)
        const calificaciones = seguimiento?.calificacionRubricasStr ? JSON.parse(seguimiento.calificacionRubricasStr) : []
        setCalificacionRubricas(calificaciones)
        setLoading(false)
    }   

    useEffect(()=>{
         if(seguimientoEvidencia && seguimientoEvidencia.length)getAvances()
    },[seguimientoEvidencia])

    const verificarTareaIniciada = () =>{
        setIsOpen(true)
        if(alumno !== null && seguimientoEvidencia.length === 0){
            setLoading(true)
            const body ={
                "alumno": alumno,
                "materia": materia,
                "libroContenido": libro,
                "umaBloque": bloque,
                "evidencia": evidencia,
                "estatus": "PENDIENTE"
            } 
            darAltaEvidencia(body)
            .then((response)=>{
                if(response.response.data !== undefined){
                    const data = response.response.data
                    const comentariosAlumno = data.comentariosAlumno.map((el)=>({...el,rol:'alumno', fecha:new Date(el.fechaCreacion).getTime()}))
                    const comentariosProfesor = data.comentariosProfesor.map((el)=>({...el, rol:'profesor', fecha:new Date(el.fechaCreacion).getTime()}))
                    const sortedComments = [...comentariosAlumno, ...comentariosProfesor].sort((a, b)=>{
                        if(a.fecha > b.fecha){
                            return 1
                        }else{
                            return -1
                        }
                    })
                    setComentarios(sortedComments)
                    setInputList(data.cargaEvidencias)
                    setEvidenciaStatus(data.estatus)
                    setEvidenciaId(data._id)
                    setEsBloqueado(data.evidencia.esBloqueado)
                    setLoading(false)
                }
            })
        }
    }

    const reloadData = (data) =>{
        setTextareaValue('')
        const reloadedComentariosAlumno = data.comentariosAlumnoStr ? JSON.parse(data.comentariosAlumnoStr) : []
        const reloadedComentariosProfesor = data.comentariosProfesorStr ? JSON.parse(data.comentariosProfesorStr) : []
        setComentarios([...reloadedComentariosAlumno, ...reloadedComentariosProfesor])
        setEvidenciaStatus(data.estatus)
        setFecha(data?.fechaEnvio)
        setLoading(false)
    }

    const enviarTarea = () =>{
        setLoading(true)
        const comentariosActuales = comentarios.filter((el)=>(el.rol === 'alumno'))
        const archivosActuales = inputList.map((el)=>({archivo:el.archivo, titulo:el.titulo}))
        const body ={
            "_id": evidenciaId,
            "estatus": "ENVIADA",
            "cargaEvidenciasStr": JSON.stringify(archivosActuales),
            "comentariosAlumnoStr": JSON.stringify([...comentariosActuales, textareaValue !== '' ? {comentario:textareaValue, _id:uuidv4(), fecha:Date.now(), esEliminado:false, rol:"alumno"} : null].filter((el)=>(el))),
        }
        enviarTareaEvidencia(body)
        .then((res)=>{
            reloadData(res.response.data)
            customToast('evidenciaSaved')
        })
        .catch(()=>{
            customToast('evidenciaNotSaved')
        })
    }

    const handleCancelarEnvio = () =>{
        toggleModal()
        setLoading(true)
        const comentariosEliminados = comentarios.filter((el)=>(el.rol === 'alumno')).map((el)=>({...el, esEliminado:true}))
        const body = {
            "_id": evidenciaId,
            "estatus": "PENDIENTE",
            "comentariosAlumnoStr":JSON.stringify(comentariosEliminados),
            "cargaEvidenciasStr":'[]'
        }
        cancelarEnvio(body)    
        .then((res)=>{
            reloadData(res)
            customToast('canceledEvidencia')
        })
        .catch(()=>{
            customToast('evidenciaNotSaved')
        })
    }

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    useEffect(()=>{
        if(!alumno && !evaluable && !materia && !seguimientoEvidencia){
            setLoading(false)
        }
    },[])

  return (
    < >
        {isOpen ? 
            <div className='my-10'>
                <ConfirmModal 
                    isOpen={isModalOpen} 
                    message={"cancelMessage"}
                    onConfirm={handleCancelarEnvio} 
                    onReject={toggleModal}
                />
                <div className="text-4xl mb-3 text-center text-[#3a93e3] font-bold flex-1 mt-20">
                    <Trans>
                        Evidencia
                    </Trans>
                </div>    
                <div
                    className={`transition border rounded-2xl shadow-lg  py-5 px-3 w-full max-w-[1280px] mx-auto border-t-[13px] md:py-10 md:px-20 bg-white ${evidenciaStatus === "PENDIENTE" ? 'border-t-[#e1e4e8]' : evidenciaStatus === "ENVIADA" ? 'border-t-[#2fdd9d]' : evidenciaStatus === "RECHAZADA" ? 'border-t-red-500' : evidenciaStatus === "CALIFICADA" ? 'border-t-[#1a3d82]' : '' }`}
                    style={{borderColor:'linear-gradient(270deg, #2EDE9A 0%, #38BFD8 100%)'}}
                >
                    <div className='max-w-activity mx-auto'>
                        <div className="text-xl md:text-2xl text-center mb-4">
                            <span className='font-bold mr-2 text-black'>
                                <Trans i18nKey=''>Título</Trans>
                            </span>
                            {nombre}
                        </div>
                        <div className='mb-4 font-bold text-black'><Trans i18nKey=''>Descripción</Trans></div>
                        <div className="text-base mb-6 visor" dangerouslySetInnerHTML={{__html:replaceTextLinks(descripcion)}}></div>
                            {loading && alumno !== null &&<ReactLoading type="spin" color="#1A3D82" height={'5%'} width={'5%'} className='my-72 m-auto'/>}
                            {!loading && 
                            <div className="flex-col-reverse lg:flex-row flex">
                                <div className="w-full md:w-2/3">
                                    <div className="w-full mb-8">
                                        <TooltipLabel title="Rúbrica" tooltip="Indica todos los puntos que se tomarán en cuenta para evaluar la tarea"/>
                                        <div>
                                            <table className='general-table text-sm'>
                                                <thead className='bg-[#1A3D82] text-white rounded-lg overflow-hidden'>
                                                    <tr>
                                                        <th><Trans i18nKey=''>Criterios</Trans></th>
                                                        <th className='text-center hidden md:table-cell'>0</th>
                                                        <th className='text-center hidden md:table-cell'>1</th>
                                                        <th className='text-center hidden md:table-cell'>2</th>
                                                        <th className='text-center hidden md:table-cell'>3</th>
                                                        <th className='text-center hidden md:table-cell'>4</th>
                                                        <th className='text-center hidden md:table-cell'>5</th>
                                                        <th className='text-center table-cell md:hidden'>
                                                            <Trans i18nKey=''>Calificación</Trans>
                                                        </th>
                                                        <th>
                                                            <Trans i18nKey=''>Observaciones</Trans>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='pointer-events-none'>
                                                    {rubricas ?
                                                    rubricas.map((el, i)=>{
                                                        const match = calificacionRubricas.find((item)=>item._id === el._id)
                                                        return(
                                                            <tr key={i}>
                                                                <td>{el.descripcion}</td>
                                                                <td className='w-10 hidden md:table-cell'>
                                                                    <input readOnly  id="default-radio-1" type="radio" value={0} name={i} class="w-6 h-6 text-blue-600 bg-gray-200 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" checked={match?.value === 0}/>
                                                                </td>
                                                                <td className='w-10 hidden md:table-cell'>
                                                                    <input readOnly id="default-radio-1" type="radio" value={1} name={i} class="w-6 h-6 text-blue-600 bg-gray-200 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" checked={match?.value === 1}/>
                                                                </td>
                                                                <td className='w-10 hidden md:table-cell'>
                                                                    <input readOnly id="default-radio-1" type="radio" value={2} name={i} class="w-6 h-6 text-blue-600 bg-gray-200 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" checked={match?.value === 2}/>
                                                                </td>
                                                                <td className='w-10 hidden md:table-cell'>
                                                                    <input readOnly id="default-radio-1" type="radio" value={3} name={i} class="w-6 h-6 text-blue-600 bg-gray-200 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" checked={match?.value === 3}/>
                                                                </td>
                                                                <td className='w-10 hidden md:table-cell'>
                                                                    <input readOnly id="default-radio-1" type="radio" value={4} name={i} class="w-6 h-6 text-blue-600 bg-gray-200 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" checked={match?.value === 4}/>
                                                                </td>
                                                                <td className='w-17 text-left hidden md:table-cell'>
                                                                    <input readOnly id="default-radio-1" type="radio" value={5} name={i} class="w-6 h-6 text-blue-600 bg-gray-200 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" checked={match?.value === 5}/>
                                                                </td>
                                                                <td className='w-17 text-center table-cell md:hidden'>
                                                                    {match?.value}
                                                                </td>
                                                                <td>
                                                                    <textarea readOnly value={match?.texto} className="w-full h-full min-w-[8rem] rounded-lg bg-[#f5f5f5] border-[#dcdcdc] text-sm resize-none"></textarea>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }):''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {evaluable ? 
                                        <div className="mb-8 w-full inline-block">
                                            <div className='font-bold text-black mb-4'>
                                                <TooltipLabel title="Evidencias" tooltip="El límite de los archivos subidos es de 10MB en formato png, jpg, pdf, docx, xlsx o pptx.Los archivos de tipo video deben ser referenciado a través de un enlace"/>
                                            </div>
                                            <div className="text-sm text-[#697D99] mb-4">
                                                <Trans i18nKey=''>Archivos cargados</Trans>
                                                <div className="ml-2 rounded-full bg-[#EFF1F5] w-5 h-5 text-nimbus items-center justify-center inline-flex">{inputList.length}</div>
                                            </div>
                                            {evidenciaId !== '' ? 
                                            <>
                                                <FormularioEvidencias
                                                    inputList={inputList}
                                                    setInputList={setInputList}
                                                    viewOnly={evidenciaStatus === "CALIFICADA" || esBloqueado}
                                                    setLoading={setLoading}
                                                />
                                            </>
                                            :''
                                            }
                                        </div>
                                    :null}
                                </div>
                                {evaluable ? 
                                <CalificacionesBox
                                    estatus={evidenciaStatus}
                                    fechaEnvio={formatearFecha(fecha)}
                                    calificacionRubricas={calificacionRubricas}
                                />
                                :null}
                            </div>
                            }
                            {evaluable && 
                            <ComentariosBox
                                comentarios={comentarios}
                                textareaValue={textareaValue}
                                setTextareaValue={setTextareaValue}
                                readOnly={evidenciaStatus === "CALIFICADA"}
                            />
                            }
                            <div className='flex items-center justify-center'>
                                {(!loading || !alumno) && 
                                    <button
                                        className="rounded-full px-8 py-4 bg-[#f5f5f5] text-nimbusLight relative block mt-4 md:mt-10 mr-4 font-bold"
                                        onClick={() => setIsOpen(false)}
                                        >
                                        <Trans>Cerrar</Trans>{" "}
                                        <div className="lowercase inline">
                                        <Trans>Evidencia</Trans>
                                        </div>
                                    </button>
                                }
                                { !loading && alumno !== null ? 
                                <>
                                    {evidenciaStatus === "CALIFICADA" || esBloqueado ? null : 
                                        <>
                                            {evidenciaStatus === "ENVIADA" ? (
                                                <button
                                                onClick={toggleModal}
                                                className="rounded-full px-8 py-4 bg-[#f5f5f5] text-nimbusLight relative block mt-4 md:mt-10 mr-4 font-bold"
                                                >
                                                <Trans>Cancelar envío</Trans>
                                                </button>
                                            ) : null}
                                            <button
                                                className="border rounded-full px-8 py-4 bg-[#1a3d82] text-white relative block mt-4 md:mt-10 font-bold"
                                                onClick={
                                                inputList.length > 0 || textareaValue !== ""
                                                    ? enviarTarea
                                                    : () => customToast("evidenciaValidation")
                                                }
                                            >
                                                <Trans>
                                                {inputList.lenght === 0 && comentarios.length === 0
                                                    ? "Enviar tarea"
                                                    : "Guardar cambios"}
                                                </Trans>
                                            </button>
                                        </>
                                    }
                                </>
                                :null}
                            </div>
                    </div>
                </div>
            </div>:
            <div className="relative my-10 border shadow-lg rounded-lg text-center">
                <img src={CaratulaEvidencias} alt="" className="min-h-[18rem]"/>
                <div className="max-w-[20rem] w-full text-center absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-nimbusLight">
                    <div className="text-2xl md:text-5xl mb-4 flex justify-center font-bold"><Trans>Evidencia</Trans></div>
                    <div className="!text-lg md:text-5xl mb-4 flex justify-center font-bold">{nombre}</div>
                    <div 
                        className='!max-w-[12rem] md:max-w-full w-full aspect-square border border-[#1cb9fcab] rounded-2xl px-4 md:px-8 mx-auto py-4 md:py-8 my-5 md:my-[3.5rem] hover:brightness-105 transition cursor-pointer'
                        onClick={verificarTareaIniciada}
                        >
                        <div className="flex mb-3 mx-auto rounded-full aspect-square w-10 h-10 md:w-[6rem] md:h-[6rem] text-white items-center justify-center bg-[#1CB9FC]">
                            <img alt="" src={FolderIcon} className="md:w-12"/>
                        </div>
                        <span className="md:text-2xl font-bold text-[#1CB9FC] whitespace-nowrap text-center">
                            <Trans>Ver tarea</Trans>
                        </span>
                    </div>
                    <div className="md:text-xl mb-4"><Trans>Estatus de envío:</Trans> 
                        <div className={`rounded-lg mt-2 text-white w-full max-w-[158px] mx-auto ${evidenciaStatus === "PENDIENTE" ? 'bg-[#e1e4e8]' : evidenciaStatus === "ENVIADA" ? 'bg-[#2fdd9d]' : evidenciaStatus === "RECHAZADA" ? 'bg-red-500' : evidenciaStatus === "CALIFICADA" ? 'bg-[#1a3d82]' : '' }`}>
                            <Trans>{evidenciaStatus}</Trans>
                        </div>
                        {typeof seguimientoEvidencia[0]?.calificacionFinal === 'number' && <div className='mt-2'>{roundTwoDecimals(seguimientoEvidencia[0]?.calificacionFinal)} / 100</div>}
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default EvidenciaBox