import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/icons/logoCheck.svg";

const ConfirmMailPassword = () => {
  let navigate = useNavigate()

  return (
    <div className="w-full min-h-screen md:h-auto md:min-h-[100vh] bg-[#fcfcfc] flex flex-col justify-center items-center">
      <div className="w-[22rem] flex flex-col justify-center px-8 md:px-0">
        <div className="flex flex-col items-center mx-auto mb-8">
        <img className="h-auto w-24" src={Logo} alt="logo.svg" />
          <h1 className="text-nimbusDark font-bold text-2xl md:text-3xl mt-8 text-center">
            Revisa tu correo
          </h1>
          <p className="text-[#3A404C] text-center mt-5 text-sm md:text-base">
            Te hemos enviado un correo electrónico con las instrucciones para
            crear tu nueva contraseña.
          </p>
        </div>
        <button className="bg-nimbusDark font-semibold text-white w-full rounded-lg py-2" onClick={() => navigate("/")}>
          Aceptar
        </button>
        <p className="text-[#3A404C] text-center text-xs md:text-sm mt-14">
          ¿No recibiste el correo electrónico? Checa el filtro Spam o
          <a href="/change/password" className="font-bold text-nimbusDark cursor-pointer"> prueba con otro correo electrónico</a>
        </p>
      </div>
    </div>
  );
};

export default ConfirmMailPassword;
