import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import LoadingScreen from "../../../../fragments/LoadingScreen";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import useValidateForm from "../../../../hooks/useValidateForm";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import { useNavigate, useParams } from "react-router-dom";
import SubHeaderPaqueteProductos from "./SubHeaderPaqueteProductos";
import ContentContainer from "../../../../fragments/ContentContainer";
import PaqueteInfoForm from "./PaqueteInfoForm";
import PaqueteContenidoForm from "./PaqueteContenidoForm";
import PaqueteCarruselForm from "./PaqueteCarruselForm";
import useCarrusel from "../../../../hooks/useCarrusel";
import { UserContext } from "../../../../services/user";
import { obtenerUserEstructuraInfo } from "../../../../utils/userUtils";
import useUploadImage from "../../../../hooks/useUploadImage";
import { PaqueteProductosContext } from "../../../../../contexts/PaqueteProductosContext";
import { errorToast } from "../../../../constructor/umas/ActividadesUtils/FormularioUtils";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../utils/alertsUtils";
import { inicializarProductos } from "../../../../../reducers/paquetesProductos/paquetesProductosAction";
import {
  getPaqueteMarketplaceDetalle,
  postPaqueteMarketplace,
  putPaqueteMarketplace,
} from "../../../../services/Api/MarketPlace/Paquetes";
import { v4 as uuidv4 } from "uuid";
import { getPaises } from "../../../../services/api";
import EditarInformacionPaqueteModal from "../fragments/EditarInformacionPaqueteModal";
import { PaisesContext } from "../../../../../contexts/PaisesContext";

const NOT_REQUIRED_FIELDS_PAQUETE = ["imagen", "estatus", "_id", "notificar", "correoNotificacion", "esDestacado"];

const INITIAL_VALUES_PAQUETE = {
  nombrePaquete: "",
  imagen: "",
  estatus: true,
  precioVenta: "",
  nivelAcademico: "637ffd677e7f9619cc2849f5",
  gradoAcademico: "",
  notificar: true,
  correoNotificacion: "",
  esDestacado: false,
  descripcion: "",
};

const PaqueteProductosForm = (props) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [formStatus, handleSubmit] = useFormSubmit(paqueteSubmit);
  const { carrusel, setCarrusel, carruselMetodos } = useCarrusel();
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"]);
  const { user, activeRole } = useContext(UserContext);
  const { paises: paisesContext, paisDestino, setPaisOrigen, setPaisDestino } = useContext(PaisesContext);
  const { productos, dispatch } = useContext(PaqueteProductosContext);
  const [precioVentaSugerido, setPrecioVentaSugerido] = useState(0);
  const [disponibilidad, setDisponibilidad] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [ conversionMonedas, setConversionMonedas ] = useState({});
  const params = useParams();

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    areRequiredFieldsFulfilled,
  } = useValidateForm(INITIAL_VALUES_PAQUETE);

  const { verifyFiles, deleteImage, uploadFile } = useUploadImage(values, setValues);
  
  const handleEliminarImagen = () => {
    setIsEnableSubmitButton(true)
    deleteImage()
  }

  const handleModalEditar = () => {
    setOpenModal(!openModal);
  };

  const cantidadDeProductos = useMemo(() => {
    return productos.reduce((acc, producto) => acc + producto.cantidad, 0);
  }, [productos]);

  const obtenerPaquete = useCallback(async () => {
    if (activeRole !== "") {
      const { estructura, anteriorEstructura, paisEstructura } = obtenerUserEstructuraInfo(user, activeRole);
      setPaisDestino(paisEstructura);
      const paises = await getPaises();
      if (estructura.dirFiscalPais) {
        const simbolo =
          estructura.dirFiscalPais.nombre === "Estados Unidos"
            ? ["USD"]
            : estructura.dirFiscalPais.simbolo
            ? [estructura.dirFiscalPais.simbolo]
            : [paises.data.filter((pais) => pais._id === estructura.dirFiscalPais)[0]?.simbolo];
        setTipoDeMoneda(simbolo);
      }
      if (props.editar || props.ver) {
        try {
          let paqueteResponse = await getPaqueteMarketplaceDetalle(params.paqueteId);

          paqueteResponse.paquete.nombrePaquete = paqueteResponse.paquete.nombre;
          paqueteResponse.paquete.nivelAcademico = paqueteResponse.paquete.nivelAcademico._id;
          paqueteResponse.paquete.gradoAcademico = paqueteResponse.paquete.gradoAcademico._id;
          paqueteResponse.paquete.carrusel = paqueteResponse.paquete.carrusel.map((foto) => ({
            _id: foto,
            imagen: foto,
          }));

          let paqueteValues = {
            ...paqueteResponse,
            ...paqueteResponse.paquete,
          };

          delete paqueteValues.paquete;

          let productosPaquete = paqueteValues.productos.map((producto) => {
            let nuevoProducto = {
              ...producto,
              productoListaId: uuidv4(),
              precioVenta: producto.producto.precioVenta,
              total: producto.producto.precioVenta * producto.cantidad,
              producto: {
                _id: producto.producto._id,
                nombre: producto.producto.producto.nombre,
                estatus: producto.producto.producto.estatus,
              },
            };

            if(producto.producto.origen === "Linea") {
              nuevoProducto.provieneDe = { [anteriorEstructura] : producto.producto.provieneDe}
              nuevoProducto.costo = producto.producto.costo 
            }

            return nuevoProducto;
          });

          let conversionBody = {
            paisOrigen: paqueteResponse.dirFiscalPais,
            paisDestino: paisDestino,
            paises: paisesContext,
          };
          
          setConversionMonedas(conversionBody);
          setCarrusel(paqueteResponse.paquete.carrusel);
          setValues(paqueteValues);
          dispatch(inicializarProductos(productosPaquete));
          setPaisOrigen(paqueteResponse?.distribucion?.dirFiscalPais);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else {
        dispatch(inicializarProductos([]));
        setLoading(false);
      }
    }
  }, [activeRole]);

  useEffect(() => {
    obtenerPaquete();
  }, [obtenerPaquete]);

  useEffect(() => {
    if (productos.length > 0) {
      const precioSugerido = productos.reduce((acc, producto) => {
        return acc + producto.total;
      }, 0);

      setPrecioVentaSugerido(precioSugerido);
      const disponibilidad = !productos.some((producto) => {
        return producto.producto.estatus === false;
      });
      setDisponibilidad(disponibilidad);
    } else {
      setPrecioVentaSugerido(0);
      setDisponibilidad(true);
    }
  }, [productos]);

  useEffect(() => {
    let enableButton = false;
    enableButton = areRequiredFieldsFulfilled(NOT_REQUIRED_FIELDS_PAQUETE) && cantidadDeProductos > 1;
    if (!enableButton) setIsEnableSubmitButton(false);
  }, [values, productos, carrusel]);

  async function paqueteSubmit() {
    try {
      if (carruselMetodos.validarCarruselVacio()) {
        errorToast("Asegúrese de tener todas las fotos con una imagen.");
        setErrors(errors);
      } else {
        if (Object.values(errors).length === 0) {
          await verifyFiles(values, setValues);
          let nuevoCarrusel = await carruselMetodos.handleSubirFotosCarrusel(uploadFile);
          let { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
          let paqueteBody = {
            ...values,
            nombre: values.nombrePaquete,
            carrusel: nuevoCarrusel,
            nivelAcademico: {
              _id: values.nivelAcademico,
            },
            gradoAcademico: {
              _id: values.gradoAcademico,
            },
            estatus: disponibilidad,
            productos: productos,
          };

          if (!paqueteBody.notificar) delete paqueteBody.correoNotificacion;

          let body = {
            paquete: paqueteBody,
            precioVenta: parseFloat(paqueteBody.precioVenta),
            cantidad: 0,
            costo: 0,
            origen: "Nuevo",
            [tipoEstructuraRequest]: {
              _id: estructuraId,
            },
          };

          if (props.editar) {
            await putPaqueteMarketplace(params.paqueteId, body);
            toast.success("El paquete se ha actualizado exitosamente.", alertConfiguration);
          } else {
            await postPaqueteMarketplace(body);
            toast.success("El paquete se ha creado exitosamente.", alertConfiguration);
          }
          navigate(-1);
        } else {
          setErrors(errors);
          errorToast("Complete todos los campos requeridos.");
        }
      }
    } catch (error) {
      toast.error("Ha ocurrido un error.", alertConfiguration);
      console.log(error);
    }
  }
  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <EditarInformacionPaqueteModal isOpen={openModal} handleModal={handleModalEditar} handleSubmit={handleSubmit}/>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderPaqueteProductos editar={props.editar} ver={props.ver} />
            <ContentContainer>
              <div className="flex flex-col w-full space-y-6">
                <PaqueteInfoForm
                  paquete={values}
                  errors={errors}
                  handleChange={handleChange}
                  setPaquete={setValues}
                  ver={props.ver}
                  editar={props.editar}
                  tipoDeMoneda={tipoDeMoneda}
                  deleteImage={handleEliminarImagen}
                  precioVentaSugerido={precioVentaSugerido}
                  disponibilidad={disponibilidad}
                  conversionMonedas={conversionMonedas}
                />
                <PaqueteContenidoForm
                  ver={props.ver}
                  tipoDeMoneda={tipoDeMoneda}
                  setIsEnableSubmitButton={setIsEnableSubmitButton}
                  cantidadDeProductos={cantidadDeProductos}
                />
                <PaqueteCarruselForm
                  carrusel={carrusel}
                  carruselMetodos={carruselMetodos}
                  setIsEnableSubmitButton={setIsEnableSubmitButton}
                  ver={props.ver}
                />
              </div>
            </ContentContainer>
            <div className="flex justify-center gap-x-4 mt-6">
              <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                <CancelButton
                  text={`${props.ver ? "Regresar" : "Cancelar"}`}
                  bgColor="bg-divider"
                  onClick={() => navigate(-1)}
                />
                {!props.ver && (
                  <SubmitButton
                    text="Guardar"
                    disabled={!isEnableSubmitButton}
                    onClick={props.editar ? handleModalEditar : handleSubmit}
                    loading={formStatus === "submitting"}
                    loadingButton
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PaqueteProductosForm;
