import { getProductosFavoritos } from "../../components/services/Api/MarketPlace/Favoritos";
import { getPaquetesMarketplaceEstructura } from "../../components/services/Api/MarketPlace/Paquetes";
import { getProductosEstructura } from "../../components/services/Api/MarketPlace/Productos";
import { createAction } from "../../components/utils/reducerUtils";
import { MARKETPLACE_ACTION_TYPES } from "./marketplaceTypes";

export const fetchMarketplaceStart = (loading) =>
  createAction(MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_START, loading);

export const fetchMarketplaceSuccess = (marketplaceProducts) =>
  createAction(MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_SUCCESS, marketplaceProducts);

export const fetchMarketplaceFailed = (error) => createAction(MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_FAILED, error);

export const fetchMarketplaceAsync =
  (tipoEstructuraRequest, estructuraId, loading = true) =>
  async (dispatch) => {
    dispatch(fetchMarketplaceStart(loading));
    try {
      let productsResponse = await getProductosEstructura(tipoEstructuraRequest, estructuraId);
      let packagesResponse = await getPaquetesMarketplaceEstructura(tipoEstructuraRequest, estructuraId);
      const marketplaceProducts = {
        productos: productsResponse,
        paquetes: packagesResponse,
      };
      dispatch(fetchMarketplaceSuccess(marketplaceProducts));
    } catch (error) {
      console.log(error);
      dispatch(fetchMarketplaceFailed(error));
    }
  };

export const fetchFavouritesStart = (loading) => createAction(MARKETPLACE_ACTION_TYPES.FETCH_FAVOURITES_START, loading);

export const fetchFavouritesSuccess = (favouriteProducts) =>
  createAction(MARKETPLACE_ACTION_TYPES.FETCH_FAVOURITES_SUCCESS, favouriteProducts);

export const fetchFavouritesFailed = (error) => createAction(MARKETPLACE_ACTION_TYPES.FETCH_FAVOURITES_FAILED, error);

export const fetchFavouritesAsync =
  (loading = true) =>
  async (dispatch) => {
    dispatch(fetchFavouritesStart(loading));

    try {
      const favouriteProductsResponse = await getProductosFavoritos();
      dispatch(fetchFavouritesSuccess(favouriteProductsResponse));
    } catch (error) {
      console.log(error);
      dispatch(fetchFavouritesFailed(error));
    }
  };

export const filtrarProductos = (busqueda) =>
  createAction(MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_SEARCH, busqueda);

export const fetchShowBuscador = (showBuscador) => 
  createAction(MARKETPLACE_ACTION_TYPES.FETCH_SHOW_BUSCADOR, showBuscador);