import React, { useCallback, useContext, useEffect, useState } from "react";
import NimbusModal from "../../../../../fragments/NimbusModal";
import CancelButton from "../../../../../fragments/Buttons/CancelButton";
import ProductosDisponiblesTable from "./ProductosDisponiblesTable";
import { UserContext } from "../../../../../services/user";
import { obtenerUserEstructuraInfo } from "../../../../../utils/userUtils";
import { getProductosEstructura } from "../../../../../services/Api/MarketPlace/Productos";
import ProductosDisponiblesFilter from "./ProductosDisponiblesFilter";
import NoDataMessage from "../../../../../fragments/NoDataMessage";
import { MESSAGES } from "../../../../../utils/messagesUtils";
import { PaqueteProductosContext } from "../../../../../../contexts/PaqueteProductosContext";
import {
  agregarTodosProductos,
  setIsAddProductsModalOpen,
} from "../../../../../../reducers/paquetesProductos/paquetesProductosAction";
import { usePagination } from "../../../../../hooks/usePagination";
import TablePagination from "../../../../../fragments/TablePagination";
import { errorToast } from "../../../../../constructor/umas/ActividadesUtils/FormularioUtils";
import AddIcon from "../../../../../../assets/icons/addLg.svg";

const AgregarProductosModal = ({ isOpen, setIsOpen, setIsEnableSubmitButton }) => {
  const { user, activeRole } = useContext(UserContext);
  const [productos, setProductos] = useState([]);
  const [productosFiltro, setProductosFiltro] = useState([]);
  const { productos: productosLista, dispatch } = useContext(PaqueteProductosContext);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(productosFiltro, 15);

  const obtenerProductos = useCallback(async () => {
    if (activeRole !== "") {
      try {
        let { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
        let productosResponse = await getProductosEstructura(tipoEstructuraRequest, estructuraId);
        const productosIdEnLaLista = productosLista.map((producto) => producto.producto._id);
        productosResponse = productosResponse.filter(
          (producto) =>
            producto.producto.configuracionProducto !== "PRODUCTO_VARIABLE" &&
            !productosIdEnLaLista.includes(producto._id) &&
            producto.origen !== "Linea"
        );
        setProductos(productosResponse);
        setProductosFiltro(productosResponse);
      } catch (error) {
        console.log(error);
      }
    }
  }, [isOpen, productosLista, activeRole]);

  const seleccionarProducto = (productoASeleccionar) => {
    let nuevosProductos = productos.map((producto) => {
      if (producto._id === productoASeleccionar._id) {
        producto.esSeleccionado = !producto.esSeleccionado;
      }
      return producto;
    });

    setProductos(nuevosProductos);
  };

  const seleccionarTodosLosProductos = (checked) => {

    const productosSeleccionadosIds = currentData().map((producto) => producto._id);

    let nuevosProductos = productos.map((producto) =>
      productosSeleccionadosIds.includes(producto._id) ? { ...producto, esSeleccionado: checked } : producto
    );

    setProductos(nuevosProductos);
  };

  const handleAgregarTodosLosProductos = () => {
      dispatch(agregarTodosProductos(productosLista, productos));
      dispatch(setIsAddProductsModalOpen(false));
      let resetProductos = productos.map((producto) => ({ ...producto, esSeleccionado: false }));
      setIsEnableSubmitButton(true);
      setProductos(resetProductos);
  };

  useEffect(() => {
    obtenerProductos();
  }, [obtenerProductos]);
  return (
    <NimbusModal isOpen={isOpen}>
      <div className="h-[80vh] md:h-[75vh] min-w-[24rem] sm:min-w-[32rem] max-w-[90vw]  xl:max-w-[94vw]  2xl:max-w-[90vw] overflow-y-auto pb-1">
        <h1 className="text-nimbusDark font-bold">Agregar productos</h1>
        <p className="px-4 sm:px-2 mt-4">
          Utilice los filtros para buscar el o los productos que desee añadir al paquete, y de clic en
          <img src={AddIcon} className="h-6 translate-y-[3px] translate-x-1 ml-1 mr-1.5 inline-block" alt="" />
          para agregarlos.
        </p>
        <div
          className={`${
            productos.length !== 0 && "justify-between"
          } relative flex-1 flex flex-col px-4 text-center h-[calc(100%-4.25rem)]`}
        >
          <div>
            <ProductosDisponiblesFilter
              productos={productos}
              productosFiltro={productosFiltro}
              setProductosFiltro={setProductosFiltro}
              seleccionarTodosLosProductos={seleccionarTodosLosProductos}
              handleAgregarTodosLosProductos={handleAgregarTodosLosProductos}
            />
            <ProductosDisponiblesTable
              productos={currentData()}
              seleccionarProducto={seleccionarProducto}
              setIsEnableSubmitButton={setIsEnableSubmitButton}
            />
            {productos.length !== 0 && productosFiltro.length === 0 && (
              <NoDataMessage
                message={MESSAGES.MESSAGE_FILTER_PRODUCTOS_TABLE}
                stylesWrapper="lg:my-5 xl:mt-12 xl:mb-6"
              />
            )}
          </div>
          <div className={`${productos.length === 0 && "h-full flex flex-col justify-between"}`}>
            {productos.length === 0 ? (
              <NoDataMessage
                message={MESSAGES.MESSAGE_FILTER__PRODUCTOS_PAQUETE_TABLE}
                stylesWrapper="lg:my-5 xl:mt-12 xl:mb-6"
              />
            ) : (
              <TablePagination
                next={next}
                prev={prev}
                currentPage={currentPage}
                data={currentData()}
                jump={jump}
                maxPage={maxPage}
              />
            )}
            <div className="flex justify-center gap-x-4 mt-8">
              <CancelButton text="Cerrar" bgColor="bg-divider" onClick={() => setIsOpen(false)} styles="!w-48" />
            </div>
          </div>
        </div>
      </div>
    </NimbusModal>
  );
};

export default AgregarProductosModal;
