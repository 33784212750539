import React, { useEffect, useState } from 'react'
import {getDetalleUMA } from '../../../services/api'
import cerrar from '../../../../assets/icons/cerrar-circle.svg'
import ModoVisor from '../../umas/ModoVisor'
import { useTranslation } from "react-i18next";

const SwitchButton = ({ modoProfesor, setModoProfesor }) => {
    const handleModoProfesor = (e) => {
        e.preventDefault()
        setModoProfesor(!modoProfesor)
    }

    return (
      <div className='mt-2'>
        <label className="inline-flex relative items-center cursor-pointer" onClick={handleModoProfesor}>
          <input
            type="checkbox"
            name="switchUMA2"
            className="sr-only peer"
            checked={modoProfesor}
          />
          <div className={`rotate-180 w-12 h-6 bg-cyan-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-zinc-400 after:content-[''] after:absolute after:top-0 after:left-0 after:bg-white after:border-cyan-400 after:border-2 after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-zinc-400`}></div>
        </label>
      </div>
    )
  }

const ModalBook = ({setModalBook, id}) => {
    const [information, setInformation] = useState({})
    const [umaBloques, setumaBloques] = useState([])
    const [modoProfesor, setModoProfesor] = useState(false)
    const [bloquesOrdenados, setBloquesOrdenados] = useState([])
    const icons = {cerrar}
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }; 

    const ordenarBloques = (bloques) => {
        bloques.sort((a, b)=>{
            if(a.orden > b.orden){
                return 1
            }else{
                return -1
            }
        })

        return bloques
    }

    useEffect(() => {
        const information = getDetalleUMA(id)
        information.then(value => {
            setInformation(value.data)
            let newUmasOrdenados = ordenarBloques(value.data.umaBloques)
            setumaBloques(newUmasOrdenados)
            setBloquesOrdenados(newUmasOrdenados)
            if(value && value.data && value.data.idioma)changeLanguage(value.data.idioma)
            else changeLanguage('ES')

        }).catch(e=>(
            console.log(e)
        ))
    }, [])

    useEffect(() => {
        if(!modoProfesor){
            let newBloquesOrdenados = umaBloques
            setBloquesOrdenados(newBloquesOrdenados)
        }else{
            let newBloquesModoProfesor = umaBloques.map((bloque) => bloque.esModoProfesor ? {...bloque, esApagado:true} : bloque)
            setBloquesOrdenados(newBloquesModoProfesor)
        }
    }, [modoProfesor])

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);

    return (
        <div
            className="fixed z-50 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            id="modal"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                ></div>
                
                <span
                    className="sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className="relative inline-block align-bottom bg-white rounded-xl px-4 pt-5 pb-10 text-left
shadow-xl transition-all sm:my-8 sm:align-middle h-full max-h-[50rem] overflow-y-auto overflow-x-hidden w-full max-w-[1280px]">
                    <div className='flex flex-col md:flex-row-reverse md:items-center z-[99] bg-white px-[10px] rounded-lg border border-[#dcdcdc] sticky top-0 right-0 w-fit ml-auto'>
                        <div className="flex justify-end items-center  md:top-8 right-1 md:right-1 sm:top- pr-4 ">
                            <p className='text-nimbus mr-0.5 font-bold font-sans'>Cerrar detalle</p>
                            <div>
                                <button
                                    type="button"
                                    className="rounded-x focus:outline-none p-4
                                        "
                                    onClick={() => setModalBook(undefined)}
                                >
                                    <img src={icons.cerrar} alt='' />
                                </button>
                                </div>
                        </div>
                        <div className='flex justify-end pr-12'>
                            <div className='flex flex-col items-center'>
                                <SwitchButton modoProfesor={modoProfesor} setModoProfesor={setModoProfesor}/>
                                <p className='h-[20px] text-nimbus'>Modo profesor</p>
                            </div>
                        </div>
                    </div>
                    <div className='z-0'>
                        <div className=' w-full  flex justify-between'>
                        </div>
                        <div className='flex justify-center max-w-[1280px] mx-auto'>
                            <div className=' bg-white p-4 w-full'>
                            <div className=''>
                              {bloquesOrdenados.length > 0 && bloquesOrdenados.map((e) => {
                                if (!e?.esApagado) return (<ModoVisor e={e} arreglo={bloquesOrdenados} modoProfesor={modoProfesor}/>);
                              })}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ModalBook