import { createAction } from "../../components/utils/reducerUtils";
import { PACKAGE_PRODUCTS_ACTION_TYPES } from "./paquetesProductosTypes";
import { v4 as uuidv4 } from "uuid";

const agregarProductoALaLista = (productos, productoAAgregar) => {
  const productoId = uuidv4();
  let variablesDelProducto = productoAAgregar.producto.variables.map((variable) => ({
    ...variable,
    valorSeleccionado: { _id: "" },
  }));
  let nuevoProducto = {
    productoListaId: productoId,
    precioVenta: productoAAgregar.precioVenta,
    cantidad: 1,
    total: productoAAgregar.provieneDe ? productoAAgregar.costo : productoAAgregar.precioVenta,
    costo: productoAAgregar.costo,
    provieneDe: productoAAgregar.provieneDe,
    producto: {
      _id: productoAAgregar._id,
      nombre: productoAAgregar.producto.nombre,
      variables: variablesDelProducto,
      estatus: productoAAgregar.producto.estatus
    },
  };
  return [...productos, nuevoProducto];
};

const eliminarProductoDeLaLista = (productos, productoAEliminar) => {
  const productoEnLaLista = productos.find((producto) => producto.productoListaId === productoAEliminar);

  if (!productoEnLaLista) return productos;

  return productos.filter((producto) => producto.productoListaId !== productoAEliminar);
};

const seleccionarProductoDeLaLista = (productos, productoASeleccionar) => {
  return productos.map((producto) => {
    if (producto._id === productoASeleccionar._id) {
      producto.esSeleccionado = !producto.esSeleccionado;
    }
    return producto;
  });
};

const seleccionarTodosLosProductos = (producto, checked) => {
  return producto.map((producto) => {
    producto.esSeleccionado = checked;
    return producto;
  });
};

const agregarTodosLosProductos = (productos, nuevosProductosAAgregar) => {
  let nuevosProductos = nuevosProductosAAgregar
    .filter((producto) => producto.esSeleccionado === true)
    .map((producto) => {
      const productoId = uuidv4();
      let variablesDelProducto = producto.producto.variables.map((variable) => ({
        ...variable,
        valorSeleccionado: { _id: "" },
      }));

      let nuevoProducto = {
        productoListaId: productoId,
        precioVenta: producto.precioVenta,
        cantidad: 1,
        total: producto.provieneDe ? producto.costo : producto.precioVenta,
        costo: producto.costo,
        provieneDe: producto.provieneDe,
        producto: {
          _id: producto._id,
          nombre: producto.producto.nombre,
          variables: variablesDelProducto,
          estatus: producto.producto.estatus
        },
      };

      return nuevoProducto;
    });

  return [...productos, ...nuevosProductos];
};

const incrementarCantidadDelProducto = (productos, productoAIncrementar) => {
  return productos.map((producto) =>
    producto.productoListaId === productoAIncrementar
      ? {
          ...producto,
          cantidad: ++producto.cantidad,
          total: producto.provieneDe ? producto.cantidad * producto.costo : producto.cantidad * producto.precioVenta,
        }
      : producto
  );
};

const decrementarCantidadDelProducto = (productos, productoAIncrementar) => {
  return productos.map((producto) =>
    producto.productoListaId === productoAIncrementar
      ? {
          ...producto,
          cantidad: --producto.cantidad,
          total: producto.provieneDe ? producto.cantidad * producto.costo : producto.cantidad * producto.precioVenta,
        }
      : producto
  );
};

const actualizarVariablesDelProducto = (productos, productoId, variables) => {
  return productos.map((producto) =>
    producto.productoListaId === productoId
      ? { ...producto, producto: { ...producto.producto, variables: variables } }
      : producto
  );
};

export const agregarProducto = (productos, producto) => {
  const nuevosProductos = agregarProductoALaLista(productos, producto);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const eliminarProducto = (productos, producto) => {
  const nuevosProductos = eliminarProductoDeLaLista(productos, producto);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const incrementarProducto = (productos, producto) => {
  const nuevosProductos = incrementarCantidadDelProducto(productos, producto);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const decrementarProducto = (productos, producto) => {
  const nuevosProductos = decrementarCantidadDelProducto(productos, producto);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const seleccionarProducto = (productos, producto) => {
  const nuevosProductos = seleccionarProductoDeLaLista(productos, producto);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const actualizarVariableProducto = (productos, productoId, variables) => {
  const nuevosProductos = actualizarVariablesDelProducto(productos, productoId, variables);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const seleccionarTodosProductos = (productos, checked) => {
  const nuevosProductos = seleccionarTodosLosProductos(productos, checked);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const agregarTodosProductos = (productos, nuevosProductosAAgregar) => {
  const nuevosProductos = agregarTodosLosProductos(productos, nuevosProductosAAgregar);

  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, nuevosProductos);
};

export const setIsAddProductsModalOpen = (boolean) =>
  createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_IS_ADD_PRODUCTS_OPEN, boolean);

export const setErroresPaquetes = (paqueteErrores) => {
  return createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS_ERRORS, paqueteErrores);
};

export const inicializarProductos = (productos) =>
  createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS, productos);

export const setFiltrosProductos = (filtros) =>
  createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_FILTERS_PRODUCTS, filtros);

export const setFiltrosPaquetes = (filtros) =>
  createAction(PACKAGE_PRODUCTS_ACTION_TYPES.SET_FILTERS_PACKAGES, filtros);