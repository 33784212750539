import { Route, Routes } from "react-router-dom";
import HistorialAvisosHome from "../../../components/Herramientas/historial-avisos/HistorialAvisosHome";
import React from "react";

const HistorialAvisosRoutes = () => {
  return (
    <Routes>
      <Route index element={<HistorialAvisosHome />} />
    </Routes>
  );
};

export default HistorialAvisosRoutes;
