export const METODO_DE_PAGO = [
    {
        _id: "card",
        nombre: "Tarjeta de crédito / débito",
    },
    {
        _id: "oxxo",
        nombre: "Pago en efectivo OXXO",
    },
    {
        _id: "customer_balance",
        nombre: "Transferencia interbancaria",
    }
]

export const ESTATUS = [
    {
        _id: true,
        nombre: "Completado",
    },
    {
        _id: false,
        nombre: "Pendiente",
    },
    {
        _id: "PAGO_FALLIDO",
        nombre: "Cancelado",
    }
]

export const ESTATUS_ENTREGA = [
    {
        _id: "PREPARANDO",
        nombre: "Preparando",
    },
    {
        _id: "PAGO_FALLIDO",
        nombre: "Cancelado",
    },
    {
        _id: "COMPLETADO",
        nombre: "Completado",
    },
    {
        _id: "ENTREGADO",
        nombre: "Entregado",
    }
]

export const METODOS_DE_PAGO = {
    card: "Tarjeta de crédito / débito",
    oxxo: "Pago en efectivo OXXO",
    customer_balance: "Transferencia interbancaria",
  };