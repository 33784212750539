import { ImgComparisonSlider } from "@img-comparison-slider/react";
import FullScreen from "./ActividadesUtils/FullScreen/FullScreen";
import ImagenValidadaComparador from "./ActividadesUtils/RecursosValidados/ImagenValidadaComparador";

function ImageComparator({ imagenUno, imagenDos, instrucciones }) {
  return (
    <>
      {instrucciones && (
        <div className="act-instructions mb-8 text-left">{instrucciones}</div>
      )}
      <FullScreen
        fullScreenStyles="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-[+99999] overflow-auto bg-white"
        defaultStyles={"overflow-auto h-auto mb-8 relative"}
      >
        <ImgComparisonSlider className="cursor-grab active:cursor-grabbing w-full">
          <ImagenValidadaComparador
            slot="first"
            src={imagenUno}
            className="!min-w-[100%] !max-w-[100%] !w-[100%] rounded h-full"
          />
          <ImagenValidadaComparador
            slot="second"
            src={imagenDos}
            className="!min-w-[100%] !max-w-[100%] !w-[100%] rounded h-full"
          />
        </ImgComparisonSlider>
      </FullScreen>
    </>
  );
}

export default ImageComparator;
