import React, { Fragment, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../fragments/LoadingScreen";
import ContentContainer from "../../fragments/ContentContainer";
import SubHeaderGestionProductos from "./SubHeaderGestionProductos";
import SectionCard from "../../fragments/SectionCard";
import ProductosIcon from "../../../assets/icons/productos.svg";
import NewProductosIcon from "../../../assets/icons/gestionProductosIcon.svg";
import PaquetesIcon from "../../../assets/icons/paquetes.svg";
import NewPaquetesIcon from "../../../assets/icons/newPaquetesIcon.svg";
import { useNavigate } from "react-router-dom";
import withUserAccesability from "../../hoc/withUserAccesability";
import { UserContext } from "../../services/user";
import { PaqueteProductosContext } from "../../../contexts/PaqueteProductosContext";
import { setFiltrosPaquetes, setFiltrosProductos } from "../../../reducers/paquetesProductos/paquetesProductosAction";

const GestionProductos = ({ isUserAllowed }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user, activeRole } = useContext(UserContext);
  const { dispatch } = useContext(PaqueteProductosContext);

  useEffect(() => {
    if(activeRole !== ""){
      if (!isUserAllowed) {
        navigate("/NotFound");
      }else{
        setLoading(false)
        dispatch(setFiltrosPaquetes({}));
        dispatch(setFiltrosProductos({}));
      }
    }
  }, [activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderGestionProductos />
            <ContentContainer>
              <div className="flex flex-wrap gap-6 justify-center md:justify-start">
                <SectionCard
                  icon={NewProductosIcon}
                  title={"Productos"}
                  newStyle={true}
                  onClick={() => navigate("/marketplace/gestionProductos/productos")}
                />
                <SectionCard
                  icon={NewPaquetesIcon}
                  title={"Paquetes"}
                  newStyle={true}
                  onClick={() => navigate("/marketplace/gestionProductos/paquetes")}
                />
              </div>
            </ContentContainer>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withUserAccesability(GestionProductos);
