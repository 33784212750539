import React from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import useFormSubmit from "../../../hooks/useFormSubmit";
import { putConfiguracionPersonalizada } from "../../../services/Api/MarketPlace/ComisionUsoPlataforma";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const ConfiguracionPersonalizadaModal = ({
  isOpen,
  setIsOpen,
  configuracionPersonalizada,
  estructura,
  tipoEstructura,
  obtenerCuentasComerciales,
}) => {
  const configuracionPersonalizadaSubmit = async () => {
    try {
      let body = { [tipoEstructura]: { _id: estructura._id }, configPersonalizada: !configuracionPersonalizada };
      await putConfiguracionPersonalizada(body);
      toast.success(
        `Configuración personalizada ${configuracionPersonalizada ? "desactivada" : "activada"} correctamente.`,
        alertConfiguration
      );
      obtenerCuentasComerciales();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setIsOpen(false);
    }
  };
  const [formStatus, handleSubmit] = useFormSubmit(configuracionPersonalizadaSubmit);

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">
        {configuracionPersonalizada ? "Desactivar" : "Activar"} configuración personalizada
      </h1>
      {configuracionPersonalizada ? (
        <p className="w-96 px-4">
          Al desactivar la configuración personalizada, ya no podrás editar el porcentaje de comisión de uso de la
          plataforma para esta estructura en específico, y por lo tanto, el valor será igual al que se tenga configurado
          en la comisión de uso de la plataforma general.
        </p>
      ) : (
        <p className="w-96 px-4">
          Al activar la configuración personalizada, podrás editar el porcentaje de comisión de uso de la plataforma
          para esta estructura en específico, y por lo tanto, cualquier modificación en la configuración general de la
          comisión de uso de la plataforma, no se verá reflejada en esta estructura.
        </p>
      )}
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">
          ¿Estás seguro que deseas {configuracionPersonalizada ? "desactivar" : "activar"} la configuración
          personalizada?
        </p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default ConfiguracionPersonalizadaModal;
