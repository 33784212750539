import { useNavigate } from "react-router-dom";
import BellIcon from "../../../../assets/icons/bell.svg";
import VisualizarIcon from "../../../../assets/icons/viewIcon.svg";

const formatDateISO = (dateISO) => {
  const date = new Date(dateISO);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = date.toLocaleString("es-ES", { month: "long" });
  const year = date.getUTCFullYear();

  return `${day}-${month.charAt(0).toUpperCase() + month.slice(1)}-${year}`;
};

const ReporteAvisosRow = ({ report }) => {
  const navigate = useNavigate();

  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-2 rounded-l-md border-r-0 pb-1 pt-3 text-center font-san">
          <div className="flex items-center justify-center">
            <img
              className="w-5 h-5 mx-6 lg:mx-1"
              src={BellIcon}
              alt="Bell icon"
            />
          </div>
        </td>
        <td className="border-y-2 font-sans text-left">{report?.nombre}</td>
        <td className="border-y-2 font-sans text-center">
          {report?.categoria?.nombre}
        </td>
        <td className="border-y-2 font-sans text-center">
          {formatDateISO(report?.fechaEnvio ?? "")}
        </td>
        <td className="border-y-2 font-sans text-right px-2">
          {report?.enviadoA}
        </td>
        <td className="border-y-2 font-sans text-right px-2">
          {report?.leidoPor}
        </td>
        <td className="border-y-2 font-sans text-right px-2">
          {report?.sinLeer}
        </td>
        <td className="border-2 rounded-r-md border-l-0 pb-1 pt-3 text-center font-sans">
          <div className="flex items-center justify-around">
            <button
              title="Ver reporte"
              className="w-5 flex"
              onClick={() =>
                navigate(`/herramientas/reporte-avisos/${report?._id}`)
              }
            >
              <img src={VisualizarIcon} className="w-5 h-5" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ReporteAvisosRow;
