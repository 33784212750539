import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import ContentContainer from "../../../../fragments/ContentContainer";
import LoadingScreen from "../../../../fragments/LoadingScreen";
import SubHeaderVariacionesProducto from "./SubHeaderVariacionesProducto";
import VariacionesProductoForm from "../VariacionesProductoForm";
import { getVariacionesProducto } from "../../../../services/Api/MarketPlace/Productos/Variaciones";
import { useParams } from "react-router-dom";
import { getProductoDetalle } from "../../../../services/Api/MarketPlace/Productos";

const VariacionesProducto = () => {
  const [loading, setLoading] = useState(true);
  const [variaciones, setVariaciones] = useState([]);
  const [variables, setVariables] = useState([]);
  const [nombreProducto, setNombreProducto] = useState("");
  const params = useParams();

  const obtenerVariaciones = useCallback(
    async (variacionAExcluir = "") => {
      try {
        let variacionesResponse = await getVariacionesProducto(params.productoId);
        variacionesResponse = variacionesResponse.map((variacion) => ({ ...variacion, variacionId: variacion._id }));
        let variacionesSinCrear = variaciones.filter(
          (variacion) => variacion._id === undefined && variacion.variacionId !== variacionAExcluir
        );
        let nuevasVariaciones = [...variacionesResponse, ...variacionesSinCrear];
        setVariaciones(nuevasVariaciones);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [params.productoId]
  );

  const obtenerVariables = useCallback(async () => {
    try {
      const productoResponse = await getProductoDetalle(params.productoId);
      const nuevasVariables = productoResponse.producto.variables.map((variable) => {
        variable.valores = variable.valores.map((valor) => ({ ...valor, valorId: valor._id, nombre: valor.valor }));
        return variable;
      });
      setNombreProducto(productoResponse.producto.nombre);
      setVariables(nuevasVariables);
    } catch (error) {
      console.log(error);
    }
  }, [params.productoId]);

  useEffect(() => {
    obtenerVariables();
    obtenerVariaciones();
  }, [obtenerVariaciones, obtenerVariables]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderVariacionesProducto nombreProducto={nombreProducto} />
            <ContentContainer>
              <div className="flex flex-col w-full space-y-6">
                <VariacionesProductoForm
                  variaciones={variaciones}
                  setVariaciones={setVariaciones}
                  variables={variables}
                />
              </div>
            </ContentContainer>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default VariacionesProducto;
