import { useState, useEffect } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import { TIPOS_DE_CATEGORIA } from "../../../utils/productoUtils";

const CATEGORIAS_FILTRO = [
  ...TIPOS_DE_CATEGORIA,
  {
    _id: "Paquetes",
    nombre: "Paquetes",
  },
];

const FILTRO_PRODUCTOS_INITIAL_VALUES = {
  busqueda: "",
  categoria: "",
};

export default function MisFavoritosFilter({ productos, productosFiltro, setProductosFiltro }) {
  const [filtro, setFiltro] = useState(FILTRO_PRODUCTOS_INITIAL_VALUES);

  const filterDataBusqueda = (productos, busqueda) => {
    if (busqueda === "") return productos;

    const newProductosFiltro = productos.filter(
      (producto) =>
        producto?.producto?.producto?.nombre.toLowerCase().includes(busqueda) ||
        producto?.paquete?.paquete?.nombre.toLowerCase().includes(busqueda)
    );

    return newProductosFiltro;
  };

  const filterSelectCategoria = (productos, categoria) => {
    if (categoria === "") return productos;
    const newProductosFiltro = productos.filter(
      (producto) => producto?.producto?.producto?.categoria === categoria || (producto?.paquete && categoria === "Paquetes")
    );

    return newProductosFiltro;
  };

  useEffect(() => {
    let newProductosFiltro = productos;
    newProductosFiltro = filterDataBusqueda(newProductosFiltro, filtro.busqueda.toLowerCase());
    newProductosFiltro = filterSelectCategoria(newProductosFiltro, filtro.categoria);
    setProductosFiltro(newProductosFiltro);
  }, [filtro, productos]);

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {productosFiltro.length}
          </p>
          <p className="text-black">Artículos favoritos</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className="flex flex-col lg:grid lg:grid-cols-1 xl:grid-cols-1 gap-3 w-full">
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Categoría"
              options={CATEGORIAS_FILTRO}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
            />
          </div>
        </div>
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:!w-96">
          <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} value={filtro.busqueda}/>
        </div>
      </div>
    </div>
  );
}
