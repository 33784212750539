import React, { useState } from "react";
import { createContext } from "react";
import { useParams } from "react-router-dom";
import { postCalificacion } from "../../../../services/api";
import { useContext } from "react";
import { UserContext } from "../../../../services/user";
import { customToast } from "../../ActividadesUtils/FormularioUtils";

export const ActividadContext = createContext();

function BlockWrapper({
  children,
  esUMA,
  isDone,
  contenido,
  umaBloque,
  reloadActividades,
  evaluable,
  guardarCalificacionEvaluacion,
  parseText = true
}) {
  const params = useParams();
  const { user } = useContext(UserContext);
  const [latestResult, setLatestResult] = useState(isDone);
  const [loading, setLoading] = useState(false);

  const postCalificacionActividad = (calificacion, respuestas) => {
    if (!evaluable) return;
    setLoading(true);
    const body = {
      materia: params.subjectId,
      libroContenido: contenido,
      umaBloque: umaBloque,
      fecha: Date.now(),
      puntuacion: Math.floor(calificacion / 10),
      calificacion: calificacion,
      ...(respuestas && { respuestas }),
    };
    if (esUMA) {
      let alumnoId = user.escuela.campus.grupos.find((grupo)=>grupo._id === params.groupId).alumnos.find(
        (alumno) => alumno.usuario === user._id
      )._id;
      postCalificacion(
        user.escuela._id,
        user.escuela.campus._id,
        params.groupId,
        alumnoId,
        body
      )
        .then((response) => {
          customToast("evidenciaSaved");
          setLoading(false);
          setLatestResult(response.data.calificacion);
          reloadActividades();
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    } else {
      guardarCalificacionEvaluacion(
        umaBloque,
        calificacion,
        JSON.stringify(respuestas)
      );
      customToast("evidenciaSaved");
      setLoading(false);
    }
  };

  const userData = latestResult?.respuestas;

  return (
    <div className={`${parseText && "whitespace-pre-wrap"} shadow-lg w-full border rounded-lg bg-[#e9f8ff54] overflow-hidden my-5 md:my-10 min-h-[400px] md:min-h-[700px] relative flex flex-col items-center justify-center pt-5md:pt-0 break-words px-4`}>
      <ActividadContext.Provider
        value={{
          postCalificacionActividad,
          userData,
          loading,
        }}
      >
        {children}
      </ActividadContext.Provider>
    </div>
  );
}

export default BlockWrapper;
