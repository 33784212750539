import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import SubHeaderConversionMonedas from "./SubHeaderConversionMonedas";
import ContentContainer from "../../../fragments/ContentContainer";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { useNavigate } from "react-router-dom";
import ConfiguracionMonedasForm from "./ConfiguracionMonedasForm";
import FactorConversionForm from "./FactorConversionForm";
import useValidateForm from "../../../hooks/useValidateForm";
import { getPaises } from "../../../services/api";
import {
  getVariablesConversionMonedas,
  putVariablesConversionMonedas,
} from "../../../services/Api/MarketPlace/Editoriales/VariablesConversionMonedas";
import { UserContext } from "../../../services/user";
import useFormSubmit from "../../../hooks/useFormSubmit";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import {
  getVariablesConversionMonedasDistribucion,
  putVariablesConversionMonedasDistribucion,
} from "../../../services/Api/MarketPlace/Distribuciones/VariablesConversionMonedas";
import { formatCurrency } from "../../../utils/productoUtils";

const INITIAL_VALUES_CONVERSION_MONEDAS = {
  monedaNacional: "",
  monedaDeIntercambio: "643ffac9cda25824d0453911",
  factorDeConversion: "",
};

const ConversionMonedas = () => {
  const [loading, setLoading] = useState(true);
  const [monedas, setMonedas] = useState([]);
  const navigate = useNavigate();
  const { user, activeRole } = useContext(UserContext);

  let { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues, setErrors } =
    useValidateForm(INITIAL_VALUES_CONVERSION_MONEDAS);

  const obtenerMonedas = useCallback(async () => {
    const paisesResponse = await getPaises();
    let monedas = paisesResponse.data.map((moneda) => ({
      ...moneda,
      nombre: moneda.divisa.charAt(0).toUpperCase() + moneda.divisa.slice(1),
    }));
    monedas = monedas.slice(0, monedas.length - 1);
    setMonedas(monedas);
  }, []);

  const obtenerVariablesDeConversion = useCallback(async () => {
    if (activeRole !== "") {
      try {
        let variablesConversionResponse;
        let newValuesConversionMonedas;
        switch (activeRole) {
          case "DIR_EDITORIAL":
            variablesConversionResponse = await getVariablesConversionMonedas(user.editorial._id);
            newValuesConversionMonedas = {
              monedaNacional: variablesConversionResponse.monedaNacional._id,
              monedaDeIntercambio: "643ffac9cda25824d0453911",
              factorDeConversion: variablesConversionResponse.factorDeConversion,
            };
            break;
          case "DISTRIBUIDOR":
            variablesConversionResponse = await getVariablesConversionMonedasDistribucion(user.distribucion._id);
            newValuesConversionMonedas = {
              monedaNacional: variablesConversionResponse.monedaNacional._id,
              monedaDeIntercambio: "643ffac9cda25824d0453911",
              factorDeConversion: variablesConversionResponse.factorDeConversion,
            };
            break;
          default:
            break;
        }
        setValues(newValuesConversionMonedas);
        obtenerMonedas();
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response.data.status === 500) {
          let newValuesConversionMonedas;
          switch (activeRole) {
            case "DIR_EDITORIAL":
              newValuesConversionMonedas = {
                ...INITIAL_VALUES_CONVERSION_MONEDAS,
                monedaNacional: user.editorial.dirFiscalPais
                  ? user.editorial.dirFiscalPais._id === "645d3b6caf6b724dc2f899ba"
                    ? "643ffac9cda25824d0453911"
                    : user.editorial.dirFiscalPais._id
                  : "6213a5815e1e2a212c63b6fc",
                monedaDeIntercambio: "643ffac9cda25824d0453911",
              };
              break;
            case "DISTRIBUIDOR":
              newValuesConversionMonedas = {
                ...INITIAL_VALUES_CONVERSION_MONEDAS,
                monedaNacional: user.distribucion.dirFiscalPais
                  ? user.distribucion.dirFiscalPais === "645d3b6caf6b724dc2f899ba"
                    ? "643ffac9cda25824d0453911"
                    : user.distribucion.dirFiscalPais
                  : "6213a5815e1e2a212c63b6fc",
                monedaDeIntercambio: "643ffac9cda25824d0453911",
              };
              break;
            default:
              break;
          }
          setValues(newValuesConversionMonedas);
          obtenerMonedas();
        }
        setLoading(false);
      }
    }
  }, [activeRole]);

  const incrementValue = (e) => {
    let valor = values.factorDeConversion === "" ? 0 : values.factorDeConversion;
    let factorConversionValor = (parseFloat(valor) + 0.01).toFixed(2);
    e.target.name = "factorDeConversion";
    e.target.value = factorConversionValor;
    e.target.type = "number";
    handleChange(e, "factorDeConversion");
  };

  const decrementValue = (e) => {
    let valor = values.factorDeConversion === "" ? 0 : values.factorDeConversion;
    let factorConversionValor = (parseFloat(valor) - 0.01).toFixed(2);
    e.target.name = "factorDeConversion";
    e.target.value = factorConversionValor;
    e.target.type = "number";
    handleChange(e, "factorDeConversion");
  };

  const conversionMonedasSubmit = async () => {
    try {
      if (Object.values(errors).length === 0) {
        let body = {
          monedaNacional: { _id: values.monedaNacional },
          monedaDeIntercambio: { _id: "643ffac9cda25824d0453911" },
          factorDeConversion: parseFloat(values.factorDeConversion),
        };
        switch (activeRole) {
          case "DIR_EDITORIAL":
            await putVariablesConversionMonedas(user.editorial._id, body);
            break;
          case "DISTRIBUIDOR":
            await putVariablesConversionMonedasDistribucion(user.distribucion._id, body);
            break;
          default:
            break;
        }
        toast.success("Variables de conversión de monedas guardadas correctamente.", alertConfiguration);
        navigate(-1);
      } else {
        setErrors(errors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(conversionMonedasSubmit);

  useEffect(() => {
    obtenerVariablesDeConversion();
  }, [obtenerVariablesDeConversion]);

  useEffect(() => {
    if (values?.factorDeConversion === "") setIsEnableSubmitButton(false);
  }, [values]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderConversionMonedas />
            <ContentContainer>
              <div className="flex flex-col w-full gap-y-6">
                <h2 className="font-bold">Primero debe realizar la configuración de las monedas</h2>
                <ConfiguracionMonedasForm
                  conversionMonedas={values}
                  setConversionMonedas={setValues}
                  handleChange={handleChange}
                  monedas={monedas}
                />
                <h2 className="mt-6 font-bold">
                  Ahora debe realizar la configuración del factor de conversión con el que va a operar su marketplace
                </h2>
                <FactorConversionForm
                  conversionMonedas={values}
                  handleChange={handleChange}
                  errors={errors}
                  monedas={monedas}
                  incrementValue={incrementValue}
                  decrementValue={decrementValue}
                />
              </div>
            </ContentContainer>
            <div className="flex justify-center gap-x-4 mt-6">
              <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => navigate(-1)} />
                <SubmitButton
                  text="Guardar"
                  disabled={formStatus === "submitting" || !isEnableSubmitButton}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ConversionMonedas;
