import { Route, Routes } from "react-router-dom";
import ReporteAvisosDetalles from "../../../components/Herramientas/reporte-avisos/reporte-avisos-detalles/ReporteAvisosDetalles";
import ReporteAvisosHome from "../../../components/Herramientas/reporte-avisos/ReporteAvisosHome";

const ReporteAvisosRoutes = () => {
  return (
    <Routes>
      <Route index element={<ReporteAvisosHome />} />
      <Route path=":avisoID" element={<ReporteAvisosDetalles />} />
    </Routes>
  );
};

export default ReporteAvisosRoutes;
