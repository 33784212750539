import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import ReactAudioPlayer from "react-audio-player";
import { Trans } from "react-i18next";

function SimplePlayer({ size, audio }) {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioValidado, setAudioValidado] = useState(true);

  const restart = () => {
    setAudioPlaying(false);
    setTimeout(() => {
      setAudioPlaying(true);
    }, 300);
  };

  return (
    <>
      {audioPlaying ? (
        <ReactAudioPlayer
          src={audio}
          //src={"zzz"}
          autoPlay
          controls
          onEnded={() => setAudioPlaying(false)}
          className="absolute -top-[9999px] -left-[9999px] opacity-0"
          onError={() => setAudioValidado(false)}
        />
      ) : (
        ""
      )}
      {audioValidado ? (
        <div
          className={`flex items-center justify-center pointer-events-auto border aspect-square p-8 rounded-full cursor-pointer hover:brightness-110 ${size}`}
          onClick={() =>
            audioPlaying ? restart() : setAudioPlaying(!audioPlaying)
          }
        >
          <FontAwesomeIcon
            color={"#1c7ccc"}
            icon={faVolumeHigh}
          ></FontAwesomeIcon>
        </div>
      ) : (
        <div className="text-center font-bold text-nimbusLight !text-base p-2 max-w-[9rem] bg-white rounded-lg mx-auto m-4">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
    </>
  );
}

export default SimplePlayer;
