import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";

const CounterTable = () => {
  const { usersAddedFiltered } = useListaDifusionDetalles();
  
  return (
    <div className="flex gap-x-5 h-full items-center justify-center sm:justify-start">
      <div className="flex flex-wrap">
        <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
          {usersAddedFiltered?.length ?? 0}
        </p>
        <p className="text-black">Destinatarios</p>
      </div>
    </div>
  );
};

export default CounterTable;
