import React, { useMemo } from "react";
import ProductoImage from "../../../../assets/img/producto.png";
import PaqueteImage from "../../../../assets/img/paquetesMarketplace.png";

const ResumenCompraProducto = ({ producto, esPaquete }) => {
  const { cantidad, estatus, precio, imagen, carrusel, nombre, total, _id } = useMemo(() => {
    if (esPaquete) {
      let paqueteInfo = {
        cantidad: producto.cantidad,
        precio: producto.paquete.precioVenta,
        estatus: producto.paquete.paquete.estatus,
        imagen: producto.paquete.paquete.imagen,
        carrusel: producto.paquete.paquete.carrusel,
        nombre: producto.paquete.paquete.nombre,
        total: producto.cantidad * producto.paquete.precioVenta,
        _id: producto._id,
      };

      return paqueteInfo;
    }

    let productoInfo = {
      cantidad: producto.cantidad,
      precio: producto.producto.precioVenta,
      estatus: producto.producto.producto.estatus,
      imagen: producto.producto.producto.imagen,
      carrusel: producto.producto.producto.carrusel,
      nombre: producto.producto.producto.nombre,
      total: producto.cantidad * producto.producto.precioVenta,
      _id: producto._id,
    };

    return productoInfo;
  }, [producto]);

  return (
    <tr className={`text-left bg-white select-none h-24`}>
      <td>
        <img
          className="h-12 w-12 object-contain ml-4"
          src={imagen === "" ? (carrusel.length > 0 ? carrusel[0] : (esPaquete ? PaqueteImage : ProductoImage)) : imagen}
          alt=""
        />
      </td>
      <td>
        <p className="ml-4">{nombre}</p>
        {producto.variacion && (
          <span>
            {producto.variacion.seleccionValores.map((valorSeleccionado) => (
              <p key={valorSeleccionado._id} className="ml-4 mr-2 text-xs text-gray-400">
                {valorSeleccionado.variable.variable}: {valorSeleccionado.valor.valor}
              </p>
            ))}
          </span>
        )}
      </td>
      <td>
        <p className="ml-4">x{cantidad}</p>
      </td>
    </tr>
  );
};

export default ResumenCompraProducto;
