import React, { useState } from "react";
import { Trans } from "react-i18next";
import VideoWrapper from "../VideoWrapper";
function VideoValidado({ src, className, onClick }) {
  const [validada, setValidada] = useState(true);
  return (
    <>
      {validada ? (
        <VideoWrapper aditionalStyles={className}>
          <video
            src={typeof src === "string" ? src : URL.createObjectURL(src)}
            className={className}
            onClick={onClick}
            onError={() => setValidada(false)}
            controls
            autoPlay
            controlsList="nodownload"
            crossOrigin="anonymous"
          ></video>
        </VideoWrapper>
      ) : (
        <div className="text-center font-bold text-nimbusLight !text-base p-2 max-w-[9rem] bg-white rounded-lg mx-auto m-4">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
    </>
  );
}

VideoValidado.defaultProps = {
  src: "",
  className: "",
  onClick: undefined,
};

export default VideoValidado;
