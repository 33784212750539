import React from 'react';
import InputSelect from '../../../fragments/Inputs/InputSelect';
import InputText from '../../../fragments/Inputs/InputText';
import { REGIMEN_FISCAL, USO_FACTURA } from '../../../utils/facturacionUtils';
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";

const GenerarFacturaForm = ({
  factura,
  errors,
  handleChange,
  setFactura,
  handleUseData,
  useData,
  compras,
}) => {
  let { compra, email, nombreCompleto, cp, rfc, regimenFiscal, usoFactura } =
    factura;

  return (
    <div className='flex flex-col items-center px-5'>
      <div className='flex flex-col w-full text-left mb-6 relative'>
        <InputSelect
          name={'compra'}
          label={'Id de la compra'}
          placeholder={'una compra'}
          options={compras}
          value={compra}
          onChange={(e) => handleChange(e, 'compra')}
          disableFirstOption
          id={'compra'}
          styles='!w-full'
        />
        <InfoIcon
          className='absolute top-1 left-32 cursor-pointer'
          title='Los id de las compras mostradas serán de aquellas compras que no hayan pasado un lapso mayor a 30 días y no se haya generado una factura de la misma'
        />
      </div>
      <div className='flex w-full text-left mb-6 gap-3 flex-row align-middle'>
        <div>
          <input
            type='checkbox'
            name={`useData`}
            id={`useData`}
            className='rounded-md border-2 border-gray-300 my-0 '
            checked={useData}
            onChange={(e) => handleUseData(e)}
          />
        </div>
        <label htmlFor={`useData`} className='cursor-pointer'>
          <p className='text-md 2xl:text-base text-left'>
            Deseo usar mis datos de facturación configurados en el perfil
          </p>
        </label>
      </div>
      <div className='flex flex-col w-full text-left mb-6'>
        <InputText
          id={'email'}
          name={'email'}
          label={'Correo electrónico'}
          placeholder={'Ingrese un correo electrónico'}
          value={email}
          onChange={(e) => handleChange(e, 'email')}
          error={errors['email']}
          styles='!w-full'
        />
      </div>
      <div className='flex flex-col w-full text-left mb-6'>
        <InputText
          id={'rfc'}
          name={'rfc'}
          label={'RFC'}
          placeholder={'Ingrese su RFC'}
          value={rfc}
          onChange={(e) => handleChange(e, 'rfc')}
          error={errors['rfc']}
          styles='!w-full'
        />
      </div>
      <div className='flex flex-col w-full text-left mb-6'>
        <InputText
          id={'nombreCompleto'}
          name={'nombreCompleto'}
          label={'Nombre completo'}
          placeholder={'Ingrese su nombre completo'}
          value={nombreCompleto}
          onChange={(e) => handleChange(e, 'nombreCompletoFactura')}
          error={errors['nombreCompleto']}
          styles='!w-full'
        />
      </div>
      <div className='flex flex-col w-full text-left mb-6'>
        <InputText
          id={'cp'}
          name={'cp'}
          label={'Código postal'}
          placeholder={'Ingrese un código postal'}
          value={cp}
          onChange={(e) => handleChange(e, 'cp')}
          error={errors['cp']}
          styles='!w-full'
        />
      </div>
      <div className='flex flex-col w-full text-left mb-6'>
        <InputSelect
          name={'regimenFiscal'}
          label={'Régimen fiscal'}
          placeholder={'un régimen fiscal'}
          options={REGIMEN_FISCAL}
          id={'regimenFiscal'}
          value={regimenFiscal}
          onChange={(e) => handleChange(e, 'regimenFiscal')}
          disableFirstOption
          styles='!w-full'
        />
      </div>
      <div className='flex flex-col w-full text-left mb-6'>
        <InputSelect
          name={'usoFactura'}
          label={'Uso de la factura'}
          placeholder={'un uso de la factura'}
          options={USO_FACTURA}
          id={'usoFactura'}
          value={usoFactura}
          onChange={(e) => handleChange(e, 'usoFactura')}
          disableFirstOption
          styles='!w-full'
        />
      </div>
    </div>
  );
};

export default GenerarFacturaForm;
