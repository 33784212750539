import React from "react";
import { Route, Routes } from "react-router-dom";
import ConfiguracionMarketplace from "../../components/comercial/ConfiguracionMarketplace/ConfiguracionMarketplace";
import CuentaBancaria from "../../components/comercial/ConfiguracionMarketplace/CuentaBancaria/CuentaBancaria";
import ConversionMonedas from "../../components/comercial/ConfiguracionMarketplace/ConversionMonedas/ConversionMonedas";
import DatosEnvio from "../../components/comercial/ConfiguracionMarketplace/DatosEnvio/DatosEnvio";
import CuentasComercialesRoutes from "./CuentasComerciales/CuentasComercialesRoutes";
import GestionProductosRoutes from "./Productos/GestionProductosRoutes";
import TiendaRoutes from "./Tienda/TiendaRoutes";
import MisFavoritos from "../../components/comercial/MisFavoritos/MisFavoritos";
import MisCompras from "../../components/comercial/MisCompras/MisCompras";
import AsignarArticulos from "../../components/comercial/AsignarArticulos/AsignarArticulos";
import AsignarArticulosRoutes from "./AsignarArticulos/AsignarArticulosRoutes";
import MiPaqueteEscolar from "../../components/comercial/MiPaqueteEscolar/MiPaqueteEscolar";
import PaquetesAsignados from "../../components/comercial/MiPaqueteEscolar/PaquetesAsignados/PaquetesAsignados";
import Facturacion from "../../components/comercial/Facturacion/Facturacion";
import MisVentas from "../../components/comercial/MisVentas/MisVentas";
import VentaInfo from "../../components/comercial/MisVentas/VentaInfo";
import CompraDetalle from "../../components/comercial/MisCompras/CompraDetalle";
import { PaisesProvider } from "../../contexts/PaisesContext";

const ComercialRoutes = () => {
  return (
    <PaisesProvider>
      <Routes>
        <Route index path="configuracion" element={<ConfiguracionMarketplace />} />
        <Route path="cuentaBancaria/:estructuraId" element={<CuentaBancaria />} />
        <Route path="conversionMonedas" element={<ConversionMonedas />} />
        <Route path="datosEnvio/:estructuraId" element={<DatosEnvio />} />
        <Route path="/cuentasComerciales/*" element={<CuentasComercialesRoutes/>}/>
        <Route path="/gestionProductos/*" element={<GestionProductosRoutes/>}/>
        <Route path="/tienda/*" element={<TiendaRoutes/>}/>
        <Route path="/misFavoritos/:categoria" element={<MisFavoritos />} />
        <Route path="/misCompras" element={<MisCompras />} />
        <Route path="/misCompras/:compraId" element={<CompraDetalle />} />
        <Route path="/asignarArticulos" element={<AsignarArticulos />} />
        <Route path="/asignarArticulos/:estructuraId" element={<AsignarArticulosRoutes />} />
        <Route path="/miPaqueteEscolar" element={<MiPaqueteEscolar />} />
        <Route path="/paquetesAsignados" element={<PaquetesAsignados />} />
        <Route path="/facturacion" element={<Facturacion />} />
        <Route path="/misVentas" element={<MisVentas />} />
        <Route path="/misVentas/:ventaId" element={<VentaInfo />} />

      </Routes>
    </PaisesProvider>
  );
};

export default ComercialRoutes;
