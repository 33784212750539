import React from "react";
import { Disclosure } from "@headlessui/react";
import ArrowUp from "../../../../assets/icons/arrowDownSimple.svg";
import ArrowDown from "../../../../assets/icons/arrowUpSimple.svg";
import DOMPurify from "dompurify";
import ProductosPaqueteTable from "./ProductosPaqueteTable";

const ProductoEspecificaciones = ({ descripcion = "", caracteristicas = "", productos = [], esPaquete }) => {
  const sanitizedDescripcion = DOMPurify.sanitize(descripcion);

  const DisclosureSection = ({ title, content }) => (
    <Disclosure as="div" className="border-t border-gray-300 py-6" defaultOpen={true}>
      {({ open }) => (
        <>
          <h3 className="-my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between py-2 text-sm">
              <div className="flex items-center gap-2">
                <span className="font-bold">{title}</span>
              </div>
              <span className="ml-6 flex items-center">
                <img className="ml-4" src={open ? ArrowDown : ArrowUp} alt="" />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">{content}</div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );

  return (
    <div className="w-full">
      <DisclosureSection
        title={`Descripción del ${esPaquete ? "paquete" : "producto"}:`}
        content={<div className="visor" dangerouslySetInnerHTML={{ __html: sanitizedDescripcion }} />}
      />
    </div>
  );
};

export default ProductoEspecificaciones;
