import React from 'react';
import SubHeader from '../../../fragments/SubHeader';
import { useNavigate } from 'react-router-dom';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';

const SubHeaderFormadorDetalles = ({ ver, editar }) => {
  const navigate = useNavigate();
  return (
    <div className='flex align-middle justify-between items-center bg-white'>
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={'Autores /'} onClick={() => navigate(-1)} />
          <BreadCrumb
            route={
              ver
                ? 'Ver autor'
                : editar
                ? 'Editar autor'
                : 'Agregar autor'
            }
            active
          />
        </BreadCrumbContainer>
      </SubHeader>
    </div>
  );
};

export default SubHeaderFormadorDetalles;
