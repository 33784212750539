import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';
import AddButton from '../../../fragments/Buttons/AddButton';

const SubHeaderCategoriaNotificaciones = () => {
  const navigate = useNavigate();
  return (
    <div className='flex align-middle justify-between items-center bg-white'>
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={'Inicio /'} onClick={() => navigate(`/home`)} />
          <BreadCrumb route={'Herramientas /'} onClick={() => navigate(-1)} />
          <BreadCrumb route={'Categorías de notificaciones'} active />
        </BreadCrumbContainer>
      </SubHeader>
      <div className='py-5 px-10'>
        <AddButton
          text='Agregar categoría'
          onClick={() => navigate('/herramientas/categoriasNotificaciones/agregarCategoria')}
        />
      </div>
    </div>
  );
};

export default SubHeaderCategoriaNotificaciones;
