import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import SubHeaderCuentaBancaria from "./SubHeaderCuentaBancaria";
import CuentaBancariaForm from "./CuentaBancariaForm";
import ContentContainer from "../../../fragments/ContentContainer";
import { ReactComponent as AddIcon } from "../../../../assets/icons//addblue.svg";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import useValidateFormArray from "../../../hooks/useValidateFormArray";
import useFormSubmit from "../../../hooks/useFormSubmit";
import {
  getCuentasBancariasEditorial,
  postCuentasBancariasEditorial,
} from "../../../services/Api/MarketPlace/Editoriales/CuentasBancarias";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { UserContext } from "../../../services/user";
import {
  getCuentasBancariasDistribucion,
  postCuentasBancariasDistribucion,
} from "../../../services/Api/MarketPlace/Distribuciones/CuentasBancarias";
import {
  getCuentasBancariasCorporativo,
  postCuentasBancariasCorporativo,
} from "../../../services/Api/MarketPlace/Corporativos/CuentasBancarias";
import {
  getCuentasBancariasPlantel,
  postCuentasBancariasPlantel,
} from "../../../services/Api/MarketPlace/Planteles/CuentasBancarias";

const INITIAL_VALUES_CUENTA_BANCARIA = {
  esExtranjera: false,
  esPrincipal: true,
  swiftAba: "",
  pais: {
    _id: "6213a5815e1e2a212c63b6fc",
  },
  banco: "",
  nombreBeneficiario: "",
  numeroCuenta: "",
};

const CuentaBancaria = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const { activeRole } = useContext(UserContext);

  const obtenerCuentasBancarias = useCallback(async () => {
    try {
      let cuentasBancariasResponse;
      switch (activeRole) {
        case "DIR_EDITORIAL":
          cuentasBancariasResponse = await getCuentasBancariasEditorial(params.estructuraId);
          break;
        case "DISTRIBUIDOR":
          cuentasBancariasResponse = await getCuentasBancariasDistribucion(params.estructuraId);
          break;
        case "DIR_CORPORATIVO":
          cuentasBancariasResponse = await getCuentasBancariasCorporativo(params.estructuraId);
          break;
        case "DIR_PLANTEL":
        case "ADMIN_OPERATIVO":
          cuentasBancariasResponse = await getCuentasBancariasPlantel(params.estructuraId);
          break;
        default:
          break;
      }
      if (cuentasBancariasResponse.length > 0) {
        const initialErrors = cuentasBancariasResponse.map(() => ({}));
        setErrors(initialErrors);
        for (let i = 0; i < cuentasBancariasResponse.length; i++) {
          delete cuentasBancariasResponse[i].editorial;
          delete cuentasBancariasResponse[i].distribucion;
          delete cuentasBancariasResponse[i].corporativo;
          delete cuentasBancariasResponse[i].plantel;
        }
        setValues(cuentasBancariasResponse);
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }, [activeRole]);

  const handleDeleteCuenta = (event, index) => {
    event.preventDefault();
    if (index === 0) {
      setValues([{ ...values[1], esPrincipal: true }]);
      setErrors([errors[1]]);
    } else {
      setValues([{ ...values[0], esPrincipal: true }]);
      setErrors([errors[0]]);
    }
    setIsEnableSubmitButton(true)
  };

  const handleAgregarCuenta = (event) => {
    event.preventDefault();
    setValues([...values, { ...INITIAL_VALUES_CUENTA_BANCARIA, esPrincipal: false }]);
    setErrors([...errors, {}]);
  };

  const cuentasBancariasSubmit = async () => {
    let hasErrors = errors.some((error) => Object.values(error).length > 0);
    if (!hasErrors) {
      try {
        switch (activeRole) {
          case "DIR_EDITORIAL":
            await postCuentasBancariasEditorial(params.estructuraId, values);
            break;
          case "DISTRIBUIDOR":
            await postCuentasBancariasDistribucion(params.estructuraId, values);
            break;
          case "DIR_CORPORATIVO":
            await postCuentasBancariasCorporativo(params.estructuraId, values);
            break;
          case "DIR_PLANTEL":
          case "ADMIN_OPERATIVO":
            await postCuentasBancariasPlantel(params.estructuraId, values);
            break;
          default:
            break;
        }
        if (values.length > 1) {
          toast.success("Cuentas bancarias guardadas correctamente.", alertConfiguration);
        } else {
          toast.success("Cuenta bancaria guardada correctamente.", alertConfiguration);
        }
        navigate(-1);
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.status === 500)
          toast.error("No puede tener el mismo número de cuenta en ambas cuentas bancarias", alertConfiguration)
      }
    } else {
      setErrors(errors);
    }
  };

  let { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues, setErrors } =
    useValidateFormArray([INITIAL_VALUES_CUENTA_BANCARIA]);

  const [formStatus, handleSubmit] = useFormSubmit(cuentasBancariasSubmit);

  useEffect(() => {
    let hasEmptyFields = values.some((cuentaBancaria) =>
      Object.keys(cuentaBancaria).some((key) => {
        if (key === "swiftAba" && cuentaBancaria[key] === "" && !cuentaBancaria["esExtranjera"]) {
          return false;
        }

        return cuentaBancaria[key] === "";
      })
    );

    if(hasEmptyFields) setIsEnableSubmitButton(false);
  }, [values]);

  useEffect(() => {
    obtenerCuentasBancarias();
  }, [obtenerCuentasBancarias]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderCuentaBancaria />
            <ContentContainer>
              <div className="flex flex-col w-full space-y-6">
                {values.map((_, index) => (
                  <CuentaBancariaForm
                    key={index}
                    index={index}
                    handleDeleteCuenta={handleDeleteCuenta}
                    cuentaBancaria={values}
                    handleChange={handleChange}
                    errors={errors}
                  />
                ))}
                {values.length < 2 && (
                  <button
                    className="h-20 text-nimbusDark font-bold bg-white rounded-lg border-2 flex items-center justify-center"
                    onClick={handleAgregarCuenta}
                  >
                    <AddIcon className="mr-4" />
                    Agregar nueva cuenta
                  </button>
                )}
              </div>
            </ContentContainer>
            <div className="flex justify-center gap-x-4 mt-6">
              <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => navigate(-1)} />
                <SubmitButton
                  text="Guardar"
                  disabled={formStatus === "submitting" || !isEnableSubmitButton}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CuentaBancaria;
