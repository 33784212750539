import React from 'react';
import CategoriasNotificacionesRow from './CategoriasNotificacionesRow';

const CategoriasNotificacionesTable = ({
  categoriasNotificaciones,
  obtenerCategoriasNotificaciones,
}) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th className='text-center'>Icono</th>
            <th className='text-left'>Nombre de la categoría</th>
            <th className='text-center'>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {categoriasNotificaciones.map((categoriaNotificacion) => (
            <CategoriasNotificacionesRow
              key={categoriaNotificacion._id}
              categoriaNotificacion={categoriaNotificacion}
              obtenerCategoriasNotificaciones={obtenerCategoriasNotificaciones}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoriasNotificacionesTable;
