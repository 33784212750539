import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PaisDetalles from '../../../components/gestion/Países/PaisDetalles';
import PaisesHome from '../../../components/gestion/Países/PaisesHome';
import { PaisesProvider } from '../../../contexts/PaisesContext';

const PaisesRoutes = () => {
  return (
    <PaisesProvider>
      <Routes>
        <Route index element={<PaisesHome />} />
        <Route path='agregarPais' element={<PaisDetalles />} />
        <Route path=':paisId/ver' element={<PaisDetalles ver />} />
        <Route path=':paisId/editar' element={<PaisDetalles editar />} />
      </Routes>
    </PaisesProvider>
  );
};

export default PaisesRoutes;
