import React from "react";
import defaultBgImg from "../../../assets/img/defaultBgImg.svg";

function CustomInput({
  imagen,
  imageValue,
  handleImage,
  text,
  textValue,
  handleText,
  placeholder = "",
  disabled = false,
  extensions = ".jpg",
  error
}) {
  return (
    <>
      <div className="flex w-full">
        {imagen ? (
          <div className="relative w-20 h-12 object-cover mr-4">
            <img
              src={
                !imageValue
                  ? defaultBgImg
                  : typeof imageValue === "object"
                  ? URL.createObjectURL(imageValue)
                  : imageValue
              }
              alt=""
              className={
                imageValue
                  ? "w-full h-full rounded object-cover"
                  : "w-full h-full rounded object-contain bg-[#e7edef] p-3 "
              }
            />
            <input
              name="imagen"
              type="file"
              onChange={handleImage}
              className={`absolute top-0 left-0 right-0 bottom-0 w-full h-full opacity-0 ${disabled ? "cursor-default" : "cursor-pointer"}`}
              id="img-input"
              title=""
              disabled={disabled}
              accept={extensions}
            />
          </div>
        ) : (
          ""
        )}
        {text ? (
          <div className="flex flex-col w-full relative justify-start">
          <input
            name="texto"
            placeholder={placeholder}
            value={textValue}
            onChange={handleText}
            className="border rounded py-3 mr-4 pl-4 flex-1 !bg-white min-w-[50%]"
            required={textValue === "" ? true : false}
            maxLength={300}
            disabled={disabled}
          />
          <p className="font-thin text-xs text-red-400 mr-4">{error}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CustomInput;
