import React from "react";
import Modal from "react-modal";
import { ReactComponent as Nimbus } from "../../assets/img/logo.svg";

const NimbusModal = ({ isOpen, children, styles ={}, showLogo = true }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      zIndex: 99,
    },
    ...styles
  };

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className="grid text-center gap-y-4">
        {showLogo && (<Nimbus className="justify-self-center" />)}        
        {children}
      </div>
    </Modal>
  );
};

export default NimbusModal;
