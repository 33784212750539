import React from 'react'
import FormadorHome from '../../../components/Herramientas/Formador/FormadorHome'
import FormadorDetalles from '../../../components/Herramientas/Formador/FormadorDetalles'
import { Route, Routes } from 'react-router-dom'

const FormadoresRoutes = () => {
  return (
    <Routes>
        <Route index element={<FormadorHome />} />
        <Route path='agregarAutor' element={<FormadorDetalles />} />
        <Route path=':formadorId/ver' element={<FormadorDetalles ver />} />
        <Route path=':formadorId/editar' element={<FormadorDetalles editar />} />
    </Routes>
  )
}

export default FormadoresRoutes