import React, { useCallback, useContext, useEffect, useState } from "react";
import ProductosTable from "./fragments/ProductosTable";
import { obtenerUserEstructuraInfo } from "../../../../utils/userUtils";
import { getProductosEstructura } from "../../../../services/Api/MarketPlace/Productos";
import { UserContext } from "../../../../services/user";
import { usePagination } from "../../../../hooks/usePagination";
import ProductosAgregadosFilter from "./fragments/ProductosAgregadosFilter";
import { PaqueteProductosContext } from "../../../../../contexts/PaqueteProductosContext";
import NoDataMessage from "../../../../fragments/NoDataMessage";
import TablePagination from "../../../../fragments/TablePagination";
import { MESSAGES } from "../../../../utils/messagesUtils";
import { setErroresPaquetes } from "../../../../../reducers/paquetesProductos/paquetesProductosAction";

const PaqueteContenidoForm = ({ ver, tipoDeMoneda, setIsEnableSubmitButton, cantidadDeProductos }) => {
  const { user, activeRole } = useContext(UserContext);
  const { productos, dispatch, errores } = useContext(PaqueteProductosContext);
  const [productosFiltro, setProductosFiltro] = useState(productos);

  useEffect(() => {
    setProductosFiltro(productos);
    let packageErrors = {};
    if (cantidadDeProductos === 1) {
      packageErrors = { productos: "Necesita agregar al menos un producto más o aumentar la cantidad del producto ya existente" };
      dispatch(setErroresPaquetes(packageErrors));
    } else {
      dispatch(setErroresPaquetes(packageErrors));
    }
  }, [productos, cantidadDeProductos]);
  return (
    <div>
      <div className="flex items-center my-6">
        <h2 className="text-black text-lg basis-72 sm:basis-56 mr-2">Productos del paquete</h2>
        <span className="w-full h-[2px] bg-divider"></span>
      </div>
      <div className="mx-0 lg:mx-8">
        <p>
          Debe seleccionar los productos que desea agregar al paquete, y configurar la información extra que sea
          necesaria sobre cada uno de ellos.
        </p>

        <ProductosAgregadosFilter
          productos={productos}
          productosFiltro={productosFiltro}
          setProductosFiltro={setProductosFiltro}
          ver={ver}
          setIsEnableSubmitButton={setIsEnableSubmitButton}
        />
        <ProductosTable productos={productosFiltro} tipoDeMoneda={tipoDeMoneda} ver={ver} setIsEnableSubmitButton={setIsEnableSubmitButton}/>
        {productos.length !== 0 && productosFiltro.length === 0 && (
          <NoDataMessage message={MESSAGES.MESSAGE_FILTER_PRODUCTOS_TABLE} />
        )}
        {productos.length === 0 ? <NoDataMessage message={MESSAGES.MESSAGE_PAQUETE_SIN_PRODUCTOS_TABLE} /> : null}
        <p className="font-thin text-xs text-red-400 mt-2">{errores["productos"]}</p>
      </div>
    </div>
  );
};

export default PaqueteContenidoForm;
