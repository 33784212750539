import { CONTENIDO_BIBLIOTECA_ACTION_TYPES } from './contenidoBibliotecaTypes';

export const CONTENIDO_BIBLIOTECA_INITIAL_STATE = {
  mostrarContenido: {
    carpetaRaiz: '',
    subcarpeta: '',
  },
};

export const contenidoBibliotecaReducer = (state = CONTENIDO_BIBLIOTECA_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONTENIDO_BIBLIOTECA_ACTION_TYPES.FETCH_MOSTRAR_CONTENIDO_START:

      return { ...state, mostrarContenido: payload };
    default:
      return state;
  }
}