//Regresa total ingresado del pais origen al tipo de moneda del pais destino
export const convertirMoneda = (
  total,
  conversionMonedas,
) => {
  const { paisOrigenId, paisDestinoId, paises, estructura = "" } = conversionMonedas;
  if (
    paisOrigenId === paisDestinoId ||
    estructura === 'editorial' ||
    paisOrigenId === undefined ||
    paisDestinoId === '' ||
    paisDestinoId === undefined ||
    paisDestinoId === '' ||
    paises.length === 0
  )
    return total;
  const paisOrigen = paises.find((pais) => pais._id === paisOrigenId);
  const paisDestino = paises.find((pais) => pais._id === paisDestinoId);
  const equivalenciaDolar = {
    paisOrigen: Object.keys(paisOrigen.configuracionEquivalencia).length > 0 ? paisOrigen.configuracionEquivalencia.equivalenciaDolar : paisOrigen.equivalenciaDolar,
    paisDestino: Object.keys(paisDestino.configuracionEquivalencia).length > 0 ? paisDestino.configuracionEquivalencia.equivalenciaDolar : paisDestino.equivalenciaDolar,
  };

  return (
    (parseFloat(total) / equivalenciaDolar.paisOrigen) *
    equivalenciaDolar.paisDestino
  );
};

//Regresa total ingresado del pais destino al tipo de moneda del pais de origen
export const convertirMonedaOrigen = (
  total,
  conversionMonedas,
) => {
  const { paisOrigenId, paisDestinoId, paises, estructura = "" } = conversionMonedas;
  if (
    paisOrigenId === paisDestinoId ||
    estructura === 'editorial' ||
    paisOrigenId === undefined ||
    paisDestinoId === '' ||
    paisDestinoId === undefined ||
    paisDestinoId === ''
  )
    return total;
  const paisDestino = paises.find((pais) => pais._id === paisDestinoId);
  return (
    parseFloat(total) / paisDestino.equivalenciaDolar
  );
};