import React, {useState, useEffect} from 'react'
import PantallaInicio from './PantallaInicio'
import PantallaFin from './PantallaFin'
/* import Cronometro from './Cronometro'; */
import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { formatPostDate } from '../../../../utils/dateFunctions';
import { postCalificacion, postEvaluacion } from '../../../../services/api';
import { useContext } from 'react';
import { UserContext } from '../../../../services/user';
import ReactLoading from "react-loading"

export const ActividadContext = createContext()

function ActWrapper({children, title, description, esUMA, isDone, contenido, umaBloque, tipo, modoPrevisualizar, caratula, reloadActividades, esEvaluacion, guardarCalificacion, esGuardada, lang}) {
    const params = useParams()
    const {user} = useContext(UserContext)
    const [iniciado, setIniciado] = useState(false);
    const [finalizado, setFinalizado] = useState(false)
    const [calificacion,setCalificacion] = useState(0);
    const [calificado,setCalificado] = useState()
    const [latestResult,setLatestResult] = useState(isDone)
    const [loading, setLoading] = useState(false)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)
    const [additionalData, setAdditionalData] = useState([])

    const cerrarSinCalificar = () =>{
        setFinalizado(false)
        setIniciado(false)
        setCalificacion(0)
        setCalificado()
        setLoading(false)
    }

    const postCalificacionActividad = (calificacion) =>{
      //fin a cronómetro
      const timeNow = Math.floor(new Date().getTime() / 1000);
      setEndTime(timeNow)
      //maneja actividades que no guardan calificaciones
      if(modoPrevisualizar){
        if(calificacion === null){
          cerrarSinCalificar()  
        }else{
          setFinalizado(true)
          const calificacionTemporal = {
            puntuacionUltimoIntento:calificacion,
          }
          setLatestResult(calificacionTemporal)
        }
      }
      //actividades que sí guardan calificaciones
      if(!modoPrevisualizar){
        if(calificacion === null){
          cerrarSinCalificar()
        }else{
          setLoading(true)
          setCalificacion(calificacion)
          const body={
            materia:params.subjectId,
            libroContenido:contenido,
            umaBloque:umaBloque,
            fecha:Date.now(),
            puntuacion:Math.floor(calificacion/10),
            calificacion:calificacion,
          }
          if(esUMA){
            let alumnoId = user?.escuela?.campus?.grupos?.find((grupo)=>grupo._id === params.groupId).alumnos.find((alumno) => alumno.usuario === user._id)._id;
            postCalificacion(user.escuela._id,user.escuela.campus._id,params.groupId,alumnoId,body).then(response=>{
              setFinalizado(true)
              setCalificado(response.data)
              setLatestResult(response.data.calificacion)
              reloadActividades()
            }).catch(error=>{
              console.log('error',error.response)
            })
          }else{
              setFinalizado(true)
          }
        }
      }
    }

    const intentarDeNuevo = () =>{
      setFinalizado(false)
      setIniciado(true)
      setCalificacion(0)
      setCalificado()
      setLoading(false)
      const timeNow = Math.floor(new Date().getTime() / 1000);
      setStartTime(timeNow)
      setEndTime(0)
    }

    useEffect(()=>{
      if(iniciado){
        setTimeout(()=>{
          setLoading(false)
          const timeNow = Math.floor(new Date().getTime() / 1000);
          setStartTime(timeNow)
          setEndTime(0)
        },1000)
      }
    },[iniciado])

    useEffect(()=>{
      if(!caratula)setIniciado(true)
    },[])

    const getAdditionalData = (data) =>{
      setAdditionalData(data)
    }

    useEffect(()=>{
      if(esGuardada){
        setFinalizado(true)
        setIniciado(true)
      }
    },[])


  return (
    <>
        {iniciado ?
        <div className='whitespace-pre-wrap shadow-lg w-full border rounded-lg bg-[#e9f8ff54] overflow-hidden my-5 md:my-10 min-h-[400px] md:min-h-[700px] relative flex flex-col items-center justify-center pt-5 md:pt-0 break-words'>
            {finalizado  ? 
            <div className='h-full w-full min-h-[400px] md:min-h-[600px]'>
              <PantallaFin 
                intentarDeNuevo={intentarDeNuevo} 
                calificado={calificado} 
                time={startTime && endTime ? (endTime - startTime) * 10 : 0} 
                calificacion={calificacion}
                isDone={latestResult}
                modoPrevisualizar={modoPrevisualizar}
                additionalActData={additionalData}
                esEvaluacion={esEvaluacion}
                lang={lang}
              />
            </div>
             :
              <ActividadContext.Provider value={{postCalificacionActividad, getAdditionalData}}>
                {!loading && children}
                {loading &&<ReactLoading type="spin" color="#1A3D82" height={'5%'} width={'5%'} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>}
              </ActividadContext.Provider>
             }
        </div>
         : 
         <div className='my-10'>
            <PantallaInicio 
              title={title} 
              description={description} 
              isDone={latestResult} 
              start={setIniciado} 
              tipo={tipo}
              lang={lang}
              />
         </div>
         }
    </>
  )
}

ActWrapper.defaultProps = {
  modoPrevisualizar:false,
  caratula:true,
  guardarCalificacion:undefined
}

export default ActWrapper