import React from "react";
import Modal from "react-modal";
import { ReactComponent as LogoModal } from "../../../assets/icons/logoModal.svg";
import { useEffect } from "react";

function ModalInfo({ isOpen, toggleModal, title, children, hideBtn = false }) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: "500px",
      maxHeight: "90vh",
      borderRadius: ".5rem",
      paddingTop: "2.5rem",
      paddingLeft: "2.5rem",
      paddingRight: "2.5rem",
      paddingBottom: "2.5rem",
    },
  };

  useEffect(() => {
    Modal.defaultStyles.overlay.backgroundColor = "rgb(84 84 84 / 75%)";
    Modal.defaultStyles.overlay.zIndex = "+99";
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      style={customStyles}
    >
      <div className="max-w-[968px] mx-auto">
        {title && (
          <>
            <div className="flex items-center justify-center">
              <LogoModal />
            </div>
            <p className="text-2xl text-nimbusDark text-center block w-full mt-2">
              {title}
            </p>
          </>
        )}
      </div>
      {children}
      {!hideBtn && (
        <button
          className="bg-nimbusDark font-semibold text-white p-2 w-28 rounded mx-auto block"
          onClick={toggleModal}
        >
          Cerrar
        </button>
      )}
    </Modal>
  );
}

export default ModalInfo;
