import { useState, useEffect, useContext } from "react";
import InputBuscar from "../../../../../fragments/Inputs/InputBuscar";
import { ReactComponent as Add } from "../../../../../../assets/icons/add.svg";
import AgregarProductosModal from "./AgregarProductosModal";
import { PaqueteProductosContext } from "../../../../../../contexts/PaqueteProductosContext";
import { setIsAddProductsModalOpen } from "../../../../../../reducers/paquetesProductos/paquetesProductosAction";

const FILTRO_PRODUCTOS_INITIAL_VALUES = {
  busqueda: "",
  origen: "",
  tipoProducto: "",
  estatus: "",
  categoria: "",
};

export default function ProductosAgregadosFilter({ productos, productosFiltro, setProductosFiltro, ver, setIsEnableSubmitButton }) {
  const [filtro, setFiltro] = useState(FILTRO_PRODUCTOS_INITIAL_VALUES);
  const { isAddProductsOpen, dispatch } = useContext(PaqueteProductosContext);

  const filterDataBusqueda = (productos, busqueda) => {
    if (busqueda === "") return productos;

    const newProductosFiltro = productos.filter(
      (producto) =>
        producto.producto.nombre.toLowerCase().includes(busqueda) ||
        producto.precioVenta.toString().includes(busqueda) ||
        producto.total.toString().includes(busqueda) ||
        producto.cantidad.toString().includes(busqueda) ||
        producto.producto._id.toLowerCase().startsWith(busqueda)
    );

    return newProductosFiltro;
  };

  useEffect(() => {
    let newProductosFiltro = filterDataBusqueda(productos, filtro.busqueda);
    setProductosFiltro(newProductosFiltro);
  }, [filtro, productos]);

  return (
    <>
      <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
        <div className="flex gap-x-5">
          <div className="flex flex-wrap items-center">
            <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
              {productosFiltro.length}
            </p>
            <p className="text-black">Productos añadidos</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
          <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
            <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value.toLowerCase() })} />
          </div>
          {!ver  && (
            <div
              className="flex px-2 gap-x-2 items-center justify-between bg-nimbusDark text-white p-2 rounded cursor-pointer w-fit self-end mt-3 lg:mt-0"
              onClick={() => dispatch(setIsAddProductsModalOpen(true))}
            >
              <Add />
              <p>Agregar productos</p>
            </div>
          )}
        </div>
      </div>
      <AgregarProductosModal
        isOpen={isAddProductsOpen}
        setIsOpen={(value) => dispatch(setIsAddProductsModalOpen(value))}
        setIsEnableSubmitButton={setIsEnableSubmitButton}
      />
    </>
  );
}
