import { getReporteAvisos } from "../../../services/Api/reporte-avisos";
import { MESSAGES } from "../../../utils/messagesUtils";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import { useParams } from "react-router-dom";
import alertConfiguration from "../../../utils/alertsUtils";
import LoadingScreen from "../../../fragments/LoadingScreen";
import NoDataMessage from "../../../fragments/NoDataMessage";
import ReporteAvisosDetallesFilter from "./fragments/ReporteAvisosDetallesFilter";
import ReporteAvisosDetallesTable from "./fragments/ReporteAvisosDetallesTable";
import SubHeaderReporteAvisosDetalles from "./fragments/SubHeaderReporteAvisosDetalles";
import TablePagination from "../../../fragments/TablePagination";

const ReporteAvisosDetalles = () => {
  const { avisoID } = useParams();
  const [report, setReport] = useState(null);
  const [destinatarios, setDestinatarios] = useState([]);
  const [destinatariosFiltro, setDestinatariosFiltro] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    destinatariosFiltro,
    10
  );

  useEffect(() => {
    getAvisoReportByID();
  }, []);

  const getAvisoReportByID = async () => {
    try {
      setIsLoading(true);

      const resp = await getReporteAvisos(avisoID);
      setReport(resp);
      setDestinatarios(resp.destinatarios);
      setDestinatariosFiltro(resp.destinatarios);
    } catch (error) {
      console.log("🚀 ~ getAvisoReportByID ~ error:", error);
      toast.error(error.message, alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
          <SubHeaderReporteAvisosDetalles title={report?.nombre} />
          <div
            className={`${
              destinatariosFiltro?.length !== 0 && "justify-between"
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <ReporteAvisosDetallesFilter
                read={report?.leidoPor}
                unread={report?.sinLeer}
                destinatarios={destinatarios}
                setDestinatariosFiltro={setDestinatariosFiltro}
              />
              <ReporteAvisosDetallesTable destinatarios={currentData()} />
            </div>
            <div>
              {destinatariosFiltro?.length === 0 ? (
                <NoDataMessage
                  message={MESSAGES.MESSAGES_REPORTE_AVISOS_DESTINATARIOS_TABLE}
                />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReporteAvisosDetalles;
