import AudioValidado from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/AudioValidado";

export default function Audio({ audio, pieDeFoto }) {
  return (
    <>
      {audio && audio.includes(".mp3") && (
        <div className="my-4 md:my-8 w-full">
          <div className="w-full flex justify-center">
            <AudioValidado src={audio} className="w-full" />
          </div>
          {pieDeFoto && pieDeFoto !== "" && (
            <div
              className="w-full mt-2 p-1"
              dangerouslySetInnerHTML={{ __html: pieDeFoto }}
            ></div>
          )}
        </div>
      )}
    </>
  );
}
