import React from "react";
import SubHeader from "../../fragments/SubHeader";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbContainer from "../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../fragments/BreadCrumb";
import { RUTAS_MAP } from "../../utils/tiendaUtils";

const SubHeaderTienda = () => {
  
  const navigate = useNavigate();
  const params = useParams();

  return (
    <SubHeader styles="bg-transparent !px-0 min-h-fit">
      <BreadCrumbContainer>
        <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} styles="text-black/70 !md:text-lg" />
        <BreadCrumb route={RUTAS_MAP[params.categoria]} styles="text-black !md:text-lg" active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderTienda;
