import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import OutButton from "../../../Botones/OutButton";

import { useLocation } from 'react-router-dom';

import "./style.css";
const BarraModificable = ({title,children}) => {
  const location = useLocation();
  const [url,setUrl] =useState([])

  useEffect(() => {
    let paths = location.pathname.split('/')
    setUrl(paths)
  },[location])



  return (
    <div className="bg-gray-50 h-screen">
            <div className='flex flex-row items-center justify-start bg-white h-18'>
                <div className='ml-4'>
                <OutButton  
                text={'Atrás'} url={'MarketPlace/Account/Home'}/>
                </div>
                <div className="flex mt-2">
                    <div className="sm:ml-2 md:ml-4 ">
                        <p className="font-sans font-medium text-base text-[#6E6D7A]">
                          {url.includes('MisCompras') ? 'Cuenta' :
                          url.includes('Favoritos') ? 'Cuenta' : 'Cuenta'}
                        </p>
                        <p className="font-sans text-xl text-nimbusDark font-bold">
                            {url.includes('MisCompras') ? 'Mis productos' :
                            (url.includes('Historial')) ? 'Historial de Pagos' :  
                            (url.includes('Favoritos')) ? 'Favoritos' : 'null'}
                        </p>
                    </div>
                </div>
            </div>
      <div className="bg-gray-50">{children}</div>
    </div>
  );
};

export default BarraModificable;
