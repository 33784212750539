import axios from "axios";

const config = () =>{
    const token = localStorage.getItem("token") || sessionStorage.getItem('token')
    return {headers:{Authorization: `Bearer ${token}`}}
}

const configDelete = (body) =>{
    const token = localStorage.getItem("token") || sessionStorage.getItem('token')
    return {headers:{Authorization: `Bearer ${token}`},data:body}
}

const configArchivo = (body) =>{
    const token = localStorage.getItem("token") || sessionStorage.getItem('token')
    return {headers:{'Content-Type':'multipart/form-data',Authorization:'Bearer '+token},data:body}
}

export const api = axios.create({
    baseURL:process.env.REACT_APP_API_URL
});

export const _get = async (endpoint) =>{
    const {data} = await api.get(endpoint,config())
    return data;
};

export const _post = async (endpoint, body) =>{
    const {data} = await api.post(endpoint, body ,config())
    return data
};

export const _postArchivo = async (endpoint, body) =>{
    const {data} = await api.post(endpoint, body ,configArchivo(body))
    return data
};

export const _put = async(endpoint, body) =>{
    const {data} = await api.put(endpoint, body, config())
    return data;
};

export const _delete = async (endpoint) =>{
    const {data} = await api.delete(endpoint, config())
    return data;
}

export const _deleteWBody = async (endpoint,body) =>{
    const {data} = await api.delete(endpoint, configDelete(body))
    return data;
}