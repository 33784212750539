import React from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";

const AplicarDescuentoModal = ({ isOpen, setIsOpen, aplicarDescuento }) => {
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Aplicar descuento</h1>
      <p className="w-96 px-4 sm:px-2">
        El descuento será aplicado sobre el precio de venta general, y se verá reflejado en el precio de asignación de
        los productos seleccionados. El precio de asignación podrá ser modificado de forma manual si así lo desea.
      </p>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea aplicar el descuento?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" onClick={aplicarDescuento} />
      </div>
    </NimbusModal>
  );
};

export default AplicarDescuentoModal;
