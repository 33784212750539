export const customEditorToolbar = [
  "fontFamily",
  "fontBackgroundColor",
  "fontColor",
  "fontSize",
  "|",
  "mediaEmbed",
  "imageInsert",
  "bold",
  "italic",
  "underline",
  "bulletedList",
  "numberedList",
  "indent",
  "outdent",
  "alignment",
  "|",
  "highlight",
  "specialCharacters",
  "strikeThrough",
  "subscript",
  "superscript",
  "removeFormat",
  "|",
  "undo",
  "redo",
  "insertTable",
  "blockQuote",
  "todoList",
  "horizontalLine",
  "codeBlock",
  "link",
  "code",
];

export const editorImageConfig = {
  styles: ["alignLeft", "alignCenter", "alignRight"],
  sizes: ["50%", "75%", "100%"],
  resizeOptions: [
    {
      name: "imageResize:original",
      label: "Original",
      value: null,
    },
    {
      name: "imageResize:50",
      label: "50%",
      value: "50",
    },
    {
      name: "imageResize:75",
      label: "75%",
      value: "75",
    },
  ],
  toolbar: [
    {
      name: "imageStyle:icons",
      title: "Centrar sin texto",
      items: ["imageStyle:alignBlockLeft", "imageStyle:alignCenter", "imageStyle:alignBlockRight"],
      defaultItem: "imageStyle:alignCenter",
    },
    {
      name: "imageStyle:iconss",
      title: "Centrar con texto",
      items: ["imageStyle:alignLeft", "imageStyle:alignRight"],
      defaultItem: "imageStyle:alignLeft",
    },

    "|",
    "imageResize",
    "|",
    "imageTextAlternative",
    "toggleImageCaption",
  ],
};

export const editorLinkConfig = {
  // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
  addTargetToExternalLinks: true,
};

export const listProperties = {
  properties: {
    styles: true,
    startIndex: true,
    reversed: true,
  },
};
