import React from 'react'
import "./style.css";
import { Link } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../../assets/icons/BackArrow.svg';
const OutButton = ({text,url}) => {
  return (
    <div className='btn__out'>
        <BackArrow className='mr-2 place-self-center' />
          <Link to={`/${url}`} className="text-base font-base">
              {text}
          </Link>
    </div>
  )
}

export default OutButton