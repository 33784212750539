import React, { Fragment, useContext, useEffect, useState } from 'react';
import SubHeaderAgregarPais from './fragments/SubHeaderAgregarPais';
import SubmitButton from '../../fragments/Buttons/SubmitButton';
import CancelButton from '../../fragments/Buttons/CancelButton';
import AgregarPaisForm from './fragments/AgregarPaisForm';
import { useNavigate, useParams } from 'react-router-dom';
import useValidateForm from '../../hooks/useValidateForm';
import useFormSubmit from '../../hooks/useFormSubmit';
import useUploadImage from '../../hooks/useUploadImage';
import { getPaisDetalle } from '../../services/api';
import { toast } from 'react-toastify';
import alertConfiguration from '../../utils/alertsUtils';
import { PaisesContext } from '../../../contexts/PaisesContext';
import { getPaisDetalleEditorial, postCrearEquivalencia, postPais, putEquivalencia, putPais } from '../../services/Api/Gestion/Paises';
import LoadingScreen from '../../fragments/LoadingScreen';
import { UserContext } from '../../services/user';

const INITIAL_VALUES_AGREGAR_PAIS = {
  nombrePais: '',
  nombreMoneda: '',
  abreviacionMoneda: '',
  simboloMoneda: '',
  valorMoneda: '',
  imagen: '',
};

const PaisDetalles = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { obtenerPaises, paises } = useContext(PaisesContext);
  const { activeRole, user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [puedeEditar, setPuedeEditar] = useState(false);

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    addError,
    areRequiredFieldsFulfilled,
    removeError,
  } = useValidateForm(INITIAL_VALUES_AGREGAR_PAIS);

  const paisSubmit = async () => {
    try {
      let paisBody = {
        nombre: values.nombrePais.substring(0, 1).toUpperCase() + values.nombrePais.substring(1, values.nombrePais.length),
        abreviatura: values.abreviacionMoneda,
        divisa: values.nombreMoneda.substring(0, 1).toUpperCase() + values.nombreMoneda.substring(1, values.nombreMoneda.length), 
        simbolo: values.simboloMoneda,
        equivalenciaDolar: parseFloat(values.valorMoneda),
      };
      if(props.editar){
        if(activeRole === 'ADMIN'){
          let respFiles = await verifyFiles(values, setValues);
          paisBody = {
            ...paisBody,
            bandera: respFiles.imagen,
          };
          await putPais(params.paisId, paisBody);
        }
        else{
          const pais = paises.find((pais) => pais._id === params.paisId);
          if(Object.keys(pais.configuracionEquivalencia).length > 0){
            let paisBody = {
              usuario: {
                _id: user._id,
              },
              equivalenciaDolar: parseFloat(values.valorMoneda),              
            }
            await putEquivalencia(user.editorial._id, pais.configuracionEquivalencia._id, paisBody);
          } else {          
            let paisBody = {
              editorial: {
                _id: user.editorial._id,
              },
              pais: {
                _id: params.paisId,
              },
              usuario: {
                _id: user._id,
              },
              equivalenciaDolar: parseFloat(values.valorMoneda),
              }
            await postCrearEquivalencia(user.editorial._id, paisBody);
          }      
        }
        toast.success(
          'El país ha sido editado con éxito.',
          alertConfiguration
        );
      }else{
        let imagen = await uploadFile(values.imagen);
        paisBody = {
          ...paisBody,
          bandera: imagen,
        };
        await postPais(paisBody);
        toast.success(
          'El país ha sido registrado con éxito.',
          alertConfiguration
          );
        }      
      obtenerPaises();
      navigate(-1);
    } catch (error) {
      console.log(error)
      toast.error("Ha ocurrido un error.", alertConfiguration);
    }
  };

  const obtenerDetallesPais = async () =>{
    try {
      const pais = paises.find((pais) => pais._id === params.paisId);
      if(Object.keys(pais.configuracionEquivalencia).length > 0){
        await getPaisDetalleEditorial(user.editorial._id, pais.configuracionEquivalencia._id).then((response) =>{
          let pais = {
            ...values,
            nombrePais: response.pais.nombre,
            nombreMoneda: response.pais.divisa,
            abreviacionMoneda: response.pais.abreviatura,
            simboloMoneda: response.pais.simbolo,
            valorMoneda: response.equivalenciaDolar,
            imagen: response.pais.bandera,
          }
          setValues(pais);
        })
      } else {
        await getPaisDetalle(params.paisId).then((response) => {
          let pais = {
            ...values,
            nombrePais: response.data.nombre,
            nombreMoneda: response.data.divisa,
            abreviacionMoneda: response.data.abreviatura,
            simboloMoneda: response.data.simbolo,
            valorMoneda: response.data.equivalenciaDolar,
            imagen: response.data.bandera,
          }
          setValues(pais);
        });
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }

  const [formStatus, handleSubmit] = useFormSubmit(paisSubmit);

  const { verifyFiles, deleteImage, uploadFile } = useUploadImage(values, setValues);

  useEffect(() => {
    if(values.imagen === ""){ 
      addError('imagen', 'La imagen es requerida');
    } else {
      removeError('imagen');
    }
    if (!areRequiredFieldsFulfilled() || Object.values(errors).length !== 0){
      setIsEnableSubmitButton(false);
    }
  }, [values]);

  useEffect(() => {
    if(params.paisId){
      obtenerDetallesPais();
    } else{
      setLoading(false);
    }
  }, [params, paises]);

  useEffect(() => {
    if (activeRole === 'ADMIN') {
      setPuedeEditar(true);
    }
  }, [activeRole]);

  return (
    <Fragment>
    { loading ? ( 
      <LoadingScreen /> 
    ) : (
      <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
        <SubHeaderAgregarPais editar={props.editar} ver={props.ver} nombrePais={values.nombrePais}/>
        <div className='flex flex-col flex-1 justify-around px-4 sm:px-20 mt-8 md:mt-0'>
          <AgregarPaisForm
            pais={values}
            errors={errors}
            handleChange={handleChange}
            setPais={setValues}
            setErrors={setErrors}
            addError={addError}
            deleteImage={deleteImage}
            ver={props.ver}
            editar={props.editar}
            puedeEditar={puedeEditar}
          />
            <div className='flex justify-center gap-3 mt-8 md:mt-0'>
              <CancelButton
                text={props.ver ? 'Regresar' : 'Cancelar'}
                styles='!w-[12rem]'
                onClick={() => navigate(-1)}
                />
              {!props.ver && (
                <SubmitButton
                  text={'Guardar'}
                  styles='!w-[12rem]'
                  disabled={!isEnableSubmitButton}
                  loadingButton
                  loading={formStatus === 'submitting'}
                  onClick={handleSubmit}
                />
              )}
            </div>
        </div>
      </div>
    )}
    </Fragment>
  );
};

export default PaisDetalles;
