export default {
  lineColor: 'L',
  lineColorFull: 'Linienfarbe',
  fillColor: 'F',
  fillColorFull: 'Füllfarbe',
  alpha: 'A',
  alphaFull: 'Alpha',
  lineWidth: 'B',
  lineWidthFull: 'Linienbreite',
  arrowLength: 'L',
  arrowLengthFull: 'Pfeillänge',
  eraserWidth: 'E',
  eraserWidthFull: 'Radiergummibreite',
  textColor: 'C',
  textColorFull: 'Textfarbe',
  fontSize: 'S',
  fontSizeFull: 'Schriftgröße',
  fontStrokeSize: 'St',
  fontStrokeSizeFull: 'Strichbreite',
  fontStyle: 'FS',
  fontStyleFull: 'Schriftstil',
  fontName: 'F',
  fontNameFull: 'Schriftartenname',
  textStrokeColor: 'SC',
  textStrokeColorFull: 'Strichfarbe',
  apply: 'Anwenden',
  cancel: 'Abbrechen',
  close: 'Schließen',
  clear: 'Zurücksetzen',
  width: 'Breite',
  height: 'Höhe',
  keepRatio: 'Breiten- / Höhenverhältnis beibehalten',
  fillPageWith: 'Füllen Sie die Seite mit der aktuellen Hintergrundfarbe',
  pixelSize: 'P',
  pixelSizeFull: 'Pixel Größe',
  resizeScale: 'Maßstab',
  resizeResize: 'Größe ändern',
  backgroundColor: 'Hintergrundfarbe der Seite',
  pixelizePixelSize: 'Pixelate Pixelgröße',
  language: 'Sprache',
  wrongPixelSizeValue:
    'Falsche Pixelgröße. Sie können zum Beispiel \'20%\' eingeben. Welche mittlere Pixelgröße wird 1/5 von die ausgewählte Bereichsseite, oder \'4\' bedeutet 4 px',
  tools: {
    crop: 'Bild auf ausgewählten Bereich zuschneiden',
    pixelize: 'Pixelisierung des ausgewählten Bereiches',
    rect: 'Rechteck zeichnen',
    ellipse: 'Ellipse zeichnen',
    line: 'Linie zeichnen',
    arrow: 'Pfeil zeichnen',
    rotate: 'Bild umdrehen',
    save: 'Bild speichern',
    load: 'Bild hochladen',
    text: 'Text hinzufügen',
    brush: 'Pinsel',
    resize: 'Größe ändern oder skalieren',
    open: 'Bild öffnen',
    select: 'Bereich auswählen',
    close: ' Painterro schließen',
    eraser: 'Radierer',
    settings: 'Einstellungen',
    undo: 'Rückgängig machen',
    redo: 'Wiederholen',
  },
  pasteOptions: {
    fit: 'Alle Austauschen ',
    extend_down: 'Nach unten strecken',
    extend_right: 'Nach rechts strecken',
    extend: 'Strecken',
    over: 'Überkleben',
    how_to_paste: 'Wie füge ich hinzu?',
  },
};
