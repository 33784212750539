import React, { useContext, useEffect, useState } from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import FacturaForm from "./FacturaForm";
import useValidateForm from "../../../hooks/useValidateForm";
import { UserContext } from "../../../services/user";
import { getInfoFacturacionUsuario } from "../../../services/api";

const INITIAL_VALUES_FACTURA = {
  email: "",
  nombreCompleto: "",
};

const SolicitarFacturaModal = ({ isOpen, setIsOpen }) => {
  const user = useContext(UserContext);

  const [useData, setUseData] = useState(false);
  const [datosFacturacion, setDatosFacturacion] = useState([]);

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    areRequiredFieldsFulfilled,
  } = useValidateForm(INITIAL_VALUES_FACTURA);

  const handleUseData = () => {
    setUseData(!useData);
    if (!useData) {
      setValues({
        ...values,
        email: user?.user?.correoElectronico,
        nombreCompleto: datosFacturacion?.nombreCompleto,
      });
    } else {
      setValues({
        ...values,
        email: "",
        nombreCompleto: "",
      });
    }
    setErrors({});
  };

  useEffect(() => {
    if (isOpen) {
      getInfoFacturacionUsuario(user.user._id).then((res) => {
        if (res.data !== undefined) setDatosFacturacion(res.data);
      });
    } else {
      setValues({
        ...values,
        email: "",
        nombreCompleto: "",
      });
    }
    setErrors({});
    setUseData(false);
  }, [isOpen]);

  useEffect(() => {
    let enableButton = Object.values(values).every(value => value !== "")
    setIsEnableSubmitButton(enableButton);
  }, [values]);

  return (
    <>
      <NimbusModal isOpen={isOpen}>
        <div className="w-[26rem] sm:w-[30rem] md:w-[34rem] px-6 justify-center">
          <h1 className="text-nimbusDark font-bold text-xl">Solicitar factura</h1>
          <p className="text-gray-400 mt-4 font-medium px-10">
            Primero debe seleccionar la compra de la cual requiere solicitar la factura, y posteriormente, completar los
            datos solicitados
          </p>
        </div>
        <FacturaForm
          factura={values}
          errors={errors}
          handleChange={handleChange}
          handleUseData={handleUseData}
          useData={useData}
        />
        <div className="flex justify-center gap-x-4">
          <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
          <SubmitButton text="Enviar" onClick={() => {}} disabled={!isEnableSubmitButton} />
        </div>
      </NimbusModal>
    </>
  );
};

export default SolicitarFacturaModal;
