import { useState } from "react";

function useFormSubmit(submitCallback) {
  const [formStatus, setFormStatus] = useState("idle");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formStatus === "submitting") {
      return;
    }

    setFormStatus("submitting");

    await submitCallback();

    setFormStatus("submitted");
  };

  return [formStatus, handleSubmit, setFormStatus];
}

export default useFormSubmit;
