import React, { useContext } from 'react';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../services/user';

const SubHeaderAgregarPais = ({ editar, ver, nombrePais }) => {
  const { activeRole } = useContext(UserContext)
  const navigate = useNavigate();
  return (
    <SubHeader>
      <div>
        <BreadCrumbContainer>
          <BreadCrumb route={activeRole === "ADMIN" ? 'Países /' : 'Monedas y países /'} onClick={() => navigate(`/paises`)} />
          <BreadCrumb route={editar ? 'Editar país' : ver ? 'Visualizar país' : 'Agregar país'} active />
        </BreadCrumbContainer>
        {(editar || ver) && (
          <div className="flex text-base md:text-lg text-transparent bg-clip-text bg-gradient-to-r from-[#18BAFB] to-[#3D90E1] items-center capitalize">
              <p>{nombrePais}</p>
          </div>
        )}
      </div>
    </SubHeader>
  );
};

export default SubHeaderAgregarPais;
