import React, {useState, useEffect} from 'react'
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

const useGrabar = ({lang}) => {
  const [isListening, setIsListening] = useState(false)
  const [completeTranscript, setCompleteTranscript] = useState('')
  const commands = [
    {
      command: "*",
      callback: (sentence) => {
        setCompleteTranscript(sentence)
      }
    }
  ];
  const { transcript, resetTranscript } = useSpeechRecognition({ commands });

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (
      <div className="mircophone-container">
        Este navegador no es compatible. Prueba usando chrome o safari
      </div>
    );
  }


  const handleListing = () => {
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
      language: lang === 'es' ? "es-MX" : 'en-IN',
    });
  };

  const stopHandle = () => {
    setIsListening(false);
    SpeechRecognition.stopListening();
  };

  const handleReset = () => {
    resetTranscript();
    setTimeout(()=>{
      stopHandle();
    },500)
  };

  const isCompatible = SpeechRecognition.browserSupportsSpeechRecognition()

  return{
    transcript,
    completeTranscript,
    isListening,
    stopHandle,
    handleReset,
    resetTranscript,
    handleListing,
    isCompatible
  }
}

export default useGrabar