import React, { useContext, useMemo } from "react";
import { UserContext } from "../../../services/user";
import { formatCurrency } from "../../../utils/productoUtils";
import ProductoImage from "../../../../assets/img/producto.png";
import PaqueteImage from "../../../../assets/img/paquetesMarketplace.png";

const ResumenCompraRow = ({ ventaInfo, detallesPais }) => {
  const { cantidad, total, variacion } = ventaInfo;

  const { _id, articulo, imagen, esPaquete, carrusel } = useMemo(() => {
    if (ventaInfo.producto)
      return {
        articulo: ventaInfo.producto.producto,
        _id: ventaInfo.producto._id,
        imagen: ventaInfo.producto.producto.imagen,
        carrusel: ventaInfo.producto.producto.carrusel,
        esPaquete: false,
      };

    return {
      articulo: ventaInfo.paquete.paquete,
      _id: ventaInfo.paquete._id,
      imagen: ventaInfo.paquete.paquete.imagen,
      carrusel: ventaInfo.paquete.paquete.carrusel,
      esPaquete: true,
    };
  }, [ventaInfo]);
  return (
    <tr className="p-2 text-left bg-white border-b-2 border-[#EEF1F3]">
      <td className="rounded-bl-sm text-left font-sans p-2">
        <img
          className="h-14"
          src={imagen === "" ? (carrusel.length > 0 ? carrusel[0] : (esPaquete ? PaqueteImage : ProductoImage)) : imagen}
          alt={`${articulo.nombre}.png`}
        />
      </td>
      <td className="font-sans text-left">{articulo.nombre}</td>
      <td className="font-sans text-left p-2">
        <div className="flex gap-2">
          <p className="text-[#808080]">Id del artículo:</p>
          <p className="text-[#B3B3B3]">{esPaquete ?  articulo._id : articulo._id}</p>
        </div>
        {variacion && (
          <>
            {variacion.seleccionValores.map((variable) => (
              <div className="flex gap-2 align-middle">
                <p className="text-[#808080]">{variable.variable.variable}:</p>
                <p className="text-[#B3B3B3]">{variable.valor.valor}</p>
              </div>
            ))}
          </>
        )}
        {esPaquete && (
          <>
            {articulo.productos.map((producto) => (     
              <div key={producto._id} className="mt-2">
                <div className="flex gap-2 align-middle">
                  <p className="text-[#808080]">Nombre del producto:</p>
                  <p className="text-[#B3B3B3]">{producto.producto.producto.nombre}</p>
                </div>
                <div className="flex gap-2 align-middle">
                  <p className="text-[#808080]">Cantidad:</p>
                  <p className="text-[#B3B3B3]">{producto.cantidad}</p>
                </div>
              </div>         
            ))}
          </>  
        )}
      </td>
      <td className=" font-sans text-left">Cantidad: {cantidad}</td>
      <td className={`rounded-br-sm  font-sans text-left`}>
        <div className="flex gap-2">
          <p>Total:</p>
          <p className="text-red-400">{detallesPais.simbolo} {formatCurrency(total)}</p>
        </div>
      </td>
    </tr>
  );
};

export default ResumenCompraRow;
