const StatusIcon = ({ estatus }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21" cy="21" r="21" fill={estatus ? "#2bab6e" : "#ff8080"} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3333 8.66667V9.05317C27.1887 10.2006 30 13.772 30 18V27.3333H31.3333V30H10V27.3333H11.3333V18C11.3333 13.772 14.1447 10.2006 18 9.05317V8.66667C18 7.19391 19.1939 6 20.6667 6C22.1394 6 23.3333 7.19391 23.3333 8.66667ZM14 27.3333H27.3333V18C27.3333 14.3181 24.3486 11.3333 20.6667 11.3333C16.9848 11.3333 14 14.3181 14 18V27.3333ZM23.3333 32.6667V31.3333H18V32.6667C18 34.1394 19.1939 35.3333 20.6667 35.3333C22.1394 35.3333 23.3333 34.1394 23.3333 32.6667Z"
        fill="white"
      />
    </svg>
  );
};

export default StatusIcon;
