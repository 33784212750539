import { toast } from "react-toastify";
import InstruccionesGeocode from "./InstruccionesGeocode";
import alertConfiguration from "../../../utils/alertsUtils";
import { useState } from "react";
import CleanButton from "./CleanButton";
import SaveButton from "./SaveButton";
import SuccessButton from "./SuccessButton";

const UBICACION_INITIAL_VALUES = {
  nombre: "",
  direccion: "",
  coordenadas: {
    lat: "",
    lng: "",
  },
};

export default function InputGeocode({ onChange, initialValues = UBICACION_INITIAL_VALUES }) {
  const [showInstrucciones, setShowInstrucciones] = useState(true);
  const [enableDireccionInput, setEnableDireccionInput] = useState(false);
  const [ubicacion, setUbicacion] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUbicacion({ ...ubicacion, [name]: value });
  };

  const handleChangeUbicacion = (values) => {
    setUbicacion({ ...ubicacion, ...values });
  };

  const handleShow = () => {
    setShowInstrucciones(!showInstrucciones);
  };

  const handleSave = () => {
    if(ubicacion.direccion !== ""){
      let datosUbicacion = { ...ubicacion };
      delete datosUbicacion.nombre;
      onChange(datosUbicacion);
      toast.success("Se guardó la dirección correctamente", alertConfiguration);
    }else{
      toast.error("No ha ingresado ninguna dirección", alertConfiguration);
    }
  };

  const handleSuccess = () => {
    if (ubicacion.nombre !== "") {
      const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${ubicacion.nombre}&key=` + key + ``)
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          const results = jsonData.results[0] ? jsonData.results[0] : 0;
          if (results.formatted_address && results !== 0) {
            const obj = {};
            obj.direccion = results.formatted_address;
            obj.coordenadas = results.geometry.location;
            handleChangeUbicacion(obj);
            setEnableDireccionInput(true);
          } else {
            //Error al obtener dirección
            toast.error("Revisa la información ingresada e intentalo nuevamente", alertConfiguration);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("No ha ingresado ninguna dirección o coordenadas", alertConfiguration);
    }
  };

  const handleClean = () => {
    setUbicacion(UBICACION_INITIAL_VALUES);
    onChange("");
    setEnableDireccionInput(false);
  };

  return (
    <div className="grid ">
      <div className="flex justify-center gap-x-5 w-full mx-auto ">
        <button
          id="instruccionesBtn"
          className="flex justify-center bg-nimbusDark font-bold p-2 text-white cursor-pointer rounded"
          onClick={handleShow}
        >
          {showInstrucciones ? "Ocultar" : "Mostrar"} Instrucciones
        </button>
      </div>
      <br />
      {showInstrucciones && <InstruccionesGeocode />}
      <label className="flex items-end w-full text-black font-bold" htmlFor="location">
        Ubicación
      </label>
      <input
        className={"h-min border-divider rounded-md md:min-w-[350px]"}
        value={ubicacion.nombre}
        name={"nombre"}
        placeholder="Ingresa una dirección o coordenadas"
        type="text"
        onChange={handleChange}
        disabled={enableDireccionInput}
      />
      <br />
      {enableDireccionInput && (
        <>
          <label className="text-[#606060] font-medium">Dirección:</label>
          <input
            className={"h-min border-divider rounded-md md:min-w-[350px]"}
            value={ubicacion.direccion}
            name={"direccion"}
            type="text"
            onChange={handleChange}
          />
        </>
      )}
      <label className="text-[#606060] font-medium">
        {enableDireccionInput ? "Coordenadas: " + ubicacion.coordenadas.lat + ", " + ubicacion.coordenadas.lng : ""}
      </label>
      <br />
      <div className="flex justify-center gap-x-5 w-full md:w-1/2 mx-auto ">
        {enableDireccionInput ? (
          <>
            <CleanButton handleClick={handleClean} />
            <SaveButton handleClick={handleSave} />
          </>
        ) : (
          <SuccessButton handleClick={handleSuccess} />
        )}
      </div>
    </div>
  );
}
