import React from "react";
import useGrabar from "./useGrabar";

function IsSpeakingCompatible({ children }) {
  const { isCompatible } = useGrabar("es");
  return (
    <div className="w-full">
      {isCompatible
        ? children
        : "Esta actividad no es compatible con tu navegador. Prueba usando chrome, microsoft edge o safari."}
    </div>
  );
}

export default IsSpeakingCompatible;
