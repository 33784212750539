import { useEffect, useState } from "react";
import InputBuscar from "../../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../../fragments/Inputs/InputSelect";

const FILTRO_REPORTE_AVISOS_DETALLES_INITIAL_VALUES = {
  leido: "",
  busqueda: "",
};

const isReadOptions = [
  { _id: "1", nombre: "Leído" },
  { _id: "0", nombre: "Sin leer" },
];

const ReporteAvisosDetallesFilterForm = ({
  destinatarios,
  setDestinatariosFiltro,
}) => {
  const [filtro, setFiltro] = useState(
    FILTRO_REPORTE_AVISOS_DETALLES_INITIAL_VALUES
  );

  useEffect(() => {
    const { busqueda, leido } = filtro;

    let newDestinatariosFiltered = destinatarios;
    newDestinatariosFiltered = filterLeido(newDestinatariosFiltered, leido);
    newDestinatariosFiltered = filterBusqueda(
      newDestinatariosFiltered,
      busqueda.toLowerCase()
    );
    setDestinatariosFiltro(newDestinatariosFiltered);
  }, [filtro]);

  const filterLeido = (destinatarios, isRead) => {
    if (isRead === "") return destinatarios;
    const newDestinatariosFiltered = destinatarios?.filter(
      (destinatario) => destinatario?.esLeido == isRead
    );
    return newDestinatariosFiltered;
  };

  const filterBusqueda = (destinatarios, busqueda) => {
    if (busqueda === "") return destinatarios;

    const newAvisosFiltro = destinatarios?.filter((destinatario) => {
      const fullname =
        `${destinatario?.usuario?.nombres} ${destinatario?.usuario?.primerApellido} ${destinatario?.usuario?.segundoApellido}`.toLowerCase();
      const email = destinatario?.usuario?.correoElectronico.toLowerCase();

      return fullname.includes(busqueda) || email.includes(busqueda);
    });
    return newAvisosFiltro;
  };

  return (
    <>
      <div className="flex flex-row">
        <InputSelect
          id="isRead"
          name="isRead"
          value={filtro.leido}
          options={isReadOptions ?? []}
          onChange={(e) => setFiltro({ ...filtro, leido: e.target.value })}
          styles="lg:w-[124px]"
          label={"Estatus"}
          topLabel
        />
      </div>

      <div className="flex flex-row">
        <InputBuscar
          onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
        />
      </div>
    </>
  );
};

export default ReporteAvisosDetallesFilterForm;
