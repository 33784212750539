import React from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbContainer from "../../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../../fragments/SubHeader";
import BreadCrumb from "../../../../fragments/BreadCrumb";

const SubHeaderPaquetesAsignados = ({ nombreAlumno }) => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <div>
        <BreadCrumbContainer>
          <BreadCrumb route={"Mi paquete escolar /"} onClick={() => navigate(-1)} />
          <BreadCrumb route={"Visualizar artículos"} active />
        </BreadCrumbContainer>
        <div className="flex text-base md:text-lg text-transparent bg-clip-text bg-gradient-to-r from-[#18BAFB] to-[#3D90E1] items-center">
          <p>{nombreAlumno}</p>
        </div>
      </div>
    </SubHeader>
  );
};

export default SubHeaderPaquetesAsignados;
