export const AVISO_DATA_ACTION_TYPES = {
  SET_NOMBRE: "gestorAvisos/SET_NOMBRE",
  SET_CATEGORIA: "gestorAvisos/SET_CATEGORIA",
  SET_DESCRIPCION: "gestorAvisos/SET_DESCRIPCION",

  ADD_CONTENIDO: "gestorAvisos/ADD_AGREGAR_CONTENIDO",
  SET_UPLOADING_FILE: "gestorAvisos/SET_UPLOADING_FILE",
  UPLOAD_FILE: "gestorAvisos/UPLOAD_FILE",
  SET_BLOQUE_ID: "gestorAvisos/SET_BLOQUE_ID",
  SET_CONTENIDO_PATH: "gestorAvisos/SET_CONTENIDO_PATH",
  SET_CONTENIDO_TEXT: "gestorAvisos/SET_CONTENIDO_TEXT",
  SET_CONTENIDO_PIE: "gestorAvisos/SET_CONTENIDO_PIE",
  DELETE_CONTENIDO: "gestorAvisos/DELETE_CONTENIDO",

  SET_CALENDARIO: "gestorAvisos/SET_CALENDARIO",
  SET_FECHA_INICIO: "gestorAvisos/SET_FECHA_INICIO",
  SET_FECHA_FIN: "gestorAvisos/SET_FECHA_FIN",

  ADD_LIST: "gestorAvisos/ADD_LIST",
  DELETE_LIST: "gestorAvisos/DELETE_LIST",

  SET_ENVIO: "gestorAvisos/SET_ENVIO",

  SET_EN_PROCESO: "gestorAvisos/SET_EN_PROCESO",
  CLEAR_AVISO: "gestorAvisos/CLEAR_AVISO",
};
