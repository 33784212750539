import { createContext, useEffect, useState } from "react";
import { isAllowedTo } from "../utils/roles";
import { getUserInfo, getUsuarioDetail } from "./api";
import { useAuth } from "./auth";
import { getPermisosRol } from "./Api/Roles";
import { obtenerEstatusMarketplace } from "../utils/marketPlaceUtils";
import validarAcceso from "../utils/checkUserAccess";

export const UserContext = createContext();

const uid = window.localStorage.getItem("uid");

const token = window.localStorage.getItem("token") || window.sessionStorage.getItem('token');

export const UserProvider = ({ children }) => {

  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [activeRole, setActiveRole] = useState("");
  const [escuelasPermits,setEscuelasPermits] = useState([])
  const [campusPermits, setCampusPermits] = useState([]);
  const [gruposPermits, setGruposPermits] = useState([]);
  const [familiasPermits, setFamiliasPermits] = useState([]);
  const [usuariosPermits, setUsuariosPermits] = useState([]);
  const [constructorPermits, setConstructorPermits] = useState([]);
  const [servidoresPermits, setServidoresPermits] = useState([]);
  const [reportesPermits, setReportesPermits] = useState([]);
  const [visorPermits,setVisorPermits] = useState([])
  const [corporativoPermits,setCorporativoPermits] = useState([])
  const [escaparatePermits,setEscaparatePermits] = useState([])
  const [usuariosEditorialPermits,setUsuariosEditorialPermits] = useState([])
  const [usuariosDistribucionPermits,setUsuariosDistribucionPermits] = useState([])
  const [distribucionesPermits,setDistribucionesPermits] = useState([])
  const [escuela,setEscuela]=useState([]);
  const [planteles, setPlanteles]=useState([]);
  const [permissions, setPermissions] = useState({})
  const [marketPlaceStatus, setMarketPlaceStatus] = useState({adminCompleted: false, userCompleted:false, hasChildren: false})


  useEffect(() => {
    if (uid !== null && token !== null) {
      getUsuarioDetail(uid).then((resU) => {
        getUserInfo().then((res) => {
          setUser(res.data);
          setRoles(res.data.roles);
          setEscuela(res.data.escuela);
          setActiveRole(res.data.roles[0]);
          setPlanteles(res.data.planteles);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (activeRole !== "") {
      Promise.all([
        isAllowedTo(activeRole, "campus"),
        isAllowedTo(activeRole, "grupos"),
        isAllowedTo(activeRole, "familias"),
        isAllowedTo(activeRole, "usuarios"),
        isAllowedTo(activeRole, "constructor"),
        isAllowedTo(activeRole, "servidores"),
        isAllowedTo(activeRole, "reportes"),
        isAllowedTo(activeRole, "visor"),
        isAllowedTo(activeRole, "escuelas"),
        isAllowedTo(activeRole, "corporativos"),
        isAllowedTo(activeRole, "escaparate"),
        isAllowedTo(activeRole, "usuariosDistribucion"),
        isAllowedTo(activeRole, "usuariosEditorial"),
        isAllowedTo(activeRole, "distribuciones")
      ]).then((res) => {
        setCampusPermits(res[0]);
        setGruposPermits(res[1]);
        setFamiliasPermits(res[2]);
        setUsuariosPermits(res[3]);
        setConstructorPermits(res[4]);
        setServidoresPermits(res[5]);
        setReportesPermits(res[6]);
        setVisorPermits(res[7]);
        setEscuelasPermits(res[8]);
        setCorporativoPermits(res[9]);
        setEscaparatePermits(res[10]);
        setUsuariosDistribucionPermits(res[11]);
        setUsuariosEditorialPermits(res[12]);
        setDistribucionesPermits(res[13]);
      });
      getPermisosRol(activeRole).then(permisosResponse => {
        if(Object.keys(permisosResponse).length !== 0 ){
          const userPermissions = permisosResponse.reduce((permissions, permission) => {
            const { id, ...operations } = permission;
            permissions[id] = operations;
            return permissions;
          }, {});
          setPermissions(userPermissions)
        }
      })
      if(validarAcceso(user.correoElectronico)){
        obtenerEstatusMarketplace(activeRole, user).then(({adminCompleted, hasChildren, userCompleted}) => {
          setMarketPlaceStatus({adminCompleted: adminCompleted, userCompleted:userCompleted, hasChildren: hasChildren})
        })
      }

    }else{
      setCampusPermits([])
      setGruposPermits([])
      setFamiliasPermits([])
      setUsuariosPermits([])
      setConstructorPermits([])
      setServidoresPermits([])
      setReportesPermits([])
      setVisorPermits([])
      setEscuelasPermits([])
      setPermissions([])
      setMarketPlaceStatus({adminCompleted: false, userCompleted:false, hasChildren: false})
    }
  }, [activeRole]);

  const clearContext = () =>{
    setUser({});
    setRoles([]);
    setEscuela([]);
    setPlanteles([]);
    setActiveRole('');
    setCampusPermits([])
    setGruposPermits([])
    setFamiliasPermits([])
    setUsuariosPermits([])
    setConstructorPermits([])
    setServidoresPermits([])
    setReportesPermits([])
    setVisorPermits([])
    setEscuelasPermits([])
    setCorporativoPermits([]);
    setEscaparatePermits([]);
    setUsuariosDistribucionPermits([]);
    setUsuariosEditorialPermits([]);
    setDistribucionesPermits([]);
    setPermissions({})
    setMarketPlaceStatus({adminCompleted: false, userCompleted:false, hasChildren: false})
  }

  return (
    <UserContext.Provider
      value={{
        user,
        roles,
        activeRole,
        setUser,
        setRoles,
        setActiveRole,
        setEscuela,
        setPlanteles,
        escuelasPermits,
        campusPermits,
        gruposPermits,
        familiasPermits,
        usuariosPermits,
        constructorPermits,
        servidoresPermits,
        reportesPermits,
        visorPermits,
        corporativoPermits,
        escaparatePermits,
        usuariosDistribucionPermits,
        usuariosEditorialPermits,
        distribucionesPermits,
        escuela,
        planteles,
        clearContext,
        permissions,
        marketPlaceStatus,
        setMarketPlaceStatus
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
