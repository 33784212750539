import {
  GestorAvisosContextProvider,
  useGestorAvisos,
} from "./GestorAvisosContext";
import { MESSAGES } from "../../utils/messagesUtils";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../services/user";
import GestorAvisosFilter from "./fragments/GestorAvisosFilter";
import GestorAvisosTable from "./fragments/GestorAvisosTable";
import LoadingScreen from "../../fragments/LoadingScreen";
import NoDataMessage from "../../fragments/NoDataMessage";
import SubHeaderGestorAvisos from "./fragments/SubHeaderGestorAvisos";
import TablePagination from "../../fragments/TablePagination";
import withUserAccesability from "../../hoc/withUserAccesability";
import { getAvisosRequest } from "../../services/Api/GestorAvisos";
import { toast } from "react-toastify";
import alertConfiguration from "../../utils/alertsUtils";
import { useDispatch } from "react-redux";
import { limpiarAviso } from "../../../reducers/avisoData/avisoDataAction";

const GestorAvisosHome = ({ isUserAllowed }) => {
  return (
    <GestorAvisosContextProvider>
      <GestorAvisosHomeComponents isUserAllowed={isUserAllowed} />
    </GestorAvisosContextProvider>
  );
};

const GestorAvisosHomeComponents = ({ isUserAllowed }) => {
  const {
    avisosFiltro,
    setAvisos,
    setAvisosFiltro,
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
  } = useGestorAvisos();
  const { user, activeRole } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && user && !isUserAllowed) {
      navigate("/NotFound");
    }
  }, [user, isUserAllowed, loading]);

  const getAvisos = async () => {
    try {
      setLoading(true);
      if (activeRole !== "") {
        let data = await getAvisosRequest(user._id);
        data.sort((avisoA, avisoB) =>
          avisoB.fechaCreacion.localeCompare(avisoA.fechaCreacion)
        );
        setAvisos(data);
        setAvisosFiltro(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeRole !== "") {
      getAvisos();
    }
    dispatch(limpiarAviso());
  }, [activeRole]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
          <SubHeaderGestorAvisos />
          <div
            className={`${
              avisosFiltro?.length !== 0 && "justify-between"
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <GestorAvisosFilter getAvisos={getAvisos} />
              <GestorAvisosTable getAvisos={getAvisos} />
            </div>
            <div>
              {avisosFiltro?.length === 0 ? (
                <NoDataMessage message={MESSAGES.MESSAGE_GESTOR_AVISOS_TABLE} />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withUserAccesability(GestorAvisosHome);
