import React from "react";
import Oxxo from "../../../../../assets/img/oxxoLogo.png";
import CodigoDeBarras from "../../../../../assets/img/codigoBarras.png";
import Advertencia from "../../../../../assets/icons/advertenciaTransferencia.svg";

const OxxoPagoExitoso = () => {
  return (
    <div className="flex flex-col px-4 pt-4 gap-6">
      <div className="flex justify-between items-center">
        <p>Pago en efectivo Oxxo</p>
        <img className="h-5 md:h-8" src={Oxxo} alt="" />
      </div>
      <div className="flex justify-center">
        <img src={CodigoDeBarras} alt="" />
      </div>
      <div className="flex gap-2">
        <img src={Advertencia} alt="" />
        <p>Esta referencia será valida en un período no máximo a 48 horas </p>
      </div>
    </div>
  );
};

export default OxxoPagoExitoso;
