import HistorialAvisosRow from "./HistorialAvisosRow";

const HistorialAvisosTable = ({ getAvisos, currentData }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th></th>
            <th className="text-center">Título del aviso</th>
            <th className="text-center">Fecha de recibido</th>
            <th className="text-center">Fecha de leído</th>
            <th className="text-center">Categoría</th>
            <th className="text-center">Estatus</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentData?.map((aviso) => (
            <HistorialAvisosRow
              key={aviso?._id}
              aviso={aviso}
              getAvisos={getAvisos}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HistorialAvisosTable;
