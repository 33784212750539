import React from 'react'
import { saveAs } from 'file-saver';
import InfoIcon from '../../../../assets/icons/info.svg'
import { Trans } from 'react-i18next';

function ContenedorArchivos({archivo, namesArr, instrucciones}) {

 const descargarArchivo = () =>{
    saveAs(archivo, 'contenedor_de_recursos.zip')
 }

 return (
  <>
  {instrucciones && <div className='block-instructions mt-6 mb-1'>{instrucciones}</div>}
    <div 
      className="rounded-xl w-full p-12 bg-[#f8fdff] mb-6 border shadow-lg"
    >
      <div className='w-full max-w-[20rem] mx-auto'>
        <div className="text-left text-[#3b709e] font-bold">
          <div className='md:text-2xl mb-8'>
            <Trans i18nKey="recursosHeading"></Trans>
          </div>
          <ul className='list-disc w-full'>
            {namesArr.map((el)=>(
              <li className='text-left mb-3 break-all'>{el.replace('archivos/', '')}</li>  
            ))}
          </ul>
        </div>
        <button className='bg-lightBlue py-3 font-bold w-full cursor-pointer text-white block mx-auto hover:brightness-105 transition-all mt-8' onClick={descargarArchivo}>
          <Trans i18nKey="recursosDownload"></Trans>
        </button>
        <div className="text-[#3b709e] font-bold mt-2 text-sm  flex align-baseline">
          <img className='mb-1 w-10 mr-2' src={InfoIcon} alt=""/>
          <Trans i18nKey="recursosInfo"></Trans>
        </div>
        <a 
          href="https://support.microsoft.com/es-es/windows/comprimir-y-descomprimir-archivos-f6dde0a7-0fec-8294-e1d3-703ed85e7ebc" 
          className='text-lightBlue mt-5 text-center block hover:underline'
          rel="noopener noreferrer"
          target="_blank"
        >
          <Trans i18nKey="recursosLink"></Trans>
        </a>
      </div>
    </div>
  </>
  )
}

export default ContenedorArchivos