import { useEffect, useState } from "react";
import {
  generarNuevaVariable,
  generarNuevoValor,
} from "../comercial/GestionProductos/Productos/fragments/GenerarVariable";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import alertConfiguration from "../utils/alertsUtils";

const validateVariableText = (value) => {
  if (value.length < 3) {
    return "El nombre del atributo debe tener como mínimo 3 caracteres.";
  } else if (value.length > 20) {
    return "El nombre del atributo debe tener como máximo 20 caracteres.";
  } else if (!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)) {
    return "El nombre del atributo sólo puede tener letras, espacios y números.";
  } else if (/^\s*$/.test(value)) {
    return "El nombre del atributo tiene únicamente espacios.";
  }

  return "";
};

const validateValorText = (value) => {
  if (value.length > 20) {
    return "El valor debe tener como máximo 20 caracteres.";
  } else if (!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)) {
    return "El valor sólo puede tener letras, espacios y números.";
  }else if (/^\s*$/.test(value)) {
    return "El valor tiene únicamente espacios.";
  }

  return "";
};

function useProductoForm(initialValuesVariables = [], initialValuesCarrusel = []) {
  const [variables, setVariables] = useState(initialValuesVariables);
  const [carrusel, setCarrusel] = useState(initialValuesCarrusel);
  const [errorsVariables, setErrorsVariables] = useState({});

  
  useEffect(() => {
    let newErrors = {};
    variables.forEach((variable) => {
      newErrors[variable.variableId] = {
        error: "",
        valores: {},
      };
      if (variable.variable !== "") {
        let variableError = validateVariableText(variable.variable);
        newErrors[variable.variableId].error = variableError;
      }

      variable.valores.forEach((valor) => {
        if (valor.valor !== "") {
          let valorError = validateValorText(valor.valor);
          newErrors[variable.variableId].valores[valor.valorId] = valorError;
        }
      });
    });
    setErrorsVariables(newErrors);
  }, [variables]);

  const areVariablesRequiredFieldsFulfilled = () => {
    let requiredFieldsFulfilled = true;
    variables.forEach((variable) => {
      if (variable.variable === "") {
        requiredFieldsFulfilled = false;
        return false;
      }

      variable.valores.forEach((valor) => {
        if (valor.valor === "") {
          requiredFieldsFulfilled = false;
          return false;
        }
      });
    });

    return requiredFieldsFulfilled;
  };

  const checkErrorsVariables = () => {
    for(const variableValue of Object.values(errorsVariables)){
      if(variableValue.error !== ""){
        return true
      }
      for( const valorValue of Object.values(variableValue.valores)){
        if(valorValue !== ""){
          return true
        }
      }

    }
    return false
  }

  const handleActualizarVariableText = (event, variableId) => {
    let variableNombre = event.target.value;
    let nuevasVariables = variables.map((variable) =>
      variable.variableId === variableId ? { ...variable, variable: variableNombre } : variable
    );
    setVariables(nuevasVariables);
  };

  const handleActualizarValorText = (event, variableId, valorId) => {
    let valorNombre = event.target.value;
    let nuevasVariables = variables.map((variable) => {
      if (variable.variableId === variableId) {
        variable.valores = variable.valores.map((valor) =>
          valor.valorId === valorId ? { ...valor, valor: valorNombre } : valor
        );
      }

      return variable;
    });

    setVariables(nuevasVariables);
  };
  const handleActualizarValorImg = (event, variableId, valorId, extensions) => {
    let fileSize = event.target.files[0].size;
    let allowedExtensionsFile = extensions
      .replace(/\s/g, "")
      .split(",")
      .map((extension) => extension.toLowerCase());
    let fileExtension = "." + event.target.files[0].name.split(".").pop().toLowerCase();
    if (!allowedExtensionsFile.includes(fileExtension)) {
      let extensionsMessage = allowedExtensionsFile.join().replace(/,/g, ", ");
      toast.warning(`Sólo puede cargar imágenes ${extensionsMessage}`, alertConfiguration);
    } else if (fileSize > 150000) {
      toast.warning(
        "Asegúrese de que el archivo no sea mayor a 150kb. Le recomendamos comprimir su archivo.",
        alertConfiguration
      );
      event.target.value = ''
    } else {
      let valorImg = event.target.files[0];
      let nuevasVariables = variables.map((variable) => {
        if (variable.variableId === variableId) {
          variable.valores = variable.valores.map((valor) =>
            valor.valorId === valorId ? { ...valor, imagen: valorImg } : valor
          );
        }

        return variable;
      });

      setVariables(nuevasVariables);
    }
  };

  const validarVariablesVacias = () => {
    return variables.some((variable) => {
      if (variable.variable === "") return true;

      return variable.valores.some((valor) => (valor.valor === "" ? true : false));
    });
  };

  const handleAgregarVariable = () => {
    let nuevaVariable = generarNuevaVariable();
    let nuevasVariables = [...variables, nuevaVariable];
    setVariables(nuevasVariables);
  };

  const handleEliminarVariable = (variableAEliminarId) => {
    let nuevasVariables = variables.filter((variable) => variable.variableId !== variableAEliminarId);
    setVariables(nuevasVariables);
  };

  const handleAgregarValor = (variableAModificarId) => {
    let nuevoValor = generarNuevoValor();
    let nuevasVariables = variables.map((variable) =>
      variable.variableId === variableAModificarId ? { ...variable, valores: [...variable.valores, nuevoValor] } : variable
    );

    setVariables(nuevasVariables);
  };
  const handleEliminarValor = (variableAModificarId, valorAEliminarId) => {
    let nuevasVariables = variables.map((variable) => {
      if (variable.variableId === variableAModificarId) {
        variable.valores = variable.valores.filter((valor) => valor.valorId !== valorAEliminarId);
      }

      return variable;
    });

    setVariables(nuevasVariables);
  };

  const handleSubirFotosVariables = async (uploadFile) => {
    let fotos = [];
    fotos = await Promise.all(
      variables.map(async (variable) => {
        variable.valores = await Promise.all(
          variable.valores.map(async (valor) => {
            valor.imagen = await uploadFile(valor.imagen);
            return valor;
          })
        );
        return variable;
      })
    );
    return fotos;
  };

  const handleAgregarFotoCarrusel = () => {
    const fotoId = uuidv4();
    let nuevaFoto = {
      _id: fotoId,
      imagen: "",
    };

    let nuevasFotos = [...carrusel, nuevaFoto];

    setCarrusel(nuevasFotos);
  };

  const handleEliminarFotoCarrusel = (fotoAEliminarId) => {
    let nuevasFotos = carrusel.filter((foto) => foto._id !== fotoAEliminarId);
    setCarrusel(nuevasFotos);
  };

  const validarCarruselVacio = () => {
    return carrusel.some((foto) => foto.imagen === "");
  };

  const handleActualizarFotoCarrusel = (event, fotoAModificarId) => {
    let fotoValue = event.target.files[0];
    let nuevasFotos = carrusel.map((foto) => (foto._id === fotoAModificarId ? { ...foto, imagen: fotoValue } : foto));
    setCarrusel(nuevasFotos);
  };

  const handleEliminarFoto = (fotoAModificarId) => {
    let nuevasFotos = carrusel.map((foto) => (foto._id === fotoAModificarId ? { ...foto, imagen: "" } : foto));
    setCarrusel(nuevasFotos);
  };

  const handleSubirFotosCarrusel = async (uploadFile) => {
    let fotos = [];
    fotos = await Promise.all(carrusel.map(async (foto) => await uploadFile(foto.imagen)));
    return fotos;
  };

  const carruselMetodos = {
    handleAgregarFotoCarrusel,
    handleEliminarFoto,
    validarCarruselVacio,
    handleActualizarFotoCarrusel,
    handleEliminarFotoCarrusel,
    handleSubirFotosCarrusel,
  };

  const variablesMetodos = {
    handleAgregarValor,
    handleEliminarValor,
    handleEliminarVariable,
    handleAgregarVariable,
    handleActualizarValorImg,
    handleActualizarValorText,
    handleActualizarVariableText,
    validarVariablesVacias,
    areVariablesRequiredFieldsFulfilled,
    handleSubirFotosVariables 
  };

  return {
    variablesMetodos,
    variables,
    setVariables,
    carruselMetodos,
    carrusel,
    setCarrusel,
    errorsVariables,
    checkErrorsVariables
  };
}

export default useProductoForm;
