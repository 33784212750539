import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FlexSlide from "./FlexSlide";

const FlexSlider = ({ carouselData, instrucciones }) => {
  const [currentSlide, setCurrentSlide] = useState(2);

  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const orderedData = carouselData.sort((a, b) => {
    if (a.orden > b.orden) {
      return 1;
    } else {
      return -1;
    }
  });

  return (
    <>
      <div className="mt-4 lg:mt-8"></div>
      {instrucciones && (
        <div className="block-instructions lg:mt-8 mb-2">{instrucciones}</div>
      )}
      <Carousel
        centerMode
        ssr
        responsive={responsive}
        showDots={false}
        keyBoardControl={true}
        infinite
        className="mb-[2rem] z-[5] flex-carousel"
        afterChange={(nextSlide, { currentSlide, onMove }) => {
          setCurrentSlide(currentSlide);
        }}
      >
        {orderedData.map((item, index) => (
          <>
            <FlexSlide
              slideData={item}
              key={index}
              currentSlide={currentSlide}
              index={index}
            />
          </>
        ))}
      </Carousel>
    </>
  );
};

export default FlexSlider;
