import React from 'react';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumb from '../../../fragments/BreadCrumb';
import { useNavigate } from 'react-router-dom';

const SubHeaderPaqueteEscolar = () => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={'Inicio /'} onClick={() => navigate(`/home`)} />
        <BreadCrumb route={'Mi paquete escolar'} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderPaqueteEscolar;
