import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';
import { RUTAS_MAP } from '../../../utils/tiendaUtils';

const SubHeaderProductoDetalle = ({nombreProducto}) => {
    const navigate = useNavigate();
    const params = useParams();
  
    return (
      <SubHeader styles="bg-transparent !px-0 min-h-fit">
        <BreadCrumbContainer>
          <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} styles="text-black/70 !md:text-lg" />
          <BreadCrumb route={`${RUTAS_MAP[params.categoria]} /`} onClick={() => navigate(-1)}  styles="text-black/70 !md:text-lg" />
          <BreadCrumb route={`${nombreProducto ?? ""}`} styles="text-black !md:text-lg break-words" active/>
        </BreadCrumbContainer>
      </SubHeader>
    )
}

export default SubHeaderProductoDetalle