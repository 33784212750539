export const TIPOS_DE_PRODUCTO = [
  {
    _id: "Digital",
    nombre: "Digital",
  },
  {
    _id: "Físico",
    nombre: "Físico",
  },
  {
    _id: "Servicio",
    nombre: "Servicio",
  },
];

export const TIPOS_DE_CATEGORIA = [
  {
    _id: "Libros impresos",
    nombre: "Libros impresos",
    tipo: "Físico",
  },
  {
    _id: "Libros digitales",
    nombre: "Libros digitales",
    tipo: "Digital",
  },
  {
    _id: "Uniformes",
    nombre: "Uniformes",
    tipo: "Físico",
  },
  {
    _id: "Útiles escolares",
    nombre: "Útiles escolares",
    tipo: "Físico",
  },
  {
    _id: "Academias",
    nombre: "Academias",
    tipo: "Servicio",
  },
  {
    _id: "Hardware",
    nombre: "Hardware",
    tipo: "Físico",
  },
  {
    _id: "Plataformas",
    nombre: "Plataformas",
    tipo: "Digital",
  },
  {
    _id: "Servicios escolares",
    nombre: "Servicios escolares",
    tipo: "Servicio",
  },
  {
    _id: "Cursos y capacitaciones",
    nombre: "Cursos y capacitaciones",
    tipo: "Servicio",
  },
  {
    _id: "Eventos",
    nombre: "Eventos",
    tipo: "Servicio",
  },
  {
    _id: "otro",
    nombre: "Otros",
    tipo: "",
  },
];

export const TIPOS_ESTATUS = [
  {
    _id: true,
    nombre: "Disponible",
  },
  {
    _id: false,
    nombre: "Agotado",
  },
];

export const OPCIONES_SI_NO = [
  {
    _id: true,
    nombre: "Sí",
  },
  {
    _id: false,
    nombre: "No",
  },
];

export const TIPOS_ORIGEN = [
  {
    _id: "Nuevo",
    nombre: "Nuevo",
  },
  {
    _id: "Linea",
    nombre: "De línea",
  },
];

export const TIPOS_CONFIGURACION_PRODUCTO = [
  {
    _id: "PRODUCTO_SIMPLE",
    nombre: "Producto simple",
  },
  {
    _id: "PRODUCTO_VARIABLE",
    nombre: "Producto variable",
  },
  {
    _id: "PRODUCTO_DIGITAL",
    nombre: "Producto digital",
  },
];

export const LISTA_PRECIOS = [
  {
    _id: "Menos de 500.00",
    nombre: "Menos de 500.00"
  },
  {
    _id: "500.00 - 1,000.00",
    nombre: "500.00 - 1,000.00"
  },
  {
    _id: "1,000.00 - 2,000.00",
    nombre: "1,000.00 - 2,000.00"
  },
  {
    _id: "Más de 2,000.00",
    nombre: "Más de 2,000.00"
  },
]

export const formatCurrency = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};
