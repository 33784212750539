import React from "react";

const ContentContainer = ({ children, styles = "" }) => {
  return (
    <div className={`px-4 sm:px-8 relative flex-1 flex flex-col mt-6 ${styles}`}>
      <div className="flex space-x-6 flex-1">{children}</div>
    </div>
  );
};

export default ContentContainer;
