import React, { Fragment, useContext, useEffect, useState } from 'react';
import { formatDate } from '../../../utils/dateFunctions';
import { UserContext } from '../../../services/user';
import { getPaises } from '../../../services/api';

const DatosDelPedido = ({ ventaInfo }) => {
  const user = useContext(UserContext);
  const {
    _id,
    fechaCreacion,
    articulos,
    compra,
    comprador,
    estatus,
    datosEnvio,
    moneda,
    metodoDePago,
    usuario,
    total,
  } = ventaInfo;

  const [abreviatura, setAbreviatura] = useState('');
  const [data, setData] = useState([]);

  const getMonedaPais = async () => {
    const { data } = await getPaises();
    const abreviaturaPais = data.filter(
      (pais) => pais.abreviatura === moneda
    )[0].simbolo;
    setAbreviatura(abreviaturaPais);
  };

  useEffect(() => {
    if (user.activeRole !== '') {
      getMonedaPais();
    }
  }, [user.activeRole]);

  useEffect(() => {
    let newVentaInfo = {
      ...ventaInfo,
      comprador: `${comprador?.nombres || usuario?.nombres} ${
        comprador?.primerApellido || usuario?.primerApellido
      } ${comprador?.segundoApellido || usuario?.segundoApellido}`,
      correoElectronico:
        comprador?.correoElectronico || usuario?.correoElectronico,
      telefono: comprador?.telefono || usuario?.telefono,
      metodoDePago: compra?.metodoDePago || metodoDePago,
      total: compra?.total || total,
      moneda: compra?.moneda || moneda,
      nombreDireccion:
        datosEnvio?.nombreDireccion || compra?.datosEnvio?.nombreDireccion,
      direccion:
        datosEnvio?.ubicacion?.direccion ||
        compra?.datosEnvio?.ubicacion?.direccion,
      telefonoEnvio: datosEnvio?.telefono || compra?.datosEnvio?.telefono,
      referencias: datosEnvio?.referencias || compra?.datosEnvio?.referencias,
    };
    setData(newVentaInfo);
  }, [ventaInfo]);

  return (
    <Fragment>
      <div className='w-full bg-[#F5F5F5]'>
        <h1 className='text-center text-[#9C9C9C] font-extrabold p-4 text-lg'>
          Datos del pedido
        </h1>
      </div>
      <div className='bg-[#fff] py-10 px-5'>
        <div className='flex justify-between px-5 flex-wrap'>
          <div className='flex gap-5'>
            <p className='text-[#808080]'>Id del pedido:</p>
            <p className='text-[#B3B3B3]'>
              {user.activeRole === 'DIR_PLANTEL' ||
              user.activeRole === 'ADMIN_OPERATIVO'
                ? _id
                : compra?._id}
            </p>
          </div>
          <div className='flex gap-5'>
            <p className='text-[#808080]'>Fecha del pedido:</p>
            <p className='text-[#B3B3B3]'>
              {formatDate(new Date(fechaCreacion))}
            </p>
          </div>
          <div className='flex gap-5'>
            <p className='text-[#808080]'>Total de artículos:</p>
            <p className='text-[#B3B3B3]'>{articulos?.length}</p>
          </div>
          <div className='xl:pr-16'>
            <p className='text-[#808080]'>Estatus del pedido</p>
          </div>
        </div>
        <div className='flex justify-between mt-3 2xl:mt-10 px-5 flex-wrap-reverse'>
          {(user.activeRole === 'DIR_PLANTEL' ||
            user.activeRole === 'ADMIN_OPERATIVO') && (
            <div>
              <div>
                <p className='text-[#808080] underline underline-offset-1 mb-5'>
                  Datos del cliente
                </p>
                <div>
                  <div className='flex gap-3 flex-wrap'>
                    <p className='text-[#808080]'>Nombre del cliente:</p>
                    <p className='text-[#B3B3B3]'>{data.comprador}</p>
                  </div>
                  <div className='flex gap-3 flex-wrap'>
                    <p className='text-[#808080]'>Correo electronico:</p>
                    <p className='text-[#B3B3B3]'>{data.correoElectronico}</p>
                  </div>
                  <div className='flex gap-3 flex-wrap'>
                    <p className='text-[#808080]'>Teléfono de contacto:</p>
                    <p className='text-[#B3B3B3]'>{data.telefono}</p>
                  </div>
                </div>
              </div>
              <div>
                <p className='text-[#808080] underline underline-offset-1 mb-5 mt-5'>
                  Datos de pago
                </p>
                <div>
                  <div className='flex gap-3 flex-wrap'>
                    <p className='text-[#808080]'>Método de pago:</p>
                    <p
                      className={`text-[#B3B3B3] ${
                        data.metodoDePago === 'oxxo' && 'capitalize'
                      }`}
                    >
                      {data.metodoDePago === 'card'
                        ? 'Tarjeta de débito / crédito'
                        : data.metodoDePago === 'customer_balance'
                        ? 'Transferencia bancaria'
                        : data.metodoDePago}
                    </p>
                  </div>
                  <div className='flex gap-3 flex-wrap'>
                    <p className='text-[#808080]'>Total:</p>
                    <p className='text-[#B3B3B3]'>
                      {abreviatura}
                      {data?.total?.toLocaleString()}
                    </p>
                  </div>
                  <div className='flex gap-3 flex-wrap'>
                    <p className='text-[#808080]'>Moneda:</p>
                    <p className='text-[#B3B3B3]'>{data.moneda}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={`${
              (user.activeRole === 'DIR_PLANTEL' ||
                user.activeRole === 'ADMIN_OPERATIVO') &&
              'flex flex-col justify-end'
            }`}
          >
            <p className={`text-[#808080] underline underline-offset-1 mb-5 `}>
              Dirección de envío
            </p>
            <div>
              <div className='flex gap-3 flex-wrap'>
                <p className='text-[#808080]'>Nombre de la dirección:</p>
                <p className='text-[#B3B3B3]'>{data.nombreDireccion}</p>
              </div>
              <div className='flex gap-3 flex-wrap'>
                <p className='text-[#808080]'>Dirección:</p>
                <p className='text-[#B3B3B3]'>
                  {data.direccion}
                </p>
              </div>
              <div className='flex gap-3 flex-wrap'>
                <p className='text-[#808080]'>Teléfono de contacto:</p>
                <p className='text-[#B3B3B3]'>
                  {data.telefono}
                </p>
              </div>
              <div className='flex gap-3 flex-wrap'>
                <p className='text-[#808080]'>Otras referencias:</p>
                <p className='text-[#B3B3B3]'>
                  {data.referencias}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p
              className={`pr-16 font-bold rounded-md py-2 ${
                estatus === 'COMPLETADO'
                  ? 'text-[#20AC09]'
                  : estatus === 'ENTREGADO'
                  ? 'text-[#1A3D82]'
                  : estatus === 'CANCELADO'
                  ? 'text-[#FF0000]'
                  : 'text-[#CF9C36]'
              }`}
            >
              {estatus}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DatosDelPedido;
