import React from "react";
import { Trans } from "react-i18next";
import ReactLoading from "react-loading";
function LoadingButton({ isLoading, onClick, aditionalStyles }) {
  return (
    <>
      <div
        onClick={isLoading ? undefined : onClick}
        className={`${aditionalStyles} flex items-center justify-center mx-auto bg-lightBlue text-white px-14 py-3 rounded-full mt-10 text-center w-[250px] ${
          isLoading ? "" : "cursor-pointer"
        }`}
      >
        {isLoading && (
          <ReactLoading
            type="spin"
            color="#fff"
            height={"15%"}
            width={"15%"}
            className="mr-2"
          />
        )}
        <Trans i18nKey={isLoading ? "saving" : "Guardar cambios"}></Trans>
      </div>
    </>
  );
}

LoadingButton.defaultProps = {
  aditionalStyles: "",
};

export default LoadingButton;
