export const MARKETPLACE_ACTION_TYPES = {
    FETCH_MARKETPLACE_START: "marketplace/FETCH_MARKETPLACE_START",
    FETCH_MARKETPLACE_SUCCESS: "marketplace/FETCH_MARKETPLACE_SUCCESS",
    FETCH_MARKETPLACE_FAILED: "marketplace/FETCH_MARKETPLACE_FAILED",
    FETCH_FAVOURITES_START: "marketplace/FETCH_FAVOURITES_START",
    FETCH_FAVOURITES_SUCCESS: "marketplace/FETCH_FAVOURITES_SUCCESS",
    FETCH_FAVOURITES_FAILED: "marketplace/FETCH_FAVOURITES_FAILED",
    FETCH_MARKETPLACE_SEARCH: "marketplace/FETCH_MARKETPLACE_SEARCH",
    FETCH_SHOW_BUSCADOR: "marketplace/FETCH_SHOW_BUSCADOR",
  };
  