import React from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

function TooltipIcon({ tooltip, icon, styles, onClick }) {
  const tooltipId = uuidv4();
  return (
    <>
      <div
        className="font-bold"
        data-tip
        data-for={tooltipId}
        onClick={onClick}
      >
        <img src={icon} alt="" className={`${styles}`} />
        <ReactTooltip
          id={tooltipId}
          place="top"
          effect="solid"
          className="max-w-[25rem] border"
          backgroundColor="#fff"
          borderColor="#000"
          textColor={"#1a3d81"}
        >
          <span className="block">{tooltip}</span>
        </ReactTooltip>
      </div>
    </>
  );
}

TooltipIcon.defaultProps = {
  title: "",
  tooltip: "",
  styles: "font-bold text-black mb-4",
  onClick: undefined,
};

export default TooltipIcon;
