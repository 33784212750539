import React, { Children, cloneElement, createContext, useContext, useEffect, useState } from "react";
import SubHeaderProcesoCompra from "../SubHeaderProcesoCompra";
import BarraProgresoCompra from "../BarraProgresoCompra";
import ResumenCompra from "../ResumenCompra";
import { useSelector } from "react-redux";
import { selectCarritoContador } from "../../../../../reducers/carrito/carritoSelector";
import CarritoIcon from "../../../../../assets/icons/carritoAmarillo.svg";

export const CarritoContext = createContext();

const ProcesoCompraLayout = ({
  estatus,
  setEstatus,
  metodoPago,
  datosEnvio,
  productosComprados,
  setProductosComprados,
  informacionMetodoPago,
  setInformacionMetodoPago,
  children,
}) => {
  const carritoContador = useSelector(selectCarritoContador);
  const [habilitarBotonFinalizarCompra, setHabilitarFinalizarCompra] = useState(false);
  const [contieneErrores, setContieneErrores] = useState(false);

  const value = { informacionMetodoPago, setInformacionMetodoPago };

  useEffect(() => {
    const habilitarBoton = Object.values(informacionMetodoPago.data).every((value) =>
      metodoPago === "TRANSFERENCIA" ? true : value !== ""
    );
    const tieneErrores =
      metodoPago === "TRANSFERENCIA" ? false : Object.values(informacionMetodoPago.errores).length !== 0;
    setHabilitarFinalizarCompra(habilitarBoton);
    setContieneErrores(tieneErrores);
  }, [informacionMetodoPago]);

  useEffect(() => {
    if (metodoPago === "TRANSFERENCIA") {
      setHabilitarFinalizarCompra(true);
    } else if (metodoPago === "TARJETA_CREDITO_DEBITO") {
      setInformacionMetodoPago({
        ...informacionMetodoPago,
        data: { numero: "", fechaExpiracion: "", cvc: "" },
        errores: {},
      });
    } else {
      setInformacionMetodoPago({
        ...informacionMetodoPago,
        data: { clabeInterbancaria: "" },
        errores: {},
      });
    }
    setContieneErrores(false);
  }, [metodoPago]);

  useEffect(() => {
    if(estatus === "Carrito" || estatus === "DatosEnvio"){
      setInformacionMetodoPago({
        ...informacionMetodoPago,
        facturacion: "false"
      });
    }
  },[estatus])

  return (
    <div className="flex flex-col w-full">
      <SubHeaderProcesoCompra estatus={estatus} setEstatus={setEstatus} />
      <BarraProgresoCompra estatus={estatus} setEstatus={setEstatus} />
      {estatus === "Carrito" && (
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-12 mt-6">
          <div className="flex flex-wrap items-center">
            <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 h-11 min-w-[44px] rounded-lg px-3 flex items-center justify-center">
              {carritoContador}
            </p>
            <p className="text-lg">Carrito de compra</p>
          </div>
          <div className="bg-[#FDF2C2] text-[#CF9C36]/60 px-4 py-2 w-full flex-1 rounded-lg flex items-center gap-6">
            <img src={CarritoIcon} alt="" />
            <span className="">
              <p>
                Recuerde que agregar artículos a su carrito <span className="text-[#CF9C36] font-bold">NO</span>{" "}
                significa que estén reservados.
              </p>
            </span>
          </div>
        </div>
      )}
      <div className={`flex flex-col xl:grid grid-cols-3 gap-6 ${estatus === "Carrito" ? "mt-6" : "mt-12"}`}>
        <CarritoContext.Provider value={value}>
          <div className="xl:col-span-2">{children}</div>
        </CarritoContext.Provider>
        <ResumenCompra
          estatus={estatus}
          setEstatus={setEstatus}
          metodoPago={metodoPago}
          habilitarBotonFinalizarCompra={habilitarBotonFinalizarCompra}
          contieneErrores={contieneErrores}
          datosEnvio={datosEnvio}
          informacionMetodoPago={informacionMetodoPago}
          productosComprados={productosComprados}
          setProductosComprados={setProductosComprados}
        />
      </div>
    </div>
  );
};

export default ProcesoCompraLayout;
