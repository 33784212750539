import React from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";

const DatosCuentaModal = ({ isOpen, setIsOpen, estructura, cuentaComercial }) => {
  const { nombre} = estructura;
  const { nombreBeneficiario, banco, numeroCuenta, swiftAba, pais } = cuentaComercial;

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Datos de la cuenta</h1>
      <div className="min-w-[24rem] flex flex-col space-y-4 px-4">
        <div className="flex space-x-4 text-left">
          <p className="font-bold w-28 md:w-fit">Nombre de la estructura:</p>
          <p>{nombre}</p>
        </div>
        <div className="flex space-x-4">
          <p className="font-bold">País:</p>
          <p>{pais?.nombre ?? ""}</p>
        </div>
        <div className="flex space-x-4">
          <p className="font-bold">Beneficiario:</p>
          <p>{nombreBeneficiario}</p>
        </div>
        <div className="flex space-x-4">
          <p className="font-bold">Banco:</p>
          <p>{banco}</p>
        </div>
        <div className="flex space-x-4">
          <p className="font-bold">No. de cuenta:</p>
          <p>{numeroCuenta}</p>
        </div>
        {swiftAba !== "" && (
          <div className="flex space-x-4">
            <p className="font-bold">Código SWIFT/BIC o ABA:</p>
            <p>{swiftAba}</p>
          </div>
        )}
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cerrar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
      </div>
    </NimbusModal>
  );
};

export default DatosCuentaModal;
