import React from "react";
import { useNavigate } from "react-router-dom";
import Image from '../../assets/img/archnimbus-proximamente.png'

const ReportesNotFound = () => {
    let navigate = useNavigate();
  return (
    <div className="w-full bg-gradient-to-b from-[#349FCA] to-[#246EB6 grid align-center justify-center">
      <div>
        <img className="m-auto" src={Image} alt="" />
      </div>
      <div className="pb-8 text-center">
        <h1 className="text-lg italic">
          "Próximamente reportes para que puedas llevar a cabo de mejor manera tu trabajo..."
        </h1>
      </div>
      <div className="m-auto">
        <button className="bg-blue-100 text-gray-500 px-10 py-2 rounded-lg" onClick={() => navigate('/home')}>
          Volver
        </button>
      </div>
    </div>
  );
};

export default ReportesNotFound;
