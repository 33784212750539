import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEnProgreso } from "../../../../reducers/avisoData/avisoDataAction";
import { useGestorAvisosDetalles } from "./GestorAvisosDetallesContext";
import {
  selectAvisoData,
  selectProgress,
} from "../../../../reducers/avisoData/avisoDataSelector";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { postAviso, updateAviso } from "../../../services/Api/GestorAvisos";
import NimbusModal from "../../../fragments/NimbusModal";
import AddListasModal from "./fragments/AddListasModal";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import Notification from "./fragments/Notification";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import moment from "moment-timezone";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

const DEFAULT_TIMEZONE = "America/Mexico_City";

function AvisoFormButtons({ user, activeRole, errors }) {
  const savedDraft = useSelector(selectProgress);

  const [addListasModal, setAddListasModal] = useState(
    savedDraft ? true : false
  );
  const [previewModal, setPreviewModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { draft, editandoTexto, editar } = useGestorAvisosDetalles();
  const [estructura, setEstructura] = useState({});
  const [horario, setHorario] = useState();

  useEffect(() => {
    if (user != null && activeRole !== "") {
      const { tipoEstructuraRequest: estructuraKey, estructuraId } =
        obtenerUserEstructuraInfo(user, activeRole);
      setEstructura({
        key: estructuraKey,
        id: estructuraId,
      });
      setHorario(
        estructuraKey === "plantel"
          ? user?.escuela?.horario
          : user[estructuraKey]?.horario
      );
      moment.tz.setDefault(
        estructuraKey === "plantel"
          ? user?.escuela?.horario?.value
          : user[estructuraKey]?.horario?.value
      );
    }
  }, [user, activeRole]);

  const {
    _id,
    visibilidad,
    nombre,
    categoria,
    descripcion,
    contenido,
    destinatarios,
    envio: { defaultOption, date, time },
    calendario: { selected, fechaInicio, fechaFin },
  } = useSelector(selectAvisoData);

  const isValidContent = contenido.every((obj) =>
    obj.hasOwnProperty("path") ||
    (obj.hasOwnProperty("texto") && obj.texto !== "")
      ? true
      : false
  );

  const disabledButton =
    editandoTexto ||
    nombre === "" ||
    descripcion === "" ||
    categoria === "" ||
    (selected && (fechaInicio == null || fechaFin == null)) ||
    (contenido.length > 0 && !isValidContent) ||
    errors["nombreAviso"];

  const handleSaveDraft = async () => {
    const titulo = nombre.trim();
    if (editar) {
      let newAviso = {
        _id: _id,
        estatus: "BORRADOR",
        visibilidad: visibilidad,
        nombre: titulo,
        categoria: categoria,
        descripcion: descripcion,
        calendario: selected,
        contenidos: contenido,
        destinatarios: [],
      };
      try {
        if (selected)
          newAviso = {
            ...newAviso,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
          };
        await updateAviso(_id, newAviso);
        toast.success(
          "El aviso se ha actualizado exitosamente.",
          alertConfiguration
        );
        navigate("/herramientas/gestor-avisos");
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.message ?? "Error al crear el aviso";
        toast.error(message, alertConfiguration);
      }
    } else {
      let newAviso = {
        creador: user._id,
        nombre: titulo,
        categoria: categoria,
        descripcion: descripcion,
        visibilidad: true,
        estatus: "BORRADOR",
        calendario: selected,
        contenidos: contenido,
        destinatarios: [],
        [estructura.key]: estructura.id,
      };
      try {
        if (selected)
          newAviso = {
            ...newAviso,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
          };
        await postAviso(newAviso);
        toast.success(
          "Aviso guardado como borrador correctamente",
          alertConfiguration
        );
        navigate("/herramientas/gestor-avisos");
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.message ?? "Error al crear el aviso";
        toast.error(message, alertConfiguration);
      }
    }
  };

  const handleSave = async () => {
    const newDestinatarios = destinatarios.map((destinatario) => {
      return {
        ...destinatario,
        listaDifusion: { _id: destinatario?.listaDifusion[0] },
      };
    });
    const titulo = nombre.trim();
    if (editar) {
      let newAviso = {
        _id: _id,
        estatus: defaultOption === "Now" ? "ENVIADO" : "PROGRAMADO",
        visibilidad: visibilidad,
        nombre: titulo,
        categoria: categoria,
        descripcion: descripcion,
        calendario: selected,
        contenidos: contenido,
        destinatarios: newDestinatarios,
      };
      try {
        if (selected)
          newAviso = {
            ...newAviso,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
          };
        if (defaultOption === "Now") {
          newAviso = {
            ...newAviso,
            fechaEnvio: moment().tz(DEFAULT_TIMEZONE).format(),
          };
          await updateAviso(_id, newAviso);
          toast.success(
            "El aviso se ha actualizado exitosamente.",
            alertConfiguration
          );
          navigate("/herramientas/gestor-avisos");
        } else {
          newAviso = {
            ...newAviso,
            fechaEnvio: moment(date + " " + time)
              .tz(DEFAULT_TIMEZONE)
              .format(),
          };
          const outDated = moment(date + " " + time).isBefore(
            moment().tz(horario?.value)
          );
          if (outDated) {
            toast.error(
              "La fecha de envío debe ser mayor a la fecha y hora configurada en la estructura",
              alertConfiguration
            );
          } else {
            await updateAviso(_id, newAviso);
            toast.success(
              "El aviso se ha actualizado exitosamente.",
              alertConfiguration
            );
            navigate("/herramientas/gestor-avisos");
          }
        }
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.message ?? "Error al crear el aviso";
        toast.error(message, alertConfiguration);
      }
    } else {
      let newAviso = {
        creador: user._id,
        nombre: titulo,
        categoria: categoria,
        descripcion: descripcion,
        visibilidad: true,
        estatus: defaultOption === "Now" ? "ENVIADO" : "PROGRAMADO",
        calendario: selected,
        contenidos: contenido,
        destinatarios: newDestinatarios,
        [estructura.key]: estructura.id,
      };
      try {
        if (selected)
          newAviso = {
            ...newAviso,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
          };
        if (defaultOption === "Now") {
          newAviso = {
            ...newAviso,
            fechaEnvio: moment().tz(DEFAULT_TIMEZONE).format(),
          };
          await postAviso(newAviso);
          toast.success("Aviso guardado correctamente", alertConfiguration);
          navigate("/herramientas/gestor-avisos");
        } else {
          newAviso = {
            ...newAviso,
            fechaEnvio: moment(date + " " + time)
              .tz(DEFAULT_TIMEZONE)
              .format(),
          };

          const outDated = moment(date + " " + time).isBefore(
            moment().tz(horario?.value)
          );
          if (outDated) {
            toast.error(
              "La fecha de envío debe ser mayor a la fecha y hora configurada en la estructura",
              alertConfiguration
            );
          } else {
            await postAviso(newAviso);
            toast.success("Aviso guardado correctamente", alertConfiguration);
            navigate("/herramientas/gestor-avisos");
          }
        }
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.message ?? "Error al crear el aviso";
        toast.error(message, alertConfiguration);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-12 md:col-span-12 gap-6 md:place-self-center">
        <div className="grid col-span-2">
          <CancelButton
            text="Cancelar"
            bgColor="bg-divider"
            styles="!w-full"
            onClick={() => navigate("/herramientas/gestor-avisos")}
            disabled={editandoTexto}
          />
        </div>
        <div className="grid col-span-3 text-white">
          <button
            className="min-h-[40px] bg-lightBlue text-white font-semibold rounded"
            disabled={editandoTexto}
            onClick={() => setPreviewModal(true)}
          >
            Previsualizar
          </button>
        </div>
        <div className="grid col-span-4">
          <button
            className="min-h-[40px] text-white font-semibold rounded bg-[#5E5F64] px-4 disabled:bg-[#7b7c80]"
            disabled={disabledButton || !draft}
            onClick={handleSaveDraft}
          >
            Guardar como borrador
          </button>
        </div>
        <div className="grid col-span-3">
          <SubmitButton
            text="Continuar"
            styles="!w-auto min-h-[40px]"
            disabled={disabledButton}
            onClick={() => {
              dispatch(setEnProgreso(true));
              setAddListasModal(true);
            }}
          />
        </div>
      </div>
      <NimbusModal isOpen={addListasModal} styles={customStyles}>
        <AddListasModal
          activeRole={activeRole}
          userId={user._id}
          onCloseModal={() => {
            dispatch(setEnProgreso(false));
            setAddListasModal(false);
          }}
          saveNotification={handleSave}
          horario={horario}
        />
      </NimbusModal>
      <Modal
        isOpen={previewModal}
        style={customStyles}
        contentLabel="My dialog"
      >
        <Notification onClosePreview={() => setPreviewModal(false)} />
      </Modal>
    </>
  );
}

export default AvisoFormButtons;
