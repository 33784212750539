import { _get, _post, _put } from '../../../http';

export const postPais = (body) => _post(`/paises`, body);

export const putPais = (paisId, body) => _put(`/paises/${paisId}`, body);

export const getPaisesEditorial = (editorialId) =>
  _get(`/editoriales/${editorialId}/equivalenciaDolar`);

export const getPaisDetalleEditorial = (editorialId, paisId) =>
  _get(`/editoriales/${editorialId}/equivalenciaDolar/${paisId}`);

export const postCrearEquivalencia = (editorialId, body) =>
  _post(`/editoriales/${editorialId}/equivalenciaDolar`, body);

export const putEquivalencia = (editorialId, equivalenciaId, body) =>
  _put(`/editoriales/${editorialId}/equivalenciaDolar/${equivalenciaId}`, body);
