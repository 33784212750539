import React, { useContext } from "react";
import Tarjeta from "../../../../../assets/img/visa-mastercard-american-express.png";
import { CarritoContext } from "./ProcesoCompraLayout";

const TarjetaPagoExitoso = () => {
  const { informacionMetodoPago } = useContext(CarritoContext);

  return (
    <div className="flex flex-col px-4 pt-4 gap-6">
      <div className="flex justify-between items-center">
        <p>Tarjeta de crédito / Tarjeta de débito</p>
        <img className="h-5 md:h-8" src={Tarjeta} alt="" />
      </div>
      <p>
        {[...informacionMetodoPago.data.numero]
          .slice(0, informacionMetodoPago.data.numero.length - 2)
          .map((_) => "*")
          .join("") +
          informacionMetodoPago.data.numero[informacionMetodoPago.data.numero.length - 2] +
          informacionMetodoPago.data.numero[informacionMetodoPago.data.numero.length - 1]}
      </p>
    </div>
  );
};

export default TarjetaPagoExitoso;
