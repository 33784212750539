import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import { MESSAGES } from "../../../utils/messagesUtils";
import NoDataMessage from "../../../fragments/NoDataMessage";
import ProductosTable from "./fragments/ProductosTable";
import SubHeaderProductos from "./SubHeaderProductos";
import ProductosFilter from "./fragments/ProductosFilter";
import { usePagination } from "../../../hooks/usePagination";
import TablePagination from "../../../fragments/TablePagination";
import { UserContext } from "../../../services/user";
import { getProductosEstructura } from "../../../services/Api/MarketPlace/Productos";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { getPaises } from "../../../services/api";
import { PaisesContext } from "../../../../contexts/PaisesContext";

const Productos = () => {
  const [loading, setLoading] = useState(true);
  const { user, activeRole } = useContext(UserContext);
  const { obtenerPaises  } = useContext(PaisesContext);
  const [productos, setProductos] = useState([]);
  const [productosFiltro, setProductosFiltro] = useState([]);
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(productosFiltro, 20);

  const obtenerProductos = useCallback(async () => {
    if (activeRole !== "") {
      try {
        let {estructura, tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
        await obtenerPaises();
        const productosResponse = await getProductosEstructura(tipoEstructuraRequest, estructuraId);
        const paises = await getPaises()
        setProductos(productosResponse);
        setProductosFiltro(productosResponse);
        if (estructura.dirFiscalPais) {
          const simbolo =
          estructura.dirFiscalPais.nombre === "Estados Unidos" ? 
          ["USD"] 
          : estructura.dirFiscalPais.simbolo 
          ? [estructura.dirFiscalPais.simbolo]
          : [paises.data.filter(pais => pais._id === estructura.dirFiscalPais)[0]?.simbolo];
          setTipoDeMoneda(simbolo);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [activeRole]);

  useEffect(() => {
    obtenerProductos();
  }, [obtenerProductos]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderProductos />
            <div
              className={`${productos.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}
            >
              <div>
                <ProductosFilter productos={productos} productosFiltro={productosFiltro} setProductosFiltro={setProductosFiltro} />
                <ProductosTable productos={currentData()} obtenerProductos={obtenerProductos} tipoDeMoneda={tipoDeMoneda}/>
                {productos.length !== 0 && productosFiltro.length === 0 && (
                  <NoDataMessage message={MESSAGES.MESSAGE_FILTER_PRODUCTOS_TABLE} />
                )}
              </div>
              <div>
                {productos.length === 0 ? (
                  <NoDataMessage message={MESSAGES.MESSAGE_PRODUCTOS_TABLE} />
                ) : (
                  <TablePagination
                    next={next}
                    prev={prev}
                    currentPage={currentPage}
                    data={currentData()}
                    jump={jump}
                    maxPage={maxPage}
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Productos;
