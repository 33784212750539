import React from "react";
import TooltipLabel from "../utils/TooltipLabel";

const SectionCard = ({ icon, title, newStyle, ...otherProps }) => {

  return (
    <>
      {newStyle ? (
        <div className="h-52 cursor-pointer" {...otherProps}>
          <div className="w-full max-w-[200px] h-52 rounded-xl mx-auto aspect-square shadow-lg border mb-3">
            <img src={icon} className="h-full w-full p-6"></img>
          </div>
          <div className="text-center">
            <TooltipLabel
              title={title}               
              tooltip={title} 
            />
          </div>  
        </div>
      ) : (
        <div className="border-2 h-52 w-80 rounded-xl flex bg-white cursor-pointer relative p-4 space-x-4" {...otherProps}>
          <img src={icon} alt={`${icon}.jpg`} className="h-16" />
          <p className="text-nimbusDark font-bold text-xl w-64 h-16 flex items-center">{title}</p>
        </div>
      )}
    </>
  );
};

export default SectionCard;
