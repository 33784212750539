import ReporteAvisosDetallesRow from "./ReporteAvisosDetallesRow";

const ReporteAvisosDetallesTable = ({ destinatarios }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th className="text-center">Nombre completo del usuario</th>
            <th className="text-center">Correo electrónico</th>
            <th className="text-center">Estatus</th>
          </tr>
        </thead>
        <tbody>
          {destinatarios?.map((destinatario) => (
            <ReporteAvisosDetallesRow
              key={destinatario?._id}
              destinatario={destinatario}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReporteAvisosDetallesTable;
