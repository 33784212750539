import { contadorcompras } from "../Actions/ChangeAction";
import {
  CHANGE_PAGE,
  DEFINE_TYPE,
  INSERRT_WORD,
  NOTIFICATION,
  SET_TYPE,
  CONT,
  FAVORITES,
  DELETE_FAV,
  COMPRADOS,
  CLEANCOMPRAS,
  BANDERACOMPRAS,
  CONTADORCOMPRAS,
  CONTADORCOMPRAS2,
  CLEANCONTADORCOM,
  CLEANCONTADORCOM2,
  TABLEMODIFY,
  TABLEMODIFYA,
  TABLEMODIFYITEMS
} from "../Types";

const initialState = {
  page: "Todos",
  word: "",
  type: "",
  tipo: true,
  notificacion: "",
  contador: 0,
  arreglofav: [],
  eliminarfav: "",
  arreglocompras: [],
  banderacompras: true,
  contadorcompras: -1,
  contadorcompras2: -1,
  tablachange: "",
  tablachangeA: "",
  tablachangeItems:''
};
export default function changeReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case INSERRT_WORD:
      return (state = action.payload);
    case DEFINE_TYPE:
      return (state = action.payload);
    case SET_TYPE:
      return {
        ...state,
        tipo: action.payload,
      };
    case NOTIFICATION:
      return {
        ...state,
        notificacion: action.payload,
      };
    case CONT:
      return {
        ...state,
        contador: action.payload,
      };
    case CONTADORCOMPRAS:
      return {
        ...state,
        contadorcompras: state.contadorcompras + 1,
      };
    case CONTADORCOMPRAS2:
      return {
        ...state,
        contadorcompras2: state.contadorcompras2 + 1,
      };
    case FAVORITES:
      return {
        ...state,
        arreglofav: action.payload,
      };
    case DELETE_FAV:
      return {
        ...state,
        eliminarfav: action.payload,
      };
    case CLEANCONTADORCOM:
      return {
        ...state,
        contadorcompras: -1,
      };
    case CLEANCONTADORCOM2:
      return {
        ...state,
        contadorcompras2: -1,
      };
    case BANDERACOMPRAS:
      return {
        ...state,
        banderacompras: action.payload,
      };
    case COMPRADOS:
      return {
        ...state,
        arreglocompras: state.arreglocompras.concat(action.payload),
      };
    case CLEANCOMPRAS:
      return {
        ...state,
        arreglocompras: [],
      };
    case TABLEMODIFY:
      return {
        ...state,
        tablachange: action.payload,
      };
    case TABLEMODIFYA:
      return {
        ...state,
        tablachangeA: action.payload,
      };
    case TABLEMODIFYITEMS:
      return {
        ...state,
        tablachangeItems: action.payload,
      };
    default:
      return state;
  }
}
