import { useState, useEffect, useContext } from "react";
import InputBuscar from "../../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../../fragments/Inputs/InputSelect";
import { TIPOS_DE_CATEGORIA, TIPOS_DE_PRODUCTO, TIPOS_ESTATUS, TIPOS_ORIGEN } from "../../../../utils/productoUtils";
import { GANANCIA_CANTIDAD } from "../../../../utils/asignarProductosUtils";
import { PaqueteProductosContext } from "../../../../../contexts/PaqueteProductosContext";
import { setFiltrosProductos } from "../../../../../reducers/paquetesProductos/paquetesProductosAction";

const FILTRO_PRODUCTOS_INITIAL_VALUES = {
  busqueda: "",
  origen: "",
  tipoProducto: "",
  estatus: "",
  categoria: "",
  ganancia: "",
};

export default function ProductosFilter({ productos, productosFiltro, setProductosFiltro }) {
  const { dispatch: dispatchPaqueteProductos, filtrosProductos } = useContext(PaqueteProductosContext);
  const [filtro, setFiltro] = useState(FILTRO_PRODUCTOS_INITIAL_VALUES);

  const filterDataBusqueda = (productos, busqueda) => {
    if (busqueda === "") return productos;

    const newProductosFiltro = productos.filter(
      (producto) =>
        producto.producto._id.toLowerCase().includes(busqueda) ||
        producto.producto.nombre.toLowerCase().includes(busqueda) ||
        parseFloat(producto.precioVenta) === parseFloat(busqueda)
    );

    return newProductosFiltro;
  };

  const filterSelectOrigen = (origen) => {
    if (origen === "") return productos;
    const newProductosFiltro = productos.filter((producto) => producto.origen === origen);

    return newProductosFiltro;
  };

  const filterSelectTipoProducto = (productos, tipoProducto) => {
    if (tipoProducto === "") return productos;
    const newProductosFiltro = productos.filter((producto) => producto.producto.tipoProducto === tipoProducto);

    return newProductosFiltro;
  };

  const filterSelectEstatus = (productos, estatus) => {
    if (estatus === "") return productos;
    estatus = estatus === "true";
    const newProductosFiltro = productos.filter((producto) => producto.producto.estatus === estatus);

    return newProductosFiltro;
  };

  const filterSelectCategoria = (productos, categoria) => {
    if (categoria === "") return productos;
    const newProductosFiltro = productos.filter((producto) => producto.producto.categoria === categoria);

    return newProductosFiltro;
  };

  const filterSelectGanancia = (productos, ganancia) => {
    if (ganancia === "") return productos;
    let newProductosFiltro = [];
    switch (ganancia) {
      case "Ninguna":
        newProductosFiltro = productos.filter((producto) => producto.precioVenta - producto.costo === 0)
        break;
      case "Saldo positivo":
        newProductosFiltro = productos.filter((producto) => producto.precioVenta - producto.costo > 0)
        break;
      case "Saldo negativo":
        newProductosFiltro = productos.filter((producto) => producto.precioVenta - producto.costo < 0);
        break;
      default:
        break;
    }

    return newProductosFiltro;
  };

  useEffect(() => {
    let newProductosFiltro = filterSelectOrigen(filtro.origen);
    newProductosFiltro = filterSelectTipoProducto(newProductosFiltro, filtro.tipoProducto);
    newProductosFiltro = filterSelectEstatus(newProductosFiltro, filtro.estatus);
    newProductosFiltro = filterSelectCategoria(newProductosFiltro, filtro.categoria);
    newProductosFiltro = filterSelectGanancia(newProductosFiltro, filtro.ganancia);
    newProductosFiltro = filterDataBusqueda(newProductosFiltro, filtro.busqueda.toLowerCase());
    setProductosFiltro(newProductosFiltro);
    dispatchPaqueteProductos(setFiltrosProductos(filtro));
  }, [filtro, productos]);

  useEffect(() => {
    if(Object.keys(filtrosProductos).length > 0){
      setFiltro(filtrosProductos);
    } else {
      dispatchPaqueteProductos(setFiltrosProductos(filtro));
    }
  }, [])

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {productosFiltro.length}
          </p>
          <p className="text-black">Productos</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className="flex flex-col lg:grid lg:grid-cols-3 xl:grid-cols-5 gap-3 w-full">
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Ganancia"
              options={GANANCIA_CANTIDAD}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-36 xl:!w-32 2xl:!w-40"
              onChange={(e) => setFiltro({ ...filtro, ganancia: e.target.value })}       
              value={filtro.ganancia}       
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Origen"
              options={TIPOS_ORIGEN}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-36 xl:!w-32 2xl:!w-40"
              onChange={(e) => setFiltro({ ...filtro, origen: e.target.value })}
              value={filtro.origen}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Tipo de producto"
              options={TIPOS_DE_PRODUCTO}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-36 xl:!w-32 2xl:!w-40"
              onChange={(e) => setFiltro({ ...filtro, tipoProducto: e.target.value })}
              value={filtro.tipoProducto}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Estatus"
              options={TIPOS_ESTATUS}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-36 xl:!w-32 2xl:!w-40"
              onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
              value={filtro.estatus}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Categoría"
              options={TIPOS_DE_CATEGORIA}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-36 xl:!w-32 2xl:!w-40"
              onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
              value={filtro.categoria}
            />
          </div>
        </div>
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
          <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} ocupaValue={true} value={filtro.busqueda} />
        </div>
      </div>
    </div>
  );
}
