import { useNavigate } from 'react-router-dom'
import Image from '../../assets/img/archnimbus-proximamente.png'

export default function NotFound(props) {
    let navigate = useNavigate()
    return(
        <div className="w-full bg-gradient-to-b from-[#349FCA] to-[#246EB6 grid align-center justify-center">
            <img src={Image} alt=""/>
            {props.reportes && (

            <p>"Proximamente reportes que te ayudarán a tomar mejores desiciones"</p>
            )}
            {props.herramientas && (

            <p>"Proximamente herramientas para que puedas llevar a cabo de mejor manera tu trabajo"</p>
            )}
            <button onClick={()=>navigate("/home")}>Volver</button>
        </div>
    )
}