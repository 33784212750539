import { isSameMinute } from "date-fns";
import React,{useState, useEffect} from "react";
import { postUserCheck } from "../../../services/api";
import Lightbox from "./ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "./ActividadesUtils/Lightbox/LightboxWrapper";
import ImagenValidada from "./ActividadesUtils/RecursosValidados/ImagenValidada";

const CheckList = ({ listData, title, checksArray, postBody, escuela, campus, grupo, usuario, materia, evaluable, instrucciones }) => {
  const [disabled, setDisabled] = useState([])
  const [checkedArr, setCheckedArr] = useState(checksArray)
  const [lightboxImage, setLightboxImage] = useState('')

  useEffect(()=>{
    setCheckedArr(checksArray)
  },[checksArray])

  const handleCheckSave = (itemId) =>{
    if(evaluable){
      setDisabled([...disabled, itemId])
      if(checkedArr.includes(itemId)){
        const newArr = checkedArr.filter((item)=>{
          return item !== itemId
        })
        setCheckedArr(newArr)
      }else{
        setCheckedArr([...checkedArr, itemId])
      }
      const newBody = {...postBody, ...{'checklist':itemId}, ...{'esSeleccionado':!checkedArr.includes(itemId)}}
      postUserCheck(escuela, campus, grupo, materia, usuario, newBody)
      .then((res)=>{
          setDisabled([])
      })
    }
  }

  const orderedList = listData.sort((a, b)=>{
    if(a.orden > b.orden){
      return 1
    }else{
      return -1
    }
  })
  
  return (
    <>
    <Lightbox lightboxValue={lightboxImage} closeLightbox={()=>setLightboxImage('')}/>
    <div className="w-full rounded-2xl border-4 border-[#3f61a4] break-words my-8 whitespace-pre-wrap">
      <h2 className="text-lg md:text-4xl text-white text-center bg-[#1a3d82] rounded-[10px] py-[10px] px-4 md:px-20 relative top-[-30px] w-fit mx-auto max-w-[90%]">{title === '' ? 'Materiales' : title}</h2>
    {instrucciones && <div className="!max-w-[1200px] act-instructions text-sm md:text-base !px-14 !mb-4">{instrucciones}</div>}
      <div className="grid grid-cols-1 lg:grid-cols-2 md:p-10 md:pt-0">
        {orderedList.map((item) => (
          <div className="flex items-center justify-around text-nimbus bg-[#f5f5f5] m-2 p-6 rounded-2xl">
            <input 
              type="checkbox" 
              className={`mr-8 p-2 md:scale-150 rounded-md ${disabled.includes(item._id)  ? 'pointer-events-none' : ''}`} 
              checked={checkedArr.includes(item._id) || !evaluable}
              onChange={()=> evaluable ? handleCheckSave(item._id) : undefined}
            />
            <div className="text-[#697d99] text-base md:text-lg flex-1 px-2">{item.texto}</div>
            {item.piezas && <div className="text-[#697d99] text-base md:text-lg">{item.piezas}</div>}
            {item.imagen && 
              <LightboxWrapper handleClick={()=>setLightboxImage(item.imagen)}>
                <ImagenValidada
                  src={item.imagen}
                  className={item.imagen ? "w-16 h-16 md:w-32 md:h-32  object-cover rounded-lg block border shadow" : "hidden"}
                  alt=""
                />
              </LightboxWrapper>
            }
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

CheckList.defaultProps = {
  title:'Materiales',
  checksArray:[],
  listData:[],
  escuela:'',
  campus:'',
  grupo:'',
  usuario:'',
  materia:'',
  evaluable:false
}

export default CheckList;
