import lamejs from "lamejs";
import { v4 as uuidv4 } from "uuid";
import {toast } from 'react-toastify';
import { Trans } from "react-i18next";

export const dummyData = [
    {
      _id: uuidv4(),
      imagen:
        "https://s8.gifyu.com/images/dani_montesinos_symio_28.09.2020.gif",
      texto:
        "It won't do any good to hide from me.	"
    },
    {
      _id: uuidv4(),
      imagen:
        "https://www.avantel.co/blog/wp-content/uploads/2019/07/4.8-actividades-al-aire-libre-para-los-m%C3%A1s-citadinos-1.jpg",
      texto: "It's very kind of you to see me at short notice."
    },
    {
      _id: uuidv4(),
      imagen:
        "https://blog.seccionamarilla.com.mx/wp-content/uploads/2020/03/actividades-para-ninos-en-casa.jpg",
      texto: "It was after midnight when I left the office."
    }
  ]


  export const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width:'100%',
      maxWidth:"1200px",
      maxHeight:"90vh",
      borderRadius:'.5rem',
      paddingTop:"5rem",
      paddingLeft:'2.5rem',
      paddingRight:'2.5rem',
    },
  };

  export const modalSmStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width:'100%',
      maxWidth:"600px",
      maxHeight:"90vh",
      borderRadius:'.5rem',
      paddingTop:"2rem",
      paddingLeft:'2.5rem',
      paddingRight:'2.5rem',
    },
  };

  export const extImagen = ['png', 'jpg', 'jpeg', 'gif']
  export const extAudio = ['mp3']
  export const extVideo = ['mp4']


//mp3 encoder
export function sliceAudioBuffer(ctx, buffer, start, end) {
  const duration = buffer.duration;
  const channels = buffer.numberOfChannels;
  const rate = buffer.sampleRate;

  if (start < 0) start = 0;

  if (end > duration) end = duration;

  const startOffset = rate * start;
  const endOffset = rate * end;
  const frameCount = endOffset - startOffset;

  let newAudioBuffer = ctx.createBuffer(
    channels,
    endOffset - startOffset,
    rate
  );
  const tempArray = new Float32Array(frameCount);
  const offset = 0;

  for (let channel = 0; channel < channels; channel++) {
    buffer.copyFromChannel(tempArray, channel, startOffset);
    newAudioBuffer.copyToChannel(tempArray, channel, offset);
  }

  return newAudioBuffer;
}

export function concatAudioBuffers(ctx, ...buffers) {
  const numberOfChannels = Math.min(
    ...buffers.map((buffer) => buffer.numberOfChannels)
  );
  const tmp = ctx.createBuffer(
    numberOfChannels,
    buffers.reduce((a, c) => a + c.length, 0),
    buffers[0].sampleRate
  );
  for (var i = 0; i < numberOfChannels; i++) {
    const channel = tmp.getChannelData(i);
    let totalOffset = 0;
    buffers.forEach((buffer, j) => {
      if (j !== 0) totalOffset += buffers[j - 1].length;
      channel.set(buffer.getChannelData(i), totalOffset);
    });
  }
  return tmp;
}

function float32ToInt16(float32) {
  let i = 0;
  const dataAsInt16Array = new Int16Array(float32.length);

  while (i < float32.length) {
    dataAsInt16Array[i] = convert(float32[i++]);
  }

  function convert(n) {
    var v = n < 0 ? n * 32768 : n * 32767; // convert in range [-32768, 32767]
    return Math.max(-32768, Math.min(32768, v)); // clamp
  }

  return dataAsInt16Array;
}

export function encodeMp3(buffer) {
  let mp3Data = [];

  const channels = buffer.numberOfChannels;
  const sampleRate = buffer.sampleRate;
  const kbps = 128;

  const channelData = [];

  for (let i = 0; i < channels; i++) {
    channelData.push(float32ToInt16(buffer.getChannelData(i)));
  }

  const encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
  let mp3Tmp = encoder.encodeBuffer(...channelData);

  mp3Data.push(mp3Tmp);
  mp3Tmp = encoder.flush();
  mp3Data.push(mp3Tmp);

  return new Blob(mp3Data, {
    type: "audio/mp3"
  });
}

export const customToast = (translationKey) =>{
  const options = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined}
  toast(() => <Trans i18nKey={translationKey}></Trans>, options);
}


export const successToast = (message) =>{

  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const errorToast = (message) =>{
  toast.warning(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}
export const infoToast = (message) =>{
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}


export const customEditorToolbar = ['fontFamily', 'fontBackgroundColor','fontColor', 'fontSize','|','mediaEmbed' , 'imageInsert', 'bold', 'italic','underline','bulletedList', 'numberedList','indent', 'outdent','alignment', '|' ,'highlight', 'specialCharacters', 'strikeThrough','subscript','superscript','removeFormat','|', 'undo', 'redo', 'insertTable', 'blockQuote', 'todoList','horizontalLine','codeBlock', 'link','code']

export const editorImageConfig = {
  styles: ["alignLeft", "alignCenter", "alignRight"],
  sizes: ["50%", "75%", "100%"],
  resizeOptions: [
    {
      name: "imageResize:original",
      label: "Original",
      value: null
    },
    {
      name: "imageResize:50",
      label: "50%",
      value: "50"
    },
    {
      name: "imageResize:75",
      label: "75%",
      value: "75"
    }
  ],
  toolbar: [
    {
      name: 'imageStyle:icons',
      title: 'Centrar sin texto',
      items: [
        "imageStyle:alignBlockLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignBlockRight",
      ],
      defaultItem: "imageStyle:alignCenter",
    },
    {
      name: 'imageStyle:iconss',
      title: 'Centrar con texto',
      items: [
        "imageStyle:alignLeft",
        "imageStyle:alignRight",
      ],
      defaultItem: "imageStyle:alignLeft",
    },
    
    "|",
    "imageResize",
    "|",
    "imageTextAlternative",
    'toggleImageCaption'
  ]
}

export const editorLinkConfig = {
  // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
  addTargetToExternalLinks: true,
}

export const listProperties = {
  properties: {
      styles: true,
      startIndex: true,
      reversed: true
  }
}

export const removeImage = (arr, objectid) => {
  return arr.map((el) => {
    if (el._id === objectid) return { ...el, imagen: "" };
    else return el;
  });
};