import React from "react";
import { useNavigate } from "react-router-dom";

function PrevBreadCrumb({ title, route }) {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (!route) return;
    else if (route === "RETURN_ONE") {
      navigate(-1);
      return;
    }
    navigate(route);
  };

  return (
    <div
      onClick={() => handleNavigation()}
      className={`ml-2 inline text-nimbusDark opacity-80 text-lg ${
        route && "cursor-pointer brightness-105"
      }`}
    >
      {title} /{" "}
    </div>
  );
}

export default PrevBreadCrumb;
