export const ESTATUS = [
  {
    _id: 'adquirido',
    nombre: 'Adquirido',
  },
  {
    _id: 'sinAdquirir',
    nombre: 'Sin adquirir',
  },
];

export const COSTO = [
  {
    _id: '0 - 500',
    nombre: '0.00 - 500.00',
  },
  {
    _id: '501 - 1000',
    nombre: '501.00 - 1,000.00',
  },
  {
    _id: '1001 - 5000',
    nombre: '1,001.00 - 5,000.00',
  },
  {
    _id: 'Más de 5001',
    nombre: 'Más de 5,001.00',
  },
];
