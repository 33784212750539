import { ARTICULOS_ACTION_TYPES } from "./asignarArticulosTypes";

export const asignarArticulosReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ARTICULOS_ACTION_TYPES.SET_ARTICULOS:
      return {
        ...state,
        articulos: payload,
      };
    case ARTICULOS_ACTION_TYPES.SET_ARTICULOS_ERRORES:
      return {
        ...state,
        errores: payload,
      };
    case ARTICULOS_ACTION_TYPES.SET_ES_AGREGAR_ARTICULOS_ABIERTO:
      return {
        ...state,
        esAgregarArticulosAbierto: payload,
      };
    case ARTICULOS_ACTION_TYPES.SET_HABILITAR_BOTON:
      return {
        ...state,
        habilitarBoton: payload,
      };

    default:
      return state;
  }
};
