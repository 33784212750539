import React, { useMemo } from "react";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { useNavigate } from "react-router-dom";
import ResumenCompraProducto from "../ProcesoDeCompra/ResumenCompraProducto";
import { formatCurrency } from "../../../utils/productoUtils";

const ResumenCompra = ({ compra }) => {
  const navigate = useNavigate();
  const { articulos, total, subTotal } = compra;

  const cantidadArticulos = useMemo(() => {
    return articulos.reduce((acc, articulo) => {
      return acc + articulo.cantidad ?? 0;
    }, 0);
  }, [articulos]);

  return (
    <div className="w-full">
      <div className={`overflow-auto w-full ${false && "max-h-[34rem]"}`}>
        <div className={`overflow-auto w-full ${articulos.length > 5 && "max-h-[34rem]"}`}>
          <table className="w-full table-auto font-sans border-b-2">
            <thead className="bg-gray-100 w-full sticky top-0">
              <tr className="text-left text-gray-500 font-semibold h-12 w-ful">
                <th className="px-4" colSpan={3}>
                  Resumen de compra
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {articulos.map((producto) => (
                <ResumenCompraProducto
                  key={producto._id}
                  producto={producto}
                  esPaquete={producto.paquete ? true : false}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="py-2 px-4 flex flex-col gap-4 bg-white">
        <div className="flex justify-between">
          <p>Subtotal ({cantidadArticulos} artículos):</p>
          <p className="text-gray-400">{formatCurrency(subTotal)}</p>
        </div>
        <div className="flex justify-between">
          <p>Total:</p>
          <p> {formatCurrency(total)}</p>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-4">
        <SubmitButton
          text={"Seguir comprando"}
          styles="h-10 !w-full"
          bgColor="bg-sidebar"
          onClick={() => navigate("/marketplace/tienda/ProductosDestacados")}
        />
        <SubmitButton
          text={"Ir a mis compras"}
          styles="h-10 !w-full"
          onClick={() => navigate("/marketplace/misCompras")}
        />
      </div>
    </div>
  );
};

export default ResumenCompra;
