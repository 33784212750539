import React from "react";
import PaquetesProductosRow from "./PaquetesProductosRow";

const PaquetesProductosTable = ({ paquetes, tipoDeMoneda, obtenerPaquetes }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            <th className="text-center">Paquete</th>
            <th className="text-center">
              <div className="flex gap-2 items-center justify-center">
                Costo ({tipoDeMoneda[0]})
              </div>
            </th>
            <th className="text-center">
              <div className="flex gap-2 items-center justify-center">
                Precio de venta ({tipoDeMoneda[0]})
              </div>
            </th>
            <th className="text-center">
              <div className="flex gap-2 items-center justify-center">
                Ganancia ({tipoDeMoneda[0]})
              </div>
            </th>
            <th className="text-center">Origen</th>
            <th className="text-center">Estatus</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {paquetes.map((paquete) => (
            <PaquetesProductosRow key={paquete._id} paqueteInfo={paquete} obtenerPaquetes={obtenerPaquetes} tipoDeMoneda={tipoDeMoneda}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaquetesProductosTable;
