import React, { useEffect, useState } from 'react';
import { ESTATUS } from '../../../utils/facturacionUtils';
import InputBuscar from '../../../fragments/Inputs/InputBuscar';
import InputSelect from '../../../fragments/Inputs/InputSelect';

const FILTRO_FACTURACION_INITIAL_VALUES = {
  busqueda: '',
  estatus: '',
};

const FacturacionFilter = ({
  facturacionList,
  facturacionFiltro,
  setFacturacionFiltro,
}) => {
  const [filtro, setFiltro] = useState(FILTRO_FACTURACION_INITIAL_VALUES);

  const filterDataBusqueda = (facturas, busqueda) => {
    if (busqueda === '') return facturas;

    const newFacturasFiltro = facturas.filter((factura) =>
      factura.compra.toLowerCase().includes(busqueda)
    );
    return newFacturasFiltro;
  };

  const filterSelectEstatus = (facturas, estatus) => {
    if (estatus === '') return facturas;
    const newFacturasFiltro = facturas.filter(
      (factura) => factura.estatus === estatus
    );
    return newFacturasFiltro;
  };

  useEffect(() => {
    let newFacturasFiltro = facturacionList;
    newFacturasFiltro = filterDataBusqueda(newFacturasFiltro, filtro.busqueda.toLowerCase());
    newFacturasFiltro = filterSelectEstatus(newFacturasFiltro, filtro.estatus);
    setFacturacionFiltro(newFacturasFiltro);
  }, [filtro, facturacionList]);

  return (
    <div className='w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start'>
      <div className='flex gap-x-5'>
        <div className='flex flex-wrap items-center'>
          <p className='mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center'>
            {facturacionFiltro.length}
          </p>
          <p className='text-black'>Historial de facturación</p>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row space-x-3 items-end lg:items-start'>
        <div className='flex flex-col gap-3 w-full'>
          <div className='w-full sm:flex sm:justify-end'>
            <InputSelect
              label='Estatus'
              options={ESTATUS}
              topLabel={true}
              styles='!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48'
              onChange={(e) =>
                setFiltro({ ...filtro, estatus: e.target.value })
              }
            />
          </div>
        </div>
        <div className='flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit'>
          <InputBuscar
            onChange={(e) =>
              setFiltro({ ...filtro, busqueda: e.target.value })
            }
            value={filtro.busqueda}
          />
        </div>
      </div>
    </div>
  );
};

export default FacturacionFilter;
