import React from "react";
import ProductosPaqueteRow from "./ProductosPaqueteRow";

const ProductosPaqueteTable = ({ productos }) => {
  return (
    <div className="overflow-x-auto w-full">
      <table className="w-full table-auto font-sans">
        <thead className="w-full">
          <tr className="text-left font-semiboldw-full">
            <th className="w-3/4 px-4"></th>
            <th className="w-1/4 px-4"></th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto) => (
            <ProductosPaqueteRow
              key={producto._id}
              nombre={producto.producto.producto.nombre}
              cantidad={producto.cantidad}
              estatus={producto.producto.producto.estatus}
              imagen={producto.producto ? producto.producto.producto.imagen : producto.paquete.paquete.imagen}
              carrusel={producto.producto ? producto.producto.producto.carrusel : producto.paquete.paquete.carrusel}
              esPaquete={producto.paquete ? true : false}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductosPaqueteTable;
