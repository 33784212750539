import React from 'react'
import { Trans } from 'react-i18next'

function CerrarActButton({onClick, text}) {
  return (
    <button 
        onClick={onClick} 
        className="border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100 cursor-pointer w-fit"
    >
      <Trans>{text}</Trans>
    </button>
  )
}

CerrarActButton.defaultProps = {
  onClick:undefined, 
  text:'Cerrar'
}

export default CerrarActButton