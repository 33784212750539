import { _get, _put } from "../../../http";

// comisión de uso de la plataforma
export const getComisionGeneral = () => _get("/comisionDeUso/general");

export const putComisionGeneral = (body) => _put("/comisionDeUso/general", body);

export const putConfiguracionPersonalizada = (body) => _put("/comisionDeUso/configPersonalizada/cambiarEstatus", body);

export const putComisionUsoPersonalizada = (body) => _put("/comisionDeUso/configPersonalizada/editarPorcentaje", body);
