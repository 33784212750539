import React, { useState } from 'react';
import EditarIcon from '../../../../assets/icons/Edit.svg';
import VisualizarIcon from '../../../../assets/icons/viewIcon.svg';
import EliminarIcon from '../../../../assets/icons/deleteIcon.svg';
import { useNavigate } from 'react-router-dom';
import EliminarCategoriaNotificacionModal from './EliminarCategoriaNotificacionModal';
import { toast } from 'react-toastify';
import alertConfiguration from '../../../utils/alertsUtils';
import { deleteCategoriaNotificacion } from '../../../services/Api/CategoriaNotificaciones';
import { ICONOS_CATEGORIAS } from '../../../utils/categoriaNotificacionesUtils';
import '../../../../colors.css'

const CategoriasNotificacionesRow = ({ categoriaNotificacion, obtenerCategoriasNotificaciones }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { _id, nombre, icono, esEliminado } = categoriaNotificacion;

  const handleEliminarCategoria = async () => {
    try {
      await deleteCategoriaNotificacion(_id);
      await obtenerCategoriasNotificaciones();
      setOpenModal(false);
      toast.success('Categoría de notificación eliminada correctamente', alertConfiguration);
    } catch (error) {
      console.log(error);
      toast.error('Ocurrió un error al eliminar la categoría de notificación', alertConfiguration);
    }
  };

  return (
    <>
      {!esEliminado && (
        <tr className='p-2 text-left bg-white'>
          <td className='border-2 rounded-l-lg border-r-0 text-left font-sans'>
            <img className={'fondo-azul-folder mx-auto w-8 h-8'} src={ICONOS_CATEGORIAS[icono]} title={nombre}/>
          </td>
          <td className='border-y-2 font-sans text-left'>
            {nombre}
          </td>
          <td className='border-2 rounded-r-md border-l-0 text-center font-sans flex justify-around min-h-[48px]'>
            {nombre !== 'General' && (
              <button
                title='Editar categoría'
                className='w-5'
                onClick={() => navigate(`/herramientas/categoriasNotificaciones/${_id}/editar`)}
              >
                <img src={EditarIcon} className='w-5 h-5' />
              </button>
            )}
            <button
              title='Ver categoría'
              className='w-5'
              onClick={() => navigate(`/herramientas/categoriasNotificaciones/${_id}/ver`)}
            >
              <img src={VisualizarIcon} className='w-5 h-5' />
            </button>
            {nombre !== 'General' && (
              <button
                title='Eliminar categoría'
                className='w-5'
                onClick={() => setOpenModal(true)}
              >
                <img src={EliminarIcon} className='w-5 h-5' />
              </button>
            )}
          </td>
        </tr>
      )}
      <EliminarCategoriaNotificacionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleSubmit={() => handleEliminarCategoria()}
        categoriaNotificacion={categoriaNotificacion}
      />
    </>
  );
};

export default CategoriasNotificacionesRow;
