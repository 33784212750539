import React, { useContext, useEffect, useState } from "react";
import { CarritoContext } from "./ProcesoCompraLayout";
import { postComprasCheckoutSession } from "../../../../services/Api/MarketPlace/Compras";
import { selectResumenCompraPrecioTotal, selectResumenCompraProductosRequest } from "../../../../../reducers/carrito/carritoSelector";
import { useSelector } from "react-redux";
import { UserContext } from "../../../../services/user";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { stripePromise } from "../../../../utils/stripe";

const OxxoPago = ({datosEnvio}) => {
  const { informacionMetodoPago } = useContext(CarritoContext);
  const [clientSecret, setClientSecret] = useState("");
  const precioTotal = useSelector(selectResumenCompraPrecioTotal);
  const resumenCompra = useSelector(selectResumenCompraProductosRequest);
  const { user } = useContext(UserContext);


  useEffect(() => {
    let body = {
      usuario: {
        _id: user._id,
      },
      plantel: {
        _id: user.escuela.campus._id,
      },
      datosEnvio: {
        _id: datosEnvio._id,
      },
      factura: informacionMetodoPago.factura === "true",
      subTotal: precioTotal,
      total: precioTotal,
      carrito: resumenCompra,
      metodoDePago: "oxxo",
    };

    postComprasCheckoutSession(body).then((response) => setClientSecret(response.clientSecret));
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
};

export default OxxoPago;
