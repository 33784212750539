import React from "react";
import { toast } from "react-toastify";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import NimbusModal from "../../../../fragments/NimbusModal";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import alertConfiguration from "../../../../utils/alertsUtils";
import { deleteProducto } from "../../../../services/Api/MarketPlace/Productos";

const EliminarProductoModal = ({ isOpen, setIsOpen, nombre, productoId, obtenerProductos }) => {

  const configuracionPersonalizadaSubmit = async () => {
    try {
      await deleteProducto(productoId);
      toast.success("Producto eliminado correctamente.", alertConfiguration);
      obtenerProductos();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setIsOpen(false);
    }
  };
  const [formStatus, handleSubmit] = useFormSubmit(configuracionPersonalizadaSubmit);
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Eliminar producto</h1>
      <p className="w-96 px-4 sm:px-2">
        Al eliminar este producto, será retirado de todas las estructuras donde haya sido asignado, de los paquetes
        donde esté incluido, de la tienda del plantel y en caso de que algún padre de familia lo tenga agregado en
        favoritos o en su carrito de compras, también será eliminado de ellos.
      </p>
      <p className="w-96 px-4 text-black break-words font-bold">{nombre}</p>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea eliminar este producto?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default EliminarProductoModal;
