import React from "react";
import NimbusModal from "../../../../fragments/NimbusModal";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import { toast } from "react-toastify";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import alertConfiguration from "../../../../utils/alertsUtils";
import { deleteProductoCarritoDeCompras } from "../../../../services/Api/MarketPlace/CarritoDeCompras";
import { useDispatch } from "react-redux";
import { fetchCarritoAsync } from "../../../../../reducers/carrito/carritoAction";

const EliminarProductoCarritoModal = ({ isOpen, setIsOpen, nombreProducto, productoId }) => {
  const dispatch = useDispatch();

  const eliminarProductoSubmit = async () => {
    try {
      await deleteProductoCarritoDeCompras(productoId);
      toast.success("Artículo eliminado correctamente.", alertConfiguration);
      dispatch(fetchCarritoAsync())
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setIsOpen(false);
    }
  };
  const [formStatus, handleSubmit] = useFormSubmit(eliminarProductoSubmit);

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold w-64 mx-auto text-center">
        ¿Está seguro que desea eliminar este artículo del carrito?
      </h1>
      <p className="w-96 px-4 text-black break-words font-bold">{nombreProducto}</p>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default EliminarProductoCarritoModal;
