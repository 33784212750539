import React, { useEffect, useState } from 'react';
import InputBuscar from '../../../fragments/Inputs/InputBuscar';

const FILTRO_FORMADOR_INITIAL_VALUES = {
  busqueda: '',
};

const FormadorFilter = ({
  formadores,
  formadoresFiltro,
  setFormadoresFiltro,
}) => {
  const [filtro, setFiltro] = useState(FILTRO_FORMADOR_INITIAL_VALUES);

  const filterDataBusqueda = (formadores, busqueda) => {
    if (busqueda === '') return formadores;

    const newFormadoresFiltro = formadores.filter((formador) =>
      formador.nombre.toLowerCase().includes(busqueda) ||
      formador.correo.toLowerCase().includes(busqueda) ||
      formador.telefono.toLowerCase().includes(busqueda)
    );
    return newFormadoresFiltro;
  };

  useEffect(() => {
    let newFormadoresFiltro = formadores;
    newFormadoresFiltro = filterDataBusqueda(
      newFormadoresFiltro,
      filtro.busqueda.toLowerCase()
    );
    setFormadoresFiltro(newFormadoresFiltro);
  }, [filtro, formadores]);

  return (
    <div className='w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start'>
      <div className='flex gap-x-5'>
        <div className='flex flex-wrap items-center'>
          <p className='mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center'>
            {formadoresFiltro.length}
          </p>
          <p className='text-black'>Autores</p>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row space-x-3 items-end lg:items-start'>
        <div className='flex-shrink-0 mt-3 lg:mt-0 w-full'>
          <InputBuscar
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
};

export default FormadorFilter;
