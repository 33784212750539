import { _get, _post, _delete } from "../../../http";

//Endpoints para la gestión de Mis Compras

export const postCompras = (body) => _post("/market-place/compras", body);

export const postComprasCheckoutSession = (body) => _post("/market-place/compras/stripe/create-checkout-session", body);

export const getComprasSessionStatus = (sessionId) =>
  _get(`/market-place/compras/stripe/session-status?session_id=${sessionId}`);

export const getCompras = (usuarioId) => _get(`/market-place/compras/obtenerPorUsuario/${usuarioId}`);
