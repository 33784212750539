import React from "react";

function TextEditorButtons({ handleCancelar, handleSave }) {
  return (
    <div className="relative bg-white pt-4 pb-2">
      <div className="flex justify-end">
        <button
          onClick={() => handleCancelar()}
          className="w-auto btn-lightBlue font-bold mr-2"
        >
          Cancelar
        </button>
        <button onClick={handleSave} className="w-auto btn-nimbus font-bold">
          Guardar
        </button>
      </div>
    </div>
  );
}

export default TextEditorButtons;
