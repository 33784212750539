import React, { useEffect, useState } from 'react'
import OrdenarOracionesSlide from "./OrdenarOracionesSlide"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useContext } from "react";
import { ActividadContext } from "../../../Actividades/ActividadesUtils/ActWrapper";

const ActividadOrdOraciones = ({oraciones, isVertical, evaluable}) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [puntuacion, setPuntuacion] = useState(null)
  const [calificando, setCalificando]  = useState(false)
  const [location, setLocation ] = useState(0)
  const activityContext = useContext(ActividadContext)

  const handleNextItem = (e) => {
    if(location + 1 < oraciones.length){
      setLocation(location + 1)
    }
  };

  const handlePreviousItem = () => {
    if(location !== 0){
      setLocation(location - 1)
    }
  };

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const reactivosTotales = oraciones.map((item)=>{
    return item.oraciones.map((el)=>{
      return el
    })
  }).flat()

  const calificacion = (puntuacion / reactivosTotales.length) * 100

  const calificar = (puntuacion) =>{
    setPuntuacion(prev => {
        return prev + puntuacion
      });
  }

  const subirCalificacion = () =>{
    activityContext.postCalificacionActividad(calificacion)
  }

  useEffect(()=>{
    if(calificando){
      if(activityContext !== undefined){
        subirCalificacion()
      }else{
          setCalificando(false)
          setPuntuacion(null)
      }
    }
  },[puntuacion])

  const reiniciarPuntuacion= ()=>{
    //setPuntuacion(0)
  }

  return (
    <div className={`my-10 w-full`}>
        {oraciones.map((item, index)=>(
          <div className={`${location == index ? "block text-center" : 'hidden'}`}>
            <OrdenarOracionesSlide 
                oraciones={item} 
                isMobile={width <= 768} 
                isVertical={isVertical}
                calificar={calificar}
                calificando={calificando}
                setCalificando={setCalificando}
                reiniciarPuntuacion={reiniciarPuntuacion}
                evaluable={evaluable}
            />
          </div>
        ))}
        {/* <SwitcherControls/>             */}
        <div className="flex w-full justify-center items-center py-4 transform scale-[.9] md:scale-100">
          <div className="bg-black text-white p-2 md:p-3 rounded h-min cursor-pointer pointer-events-auto" onClick={handlePreviousItem}>
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </div>
          <div className="p-2 md:p-4">{`${location + 1} de ${oraciones.length}`}</div>
          <div className="bg-black text-white p-2 md:p-3 rounded h-min cursor-pointer pointer-events-auto" onClick={handleNextItem}>
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </div>
        </div>
        {evaluable ?
        <button 
          onClick={()=>{setCalificando(true)}}
          className={`border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100`}
        >
          Enviar todos
        </button>
        :''}
    </div>
  )
}

ActividadOrdOraciones.defaultProps={
  evaluable:true,
  oraciones:[],
  isVertical:false
}

export default ActividadOrdOraciones