import { createSelector } from "reselect";

const selectCarritoReducer = (state) => state.carrito;

export const selectCarrito = createSelector([selectCarritoReducer], (carrito) => carrito.carrito);

export const selectCarritoContador = createSelector([selectCarrito], (carrito) => {
  return carrito.reduce((acc, producto) => {
    return acc + producto.cantidad;
  }, 0);
});

export const selectResumenCompra = createSelector([selectCarrito], (carrito) => {
  return carrito.filter(
    (producto) =>
      (!producto.variacion && !producto?.producto?.esEliminado && producto?.producto?.producto?.estatus) ||
      (producto.variacion &&
        !producto?.producto?.esEliminado &&
        producto?.producto?.producto?.estatus &&
        !producto?.variacion?.esEliminado) ||
      (!producto?.paquete?.esEliminado && producto?.paquete?.paquete?.estatus)
  );
});

export const selectProductosNoDisponibles = createSelector([selectCarrito], (carrito) => {
  return carrito.filter(
    (producto) =>
      (producto?.producto?.esEliminado || !producto?.producto?.producto?.estatus || producto?.variacion?.esEliminado) &&
      (producto?.paquete?.esEliminado || !producto?.paquete?.paquete?.estatus)
  );
});

export const selectResumenCompraProductosRequest = createSelector([selectResumenCompra], (resumenCompra) => {
  let cloneResumenCompra = JSON.parse(JSON.stringify(resumenCompra));
  return cloneResumenCompra.map((producto) => {
    if (producto.producto) {
      producto.precioUnitario = producto.producto.precioVenta;
      producto.producto = {
        _id: producto.producto._id,
      };
      if (producto.variacion) {
        producto.variacion = {
          _id: producto.variacion._id,
        };
      }
    } else {
      producto.precioUnitario = producto.paquete.precioVenta;
      producto.paquete = {
        _id: producto.paquete._id,
      };
    }
    delete producto.usuario;
    producto.total = producto.precioUnitario * producto.cantidad;

    return producto;
  });
});
export const selectResumenCompraContador = createSelector([selectResumenCompra], (resumenCompra) => {
  return resumenCompra.reduce((acc, producto) => {
    return acc + producto.cantidad;
  }, 0);
});

export const selectResumenCompraPrecioTotal = createSelector([selectResumenCompra], (resumenCompra) => {
  return resumenCompra.reduce((acc, producto) => {
    if (producto.paquete) return acc + producto.cantidad * producto.paquete.precioVenta;

    return acc + producto.cantidad * producto.producto.precioVenta;
  }, 0);
});

export const selectCarritoIsLoading = createSelector([selectCarritoReducer], (carrito) => carrito.isLoading);
