
import React, {useState, useEffect} from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {shuffle} from "../../../../umas/Actividades/ActividadesUtils/ActividadesUtils"

function OrdenarOracionesSlide({oraciones, isMobile, isVertical, calificar, calificando, setCalificando, reiniciarPuntuacion, evaluable}) {
    const ordenCorrecto = oraciones.oraciones
    const oracionesLista = [...oraciones.oraciones]
    const [oracionesSlide, setOracionesSlide] = useState(oracionesLista)
    
    useEffect(()=>{
        if(evaluable){
            const oracionesNoOrdenadas = shuffle(oraciones.oraciones)
            setOracionesSlide(oracionesNoOrdenadas)
        }else{    
            const oracionesOrdenadas = oracionesLista.sort(function(a, b) {
                return a.orden - b.orden;
            });
            setOracionesSlide(oracionesOrdenadas)
        }
    },[evaluable])

    const handlePuntuacion = () =>{
        const puntuacion = oracionesSlide.map((item, index)=>{
            return item.texto === ordenCorrecto[index].texto
        }).filter(Boolean).length
        return puntuacion
    }

    useEffect(()=>{
        if(calificando){
            calificar(handlePuntuacion())        
            setTimeout(()=>{
                setCalificando(false)
                reiniciarPuntuacion()
            },2000)
        }
    },[calificando])

    const reorder = (list, startIndex, endIndex)=>{
        const result = [...list] 
        const [removed] = result.splice(startIndex,1)
        result.splice(endIndex,0,removed)
        return result
      }
     Array.prototype.equals = function (getArray) {
        if (this.length != getArray.length) return false;
      
        for (var i = 0; i < getArray.length; i++) {
          if (this[i] instanceof Array && getArray[i] instanceof Array) {
            if (!this[i].equals(getArray[i])) return false;
          } else if (this[i] != getArray[i]) {
            return false;
          }
        }
        return true;
      };

  return (
    <>
    {oracionesSlide.length !==0 &&
            <DragDropContext onDragEnd={result=>{console.log(result)
                const {source,destination} = result
                if(!destination){
                    return
                }
                if(source.index===destination.index && source.droppableId===destination.droppableId){
                    return
                }
                setOracionesSlide((e=>reorder(e,source.index,destination.index)))
            }}>
                    <div className="w-full flex flex-wrap gap-y-2 min-w-[8rem]">
                        <div className={`w-full  flex justify-center relative mb-8`}>
                            <div className="w-full overflow-auto mt-8 relative">
                                <Droppable droppableId="as" direction={isMobile || isVertical ? 'vertical' : 'horizontal'} >
                                     {(droppableProvided)=><div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}  className={`p-3 rounded-lg border w-fit mx-auto ${isMobile || isVertical ? '' : 'flex items-center'}`}>
                                        <div className={`flex relative justify-center w-full flex-col  ${ isVertical ? '' : 'md:flex-row'}`}>
                                        {oracionesSlide.map((fila,index)=>(
                                            <Draggable key={fila._id} draggableId={fila._id} index={index} isDragDisabled={!evaluable}>
                                            {(draggableProvided)=><div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef} {...draggableProvided.dragHandleProps} className='mx-2 bg-white rounded h-min w-fit mb-1'>
                                                <div className='flex items-center'>
                                                    <div className={`p-2 flex border rounded-md w-fit justify-start ${evaluable ? 'bg-white' : 'bg-[#4a8f67] text-white'}`}>
                                                        <div className="flex items-center flex-col">
                                                            {fila.imagen ?
                                                            <img
                                                                src={typeof fila.imagen === 'object' ? URL.createObjectURL(fila.imagen) : fila.imagen}
                                                                className="w-[8rem] aspect-video rounded-md shadow-lg border object-cover mr-3 m-[0 auto]"
                                                                alt=""
                                                            />:""
                                                            }
                                                            <p className="font-sans md:text-lg md:p-2">{fila.texto}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                          </Draggable>
                                        ))}
                                        </div>
                                        {droppableProvided.placeholder}
                                        
                                    </div>}
                                </Droppable> 
                            </div>
                        </div>
                    </div>
            </DragDropContext>
        }
    </>
  )
}

export default OrdenarOracionesSlide