import React from "react";
import SubHeader from "../../fragments/SubHeader";
import { useNavigate } from "react-router-dom";
import BreadCrumbContainer from "../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../fragments/BreadCrumb";

const SubHeaderMarketPlace = () => {
  const navigate = useNavigate();

  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} />
        <BreadCrumb route={"Configuración del marketplace"} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderMarketPlace;
