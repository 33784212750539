import React, { useState, useEffect } from "react";
import IconAdd from "../../../../assets/icons/add.svg";
import IconDelete from "../../../../assets/icons/trashWhiteIcon.svg";
import { useNavigate } from "react-router-dom";
import { formatearFecha } from "../../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";

const TareasFilter = ({ data, setFilteredData }) => {
  const [inputvalue, setinputValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const filteredTests = data.filter((el) => {
      return (
        el?.nombre?.includes(inputvalue) ||
        formatearFecha(el?.fechaCreacion).includes(inputvalue)
      );
    });
    setFilteredData(filteredTests);
  }, [inputvalue]);

  const createNewTarea = () => {
    navigate("/creadorTareas");
  };

  const openPapelera = () => {
    navigate("/papeleraTareas");
  };

  return (
    <div className="bg-white">
      <div className={`flex items-center py-2 px-3 md:px-5`}>
        <input
          id="buscar"
          value={inputvalue}
          onChange={(e) => setinputValue(e.target.value)}
          placeholder="Buscar..."
          className="peer bg-[#E5E7EB] border-2  focus:outline-none hover:border-[#A4B9E2] border-gray-300 text-ellipsis whitespace-nowrap pr-8 rounded-lg py-2 px-4 w-full md:w-64 xl:w-72 h-[40px]"
        ></input>
        <button
          className="btn-nimbus flex items-center ml-2"
          onClick={createNewTarea}
        >
          <img src={IconAdd} />
          <div className="ml-2">Crear Tarea</div>
        </button>
        <button
          className="btn-nimbus flex items-center ml-2"
          onClick={openPapelera}
        >
          <img src={IconDelete} />
          <div className="ml-2">Papelera</div>
        </button>
      </div>
    </div>
  );
};

export default TareasFilter;
