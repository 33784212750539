import { toast } from "react-toastify";
import { createAction } from "../../components/utils/reducerUtils";
import { AVISO_DATA_ACTION_TYPES } from "./avisoDataTypes";
import alertConfiguration from "../../components/utils/alertsUtils";
import { postArchivos } from "../../components/services/api";
import {
  validateFileSize,
  validateFileType,
} from "../../components/utils/gestorAvisosUtils";

const agregarBloqueId = (bloqueId, contenidoArr, contenidoId) => {
  return contenidoArr.map((contenido) => {
    if (contenido.orden === contenidoId) {
      contenido.bloque._id = bloqueId;
      if (contenido.path) delete contenido.path;
      if (contenido.texto) delete contenido.texto;
    }
    return contenido;
  });
};

const agregarPathContenido = (path, contenidoArr, contenidoId) => {
  return contenidoArr.map((contenido) => {
    if (contenido.orden === contenidoId) {
      contenido.path = path;
    }
    return contenido;
  });
};

const agregarTextContenido = (text, contenidoArr, contenidoId) => {
  return contenidoArr.map((contenido) => {
    if (contenido.orden === contenidoId) {
      contenido.texto = text;
    }
    return contenido;
  });
};

const agregarPieContenido = (pieText, contenidoArr, contenidoId) => {
  return contenidoArr.map((contenido) => {
    if (contenido.orden === contenidoId) {
      contenido.pieDeFoto = pieText;
    }
    return contenido;
  });
};

const eliminarBloque = (contenidoArr, contenidoId) => {
  return contenidoArr.filter((contenido) => contenido.orden !== contenidoId);
};

const eliminarDestinatarios = (listaArr, listaId) => {
  let filterListArray = listaArr.map((destinatario) => {
    let newArrIds = destinatario?.listaDifusion?.filter((id) => id !== listaId);
    return { ...destinatario, listaDifusion: newArrIds };
  });
  return filterListArray.filter(
    (destinatario) => destinatario?.listaDifusion?.length > 0
  );
};

export const setNombreAviso = (nombre) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_NOMBRE, nombre);

export const setCategoriaAviso = (categoria) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_CATEGORIA, categoria);

export const setDescripcionAviso = (descripcion) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_DESCRIPCION, descripcion);

export const addContenido = (newBloqueContenido) =>
  createAction(AVISO_DATA_ACTION_TYPES.ADD_CONTENIDO, newBloqueContenido);

export const setUploadingFile = (loading = true) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_UPLOADING_FILE, loading);

export const uploadFileHandler =
  (
    file,
    setComponentData,
    contenido,
    contenidoId,
    componentLabel,
    currentContent
  ) =>
  async (dispatch) => {
    if (file) {
      const validType = validateFileType(file, componentLabel);
      const validSize = validateFileSize(file, componentLabel);
      if (validType) {
        if (validSize) {
          dispatch(setUploadingFile());
          try {
            let body = new FormData();
            body.set("file", file);
            const { data } = await postArchivos(body);
            setComponentData(data);
            dispatch(setContenidoPath(data, contenido, contenidoId));
            if (Boolean(currentContent)) {
              toast.success(
                "Los recursos se han actualizado exitosamente",
                alertConfiguration
              );
            }
          } catch (error) {
            console.log(error);
            toast.error("Ha ocurrido un error.", alertConfiguration);
          } finally {
            dispatch(setUploadingFile(false));
          }
        }
      }
    }
  };

export const setBloqueId = (bloqueId, contenidoArr, contenidoId) => {
  const newContenidoArray = agregarBloqueId(
    bloqueId,
    contenidoArr,
    contenidoId
  );

  return createAction(AVISO_DATA_ACTION_TYPES.SET_BLOQUE_ID, newContenidoArray);
};

export const setContenidoPath = (path, contenidoArr, contenidoId) => {
  const newContenidoArray = agregarPathContenido(
    path,
    contenidoArr,
    contenidoId
  );

  return createAction(
    AVISO_DATA_ACTION_TYPES.SET_CONTENIDO_PATH,
    newContenidoArray
  );
};

export const setContenidoText = (text, contenidoArr, contenidoId) => {
  const newContenidoArray = agregarTextContenido(
    text,
    contenidoArr,
    contenidoId
  );

  return createAction(
    AVISO_DATA_ACTION_TYPES.SET_CONTENIDO_TEXT,
    newContenidoArray
  );
};

export const setContenidoPie = (pieText, contenidoArr, contenidoId) => {
  const newContenidoArray = agregarPieContenido(
    pieText,
    contenidoArr,
    contenidoId
  );

  return createAction(
    AVISO_DATA_ACTION_TYPES.SET_CONTENIDO_PIE,
    newContenidoArray
  );
};

export const deleteBloqueContenido = (contenidoArr, contenidoId) => {
  const newContenidoArray = eliminarBloque(contenidoArr, contenidoId);

  return createAction(
    AVISO_DATA_ACTION_TYPES.DELETE_CONTENIDO,
    newContenidoArray
  );
};

export const setCalendario = (calendario) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_CALENDARIO, calendario);

export const setFechaInicio = (fechaInicio) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_FECHA_INICIO, fechaInicio);

export const setFechaFin = (fechaFin) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_FECHA_FIN, fechaFin);

export const addDestinatarios = (destinatarios) =>
  createAction(AVISO_DATA_ACTION_TYPES.ADD_LIST, destinatarios);

export const deleteDestinatarios = (listasArr, listaId) => {
  const newContenidoArray = eliminarDestinatarios(listasArr, listaId);

  return createAction(AVISO_DATA_ACTION_TYPES.DELETE_LIST, newContenidoArray);
};

export const setFechaEnvio = (send = "Now", fecha = null, hora = null) => {
  const envio = {
    defaultOption: send,
    date: fecha,
    time: hora,
  };

  return createAction(AVISO_DATA_ACTION_TYPES.SET_ENVIO, envio);
};

export const setEnProgreso = (status = true) =>
  createAction(AVISO_DATA_ACTION_TYPES.SET_EN_PROCESO, status);

export const limpiarAviso = (aviso = null) =>
  createAction(AVISO_DATA_ACTION_TYPES.CLEAR_AVISO, aviso);
