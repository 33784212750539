import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ProductoCard from "./fragments/ProductoCard";
import { UserContext } from "../../services/user";
import { useNavigate, useParams } from "react-router-dom";
import SubHeaderTienda from "./SubHeaderTienda";
import Close from "../../../assets/icons/closeBlack.svg";
import { useSelector } from "react-redux";
import {
  selectBuscar,
  selectFavouritesIds,
  selectMarketplace,
  selectMarketplaceMap,
} from "../../../reducers/marketplace/marketplaceSelector";
import ProductosFilter from "./fragments/ProductosFilter";
import NoDataMessage from "../../fragments/NoDataMessage";
import { MESSAGES } from "../../utils/messagesUtils";

export default function ProductosContainer() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const params = useParams();
  const marketplaceMap = useSelector(selectMarketplaceMap);
  const articulosMarketplace = useSelector(selectMarketplace)
  const [productos, setProductos] = useState(marketplaceMap.ProductosDestacados);
  const [productosFiltro, setProductosFiltro] = useState([]);
  const buscar = useSelector(selectBuscar);
  const favoritosId = useSelector(selectFavouritesIds);
  const [paginacion, setPaginacion] = useState(params.categoria === "ProductosDestacados" ? 20 : 12);

  const handlePaginacion = () => {
    const agregarCantidad = params.categoria === "ProductosDestacados" ? 20 : 12;
    setPaginacion((paginacion) => paginacion + agregarCantidad);
  };

  useEffect(() => {
    setProductos(marketplaceMap[params.categoria]);
    setProductosFiltro(marketplaceMap[params.categoria]);
  }, [params.categoria, marketplaceMap]);

  useEffect(() => {
    if (params.categoria === "ProductosDestacados") {
      if (buscar !== "") {
        let newProductosFiltro = []
        if(articulosMarketplace.productos) newProductosFiltro = [...articulosMarketplace.productos]
        if(articulosMarketplace.paquetes) newProductosFiltro = [...newProductosFiltro, ...articulosMarketplace.paquetes]
        newProductosFiltro = newProductosFiltro.filter(
          (producto) =>
            producto?.producto?.nombre.toLowerCase().includes(buscar.toLowerCase()) ||
            producto?.paquete?.nombre.toLowerCase().includes(buscar.toLowerCase())
        );
        setProductosFiltro(newProductosFiltro);
      } else {
        setProductosFiltro(productos);
      }
    }
  }, [buscar, params.categoria]);

  useEffect(() => {
    setPaginacion(params.categoria === "ProductosDestacados" ? 20 : 12);
  }, [productosFiltro]);

  return (
    <div className="h-full">
      <div className="h-full">
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-end px-4">
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <img className="h-4" src={Close} alt="" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200 w-full">
                    <ProductosFilter
                      productos={productos}
                      productosFiltro={productosFiltro}
                      setProductosFiltro={setProductosFiltro}
                    />
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="h-full">
          {params.categoria !== "ProductosDestacados" && (
            <div className="flex items-baseline justify-end border-b border-gray-200">
              <button
                type="button"
                className="ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span>Filtrar</span>
              </button>
            </div>
          )}

          <section aria-labelledby="products-heading" className="h-[calc(100vh-5rem)]">
            <div className="flex h-full">
              {/* Filters */}
              {params.categoria !== "ProductosDestacados" && (
                <form className="hidden lg:block border-r border-gray-200 h-[calc(100vh-14rem)] w-64 pb-8 mr-8 overflow-y-auto">
                  <ProductosFilter
                    productos={productos}
                    productosFiltro={productosFiltro}
                    setProductosFiltro={setProductosFiltro}
                  />
                </form>
              )}

              {/* Product grid */}
              <div
                className={`flex-1 lg:h-[calc(100vh-16rem)] flex-grow my-4 px-4  overflow-auto ${
                  params.categoria === "ProductosDestacados" ? "lg:px-8 h-[calc(100vh-15.25rem)] sm:h-[calc(100vh-15.75rem)]" : "lg:px-0 h-[calc(100vh-17.75rem)] sm:h-[calc(100vh-18.5rem)]"
                }`}
              >
                <div className="mb-6 -mt-4">
                  <SubHeaderTienda />
                  <div className="flex gap-x-5">
                    <div className="flex flex-wrap items-center">
                      <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
                        {productosFiltro.length}
                      </p>
                      <p className="text-black">Artículos</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex flex-col justify-between h-[calc(100%-42px)] sm:h-[calc(100%-104px)] lg:h-[calc(100%-100px)]`}
                >
                  {productosFiltro.length === 0 ? (
                    <NoDataMessage message={productos.length === 0 && params.categoria === "ProductosDestacados" ? MESSAGES.MESSAGES_PRODUCTOS_DESTACADOS_TIENDA : MESSAGES.MESSAGES_PRODUCTOS_TIENDA} />
                  ) : (
                    <div className={`grid justify-center gap-3 ${params.categoria === "ProductosDestacados" ? "sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5" : "sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 lg:mr-8"} `}>
                      {productosFiltro.slice(0, paginacion).map((producto) => (
                        <ProductoCard
                          key={producto._id}
                          productoInfo={producto}
                          esPaquete={producto.producto === undefined}
                          esFavorito={Object.keys(favoritosId).includes(producto._id)}
                          favoritoId={favoritosId[producto._id]}
                        />
                      ))}
                    </div>
                  )}
                  {paginacion < productosFiltro.length && (
                    <div className="flex justify-center mt-6">
                      <button
                        className="border-2 rounded-md text-nimbusDark border-nimbusDark px-4 py-2"
                        onClick={handlePaginacion}
                      >
                        Mostrar más artículos
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
