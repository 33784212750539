import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import SolicitarFacturaModal from "./SolicitarFacturaModal";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../utils/dateFunctions";
import { addDays } from "date-fns";
import { formatCurrency } from "../../../utils/productoUtils";
import { METODOS_DE_PAGO } from "../../../utils/misComprasUtils";
import { getComprasSessionStatus } from "../../../services/Api/MarketPlace/Compras";

const DatosCompra = ({ compraInfo, abreviatura, detallesPais }) => {
  const params = useParams();
  const [facturaModal, setFacturaModal] = useState(false);
  const [session, setSession] = useState({});

  const { fechaCreacion, articulos, total, metodoDePago, datosEnvio, estatus, esPagado, checkoutSession } = compraInfo;
  const { nombreDireccion, ubicacion, telefono, referencias } = datosEnvio;
  const { nombre, direccion } = ubicacion;
  const cantidadArticulos = useMemo(() => {
    return articulos.reduce((acc, articulo) => {
      return acc + articulo.cantidad ?? 0;
    }, 0);
  }, [articulos]);

  useEffect(() => {
    const sessionId = checkoutSession.split("_secret_")[0];
    getComprasSessionStatus(sessionId).then((response) => {
      setSession(response);
    });
  }, [checkoutSession]);  

  return (
    <Fragment>
      <div className="w-full bg-[#F5F5F5] relative">
        <h1 className="text-center text-[#9C9C9C] font-extrabold p-4 text-lg">Datos de la compra</h1>
      </div>
      <div className="bg-[#fff] py-10 px-5">
        <div className="flex justify-between px-5 flex-wrap">
          <div className="flex gap-5">
            <p className="text-[#808080]">Id de la compra:</p>
            <p className="text-[#B3B3B3]">{params.compraId}</p>
          </div>
          <div className="flex gap-5">
            <p className="text-[#808080]">Fecha de la compra:</p>
            <p className="text-[#B3B3B3]">{formatDate(new Date(fechaCreacion))}</p>
          </div>
          {estatus === "PAGO_FALLIDO" ? (
            <div className="flex gap-5">
              <p className="text-[#808080]">Fecha de pago:</p>
              <p className="text-[#B3B3B3]">Sin registrar</p>
            </div>
          ) : (
            <Fragment>
              <div className="flex gap-5">
                <p className="text-[#808080]">Total de artículos:</p>
                <p className="text-[#B3B3B3]">{cantidadArticulos}</p>
              </div>
              <div className="flex gap-5">
                <p className="text-[#808080]">Total de la compra:</p>
                <p className="text-[#B3B3B3]">
                   {detallesPais.simbolo} {formatCurrency(total)}
                </p>
              </div>
            </Fragment>
          )}
        </div>
        <div className="flex justify-between mt-10 px-5 flex-wrap-reverse">
          <div>
            <p className="text-[#808080] underline underline-offset-1 mb-5">Dirección de envío</p>
            <div>
              <div className="flex gap-3 flex-wrap">
                <p className="text-[#808080]">Nombre de la dirección:</p>
                <p className="text-[#B3B3B3]">{nombreDireccion}</p>
              </div>
              <div className="flex gap-3 flex-wrap">
                <p className="text-[#808080]">Dirección:</p>
                <p className="text-[#B3B3B3]">{nombre ? nombre : direccion}</p>
              </div>
              <div className="flex gap-3 flex-wrap">
                <p className="text-[#808080]">Teléfono de contacto:</p>
                <p className="text-[#B3B3B3]">{telefono}</p>
              </div>
              <div className="flex gap-3 flex-wrap">
                <p className="text-[#808080]">Otras referencias:</p>
                <p className="text-[#B3B3B3]">{referencias}</p>
              </div>
            </div>
          </div>
          <div>
            <p className="text-[#808080] underline underline-offset-1 mb-5">Datos de pago</p>
            <div>
              <div className="flex gap-3 flex-wrap">
                <p className="text-[#808080]">Método de pago:</p>
                <p className="text-[#B3B3B3]">{METODOS_DE_PAGO[metodoDePago]}</p>
              </div>
              <div className="flex gap-3 flex-wrap">
                <p className="text-[#808080]">Estatus de pago:</p>
                <p
                  className={`${
                    estatus === "CANCELADA" || estatus === "PAGO_FALLIDO"
                      ? "text-[#EB0000]"
                      : esPagado
                      ? "text-[#17A300]"
                      : "text-[#B89A00]"
                  } `}
                >
                  {estatus === "CANCELADA" || estatus === "PAGO_FALLIDO"
                    ? "CANCELADO"
                    : esPagado
                    ? "COMPLETADO"
                    : "PENDIENTE"}
                </p>
              </div>
              {estatus !== "CANCELADA" && estatus !== "PAGO_FALLIDO" && !esPagado && (
                <div className="flex gap-3 flex-wrap">
                  <a
                    href={session?.session_status?.paymentIntent?.receipt_url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#808080] underline underline-offset-1"
                  >
                    Visualizar datos para realizar el pago
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-10 px-5 flex-wrap-reverse">
          <div>
            <p className="text-[#808080] underline underline-offset-1 mb-5">Estatus del pedido</p>
            {estatus !== "CANCELADA" && estatus !== "PAGO_FALLIDO" && !esPagado ? (
              <p className="text-[#B3B3B3]">Debe primero completar el pago</p>
            ) : (
              <div className="flex flex-wrap gap-4 items-center">
                <div
                  className={`w-44 h-10 flex justify-center items-center font-bold rounded-md ${
                    estatus === "ENTREGADO"
                      ? "text-nimbusDark"
                      : estatus === "COMPLETADO"
                      ? "text-[#17A300]"
                      : estatus === "PREPARANDO"
                      ? "text-[#B89A00]"
                      : "text-[#EB0000]"
                  } `}
                >
                  {estatus === "PAGO_FALLIDO" ? "CANCELADO" : estatus}
                </div>
                {estatus === "PREPARANDO" ? (
                  <p className="text-[#B3B3B3]">Su pedido se encuentra en preparación</p>
                ) : estatus === "ENTREGADO" ? (
                  <p className="text-[#B3B3B3]">
                    Su pedido ha sido entregado, y a continuación, podrá solicitar la factura
                  </p>
                ) : estatus === "COMPLETADO" ? (
                  <p className="text-[#B3B3B3]">Ya puede pasar a recoger su pedido</p>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <SolicitarFacturaModal isOpen={facturaModal} setIsOpen={setFacturaModal} />
    </Fragment>
  );
};

export default DatosCompra;
