import React, { useContext, useEffect, useState } from "react";
import SeleccionarArticulos from "./SeleccionarArticulos";
import AplicarDescuento from "./AplicarDescuento";
import { ReactComponent as CloseBlack } from "../../../../assets/icons/closeBlack.svg";
import { AsignarArticulosContext } from "../../../../contexts/AsignarProductosContext";
import {
  aplicarDescuentoArticulos,
  habilitarBotonEstatus,
  removerArticulosLista,
  seleccionarTodosArticulosLista,
} from "../../../../reducers/asginarArticulos/asignarArticulosAction";
import IconButton from "../../../fragments/Buttons/IconButton";
import { ReactComponent as RemoveWhite } from "../../../../assets/icons/removeWhite.svg";
import { ReactComponent as SideGestionWhite } from "../../../../assets/icons/sideGestionWhite.svg";
import InputText from "../../../fragments/Inputs/InputText";
import useValidateForm from "../../../hooks/useValidateForm";
import AplicarDescuentoModal from "./AplicarDescuentoModal";
import EliminarArticulosAsignadosModal from "./EliminarArticulosAsignadosModal";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const AccionesMasivas = ({ setAccionesMasivasShow, articulosMostrados }) => {
  const [checked, setChecked] = useState(false);
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [habilitarBotonDescuento, setHabilitarBotonDescuento] = useState(false);
  const [aplicarDescuentoModal, setAplicarDescuentoModal] = useState(false);
  const [eliminarArticulosModal, setEliminarArticulosModal] = useState(false);

  const { articulos, dispatch } = useContext(AsignarArticulosContext);

  let { handleChange, errors, values } = useValidateForm({ descuento: "" });

  const handleChecked = () => {
    setChecked((state) => !state);
    dispatch(seleccionarTodosArticulosLista(articulos, articulosMostrados, !checked));
  };

  const removerArticulos = () => {
    dispatch(removerArticulosLista(articulos));
    dispatch(habilitarBotonEstatus(true))
    setChecked(false)
    setEliminarArticulosModal(false);
    setAccionesMasivasShow(false);
    toast.success("Artículos removidos correctamente", alertConfiguration)
  };
  
  const aplicarDescuento = () => {
    dispatch(aplicarDescuentoArticulos(articulos, parseInt(values.descuento)));
    dispatch(habilitarBotonEstatus(true))
    setChecked(false)
    setAplicarDescuentoModal(false);
    setAccionesMasivasShow(false);
    toast.success("Se ha aplicado el descuento correctamente", alertConfiguration)
  };

  useEffect(() => {
    const enableButton = articulos.some((producto) => producto.esSeleccionado);
    setHabilitarBoton(enableButton);
  }, [articulos]);

  useEffect(() => {
    setHabilitarBotonDescuento(values.descuento !== "");
  }, [values.descuento]);

  return (
    <div className="p-5 pb-0 border bg-white flex flex-col mt-3">
      <div className="text-sm text-[#6E6D7A] w-full flex justify-between gap-4">
        <p className="flex-1">
          Seleccione los artículos sobre los cuáles desee aplicar una acción masiva, y después, seleccione la opción que
          requiera.
        </p>
        <CloseBlack className="w-2 h-2 md:w-3 md:h-3 cursor-pointer" onClick={() => setAccionesMasivasShow(false)} />
      </div>
      <div className="flex flex-col xl:flex-row xl:justify-between py-2 flex-wrap gap-6 mt-2">
        <div className="relative rounded-md border-[1px] border-gray-300 flex gap-3 items-center px-4 py-6 md:py-2 flex-1">
          <input
            type="checkbox"
            name={`selectArticulosLista`}
            id={`selectArticulosLista`}
            className="rounded-md border-2 border-gray-300  my-0"
            checked={checked}
            onChange={handleChecked}
          />
          <label
            htmlFor={`selectArticulosLista`}
            className="cursor-pointer select-none absolute w-full h-full flex gap-6 items-center -translate-x-4 pl-12"
          >
            <p className="text-sm 2xl:text-base text-left">Seleccionar todos los artículos mostrados en pantalla</p>
          </label>
        </div>
        <div className="flex flex-col md:flex-row gap-6 md:items-end justify-end">
          <IconButton
            text={"Quitar artículos"}
            styles="bg-sidebar !w-full md:!w-fit h-[38px]"
            disabled={!habilitarBoton}
            Icon={RemoveWhite}
            onClick={() => setEliminarArticulosModal(true)}
          />
          <div className="flex">
            <InputText
              name={"descuento"}
              label={"Descuento:"}
              placeholder={"Introduce un valor"}
              styles="!w-full"
              wrapperStyles="!w-full lg:!flex items-center gap-x-2"
              labelStyles="!text-sm !font-semibold !text-gray-400"
              disabled={!habilitarBoton}
              disabledStyles={!habilitarBoton}
              value={values.descuento}
              onChange={(e) => handleChange(e, "descuento")}
              error={errors["descuento"]}
            />
          </div>
          <IconButton
            text={"Aplicar descuento"}
            disabled={!habilitarBotonDescuento}
            Icon={SideGestionWhite}
            onClick={() => setAplicarDescuentoModal(true)}
            styles="!w-full md:!w-fit h-[38px]"
          />
        </div>
      </div>
      <AplicarDescuentoModal
        isOpen={aplicarDescuentoModal}
        setIsOpen={setAplicarDescuentoModal}
        aplicarDescuento={aplicarDescuento}
      />
      <EliminarArticulosAsignadosModal
        isOpen={eliminarArticulosModal}
        setIsOpen={setEliminarArticulosModal}
        removerArticulos={removerArticulos}
      />
    </div>
  );
};

export default AccionesMasivas;
