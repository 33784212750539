import { createContext, useContext, useState } from "react";
import { usePagination } from "../../../hooks/usePagination";

const initialContext = {
  usersAdded: [],
  setUsersAdded: () => {},
  usersAddedFiltered: [],
  setUsersAddedFiltered: () => {},
  selectUser: () => {},
  selectAllUsers: () => {},
  ver: undefined,
  setVer: () => {},
  editar: undefined,
  setEditar: () => {},
  next: () => {},
  prev: () => {},
  jump: () => {},
  currentData: () => {},
  currentPage: 1,
  maxPage: 0,
};

export const ListaDifusionDetallesContext = createContext(initialContext);

export const ListaDifusionDetallesContextProvider = ({ children }) => {
  const [usersAdded, setUsersAdded] = useState([]);
  const [usersAddedFiltered, setUsersAddedFiltered] = useState([]);
  const [ver, setVer] = useState(undefined);
  const [editar, setEditar] = useState(undefined);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    usersAddedFiltered,
    20
  );

  const selectUser = (userToDelete) => {
    const newUsersList = usersAdded?.map((user) => {
      if (user.usuario._id === userToDelete.usuario._id) {
        user.esSeleccionado = !user.esSeleccionado;
      }
      return user;
    });

    setUsersAdded(newUsersList);
  };

  const selectAllUsers = (checked, data) => {
    const usersSelectedIds = data?.map((user) => user.usuario._id);

    const newUsersList = usersAdded?.map((user) =>
      usersSelectedIds.includes(user.usuario._id)
        ? { ...user, esSeleccionado: checked }
        : user
    );

    setUsersAdded(newUsersList);
  };

  return (
    <ListaDifusionDetallesContext.Provider
      value={{
        usersAdded,
        setUsersAdded,
        usersAddedFiltered,
        setUsersAddedFiltered,
        selectUser,
        selectAllUsers,
        ver,
        setVer,
        editar,
        setEditar,
        next,
        prev,
        jump,
        currentData,
        currentPage,
        maxPage,
      }}
    >
      {children}
    </ListaDifusionDetallesContext.Provider>
  );
};

export const useListaDifusionDetalles = () => {
  return useContext(ListaDifusionDetallesContext);
};
