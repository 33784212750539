import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-resize-nimbus-build/build/ckeditor";
import React from "react";
import { MyUploadAdapter } from "../../constructor/umas/MyUploadAdapter";
import { customEditorToolbar, editorImageConfig, editorLinkConfig, listProperties } from "../../utils/ckEditorUtils";

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

const RichContentInput = ({ value = "", onChange = () => {}, label, error = "", name, disabled = false, placeholder="", styles="" }) => {
  return (
    <div className={`${styles}`}>
      <p className="font-semibold text-base text-[#0D0C22] truncate mb-2">{label}</p>
      <div className={`editor-wrapper ${error === "" ? "" : "border-2 border-red-400 rounded-[4px]"}`}>
        <CKEditor
          id={name}
          editor={ClassicEditor}
          data={value}
          disabled={disabled}
          config={{
            placeholder: placeholder,
            image: editorImageConfig,
            fontSize: {
              options: [9, 11, 13, "default", 17, 19, 25, 30, 35, 40],
            },
            extraPlugins: [MyCustomUploadAdapterPlugin],
            removePlugins: ["Title"],
            mediaEmbed: { previewsInData: true },
            link: editorLinkConfig,
            toolbar: customEditorToolbar,
            list: listProperties,
            heading: {
              options: [
                { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
              ],
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();

            let eventRichContentInput = {
              target: {
                name: name,
                value: data,
                type: "text",
              },
              persist: () => {},
            };
            onChange(eventRichContentInput);
          }}
        />
      </div>
      <p className="font-thin text-xs text-red-400">{error}</p>
    </div>
  );
};

export default RichContentInput;
