import CoordMaps from "../../../../assets/img/coordmaps.png";
export default function InstruccionesGeocode() {
  return (
    <div>
      <label className="text-[#606060] font-medium">
        Nimbus te solicita la ubicación de tu dirección y la puedes agregar mediante dos opciones: La primera es
        escribiendo la dirección directamente en el campo y la segunda es utilizando Google Maps. Para ello es necesario
        seguir una serie de pasos que te vamos a indicar para obtener las coordenadas de tu domicilio e integrarlas en
        la plataforma.
        <br />
        <br />
        Opción 1 - Agregar la dirección de manera directa.
        <br />
        En el campo Ubicación introduce tu dirección completa:
        <br />
        Calle, Número, Colonia, Estado, Municipio y Código Postal.
        <br />
        Pulsa el botón "Verificar dirección" y la plataforma regresa la dirección aproximada. En ocasiones Google Maps
        no muestra la dirección exacta, esto es normal y puedes dejar la dirección aproximada.
        <br />
        Si la dirección es correcta, pulsa el botón "Guardar dirección" y tu dirección se almacena en la plataforma.
        <br />
        Si tu dirección no es correcta, pulsa el botón "Limpiar" y realiza el procedimiento nuevamente o intenta con la
        segunda opción.
        <br />
        <br />
        Opción 2 - Agregar la dirección buscándola en Google Maps.
        <br />
        Pulsa{" "}
        <a href="https://www.google.com/maps/" target="_blank">
          <label className="text-blue-500">aquí</label>
        </a>{" "}
        para abrir Google Maps en una nueva pestaña de tu navegador.
        <br />
        En Google Maps, busca tu dirección escribiendo en el campo “Buscar en Google Maps” y presiona “Enter”
        <br />
        En el mapa aparece tu dirección resaltada con un marcador de posición. En ocasiones Google Maps no muestra la
        dirección exacta, esto es normal y puedes dejar la dirección aproximada.
        <br />
        Haz clic con el botón derecho o secundario del ratón para mostrar el menú contextual del mapa.
        <br />
        Para copiar las coordenadas de tu domicilio al portapapeles, haz clic o pulsa sobre el primer elemento del menú
        contextual que consta de dos números, estos números corresponden a las coordenadas de tu domicilio.{" "}
        <img src={CoordMaps}></img>
        <br />
        Regresa a esta pestaña, pulsa sobre el campo de texto Ubicación “Ingresa una dirección o coordenadas” y pega las
        coordenadas con el comando Ctrl+V
        <br />
        Ahora pulsa el botón "Verificar dirección", y la plataforma te muestra la dirección.
        <br />
        Si tu dirección es correcta, pulsa el botón "Guardar dirección". La plataforma te muestra un mensaje de guardado
        correcto en la parte superior derecha.
        <br />
        Si la dirección no es correcta, pulsa el botón "Limpiar" y realiza el procedimiento nuevamente. Si requieres
        editar la dirección, pulsa en el campo de dirección y edita la información que ahí se muestra, pulsa el botón
        "Guardar dirección".
      </label>
      <br />
      <br />
      <br />
    </div>
  );
}
