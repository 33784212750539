import { MESSAGES } from "../../../utils/messagesUtils";
import { useEffect } from "react";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import ListaDifusionDetallesTableHeader from "./ListaDifusionDetallesTableHeader";
import NoDataMessage from "../../../fragments/NoDataMessage";
import TablePagination from "../../../fragments/TablePagination";
import UsersAddedTable from "./UsersAddedTable";

const ListaDifusionUsersAdded = () => {
  const {
    usersAddedFiltered,
    setUsersAddedFiltered,
    setUsersAdded,
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
  } = useListaDifusionDetalles();

  useEffect(() => {
    return () => {
      setUsersAdded([]);
      setUsersAddedFiltered([]);
    };
  }, []);

  return (
    <div className="flex flex-col mt-8">
      <ListaDifusionDetallesTableHeader />
      {usersAddedFiltered?.length === 0 ? (
        <NoDataMessage message={MESSAGES.MESSAGES_LISTA_DIFUSION_DESTINATARIOS} />
      ) : (
        <div>
          <UsersAddedTable />
          <TablePagination
            next={next}
            prev={prev}
            jump={jump}
            data={currentData()}
            currentPage={currentPage}
            maxPage={maxPage}
          />
        </div>
      )}
    </div>
  );
};

export default ListaDifusionUsersAdded;
