import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { formatCurrency } from "../../../utils/productoUtils";
import ProductoImage from "../../../../assets/img/producto.png";
import PaqueteImage from "../../../../assets/img/paquetesMarketplace.png";
import { ReactComponent as AddIcon } from "../../../../assets/icons/addIcon.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/icons/minusIcon.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import ActiveWrapper from "../../../constructor/libros/fragments/ActiveWrapper";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import FavoritosIcon from "../../../../assets/icons/favoritosIcon.svg";
import FavoritosSeleccionadoIcon from "../../../../assets/icons/favoritosSeleccionadoIcon.svg";
import ProductoAtributos from "./ProductoAtributos";
import InputNumber from "../../../fragments/Inputs/InputNumber";
import ProductoEspecificaciones from "./ProductoEspecificaciones";
import { deleteProductoFavorito, postProductoFavorito } from "../../../services/Api/MarketPlace/Favoritos";
import { fetchFavouritesAsync } from "../../../../reducers/marketplace/marketplaceAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { useNavigate } from "react-router-dom";
import { postCarritoDeCompras, putProductoCarritoDeCompras } from "../../../services/Api/MarketPlace/CarritoDeCompras";
import { fetchCarritoAsync } from "../../../../reducers/carrito/carritoAction";
import { selectCarrito } from "../../../../reducers/carrito/carritoSelector";
import useValidateForm from "../../../hooks/useValidateForm";
import { Disclosure } from "@headlessui/react";
import ArrowUp from "../../../../assets/icons/arrowDownSimple.svg";
import ArrowDown from "../../../../assets/icons/arrowUpSimple.svg";
import DOMPurify from "dompurify";
import ProductosPaqueteTable from "./ProductosPaqueteTable";
import { UserContext } from "../../../services/user";
import { getPaises } from "../../../services/api";

const ProductoInfoDetalle = ({ productoInfo, esPaquete, esFavorito, favoritoId }) => {
  const user = useContext(UserContext);
  const [atributosSeleccionados, setAtributosSeleccionados] = useState([]);
  const [atributosSeleccionadosCompletado, setAtributosSeleccionadosCompletado] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carrito = useSelector(selectCarrito);

  const {
    nombre,
    estatus,
    imagen,
    carrusel,
    caracteristicas,
    detalles = [],
    descripcion,
    configuracionProducto,
  } = useMemo(() => {
    let detalles = obtenerDetalles();
    if (esPaquete) {
      let paquete = {
        ...productoInfo?.paquete,
        detalles: detalles,
      };
      return paquete;
    }

    let producto = {
      ...productoInfo.producto,
      detalles: detalles,
    };

    return producto;
  }, [productoInfo]);
  const [fotoSeleccionada, setFotoSeleccionada] = useState(imagen ? imagen : carrusel[0] ? carrusel[0] : "");
  const [abreviatura, setAbreviatura] = useState("")
  const { _id, precioVenta, producto } = productoInfo;
  const sanitizedCaracteristicas = DOMPurify.sanitize(caracteristicas);
  function obtenerDetalles() {
    let detalles = [];
    if (esPaquete) {
      detalles = [
        {
          _id: "Categoría del artículo",
          nombre: "Categoría del artículo",
          valor: "Paquete",
        },
        {
          _id: "Nivel educativo",
          nombre: "Nivel educativo",
          valor: productoInfo.paquete.nivelAcademico.nombre,
        },
        {
          _id: "Grado académico",
          nombre: "Grado académico",
          valor: productoInfo.paquete.gradoAcademico.nombre,
        },
      ];
    } else {
      detalles = [
        {
          _id: "Categoría del artículo",
          nombre: "Categoría del artículo",
          valor: productoInfo.producto.categoria === "otro" ? "Otros" : productoInfo.producto.categoria,
        },
        {
          _id: "Tipo del producto",
          nombre: "Tipo del producto",
          valor: productoInfo.producto.tipoProducto,
        },
        {
          _id: "Marca",
          nombre: "Marca",
          valor: productoInfo.producto.marca,
        },
        {
          _id: "Nivel educativo",
          nombre: "Nivel educativo",
          valor: productoInfo.producto.nivelAcademico.nombre,
        },
        {
          _id: "Grado académico",
          nombre: "Grado académico",
          valor: productoInfo.producto.gradoAcademico.nombre,
        },
      ];
    }

    return detalles;
  }

  const { handleChange, errors, values, setValues } = useValidateForm({ cantidadArticulo: 1 });

  const handleIncrementarCantidad = () => {
    setValues({ cantidadArticulo: parseInt(values.cantidadArticulo) + 1 });
  };

  const handleDecrementarCantidad = () => {
    setValues({ cantidadArticulo: parseInt(values.cantidadArticulo) - 1 });
  };

  const handleComprar = async (comprar) => {
    try {
      if (atributosSeleccionadosCompletado) {
        if (Object.values(errors).length === 0) {
          let productoEnCarrito

          const tipoDeProducto = esPaquete ? "paquete" : "producto";
          let body = {
            [tipoDeProducto]: {
              _id: _id,
            },
            cantidad: parseInt(values.cantidadArticulo),
          };

          if (configuracionProducto === "PRODUCTO_VARIABLE") {
            let variacionAEncontrar = productoInfo.producto.variaciones;
            for (let index = 0; index < atributosSeleccionados.length; index++) {
              variacionAEncontrar = variacionAEncontrar.filter(
                (variacion) =>
                  variacion.seleccionValores[index].valor._id === atributosSeleccionados[index].valorSeleccionado._id
              );
            }
            variacionAEncontrar = variacionAEncontrar[0];
            body.variacion = {
              _id: variacionAEncontrar._id,
            };
            
            productoEnCarrito = carrito.find((producto) => producto.producto?._id === _id && producto?.variacion?._id === variacionAEncontrar?._id)
            
            if (productoEnCarrito) body.cantidad += productoEnCarrito.cantidad;

            if (productoEnCarrito) {
              if (productoEnCarrito.variacion._id !== variacionAEncontrar._id) {
                body.cantidad -= productoEnCarrito.cantidad;
                await postCarritoDeCompras(body);
              } else {
                await putProductoCarritoDeCompras(productoEnCarrito._id, body);
              }
            } else {
              await postCarritoDeCompras(body);
            }
          } else {
            productoEnCarrito = carrito.find(
              (producto) => producto?.producto?._id === _id || producto?.paquete?._id === _id
            );

            if (productoEnCarrito) body.cantidad += productoEnCarrito.cantidad;

            await postCarritoDeCompras(body);
          }

          dispatch(fetchCarritoAsync());
          toast.success("El artículo se ha añadido correctamente al carrito", alertConfiguration);

          if (comprar) navigate("/marketplace/tienda/carritoDeCompra");
        } else {
          toast.error("Ingrese una cantidad válida", alertConfiguration);
        }
      } else {
        toast.error(
          "Debe especificar todas las características con las que quiere comprar su producto",
          alertConfiguration
        );
      }
    } catch (error) {
      toast.error("Ha ocurrido un error", alertConfiguration);
      console.log(error);
    }
  };

  const handleFavoritos = async (e) => {
    try {
      e.stopPropagation();
      const tipoDeProducto = esPaquete ? "paquete" : "producto";
      let body = {
        [tipoDeProducto]: {
          _id: _id,
        },
      };
      if (esFavorito) {
        await deleteProductoFavorito(favoritoId);
        dispatch(fetchFavouritesAsync(false));
        toast.success("El producto se ha eliminado correctamente de sus favoritos", alertConfiguration);
      } else {
        await postProductoFavorito(body);
        dispatch(fetchFavouritesAsync(false));
        toast.success("El producto se ha añadido correctamente a sus favoritos", alertConfiguration);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DisclosureSection = ({ title, content }) => (
    <Disclosure as="div" className="border-t border-gray-300 py-6" defaultOpen={true}>
      {({ open }) => (
        <>
          <h3 className="-my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between py-2 text-sm">
              <div className="flex items-center gap-2">
                <span className="font-bold">{title}</span>
              </div>
              <span className="ml-6 flex items-center">
                <img className="ml-4" src={open ? ArrowDown : ArrowUp} alt="" />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">{content}</div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );

  const getMonedaPais = async () => {
    const { data } = await getPaises();
    const abreviaturaPais = data.filter((pais) => pais._id === productoInfo?.plantel?.dirFiscalPais)[0].simbolo
    setAbreviatura(abreviaturaPais);
  }

  useEffect(() => {
    if (!esPaquete && atributosSeleccionados.length !== 0) {
      let atributosCompletados = atributosSeleccionados.every((atributo) => atributo.valorSeleccionado._id !== "");
      setAtributosSeleccionadosCompletado(atributosCompletados);
    }
  }, [atributosSeleccionados]);

  useEffect(() => {
    if(user.activeRole !== ""){
      getMonedaPais();
    }
  }, [user.activeRole]);
  return (
    <div className="flex flex-col mt-6 items-center lg:items-start w-full">
      <div className="flex flex-col lg:grid grid-cols-9 xl:grid-cols-2 gap-12 w-full justify-items-start">
        <div className="col-span-5 xl:col-span-1 flex flex-col items-center gap-6">
          <div className="flex flex-col-reverse lg:flex-row gap-6 items-center lg:items-start">
            <div className="grid grid-cols-4 sm:grid-cols-6 lg:flex lg:flex-col gap-2">
              {[imagen ? imagen : 'No image' , ...carrusel].map((foto) => foto !== 'No image' && (
                <ActiveWrapper
                  key={foto}
                  handleClick={() => setFotoSeleccionada(foto)}
                  active={fotoSeleccionada === foto}
                  checkMark={false}
                  wrapperStyles="rounded-none outline-1"
                >
                  <img
                    className="h-20 w-20 object-contain bg-white border-[1px] cursor-pointer hover:border-nimbusDark"
                    src={foto === "" ? (esPaquete ? PaqueteImage : ProductoImage) : foto}
                    alt=""
                  />
                </ActiveWrapper>
              ))} 
            </div>
            <div className="w-80 sm:w-[32rem] sm:h-[32rem] sm: lg:w-[28rem] lg:h-[28rem] xl:w-[32rem] xl:h-[32rem] bg-white flex justify-center items-center flex-1">
              <img
                className="object-contain h-full w-full"
                src={fotoSeleccionada === "" ? (esPaquete ? PaqueteImage : ProductoImage) : fotoSeleccionada}
                alt=""
              />
            </div>
          </div>
          <span className="flex gap-2 items-center">
            <InfoIcon className="rotate-180" />
            <p>Compre ahora y recoja este producto dentro de los próximos 10 días hábiles.</p>
          </span>
          <div className="w-full hidden lg:block">
            <ProductoEspecificaciones
              caracteristicas={caracteristicas}
              descripcion={descripcion}
              esPaquete={esPaquete}
              productos={esPaquete ? productoInfo.paquete.productos : []}
            />
            {productoInfo.producto && productoInfo.producto.variables.length > 0 && (
              <DisclosureSection
                title="Características del producto:"
                content={<div className="visor" dangerouslySetInnerHTML={{ __html: sanitizedCaracteristicas }} />}
              />
            )}
          </div>
        </div>
        <div className="col-span-4 xl:col-span-1 flex flex-col gap-6 flex-1 w-full">
          <div className="flex flex-col gap-6 bg-[#E5E7EB80] p-6">
            <div className="flex flex-col">
              <h3 className="font-bold text-2xl break-words">{nombre}</h3>
              <span className="flex gap-2 items-center">
                <p className="font-bold text-sm">ID del artículo:</p>
                <p> {esPaquete ? productoInfo.paquete._id : productoInfo.producto._id }</p>
              </span>
            </div>
            <div className="flex flex-wrap gap-4 items-center">
              <p className="text-3xl">{abreviatura} {formatCurrency(precioVenta)}</p>
              {estatus ? (
                <div>
                  <span className="flex items-center space-x-3 border-2 w-fit min-w-[8.5rem] px-2 py-3 rounded bg-white justify-evenly">
                    <MinusIcon
                      className={`${
                        values.cantidadArticulo > 1
                          ? "opacity-100 pointer-events-auto"
                          : "opacity-80 pointer-events-none"
                      } cursor-pointer`}
                      onClick={handleDecrementarCantidad}
                    />
                    <InputNumber
                      styles="-mt-2 !w-24 !p-2 text-center"
                      name={"cantidadArticulo"}
                      value={values.cantidadArticulo ?? 1}
                      onChange={(e) => handleChange(e, "cantidadArticulo")}
                    />
                    <AddIcon
                      className={`${
                        values.cantidadArticulo < 1000
                          ? "opacity-100 pointer-events-auto"
                          : "opacity-80 pointer-events-none"
                      } cursor-pointer`}
                      onClick={handleIncrementarCantidad}
                    />
                  </span>
                  <p className="font-thin text-xs text-red-400">{errors["cantidadArticulo"]}</p>
                </div>
              ) : (
                <img
                  className="h-8 cursor-pointer"
                  src={esFavorito ? FavoritosSeleccionadoIcon : FavoritosIcon}
                  alt=""
                  onClick={handleFavoritos}
                />
              )}
            </div>
            <div className="flex flex-col-reverse sm:flex-row justify-end sm:justify-start  flex-wrap gap-4 items-start sm:items-center">
              <div className="flex flex-wrap gap-4">
                {estatus ? (
                  <>
                    <SubmitButton
                      text={"Agregar al carrito"}
                      styles="h-10 !w-40"
                      onClick={() => handleComprar(false)}
                    />
                    <SubmitButton
                      text={"Ver carrito"}
                      bgColor="bg-sidebar"
                      styles="h-10 !w-40"
                      onClick={() => navigate("/marketplace/tienda/carritoDeCompra")}
                    />
                  </>
                ) : (
                  <SubmitButton text={"Agotado"} bgColor="bg-[#D9D9D9]" styles="h-10 !w-40" disabled />
                )}
              </div>
              {estatus && (
                <img
                  className="h-8 cursor-pointer"
                  src={esFavorito ? FavoritosSeleccionadoIcon : FavoritosIcon}
                  alt=""
                  onClick={handleFavoritos}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full lg:pl-6">
            <Disclosure as="div" className="py-6" defaultOpen={true}>
              {({ open }) => (
                <>
                  <h3 className="-my-3 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between py-2 text-sm">
                      <div className="flex items-center gap-2">
                        <span className="font-bold">{`Información del ${esPaquete ? 'paquete' : 'artículo' }`}</span>
                      </div>
                      <span className="ml-6 flex items-center">
                        <img className="ml-4" src={open ? ArrowDown : ArrowUp} alt="" />
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-4">
                      {detalles.map((caracteristica) => (
                        <span key={caracteristica._id} className="flex gap-2 items-center">
                          <p className="font-bold text-sm">{caracteristica.nombre}:</p>
                          <p> {caracteristica.valor}</p>
                        </span>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {!esPaquete && (
              <div className="col-span-4 xl:col-span-1 flex flex-col gap-6 flex-1">
                <ProductoAtributos
                  atributos={productoInfo.producto.variables}
                  variaciones={productoInfo.producto.variaciones}
                  atributosSeleccionados={atributosSeleccionados}
                  setAtributosSeleccionados={setAtributosSeleccionados}
                />
              </div>
            )}
            {productoInfo.producto && productoInfo.producto.variables.length === 0 && (
              <div className="hidden lg:block">
                <DisclosureSection
                  title="Características del producto:"
                  content={<div className="visor" dangerouslySetInnerHTML={{ __html: sanitizedCaracteristicas }} />}
                />
              </div>
            )}
            {esPaquete && (
              <div className="hidden lg:block">
                <DisclosureSection
                  title="Productos del paquete:"
                  content={<ProductosPaqueteTable productos={esPaquete ? productoInfo.paquete.productos : []} />}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col w-full lg:hidden ${
          productoInfo.producto && productoInfo.producto.variables.length > 0 && "lg:grid grid-cols-9 xl:grid-cols-2"
        }`}
      >
        <div className="col-span-5 xl:col-span-1 flex flex-col items-center gap-6">
          <ProductoEspecificaciones
            caracteristicas={caracteristicas}
            descripcion={descripcion}
            esPaquete={esPaquete}
            productos={esPaquete ? productoInfo.paquete.productos : []}
          />
        </div>
        {productoInfo.producto && productoInfo.producto.variables.length > 0 && (
          <DisclosureSection
            title="Características del producto:"
            content={<div className="visor" dangerouslySetInnerHTML={{ __html: sanitizedCaracteristicas }} />}
          />
        )}
        {productoInfo.producto && productoInfo.producto.variables.length === 0 && (
          <div className="block lg:hidden">
            <DisclosureSection
              title="Características del producto:"
              content={<div className="visor" dangerouslySetInnerHTML={{ __html: sanitizedCaracteristicas }} />}
            />
          </div>
        )}
        {esPaquete && (
          <div className="block lg:hidden">
            <DisclosureSection
              title="Productos del paquete:"
              content={<ProductosPaqueteTable productos={esPaquete ? productoInfo.paquete.productos : []} />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductoInfoDetalle;
