import ReporteAvisosDetallesFilterForm from "./ReporteAvisosDetallesFilterForm";

const ReporteAvisosDetallesFilter = ({
  read,
  unread,
  destinatarios,
  setDestinatariosFiltro,
}) => {
  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {read}
          </p>
          <p className="text-black">Leídos</p>
        </div>

        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {unread}
          </p>
          <p className="text-black">Sin leer</p>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-2">
        <ReporteAvisosDetallesFilterForm
          destinatarios={destinatarios}
          setDestinatariosFiltro={setDestinatariosFiltro}
        />
      </div>
    </div>
  );
};

export default ReporteAvisosDetallesFilter;
