import React from "react";
import Completado from "../../assets/icons/completado.svg";
import Incompleto from "../../assets/icons/restringido.svg";

const MenuCard = ({ icon, title, status, ...otherProps }) => {

  return (
    <div
      className="border-2 h-72 w-80 rounded-xl flex flex-col items-center justify-center bg-white cursor-pointer relative"
      {...otherProps}
    >
      <img src={icon} alt={`${icon}.jpg`} />
      <img src={status ? Completado : Incompleto} className="absolute top-4 left-4" alt="estatus.svg" />
      <p className="text-nimbusDark font-bold text-xl w-64 text-center mt-8">{title}</p>
    </div>
  );
};

export default MenuCard;
