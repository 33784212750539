import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import { MESSAGES } from "../../../utils/messagesUtils";
import NoDataMessage from "../../../fragments/NoDataMessage";
import { usePagination } from "../../../hooks/usePagination";
import TablePagination from "../../../fragments/TablePagination";
import { UserContext } from "../../../services/user";
import { getProductosEstructura } from "../../../services/Api/MarketPlace/Productos";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import SubHeaderPaquetesProductos from "./SubHeaderPaquetesProductos";
import PaquetesProductosTable from "./fragments/PaquetesProductosTable";
import PaquetesProductosFilter from "./fragments/PaquetesProductosFilter";
import { getPaquetesMarketplaceEstructura } from "../../../services/Api/MarketPlace/Paquetes";
import { getPaises } from "../../../services/api";
import { PaisesContext } from "../../../../contexts/PaisesContext";

const PaquetesProductos = () => {
  const [loading, setLoading] = useState(true);
  const { user, activeRole } = useContext(UserContext);
  const { obtenerPaises } = useContext(PaisesContext);
  const [paquetes, setPaquetes] = useState([]);
  const [paquetesFiltro, setPaquetesFiltro] = useState([]);
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"])

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(paquetesFiltro, 20);

  const obtenerPaquetes = useCallback(async () => {
    if (activeRole !== "") {
      try {
        let { estructura, tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
        await obtenerPaises();
        const paquetesResponse = await getPaquetesMarketplaceEstructura(tipoEstructuraRequest, estructuraId);
        const paises = await getPaises()
        setPaquetes(paquetesResponse);
        setPaquetesFiltro(paquetesResponse);
        if (estructura.dirFiscalPais) {
          const simbolo =
          estructura.dirFiscalPais.nombre === "Estados Unidos" ? 
          ["USD"] 
          : estructura.dirFiscalPais.simbolo 
          ? [estructura.dirFiscalPais.simbolo]
          : [paises.data.filter(pais => pais._id === estructura.dirFiscalPais)[0]?.simbolo];
          setTipoDeMoneda(simbolo);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [activeRole]);

  useEffect(() => {
    obtenerPaquetes();
  }, [obtenerPaquetes]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderPaquetesProductos />
            <div className={`${paquetes.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}>
              <div>
                <PaquetesProductosFilter
                  paquetes={paquetes}
                  paquetesFiltro={paquetesFiltro}
                  setPaquetesFiltro={setPaquetesFiltro}
                />
                <PaquetesProductosTable
                  paquetes={currentData()}
                  obtenerPaquetes={obtenerPaquetes}
                  tipoDeMoneda={tipoDeMoneda}
                />
                {paquetes.length !== 0 && paquetesFiltro.length === 0 && (
                  <NoDataMessage message={MESSAGES.MESSAGE_FILTER_PAQUETES_PRODUCTOS_TABLE} />
                )}
              </div>
              <div>
                {paquetes.length === 0 ? (
                  <NoDataMessage message={MESSAGES.MESSAGE_PAQUETES_PRODUCTOS_TABLE} />
                ) : (
                  <TablePagination
                    next={next}
                    prev={prev}
                    currentPage={currentPage}
                    data={currentData()}
                    jump={jump}
                    maxPage={maxPage}
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PaquetesProductos;
