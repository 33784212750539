import React from "react";
import ResumenCompraRow from "./ResumenCompraRow";
import { formatCurrency } from "../../../utils/productoUtils";

const ResumenCompraTable = ({ articulosList, total, subTotal, detallesPais }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {articulosList.map((venta) => (
            <ResumenCompraRow key={venta._id} ventaInfo={venta} detallesPais={detallesPais}/>
          ))}
          <tr className="p-2 text-left bg-white border-b-2 border-[#EEF1F3] h-16">
            <td className="rounded-bl-sm text-left font-sans p-2"></td>
            <td className="font-sans text-left"></td>
            <td className="font-sans text-left"></td>
            <td className=" font-sans text-right text-xl">
              <div className="flex gap-2 align-middle">
                <p className="text-[#808080]">SubTotal:</p>
                <p className="text-[#B3B3B3]">{detallesPais.simbolo} {formatCurrency(subTotal)}</p>
              </div>
            </td>
            <td className={`rounded-br-sm  font-sans text-right text-xl`}>
              <div className="flex gap-2 align-middle">
                <p className="text-[#808080]">Total:</p>
                <p className="text-[#B3B3B3]">{detallesPais.simbolo} {formatCurrency(total)}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ResumenCompraTable;
