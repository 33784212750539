import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import CerrarActButton from "../ActividadesUtils/CerrarActButton";
import ImagenValidadaComparador from "./ActividadesUtils/RecursosValidados/ImagenValidadaComparador";

const MemoramaForPDFs = ({ data, evaluable, instrucciones }) => {
  const activityContext = useContext(ActividadContext);

  const handleCerrarAct = () => {
    activityContext.postCalificacionActividad(null);
  };

  return (
    <div className="my-5 w-full">
      {instrucciones && <div className="max-w-activity act-instructions mb-6">{instrucciones}</div>}
      <section className="memory-game relative  max-w-activity">
        {data.map((item, index) => (
          <div
            className={`relative memory-card pointer-events-none`}
            key={index}
          >
            {!evaluable ? (
              <div className="absolute top-1 left-1 z-10 !bg-[#3ea34d] font-bold text-sm !w-7 !h-7 !text-white flex justify-center align-center !rounded-full back-face !p-0 flex items-center justify-center">
                {item.orden}
              </div>
            ) : (
              ""
            )}
            {item.imagen ? (
              <ImagenValidadaComparador
                className="!bg-white border back-face"
                src={
                  typeof item.imagen !== "string"
                    ? URL.createObjectURL(item.imagen)
                    : item.imagen
                }
              />
            ) : (
              ""
            )}
            {item.texto ? (
              <div className="back-face !bg-white border border-[#dcdcdc] flex items-center justify-center text-sm md:text-xl text-nimbus text-center p-2 md:p-4">
                {item.texto}
              </div>
            ) : (
              ""
            )}
            {item.audio ? (
              <FontAwesomeIcon
                className="back-face !bg-white !box-border !p-[2.5rem]"
                color={"#1c7ccc"}
                icon={faVolumeHigh}
              ></FontAwesomeIcon>
            ) : (
              ""
            )}
          </div>
        ))}
      </section>
      <CerrarActButton onClick={handleCerrarAct} />
    </div>
  );
};

MemoramaForPDFs.defaultProps = {
  evaluable: true,
  data: [],
};

export default MemoramaForPDFs;
