import React, {useState, useEffect, useRef} from 'react'
import ReactPlayer from 'react-player'
import { Trans } from 'react-i18next'
import ImagenValidada from './ActividadesUtils/RecursosValidados/ImagenValidada'
import VideoValidado from './ActividadesUtils/RecursosValidados/VideoValidado'

function CarouselSlide({slideData, currentSlide, index}) {
  const [colapse, setColapse] = useState(true)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if(slideData.texto !== ''){
      setHeight(ref.current.clientHeight)
    }
  })

  return (
    <div className="whitespace-pre-wrap mb-10">
      <div className="relative">
        {slideData.imagen ? 
          <ImagenValidada
          src={slideData.imagen}
          className="mx-auto block w-full aspect-video object-contain rounded-xl mb-[10px] shadow-xl border min-h-[16rem]"
          />
        :''
        }
        {slideData.video  ? 
            currentSlide === index ?
            <div className='video_bg'>
              <VideoValidado
                src={slideData.video}
                className='mx-auto block w-full aspect-video object-cover rounded-xl mb-5 shadow-xl border min-h-[16rem]'
              />
            </div>
            :<div className='mx-auto block w-full aspect-video object-cover rounded-xl mb-5 shadow-xl border min-h-[16rem]'></div>
          :null
        }
      </div>
      {slideData.texto ?
      <div className={`mb-10 transform md:w-[100%] shadow-lg text-nimbusLight md:text-2xl border bg-white rounded-xl  p-2 md:p-4 text-sm  ${colapse ? "overflow-hidden" : 'max-h-[70%] md:max-h-[80%] overflow-auto'} ${slideData.imagen ? 'bottom-[2rem]' : 'bottom-[65px] md:bottom-[90px]'}`}>
          <span 
            ref={ref} 
            className={`block overflow-hidden ${colapse ? 'max-h-[45px] md:max-h-[70px]' : ''}`}
          >
            {slideData.texto}
          </span>
          {height > 65 ?
            <button 
              className="text-sm md:text-base underline text-[#18bafb] float-right font-bold hidden md:block" 
              onClick={()=>setColapse(!colapse)}
            >
              {colapse ? <Trans i18nKey='carouselShowMore'></Trans> : <Trans i18nKey='carouselShowLess'></Trans>}
            </button>
          :''
          }
          {height >= 45 ?
            <button 
              className="text-sm md:text-base underline text-[#18bafb] float-right font-bold block md:hidden" 
              onClick={()=>setColapse(!colapse)}
            >
              {colapse ? <Trans i18nKey='carouselShowMore'></Trans> : <Trans i18nKey='carouselShowLess'></Trans>}
            </button>
          :''
          }
      </div>
      :
      ''}
    </div>
  )
}

export default CarouselSlide