import React from "react";
import NimbusModal from "../../../../../fragments/NimbusModal";
import CancelButton from "../../../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../../../fragments/Buttons/SubmitButton";
import useFormSubmit from "../../../../../hooks/useFormSubmit";
import { deleteVariacionProducto } from "../../../../../services/Api/MarketPlace/Productos/Variaciones";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../../utils/alertsUtils";

const EliminarVariacionModal = ({ isOpen, setIsOpen, variacionId, obtenerVariaciones }) => {
  const params = useParams();

  const eliminarVariacionSubmit = async () => {
    try {
      await deleteVariacionProducto(params.productoId, variacionId);
      toast.success("Variación eliminada correctamente", alertConfiguration);
      setIsOpen(false);
      obtenerVariaciones();
    } catch (error) {
      setIsOpen(false);
      console.log(error);
      toast.error("Ocurrió un error", alertConfiguration);
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(eliminarVariacionSubmit);
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Eliminar variación</h1>
      <p className="w-96 px-4 sm:px-2">
        Al eliminar esta variación, no se podrá seleccionar en la tienda una combinación con estas características para
        este producto, y en dado caso de que algún padre ya haya agregado este producto a su carrito con está variación,
        se le mostrará como artículo no existente.
      </p>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea eliminar la variación?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default EliminarVariacionModal;
