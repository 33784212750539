export default {
  lineColor: 'CL',
  lineColorFull: 'Couleur de la ligne',
  fillColor: 'CR',
  fillColorFull: 'Couleur de Remplissage',
  alpha: 'T',
  alphaFull: 'Transparence',
  lineWidth: 'L',
  lineWidthFull: 'Largeur de ligne',
  arrowLength: 'F',
  arrowLengthFull: 'Longeur de la flèche',
  eraserWidth: 'LG',
  eraserWidthFull: 'Largeur de la gomme',
  textColor: 'CT',
  textColorFull: 'Couleur du texte',
  fontSize: 'TP',
  fontSizeFull: 'Taille de Police',
  fontStrokeSize: 'LT',
  fontStrokeSizeFull: 'Largeur du trait',
  fontStyle: 'SP',
  fontStyleFull: 'Style de police',
  fontName: 'NP',
  fontNameFull: 'Nom de la police',
  textStrokeColor: 'CT',
  textStrokeColorFull: 'Couleur du trait',
  apply: 'Appliquer',
  cancel: 'Annuler',
  close: 'Fermer',
  clear: 'Effacer',
  width: 'Largeur',
  height: 'Hauteur',
  keepRatio: 'Conserver le rapport largeur/hauteur',
  fillPageWith: 'Remplir avec la couleur d\'arrière-plan courante',
  pixelSize: 'P',
  pixelSizeFull: 'Taille de Pixel',
  resizeScale: 'Échelle',
  resizeResize: 'Redimensionner',
  backgroundColor: 'Couleur de fond de la page',
  pixelizePixelSize: 'Pixéliser la taille de pixel',
  language: 'Langue',
  wrongPixelSizeValue:
    'Mauvaise taille de pixel. Vous pouvez ajouter par exemple e.g. \'20%\' ce qui signifie que la taille moyenne des pixels sera 1/5 de la ' +
    'surface sélectionnée, ou \'4\' signifie 4 px',
  tools: {
    crop: 'Recadrer l\'image dans la zone sélectionnée',
    pixelize: 'Pixéliser la zone sélectionnée',
    rect: 'Dessiner un rectangle',
    triangleType2: 'Dessiner un triangle',
    triangle: 'Dessiner un triangle rectangle',
    ellipse: 'Dessiner une ellipse',
    line: 'Dessiner une ligne',
    arrow: 'Dessiner une flèche',
    rotate: 'Pivoter l\'image',
    save: 'Enregistrer l\'image',
    load: 'Charger l\'image',
    text: 'Mettre du texte',
    brush: 'Brosse',
    resize: 'Redimensionner ou échelle',
    open: 'Ouvrir l\'image',
    select: 'Sélectionnez une région',
    close: 'Fermer Painterro',
    eraser: 'Gomme',
    settings: 'Réglages',
    undo: 'Annuler',
    redo: 'Refaire',
  },
  pasteOptions: {
    fit: 'Tout Remplacer',
    extend_down: 'Étendre vers le bas',
    extend_right: 'Étendre à droite',
    extend: 'Étendre',
    over: 'Coller sur',
    how_to_paste: 'Comment coller?',
  },
};
