import React from "react";
import {ReactComponent as WarningIcon} from '../../assets/icons/warningIcon.svg'

const NoDataMessage = ({message, styles, stylesWrapper = ""}) => {
  return (
    <div className={`flex flex-col items-center w-full justify-center gap-x-2 p-2 my-24 ${stylesWrapper}`}>
      <WarningIcon />
      <p className={`w-1/2 text-center text-sm font-bold text-gray-400 mt-6 ${styles}`}>
        {message}
      </p>
    </div>
  );
};

export default NoDataMessage;
