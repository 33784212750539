import { _delete, _get, _post, _put } from "../../../http";

// carrito de compras

export const getCarritoDeCompras = () => _get("/market-place/carritoCompras");

export const postCarritoDeCompras = (body) => _post("/market-place/carritoCompras", body);

export const putProductoCarritoDeCompras = (productoId, body) =>
  _put(`/market-place/carritoCompras/${productoId}`, body);

export const deleteProductoCarritoDeCompras = (productoId) => _delete(`/market-place/carritoCompras/${productoId}`);

export const deleteVaciarCarritoDeCompras = () => _delete("/market-place/carritoCompras/vaciarCarrito");
