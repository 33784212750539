import React from "react";
import SubHeader from "../../../fragments/SubHeader";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../fragments/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";

const SubHeaderAsignarProductos = ({ userRole }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { nombreEstructura } = location.state || {};

  return (
    <SubHeader>
      <div>
        <BreadCrumbContainer>
          <BreadCrumb
            route={
              userRole === "DIR_PLANTEL" || userRole === "ADMIN_OPERATIVO"
                ? "Paquetes escolares /"
                : "Asignación de artículos /"
            }
            onClick={() => navigate(-1)}
          />
          <BreadCrumb route={"Asignar artículos"} active />
        </BreadCrumbContainer>
        <div className="flex text-base md:text-lg text-transparent bg-clip-text bg-gradient-to-r from-[#18BAFB] to-[#3D90E1] items-center">
          <p>{nombreEstructura}</p>
        </div>
      </div>
    </SubHeader>
  );
};

export default SubHeaderAsignarProductos;
