import React from 'react'
import SubHeader from '../../../../fragments/SubHeader'
import BreadCrumbContainer from '../../../../fragments/BreadCrumbContainer'
import BreadCrumb from '../../../../fragments/BreadCrumb'
import { useNavigate } from 'react-router-dom'

const SubHeaderCheckoutReturn = () => {

    const navigate = useNavigate()

  return (
    <SubHeader styles="bg-transparent min-h-fit mt-6">
      <BreadCrumbContainer>
        <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} styles="text-black/70 !md:text-lg" />
        <BreadCrumb route={"Compra exitosa"} active styles="text-black !md:text-lg break-words" />
      </BreadCrumbContainer>
    </SubHeader>
  )
}

export default SubHeaderCheckoutReturn