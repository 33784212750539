import React from "react";
import InputText from "../../../fragments/Inputs/InputText";
import InputGeocode from "../../../fragments/Inputs/InputGeocode/InputGeocode";

const UBICACION_INITIAL_VALUES = {
  nombre: "",
  direccion: "",
  coordenadas: {
    lat: "",
    lng: "",
  },
};

const DatosEnvioForm = ({ datosEnvio, errors, handleChange, setDatosEnvio, setIsEnableSubmitButton }) => {
  let { nombreDireccion, telefono, referencias, ubicacion } = datosEnvio;

  const handleSetUbicacion = (ubicacion) => {
    setDatosEnvio({ ...datosEnvio, ubicacion: ubicacion });
    setIsEnableSubmitButton(true)
  };

  return (
    <div className="flex flex-col sm:grid sm:grid-cols-2 gap-3 sm:gap-6">
      <InputText
        name={"nombreDireccion"}
        label={"Nombre de la dirección"}
        placeholder={"Ingrese el nombre de la dirección"}
        styles="!w-full"
        value={nombreDireccion}
        onChange={(e) => handleChange(e, "nombreDireccion")}
        error={errors["nombreDireccion"]}
      />
      <InputText
        name={"telefono"}
        label={"Teléfono de contacto"}
        placeholder={"Ingrese el teléfono de contacto"}
        styles="!w-full"
        value={telefono}
        onChange={(e) => handleChange(e, "telefono")}
        error={errors["telefono"]}
      />
      <div className="col-span-2">
        <InputText
          name={"referencias"}
          label={"Otras referencias"}
          placeholder={"Ingrese otras referencias"}
          styles="!w-full"
          value={referencias}
          onChange={(e) => handleChange(e, "referencias")}
          error={errors["referencias"]}
        />
      </div>
      <div className="col-span-2">
        <InputGeocode
          onChange={handleSetUbicacion}
          initialValues={ubicacion !== "" ? ubicacion : UBICACION_INITIAL_VALUES}
        />
      </div>
    </div>
  );
};

export default DatosEnvioForm;
