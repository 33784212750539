import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';

const SubHeaderCategoriaNotificacionesDetalles = ({ ver, editar }) => {
  const navigate = useNavigate();
  return (
    <div className='flex align-middle justify-between items-center bg-white'>
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={'Categorías de notificaciones /'} onClick={() => navigate(-1)} />
          <BreadCrumb
            route={
              ver ? 'Ver categoría' : editar ? 'Editar categoría' : 'Agregar categoría'
            }
            active
          />
        </BreadCrumbContainer>
      </SubHeader>
    </div>
  );
};

export default SubHeaderCategoriaNotificacionesDetalles;
