import { formatShowDate } from "../../../utils/dateFunctions";
import { getCategoriasNotificaciones } from "../../../services/Api/CategoriaNotificaciones";
import { toast } from "react-toastify";
import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { UserContext } from "../../../services/user";
import alertConfiguration from "../../../utils/alertsUtils";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../fragments/Inputs/InputSelect";

const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

const ReporteAvisosFilter = ({ filtro, setFiltro }) => {
  const { user } = useContext(UserContext);

  const [dateFieldType, setDateFieldType] = useState("text");
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const debounceTimeout = useRef(null);

  useEffect(() => {
    if (user?._id) getNotificationsCategories();
  }, [user?._id]);

  // Petición para obtener listado de categoirías
  const getNotificationsCategories = async () => {
    try {
      setIsLoadingCategories(true);
      let resp = await getCategoriasNotificaciones(user?._id);

      resp = resp.sort((a, b) => {
        if (a.fechaCreacion > b.fechaCreacion) return 1;
        if (a.fechaCreacion < b.fechaCreacion) return -1;
        return 0;
      });

      setCategories(resp);
    } catch (error) {
      console.log("🚀 ~ ReporteAvisosFilter ~ error:", error);
      toast.error(
        "Error al obtener las categorías de notificaciones",
        alertConfiguration
      );
    } finally {
      setIsLoadingCategories(false);
    }
  };

  // Función que maneja el cambio del input
  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setInputValue(value);

    // Limpia el timeout previo
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Inicia un nuevo timeout
    debounceTimeout.current = setTimeout(() => {
      setFiltro({ ...filtro, busqueda: value });
    }, 500); // Ajusta el tiempo del debounce, en este caso 500ms
  };

  // Cleanup del timeout al desmontar el componente
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  // Memoize categories to avoid unnecessary re-renders
  const memoizedCategories = useMemo(() => categories, [categories]);

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-end space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex flex-wrap items-center justify-center gap-2">
        <div className="flex flex-row">
          <div>
            <input
              type={dateFieldType}
              name="fechaInicio"
              id="fechaInicio"
              placeholder="Fecha de envío inicial"
              onFocus={() => setDateFieldType("date")}
              onBlur={() => setDateFieldType("text")}
              max={filtro?.fechaFin ?? undefined}
              value={
                filtro.fechaInicio
                  ? formatShowDate(addDays(new Date(filtro.fechaInicio)))
                  : ""
              }
              onChange={(e) => {
                const value = e.target.value;
                value
                  ? setFiltro({ ...filtro, fechaInicio: value })
                  : setFiltro({
                      ...filtro,
                      fechaInicio: value,
                      fechaFin: value,
                    });
              }}
              className={
                "rounded-l-lg p-2 w-full border-divider border text-black/60"
              }
            />
          </div>
          <div>
            <input
              type={dateFieldType}
              name="fechaFin"
              id="fechaFin"
              placeholder="Fecha de envío final"
              onFocus={() => setDateFieldType("date")}
              onBlur={() => setDateFieldType("text")}
              disabled={filtro?.fechaInicio === ""}
              min={filtro?.fechaInicio ?? undefined}
              value={
                filtro.fechaFin
                  ? formatShowDate(addDays(new Date(filtro.fechaFin)))
                  : ""
              }
              onChange={(e) =>
                setFiltro({ ...filtro, fechaFin: e.target.value })
              }
              className={
                "rounded-r-lg p-2 w-full border-divider border text-black/60 disabled:bg-[#EEEEEE]"
              }
            />
          </div>
        </div>

        <div className="flex flex-row">
          <InputSelect
            id="categoria"
            name="categoria"
            value={filtro.categoria}
            options={memoizedCategories ?? []}
            onChange={(e) =>
              setFiltro({ ...filtro, categoria: e.target.value })
            }
            styles="lg:w-[124px]"
            label={"Categoría"}
            disabled={isLoadingCategories}
            topLabel={true}
          />
        </div>

        <div className="flex flex-row">
          <InputBuscar onChange={handleChangeSearch} />
        </div>
      </div>
    </div>
  );
};

export default ReporteAvisosFilter;
