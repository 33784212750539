import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../fragments/ConfirmationModal";
import folder from "../../../../assets/img/folder.svg";
import { useLocation } from "react-router-dom";

const CarpetaCard = ({
  carpeta,
  handleDelete,
  handleEdit,
  isTarea = false,
}) => {
  let navigate = useNavigate();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const location = useLocation();

  return (
    <div className="w-full md:w-60 rounded-lg border h-32 p-2">
      <div
        title={carpeta.nombre}
        className="grid grid-cols-3 p-2 cursor-pointer"
        onClick={() =>
          navigate(
            `/constructor/${isTarea ? "carpetaTarea" : "CarpetaExamen"}/${
              carpeta._id
            }`,
            {
              state: {
                nombreCarpetaPrimaria: location?.state?.nombreCarpeta,
                nombreCarpeta: carpeta?.nombre,
              },
            }
          )
        }
      >
        <img className={`${carpeta.color}-folder w-10`} src={folder} alt="" />
        <div className="col-span-2 py-2">
          <p className="text-[#1A3D82] font-bold truncate hover:text-ellipsis">
            {carpeta.nombre}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-10 items-end p-2">
        <div className="col-span-7">
          {carpeta.nivel === 0 ? (
            <p className="text-xs">Carpetas: {carpeta.carpetas.length}</p>
          ) : (
            ""
          )}
          <p className="text-xs">
            Contenido:{" "}
            {carpeta?.umas?.length
              ? carpeta.umas.length
              : carpeta?.tareas?.length
              ? carpeta.tareas.length
              : 0}
          </p>
        </div>
        <button title="Editar" className="mx-2">
          <svg
            onClick={() => handleEdit(carpeta._id)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mt-5 text-[#1A3D82] col-span-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </button>
        <button title="Eliminar" className="mx-4">
          <svg
            onClick={() => setDeleteConfirmation(true)}
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-[#1A3D82] col-span-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
      <ConfirmationModal
        isOpen={deleteConfirmation}
        setIsOpen={setDeleteConfirmation}
        handleAction={() => handleDelete(carpeta._id)}
        action="Eliminar"
        messages={[
          "El proceso de eliminación solo elimina carpetas, no borra los exámenes contenidos, solo los mueve al listado de exámenes principal.",
          "¿Está seguro que desea eliminar la carpeta?",
        ]}
      />
    </div>
  );
};

export default CarpetaCard;
