import { useContext, useState } from "react";
import TooltipButton from "../fragments/Buttons/TooltipButton";
import TrashIcon from "../../assets/icons/trash2.svg";
import ModalInfo from "../fragments/Modals/ModalInfo";
import { deleteUMA } from "../services/api";
import ReactLoading from "react-loading";
import {
  errorToast,
  successToast,
} from "../constructor/umas/ActividadesUtils/FormularioUtils";
import { deleteTarea } from "../services/Api/Tareas";
import { postMoverLibroPapelera } from "../services/Api/Constructor/Libros";
import { UserContext } from "../services/user";

function EliminadoMasivoContenidos({ seleccionados, contentType, reload }) {
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleWarning = () => setShowWarning(!showWarning);
  const user = useContext(UserContext);

  const handleDelete = () => {
    if (!seleccionados) return;
    setLoading(true);
    let deletionPromises;
    if (contentType === "EXAMENES") {
      deletionPromises = seleccionados.map((examen) => {
        return deleteUMA(examen);
      });
    } else if (contentType === "TAREAS") {
      deletionPromises = seleccionados.map((tarea) => {
        return deleteTarea(tarea);
      });
    } else if (contentType === "LIBROS") {
      deletionPromises = seleccionados.map((libroId) => {
        let body = {
          libro: libroId,
          usuario: user._id,
        };
        return postMoverLibroPapelera(body);
      });
    }
    Promise.allSettled(deletionPromises)
      .then(() => {
        setLoading(false);
        successToast("Se han guardado tus cambios con éxito");
        toggleWarning();
        reload();
      })
      .catch(() => {
        errorToast(
          "Hubo un problema al guardar tus cambios. Inténtalo de nuevo en unos momentos"
        );
      });
  };

  return (
    <div>
      {showWarning && (
        <ModalInfo
          hideBtn
          isOpen={showWarning}
          toggleModal={toggleWarning}
          title={"Eliminar contenidos"}
        >
          {loading ? (
            <ReactLoading
              type="spin"
              color="#1A3D82"
              height="80px"
              width="80px"
              className=" mr-auto ml-auto mt-10"
            />
          ) : (
            <div className="text-center my-4">
              <div className="mb-2">
                Al eliminar un contenido se enviará a la papelera,
                posteriormente si lo desea puede recuperarlo.
              </div>
              <div>
                ¿Está seguro que desea eliminar {seleccionados?.length}{" "}
                contenido
                {seleccionados.length > 1 && "s"}?
              </div>
              <div className="mt-5">
                <button
                  className="bg-gray-200 font-semibold text-nimbusDark p-2 w-28 rounded mx-2"
                  onClick={toggleWarning}
                >
                  Cancelar
                </button>
                <button
                  className="bg-nimbusDark font-semibold text-white p-2 w-28 rounded mx-2"
                  onClick={handleDelete}
                >
                  Continuar
                </button>
              </div>
            </div>
          )}
        </ModalInfo>
      )}
      <TooltipButton
        icon={TrashIcon}
        message={"Eliminar"}
        handleClick={toggleWarning}
        styles={"w-auto h-10 ml-2"}
        tooltipText="Transferir propiedad"
      />
    </div>
  );
}

export default EliminadoMasivoContenidos;
