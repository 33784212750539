import React from "react";
import CustomInput from "../../../fragments/Inputs/CustomInput";
import AddBlueIcon from "../../../../assets/icons/addblue.svg";
import AddIcon from "../../../../assets/icons/add.svg";
import DeleteIcon from "../../../../assets/icons//deleteListIcon.svg";

const VariablesProductoForm = ({ variables, variablesMetodos, setIsEnableSubmitButton, editar, ver, extensions, errors }) => {
  const {
    handleAgregarValor,
    handleEliminarValor,
    handleEliminarVariable,
    handleAgregarVariable,
    handleActualizarValorImg,
    handleActualizarValorText,
    handleActualizarVariableText,
  } = variablesMetodos;

  const handleChangeVariableText = (e, variableId) => {
    handleActualizarVariableText(e, variableId);
    setIsEnableSubmitButton(true);
  };
  const handleChangeValorText = (e, variableId, valorId) => {
    handleActualizarValorText(e, variableId, valorId);
    setIsEnableSubmitButton(true);
  };

  const handleChangeValorImg = (e, variableId, valorId, extensions) => {
    handleActualizarValorImg(e, variableId, valorId, extensions);
    setIsEnableSubmitButton(true);
  };

  const handleAddVariable = () => {
    handleAgregarVariable();
    setIsEnableSubmitButton(true);
  };
  const handleDeleteVariable = (variableId) => {
    handleEliminarVariable(variableId);
    setIsEnableSubmitButton(true);
  };

  const handleAddValor = (variableId) => {
    handleAgregarValor(variableId);
    setIsEnableSubmitButton(true);
  };
  const handleDeleteValor = (variableId, valorId) => {
    handleEliminarValor(variableId, valorId);
    setIsEnableSubmitButton(true);
  };

  return (
    <div className="mx-0 lg:mx-24">
      <span className="ml-1 flex space-x-3 items-center">
        <h4 className="capitalize text-black">Atributos</h4>
        {!ver && !editar && <p className="text-gray-400">(Mínimo 1 máximo 3)</p>}
      </span>
      {variables.map((variable) => (
        <div key={variable.variableId} className="bg-[#f5f5f5] rounded-lg p-4 w-full relative mt-3 mb-6">
          <h5 className="mb-2 ml-1 mt-4 lg:mt-0 capitalize">Atributo</h5>
          <CustomInput
            text={true}
            textValue={variable.variable}
            placeholder={"Ingrese el nombre del atributo"}
            handleText={(e) => handleChangeVariableText(e, variable.variableId)}
            disabled={ver || editar}
            error={errors[variable.variableId]?.error}
          />
          <div className="flex flex-col gap-3 w-full overflow-x-auto sm:overflow-x-hidden">
            <span className="mt-3 ml-1 flex space-x-3 items-center">
              <h6 className="capitalize">Valores</h6>
              {!ver && !editar && <p className="text-sm text-gray-400">(Mínimo 1 máximo 20)</p>}
            </span>
            {variable.valores.map((valor) => (
              <div key={valor.valorId} className="flex mr-4 w-full">
                <CustomInput
                  text={true}
                  imagen={true}
                  textValue={valor.valor}
                  imageValue={valor.imagen}
                  placeholder={"Ingrese el valor"}
                  handleText={(e) => handleChangeValorText(e, variable.variableId, valor.valorId)}
                  handleImage={(e) => handleChangeValorImg(e, variable.variableId, valor.valorId, extensions)}
                  disabled={ver || editar}
                  extensions={extensions}
                  error={errors[variable.variableId]?.valores[valor.valorId]}
                />
                {variable.valores.length > 1 && !ver && !editar &&(
                  <button
                    className="relative flex-shrink-0 self-start mt-[5px]"
                    onClick={() => handleDeleteValor(variable.variableId, valor.valorId)}
                  >
                    <img src={DeleteIcon} className="rounded-lg w-10 h-10 mr-4" alt="" />
                  </button>
                )}
              </div>
            ))}
            {variable.valores.length < 20 && !ver && !editar && (
              <button
                className="flex-shrink-0 sm:flex-shrink h-12 w-[calc(100%)] sm:w-[calc(100%-112px)] bg-nimbusDark text-white rounded-lg flex items-center justify-center mt-3 ml-auto mr-4"
                onClick={() => handleAddValor(variable.variableId)}
              >
                <img src={AddIcon} className="mr-4 h-5" alt="" />
                Agregar valores
              </button>
            )}
          </div>
          {variables.length > 1 && !ver && !editar && (
            <button
              className="absolute top-2 right-2 lg:top-1/2 lg:-right-16"
              onClick={() => handleDeleteVariable(variable.variableId)}
            >
              <img src={DeleteIcon} className="rounded-lg w-8 h-8 lg:w-10 lg:h-10" alt="" />
            </button>
          )}
        </div>
      ))}
      {variables.length < 3 && !ver && !editar && (
        <button
          className="h-20 w-full text-nimbusDark text-lg bg-white rounded-lg border-2 flex items-center justify-center my-8"
          onClick={handleAddVariable}
        >
          <img src={AddBlueIcon} className="mr-4 h-5" alt="" />
          Agregar atributo
        </button>
      )}
    </div>
  );
};

export default VariablesProductoForm;
