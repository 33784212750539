import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import NimbusModal from "../../../../fragments/NimbusModal";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import InputMoneda from "../../../../fragments/Inputs/InputMoneda";
import useValidateForm from "../../../../hooks/useValidateForm";
import { UserContext } from "../../../../services/user";
import { getPorcentajeDeGananciaDistribucion } from "../../../../services/Api/MarketPlace/Distribuciones/PorcentajeDeGanancia";
import { getPorcentajeDeGananciaCorporativo } from "../../../../services/Api/MarketPlace/Corporativos/PorcentajeDeGanancia";
import { getPorcentajeDeGananciaPlantel } from "../../../../services/Api/MarketPlace/Planteles/PorcentajeDeGanancia";
import InputText from "../../../../fragments/Inputs/InputText";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../utils/alertsUtils";
import { putPrecioVentaProducto } from "../../../../services/Api/MarketPlace/Productos";
import { convertirMoneda, convertirMonedaOrigen } from "../../../../utils/conversionMonedasUtils";
import { formatCurrency } from "../../../../utils/productoUtils";

const GANANCIA_MODAL_MESSAGE = `La ganancia para este producto es menor o igual a 0.00 debido a que el precio de asignación es menor o igual al
costo, por lo tanto, le recomendamos revisar de nuevo. En caso de que así se haya requerido, hacer caso omiso y
continuar con el proceso.`;

const EditarPrecioVentaModal = ({ isOpen, setIsOpen, productoInfo, tipoDeMoneda, productoId, obtenerProductos, conversionMonedas }) => {
  const { _id, precioVenta, producto, origen, provieneDe, costo } = productoInfo;
  const { nombre, categoria, tipoProducto, estatus, configuracionProducto } = producto;
  const { user, activeRole } = useContext(UserContext);

  const ganancia = useMemo(() => {
    return precioVenta - convertirMoneda(costo, conversionMonedas);
  }, [precioVenta, costo]);

  const [nuevaGanancia, setNuevaGanancia] = useState(ganancia);
  const [gananciaBajaModal, setGananciaBajaModal] = useState(false);
  const [porcentajeDeGanancia, setPorcentajeDeGanancia] = useState("");

  const { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues } = useValidateForm({
    precioAsignacion: precioVenta,
  });

  const editarPrecioVentaSubmit = async () => {
    if (gananciaBajaModal) {
      try {
        let body = {
          _id: productoId,
          precioVenta: parseFloat(values.precioAsignacion),
        };
        await putPrecioVentaProducto(productoId, body);
        toast.success("Precio de venta editado correctamente.", alertConfiguration);
        obtenerProductos();
        setGananciaBajaModal(false);
        setIsOpen(false);
        setIsEnableSubmitButton(false);
      } catch (error) {
        console.log(error);
        setGananciaBajaModal(false);
        setIsEnableSubmitButton(false);
        setIsOpen(false);
      }
    } else {
      if (nuevaGanancia > 0) {
        try {
          let body = {
            _id: productoId,
            precioVenta: parseFloat(values.precioAsignacion),
          };
          await putPrecioVentaProducto(productoId, body);
          toast.success("Precio de venta editado correctamente.", alertConfiguration);
          obtenerProductos();
          setGananciaBajaModal(false);
          setIsOpen(false);
          setIsEnableSubmitButton(false);
        } catch (error) {
          console.log(error);
          setGananciaBajaModal(false);
          setIsEnableSubmitButton(false);
          setIsOpen(false);
        }
      } else {
        setGananciaBajaModal(true);
      }
    }
  };
  const [formStatus, handleSubmit] = useFormSubmit(editarPrecioVentaSubmit);

  const handleCloseModal = () => {
    if (gananciaBajaModal) setGananciaBajaModal(false);
    else {
      setValues({ precioAsignacion: costo });
      setIsEnableSubmitButton(false);
      setIsOpen(false);
    }
  };
  
  const obtenerPorcentajeDeGanancia = useCallback(async () => {
    let porcentajeDeGananciaResponse;
    try {
      switch (activeRole) {
        case "DISTRIBUIDOR":
          porcentajeDeGananciaResponse = await getPorcentajeDeGananciaDistribucion(user.distribucion._id);
          break;
        case "DIR_CORPORATIVO":
          porcentajeDeGananciaResponse = await getPorcentajeDeGananciaCorporativo(user.escuela._id);
          break;
        case "DIR_PLANTEL":
        case "ADMIN_OPERATIVO":
          porcentajeDeGananciaResponse = await getPorcentajeDeGananciaPlantel(user.escuela.campus._id);
          break;
        default:
          break;
      }
      setPorcentajeDeGanancia(porcentajeDeGananciaResponse.porcentajeDeGanancia);
    } catch (error) {
      console.log(error);
    }
  }, [isOpen]);

  useEffect(() => {
    let calcularNuevaGanancia = values.precioAsignacion - convertirMoneda(costo, conversionMonedas);
    if (!values.precioAsignacion) calcularNuevaGanancia = 0;
    setNuevaGanancia(calcularNuevaGanancia);
    if (values.precioAsignacion === "" || values.precioAsignacion === undefined || Object.values(errors).length > 0) setIsEnableSubmitButton(false);
    calcularNuevaGanancia = calcularNuevaGanancia.toFixed(2);
    calcularNuevaGanancia = calcularNuevaGanancia === "-0.00" ? 0.00 : parseFloat(calcularNuevaGanancia);
    setNuevaGanancia(calcularNuevaGanancia);
  }, [values]);

  useEffect(() => {
    obtenerPorcentajeDeGanancia();
  }, [obtenerPorcentajeDeGanancia]);

  return (
    <NimbusModal isOpen={isOpen}>
      <div className="max-h-[80vh] md:max-h-[75vh] overflow-y-auto w-full md:w-[28rem]">
        <h1 className="text-nimbusDark font-bold">
          {gananciaBajaModal ? "Ganancia demasiado baja" : "Editar precio de venta"}
        </h1>
        <p className={`text-center px-4 sm:px-2 ${gananciaBajaModal ? "text-inherit mx-auto" : "text-gray-400"}`}>
          {gananciaBajaModal ? GANANCIA_MODAL_MESSAGE : nombre}
        </p>
        <div className="lg:px-4 mb-6 flex flex-col gap-4">
          {gananciaBajaModal ? (
            <p className="text-nimbusDark font-bold text-center mt-4">¿Está seguro que desea continuar?</p>
          ) : (
            <>
              <InputText
                label={"Porcentaje de aumento de los artículos de línea (%)"}
                disabled
                disabledStyles
                value={porcentajeDeGanancia}
                styles="!w-full"
              />
              <InputMoneda
                label={"Costo"}
                disabled
                disabledStyles
                value={convertirMoneda(costo, conversionMonedas)}
                extraInfoLines={tipoDeMoneda}
                styles="!w-full"
              />
              <InputMoneda
                label={"Precio de venta sugerido"}
                disabled
                disabledStyles
                value={convertirMoneda((costo * porcentajeDeGanancia) / 100 + costo, conversionMonedas)}
                extraInfoLines={tipoDeMoneda}
                styles="!w-full"
              />
              <InputMoneda
                name={"precioAsignacion"}
                label={"Precio de venta establecido"}
                value={(values.precioAsignacion)}
                onChange={(e) => handleChange(e, "precioAsignacion")}
                error={errors["precioAsignacion"]}
                extraInfoLines={tipoDeMoneda}
                styles="!w-full"
                errorStyles="!text-left"
              />
              <InputMoneda
                label={"Ganancia"}
                styles={`!w-full ${
                  nuevaGanancia < 0 ? "text-red-600" : nuevaGanancia > 0 ? "text-green-600" : "text-inherit"
                }`}
                extraInfoLines={tipoDeMoneda}
                disabled
                disabledStyles
                value={nuevaGanancia}
              />
            </>
          )}
        </div>
        <div
          className={`flex justify-center flex-row-reverse gap-4 ${
            gananciaBajaModal && "flex-col items-center sm:items-stretch sm:flex-row"
          }`}
        >
          <SubmitButton
            text={gananciaBajaModal ? "Continuar con los precios ya establecidos" : "Guardar"}
            disabled={formStatus === "submitting" || !isEnableSubmitButton}
            onClick={handleSubmit}
            styles={`${gananciaBajaModal && "!w-64 p-4 !text-nimbusDark bg-divider"}`}
          />
          <CancelButton
            text={gananciaBajaModal ? "Cancelar y revisar de nuevo los precios" : "Cancelar"}
            onClick={handleCloseModal}
            styles={`${gananciaBajaModal && "!w-64 !p-4 !text-white !bg-nimbusDark"}`}
          />
        </div>
      </div>
    </NimbusModal>
  );
};

export default EditarPrecioVentaModal;
