import { createSelector } from "reselect";

const MARKETPLACE_MAP_INITIAL_VALUE = {
  ProductosDestacados: [],
  LibrosImpresos: [],
  LibrosDigitales: [],
  Uniformes: [],
  UtilesEscolares: [],
  Academias: [],
  Hardware: [],
  Plataformas: [],
  ServiciosEscolares: [],
  CursosYCapacitaciones: [],
  Eventos: [],
  Otro: [],
};

const selectMarketplaceReducer = (state) => state.marketplace;

export const selectMarketplace = createSelector([selectMarketplaceReducer], (marketplace) => marketplace.marketplace);

export const selectMarketplaceMap = createSelector([selectMarketplace], (marketplace) => {
  let marketplaceMap = JSON.parse(JSON.stringify(MARKETPLACE_MAP_INITIAL_VALUE));

  marketplaceMap = marketplace.productos.reduce((acc, producto) => {
    let categoriaKey = producto.producto.categoria
      .split(" ")
      .map((palabra) => palabra.substr(0, 1).toUpperCase().replace("Ú", "U") + palabra.substr(1))
      .join("");

    if (producto.producto.esDestacado) acc.ProductosDestacados.push(producto);

    acc[categoriaKey].push(producto);

    return acc;
  }, marketplaceMap);

  marketplaceMap = marketplace.paquetes.reduce((acc, paquete) => {
    if (paquete.paquete.esDestacado) acc.ProductosDestacados.push(paquete);
    return acc;
  }, marketplaceMap);

  marketplaceMap.Paquetes = marketplace.paquetes ?? [];

  return marketplaceMap;
});

export const selectMarketplaceIsLoading = createSelector(
  [selectMarketplaceReducer],
  (marketplace) => marketplace.isLoading
);

export const selectFavourites = createSelector([selectMarketplaceReducer], (marketplace) => marketplace.favourites);

export const selectFavouritesIds = createSelector([selectFavourites], (favoritos) => {
  // Obtener Ids donde la key es el _id del producto y su valor asociado con el _id de la tabla de favoritos en BD
  return favoritos.reduce((acc, favorito) => {
    acc[favorito.producto ? favorito.producto._id : favorito.paquete._id] = favorito._id;
    return acc;
  }, {});
});

export const selectFavouritesIsLoading = createSelector(
  [selectMarketplaceReducer],
  (marketplace) => marketplace.isLoadingFavourites
);

export const selectBuscar = createSelector([selectMarketplaceReducer], (marketplace) => marketplace.buscar);
