const obtenerUserEstructuraInfo = (user, activeRole) => {
  return user.editorial
    ? {
        estructura: user.editorial,
        tipoEstructura: "Casa editorial",
        tipoEstructuraRequest: "editorial",
        estructuraId: user.editorial._id,
        siguienteEstructura: "distribucion",
        anteriorEstructura: "editorial",
        paisEstructura: user.editorial.dirFiscalPais._id
      }
    : user.distribucion
    ? {
        estructura: user.distribucion,
        tipoEstructura: "Distribución",
        tipoEstructuraRequest: "distribucion",
        estructuraId: user.distribucion._id,
        siguienteEstructura: "corporativo",
        anteriorEstructura: "editorial",
        paisEstructura: user.distribucion.dirFiscalPais
      }
      : user.corporativo && activeRole === "DIR_CORPORATIVO"
      ? {
        estructura: user.corporativo,
        tipoEstructura: "Corporativo",
        tipoEstructuraRequest: "corporativo",
        estructuraId: user.corporativo._id,
        siguienteEstructura: "plantel",
        anteriorEstructura: "distribucion",
        paisEstructura: user.corporativo.dirFiscalPais
      }
    : user.escuela && activeRole === "DIR_CORPORATIVO"
    ? {
        estructura: user.escuela,
        tipoEstructura: "Corporativo",
        tipoEstructuraRequest: "corporativo",
        estructuraId: user.escuela._id,
        siguienteEstructura: "plantel",
        anteriorEstructura: "distribucion",
        paisEstructura: user.escuela.dirFiscalPais._id
      }
    : user.escuela.campus
    ? {
        estructura: user.escuela.campus,
        tipoEstructura: "Plantel",
        tipoEstructuraRequest: "plantel",
        estructuraId: user.escuela.campus._id,
        anteriorEstructura: "corporativo",
        paisEstructura: user.escuela.campus?.dirFiscalPais?._id
      }
    : {
        estructura: user.campus,
        tipoEstructura: "Plantel",
        tipoEstructuraRequest: "plantel",
        estructuraId: user.campus._id,
        anteriorEstructura: "corporativo",
        paisEstructura: user.campus.dirFiscalPais._id
      };
};

export { obtenerUserEstructuraInfo };
