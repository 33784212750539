import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentContainer from "../../../fragments/ContentContainer";
import LoadingScreen from "../../../fragments/LoadingScreen";
import SubHeaderProductoDetalle from "./SubHeaderProductoDetalle";
import ProductoInfoDetalle from "./ProductoInfoDetalle";
import { UserContext } from "../../../services/user";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { getProductoDetalle } from "../../../services/Api/MarketPlace/Productos";
import { getPaqueteMarketplaceDetalle } from "../../../services/Api/MarketPlace/Paquetes";
import { useDispatch, useSelector } from "react-redux";
import { selectFavouritesIds } from "../../../../reducers/marketplace/marketplaceSelector";
import { selectCarritoIsLoading } from "../../../../reducers/carrito/carritoSelector";
import { fetchShowBuscador, filtrarProductos } from "../../../../reducers/marketplace/marketplaceAction";

const ProductoDetalle = () => {
  const [loading, setLoading] = useState(true);
  const [producto, setProducto] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const favoritosId = useSelector(selectFavouritesIds)

  const obtenerProducto = useCallback(async () => {
    try {
      let productoResponse = {};
      if (params.tipoDeProducto === "producto") {
        productoResponse = await getProductoDetalle(params.articuloId);
      } else {
        productoResponse = await getPaqueteMarketplaceDetalle(params.articuloId);
      }
      setProducto(productoResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [params.articuloId]);

  const handleShowBuscador = () => {
    dispatch(fetchShowBuscador(false));
  }

  useEffect(() => {
    obtenerProducto();
    handleShowBuscador();
  }, [obtenerProducto]);

  useEffect(()=> { 
    return () => {
      dispatch(filtrarProductos(""))
    }
  },[])

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <ContentContainer styles="-mt-0">
              <div className="flex flex-col w-full">
                <SubHeaderProductoDetalle
                  nombreProducto={producto?.producto ? producto?.producto?.nombre : producto?.paquete?.nombre}
                />
                <ProductoInfoDetalle
                  productoInfo={producto}
                  esPaquete={producto.producto === undefined}
                  esFavorito={Object.keys(favoritosId).includes(producto._id)}
                  favoritoId={favoritosId[producto._id]}
                />
              </div>
            </ContentContainer>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductoDetalle;
