import { getListaDifusion } from "../../../services/Api/ListasDifusion";
import {
  ListaDifusionDetallesContextProvider,
  useListaDifusionDetalles,
} from "./ListaDifusionDetallesContext";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../services/user";
import alertConfiguration from "../../../utils/alertsUtils";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import ListaDifusionDescription from "./ListaDifusionDescription";
import ListaDifusionForm from "./ListaDifusionForm";
import ListaDifusionUsersAdded from "./ListaDifusionUsersAdded";
import LoadingScreen from "../../../fragments/LoadingScreen";
import SubHeaderListaDifusionDetalles from "./SubHeaderListaDifusionDetalles";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import useValidateForm from "../../../hooks/useValidateForm";
import useListAddEdit from "./useListAddEdit";

const INITIAL_VALUES_LISTA_DIFUSION = {
  nombreLista: "",
};

const ListaDeDifusionDetalles = ({ editar, ver }) => {
  return (
    <ListaDifusionDetallesContextProvider>
      <ListaDifusionDetallesComponents editar={editar} ver={ver} />
    </ListaDifusionDetallesContextProvider>
  );
};

const ListaDifusionDetallesComponents = ({ editar, ver }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, activeRole } = useContext(UserContext);
  const [loading, setLoading] = useState(ver || editar ? true : false);

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setValues,
    setIsEnableSubmitButton,
  } = useValidateForm(INITIAL_VALUES_LISTA_DIFUSION);

  const {
    setUsersAdded,
    setUsersAddedFiltered,
    usersAdded,
    setVer,
    setEditar,
  } = useListaDifusionDetalles();

  const { handleCreateList, handleUpdateList, isLoading } = useListAddEdit(
    user?._id
  );

  const getListaById = async () => {
    try {
      let listaDifusionRes = await getListaDifusion(params.listaDifusionID);

      // set default values
      setValues({
        nombreLista: listaDifusionRes.nombre,
      });
      setUsersAdded(listaDifusionRes?.destinatarios);
      setUsersAddedFiltered(listaDifusionRes?.destinatarios);
    } catch (error) {
      console.error(error);
      toast.error("Error al obtener la lista de difusión", alertConfiguration);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editar || ver) {
      getListaById();
      setVer(ver);
      setEditar(editar);
      setIsEnableSubmitButton(true);
    }
  }, [activeRole, params]);

  const handleSubmit = () => {
    let { nombreLista } = values;
    nombreLista = nombreLista.charAt(0).toUpperCase() + nombreLista.slice(1);
    nombreLista = nombreLista.trim();

    switch (editar) {
      case true:
        handleUpdateList(nombreLista, usersAdded, params.listaDifusionID);
        break;
      default:
        handleCreateList(nombreLista, usersAdded);
        break;
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
          <SubHeaderListaDifusionDetalles />
          <div className="flex flex-col justify-around px-4 lg:px-16 xl:px-32 mt-10 md:mt-0">
            <ListaDifusionDescription />
            <ListaDifusionForm
              lista={values}
              errors={errors}
              handleChange={handleChange}
              setFormador={setValues}
            />
            <ListaDifusionUsersAdded />
          </div>
          <div className="flex justify-center gap-3 mt-8">
            <CancelButton
              text={ver ? "Regresar" : "Cancelar"}
              styles="!w-[12rem]"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            />
            {!ver && (
              <SubmitButton
                text={"Guardar"}
                styles="!w-[12rem] disabled:bg-opacity-50"
                disabled={
                  !isEnableSubmitButton ||
                  usersAdded?.length < 1 ||
                  errors?.nombreLista !== undefined
                }
                loadingButton
                onClick={handleSubmit}
                loading={isLoading}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListaDeDifusionDetalles;
