import React from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";

const EliminarArticulosAsignadosModal = ({ isOpen, setIsOpen, removerArticulos }) => {
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Quitar artículos</h1>
      <p className="w-96 px-4 sm:px-2">
        Al quitar los artículos seleccionados, estos serán retirados de todas las estructuras inferiores en las cuáles
        estén asignados, así como de la tienda de los planteles y de los carritos de compra de los padres de familia en
        donde se encuentren.
      </p>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea quitar los artículos de esta estructura?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" onClick={removerArticulos} />
      </div>
    </NimbusModal>
  );
};

export default EliminarArticulosAsignadosModal;
