import React from "react";
import { replaceTextLinks } from "../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";

const Texto = ({ texto, _id, background }) => {
  return (
    <div className={`w-full rounded-lg ${background}`}>
      {texto !== "" && (
        <div
          className="p-2 justify-center visor"
          dangerouslySetInnerHTML={{ __html: replaceTextLinks(texto) }}
        ></div>
      )}
    </div>
  );
};

export default Texto;
