export const CHANGE_PAGE="CHANGE_PAGE";
export const INSERRT_WORD="INSERRT_WORD";
export const DEFINE_TYPE="DEFINE_TYPE";
export const SET_TYPE="SET_TYPE";
export const NOTIFICATION="NOTIFICATION";
export const BANDERACOMPRAS="BANDERACOMPRAS";
export const CONTADORCOMPRAS="CONTADORCOMPRAS";
export const CONTADORCOMPRAS2="CONTADORCOMPRAS2";
export const CLEANCONTADORCOM="CLEANCONTADORCOM";
export const CLEANCONTADORCOM2="CLEANCONTADORCOM2";
export const CONT="CONT";
export const FAVORITES="FAVORITES";
export const DELETE_FAV="DELETE_FAV";
export const COMPRADOS="COMPRADOS";
export const CLEANCOMPRAS="CLEANCOMPRAS";
export const TABLEMODIFY="TABLEMODIFY";
export const TABLEMODIFYA="TABLEMODIFYA";
export const TABLEMODIFYITEMS="TABLEMODIFYITEMS";

