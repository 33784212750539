import React from 'react';
import MisComprasRow from './MisComprasRow';

const MisComprasTable = ({misComprasList, tipoDeMoneda, abreviatura}) => {

  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th></th>
            <th>ID de <br/>la compra</th>
            <th>Fecha de<br/>compra</th>
            <th>
              <div className="flex gap-2 items-center justify-center">
                Total ({tipoDeMoneda[0]})
              </div>
            </th>
            <th>Método de pago</th>
            <th>Estatus de pago</th>
            <th>Estatus de entrega</th>
            <th className='w-[3.5rem]'></th>
          </tr>
        </thead>
        <tbody>
          {misComprasList.map((compra) => (
            <MisComprasRow key={compra._id} compraInfo={compra} abreviatura={abreviatura}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MisComprasTable;
