import { _delete, _get, _post, _put } from "../../http";

export const getAvisosRequest = (usuarioId) => {
  return _get(`/avisos/usuario/${usuarioId}`);
};

export const putVisibilidad = (avisoId, body) =>
  _put(`/avisos/${avisoId}/cambiarVisibilidad`, body);

export const deleteAviso = (avisoId) => _delete(`/avisos/${avisoId}`);

export const postAviso = (body) => _post(`/avisos`, body);

export const putAvisoDuplicado = (avisoId) =>
  _put(`/avisos/duplicarAviso`, avisoId);

export const getAviso = (avisoId) => _get(`/avisos/${avisoId}`);

export const updateAviso = (avisoId, body) => _put(`/avisos/${avisoId}`, body);
