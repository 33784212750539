import { useState } from 'react'
import cerrar from '../../../assets/icons/cerrar-circle.svg'
import IconCirculo from '../../../assets/icons/circulo.svg'
import pdfBackground from '../../../assets/icons/icons-bloques/pdf_bg.svg'
import pdfImage from '../../../assets/icons/icons-bloques/pdf_raw.png'
import { Trans } from 'react-i18next'
import PDFvalidado from '../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/PDFvalidado'

export default function Pdf({pdf, pieDeFoto}) {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [pdfFile, setPdfFile] = useState('')
    const [modalPdf, setModalPdf] = useState(null)
    const icons = {
        cerrar,
        circulo: IconCirculo
      }
      
    return(
        <div className="w-full py-2">
                <div className='grid justify-center w-full md:p-2 relative h-[20rem] rounded-2xl overflow-hidden mx-auto z-0'>
                    <button onClick={()=>setModalPdf(true)}  className='rounded-md py-3 px-5 bg-nimbus z-[+9] h-min flex absolute top-[15rem] left-1/2 transform translate-x-[-45%] md:top-1/2 md:translate-x-[75%]'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        <p className='text-white ml-1 whitespace-nowrap'>
                            <Trans i18nKey='pdfLabel'></Trans>
                        </p>
                    </button>
                <img src={pdfBackground} className='bg-[#aac9d8] w-full h-full absolute object-cover'/>
                <img src={pdfImage} className='left-1/2 transform -translate-x-1/2 w-[250px] md:left-0 md:translate-x-0 md:w-auto absolute'/>
                </div>
                {pieDeFoto!=='' && <div className='w-full mt-2 p-1' dangerouslySetInnerHTML={{__html:pieDeFoto}}></div>}
            
            {modalPdf ? <div
                    //className="fixed z-10 inset-0 overflow-y-auto h-3/4"
                    className="fixed z-[+999] inset-0 overflow-hidden h-[90vh]"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                    id="modal"
                >

                    <div className="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                            aria-hidden="true"
                        ></div>
                        <span
                            className="sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <div className="relative inline-block align-bottom bg-white rounded-xl px-4 pt-5 pb-10 text-left overflow-hidden
                        shadow-xl transform transition-all sm:my-8 sm:align-middle w-max-w-sm h-full w-[90vw] ">
                            <div className="flex justify-end items-center  md:top-8 right-1 md:right-1 sm:top- pr-4 ">
                                <p className='text-nimbus mr-0.5 font-bold font-sans'><Trans i18nKey='cerrarLabel'></Trans> PDF</p>
                                <button
                                    type="button"
                                    className="rounded-x focus:outline-none
                                     "
                                    onClick={() => setModalPdf(false)}
                                >
                                    <img src={icons.cerrar} alt='' />
                                </button>
                            </div>
                            <div className='grid justify-center w-full h-full  '>
                                <PDFvalidado
                                    className='h-full w-[90vw] hidden md:block'
                                    src={pdf}
                                />
                                <p className='block md:hidden mt-4'>Haz click <a href={pdf} target="_blank" className='text-[#00a6f0]' rel="noopener noreferrer">aquí</a> para ver el archivo</p>
                                {/* A usar después */}
                                {/* <object data={pdf} type="application/pdf" width="100%" height="100%" className='block md:hidden'>
                                    <p>Tu navegador no cuenta con un plugin para visualizar archivos pdf.En su lugar puedes <a href={pdf} target="_blank" className='text-[#00a6f0]' rel="noopener noreferrer">dar click aquí para ver el archivo</a></p>
                                </object> */}
                            </div>
                        </div>
                    </div>
                </div > : ''}
            
        </div>
    )
}