import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import SubHeaderPaqueteEscolar from "./fragments/SubHeaderPaqueteEscolar";
import MiPaqueteEscolarFilter from "./fragments/MiPaqueteEscolarFilter";
import MiPaqueteEscolarTable from "./fragments/MiPaqueteEscolarTable";
import { usePagination } from "../../hooks/usePagination";
import TablePagination from "../../fragments/TablePagination";
import { UserContext } from "../../services/user";
import { getAlumnosPaqueteEscolar } from "../../services/Api/MarketPlace/PaqueteEscolar";
import { getTutorDetalle } from "../../services/api";
import LoadingScreen from "../../fragments/LoadingScreen";
import NoDataMessage from "../../fragments/NoDataMessage";
import { MESSAGES } from "../../utils/messagesUtils";

const MiPaqueteEscolar = () => {
  const { user, activeRole } = useContext(UserContext);

  const [alumnos, setAlumnos] = useState([]);
  const [alumnosFiltro, setAlumnosFiltro] = useState([]);
  const [loading, setLoading] = useState(true);

  const obtenerAlumnos = useCallback(async () => {
    if (activeRole !== "") {
      try {
        let alumnosResponse = await getAlumnosPaqueteEscolar(user.escuela.campus.tutores[0]._id);
        alumnosResponse.map((alumno) => {
          alumno.articulosAsignados = alumno.articulosAsignados.filter(
            (articulosAsignado) =>
              Object.values(articulosAsignado.asignacionarticulogrupo.paquete).length > 1 ||
              Object.values(articulosAsignado.asignacionarticulogrupo.producto).length > 1
          );
          return alumno;
        });
        setAlumnos(alumnosResponse);
        setAlumnosFiltro(alumnosResponse);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  }, [activeRole]);

  useEffect(() => {
    obtenerAlumnos();
  }, [obtenerAlumnos]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(alumnosFiltro, 15);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderPaqueteEscolar />
            <div className={`${alumnos.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}>
              <div>
                <MiPaqueteEscolarFilter
                  alumnosList={alumnos}
                  alumnosFiltro={alumnosFiltro}
                  setAlumnosFiltro={setAlumnosFiltro}
                />
                <div>
                  <MiPaqueteEscolarTable alumnosList={currentData()} />
                  {alumnos.length !== 0 && alumnosFiltro.length === 0 && (
                    <NoDataMessage message={MESSAGES.MESSAGE_FILTER_ALUMNOS_TABLE} />
                  )}
                </div>
              </div>
              <div>
                {alumnos.length === 0 ? (
                  <NoDataMessage message={MESSAGES.MESSAGE_FILTER_ALUMNOS_TABLE} />
                ) : (
                  <TablePagination
                    next={next}
                    prev={prev}
                    jump={jump}
                    data={currentData()}
                    currentPage={currentPage}
                    maxPage={maxPage}
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default MiPaqueteEscolar;
