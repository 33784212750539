import React, { Fragment, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import SubHeaderCheckoutReturn from "./fragments/SubHeaderCheckoutReturn";
import BarraProgresoCompra from "../ProcesoDeCompra/BarraProgresoCompra";
import { getComprasSessionStatus } from "../../../services/Api/MarketPlace/Compras";
import Tarjeta from "../../../../assets/img/visa-mastercard-american-express.png";
import PagoExitoso from "../../../../assets/icons/pagoExitoso.svg";
import AdvertenciaPago from "../../../../assets/icons/advertenciaPago.svg";
import ResumenCompra from "./ResumenCompra";
import DatosEnvioCompra from "../ProcesoDeCompra/DatosEnvioCompra";
import { useDispatch } from "react-redux";
import { fetchShowBuscador } from "../../../../reducers/marketplace/marketplaceAction";

const CheckoutReturn = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [metodoPago, setMetodoPago] = useState("TARJETA_CREDITO_DEBITO");
  const [compra, setCompra] = useState({ datosEnvio: {}, compra: {}, sessionStatus: {} });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    dispatch(fetchShowBuscador(false));
    getComprasSessionStatus(sessionId).then((response) => {
      setStatus(response.session_status.status);
      setCompra({
        datosEnvio: response.compra.datosEnvio,
        compra: response.compra,
        sessionStatus: response.session_status,
      });
      setLoading(false);
    });
  }, []);

  return (
    <Fragment>
      {status !== "complete" || loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-8 min-h-[calc(100vh-13.85rem)] flex flex-col">
            <SubHeaderCheckoutReturn />
            <div className="px-4 md:px-6 lg:px-8">
              <BarraProgresoCompra estatus={"Compra exitosa"} />
              <div
                className={`flex flex-col gap-6 sm:flex-row justify-center sm:items-center p-6 mt-12 ${
                  metodoPago === "TARJETA_CREDITO_DEBITO" ? "bg-[#D7F9E8B2]/70" : "bg-[#EAE18F80]/50"
                }`}
              >
                <img
                  className="h-16"
                  src={metodoPago === "TARJETA_CREDITO_DEBITO" ? PagoExitoso : AdvertenciaPago}
                  alt=""
                />
                <div className="flex flex-col justify-center items-center w-full gap-3">
                  <p className="font-bold text-xl text-gray-500 text-center">
                    {metodoPago === "TARJETA_CREDITO_DEBITO"
                      ? "¡TU COMPRA SE HA REALIZADO DE MANERA EXITOSA!"
                      : "¡DEBES REALIZAR EL PAGO PARA COMPLETAR TU COMPRA DE MANERA EXITOSA!"}
                  </p>
                  <p className="font-bold text-gray-400 text-center">
                    {metodoPago === "TARJETA_CREDITO_DEBITO"
                      ? "Puedes consultar más detalles en la sección de Mis compras"
                      : "Ya puedes consultarla en la sección de “Mis compras”"}
                  </p>
                </div>
              </div>
              <div className={`flex flex-col xl:grid grid-cols-3 gap-6 mt-12`}>
                <div className="xl:col-span-2">
                  <DatosEnvioCompra estatus={"Compra exitosa"} datosEnvio={compra.datosEnvio} />
                  <div className="w-full flex flex-col mt-4">
                    <div className="h-12 bg-gray-100 px-4 flex items-center">
                      <p className="text-gray-500 font-bold">Método de pago</p>
                    </div>
                    <div className="flex flex-col px-4 pt-4 gap-6">
                      <div className="flex justify-between items-center">
                        <p>Tarjeta de crédito / Tarjeta de débito</p>
                        <img className="h-5 md:h-8" src={Tarjeta} alt="" />
                      </div>
                      <p>
                        ************{compra?.sessionStatus?.paymentIntent?.payment_method_details?.card?.last4}
                      </p>
                    </div>
                  </div>
                </div>
                <ResumenCompra compra={compra.compra} />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CheckoutReturn;
