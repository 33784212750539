import { AVISO_DATA_ACTION_TYPES } from "./avisoDataTypes";

export const AVISO_DATA_INITIAL_STATE = {
  nombre: "",
  categoria: "66997fe27858466e8c9d09af",
  descripcion: "",
  isUploadingFile: false,
  contenido: [],
  calendario: {
    selected: false,
    fechaInicio: null,
    fechaFin: null,
  },
  destinatarios: [],
  envio: {
    defaultOption: "Now",
    date: null,
    time: null,
  },
  saveProgress: false,
};

export const avisoDataReducer = (state = AVISO_DATA_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AVISO_DATA_ACTION_TYPES.SET_NOMBRE:
      return {
        ...state,
        nombre: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_CATEGORIA:
      return {
        ...state,
        categoria: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_DESCRIPCION:
      return {
        ...state,
        descripcion: payload,
      };
    case AVISO_DATA_ACTION_TYPES.ADD_CONTENIDO:
      return {
        ...state,
        contenido: [...state.contenido, payload],
      };
    case AVISO_DATA_ACTION_TYPES.SET_UPLOADING_FILE:
      return {
        ...state,
        isUploadingFile: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_BLOQUE_ID:
      return {
        ...state,
        contenido: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_CONTENIDO_PATH:
      return {
        ...state,
        contenido: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_CONTENIDO_TEXT:
      return {
        ...state,
        contenido: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_CONTENIDO_PIE:
      return {
        ...state,
        contenido: payload,
      };
    case AVISO_DATA_ACTION_TYPES.DELETE_CONTENIDO:
      return {
        ...state,
        contenido: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_CALENDARIO:
      return {
        ...state,
        calendario: {
          ...state.calendario,
          selected: payload,
        },
      };
    case AVISO_DATA_ACTION_TYPES.SET_FECHA_INICIO:
      return {
        ...state,
        calendario: {
          ...state.calendario,
          fechaInicio: payload,
        },
      };
    case AVISO_DATA_ACTION_TYPES.SET_FECHA_FIN:
      return {
        ...state,
        calendario: {
          ...state.calendario,
          fechaFin: payload,
        },
      };
    case AVISO_DATA_ACTION_TYPES.ADD_LIST:
      return {
        ...state,
        destinatarios: payload,
      };
    case AVISO_DATA_ACTION_TYPES.DELETE_LIST:
      return {
        ...state,
        destinatarios: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_ENVIO:
      return {
        ...state,
        envio: payload,
      };
    case AVISO_DATA_ACTION_TYPES.SET_EN_PROCESO:
      return {
        ...state,
        saveProgress: payload,
      };
    case AVISO_DATA_ACTION_TYPES.CLEAR_AVISO:
      return (state = payload ? payload : AVISO_DATA_INITIAL_STATE);
    default:
      return state;
  }
};
