import React, { useState } from "react";
import GeneralDropdown from "../../../fragments/Dropdowns/GeneralDropdown";
import IconSelect from "../../../../../src/assets/icons/tresPuntosVertical.svg";
import ConfirmationModal from "../../../fragments/ConfirmationModal";
import folder from "../../../../assets/img/folder.svg";
import ModalAgregarACarpeta from "../../../constructor/umas/fragments/ModalAgregarACarpeta";
import { checkboxCardStyles } from "../../../utils/layoutUtils";

function TareaCard({
  nombre,
  fechaCreacion,
  handleClick,
  handleDelete,
  tareaId,
  reload,
  isChecked,
  handleCheckboxChange,
}) {
  const [dropdownValue, setDropdownValue] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const toggleDropdown = () => setDropdownValue(!dropdownValue);
  const [agregarACarpeta, setAgregarACarpeta] = useState(false);

  const CardOptions = () => {
    return (
      <div className="bg-white p-2 rounded-md border border-[#dcdcdc] flex custom-dropdown-container z-10">
        <button
          onClick={() => setAgregarACarpeta(true)}
          title="Agregar a carpeta"
          className="dropdown-item"
        >
          <img className={"fondo-azul-folder w-6 mb-1"} src={folder} alt="" />
          <div className="ml-1 text-sm">Añadir a carpeta</div>
        </button>
        <div onClick={handleClick} className="dropdown-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-nimbus col-span-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div className="ml-1 text-sm">Editar</div>
        </div>
        <span
          //onClick={() => setDeleteConfirmation(true)}
          className="rounded-full w-7 h-7 py-1 cursor-pointer mr-4 dropdown-item"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="min-w-[24px] w-6 h-6 text-nimbus col-span-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            className="ml-1 text-sm"
            onClick={() => setDeleteConfirmation(true)}
          >
            Eliminar
          </div>
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="w-80 mb-4">
        <div className="h-40 rounded-lg p-2 relative pl-5 border border-[#ededed]">
          <input
            className={checkboxCardStyles}
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckboxChange(tareaId)}
          />
          <div className="absolute bg-gradient-to-b from-[#6f178d] to-[#e4baff] w-2 h-full left-0 top-0 rounded-l-lg"></div>
          <div className="col-span-2">
            <p
              className="text-nimbus font-bold truncate cursor-pointer mt-4"
              onClick={handleClick}
            >
              {nombre}
            </p>
            <div className="relative grid grid-cols-11 gap-1 items-end mt-[5rem]">
              <div className="col-span-11 flex justify-between">
                <p className="text-xs">{fechaCreacion}</p>
                <div className="w-6 h-6 relative">
                  <img
                    src={IconSelect}
                    className="cursor-pointer absolute top-0 left-0 right-0 bottom-0 z-[19]"
                    onClick={toggleDropdown}
                  />
                </div>
                <GeneralDropdown
                  open={dropdownValue}
                  children={<CardOptions />}
                  toggleDropdown={toggleDropdown}
                  dropdownStyles={"absolute bottom-0 right-[150px]"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={deleteConfirmation}
        setIsOpen={setDeleteConfirmation}
        handleAction={handleDelete}
        action="Eliminar"
        messages={["¿Está seguro que desea eliminar esta tarea?"]}
      />
      <ModalAgregarACarpeta
        isOpen={agregarACarpeta}
        setIsOpen={setAgregarACarpeta}
        isTarea
        idTarea={tareaId}
        reload={reload}
      />
    </>
  );
}

export default TareaCard;
