import { createContext, useContext, useState } from "react";

const initialContext = {
  destinatarios: [],
  setDestinatarios: () => {},
  destinatariosFiltro: [],
  setDestinatariosFiltro: () => {},
  userRol: "",
  setUserRol: () => {},
  selectUser: () => {},
  selectAllUsers: () => {},
};

export const AddUsersContext = createContext(initialContext);

export const AddUsersContextProvider = ({ children }) => {
  const [destinatarios, setDestinatarios] = useState([]);
  const [destinatariosFiltro, setDestinatariosFiltro] = useState([]);
  const [userRol, setUserRol] = useState("");

  const selectUser = (userToAdd) => {
    const newUsersList = destinatarios.map((user) => {
      if (user.usuario._id === userToAdd.usuario._id) {
        user.esSeleccionado = !user.esSeleccionado;
      }
      return user;
    });

    setDestinatarios(newUsersList);
  };

  const selectAllUsers = (checked, currentData) => {
    const usersSelectedIds = currentData?.map((user) => user.usuario._id);

    const newUsersList = destinatarios.map((user) =>
      usersSelectedIds.includes(user.usuario._id)
        ? { ...user, esSeleccionado: checked }
        : user
    );

    setDestinatarios(newUsersList);
  };

  return (
    <AddUsersContext.Provider
      value={{
        destinatarios,
        setDestinatarios,
        destinatariosFiltro,
        setDestinatariosFiltro,
        userRol,
        setUserRol,
        selectUser,
        selectAllUsers,
      }}
    >
      {children}
    </AddUsersContext.Provider>
  );
};

export const useAddUsers = () => {
  return useContext(AddUsersContext);
};
