import LoadingScreen from "../../../fragments/LoadingScreen";
import { useAddUsers } from "./AddUsersContext";
import AddUsersRow from "./AddUsersRow";
import React from "react";
import AddUsersSelectItems from "./AddUsersSelectItems";

const AddUsersTable = ({ currentUsers, isLoading }) => {
  const { userRol } = useAddUsers();

  const showAdditionalFields =
    userRol === "DIR_PLANTEL" ||
    userRol === "ADMIN_OPERATIVO" ||
    userRol === "PROFESOR";

  if (isLoading) return <LoadingScreen />;
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th>
              <AddUsersSelectItems currentUsers={currentUsers} />
            </th>
            <th className="text-center">Nombre del usuario</th>
            <th className="text-center">Correo electrónico</th>
            {showAdditionalFields ? (
              <>
                <th className="text-center">Nivel</th>
                <th className="text-center">Grado</th>
                <th className="text-center">Grupo</th>
              </>
            ) : (
              <th className="text-center">Estructura</th>
            )}
            <th className="text-center">Rol</th>
            <th className="text-center">Acciones</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <AddUsersRow
              key={user._id}
              user={user}
              additionalFields={showAdditionalFields}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddUsersTable;
