import { useContext } from "react";
import { ReactComponent as AddIcon } from "../../../../../../assets/icons/addLg.svg";
import { PaqueteProductosContext } from "../../../../../../contexts/PaqueteProductosContext";
import {
  agregarProducto,
  setIsAddProductsModalOpen,
} from "../../../../../../reducers/paquetesProductos/paquetesProductosAction";

const ProductosDisponiblesRow = ({ productoInfo, seleccionarProducto, setIsEnableSubmitButton}) => {
  const { producto, origen, esSeleccionado } = productoInfo;
  const { nombre, categoria, tipoProducto, estatus } = producto;

  const { productos, dispatch } = useContext(PaqueteProductosContext);

  const handleAgregarProducto = () => {
    dispatch(agregarProducto(productos, productoInfo));
    dispatch(setIsAddProductsModalOpen(false));
    setIsEnableSubmitButton(true)
  };

  const handleSeleccionarProducto = () => {
    seleccionarProducto(productoInfo);
  };

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-md border-r-0 text-center font-sans w-20">
        <input
          type="checkbox"
          name="selectProducto"
          id="selectProducto"
          className="rounded-md border-2 border-gray-300 cursor-pointer"
          checked={esSeleccionado ?? false}
          onChange={handleSeleccionarProducto}
        />
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans">
        <p>{nombre}</p>
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans">
        <p>{categoria === "otro" ? "Otros" : categoria}</p>
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans">
        <p>{tipoProducto}</p>
      </td>
      <td className="border-y-2 font-sans">
        <p>{origen}</p>
      </td>
      <td className="border-y-2 font-sans">
        <p>{estatus ? "Disponible" : "Agotado"}</p>
      </td>
      <td className="border-2 rounded-r-md border-l-0 pt-3 pb-1">
        <div className="flex justify-center  ml-[14px]">
          <AddIcon title="Agregar producto" className="cursor-pointer h-8 " onClick={handleAgregarProducto} />
        </div>
      </td>
    </tr>
  );
};

export default ProductosDisponiblesRow;
