import React, { Fragment } from "react";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import ResumenCompraTable from "./ResumenCompraTable";

const ResumenCompra = ({ compraInfo, detallesPais }) => {
  const { articulos, total, subTotal, snapshotArticulos } = compraInfo;
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="w-full bg-[#F5F5F5]">
        <h1 className="text-center text-[#9C9C9C] font-extrabold p-4 text-lg">Resumen de la compra</h1>
      </div>
      <div className="bg-[#fff] gap-4">
        <ResumenCompraTable articulosList={snapshotArticulos ? snapshotArticulos.articulos : articulos} total={total} subTotal={subTotal} detallesPais={detallesPais}/>
      </div>
      <div className="flex justify-center mt-6">
        <CancelButton text="Cerrar" bgColor="bg-divider" onClick={() => navigate(-1)} styles="w-[10rem]" />
      </div>
    </Fragment>
  );
};

export default ResumenCompra;
