import ImagenValidada from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";

const Imagen = ({ imagen, pieDeFoto, medidas, tipo }) => {
  return (
    <div className="w-full grid justify-items-center">
      <div className="my-10 mx-auto w-full">
        <ImagenValidada
          src={imagen}
          className={`rounded-xl w-full mx-auto ${
            tipo === "CORTADA"
              ? "aspect-video object-cover"
              : tipo === "COMPLETA"
              ? "aspect-video object-contain"
              : tipo === "RELLENAR"
              ? "aspect-video object-fill"
              : ""
          }`}
        />
        {pieDeFoto !== "" && (
          <div
            className="w-full mt-2 p-1"
            dangerouslySetInnerHTML={{ __html: pieDeFoto }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Imagen;
