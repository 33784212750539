export default function SaveButton({ handleClick }) {
  return (
    <button
      id="saveBtn"
      className="flex justify-center bg-nimbusDark font-bold p-2 text-white cursor-pointer rounded"
      onClick={handleClick}
    >
      <p>Guardar dirección</p>
    </button>
  );
}
