import { createContext, useContext, useEffect, useState } from "react";
import { usePagination } from "../../hooks/usePagination";

const initialContext = {
  avisos: [],
  setAvisos: () => {},
  avisosFiltro: [],
  setAvisosFiltro: () => {},
  selectAviso: () => {},
  selectAllAvisos: () => {},
  next: () => {},
  prev: () => {},
  jump: () => {},
  currentData: () => {},
  currentPage: 1,
  maxPage: 0,
};

export const GestorAvisosContext = createContext(initialContext);

export const GestorAvisosContextProvider = ({ children }) => {
  const [avisos, setAvisos] = useState([]);
  const [avisosFiltro, setAvisosFiltro] = useState([]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    avisosFiltro,
    10
  );

  const selectAviso = (avisoSelected) => {
    const newAvisoList = avisos?.map((aviso) => {
      if (aviso._id === avisoSelected._id) {
        aviso.esSeleccionado = !aviso.esSeleccionado;
      }
      return aviso;
    });

    setAvisos(newAvisoList);
    setAvisosFiltro(newAvisoList);
  };

  const selectAllAvisos = (checked, data) => {
    const avisosSelectedIDs = data?.map((aviso) => aviso._id);

    const newAvisosList = avisos?.map((aviso) =>
      avisosSelectedIDs.includes(aviso._id)
        ? { ...aviso, esSeleccionado: checked }
        : aviso
    );

    setAvisos(newAvisosList);
    setAvisosFiltro(newAvisosList);
  };

  return (
    <GestorAvisosContext.Provider
      value={{
        avisos,
        setAvisos,
        avisosFiltro,
        setAvisosFiltro,
        selectAviso,
        selectAllAvisos,
        next,
        prev,
        jump,
        currentData,
        currentPage,
        maxPage,
      }}
    >
      {children}
    </GestorAvisosContext.Provider>
  );
};

export const useGestorAvisos = () => {
  return useContext(GestorAvisosContext);
};
