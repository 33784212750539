import { _delete, _get, _post, _put } from "../../http";

export const getReportesAvisos = (
  userID,
  page,
  busqueda,
  categoria,
  fechaEnvioInicial,
  fechaEnvioFinal
) => {
  let url = `/avisos/reportes/usuario/${userID}?pagina=${page}&limite=10`;

  if (busqueda) url += `&titulo=${busqueda}`;
  if (categoria) url += `&categoria=${categoria}`;
  if (fechaEnvioInicial && !fechaEnvioFinal)
    url += `&fechaEnvio=${fechaEnvioInicial}`;
  if (fechaEnvioInicial && fechaEnvioFinal)
    url += `&fechaEnvioInicial=${fechaEnvioInicial}&fechaEnvioFinal=${fechaEnvioFinal}`;

  return _get(url);
};

export const getReporteAvisos = (avisoID) => {
  return _get(`/avisos/${avisoID}/reportes`);
};
