import {ReactComponent as BuscarIcon} from '../../../assets/icons/buscar.svg'

export default function InputBuscar({onChange, name, id, styles="", placeholder="Buscar...", ocupaValue=false, value=""}) {
    return(
        <div className={`${styles} flex rounded-md bg-gray-200 pl-2 items-center gap-x-2`}>
            <BuscarIcon/>
            {ocupaValue 
            ? (
                <input
                    type="text"
                    name={name}
                    id={id}
                    onChange={onChange}
                    className='focus:outline-none border-none bg-inherit w-full rounded-r-md focus:rounded-none'
                    placeholder={placeholder}
                    value={value}
                />
            ) : (
                <input
                    type="text"
                    name={name}
                    id={id}
                    onChange={onChange}
                    className='focus:outline-none border-none bg-inherit w-full rounded-r-md focus:rounded-none'
                    placeholder={placeholder}
                />
            )}
        </div>
    )
}