import React from "react";
import Transferencia from "../../../../../assets/img/spei.png";
import Advertencia from "../../../../../assets/icons/advertenciaTransferencia.svg";

export const TransferenciaPagoExitoso = () => {
  return (
    <div className="flex flex-col px-4 pt-4 gap-6">
      <div className="flex justify-between items-center">
        <p>Transferencia interbancaria</p>
        <img className="h-5 md:h-8" src={Transferencia} alt="" />
      </div>
      <div className="flex flex-col items-center justify-evenly h-36 bg-white border-2 rounded-lg">
        <p>CLABE INTERBANCARIA</p>
        <p className="font-bold text-2xl">123456789876543212</p>
      </div>
      <div className="flex gap-2">
        <img src={Advertencia} alt="" />
        <p>Esta referencia será valida en un período no máximo a 48 horas </p>
      </div>
    </div>
  );
};
