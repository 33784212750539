import React from 'react';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';
import AddButton from '../../../fragments/Buttons/AddButton';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../fragments/SubHeader';

const SubHeaderPaises = ({ agregarPais }) => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-wrap align-middle justify-between bg-white'>
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={agregarPais ? 'Inicio /' : 'Configuración del marketplace /'} onClick={() => navigate(agregarPais ? `/home` : `/marketplace/configuracion`)} />
          <BreadCrumb route={agregarPais ? 'Países' : 'Monedas y países'} active />
        </BreadCrumbContainer>
      </SubHeader>
      {agregarPais && (
        <div className='py-5 px-4 sm:px-8'>
          <AddButton
            text='Agregar país'
            onClick={() => navigate('/paises/agregarPais')}
          />
        </div>
      )}
    </div>
  );
};

export default SubHeaderPaises;
