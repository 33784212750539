import { useState } from "react";

import { v4 as uuidv4 } from "uuid";

function useCarrusel(initialValuesCarrusel = []) {
  const [carrusel, setCarrusel] = useState(initialValuesCarrusel);

  const handleAgregarFotoCarrusel = () => {
    const fotoId = uuidv4();
    let nuevaFoto = {
      _id: fotoId,
      imagen: "",
    };

    let nuevasFotos = [...carrusel, nuevaFoto];

    setCarrusel(nuevasFotos);
  };

  const handleEliminarFotoCarrusel = (fotoAEliminarId) => {
    let nuevasFotos = carrusel.filter((foto) => foto._id !== fotoAEliminarId);
    setCarrusel(nuevasFotos);
  };

  const validarCarruselVacio = () => {
    return carrusel.some((foto) => foto.imagen === "");
  };

  const handleActualizarFotoCarrusel = (event, fotoAModificarId) => {
    let fotoValue = event.target.files[0];
    let nuevasFotos = carrusel.map((foto) => (foto._id === fotoAModificarId ? { ...foto, imagen: fotoValue } : foto));
    setCarrusel(nuevasFotos);
  };

  const handleEliminarFoto = (fotoAModificarId) => {
    let nuevasFotos = carrusel.map((foto) => (foto._id === fotoAModificarId ? { ...foto, imagen: "" } : foto));
    setCarrusel(nuevasFotos);
  };

  const handleSubirFotosCarrusel = async (uploadFile) => {
    let fotos = [];
    fotos = await Promise.all(carrusel.map(async (foto) => await uploadFile(foto.imagen)));
    return fotos;
  };

  const carruselMetodos = {
    handleAgregarFotoCarrusel,
    handleEliminarFoto,
    validarCarruselVacio,
    handleActualizarFotoCarrusel,
    handleEliminarFotoCarrusel,
    handleSubirFotosCarrusel,
  };

  return {
    carruselMetodos,
    carrusel,
    setCarrusel,
  };
}

export default useCarrusel;
