import { _get } from "../../../http";

// módulos del marketplace
export const getModulosMarketplaceEditorial = (editorialId) => _get(`/market-place/obtenerModulosConfigurados/editorial/${editorialId}`);

export const getModulosMarketplaceDistribucion = (distribucionId) => _get(`/market-place/obtenerModulosConfigurados/distribucion/${distribucionId}`);

export const getModulosMarketplaceCorporativo = (corporativoId) => _get(`/market-place/obtenerModulosConfigurados/corporativo/${corporativoId}`);

export const getModulosMarketplacePlantel = (plantelId) => _get(`/market-place/obtenerModulosConfigurados/plantel/${plantelId}`);

export const getModulosMarketplaceAdmin = () => _get("/market-place/obtenerModulosConfigurados/admin/admin");