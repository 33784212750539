import React, { useContext } from "react";
import { ReactComponent as EmpresaList } from "../../../../assets/icons/empresaList.svg";
import { ReactComponent as TiendaAgregar } from "../../../../assets/icons/tiendaAgregar.svg";
import { useNavigate } from "react-router-dom";

const AsignarArticulosRow = ({ estructuraInfo, userRol }) => {
  const navigate = useNavigate();
  const { _id, nombre, dirFiscalPais, nivelAcademico, gradosAcademicos } = estructuraInfo;

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-lg border-r-0 font-sans">
        <EmpresaList className="mx-auto" />
      </td>
      <td className="border-y-2 font-sans text-left">{nombre}</td>
      <td className="border-y-2 font-sans text-left">
        {userRol === "DIR_PLANTEL" ? nivelAcademico.nombre : dirFiscalPais ? dirFiscalPais.nombre : "México"}
      </td>
      {userRol === "DIR_PLANTEL" && <td className="border-y-2 font-sans text-left">{gradosAcademicos[0].nombre}</td>}
      <td className="text-right border-2 rounded-r-md border-l-0 min-h-[52px]">
        <TiendaAgregar
          className="mx-auto cursor-pointer"
          onClick={() => 
            navigate(`/marketplace/asignarArticulos/${_id}`, { state: { nombreEstructura: nombre } })
          }
        />
      </td>
    </tr>
  );
};

export default AsignarArticulosRow;
