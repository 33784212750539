import { Route, Routes } from "react-router-dom";
import GestorAvisosDetalles from "../../../components/Herramientas/GestorAvisos/gestor-avisos-detalles/GestorAvisosDetalles";
import GestorAvisosHome from "../../../components/Herramientas/GestorAvisos/GestorAvisosHome";

const GestorAvisosRoutes = () => {
  return (
    <Routes>
      <Route index element={<GestorAvisosHome />} />
      <Route path="agregar-aviso" element={<GestorAvisosDetalles />} />
      <Route path=":avisoID/ver" element={<GestorAvisosDetalles ver />} />
      <Route path=":avisoID/editar" element={<GestorAvisosDetalles editar />} />
    </Routes>
  );
};

export default GestorAvisosRoutes;
