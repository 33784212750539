import { _delete, _get, _post, _put } from "../../http";

//bloqueos en evidencias
export const exceptuarAlumnoBloqueoGeneral = (evidenciaIndividualId, body) => _put(`/calificacionEvidencias/${evidenciaIndividualId}/exceptuarBloqueoGeneral`,body);

export const guardarCambiosAlumno = (evidenciaId, body) => _post(`/calificacionEvidencias/${evidenciaId}/guardarCambios`,body);

export const postCalificacion = (evidenciaId, body) => _post(`/calificacionEvidencias/${evidenciaId}/calificacionRubrica`,body);

export const removerCalificacion = (body) => _post(`/calificacionEvidencias/removerCalificacion`,body);

export const cancelarEnvio = (body) => _put(`/calificacionEvidencias/cancelarEnvioTarea`,body);

export const manejarBloqueoAlumnos = (body) => _put(`calificacionEvidencias/bloquear`,body);

export const reiniciarRegistros = (id, body) => _put(`/restablecerEvidencia/${id}`, body);