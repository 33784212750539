import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useOutsideClick from "../../../../../utils/OutsideClick";
import ImagenValidada from "../RecursosValidados/ImagenValidada";

function Lightbox({ lightboxValue, closeLightbox }) {
  const ref = useRef();

  const handleLightbox = () => {
    closeLightbox();
  };

  useOutsideClick(ref, () => {
    if (lightboxValue) handleLightbox();
  });

  return lightboxValue ? (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-[#ffffffe0] z-[+9999] flex items-center justify-center">
      <div
        className="max-w-[80%] max-h-[80%] relative flex justify-center items-center mx-auto w-auto h-auto md:min-w-[25rem]"
        ref={ref}
      >
        <FontAwesomeIcon
          onClick={handleLightbox}
          icon={faTimes}
          className="cursor-pointer absolute top-2 right-2 bg-black w-6 h-6 rounded text-white text-xl transition-all hover:scale-110"
        ></FontAwesomeIcon>
        <ImagenValidada
          src={lightboxValue}
          className="w-full h-fit rounded-lg shadow-lg bg-white border max-h-[30rem] pointer-events-none"
        />
      </div>
    </div>
  ) : null;
}

export default Lightbox;
