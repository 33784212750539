import { PACKAGE_PRODUCTS_ACTION_TYPES } from "./paquetesProductosTypes";

export const packageProductsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS:
      return {
        ...state,
        productos: payload,
      };
    case PACKAGE_PRODUCTS_ACTION_TYPES.SET_PACKAGE_PRODUCTS_ERRORS:
      return {
        ...state,
        errores: payload,
      };
    case PACKAGE_PRODUCTS_ACTION_TYPES.SET_IS_ADD_PRODUCTS_OPEN:
      return {
        ...state,
        isAddProductsOpen: payload,
      };
    case PACKAGE_PRODUCTS_ACTION_TYPES.SET_FILTERS_PRODUCTS:
      return {
        ...state,
        filtrosProductos: payload,
      };
    case PACKAGE_PRODUCTS_ACTION_TYPES.SET_FILTERS_PACKAGES:
      return {
        ...state,
        filtrosPaquetes: payload,
      };
    default:
      return state;
  }
};
