import React from 'react'

function CustomPoly({onClick, points, encontrado}){

  return (
    <div className='w-full h-full absolute top-0 left-0 right-0 bottom-0 pointer-events-none'>
        <div classname="relative">
            <svg width='100%' height='100%' viewBox={"0 0 100 1000"}>
                <polygon 
                    onClick={onClick}
                    points={points} 
                    vectorEffect={'non-scalling-stroke'}
                    className={`stroke-1 md:stroke-[.5px] pointer-events-auto transition ${encontrado ? 'stroke-green-500 fill-[#00800038]' : "fill-transparent stroke-transparent"} ` }
                    stroke-dasharray="1,1"
                    strokeWidth='2'
                />
            </svg>
        </div>
    </div>
  )
}

export default CustomPoly