import React from 'react';
import FacturacionRow from './FacturacionRow';

const FacturacionTable = ({ facturacionList }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th className='w-[7rem]'></th>
            <th>Id de la compra</th>
            <th>Estatus de facturación</th>
            <th className='w-[3.5rem]'></th>
          </tr>
        </thead>
        <tbody>
          {facturacionList.map((factura) => (
            <FacturacionRow key={factura._id} facturacionInfo={factura} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FacturacionTable;
