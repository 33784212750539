import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useOutsideClick from "../../../../../../src/components/utils/OutsideClick";
import ImagenValidada from "../ActividadesUtils/RecursosValidados/ImagenValidada";

function Modal({ modalValue, closeModal }) {
  const ref = useRef();

  const handleLightbox = () => {
    closeModal();
  };

  useOutsideClick(ref, () => {
    if (modalValue) handleLightbox();
  });

  return modalValue ? (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-[#ffffffe0] z-[+9999] flex items-center justify-center">
      <div
        className="max-w-[10rem] min-w-[25rem] max-h-[90vh] overflow-auto relative flex-col flex items-center mx-auto w-auto h-auto bg-white rounded-lg border p-10"
        ref={ref}
      >
        <FontAwesomeIcon
          onClick={handleLightbox}
          icon={faTimes}
          className="cursor-pointer absolute top-2 right-2 bg-black w-6 h-6 rounded text-white text-xl transition-all hover:scale-110"
        ></FontAwesomeIcon>
        {modalValue.imagen && (
          <ImagenValidada
            src={modalValue.imagen}
            alt=""
            className="w-full h-fit rounded-lg shadow-lg bg-white border max-h-[30rem] pointer-events-none"
          />
        )}
        {modalValue.texto && (
          <div className="text-nimbusLight mt-4 font-bold">
            {typeof modalValue?.orden === "number" && (
              <div className="inline-block font-bold text-lg">
                {modalValue?.orden + 1}:{" "}
              </div>
            )}
            {modalValue.texto}
          </div>
        )}
      </div>
    </div>
  ) : null;
}

export default Modal;
