import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import MisComprasTable from "./fragments/MisComprasTable";
import SubHeaderMisCompras from "./fragments/SubHeaderMisCompras";
import MisComprasFilter from "./fragments/MisComprasFilter";
import TablePagination from "../../fragments/TablePagination";
import { usePagination } from "../../hooks/usePagination";
import NoDataMessage from "../../fragments/NoDataMessage";
import { MESSAGES } from "../../utils/messagesUtils";
import withUserAccesability from "../../hoc/withUserAccesability";
import { UserContext } from "../../services/user";
import { Navigate, useNavigate } from "react-router-dom";
import { getCompras } from "../../services/Api/MarketPlace/Compras";
import LoadingScreen from "../../fragments/LoadingScreen";
import { toast } from "react-toastify";
import { obtenerUserEstructuraInfo } from "../../utils/userUtils";
import { getPaises } from "../../services/api";

const MisCompras = ({ isUserAllowed }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [compras, setCompras] = useState([]);
  const [comprasFiltro, setComprasFiltro] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"]);
  const [abreviatura, setAbreviatura] = useState("")

  useEffect(() => {
    if(user.activeRole !== ""){
      if (!isUserAllowed) {
        navigate("/NotFound");
      }
    }
  }, [user.activeRole]);
  
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(comprasFiltro, 20);

  const obtenerCompras = useCallback(async () => {
    if (user.activeRole !== "") {
      try {
        let comprasResponse = await getCompras(user.user._id);
        comprasResponse = comprasResponse.filter((compra) => compra.checkoutSession);
        comprasResponse.sort((compraA, compraB) => compraB.fechaCreacion.localeCompare(compraA.fechaCreacion))
        setCompras(comprasResponse);
        setComprasFiltro(comprasResponse);
        setLoading(false)

        const { estructura } = obtenerUserEstructuraInfo(user.user, user.activeRole);
        const paises = await getPaises();
        if (estructura.dirFiscalPais) {
          const simbolo =
            estructura.dirFiscalPais.nombre === "Estados Unidos"
              ? ["USD"]
              : estructura.dirFiscalPais.simbolo
              ? [estructura.dirFiscalPais.simbolo]
              : [paises.data.filter((pais) => pais._id === estructura.dirFiscalPais)[0]?.simbolo];

          const abreviaturaPais =  paises.data.filter((pais) => pais._id === estructura.dirFiscalPais._id)[0].abreviatura
          setTipoDeMoneda(simbolo);
          setAbreviatura(abreviaturaPais);
        }
    
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error("Ha ocurrido un error")
      }
    }
  }, [user.activeRole]);

  useEffect(() => {
    obtenerCompras();
  }, [obtenerCompras]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderMisCompras />
            <div className={`${compras.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}>
              <div>
                <MisComprasFilter compras={compras} comprasFiltro={comprasFiltro} setComprasFiltro={setComprasFiltro} />
                <MisComprasTable misComprasList={currentData()} tipoDeMoneda={tipoDeMoneda} abreviatura={abreviatura}/>
                {compras.length !== 0 && comprasFiltro.length === 0 && (
                  <NoDataMessage message={MESSAGES.MESSAGES_SIN_COMPRAS_TABLE} />
                )}
              </div>
              <div>
                {compras.length === 0 ? (
                  <NoDataMessage message={MESSAGES.MESSAGES_MIS_COMPRAS_TABLE} />
                ) : (
                  <TablePagination
                    next={next}
                    prev={prev}
                    jump={jump}
                    data={currentData()}
                    currentPage={currentPage}
                    maxPage={maxPage}
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withUserAccesability(MisCompras);
