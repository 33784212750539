import React, { useContext, createContext, useState } from "react"

import * as axios from "axios"
import { logIn } from "./api"


const authContext = createContext()

export function useAuth(){
    return useContext(authContext)
}

export function ProvideAuth({children}){
    const auth = useProvideAuth()
    return(
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

function useProvideAuth(){
    const [login,setLogin] = useState(null)
    const [cred,setCred] = useState(false)

    const signIn = async (email,password, rememberMe) => {
        var data = {correoElectronico:email, contrasenia:password}

        let response = await logIn(data)
        if(response.status===200){
            setCred('')
            if(rememberMe){
                window.localStorage.setItem('token',response.data.token)
            }else{
                window.sessionStorage.setItem('token',response.data.token)
            } 
            window.localStorage.setItem('uid',response.data.id)
            setLogin(true)
            return response.data
        }else{
            setCred(response.error.message)
            return response.error
        }
        
    }

    const signOut = () => {
        window.localStorage.clear()
        window.sessionStorage.clear()
        setLogin(false)
    }

    return{
        cred,
        setCred,
        login,
        signIn,
        signOut
    }
}