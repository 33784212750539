import { useRef, useState } from "react";
import { useGestorAvisosDetalles } from "../GestorAvisosDetallesContext";
import ReactLoading from "react-loading";
import editListIcon from "../../../../../assets/icons/editListIcon.svg";
import IconImagen from "../../../../../assets/icons/icons-bloques/image_default.png";
import { uploadFileHandler } from "../../../../../reducers/avisoData/avisoDataAction";
import { useDispatch, useSelector } from "react-redux";
import { selectAvisoData } from "../../../../../reducers/avisoData/avisoDataSelector";

function ImagenComponent({ pieDeFoto, setCkEditorTable, contenidoId, path }) {
  const [imagen, setImagen] = useState(path ?? null);
  const { editandoTexto, ver } = useGestorAvisosDetalles();
  const dispatch = useDispatch();
  const { contenido, isUploadingFile: loading } = useSelector(selectAvisoData);
  const fileRef = useRef();

  return (
    <div
      className={`hover:border-[#c6c6c6] transition-all rounded-md pl-5 pb-5 pr-5 p-2 mb-4 border ${
        ver ? "cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <div className="flex justify-end pb-2">
        <div className="border rounded-l-md rounded-b-md bg-nimbus text-white px-2">
          Imagen
        </div>
      </div>
      <div className="flex justify-center w-full max-w-[1280px] mx-auto">
        <button
          className={`relative bloque-imagen w-full h-full ${
            ver ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => fileRef.current.click()}
          disabled={ver}
        >
          <input
            type="file"
            ref={fileRef}
            onChange={(e) =>
              dispatch(
                uploadFileHandler(
                  e.target.files[0],
                  setImagen,
                  contenido,
                  contenidoId,
                  "imagen",
                  imagen
                )
              )
            }
            hidden
            disabled={editandoTexto || ver}
            accept=".jpeg, .png, .jpg"
          />
          {loading && (
            <div className="w-full h-full absolute bg-[#fff] flex items-center justify-center z-10">
              <ReactLoading
                type="spin"
                color="#1A3D82"
                height={"5%"}
                width={"5%"}
              />
            </div>
          )}
          <img
            className={`h-full w-full rounded-lg !relative aspect-video object-contain `}
            src={imagen ? imagen : IconImagen}
            alt=""
          />
        </button>
      </div>
      <div className="w-full max-w-[960px] mx-auto">
        {pieDeFoto === "" || pieDeFoto === undefined ? (
          <div
            className={`text-[#697D99] cursor-pointer flex justify-center items-center ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
            onClick={() => {
              if (!editandoTexto && !ver) setCkEditorTable(false);
            }}
          >
            <img src={editListIcon} alt="" />
            <span>Editar pie</span>
          </div>
        ) : (
          <div
            onClick={() => {
              if (!editandoTexto && !ver) setCkEditorTable(false);
            }}
            className={`text-[#697D99] cursor-pointer items-center mt-2 flex justify-center ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: pieDeFoto }}
          />
        )}
      </div>
    </div>
  );
}

export default ImagenComponent;
