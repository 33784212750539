import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { getDatosDeEnvioPlantel } from "../../../services/Api/MarketPlace/Planteles/DatosEnvio";
import LoadingScreen from "../../../fragments/LoadingScreen";
import { UserContext } from "../../../services/user";

const DatosEnvioCompra = ({ estatus, datosEnvio }) => {
  const [loading, setLoading] = useState(false);

  const { nombreDireccion, ubicacion, telefono, referencias } = datosEnvio;

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="w-full flex flex-col">
          <div className="h-12 bg-gray-100 px-4 flex items-center">
            <p className="text-gray-500 font-bold">Datos de envío</p>
          </div>
          <div className="flex flex-col px-4 pt-4">
            {estatus === "DatosEnvio" && (
              <p className="text-gray-400 font-bold mb-4">
                Sus artículos llegarán a la siguiente dirección en los próximos 10 días hábiles
              </p>
            )}
            <p className="text-gray-400">
              {estatus === "DatosEnvio" && <b className="text-black font-normal mr-2">Nombre de la dirección:</b>}{" "}
              {nombreDireccion}
            </p>
            <p className="text-gray-400">
              {estatus === "DatosEnvio" && <b className="text-black font-normal mr-2">Dirección:</b>}
              {ubicacion?.direccion}
            </p>
            <p className="text-gray-400">
              {estatus === "DatosEnvio" && <b className="text-black font-normal mr-2">Teléfono de contacto:</b>}{" "}
              {telefono}
            </p>
            <p className="text-gray-400">
              {estatus === "DatosEnvio" && <b className="text-black font-normal mr-2">Otras referencias:</b>}
              {referencias}
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DatosEnvioCompra;
