import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../assets/icons/closeModal.svg";
import ExpandIcon from "../../../../../../assets/img/pantalla-completa.png";

function FullScreen({ children, text, defaultStyles, fullScreenStyles }) {
  const [isFull, setIsFull] = useState(false);

  const switchScreenMode = () => setIsFull(!isFull);

  return (
    <>
      {text && (
        <div className=" pt-4 pl-1 bg-white mt-6 block-instructions mb-3">
          {text}
        </div>
      )}
      <div className={isFull ? fullScreenStyles : defaultStyles}>
        {isFull ? (
          <CloseIcon
            className="z-[+99999] float-right mr-8 mt-8 absolute right-0 bg-white rounded-md cursor-pointer hover:scale-110 w-10 h-10 p-2"
            onClick={switchScreenMode}
          />
        ) : (
          <img
            src={ExpandIcon}
            onClick={switchScreenMode}
            className="absolute bottom-4 right-4 z-[1] cursor-pointer hover:scale-[1.10] bg-white rounded-md p-[6px] w-[35px] h-[35px]"
          />
        )}
        {children}
      </div>
    </>
  );
}

FullScreen.defaultProps = {
  defaultStyles: "h-[15rem] md:h-[600px] rounded-md mb-8 relative",
  fullScreenStyles:
    "fixed top-0 left-0 right-0 bottom-0 w-full h-full z-[+99999]",
};

export default FullScreen;
