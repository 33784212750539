export const ESTATUS = [
  {
    _id: 'FACTURADA',
    nombre: 'Facturada',
  },
  {
    _id: 'POR_FACTURAR',
    nombre: 'Por facturar',
  },
];

export const USO_FACTURA = [
  {
    _id: 'Gastos_en_general',
    nombre: 'Gastos en general',
  },
];

export const REGIMEN_FISCAL = [
  {
    _id: 'Persona_fisica',
    nombre: 'Persona física',
  },
  {
    _id: 'Persona_moral',
    nombre: 'Persona moral',
  },
];
