const CheckedIcon = ({ isRead }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill={isRead ? "#28A745" : "#BBBBBB"} />
      <path
        d="M14.2004 18.2925C14.0188 18.2944 13.8422 18.2334 13.7004 18.12L11.3229 16.22C11.1588 16.0874 11.0541 15.895 11.0318 15.6852C11.0095 15.4754 11.0715 15.2654 11.2041 15.1013C11.3367 14.9372 11.5291 14.8325 11.7389 14.8102C11.9487 14.7879 12.1588 14.8499 12.3229 14.9825L14.1404 16.435L18.5004 11.9475C18.6514 11.8243 18.8426 11.7612 19.0373 11.7701C19.232 11.7789 19.4166 11.8593 19.5558 11.9957C19.6951 12.1321 19.7791 12.3151 19.7919 12.5096C19.8048 12.7041 19.7455 12.8965 19.6254 13.05L14.7679 18.05C14.6944 18.1264 14.6063 18.1873 14.5088 18.229C14.4113 18.2706 14.3064 18.2922 14.2004 18.2925Z"
        fill="white"
      />
      <path
        d="M14.9997 24.6325C13.0946 24.6325 11.2322 24.0676 9.64816 23.0091C8.06411 21.9507 6.82948 20.4463 6.10042 18.6862C5.37136 16.9261 5.18061 14.9893 5.55228 13.1208C5.92395 11.2523 6.84136 9.53593 8.18849 8.18881C9.53561 6.84168 11.252 5.92427 13.1205 5.5526C14.989 5.18093 16.9258 5.37168 18.6859 6.10074C20.446 6.8298 21.9504 8.06443 23.0088 9.64848C24.0673 11.2325 24.6322 13.0949 24.6322 15C24.6289 17.5537 23.613 20.0018 21.8072 21.8076C20.0015 23.6133 17.5534 24.6292 14.9997 24.6325ZM14.9997 6.95001C13.4076 6.95001 11.8512 7.42214 10.5274 8.30668C9.20354 9.19123 8.17175 10.4485 7.56246 11.9194C6.95318 13.3904 6.79376 15.0089 7.10437 16.5705C7.41498 18.132 8.18167 19.5664 9.30748 20.6922C10.4333 21.818 11.8677 22.5847 13.4292 22.8953C14.9908 23.2059 16.6093 23.0465 18.0803 22.4372C19.5512 21.828 20.8085 20.7962 21.693 19.4724C22.5776 18.1485 23.0497 16.5922 23.0497 15C23.047 12.8658 22.1981 10.8198 20.689 9.31072C19.1799 7.80163 17.1339 6.95266 14.9997 6.95001Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedIcon;
