import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../../fragments/SubHeader";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../fragments/BreadCrumb";

import { ReactComponent as Add } from "../../../../assets/icons/add.svg";
import { UserContext } from "../../../services/user";

const SubHeaderPaquetesProductos = () => {
  const navigate = useNavigate();

  const { permissions } = useContext(UserContext);

  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Gestión de artículos /"} onClick={() => navigate(`/marketplace/gestionProductos`)} />
        <BreadCrumb route={"Paquetes"} active />
      </BreadCrumbContainer>
      {permissions.paquetesproductosPermits?.crear && (
        <div
          className="flex px-2 gap-x-2 items-center justify-between bg-nimbusDark text-white p-2 rounded cursor-pointer w-fit self-end sm:self-center mt-3 sm:mt-0"
          onClick={() => navigate(`/marketplace/gestionProductos/paquetes/agregar`)}
        >
          <Add />
          <p>Crear paquete</p>
        </div>
      )}
    </SubHeader>
  );
};

export default SubHeaderPaquetesProductos;
