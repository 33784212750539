import React from "react";
import { Trans } from "react-i18next";

function ConfirmModal({ isOpen, message, onConfirm, onReject }) {
  return isOpen ? (
    <div className="z-[+999] bg-[#85858573] w-full h-full top-0 right-0 bottom-0 left-0 fixed flex items-center justify-center">
      <div className="bg-white w-full max-w-[30rem] p-4 md:p-8 rounded-lg">
        <div className="text-center text-nimbusLight mb-6 font-bold">
          <Trans i18nKey={message}></Trans>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-nimbus px-4 py-2 rounded-lg text-white mx-2"
            onClick={onConfirm}
          >
            Ok
          </button>
          <button
            className="bg-[#f5f5f5] px-4 py-2 rounded-lg mx-2 border text-nimbusLight"
            onClick={onReject}
          >
            <Trans i18nKey={'Cerrar'}></Trans>
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default ConfirmModal;
