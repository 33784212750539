import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/Edit.svg";
import InputSelect from "../../../../fragments/Inputs/InputSelect";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import InputNumber from "../../../../fragments/Inputs/InputNumber";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../utils/alertsUtils";
import {
  postVariacionProducto,
  putVariacionProducto,
} from "../../../../services/Api/MarketPlace/Productos/Variaciones";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import EliminarVariacionModal from "../../Paquetes/Paquete/fragments/EliminarVariacionModal";

const VariacionesRow = ({
  variables,
  variacionInfo,
  variacionIndex,
  handleDeleteVariacion,
  handleActualizarValorVariable,
  ver,
  obtenerVariaciones,
}) => {
  const { _id, variacionId, seleccionValores } = variacionInfo;
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [habilitarEditar, setHabilitarEditar] = useState(_id ? false : true);
  const [seleccionValoresTemp, setSeleccionValoresTemp] = useState(seleccionValores);
  const [eliminarVariacionModal, setEliminarVariacionModal] = useState(false);
  const params = useParams();

  const handleActualizarValores = (e, variableId) => {
    let nuevoValorId = e.target.value;
    let nuevoValorNombre = variables
      .find((variable) => variable._id === variableId)
      .valores.find((valor) => valor._id === nuevoValorId).valor;

    let nuevosValores = seleccionValoresTemp.map((variacionSeleccionada) =>
      variacionSeleccionada.variable._id === variableId
        ? {
            ...variacionSeleccionada,
            valor: {
              ...variacionSeleccionada.valor,
              _id: nuevoValorId,
              valor: nuevoValorNombre,
            },
          }
        : variacionSeleccionada
    );
    setSeleccionValoresTemp(nuevosValores);
  };

  const variacionSubmit = async () => {
    try {
      let body = {};
      if (_id) {
        body = {
          _id: _id,
          seleccionValores: seleccionValoresTemp,
        };
        await putVariacionProducto(params.productoId, _id, body);
        toast.success("Variación actualizada correctamente", alertConfiguration);
        obtenerVariaciones();
      } else {
        body = seleccionValoresTemp;
        await postVariacionProducto(params.productoId, body);
        toast.success("Variación creada correctamente", alertConfiguration);
        obtenerVariaciones(variacionId);
      }
      setHabilitarEditar(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        toast.error("La variación ya se encuentra registrada", alertConfiguration);
      } else {
        toast.error("Ocurrión un error", alertConfiguration);
      }
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(variacionSubmit);

  useEffect(() => {
    let camposCompletados = seleccionValoresTemp.every((valoresSeleccionado) => {
      return valoresSeleccionado.valor._id !== "";
    });

    if (!camposCompletados) setHabilitarBoton(false);
  }, [seleccionValoresTemp]);

  return (
    <>
      {habilitarEditar ? (
        <tr className="p-2 text-left bg-white">
          <td className="border-2 rounded-l-md rounded-r-md text-left font-sans p-4" colSpan={2 + variables.length}>
            <div className="flex flex-col">
              <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
                {variables.map((variable, idx) => (
                  <InputSelect
                    key={variable._id}
                    name={variable.variable}
                    label={variable.variable}
                    placeholder={`el/la ${variable.variable.toLowerCase()}`}
                    styles="!w-full"
                    options={variable.valores}
                    disableFirstOption
                    value={seleccionValoresTemp[idx].valor._id}
                    onChange={(e) => {
                      handleActualizarValores(e, variable._id);
                      setHabilitarBoton(true);
                    }}
                  />
                ))}
                {/* <InputNumber
                  label={"Cantidad en stock"}
                  name={"stock"}
                  placeholder={"Ingrese un valor"}
                  styles="!w-full"
                  value={0}
                  disabled
                /> */}
              </div>
              <div className="flex justify-center gap-x-4 mt-6">
                <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                  <CancelButton
                    text={"Cancelar"}
                    bgColor="bg-divider"
                    onClick={
                      _id
                        ? () => {
                            setHabilitarEditar(false);
                            setSeleccionValoresTemp(seleccionValores);
                            setHabilitarBoton(false);
                          }
                        : () => handleDeleteVariacion(variacionId)
                    }
                  />
                  <SubmitButton
                    text="Guardar"
                    onClick={handleSubmit}
                    disabled={formStatus === "submitting" || !habilitarBoton}
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <tr className="p-2 text-left bg-white">
          <td className="border-2 rounded-l-md border-r-0 text-left font-sans pl-4">
            <p className="text-black font-bold">Variación {variacionIndex + 1}</p>
          </td>
          {variables.map((variable, idx) => (
            <td key={variable._id} className="border-y-2 font-sans">
              <span className="flex gap-2">
                <p className="text-black font-bold">{variable.variable}:</p>
                <p>{seleccionValoresTemp[idx].valor.valor}</p>
              </span>
            </td>
          ))}
          <td className="border-2 rounded-r-md border-l-0 flex justify-evenly items-center py-6 md:py-3 space-x-2 min-h-[52px]">
            {!ver && (
              <>
                <EditIcon
                  title="Editar variación"
                  className="cursor-pointer"
                  onClick={() => setHabilitarEditar(true)}
                />
                <DeleteIcon
                  title="Eliminar variación"
                  className="cursor-pointer"
                  onClick={() => setEliminarVariacionModal(true)}
                />
              </>
            )}
          </td>
        </tr>
      )}
      <EliminarVariacionModal
        isOpen={eliminarVariacionModal}
        setIsOpen={setEliminarVariacionModal}
        variacionId={_id}
        obtenerVariaciones={obtenerVariaciones}
      />
    </>
  );
};

export default VariacionesRow;
