import React from 'react';
import NimbusModal from '../../../fragments/NimbusModal';
import CancelButton from '../../../fragments/Buttons/CancelButton';
import SubmitButton from '../../../fragments/Buttons/SubmitButton';

const ProductoModalEditar = ({ isOpen, handleModal, handleSubmit }) => {
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className='text-nimbusDark font-bold'>Editar producto</h1>
      <p className='w-96 px-4 sm:px-2'>
        Al guardar los cambios, estos se verán reflejados en todas las
        estructuras y pedidos donde este artículo está incluido. Si desea
        continuar, recomendamos notificar de esta actualización a los
        involucrados.
      </p>
      <div className='w-96 px-4 text-center mb-6'>
        <p className='text-nimbusDark font-bold'>
          ¿Está seguro que desea continuar?
        </p>
      </div>
      <div className='flex justify-center gap-x-4'>
        <CancelButton
          text='Cancelar'
          bgColor='bg-divider'
          onClick={handleModal}
        />
        <SubmitButton text='Aceptar' onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default ProductoModalEditar;
