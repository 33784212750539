import React, {useEffect} from 'react';

const HtmlObject = ({
    dataRoute,
    path_origin,
    id_interactivo
}) => {
    let id_ruffle = "refle_" + id_interactivo;
    //let interactivo = path_origin + "/interactivos/" + dataRoute;
    let interactivo = path_origin + dataRoute;
    let flash_vars = ''
    flash_vars = "enlace=" + encodeURI("/" + "#!titulo/" ) + "&";
    flash_vars += "resolucionX=" + 700 + "&";
    flash_vars += "resolucionY=" + 400 + "&";
    flash_vars += "rutaServerAdmin=" + "";



    useEffect(()=>{
          var swfobject = {};
          swfobject.embedSWF = function(url, cont){
              var ruffle = window.RufflePlayer.newest(),
                  player = Object.assign(document.getElementById(cont).appendChild(ruffle.createPlayer()), {
                      style: 'width: 100%; height: 100%',
                  });
                const pathArr = interactivo.split('/')
                pathArr.pop()
              player.load({ 
                  url: url,
                  parameters: flash_vars,
                  wmode: "transparent",
                  quality: "high",
                  base:pathArr.join('/')
              })
              .then((res)=>{
                  console.log("success", res)
              })
              .catch((err)=>{
                  console.log('error', err)
              });
          }
          swfobject.embedSWF(interactivo, id_ruffle);
          //swfobject.embedSWF("https://storage.googleapis.com/nimbus-interactivos/K018/K018.swf", id_ruffle);
    },[])
 
  return (
    <>
      <div className='w-full h-full aspect-video z-10 relative' id={id_ruffle}></div>
    </>
  )
};

export default HtmlObject;