import { _get, _post, _delete, _put } from "../../../http";

//Endpoints para la gestión de paquetes

export const getPaquetesMarketplaceEstructura = (tipoDeEstructura, estructuraId) =>
  _get(`/paquetesMP/${tipoDeEstructura}/${estructuraId}`);

export const getPaqueteMarketplaceDetalle = (paqueteId) => _get(`/paquetesMP/${paqueteId}`);

export const postPaqueteMarketplace = (body) => _post("/paquetesMP", body);

export const putPaqueteMarketplace = (paqueteId, body) => _put(`/paquetesMP/${paqueteId}`, body);

export const putPrecioVentaPaquete = (paqueteId, body) => _put(`/paquetesMP/${paqueteId}/actualizarPrecioVenta`, body);

export const deletePaqueteMarketplace = (paqueteId) => _delete(`/paquetesMP/${paqueteId}`);
