import React from "react";
import SubHeader from "../../fragments/SubHeader";
import BreadCrumb from "../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../fragments/BreadCrumbContainer";
import { useNavigate } from "react-router-dom";

const SubHeaderMisFavoritos = () => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} />
        <BreadCrumb route={"Mis favoritos"} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderMisFavoritos;
