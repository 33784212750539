import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFechaEnvio } from "../../../../../reducers/avisoData/avisoDataAction";
import { selectSendDate } from "../../../../../reducers/avisoData/avisoDataSelector";
import moment from "moment-timezone";
import { useGestorAvisosDetalles } from "../GestorAvisosDetallesContext";

const ScheduleForm = ({ horario }) => {
  const { defaultOption, date, time } = useSelector(selectSendDate);
  const [schedule, setSchedule] = useState(defaultOption);
  const [sendDate, setSendDate] = useState(date);
  const [sendHour, setSendHour] = useState(time);
  const [dateFieldType, setDateFieldType] = useState("text");
  const [hourFieldType, setHourFieldType] = useState("text");
  const dispatch = useDispatch();
  const { ver } = useGestorAvisosDetalles();

  const currentDate = moment().tz(horario?.value);
  const MIN_DATE = moment(currentDate).format("YYYY-MM-DD");
  const MIN_HOUR = moment(currentDate).format("HH:mm");

  return (
    <div className="flex flex-col mt-8 gap-4">
      <p className="font-semibold text-center md:text-left text-base text-black">
        Fecha de envio:
      </p>
      <div className="flex flex-col md:flex-row items-center gap-10">
        <div className="flex gap-2">
          <input
            type="radio"
            name="sendNow"
            className="w-6 h-6 text-nimbusDark focus:ring-nimbusDark dark:focus:ring-nimbusDark cursor-pointer disabled:cursor-not-allowed"
            checked={schedule === "Now"}
            value={"Now"}
            onChange={(e) => {
              setSchedule(e.target.value);
              dispatch(setFechaEnvio());
              setSendDate("");
              setSendHour("");
            }}
            disabled={ver}
          />
          <label
            htmlFor="sendNow"
            className="font-semibold text-base text-[#0D0C22]"
          >
            Ahora
          </label>
        </div>
        <div className="flex gap-2">
          <input
            type="radio"
            name="sendLater"
            className="w-6 h-6 text-nimbusDark focus:ring-nimbusDark dark:focus:ring-nimbusDark cursor-pointer disabled:cursor-not-allowed"
            value={"Later"}
            checked={schedule === "Later"}
            onChange={(e) => {
              setSchedule(e.target.value);
              dispatch(setFechaEnvio("Later"));
            }}
            disabled={ver}
          />
          <label
            htmlFor="sendLater"
            className="font-semibold text-base text-[#0D0C22]"
          >
            Programar envío
          </label>
        </div>
      </div>
      {schedule === "Later" && (
        <div className="grid grid-cols-12 gap-4 mt-6">
          <div className="grid col-span-6">
            <label
              htmlFor="fechaEnvio"
              className="font-semibold text-base text-[#0D0C22] text-left"
            >
              Fecha de envío
            </label>
            <input
              type={dateFieldType}
              name={"fechaEnvio"}
              id={"fechaEnvio"}
              placeholder="Seleccione la fecha de envío"
              onFocus={() => setDateFieldType("date")}
              onBlur={() => setDateFieldType("text")}
              value={date ?? ""}
              min={MIN_DATE}
              required
              onChange={(e) => {
                setSendDate(e.target.value);
                dispatch(setFechaEnvio("Later", e.target.value, sendHour));
              }}
              className={
                "rounded-lg p-2 w-full border-divider border text-black/60 disabled:cursor-not-allowed"
              }
              disabled={ver}
            />
          </div>
          <div className="grid col-span-6">
            <label
              htmlFor="horaEnvio"
              className="font-semibold text-base text-[#0D0C22] text-left"
            >
              Hora de envío
            </label>
            <input
              type={hourFieldType}
              name={"horaEnvio"}
              id={"horaEnvio"}
              placeholder="Seleccione la hora de envío"
              onFocus={() => setHourFieldType("time")}
              onBlur={() => setHourFieldType("text")}
              value={sendHour}
              min={MIN_HOUR}
              onChange={(e) => {
                setSendHour(e.target.value);
                dispatch(setFechaEnvio("Later", sendDate, e.target.value));
              }}
              className={
                "rounded-lg p-2 w-full border-divider border text-black/60 disabled:cursor-not-allowed"
              }
              disabled={ver}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleForm;
