import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import ContentContainer from "../../../fragments/ContentContainer";
import LoadingScreen from "../../../fragments/LoadingScreen";
import SubHeaderProductoForm from "./SubHeaderProductoForm";
import ProductoInfoForm from "./ProductoInfoForm";
import ProductoEspecificacionesForm from "./ProductoEspecificacionesForm";
import VariablesProductoForm from "./VariablesProductoForm";
import useValidateForm from "../../../hooks/useValidateForm";
import useFormSubmit from "../../../hooks/useFormSubmit";
import useProductoForm from "../../../hooks/useProductoForm";
import { errorToast } from "../../../constructor/umas/ActividadesUtils/FormularioUtils";
import useUploadImage from "../../../hooks/useUploadImage";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { UserContext } from "../../../services/user";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { getProductoDetalle, postProducto, putProducto } from "../../../services/Api/MarketPlace/Productos";
import ProductoMenu from "./fragments/ProductoMenu";
import { getPaises } from "../../../services/api";
import ProductoModalEditar from "./ProductoModalEditar";

const NOT_REQUIRED_FIELDS_PRODUCTO = [
  "imagen",
  "estatus",
  "_id",
  "notificar",
  "correoNotificacion",
  "esDestacado",
  "configuracion",
];

const INITIAL_VALUES_PRODUCTO = {
  nombreProducto: "",
  imagen: "",
  categoria: "",
  nombreCategoria: "",
  estatus: true,
  claveArticulo:"",
  claveUnidad: "",
  impuestos: true,
  porcentajeImpuestos: 16,
  marca: "",
  tipoProducto: "",
  precioVenta: "",
  nivelAcademico: "637ffd677e7f9619cc2849f5",
  gradoAcademico: "",
  notificar: true,
  correoNotificacion: "",
  esDestacado: false,
  configuracionProducto: "PRODUCTO_SIMPLE",
  descripcion: "",
  caracteristicas: "",
};

const ProductoForm = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user, activeRole } = useContext(UserContext);
  const params = useParams();

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    areRequiredFieldsFulfilled,
  } = useValidateForm(INITIAL_VALUES_PRODUCTO);

  const {
    variablesMetodos,
    variables,
    setVariables,
    carrusel,
    setCarrusel,
    carruselMetodos,
    errorsVariables,
    checkErrorsVariables,
  } = useProductoForm();

  const [formStatus, handleSubmit] = useFormSubmit(productoSubmit);

  const { verifyFiles, deleteImage, uploadFile } = useUploadImage(values, setValues);
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"]);
  const [menu, setMenu] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleEliminarImagen = () => {
    setIsEnableSubmitButton(true);
    deleteImage();
  };

  const handleModalEditar = () => {
    setOpenModal(!openModal);
  };

  const obtenerProducto = useCallback(async () => {
    if (activeRole !== "") {
      const { estructura } = obtenerUserEstructuraInfo(user, activeRole);
      const paises = await getPaises();
      if (estructura.dirFiscalPais) {
        const simbolo =
          estructura.dirFiscalPais.nombre === "Estados Unidos"
            ? ["USD"]
            : estructura.dirFiscalPais.simbolo
            ? [estructura.dirFiscalPais.simbolo]
            : [paises.data.filter((pais) => pais._id === estructura.dirFiscalPais)[0]?.simbolo];
        setTipoDeMoneda(simbolo);
      }
      if (props.editar || props.ver) {
        try {
          let productoResponse = await getProductoDetalle(params.productoId);
          delete productoResponse.editorial;
          delete productoResponse.distribucion;
          delete productoResponse.corporativo;
          delete productoResponse.plantel;
          productoResponse.producto.nombreProducto = productoResponse.producto.nombre;
          productoResponse.producto.nivelAcademico = productoResponse.producto.nivelAcademico._id;
          productoResponse.producto.gradoAcademico = productoResponse.producto.gradoAcademico._id;
          productoResponse.producto.carrusel = productoResponse.producto.carrusel.map((foto) => ({
            _id: foto,
            imagen: foto,
          }));

          let productoValues = {
            ...productoResponse,
            ...productoResponse.producto,
          };
          delete productoValues.producto;
          let variablesValues = [];
          variablesValues = productoValues.variables.map((variable) => {
            variable.variableId = variable._id;
            variable.valores = variable.valores.map((valor) => ({ ...valor, valorId: valor._id, nombre: valor.valor }));
            return variable;
          });
          setCarrusel(productoResponse.producto.carrusel);
          setValues(productoValues);
          setVariables(variablesValues);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else {
        setLoading(false);
      }
    }
  }, [activeRole]);

  async function productoSubmit() {
    try {
      if (carruselMetodos.validarCarruselVacio()) {
        errorToast("Asegúrese de tener todas las fotos con una imagen.");
        setErrors(errors);
      } else {
        if (Object.values(errors).length === 0 && !checkErrorsVariables()) {
          await verifyFiles(values, setValues);
          let nuevoCarrusel = await carruselMetodos.handleSubirFotosCarrusel(uploadFile);
          let { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
          let productoBody = {
            ...values,
            nombre: values.nombreProducto,
            carrusel: nuevoCarrusel,
            nivelAcademico: {
              _id: values.nivelAcademico,
            },
            gradoAcademico: {
              _id: values.gradoAcademico,
            },
          };
          if (variables.length !== 0) {
            productoBody.variables = await variablesMetodos.handleSubirFotosVariables(uploadFile);
          } else productoBody.variables = [];

          if (!productoBody.notificar) delete productoBody.correoNotificacion;
          if (productoBody.categoria !== "otro") delete productoBody.nombreCategoria;

          let body = {
            producto: productoBody,
            impuestos: productoBody.impuestos,
            porcentajeImpuestos: parseFloat(productoBody.porcentajeImpuestos),
            precioVenta: parseFloat(productoBody.precioVenta),
            cantidad: 0,
            costo: 0,
            origen: "Nuevo",
            [tipoEstructuraRequest]: {
              _id: estructuraId,
            },
          };

          if (props.editar) {
            await putProducto(params.productoId, body);
            toast.success("El producto se ha actualizado exitosamente.", alertConfiguration);
          } else {
            await postProducto(body);
            toast.success("El producto se ha registrado exitosamente.", alertConfiguration);
          }
          navigate(-1);
        } else {
          setErrors(errors);
          errorToast("Complete todos los campos requeridos.");
        }
      }
    } catch (error) {
      toast.error("Ha ocurrido un error.", alertConfiguration);
      console.log(error);
    }
  }
  useEffect(() => {
    let enableButton = false;
    if (values.categoria === "otro") {
      enableButton =
        areRequiredFieldsFulfilled(NOT_REQUIRED_FIELDS_PRODUCTO) &&
        variablesMetodos.areVariablesRequiredFieldsFulfilled();
    } else {
      enableButton =
        areRequiredFieldsFulfilled([...NOT_REQUIRED_FIELDS_PRODUCTO, "nombreCategoria"]) &&
        variablesMetodos.areVariablesRequiredFieldsFulfilled();
    }
    if (values.categoria !== "otro" && values.tipoProducto !== "" && !props.editar) setIsEnableSubmitButton(true);
    if (!enableButton) setIsEnableSubmitButton(false);
  }, [values, variables, carrusel]);

  useEffect(() => {
    obtenerProducto();
  }, [obtenerProducto]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <ProductoModalEditar isOpen={openModal} handleModal={handleModalEditar} handleSubmit={handleSubmit}/>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderProductoForm editar={props.editar} ver={props.ver} />
            <ContentContainer>
              <div className="flex flex-col w-full space-y-6">
                {values.configuracionProducto === "PRODUCTO_VARIABLE" && (
                  <ProductoMenu menu={menu} setMenu={setMenu} />
                )}
                <div className={`${menu === 0 ? "block" : "hidden"}`}>
                  <ProductoInfoForm
                    producto={values}
                    errors={errors}
                    handleChange={handleChange}
                    setProducto={setValues}
                    ver={props.ver}
                    editar={props.editar}
                    deleteImage={handleEliminarImagen}
                    variables={variables}
                    setVariables={setVariables}
                    tipoDeMoneda={tipoDeMoneda}
                    carrusel={carrusel}
                  />
                  <ProductoEspecificacionesForm
                    producto={values}
                    carrusel={carrusel}
                    errors={errors}
                    carruselMetodos={carruselMetodos}
                    handleChange={handleChange}
                    setIsEnableSubmitButton={setIsEnableSubmitButton}
                    ver={props.ver}
                  />
                </div>
                <div className={`${menu === 1 ? "block" : "hidden"}`}>
                  <VariablesProductoForm
                    producto={values}
                    variables={variables}
                    handleChange={handleChange}
                    variablesMetodos={variablesMetodos}
                    setIsEnableSubmitButton={setIsEnableSubmitButton}
                    ver={props.ver}
                    editar={props.editar}
                    extensions={".jpg, .png"}
                    errors={errorsVariables}
                  />
                </div>
              </div>
            </ContentContainer>
            <div className="flex justify-center gap-x-4 mt-6">
              <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                <CancelButton
                  text={`${props.ver ? "Regresar" : "Cancelar"}`}
                  bgColor="bg-divider"
                  onClick={() => navigate(-1)}
                />
                {!props.ver && (
                  <SubmitButton
                    text="Guardar"
                    disabled={!isEnableSubmitButton}
                    onClick={props.editar ? handleModalEditar : handleSubmit}
                    loadingButton
                    loading={formStatus === "submitting"}
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductoForm;
