import generator from "crossword-layout-generator";
import { isOdd, isEven } from "./ActividadesUtils/ActividadesUtils";
import CrucigramaBody from "./CrucigramaBody";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";

function Crucigrama({
  inputList,
  evaluable,
  umaBloque,
  guardarCalificacion,
  instrucciones,
}) {
  const [crossword, setCrossword] = useState([]);

  useEffect(() => {
    const evenItems = inputList
      .filter((item, index) => {
        return isEven(index);
      })
      .map((el, i) => ({ ...el, orden: i }));

    const oddItems = inputList
      .filter((item, index) => {
        return isOdd(index);
      })
      .map((el, i) => ({ ...el, orden: i }));

    const crucigramaData = evenItems
      .map((el, i) => ({
        answer: el.texto,
        clue: oddItems[i].texto ? oddItems[i].texto : " ",
      }))
      .filter((el) => el.answer && el.clue)
      .map((el) => ({
        ...el,
        answer: el.answer.toUpperCase().replace(/\s/g, ""),
      }));

    const layout = generator.generateLayout(crucigramaData);
    const crosswordWithIds = layout?.result?.map((el) => ({
      ...el,
      _id: uuidv4(),
      myAnswer: "",
    }));
    setCrossword(crosswordWithIds);
  }, []);

  return (
    <div className={`w-full max-w-activity pt-10`}>
      {instrucciones && (
        <div className="act-instructions mb-5">{instrucciones}</div>
      )}
      {crossword.length > 0 && (
        <CrucigramaBody
          crucigrama={crossword}
          setCrossword={setCrossword}
          evaluable={evaluable}
          crucigramaLength={crossword.length}
          umaBloque={umaBloque}
          guardarCalificacion={guardarCalificacion}
        />
      )}
    </div>
  );
}

Crucigrama.defaultProps = {
  evaluable: true,
};

export default Crucigrama;
