import { deleteAvisoFromHistory } from "../../../services/Api/historial-avisos";
import { toast } from "react-toastify";
import { useState } from "react";
import alertConfiguration from "../../../utils/alertsUtils";

const DeleteAviso = ({ id, getAvisos, onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  // Delete function
  const handleDeleteAviso = async () => {
    try {
      setIsLoading(true);

      const body = {
        _id: id,
        esEliminado: true,
      };
      await deleteAvisoFromHistory(body);
      getAvisos();
      onCloseModal();

      toast.success(
        "El aviso ha sido eliminado exitosamente",
        alertConfiguration
      );
    } catch (error) {
      console.error(error);
      toast.error("Ocurrió un error al eliminar el aviso", alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid gap-y-4">
      <div className="max-w-2xl text-lg md:px-10 text-center w-full px-3">
        <h2>Al eliminar el aviso, ya no podrá leerlo posteriormente</h2>
      </div>

      <div className="w-full text-center max-w-2xl">
        <h2 className="text-nimbusDark font-semibold text-2xl mb-3 sm:px-20">
          ¿Está seguro que desea eliminar el aviso de forma definitiva?
        </h2>
      </div>

      <div className="flex justify-center gap-x-4">
        <button
          disabled={isLoading}
          className="flex items-center justify-center bg-nimbusDark w-32 h-10 rounded-lg text-white font-semibold cursor-pointer mr-5"
          onClick={handleDeleteAviso}
          text="Confirmar"
        >
          <span>Confirmar</span>
        </button>

        <button
          className="flex items-center justify-center bg-gray-200 w-32 h-10 rounded-lg text-nimbusDark font-semibold cursor-pointer mr-5"
          onClick={onCloseModal}
          disabled={isLoading}
        >
          <span>Cancelar</span>
        </button>
      </div>
    </div>
  );
};

export default DeleteAviso;
