import ReporteAvisosRow from "./ReporteAvisosRow";

const ReporteAvisosTable = ({ reports }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th />
            <th className="text-center">Título del aviso</th>
            <th className="text-center">Categoría</th>
            <th className="text-center">Fecha de envío</th>
            <th className="text-center">Enviado a</th>
            <th className="text-center">Leído por</th>
            <th className="text-center">Sin leer por</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {reports?.map((report) => (
            <ReporteAvisosRow key={report?._id} report={report} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReporteAvisosTable;
