import React from "react";
import ProductosRow from "./ProductosRow";

const ProductosTable = ({ productos, obtenerProductos, tipoDeMoneda }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            <th className="text-center">Nombre del producto</th>
            <th className="text-center">Categoría</th>
            <th className="text-center">Tipo de producto</th>
            <th>
              <div className="text-center flex gap-2 items-center justify-center">
                Costo ({tipoDeMoneda[0]})
              </div>
            </th>
            <th>
              <div className="text-center flex gap-2 items-center justify-center">
                Precio de venta ({tipoDeMoneda[0]})
              </div>
            </th>
            <th>
              <div className="text-center flex gap-2 items-center justify-center">
                Ganancia ({tipoDeMoneda[0]})
              </div>
            </th>
            <th className="text-center">Origen</th>
            <th className="text-center">Estatus</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto) => (
            <ProductosRow key={producto._id} productoInfo={producto} obtenerProductos={obtenerProductos} tipoDeMoneda={tipoDeMoneda}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductosTable;
