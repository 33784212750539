import { _delete, _get, _post, _put } from "../../http";

export const postListaDifusion = (body) => _post(`/listasDeDifucion`, body);

export const getListasDifusionPropias = (usuarioId, page, name) => {
  let url = `/listasDeDifucion/usuario/${usuarioId}?pagina=${page}&limite=${5000}`;
  if (name) url += `&nombre=${name}`;
  return _get(url);
};

export const getListaDifusion = (listaDifusionId) => {
  return _get(`/listasDeDifucion/${listaDifusionId}`);
};

export const deleteListaDifusion = (listaDifusionId) => {
  return _delete(`/listasDeDifucion/${listaDifusionId}`);
};

export const createListaDifusion = (body) => {
  return _post("/listasDeDifucion", body);
};

export const updateListaDifusion = (body, id) => {
  return _put(`/listasDeDifucion/${id}`, body);
};
