import React from "react";
import { Route, Routes } from "react-router-dom";
import CuentasComerciales from "../../../components/comercial/CuentasComerciales/CuentasComerciales";

const CuentasComercialesRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<CuentasComerciales />} />
    </Routes>
  );
};

export default CuentasComercialesRoutes;
