import React from "react";

const ProductoMenu = ({ menu, setMenu }) => {
  return (
    <div className={`mx-0 lg:mx-24 grid bg-white rounded-md h-12 text-sm md:text-base grid-cols-2`}>
      <button
        className={`${
          menu === 0 ? "bg-nimbusDark text-white" : "bg-white text-nimbusDark"
        } flex justify-center items-center rounded-l-md transition-all duration-200`}
        onClick={() => setMenu(0)}
      >
        Información del producto
      </button>
      <button
        className={`${
          menu === 1 ? "bg-nimbusDark text-white" : "bg-white text-nimbusDark"
        } rounded-r-md flex justify-center items-center transition-all duration-200`}
        onClick={() => setMenu(1)}
      >
        Atributos
      </button>
    </div>
  );
};

export default ProductoMenu;
