import { useEffect, useState } from "react";
import { useHistorialAvisos } from "../HistorialAvisosContext";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../fragments/Inputs/InputSelect";

const FILTRO_HISTORIAL_AVISOS_INITIAL_VALUES = {
  categoria: "",
  estatus: "",
  busqueda: "",
};

export const estatusOptions = [
  { _id: false, nombre: "Sin leer" },
  { _id: true, nombre: "Leído" },
];

const HistorialAvisosFilterForm = () => {
  const { avisos, setAvisosFiltro } = useHistorialAvisos();

  const [filtro, setFiltro] = useState(FILTRO_HISTORIAL_AVISOS_INITIAL_VALUES);
  const [categories, setCategories] = useState([]);

  const categoryNames = new Set();

  useEffect(() => {
    if (avisos) {
      createCategoriesOptions(avisos);
    }
  }, [avisos]);

  useEffect(() => {
    const { busqueda, categoria, estatus } = filtro;

    let newAvisosFiltro = avisos;
    newAvisosFiltro = filterCategoria(newAvisosFiltro, categoria);
    newAvisosFiltro = filterEstatus(newAvisosFiltro, estatus);
    newAvisosFiltro = filterBusqueda(newAvisosFiltro, busqueda.toLowerCase());
    setAvisosFiltro(newAvisosFiltro);
  }, [filtro]);

  const createCategoriesOptions = (avisosList) => {
    const categoriesOptions = [];
    
    avisosList.forEach(avisoObj => {
      const categoria = avisoObj.aviso.categoria;
    
      // Verify id category already exist in the names Set
      if (!categoryNames.has(categoria.nombre)) {
        // If it doesn't, it is setted inside categories array
        categoryNames.add(categoria.nombre);
        categoriesOptions.push({
          _id: categoria.nombre,
          nombre: categoria.nombre
        });
      }
    });

    setCategories(categoriesOptions);
  }

  // Function to filter by category
  const filterCategoria = (avisos, categoria) => {
    if (categoria === "") return avisos;
    const newAvisosFiltro = avisos.filter(
      (aviso) => aviso?.aviso?.categoria?.nombre === categoria
    );

    return newAvisosFiltro;
  };

  // Function to filter by status
  const filterEstatus = (avisos, estatus) => {
    if (estatus === "") return avisos;
    const newAvisosFiltro = avisos.filter(
      (aviso) => aviso?.esLeido === (estatus === "true")
    );
    return newAvisosFiltro;
  };

  // Function to filter by search
  const filterBusqueda = (avisos, busqueda) => {
    if (busqueda === "") return avisos;

    const newAvisosFiltro = avisos.filter((aviso) =>
      aviso?.aviso?.nombre?.toLowerCase().includes(busqueda)
    );
    return newAvisosFiltro;
  };

  return (
    <div className="flex flex-col gap-3 md:flex-row">
      <div className="flex flex-row">
        <InputSelect
          id="categoria"
          name="categoria"
          value={filtro.categoria}
          options={categories ?? []}
          onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
          styles="md:w-[124px] lg:w-[124px]"
          label={"Categoría"}
          topLabel={true}
        />
      </div>

      <div className="flex flex-row">
        <InputSelect
          id="estatus"
          name="estatus"
          value={filtro.estatus}
          options={estatusOptions ?? []}
          onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
          styles="md:w-[124px] lg:w-[124px]"
          label={"Estatus"}
          topLabel={true}
        />
      </div>

      <div>
        <InputBuscar
          onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
        />
      </div>
    </div>
  );
};

export default HistorialAvisosFilterForm;
