import React, { useMemo } from "react";
import { ReactComponent as AlumnoListIcon } from "../../../../assets/icons/alumnoListIcon.svg";
import { ReactComponent as View } from "../../../../assets/icons/view.svg";
import { useNavigate } from "react-router-dom";

const MiPaqueteEscolarRow = ({ alumnoInfo }) => {
  const navigate = useNavigate();
  const { alumno, articulosAsignados } = alumnoInfo;
  const { usuario, _id } = alumno;
  const { nombres, primerApellido, segundoApellido } = usuario;

  const { articulosAdquiridos, articulosSinAdquirir } = useMemo(() => {
    return articulosAsignados.reduce(
      (cantidadArticulos, articulo) => {
        if (articulo.articuloadquirido) {
          return articulo.articuloadquirido.esAdquirido
            ? { ...cantidadArticulos, articulosAdquiridos: cantidadArticulos.articulosAdquiridos + 1 }
            : { ...cantidadArticulos, articulosSinAdquirir: cantidadArticulos.articulosSinAdquirir + 1 };
        }

        return { ...cantidadArticulos, articulosSinAdquirir: cantidadArticulos.articulosSinAdquirir + 1 };
      },
      { articulosAdquiridos: 0, articulosSinAdquirir: 0 }
    );
  }, [articulosAsignados]);

  return (
    <tr className="h-[3rem] bg-white">
      <td className="border-2 rounded-l-lg border-r-0 ">
        <AlumnoListIcon className="mx-auto" />
      </td>
      <td className="border-y-2 font-sans text-left">{`${nombres} ${primerApellido} ${segundoApellido}`}</td>
      <td className="border-y-2 font-sans text-left">{articulosAsignados.length}</td>
      <td className="border-y-2 font-sans text-left">{articulosAdquiridos}</td>
      <td className="border-y-2 font-sans text-left">{articulosSinAdquirir}</td>
      <td className="border-2 rounded-r-md border-l-0 min-h-[52px] text-left font-sans">
        <View
          className="mx-auto cursor-pointer"
          title="Visualizar paquete escolar"
          onClick={() =>
            navigate(`/marketplace/paquetesAsignados`, {
              state: {
                articulosAsignados: articulosAsignados,
                alumnoId: _id,
                nombreAlumno: `${nombres} ${primerApellido} ${segundoApellido}`,
              },
            })
          }
        />
      </td>
    </tr>
  );
};

export default MiPaqueteEscolarRow;
