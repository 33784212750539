import React, { Fragment, useContext, useEffect, useState } from "react";
import NoDataMessage from "../../../fragments/NoDataMessage";
import TablePagination from "../../../fragments/TablePagination";
import { usePagination } from "../../../hooks/usePagination";
import SubHeaderPaquetesAsignados from "./fragments/SubHeaderPaquetesAsignados";
import { MESSAGES } from "../../../utils/messagesUtils";
import PaquetesAsignadosFilter from "./fragments/PaquetesAsignadosFilter";
import PaquetesAsignadosTable from "./fragments/PaquetesAsignadosTable";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import { useLocation, useNavigate } from "react-router-dom";
import useFormSubmit from "../../../hooks/useFormSubmit";
import { postGuardarArticulosAdquiridos } from "../../../services/Api/MarketPlace/PaqueteEscolar";
import { UserContext } from "../../../services/user";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { getPaises } from "../../../services/api";

const PaquetesAsignados = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, activeRole } = useContext(UserContext);
  const { articulosAsignados: articulosAsignadosLista, alumnoId, nombreAlumno } = state;

  const [articulosAsignados, setArticulosAsignados] = useState([]);
  const [articulosAsignadosFiltro, setArticulosAsignadosFiltro] = useState([]);
  const [isEnableSubmitButton, setIsEnableSubmitButton] = useState(false);
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(articulosAsignadosFiltro, 20);

  const handleArticulosAdquiridosSubmit = async () => {
    try {
      const articulosAdquiridosBody = articulosAsignados.map((articulo) => {
        return {
          asignacionArticuloGrupo: {
            _id: articulo.asignacionarticulogrupo._id,
          },
          esAdquirido: articulo.esAdquirido,
        };
      });

      const body = {
        alumno: {
          _id: alumnoId,
        },
        articulosAdquiridos: articulosAdquiridosBody,
      };

      await postGuardarArticulosAdquiridos(user.escuela.campus.tutores[0]._id, body);
      toast.success("Artículos adquiridos guardados correctamente", alertConfiguration);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(handleArticulosAdquiridosSubmit);

  const cambiarEstatusArticuloAsignado = (articuloId) => {
    let newArticulosAsignados = articulosAsignados.map((articulo) =>
      articulo.asignacionarticulogrupo._id === articuloId
        ? { ...articulo, esAdquirido: !articulo.esAdquirido }
        : articulo
    );

    setArticulosAsignados(newArticulosAsignados);
    setIsEnableSubmitButton(true);
  };

  useEffect(() => {
    let newArticulosAsignados = articulosAsignadosLista.map((articulo) => {
      if (articulo.articuloadquirido) {
        articulo.esAdquirido = articulo.articuloadquirido.esAdquirido;
        return articulo;
      }

      articulo.esAdquirido = false;
      return articulo;
    });
    setArticulosAsignados(newArticulosAsignados);
    setArticulosAsignadosFiltro(newArticulosAsignados);
  }, [articulosAsignadosLista]);

  useEffect(() => {
    const obtenerTipoDeMoneda = async () => {
      const { estructura } = obtenerUserEstructuraInfo(user, activeRole);
      const paises = await getPaises();
      if (estructura.dirFiscalPais) {
        const simbolo =
          estructura.dirFiscalPais.nombre === "Estados Unidos"
            ? ["USD"]
            : estructura.dirFiscalPais.simbolo
            ? [estructura.dirFiscalPais.simbolo]
            : [paises.data.filter((pais) => pais._id === estructura.dirFiscalPais)[0]?.simbolo];
        setTipoDeMoneda(simbolo);
      }
    };

    if (activeRole !== "") {
      obtenerTipoDeMoneda();
    }
  }, [activeRole, user]);

  return (
    <Fragment>
      <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
        <SubHeaderPaquetesAsignados nombreAlumno={nombreAlumno} />
        <div
          className={`${
            articulosAsignados.length !== 0 && "justify-between"
          } relative flex-1 flex flex-col px-4 sm:px-8`}
        >
          <div>
            <div className="text-lg text-[#6E6D7A] w-full mt-2">
              En esta pantalla podrá llevar un control de los artículos asignados a su alumno marcando la casilla de
              aquellos que ya fueron adquiridos. Podrá acceder a visualizar el detalle del producto en la tienda por
              medio del icono de vista.
            </div>
            <PaquetesAsignadosFilter
              articulosList={articulosAsignados}
              articulosAsignadosFiltro={articulosAsignadosFiltro}
              setArticulosAsignadosFiltro={setArticulosAsignadosFiltro}
            />
            <PaquetesAsignadosTable
              articulosList={currentData()}
              cambiarEstatusArticuloAsignado={cambiarEstatusArticuloAsignado}
              tipoDeMoneda={tipoDeMoneda}
            />
            {articulosAsignados.length !== 0 && articulosAsignadosFiltro.length === 0 && (
              <NoDataMessage message={MESSAGES.MESSAGES_FILTER_ARTICULOS} stylesWrapper="!my-6" />
            )}
          </div>

          <div>
            {articulosAsignados.length === 0 ? (
              <NoDataMessage message={MESSAGES.MESSAGES_PAQUETES_ASIGNADOS_TABLE} />
            ) : (
              <TablePagination
                next={next}
                prev={prev}
                jump={jump}
                data={currentData()}
                currentPage={currentPage}
                maxPage={maxPage}
              />
            )}
            <div className="flex justify-center gap-x-4 mt-6">
              <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                <CancelButton
                  text={"Cancelar"}
                  bgColor="bg-divider"
                  styles="2xl:w-[10rem]"
                  onClick={() => navigate(-1)}
                />
                <SubmitButton
                  text="Guardar"
                  styles="2xl:w-[10rem]"
                  disabled={!isEnableSubmitButton}
                  onClick={handleSubmit}
                  loadingButton
                  loading={formStatus === "submitting"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PaquetesAsignados;
