import { v4 as uuidv4 } from "uuid";

function generarNuevoValor() {
  const valorId = uuidv4();
  let valor = {
    valorId: valorId,
    imagen: "",
    valor: "",
  };

  return valor;
}

function generarNuevaVariable() {
  const variableId = uuidv4();
  let valor1 = generarNuevoValor();
  let valores = [valor1];
  let variable = {
    variableId: variableId,
    variable: "",
    valores: valores,
  };

  return variable;
}

export { generarNuevaVariable, generarNuevoValor };
