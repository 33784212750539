import React, { Fragment, useContext, useEffect, useState } from 'react';
import SubHeaderVentaInfo from './fragments/SubHeaderVentaInfo';
import DatosDelPedido from './fragments/DatosPedido';
import ResumenPedido from './fragments/ResumenPedido';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../services/user';
import {
  getPedidoDetalles,
  getPedidosPorCompra,
} from '../../services/Api/MarketPlace/MisPedidos';
import LoadingScreen from '../../fragments/LoadingScreen';

const VentaInfo = () => {
  const user = useContext(UserContext);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [ventaInfo, setVentaInfo] = useState([]);

  const getDetallesPedido = async () => {
    try {
      setLoading(true);
      if (
        user.activeRole === 'DIR_PLANTEL' ||
        user.activeRole === 'ADMIN_OPERATIVO'
      ) {
        await getPedidosPorCompra(params.ventaId).then((res) => {
          setVentaInfo(res);
        });
      } else {
        await getPedidoDetalles(params.ventaId).then((res) => {
          setVentaInfo(res);
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user.activeRole !== '') {
      getDetallesPedido();
    }
  }, [user.activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderVentaInfo
            ventaId={
              user.activeRole === 'DIR_PLANTEL' ||
              user.activeRole === 'ADMIN_OPERATIVO'
                ? ventaInfo?._id
                : ventaInfo?.compra?._id
            }
          />
          <div className={`relative flex-1 flex flex-col px-4 sm:px-8 mt-5`}>
            <div className='bg-backgroundColor'>
              <DatosDelPedido ventaInfo={ventaInfo} />
            </div>
            <div className='bg-backgroundColor mt-10'>
              <ResumenPedido data={ventaInfo} getData={getDetallesPedido} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default VentaInfo;
