import { type } from '@testing-library/user-event/dist/type';
import React, {useState, useEffect} from 'react'
import Modal from 'react-modal'
import CerrarIcon from '../../assets/icons/cerrar-circle.svg'

function PDFviewer({content,  modal, toggleModal}) {

  useEffect(()=>{
    Modal.defaultStyles.overlay.backgroundColor = 'rgb(84 84 84 / 75%)';
    Modal.defaultStyles.overlay.zIndex = '+99';
  },[])

  const modalStyles = {
    content: {
      padding:'0',
      display:'flex',
      flexDirection:'column',
      borderRadius:'20px 20px 0 0'
    },
  };

  return (
    <>
        <Modal
        isOpen={modal}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={modalStyles}
        >
            <div className="flex justify-end items-center  md:top-8 right-1 md:right-1 sm:top- pr-4 ">
                <p className='text-nimbus mr-0.5 font-bold font-sans'>Cerrar</p>
                <button type="button" className="rounded-x focus:outline-none" onClick={toggleModal}>
                    <img src={CerrarIcon} alt='' />
                </button>
            </div>
            <embed  src={typeof content === 'object' ? URL.createObjectURL(content) : content} type='application/pdf' className='h-full w-full hidden md:block'></embed>
            <object data={typeof content === 'object' ? URL.createObjectURL(content) : content} type="application/pdf" width="100%" height="100%" className='block md:hidden'>
                <p className='p-4'>Tu navegador no cuenta con un plugin para visualizar archivos pdf.En su lugar puedes <a href={typeof content === 'object' ? URL.createObjectURL(content) : content} target="_blank" className='text-[#00a6f0]' rel="noopener noreferrer">dar click aquí para ver el archivo</a></p>
            </object>
        </Modal>
    </>
  )
}

export default PDFviewer