import React, { useState } from "react";
import { Trans } from "react-i18next";
function ImagenValidada({ src, className, onClick }) {
  const [validada, setValidada] = useState(true);
  return (
    <>
      {validada ? (
        <>
          {src !== "" && (
            <img
              src={typeof src !== "string" ? URL.createObjectURL(src) : src}
              className={className}
              onClick={onClick}
              onError={() => setValidada(false)}
              alt=""
            />
          )}
        </>
      ) : (
        <div className="text-center font-bold text-nimbusLight !text-base p-2 max-w-[9rem] bg-white rounded-lg mx-auto m-4">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
    </>
  );
}

ImagenValidada.defaultProps = {
  src: "",
  className: "",
  onClick: undefined,
};

export default ImagenValidada;
