export const ESTATUS_PEDIDO_FILTER = [
  {
    _id: 'PREPARANDO',
    nombre: 'Preparando',
  },
  {
    _id: 'COMPLETADO',
    nombre: 'Completado',
  },
  {
    _id: 'ENTREGADO',
    nombre: 'Entregado',
  },
];


export const ESTATUS = ['PREPARANDO', 'COMPLETADO', 'ENTREGADO'];
