import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {createStore,combineReducers, compose, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import arreglosReducers from './reducers/arreglosReducers';
import changeReducer from './components/marketplace/redux/reducers/changeReducer';
import { packageReducer } from './reducers/paquetes/paquetesReducer';
import thunk from 'redux-thunk';
import { marketplaceReducer } from './reducers/marketplace/marketplaceReducer';
import { carritoReducer } from './reducers/carrito/carritoReducer';
import { contenidoBibliotecaReducer } from './reducers/contenidoBiblioteca/contenidoBibliotecaReducer';
import { avisoDataReducer } from './reducers/avisoData/avisoDataReducer';

const middleWares = [thunk]
const composeEnhancers = compose(applyMiddleware(...middleWares))

const reducer=combineReducers({
  arreglo:arreglosReducers,
  market:changeReducer,
  paquetes: packageReducer,
  marketplace:marketplaceReducer, 
  carrito: carritoReducer,
  contenidoBiblioteca: contenidoBibliotecaReducer,
  avisoData: avisoDataReducer,
})
const store = createStore(reducer, composeEnhancers);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
