import React, { useEffect, useState } from "react";
import Painterro from "nimbus-painterro";
import CerrarActButton from "../ActividadesUtils/CerrarActButton";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import {useContext} from 'react'
import { toast } from 'react-toastify'
import alertConfiguration from "../../../utils/alertsUtils";
import {v4 as uuidv4} from 'uuid'
import { saveAs } from "file-saver";
import InfoIcon from '../../../../assets/icons/info.svg'
import EditIcon from '../../../../assets/icons/Edit.svg'
import { Trans } from "react-i18next";

function HerramientaDibujo({coloringPicture, indicaciones, evaluable, canvasId}) {
  const activityContext = useContext(ActividadContext)
  const [downloadedImage, setDownloadedImage] = useState('')
  const [fileName, setFileName] = useState('')
  const currentId = canvasId.replace(/[0-9]/g, '')//la librería tiene un problema al ingresar ids que incluyen números
  let showCreate = {};
  showCreate = coloringPicture !== "" ? coloringPicture : {};

  useEffect(() => {
    window.ptro = Object.keys(Painterro(
      {
        id: currentId,
        hiddenTools: [
          "crop",
          "rotate",
          "resize",
          "zoomin",
          "zoomout",
          'select',
          'pixelize',
          'settings',
          'close'
        ],
        defaultSize:'200x100',
        fixMobilePageReloader:false,
        defaultTool:'brush',
        defaultSize:'1050x550',
        language: 'es',
        bucketSensivity	:coloringPicture ? 15 : 100,
        activeColor:'#000',
        defaultPrimitiveShadowOn:false,
        activeFillColor:'#000',
        saveHandler: function (image, done) {
          image.asBlob(image.hasAlphaChannel() ? 'image/png' : 'image/jpeg');
          const imageToDownload = image.asDataURL
          setDownloadedImage(imageToDownload)
          done()
        },
      }
    ).show(showCreate)).map((obj) => {
      return obj;
    })
  },[evaluable]);

  useEffect(()=>{
    if(downloadedImage){
      saveAs(downloadedImage, fileName !== '' ? fileName : 'mi_dibujo.png')
      toast.success("Archivo guardada con éxito. Revisa tu folder de descarga configurado.", alertConfiguration)
    }
  },[downloadedImage])

  const calificar = () =>{
    activityContext.postCalificacionActividad(100)
  }

  return (
    <>
    <div className="min-h-[30rem] md:hidden"></div>
    <div className="md:flex md:items-center md:justify-between p-2 absolute top-0 left-0 z-[+8] w-full bg-white box-shadow">
      {indicaciones ? 
      <div className="flex mb-3 md:mb-0">
        <img alt="" src={InfoIcon} className="mr-2 hidden md:block"/>
        <div className="z-[+5] font-bold text-left w-full text-nimbusLight  pointer-events-none text-sm md:text-sm leading-4 ml-1 mt-2  md:m-0"><Trans i18nKey='instruccionesLabel'></Trans>: {indicaciones}</div>
      </div>:null}
      <div className="relative md:max-w-[22rem] w-full">
          <img alt="" src={EditIcon} className="absolute left-2 top-1/2 -translate-y-1/2"/>        
          <input 
            type="text" 
            placeholder="" 
            className="shadow-lg rounded z-10 mr-2 md: w-full  h-[2.2rem] border border-[#dcdcdc] pl-9"
            onChange={fileName.length < 150 ? (e)=>setFileName(e.target.value) : undefined}
            value={fileName}
          />
          {fileName.length === 0 && <div className="absolute top-1/2 -translate-y-1/2 left-10 pointer-events-none">
            <Trans i18nKey={'drawingNameLabel'}></Trans>
          </div>}
      </div>
    </div>
    <div className="w-full h-full z-0 mt-10 pt-10">
         <div id={currentId} className="w-full h-full !pointer-events-auto mt-10 pt-10"></div>
    </div>
    {evaluable ? 
    <div className="absolute bottom-[17px] scale-75">
      <CerrarActButton text={'Calificar'} onClick={calificar}/>
    </div>:''
    }
    </>
  )
}

export default HerramientaDibujo