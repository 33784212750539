import CurrencyInput from "react-currency-input-field";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrowUpSimple.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDownSimple.svg";

export default function InputMoneda({
  value,
  onBlur,
  onChange,
  name,
  id,
  disabled = false,
  label,
  placeholder,
  error = "",
  styles = "",
  errorStyles = "",
  maxLength = undefined,
  wrapperStyles = "",
  labelStyles = "",
  extraInfoLines,
  extraInfoStyles = "",
  disabledStyles = false,
  decimalsLimit = 2,
  enableStep = false,
  decimalScale= 2,
  incrementValue,
  decrementValue,
}) {
  const handleChange = (value, name, _) => {
    let event = {
      target: {
        type: "text",
        name: name,
        value: value,
      },
      persist: () => {},
    };
    onChange(event);
  };

  return (
    <div className={`grid gap-y-2 ${wrapperStyles}`}>
      <div className="flex truncate space-x-3 items-center">
        <p className={`font-semibold text-base text-[#0D0C22] ${!extraInfoLines && "truncate"} ${labelStyles}`}>
          {label}
        </p>
        {extraInfoLines && (
          <span
            className={`flex gap-1 truncate justify-center before:content-['('] after:content-[')'] before:mb-0.5 after:mb-0.5  ${extraInfoStyles}`}
          >
            <span className="flex flex-col truncate justify-center">
              {extraInfoLines.map((text) => (
                <p key={text} className={`font-semibold text-xs text-[#0D0C22] truncate ${labelStyles}`}>
                  {text}
                </p>
              ))}
            </span>
          </span>
        )}
      </div>
      <div className="relative">
        <CurrencyInput
          className={`peer border-2 h-[40px] focus:outline-none rounded-lg p-2 w-80 lg:w-full ${styles} ${
            error === "" ? "border-gray-300" : "border-red-400"
          } ${disabledStyles && "bg-gray-200 opacity-70"}`}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          onValueChange={handleChange}
          name={name}
          id={id}
          disabled={disabled}
          maxLength={maxLength}
          decimalsLimit={decimalsLimit}
          decimalScale={decimalScale}
          step={0.01}
        />
        {(!disabled && enableStep) && (
          <>
            <ArrowUp
              className="absolute top-2 right-2 cursor-pointer hover:bg-gray-200 select-none"
              onClick={incrementValue}
            />
            <ArrowDown
              className="absolute top-[22px] right-2 cursor-pointer hover:bg-gray-200 select-none"
              onClick={decrementValue}
            />
          </>
        )}
        <p className={`${errorStyles} font-thin text-xs text-red-400`}>{error}</p>
      </div>
    </div>
  );
}
