import { useState, useEffect } from "react"
import { Wheel } from "react-custom-roulette"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'

const initialResults = [{texto:''},{texto:''},{texto:""},{texto:""},{texto:""}]

export default function Ruleta({ruleta}) {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [rouletteData,setRouletteData] = useState(ruleta)
    const [resultsTable, setResultsTable] = useState(initialResults)

    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * ruleta.length);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
    };

    useEffect(() => {
        const addShortString = ruleta.map((item) => {
            return {
                completeOption: item.texto,
                option:
                    item.texto.length >= 30
                        ? item.texto.substring(0, 30).trimEnd() + "..."
                        : item.texto
            };
        });
        setRouletteData(addShortString);
    }, [ruleta]);

    useEffect(()=>{
        if(!mustSpin){
            setResultsTable([{texto:rouletteData[prizeNumber].completeOption},...resultsTable])
            console.log({resultsTable})
            console.log(rouletteData[prizeNumber].completeOption)
        }
    },[mustSpin])
    return(
    <div className="flex items-center justify-center flex-col-reverse lg:flex-row">
        <div className="border border-[#dcdcdc] rounded-xl w-full lg:w-[20rem] mr-0 lg:mr-6 overflow-hidden">
            <div className="font-xl text-white bg-[#3ea34d] mb-6 font-bold p-3 text-center flex justify-between items-center">
                <span className="">últimos 5 resultados:</span>
                <FontAwesomeIcon className="text-xl hover:text-[#cccccc] transition" icon={faArrowRotateRight} onClick={()=>setResultsTable(initialResults)}></FontAwesomeIcon>
            </div>
            {resultsTable.slice(0,5).map((item, index)=>(
                <div className="mb-4 p-3 flex items-baseline pt-0">
                    <span className="mr-3 w-6 h-6 flex items-center justify-center bg-[#3ea34d] text-white rounded-full">{index + 1}</span>
                    <div className="break-word">{item.texto}</div>
                </div>
            ))}
        </div>
        <div className="relative mt-10 mb-10 w-full">
            <div align='center' className="roulette-container relative block w-full mx-auto h-fit">
                <Wheel
                    mustStartSpinning={mustSpin}
                    spinDuration={[0.25]}
                    prizeNumber={prizeNumber}
                    data={rouletteData}
                    outerBorderColor={["#ccc"]}
                    outerBorderWidth={[9]}
                    innerBorderColor={["#f2f2f2"]}
                    radiusLineColor={["tranparent"]}
                    radiusLineWidth={[1]}
                    textColors={["#f5f5f5"]}
                    textDistance={55}
                    fontSize={[9]}
                    backgroundColors={[
                        "#3f297e",
                        "#175fa9",
                        "#169ed8",
                        "#239b63",
                        "#64b031",
                        "#efe61f",
                        "#f7a416",
                        "#e6471d",
                        "#dc0936",
                        "#e5177b",
                        "#be1180",
                        "#871f7f"
                    ]}
                    onStopSpinning={() => {
                        setMustSpin(false);
                    }}
                />
                <button
                    className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full text-black z-[5] w-11 h-11 md:w-20 md:h-20 text-sm md:text-base ${mustSpin ? 'pointer-events-none' : ''}  `}
                    onClick={handleSpinClick}
                >
                    Girar
                </button>
            </div>
            <button
                className="p-6 rounded-lg w-full max-w-[800px] mx-auto block text-white mt-6 text-base md:text-2xl font-bold break-words"
                onClick={handleSpinClick} disabled={mustSpin}
                style={{background:"linear-gradient( 0deg, #239b63 0%, rgba(100, 176, 49, 1) 100% )"}}
                >
                    <span>
                {!mustSpin ? rouletteData[prizeNumber].completeOption : "Girando..."}

                    </span>
            </button>
        </div>
    </div>
    )
}