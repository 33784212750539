import { useNavigate } from "react-router-dom";
import DeleteLista from "./DeleteLista";
import EditarIcon from "../../../../assets/icons/Edit.svg";
import EliminarIcon from "../../../../assets/icons/trash2.svg";
import NimbusModal from "../../../fragments/NimbusModal";
import PeopleIcon from "../../../../assets/img/people.png";
import React, { useState } from "react";
import VisualizarIcon from "../../../../assets/icons/viewIcon.svg";

const customStyles = {
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

const ListasDifusionRow = ({ lista_difusion, getListasDifusion }) => {
  const { _id, nombre, destinatarios } = lista_difusion;
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-2 rounded-l-lg border-r-0 text-left font-sans">
          <div className="flex items-center justify-center">
            <img src={PeopleIcon} className="h-7 w-auto mx-3 lg:mx-0" />
          </div>
        </td>
        <td className="border-y-2 font-sans text-left">{nombre}</td>
        <td className="border-y-2 font-sans text-center">
          {destinatarios?.length}
        </td>
        <td className="border-2 rounded-r-md border-l-0 text-center font-sans flex justify-around min-h-[48px]">
          <button
            title="Editar lista de difusión"
            className="w-5"
            onClick={() =>
              navigate(`/herramientas/listas-difusion/${_id}/editar`)
            }
          >
            <img src={EditarIcon} className="w-5 h-5" />
          </button>
          <button
            title="Ver lista de difusión"
            className="w-5"
            onClick={() => navigate(`/herramientas/listas-difusion/${_id}/ver`)}
          >
            <img src={VisualizarIcon} className="w-5 h-5" />
          </button>
          <button
            title="Eliminar lista de difusión"
            className="w-5"
            onClick={() => setDeleteModal(true)}
          >
            <img src={EliminarIcon} className="w-5 h-5" />
          </button>
        </td>
      </tr>

      <NimbusModal isOpen={deleteModal} styles={customStyles}>
        <DeleteLista
          oCloseModal={() => setDeleteModal(false)}
          id={_id}
          getListasDifusion={getListasDifusion}
        />
      </NimbusModal>
    </>
  );
};

export default ListasDifusionRow;
