import { CHANGE_PAGE, DEFINE_TYPE, INSERRT_WORD, SET_TYPE,NOTIFICATION, CONT,FAVORITES,DELETE_FAV,COMPRADOS,CLEANCOMPRAS,BANDERACOMPRAS,TABLEMODIFYA,CONTADORCOMPRAS,CONTADORCOMPRAS2,CLEANCONTADORCOM,CLEANCONTADORCOM2,TABLEMODIFY,TABLEMODIFYITEMS} from '../Types';
export const cambiar = (payload) => (
    {
        type:CHANGE_PAGE,
        payload
    }
);
export const insert_word = (payload) => (
    {
        type:INSERRT_WORD,
        payload
    }
);
export const definir_tipo = (payload) => (
    {
        type:DEFINE_TYPE,
        payload
    }
);
export const set_tipo = (payload) => (
    {
        type:SET_TYPE,
        payload
    }
);
export const notification = (payload) => (
    {
        type:NOTIFICATION,
        payload
    }
);
export const contador = (payload) => (
    {
        type:CONT,
        payload
    }
);
export const arreglofavoritos = (payload) => (
    {
        type:FAVORITES,
        payload
    }
);
export const eliminarfav = (payload) => (
    {
        type:DELETE_FAV,
        payload
    }
);
export const banderacompras= (payload) => (
    {
        type:BANDERACOMPRAS,
        payload
    }
);
export const contadorcompras=()=>(
    {
        type:CONTADORCOMPRAS
    }
);
export const contadorcompras2=()=>(
    {
        type:CONTADORCOMPRAS2
    }
);
export const arreglocompras = (payload) => (
    {
        type:COMPRADOS,
        payload
    }
);
export const tablachange = (payload) => (
    {
        type:TABLEMODIFY,
        payload
    }
);
export const tablachangea = (payload) => (
    {
        type:TABLEMODIFYA,
        payload
    }
);

export const tablachangeItems = (payload) => (
    {
        type:TABLEMODIFYITEMS,
        payload
    }
);

export const limpiarcompras= (payload) => (
    {
        type:CLEANCOMPRAS,
        payload
    }
);
export const limpiarcontadorcom= () => (
    {
        type:CLEANCONTADORCOM
    }
);
export const limpiarcontadorcom2= () => (
    {
        type:CLEANCONTADORCOM2
    }
);
