import React from "react";
import folder from "../../../assets/img/folder.svg";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

function TooltipButton({
  handleClick,
  message,
  styles,
  disabled = false,
  tooltipText = "",
  icon = folder,
  iconStyles = "",
}) {
  const tooltipId = uuidv4();
  return (
    <>
      <button
        onClick={handleClick}
        disabled={disabled}
        className={`rounded-full hover:bg-[#f9f9f9] mx-2 border border-nimbusDark text-nimbusDark flex items-center justify-center p-2 w-full text-center gap-x-2 font-semibold px-4 ${styles} ${
          disabled ? "bg-opacity-90 cursor-default" : "cursor-pointer"
        }`}
        data-tip
        data-for={tooltipId}
      >
        <img src={icon} alt="" className={`w-6 h-6 ${iconStyles}`} />
        {message}
      </button>
      {tooltipText && (
        <ReactTooltip
          id={tooltipId}
          place="bottom"
          effect="solid"
          className="max-w-[25rem]"
          delayShow={1000}
        >
          <span className="block">{tooltipText}</span>
        </ReactTooltip>
      )}
    </>
  );
}

export default TooltipButton;
