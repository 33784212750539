import React from "react";
import TooltipLabel from "../../../../utils/TooltipLabel";
import { Trans } from "react-i18next";
import { getCalificacionFinal } from ".";

function CalificacionesBox({ estatus, fechaEnvio, calificacionRubricas }) {
  const sumatoriaArr = calificacionRubricas
    .filter((el) => typeof el.value === "number")
    .map((el) => el.value);

  return (
    <>
      <div className="mb-6 w-[15rem] mx-auto">
        <div className="font-bold text-black mb-2">
          <TooltipLabel
            title="Resultados"
            tooltip="La calificación es calculada con base al promedio de todos los puntos seleccionados en la rúbrica"
          />
        </div>
        <div className="w-full">
          <div className="text-sm text-center font-bold text-black">
            <Trans i18nKey="">Estatus</Trans>
          </div>
          <div
            className={`font-bold border rounded-lg p-3 w-full text-center mb-3 bg-[#f5f5f5] text-sm lowercase ${
              estatus === "PENDIENTE"
                ? "border-t-[#e1e4e8]"
                : estatus === "ENVIADA"
                ? "text-[#2fdd9d]"
                : estatus === "RECHAZADA"
                ? "text-red-500"
                : estatus === "CALIFICADA"
                ? "text-[#1a3d82]"
                : ""
            }`}
          >
            <Trans i18nKey="">{estatus}</Trans>
          </div>
        </div>
        <div className="w-full">
          <div className="text-sm text-center font-bold text-black">
            <Trans>Fecha de envio</Trans>
          </div>
          <div className="border rounded-lg p-3 w-full text-center mb-3 bg-[#f5f5f5] text-sm">
            <span>{fechaEnvio ? fechaEnvio : '-'}</span>
          </div>
        </div>
        <div className="w-full">
          <div className="text-sm text-center font-bold text-black">
            <Trans>Calificación</Trans>
          </div>
          <div className="border rounded-lg p-3 w-full text-center mb-3 bg-[#f5f5f5] text-sm">
            {calificacionRubricas.length > 0
              ? `${getCalificacionFinal(calificacionRubricas)} / 100`
              : "-"}
          </div>
        </div>
        <div className="w-full">
          <div className="text-sm text-center font-bold text-black">
            <Trans>Sumatoria</Trans>
          </div>
          <div className="border rounded-lg p-3 w-full text-center mb-3 bg-[#f5f5f5] text-sm">
            {sumatoriaArr.length > 0
              ? `(${sumatoriaArr.join(" + ")}) / ${sumatoriaArr.length} * 20`
              : "-"}
          </div>
        </div>
      </div>
    </>
  );
}

export default CalificacionesBox;
