import React from 'react'
import ReactTooltip from "react-tooltip";
import {v4 as uuidv4} from 'uuid'
import infoIcon from '../../assets/icons/info.svg'
import { Trans } from 'react-i18next';

function TooltipLabel({title, styles, tooltip}) {
  const tooltipId = uuidv4()
  return (
    <div className={`${styles}`}>
        <Trans>
          {title}
        </Trans>
        <div className="border rounded-md bg-[#F1F1F2] ml-2 w-6 h-6 inline-flex items-center justify-center" data-tip data-for={tooltipId}>
            <img src={infoIcon} alt="" className='w-4 h-4'/>
        </div>
        <ReactTooltip id={tooltipId} place="top" effect="solid" className="max-w-[25rem]">
            <span className='block'>
              <Trans>{tooltip}</Trans>
            </span>
        </ReactTooltip>
    </div>
  )
}

TooltipLabel.defaultProps={
  title:'',
  tooltip:'',
  styles:'font-bold text-black mb-4'
}

export default TooltipLabel