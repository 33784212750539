export default function CancelButton({ text, onClick, disabled = false, bgColor = "bg-gray-200", styles = "" }) {
  return (
    <div
      className={`rounded-md text-nimbusDark w-1/3 flex items-center justify-center p-2 cursor-pointer ${bgColor} ${styles} ${bgColor} ${styles} ${
        disabled && "opacity-70 cursor-default"
      }`}
    >
      <button className="font-semibold w-full h-full rounded-sm" disabled={disabled} onClick={onClick}>
        {text}
      </button>
    </div>
  );
}
