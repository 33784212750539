import React from "react";
import NimbusModal from "../../../../fragments/NimbusModal";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductoCarritoDeCompras } from "../../../../services/Api/MarketPlace/CarritoDeCompras";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import { fetchCarritoAsync } from "../../../../../reducers/carrito/carritoAction";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../utils/alertsUtils";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import { selectProductosNoDisponibles } from "../../../../../reducers/carrito/carritoSelector";

const EliminarProductosNoDisponiblesModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const productosNoDisponibles = useSelector(selectProductosNoDisponibles);

  const eliminarProductoSubmit = async () => {
    try {
      await Promise.all(
        productosNoDisponibles.map(async (producto) => await deleteProductoCarritoDeCompras(producto._id))
      );
      toast.success("Artículos eliminados correctamente.", alertConfiguration);
      dispatch(fetchCarritoAsync());
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setIsOpen(false);
    }
  };
  const [formStatus, handleSubmit] = useFormSubmit(eliminarProductoSubmit);

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold w-64 mx-auto text-center">Artículos no disponibles</h1>
      <p className="w-96 sm:w-[36rem] px-4 break-words mx-auto">
        No fue posible continuar con el proceso de compra debido a que en el carrito existen artículos agotados o
        inexistentes; para poder continuar, es necesario eliminar dichos artículos
      </p>
      <div className="w-96 sm:w-[36rem] px-4 text-center mb-6 mx-auto">
        <p className="text-nimbusDark font-bold">
          A continuación, le mostramos dos opciones que puede aplicar para continuar con el proceso
        </p>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
        <CancelButton
          text="Eliminar artículos no disponibles, para poder continuar con el proceso de compra"
          bgColor="bg-divider"
          styles="!w-72 h-24 p-2"
          onClick={handleSubmit}
          />
        <SubmitButton
          text="Cerrar el cuadro de diálogo, y eliminar los artículos no disponibles de forma manual"
          disabled={formStatus === "submitting"}
          styles="!w-72 h-24 p-2"
          onClick={() => setIsOpen(false)}
        />
      </div>
    </NimbusModal>
  );
};

export default EliminarProductosNoDisponiblesModal;
