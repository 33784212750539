import React, { Fragment, useContext, useEffect, useState } from 'react';
import SubHeaderFormadorDetalles from './fragments/SubHeaderFormadorDetalles';
import { useNavigate, useParams } from 'react-router-dom';
import useValidateForm from '../../hooks/useValidateForm';
import useFormSubmit from '../../hooks/useFormSubmit';
import LoadingScreen from '../../fragments/LoadingScreen';
import AgregarFormadorForm from './fragments/AgregarFormadorForm';
import CancelButton from '../../fragments/Buttons/CancelButton';
import SubmitButton from '../../fragments/Buttons/SubmitButton';
import { UserContext } from '../../services/user';
import { toast } from 'react-toastify';
import alertConfiguration from '../../utils/alertsUtils';
import { obtenerUserEstructuraInfo } from '../../utils/userUtils';
import {
  getFormadorPorId,
  postFormador,
  putFormador,
} from '../../services/Api/Formadores';

const INITIAL_VALUES_FORMADOR = {
  nombre: '',
  primerApellido: '',
  segundoApellido: '',
  email: '',
  telefono: '',
  esActivo: true,
};

const NOT_REQUIRED_FIELDS = ['telefono'];

const FormadorDetalles = ({ editar, ver }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, activeRole } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const obtenerFormador = async () => {
    try {
      let formadorRes = await getFormadorPorId(params.formadorId);
      setValues({
        nombre: formadorRes.nombre,
        primerApellido: formadorRes.primerApellido,
        segundoApellido: formadorRes.segundoApellido,
        email: formadorRes.correo,
        telefono: formadorRes.telefono,
        esActivo: formadorRes.esActivo,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el formador', alertConfiguration);
    }
  };

  const formadorSubmit = async () => {
    try {
      let body = {
        nombre: values.nombre,
        primerApellido: values.primerApellido,
        segundoApellido: values.segundoApellido,
        correo: values.email,
        telefono: values.telefono,
        esActivo: values.esActivo,
      };
      if (editar) {
        await putFormador(params.formadorId, body);
        toast.success('Formador guardado correctamente', alertConfiguration);
        navigate(-1);
        return;
      }
      const { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(
        user,
        activeRole
      );
      if (tipoEstructuraRequest === 'editorial') {
        body.editorial = estructuraId;
      } else if (tipoEstructuraRequest === 'plantel') {
        body.escuela = estructuraId;
      }
      await postFormador(body);
      toast.success('Formador guardado correctamente', alertConfiguration);
      navigate(-1);
    } catch (error) {
      console.error(error);
      toast.error('Error al guardar el formador', alertConfiguration);
    }
  };

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    removeError,
    areRequiredFieldsFulfilled,    
  } = useValidateForm(INITIAL_VALUES_FORMADOR);

  const [formStatus, handleSubmit] = useFormSubmit(formadorSubmit);

  useEffect(() => {
    if(values.telefono === '') {
      removeError('telefono');
    }
    if (!areRequiredFieldsFulfilled(NOT_REQUIRED_FIELDS) || Object.keys(errors).length > 0) {
      setIsEnableSubmitButton(false);
    } else {
      setIsEnableSubmitButton(true);
    }
  }, [values]);

  useEffect(() => {
    if (editar || ver) {
      obtenerFormador();
    } else {
      setLoading(false);
    }
  }, [activeRole, params]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderFormadorDetalles ver={ver} editar={editar} />
          <div className='flex flex-col flex-1 justify-around px-4 sm:px-20 mt-8 md:mt-0'>
            <AgregarFormadorForm
              formador={values}
              errors={errors}
              handleChange={handleChange}
              setFormador={setValues}
              ver={ver}
              editar={editar}
            />
            <div className='flex justify-center gap-3 mt-8 md:mt-0'>
              <CancelButton
                text={ver ? 'Regresar' : 'Cancelar'}
                styles='!w-[12rem]'
                onClick={() => navigate(-1)}
              />
              {!ver && (
                <SubmitButton
                  text={'Guardar'}
                  styles='!w-[12rem]'
                  disabled={!isEnableSubmitButton}
                  loadingButton
                  loading={formStatus === 'submitting'}
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FormadorDetalles;
