import React, { useRef } from "react";
import useOutsideClick from "../../utils/OutsideClick";
const GeneralDropdown = ({
  open,
  children,
  toggleDropdown,
  dropdownStyles,
}) => {
  const ref = useRef();
  useOutsideClick(ref, () => {
    if (open) toggleDropdown(false);
  });

  return (
    <>
      {open && (
        <div
          className={`${dropdownStyles}`}
          ref={ref}
        >
          <>{children}</>
        </div>
      )}
    </>
  );
};

GeneralDropdown.defaultProps = {
  dropdownStyles: "",
};

export default GeneralDropdown;
