import { useNavigate } from "react-router-dom";
import { ReactComponent as EstructuraIcon } from "../../../../assets/icons/empresaList.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { useMemo, useState } from "react";
import DatosCuentaModal from "./DatosCuentaModal";
import ConfiguracionPersonalizadaModal from "./ConfiguracionPersonalizadaModal";
import ComisionUsoPlataformaModal from "./ComisionUsoPlataformaModal";

const SwitchButton = ({ active = false, openModal }) => {
  const handleActive = (e) => {
    e.preventDefault();
    openModal(true);
  };

  return (
    <div className="mt-2">
      <label className="inline-flex relative items-center cursor-pointer" onClick={handleActive}>
        <input type="checkbox" name="switchUMA2" className="sr-only peer" checked={!active} onChange={() => {}} />
        <div
          className={`rotate-180 w-12 h-6 bg-cyan-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-zinc-400 after:content-[''] after:absolute after:top-0 after:left-0 after:bg-white after:border-cyan-400 after:border-2 after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-zinc-400`}
        ></div>
      </label>
    </div>
  );
};

export default function CuentasComercialesRow({ cuentaComercial, comisionDeUsoGeneral, obtenerCuentasComerciales }) {
  const [configuracionPersonalizadaModal, setConfiguracionPersonalizadaModal] = useState(false);
  const [comisionUsoModal, setComisionUsoModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { estructura, tipoEstructura, tipoEstructuraRequest } = useMemo(() => {
    return cuentaComercial.editorial
      ? { estructura: cuentaComercial.editorial, tipoEstructura: "Casa editorial", tipoEstructuraRequest: "editorial" }
      : cuentaComercial.distribucion
      ? {
          estructura: cuentaComercial.distribucion,
          tipoEstructura: "Distribución",
          tipoEstructuraRequest: "distribucion",
        }
      : cuentaComercial.corporativo
      ? { estructura: cuentaComercial.corporativo, tipoEstructura: "Corporativo", tipoEstructuraRequest: "corporativo" }
      : { estructura: cuentaComercial.plantel, tipoEstructura: "Plantel", tipoEstructuraRequest: "plantel" };
  }, [cuentaComercial]);

  const { comisionDeUso, configPersonalizada } = estructura;

  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-2 rounded-l-md border-r-0 text-left font-sans">
          <EstructuraIcon className="mx-auto" />
        </td>
        <td className="border-y-2 border-x-0 text-left font-sans">
          <p>{estructura.nombre}</p>
        </td>
        <td className="border-y-2 text-left font-sans">
          <p>{tipoEstructura}</p>
        </td>
        <td className="border-y-2 text-left font-sans">
          <span className="flex space-x-6 justify-center">
            <p>{configPersonalizada ? comisionDeUso : comisionDeUsoGeneral}</p>
            <EditIcon
              title="Editar editorial"
              className={`${configPersonalizada ? "visible" : "invisible"} cursor-pointer`}
              onClick={() => setComisionUsoModal(true)}
            />
          </span>
        </td>
        <td className="border-y-2 font-sans text-center">
          <SwitchButton active={configPersonalizada} openModal={setConfiguracionPersonalizadaModal} />
        </td>
        <td className="border-2 rounded-r-md border-l-0 flex justify-evenly items-center py-6 md:py-3 space-x-2 min-w-[5rem]">
          <ViewIcon title="Ver cuenta comercial" className="cursor-pointer" onClick={() => setIsOpen(true)} />
        </td>
      </tr>
      <DatosCuentaModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        estructura={estructura}
        cuentaComercial={cuentaComercial}
      />
      <ConfiguracionPersonalizadaModal
        isOpen={configuracionPersonalizadaModal}
        setIsOpen={setConfiguracionPersonalizadaModal}
        configuracionPersonalizada={configPersonalizada}
        tipoEstructura={tipoEstructuraRequest}
        estructura={estructura}
        obtenerCuentasComerciales={obtenerCuentasComerciales}
      />
      <ComisionUsoPlataformaModal
        isOpen={comisionUsoModal}
        setIsOpen={setComisionUsoModal}
        estructura={estructura}
        comisionDeUso={comisionDeUso}
        tipoEstructura={tipoEstructuraRequest}
        obtenerCuentasComerciales={obtenerCuentasComerciales}
      />
    </>
  );
}
