import { useState, useEffect } from "react";
import InputBuscar from "../../../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../../../fragments/Inputs/InputSelect";
import { TIPOS_DE_CATEGORIA, TIPOS_DE_PRODUCTO, TIPOS_ESTATUS, TIPOS_ORIGEN } from "../../../../../utils/productoUtils";

const FILTRO_PRODUCTOS_INITIAL_VALUES = {
  busqueda: "",
  origen: "",
  tipoProducto: "",
  estatus: "",
  categoria: "",
};

export default function ProductosDisponiblesFilter({
  productos,
  productosFiltro,
  setProductosFiltro,
  seleccionarTodosLosProductos,
  handleAgregarTodosLosProductos,
}) {
  const [filtro, setFiltro] = useState(FILTRO_PRODUCTOS_INITIAL_VALUES);
  const [checked, setChecked] = useState(false);
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [productosSeleccionados, setProductosSeleccionados] = useState(0)
  const handleChecked = () => {
    setChecked((state) => !state);
    seleccionarTodosLosProductos(!checked);
  };

  const filterDataBusqueda = (productos, busqueda) => {
    if (busqueda === "") return productos;

    const newProductosFiltro = productos.filter(
      (producto) =>
        producto.producto.nombre.toLowerCase().includes(busqueda) ||
        producto.producto._id.toLowerCase().startsWith(busqueda) ||
        producto.precioVenta.toString().includes(busqueda)
    );

    return newProductosFiltro;
  };

  const filterSelectOrigen = (origen) => {
    if (origen === "") return productos;
    const newProductosFiltro = productos.filter((producto) => producto.origen === origen);

    return newProductosFiltro;
  };

  const filterSelectTipoProducto = (productos, tipoProducto) => {
    if (tipoProducto === "") return productos;
    const newProductosFiltro = productos.filter((producto) => producto.producto.tipoProducto === tipoProducto);

    return newProductosFiltro;
  };

  const filterSelectEstatus = (productos, estatus) => {
    if (estatus === "") return productos;
    estatus = estatus === "true";
    const newProductosFiltro = productos.filter((producto) => producto.producto.estatus === estatus);

    return newProductosFiltro;
  };

  const filterSelectCategoria = (productos, categoria) => {
    if (categoria === "") return productos;
    const newProductosFiltro = productos.filter((producto) => producto.producto.categoria === categoria);

    return newProductosFiltro;
  };

  useEffect(() => {
    let newProductosFiltro = filterSelectOrigen(filtro.origen);
    newProductosFiltro = filterSelectTipoProducto(newProductosFiltro, filtro.tipoProducto);
    newProductosFiltro = filterSelectEstatus(newProductosFiltro, filtro.estatus);
    newProductosFiltro = filterSelectCategoria(newProductosFiltro, filtro.categoria);
    newProductosFiltro = filterDataBusqueda(newProductosFiltro, filtro.busqueda);
    setProductosFiltro(newProductosFiltro);
    const enableButton = productos.some((producto) => producto.esSeleccionado);
    const nuevosArticulosSeleccionados = productos.filter((producto) => producto.esSeleccionado);
    setProductosSeleccionados(nuevosArticulosSeleccionados);

    setHabilitarBoton(enableButton);
  }, [filtro, productos]);

  return (
    <div>
      <div className="w-full py-6 flex flex-col space-y-5">
        <div className="flex flex-col items-end lg:flex-row lg:space-x-3 lg:justify-between lg:items-start">
          <div className="flex flex-col items-end md:grid md:grid-cols-3 gap-3 w-full lg:w-fit">
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Tipo de producto"
                options={TIPOS_DE_PRODUCTO}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full"
                onChange={(e) => setFiltro({ ...filtro, tipoProducto: e.target.value })}
              />
            </div>
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Estatus"
                options={TIPOS_ESTATUS}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full"
                onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
              />
            </div>
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Categoría"
                options={TIPOS_DE_CATEGORIA}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full"
                onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
              />
            </div>
          </div>
          <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 md:w-1/2 lg:w-full flex-1">
            <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value.toLowerCase() })} />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-5 justify-between">
        <div className="flex flex-wrap items-center w-64">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {productosFiltro.length}
          </p>
          <p className="text-black">Productos disponibles</p>
        </div>
        <div className="flex flex-col lg:flex-row gap-3 w-full lg:w-[40rem] xl:w-[54rem] rounded-md border-[1px] border-gray-300 p-3 self-end">
          <div className="relative rounded-md border-[1px] border-gray-300 flex gap-3 items-center px-4 py-6 md:py-4 xl:py-2 flex-1">
            <input
              type="checkbox"
              name={`selectProductos`}
              id={`selectProductos`}
              className="rounded-md border-2 border-gray-300  my-0"
              checked={checked}
              onChange={handleChecked}
            />
            <label
              htmlFor={`selectProductos`}
              className="cursor-pointer select-none absolute w-full h-full flex gap-6 items-center -translate-x-4 pl-12"
            >
              <p className="text-sm xl:text-base text-left">
                Seleccionar todos los productos que se muestran en pantalla
              </p>
              <p className="text-gray-400 text-sm">{productosSeleccionados.length} productos seleccionados</p>
            </label>
          </div>
          <button
            className={`flex px-2 gap-x-2 items-center justify-center bg-nimbusDark text-white p-2 rounded w-full lg:w-fit self-end sm:self-center ${
              habilitarBoton ? "opacity-100" : "opacity-90"
            }`}
            disabled={!habilitarBoton}
            onClick={handleAgregarTodosLosProductos}
          >
            <p>Añadir productos</p>
          </button>
        </div>
      </div>
    </div>
  );
}
