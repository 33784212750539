import React, { useCallback, useEffect, useState } from "react";
import VariacionesTable from "./fragments/VariacionesTable";
import AddBlueIcon from "../../../../assets/icons/addblue.svg";
import { getVariacionesProducto } from "../../../services/Api/MarketPlace/Productos/Variaciones";
import { useParams } from "react-router-dom";
import NoDataMessage from "../../../fragments/NoDataMessage";
import { MESSAGES } from "../../../utils/messagesUtils";

const VariacionesProductoForm = ({ variaciones, setVariaciones, ver = false, variables }) => {
  const params = useParams();

  const handleAddVariacion = () => {
    const variacionId = Math.floor(Math.random() * 1000);
    let nuevaVariacion = {
      variacionId: variacionId,
    };
    nuevaVariacion.seleccionValores = variables.map((variable) => ({
      valor: {
        _id: "",
        valor: "",
      },
      variable: {
        _id: variable._id,
      },
    }));

    let nuevasVariaciones = [...variaciones, nuevaVariacion];

    setVariaciones(nuevasVariaciones);
  };

  const handleActualizarValorVariable = (e, variacionId, variableId) => {
    let nuevoValorId = e.target.value;
    let nuevoValorNombre = variables
      .find((variable) => variable._id === variableId)
      .valores.find((valor) => valor._id === nuevoValorId).valor;

    let nuevasVariaciones = variaciones.map((variacion) => {
      if (variacion.variacionId === variacionId) {
        variacion.seleccionValores = variacion.seleccionValores.map((variacionSeleccionada) =>
          variacionSeleccionada.variable._id === variableId
            ? {
                ...variacionSeleccionada,
                valor: {
                  ...variacionSeleccionada.valor,
                  _id: nuevoValorId,
                  valor: nuevoValorNombre,
                },
              }
            : variacionSeleccionada
        );
      }
      return variacion;
    });

    setVariaciones(nuevasVariaciones);
  };

  const obtenerVariaciones = async (variacionAExcluir = "") => {
    try {
      let variacionesResponse = await getVariacionesProducto(params.productoId);
      variacionesResponse = variacionesResponse.map((variacion) => ({ ...variacion, variacionId: variacion._id }));
      let variacionesSinCrear = variaciones.filter(
        (variacion) => variacion._id === undefined && variacion.variacionId !== variacionAExcluir
      );
      let nuevasVariaciones = [...variacionesResponse, ...variacionesSinCrear];
      setVariaciones(nuevasVariaciones);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteVariacion = (variacionId) => {
    let nuevasVariaciones = variaciones.filter((variacion) => variacion.variacionId !== variacionId);
    setVariaciones(nuevasVariaciones);
  };

  return (
    <div className="mx-0 lg:mx-24">
      {!ver && (
        <p>Debe agregar las variaciones existentes del producto con base a los atributos y sus valores agregados.</p>
      )}
      <VariacionesTable
        variaciones={variaciones}
        variables={variables}
        handleDeleteVariacion={handleDeleteVariacion}
        handleActualizarValorVariable={handleActualizarValorVariable}
        obtenerVariaciones={obtenerVariaciones}
        ver={ver}
      />
      {variaciones.length === 0 && <NoDataMessage message={MESSAGES.MESSAGES_VARIACIONES_PRODUCTO} />}
      {!ver && (
        <button
          className="h-20 w-full text-nimbusDark text-lg bg-white rounded-lg border-2 flex items-center justify-center my-8"
          onClick={handleAddVariacion}
        >
          <img src={AddBlueIcon} className="mr-4 h-5" alt="" />
          Agregar variación
        </button>
      )}
    </div>
  );
};

export default VariacionesProductoForm;
