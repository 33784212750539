import React, { useContext, useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../components/fragments/NotFound";
import { PaqueteProductosProvider } from "../../../contexts/PaqueteProductosContext";
import Tienda from "../../../components/comercial/Tienda/Tienda";
import TiendaMenu from "../../../components/comercial/Tienda/TiendaMenu";
import { UserContext } from "../../../components/services/user";
import { obtenerUserEstructuraInfo } from "../../../components/utils/userUtils";
import { useDispatch } from "react-redux";
import { fetchFavouritesAsync, fetchMarketplaceAsync } from "../../../reducers/marketplace/marketplaceAction";
import ProductoDetalle from "../../../components/comercial/Tienda/ProductoDetalle.js/ProductoDetalle";
import ProcesoCompra from "../../../components/comercial/Tienda/ProcesoDeCompra/ProcesoCompra";
import CheckoutReturn from "../../../components/comercial/Tienda/Checkout/CheckoutReturn";

const TiendaRoutes = () => {

  const { user, activeRole } = useContext(UserContext);
  const dispatch = useDispatch()

  useEffect(() => {
    if(activeRole !== ""){
      const { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
      dispatch(fetchMarketplaceAsync(tipoEstructuraRequest, estructuraId))
      dispatch(fetchFavouritesAsync())
    }
  }, [activeRole])

  return (
    <PaqueteProductosProvider>
      <Routes>
        <Route path="/" element={<TiendaMenu />}>
          <Route index path="/carritoDeCompra" element={<ProcesoCompra />} />
          <Route index path="/carritoDeCompra/return" element={<CheckoutReturn />} />
          <Route index path="/:categoria" element={<Tienda />} />
          <Route index path="/:categoria/:tipoDeProducto/:articuloId" element={<ProductoDetalle />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </PaqueteProductosProvider>
  );
};

export default TiendaRoutes;
