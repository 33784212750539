export default function getHeaderTitle(pathname) {
    let paths = pathname.split('/')
    paths.shift()
    if(paths.includes('home')) return 'Nimbus'
    else if(paths.includes('escolar')) return 'Escolar'
    else if(paths.includes('constructor')) return 'Constructor'
    else if(paths.includes('MarketPlace')) return 'Comercial'
    else if(paths.includes('Gestion')) return 'Comercial'
    else if (paths.includes('visor')) return 'Recursos'
    return
}