import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { formatCurrency } from "../../../utils/productoUtils";
import { ReactComponent as ProductoIcon } from "../../../../assets/icons/productoIcon.svg";
import { ReactComponent as ProductoEditorialIcon } from "../../../../assets/icons/productoEditorialIcon.svg";
import { ReactComponent as ProductoDistribucionIcon } from "../../../../assets/icons/productoDistribucionIcon.svg";
import { ReactComponent as ProductoCorporativoIcon } from "../../../../assets/icons/productoCorporativoIcon.svg";
import { ReactComponent as ProductoPlantelIcon } from "../../../../assets/icons/productoPlantelIcon.svg";
import { ReactComponent as PaqueteIcon } from "../../../../assets/icons/paqueteMarketplace.svg";
import { ReactComponent as PaqueteEditorialIcon } from "../../../../assets/icons/paqueteEditorialMarketplace.svg";
import { ReactComponent as PaqueteDistribucionIcon } from "../../../../assets/icons/paqueteDistribucionMarketplace.svg";
import { ReactComponent as PaqueteCorporativoIcon } from "../../../../assets/icons/paqueteCorporativoMarketplace.svg";
import { ReactComponent as PaquetePlantelIcon } from "../../../../assets/icons/paquetePlantelMarketplace.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/icons/remove.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { UserContext } from "../../../services/user";
import EliminarArticuloAsignadoModal from "./EliminarArticuloAsignadoModal";
import EditarPrecioAsignacionModal from "./EditarPrecioAsignacionModal";
import { convertirMoneda } from "../../../utils/conversionMonedasUtils";
import { PaisesContext } from "../../../../contexts/PaisesContext";
import { obtenerEstructuraYRelaciones } from "../../../services/Api/Autorizacion";

const ICON_PRODUCTOS_COLOR = {
  "Casa editorial": ProductoEditorialIcon,
  Distribución: ProductoDistribucionIcon,
  Corporativo: ProductoCorporativoIcon,
  Plantel: ProductoPlantelIcon,
};

const ICON_PAQUETES_COLOR = {
  "Casa editorial": PaqueteEditorialIcon,
  Distribución: PaqueteDistribucionIcon,
  Corporativo: PaqueteCorporativoIcon,
  Plantel: PaquetePlantelIcon,
};

const AsignarProductosRow = ({ articuloInfo, accionesMasivasShow, seleccionarArticulo, tipoDeMoneda }) => {
  const { articuloListaId, costo, precioVenta, precioAsignacion, articulo, ganancia, esPaquete, esSeleccionado, provieneDe } =
    articuloInfo;

  const { nombre, estatus, categoria, nivelAcademico, gradoAcademico } = articulo;

  const [eliminarArticuloAsignadoModal, setEliminarArticuloAsignadoModal] = useState(false);
  const [editarPrecioAsignadoModal, setEditarPrecioAsignadoModal] = useState(false);
  const [conversionMonedas, setConversionMonedas] = useState({});
  const { user, activeRole } = useContext(UserContext);
  const { obtenerPaises, paises, estructuras } = useContext(PaisesContext);
  
  const handleSeleccionarArticulo = () => {
    seleccionarArticulo(articuloInfo);
  };

  const nuevaGanancia = useMemo(() => {
    let gananciaRedondeo = precioAsignacion - convertirMoneda(costo, conversionMonedas);
    gananciaRedondeo = gananciaRedondeo.toFixed(2);
    return gananciaRedondeo === "-0.00" ? "0.00" : gananciaRedondeo;
  }, [precioAsignacion, costo, conversionMonedas]);
  
  const obtenerConversion = useCallback(async () => {
    if(provieneDe){
      const { tipoEstructuraRequest } = obtenerUserEstructuraInfo(user, activeRole);
      const { editorial, distribucion, dirFiscalPais } = estructuras;
      const paisOrigen = distribucion ? distribucion.dirFiscalPais : editorial.dirFiscalPais;
      const conversionBody = {
        paisOrigenId: paisOrigen,
        paisDestinoId: dirFiscalPais,
        paises: paises,
        estructura: tipoEstructuraRequest,
      }
      setConversionMonedas(conversionBody);
    }
  }, [activeRole])

  useEffect(() =>{
    if(activeRole !== ""){
      obtenerConversion();
    }
  },[activeRole, articuloInfo])

  const IconEstructura = useMemo(() => {
    if (activeRole === "") return esPaquete ? PaqueteIcon : ProductoIcon;
    if ( articuloInfo.origen === "Linea") {
      const iconoProductoLinea = activeRole === "DISTRIBUIDOR"
      ? "Casa editorial"
      : activeRole === "DIR_CORPORATIVO"
      ? "Distribución"
      : activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO"
      ? "Corporativo"
      : "Plantel";
      
      return esPaquete ? ICON_PAQUETES_COLOR[iconoProductoLinea] :  ICON_PRODUCTOS_COLOR[iconoProductoLinea] 
    }

    const { tipoEstructura } = obtenerUserEstructuraInfo(user, activeRole);
    return esPaquete ? ICON_PAQUETES_COLOR[tipoEstructura] : ICON_PRODUCTOS_COLOR[tipoEstructura];
  }, [activeRole]);
  
  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-2 rounded-l-lg border-r-0 text-left font-sans">
          {(accionesMasivasShow || activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO") && (
            <input
              type="checkbox"
              name="selectProducto"
              id="selectProducto"
              className="rounded-md border-2 border-gray-300 cursor-pointer ml-4 mr-2"
              checked={esSeleccionado ?? false}
              onChange={handleSeleccionarArticulo}
            />
          )}
        </td>
        <td className="border-y-2 font-sans text-left">
          <IconEstructura className="mx-auto" />
        </td>
        <td className="border-y-2 font-sans text-left">{nombre}</td>
        <td className="border-y-2 font-sans text-left">{categoria === 'otro' ? 'Otros' : categoria}</td>
        {activeRole !== "DIR_PLANTEL" && activeRole !== "ADMIN_OPERATIVO" ? (
          <td className="border-y-2 font-sans text-right pr-4">{formatCurrency(convertirMoneda(costo, conversionMonedas))}</td>
        ) : (
          <>
            <td className="border-y-2 font-sans text-left">{nivelAcademico.nombre}</td>
            <td className="border-y-2 font-sans text-left">{gradoAcademico.nombre}</td>
          </>
        )}
        <td className="border-y-2 font-sans text-right pr-4">{formatCurrency(precioVenta)}</td>
        {activeRole !== "DIR_PLANTEL" && activeRole !== "ADMIN_OPERATIVO" && (
          <>
            <td className="border-y-2 font-sans text-right pr-4">{formatCurrency(precioAsignacion)}</td>

            <td
              className={`border-y-2 font-sans text-right pr-4 ${
                nuevaGanancia < 0 ? "text-red-600" : nuevaGanancia > 0 ? "text-green-600" : "text-inherit"
              }`}
            >
              {formatCurrency(nuevaGanancia)}
            </td>
          </>
        )}
        <td className="border-y-2 font-sans text-left">{estatus ? "Disponible" : "Agotado"}</td>
        <td className="border-2 rounded-r-md border-l-0 min-h-[52px]">
          <div className="flex items-center justify-evenly space-x-2 px-2">
            {activeRole !== "DIR_PLANTEL" && activeRole !== "ADMIN_OPERATIVO" && (
              <EditIcon
                title="Editar precio de asignación"
                className="cursor-pointer"
                onClick={() => setEditarPrecioAsignadoModal(true)}
              />
            )}
            <RemoveIcon
              title="Eliminar artículo"
              className="cursor-pointer h-5"
              onClick={() => setEliminarArticuloAsignadoModal(true)}
            />
          </div>
        </td>
      </tr>
      <EditarPrecioAsignacionModal
        isOpen={editarPrecioAsignadoModal}
        setIsOpen={setEditarPrecioAsignadoModal}
        articuloInfo={articuloInfo}
        tipoDeMoneda={tipoDeMoneda}
        conversionMonedas={conversionMonedas}
      />
      <EliminarArticuloAsignadoModal
        isOpen={eliminarArticuloAsignadoModal}
        setIsOpen={setEliminarArticuloAsignadoModal}
        articuloId={articuloListaId}
      />
    </>
  );
};

export default AsignarProductosRow;
