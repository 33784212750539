import React from "react";
import ProductosDisponiblesRow from "./ProductosDisponiblesRow";

const ProductosDisponiblesTable = ({ productos, seleccionarProducto, setIsEnableSubmitButton }) => {
  return (
    <div className="overflow-x-auto mt-6">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            <th>Nombre del producto</th>
            <th>Categoria</th>
            <th>Tipo de producto</th>
            <th>Origen</th>
            <th>Estatus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto) => (
            <ProductosDisponiblesRow
              key={producto._id}
              productoInfo={producto}
              seleccionarProducto={seleccionarProducto}
              setIsEnableSubmitButton={setIsEnableSubmitButton}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductosDisponiblesTable;
