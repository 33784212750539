import React from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../../fragments/SubHeader";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../fragments/BreadCrumb";

const SubHeaderProductoForm = ({ editar, ver }) => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Productos /"} onClick={() => navigate(`/marketplace/gestionProductos/productos`)} />
        <BreadCrumb route={`${editar ? "Editar" : ver ? "Ver" : "Agregar"} producto`} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderProductoForm;
