import React, { useCallback, useEffect, useState } from "react";
import { TIPOS_DE_CATEGORIA, TIPOS_DE_PRODUCTO, TIPOS_ESTATUS, TIPOS_ORIGEN } from "../../../utils/productoUtils";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import { getNivelAcademico, getNivelesAcademico } from "../../../services/api";

const FILTRO_ARTICULOS_INITIAL_VALUES = {
  busqueda: "",
  origen: "",
  estatus: "",
  categoria: "",
  nivelEducativo: "",
  gradoAcademico: "",
};

const CATEGORIAS_FILTRO = [
  ...TIPOS_DE_CATEGORIA,
  {
    _id: "Paquetes",
    nombre: "Paquetes",
  },
];

const ArticulosDisponiblesFilter = ({
  articulos,
  articulosFiltro,
  setArticulosFiltro,
  seleccionarTodosLosArticulos,
  handleAgregarTodosLosArticulos,
}) => {
  const [filtro, setFiltro] = useState(FILTRO_ARTICULOS_INITIAL_VALUES);
  const [checked, setChecked] = useState(false);
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [nivelesEducativos, setNivelesEducativos] = useState([]);
  const [gradosAcademicos, setGradosAcademicos] = useState([]);
  const [articulosSeleccionados, setArticulosSeleccionados] = useState(0);

  const handleChecked = () => {
    setChecked((state) => !state);
    seleccionarTodosLosArticulos(!checked);
  };

  const filterDataBusqueda = (articulos, busqueda) => {
    if (busqueda === "") return articulos;

    const newArticulosFiltro = articulos.filter(
      (articulo) =>
        articulo.producto?.nombre.toLowerCase().includes(busqueda) ||
        articulo.paquete?.nombre.toLowerCase().includes(busqueda) ||
        articulo.producto?._id.toLowerCase().startsWith(busqueda) ||
        articulo.paquete?._id.toLowerCase().startsWith(busqueda) ||
        articulo.precioVenta.toString().includes(busqueda)
    );

    return newArticulosFiltro;
  };

  const filterSelectOrigen = (origen) => {
    if (origen === "") return articulos;
    const newArticulosFiltro = articulos.filter((articulo) => articulo.origen === origen);

    return newArticulosFiltro;
  };

  const filterSelectNivelEducativo = (articulos, nivelEducativo) => {
    if (nivelEducativo === "") return articulos;
    const newArticulosFiltro = articulos.filter(
      (articulo) =>
        articulo.producto?.nivelAcademico?._id === nivelEducativo ||
        articulo.paquete?.nivelAcademico?._id === nivelEducativo
    );

    return newArticulosFiltro;
  };

  const filterSelectGradoAcademico = (articulos, gradoAcademico) => {
    if (gradoAcademico === "") return articulos;
    const newArticulosFiltro = articulos.filter(
      (articulo) =>
        articulo.producto?.gradoAcademico?._id === gradoAcademico ||
        articulo.paquete?.gradoAcademico?._id === gradoAcademico
    );

    return newArticulosFiltro;
  };

  const filterSelectEstatus = (articulos, estatus) => {
    if (estatus === "") return articulos;
    estatus = estatus === "true";
    const newArticulosFiltro = articulos.filter(
      (articulo) => articulo.producto?.estatus === estatus || articulo.paquete?.estatus === estatus
    );

    return newArticulosFiltro;
  };

  const filterSelectCategoria = (articulos, categoria) => {
    if (categoria === "") return articulos;
    const newArticulosFiltro = articulos.filter(
      (articulo) => articulo.producto?.categoria === categoria || (articulo.paquete && categoria === "Paquetes")
    );

    return newArticulosFiltro;
  };

  const getNivelesEducativos = useCallback(async () => {
    const nivelesAcademicosResponse = await getNivelesAcademico();
    setNivelesEducativos(nivelesAcademicosResponse.data);
  }, []);

  useEffect(() => {
    let newArticulosFiltro = filterSelectOrigen(filtro.origen);
    newArticulosFiltro = filterSelectNivelEducativo(newArticulosFiltro, filtro.nivelEducativo);
    newArticulosFiltro = filterSelectGradoAcademico(newArticulosFiltro, filtro.gradoAcademico);
    newArticulosFiltro = filterSelectEstatus(newArticulosFiltro, filtro.estatus);
    newArticulosFiltro = filterSelectCategoria(newArticulosFiltro, filtro.categoria);
    newArticulosFiltro = filterDataBusqueda(newArticulosFiltro, filtro.busqueda.toLowerCase());
    setArticulosFiltro(newArticulosFiltro);
    const enableButton = articulos.some((producto) => producto.esSeleccionado);
    const nuevosArticulosSeleccionados = articulos.filter((articulo) => articulo.esSeleccionado);
    setArticulosSeleccionados(nuevosArticulosSeleccionados);
    setHabilitarBoton(enableButton);
  }, [filtro, articulos]);

  useEffect(() => {
    if (filtro.nivelEducativo !== "") {
      getNivelAcademico(filtro.nivelEducativo).then((valores) => {
        setGradosAcademicos(valores.data.gradosAcademicos);
      });
    } else {
      setGradosAcademicos([]);
    }
    setFiltro({ ...filtro, gradoAcademico: "" });
  }, [filtro.nivelEducativo]);

  useEffect(() => {
    getNivelesEducativos();
  }, [getNivelesEducativos]);

  return (
    <div>
      <div className="w-full py-6 flex flex-col space-y-5">
        <div className="flex flex-col items-end lg:flex-row lg:space-x-3 lg:justify-between lg:items-start">
          <div className="flex flex-col items-end md:grid md:grid-cols-3 xl:grid-cols-5 gap-3 w-full lg:w-fit">
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Categoría"
                options={CATEGORIAS_FILTRO}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full lg:!w-48"
                onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
              />
            </div>
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Origen"
                options={TIPOS_ORIGEN}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full lg:!w-48"
                onChange={(e) => setFiltro({ ...filtro, origen: e.target.value })}
              />
            </div>
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Estatus"
                options={TIPOS_ESTATUS}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full lg:!w-48"
                onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
              />
            </div>
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Nivel educativo"
                options={nivelesEducativos}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full lg:!w-48"
                onChange={(e) => setFiltro({ ...filtro, nivelEducativo: e.target.value })}
              />
            </div>
            <div className="w-full sm:flex justify-end md:block">
              <InputSelect
                label="Grado académico"
                options={gradosAcademicos}
                topLabel={true}
                styles="!w-full sm:!w-80 md:!w-full lg:!w-48"
                onChange={(e) => setFiltro({ ...filtro, gradoAcademico: e.target.value })}
              />
            </div>
          </div>
          <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 md:w-1/2 lg:w-fit">
            <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} value={filtro.busqueda}/>
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:flex-row gap-5 justify-end">
        <div className="flex flex-wrap items-center w-64">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {articulosFiltro.length}
          </p>
          <p className="text-black">Artículos disponibles</p>
        </div>
        <div className="flex flex-col lg:flex-row gap-3 w-full xl:w-5/6 rounded-md border-[1px] border-gray-300 p-3 self-end">
          <div className="relative rounded-md border-[1px] border-gray-300 flex gap-3 items-center px-4 py-6 md:py-2 flex-1">
            <input
              type="checkbox"
              name={`selectProductos`}
              id={`selectProductos`}
              className="rounded-md border-2 border-gray-300  my-0"
              checked={checked}
              onChange={handleChecked}
            />
            <label
              htmlFor={`selectProductos`}
              className="cursor-pointer select-none absolute w-full h-full flex gap-6 items-center -translate-x-4 pl-12"
            >
              <p className="text-sm md:text-base text-left">
                Seleccionar todos los artículos que se muestran en pantalla
              </p>
              <p className="text-gray-400 text-sm">{articulosSeleccionados.length} artículos seleccionados</p>
            </label>
          </div>
          <button
            className={`flex px-2 gap-x-2 items-center justify-center bg-nimbusDark text-white p-2 rounded w-full lg:w-fit self-end sm:self-center ${
              habilitarBoton ? "opacity-100" : "opacity-90"
            }`}
            disabled={!habilitarBoton}
            onClick={handleAgregarTodosLosArticulos}
          >
            <p>Añadir artículos</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArticulosDisponiblesFilter;
