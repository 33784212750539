const ListaDifusionDescription = () => {
  return (
    <div className="font-semibold text-zinc-500/70 text-lg">
      Pulse en el botón de "Agregar destinatarios" para agregar destinatarios
      a la lista de difusión. Una vez que haya agregado todos los destinatarios
      requeridos, debe pulsar en guardar. Esta lista podrá ser editada posteriormente
      si así se requiere.
    </div>
  );
};

export default ListaDifusionDescription;
