import React, { useEffect, useState } from "react";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputText from "../../../fragments/Inputs/InputText";

const ConfiguracionMonedasForm = ({ conversionMonedas, setConversionMonedas, handleChange, monedas }) => {
  let { monedaNacional, monedaDeIntercambio } = conversionMonedas;

  const [abreviaturasSimbolo, setAbreviaturasSimbolo] = useState({
    monedaNacional: {
      abreviatura: "",
      simbolo: ""
    },
    monedaDeIntercambio: {
      abreviatura: "",
      simbolo: ""
    },
  });

  useEffect(() => {
    if(monedas.length !== 0 && monedaNacional && monedaDeIntercambio){
      let monedaNacionalInfo = monedas.find(moneda => moneda._id === monedaNacional)
      let monedaDeIntercambioInfo = monedas.find(moneda => moneda._id === monedaDeIntercambio)

      let newAbreviaturasSimbolos = {
        monedaNacional: {
          abreviatura: monedaNacionalInfo.abreviatura,
          simbolo: monedaNacionalInfo.simbolo 
        },
        monedaDeIntercambio: {
          abreviatura: monedaDeIntercambioInfo.abreviatura,
          simbolo: monedaDeIntercambioInfo.simbolo 
        }
      }
  
      setAbreviaturasSimbolo(newAbreviaturasSimbolos)
    }

  }, [monedaNacional, monedaDeIntercambio, monedas]);

  useEffect(() => {
    if(monedaNacional && monedaNacional === "643ffac9cda25824d0453911"){
      setConversionMonedas({...conversionMonedas, factorDeConversion: 1.00})
    }
  }, [monedaNacional])

  return (
    <div className="flex flex-col sm:grid sm:grid-cols-2 xl:grid-cols-8 gap-3 sm:gap-6 xl:gap-5 2xl:gap-6 mt-6">
      <div className="col-span-2">
        <InputSelect
          label={"Moneda nacional"}
          name={"monedaNacional"}
          placeholder={"tipo de moneda"}
          styles="!w-full"
          options={monedas}
          value={monedaNacional ?? ""}
          onChange={(e) => handleChange(e, "monedaNacional")}
          disableFirstOption
        />
      </div>
      <InputText label={"Abreviatura"} styles="!w-full" disabled value={abreviaturasSimbolo.monedaNacional.abreviatura ?? ""} />
      <InputText label={"Símbolo"} styles="!w-full" disabled value={abreviaturasSimbolo.monedaNacional.simbolo ?? ""} />
      <div className="col-span-2">
        <InputText
          label={"Moneda de intercambio internacional"}
          name={"monedaDeIntercambio"}
          styles="!w-full"
          disabled
          disabledStyles
          value={"Dólar estadounidense"}
        />
      </div>
      <InputText label={"Abreviatura"} styles="!w-full" disabled disabledStyles value={"USD"} />
      <InputText label={"Símbolo"} styles="!w-full" disabled disabledStyles value={"$"} />
    </div>
  );
};

export default ConfiguracionMonedasForm;
