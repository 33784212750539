import React, { Fragment, useContext, useEffect, useState } from 'react';
import SubHeaderFormador from './fragments/SubHeaderFormador';
import FormadorFilter from './fragments/FormadorFilter';
import { MESSAGES } from '../../utils/messagesUtils';
import TablePagination from '../../fragments/TablePagination';
import { usePagination } from '../../hooks/usePagination';
import FormadorTable from './fragments/FormadorTable';
import NoDataMessage from '../../fragments/NoDataMessage';
import { toast } from 'react-toastify';
import alertConfiguration from '../../utils/alertsUtils';
import { UserContext } from '../../services/user';
import { getFormadoresEstructura } from '../../services/Api/Formadores';
import { obtenerUserEstructuraInfo } from '../../utils/userUtils';
import LoadingScreen from '../../fragments/LoadingScreen';

const FormadorHome = () => {
  const { user, activeRole } = useContext(UserContext);
  const [formadores, setFormadores] = useState([]);
  const [formadoresFiltro, setFormadoresFiltro] = useState([]);
  const [loading, setLoading] = useState(true);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    formadoresFiltro,
    20
  );

  const obtenerFormadores = async () => {
    try {
      const { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(
        user,
        activeRole
      );
      const formadoresResponse = await getFormadoresEstructura(
        tipoEstructuraRequest,
        estructuraId
      );
      setFormadores(formadoresResponse);
      setFormadoresFiltro(formadoresResponse);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener los autores', alertConfiguration);
    }
  };

  useEffect(() => {
    if (activeRole !== '') {
      obtenerFormadores();
    }
  }, [activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderFormador />
          <div
            className={`${
              formadoresFiltro.length !== 0 && 'justify-between'
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <FormadorFilter
                formadores={formadores}
                formadoresFiltro={formadoresFiltro}
                setFormadoresFiltro={setFormadoresFiltro}
              />
              <FormadorTable formadores={currentData()} />
            </div>
            <div>
              {formadoresFiltro.length === 0 ? (
                <NoDataMessage message={MESSAGES.MESSAGES_FORMADOR_TABLE} />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FormadorHome;
