import React from 'react';
import FormadorRow from './FormadorRow';

const FormadorTable = ({ formadores }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th></th>
            <th className="text-center">Nombre completo</th>
            <th className="text-center">Correo electrónico</th>
            <th className="text-center">Teléfono</th>
            <th className="text-center">Estatus</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {formadores.map((formador) => (
            <FormadorRow key={formador._id} formador={formador} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormadorTable;
