import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { customToast } from "../../ActividadesUtils/FormularioUtils";
import { colors } from "../../Actividades/ActividadesUtils/ActividadesUtils";
import { Trans } from "react-i18next";

const responsive = {
  desktop: {
    breakpoint: { max: 10000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Test({ activityData, enunciados, instrucciones, nombre }) {
  const [answers, setAnswers] = useState([]);
  const [isFinished, setIsFinished] = useState(false);

  const selectAnswer = (ans) => {
    const newSelection = answers.filter((el) => el.parentId !== ans.parentId);
    setAnswers([...newSelection, ans]);
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <>
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full max-w-[768px]">
          {activityData.length > currentSlide ? (
            <div className="flex items-center justify-center">
              {currentSlide !== 0 && (
                <div
                  className="btn-nimbus cursor-pointer !rounded-full !mx-2 min-w-[8rem] text-center"
                  onClick={() => previous()}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-lg mr-2"
                  />
                  <Trans i18nKey={"previousLabel"}></Trans>
                </div>
              )}
              <div
                className="btn-nimbus cursor-pointer !rounded-full min-w-[8rem] text-center"
                onClick={() => {
                  if (answers.length < currentSlide + 1) {
                    customToast("questionValidationLabel");
                  } else {
                    next();
                    if (activityData.length === currentSlide + 1)
                      setIsFinished(true);
                    else setIsFinished(false);
                  }
                }}
              >
                <Trans
                  i18nKey={
                    activityData.length === currentSlide + 1
                      ? "finishLabel"
                      : "nextLabel"
                  }
                ></Trans>

                <FontAwesomeIcon icon={faArrowRight} className="text-lg ml-2" />
              </div>
            </div>
          ) : (
            <div
              className="btn-nimbus cursor-pointer w-fit mx-auto !rounded-full"
              onClick={() => {
                setIsFinished(false);
                goToSlide(0);
                setAnswers([]);
              }}
            >
              <Trans i18nKey={"tryAgainLabel"}></Trans>
              <FontAwesomeIcon icon={faRotateRight} className="text-lg ml-2" />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!isFinished && (
        <>
          <div className="text-[#5f657e] font-bold md:text-2xl text-center mt-4">
            {nombre}
          </div>
          {instrucciones && (
            <div className="act-instructions mb-5">{instrucciones}</div>
          )}
        </>
      )}
      <Carousel
        draggable={false}
        ssr
        responsive={responsive}
        keyBoardControl={true}
        infinite={false}
        className="mb-[2rem] w-full min-w-[500px] "
        customButtonGroup={<ButtonGroup />}
        arrows={false}
      >
        {activityData.map((item, index) => (
          <>
            <div className="max-w-activity mx-auto pb-[60px]" key={index}>
              <div className="rounded-md p-4 bg-white border !w-[98%] mx-auto !pb-[50px]">
                <div className="block mb-3 text-center text-sm font-bold text-[#5f657e]">
                  {" "}
                  <Trans i18nKey={"questionLabel"}></Trans> {index + 1} /{" "}
                  {activityData.length}
                </div>
                <div className="block mb-6 text-center text-xl font-bold text-[#5f657e] mx-auto border-b pb-6 border-[#ededed] w-10/12">
                  {item.texto}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {item.respuestas.map((el, i) => {
                    const isSelected = answers.find(
                      (ans) => ans._id === el._id
                    );
                    return (
                      <div
                        key={el._id}
                        onClick={() => selectAnswer(el)}
                        className={`${
                          isSelected && "bg-[aliceblue]"
                        } text-center rounded-xl p-6 hover:bg-[#f9f9f9] bg-white text-white fast-transition border-[3px]`}
                        style={{
                          boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
                          borderColor: colors[i + index] + 90,
                          color: isSelected ? "#fff" : colors[i + index],
                          background: isSelected ? colors[i + index] + 90 : "",
                        }}
                      >
                        {el?.texto}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ))}
        <div className="w-full h-full flex items-center justify-center mx-auto">
          <div className="w-full">
            <div className="text-center text-lightBlue font-bold text-2xl mt-8">
              <Trans i18nKey={"Resultados"}></Trans>:
            </div>
            <div className="text-[#5f657e] font-bold p-4 text-center mb-8">
              {isFinished && (
                <>
                  {enunciados
                    .map((el) => {
                      const timesSelected = answers.filter(
                        (answer) => answer.value === el.value
                      ).length;
                      return { ...el, timesSelected: timesSelected };
                    })
                    .sort((a, b) => {
                      if (a.timesSelected > b.timesSelected) return -1;
                      else return 1;
                    })
                    .map((el, i) => {
                      return (
                        <div
                          key={i}
                          className="p-4 bg-white rounded-md border shadow mb-2"
                        >
                          <div className="w-fit flex items-center mb-4">
                            <div className="text-left inline-block mr-3">
                              {el.title}
                            </div>
                            <div className="bg-[#1a3d81] w-fit py-[3px] px-[9px] rounded text-white text-xs inline-block whitespace-nowrap h-fit">
                              {el.timesSelected === 0 ? (
                                <Trans i18nKey={"notSelected"}></Trans>
                              ) : el.timesSelected === 1 ? (
                                <>
                                  1 <Trans i18nKey={"singleSelected"}></Trans>
                                </>
                              ) : (
                                <>
                                  {el.timesSelected}{" "}
                                  <Trans i18nKey={"multipleSelected"}></Trans>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-left">
                            <div className="text-justify">{el.texto}</div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      </Carousel>
    </>
  );
}

export default Test;
