import CuentasComercialesRow from "./CuentasComercialesRow";

export default function CuentasComercialesTable({
  cuentasComerciales,
  comisionDeUsoGeneral,
  obtenerCuentasComerciales,
}) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            <th>Nombre de la estructura</th>
            <th>Tipo de estructura</th>
            <th className="text-center">
              <p className="hidden lg:block">Comisión de uso de </p>
              <p className="hidden lg:block">la plataforma (%)</p>
              <p className="lg:hidden">Comisión de uso de la plataforma (%)</p>
            </th>
            <th className="text-center">Configuración personalizada</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cuentasComerciales.map((cuentaComercial) => (
            <CuentasComercialesRow
              key={cuentaComercial._id}
              cuentaComercial={cuentaComercial}
              comisionDeUsoGeneral={comisionDeUsoGeneral}
              obtenerCuentasComerciales={obtenerCuentasComerciales}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
