import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputText from "../../../fragments/Inputs/InputText";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as Add } from "../../../../assets/icons/addBlueSmall.svg";
import CalendarForm from "./CalendarForm";
import AvisoFormButtons from "./AvisoFormButtons";
import ContentBox from "./ContentBox";
import { useGestorAvisosDetalles } from "./GestorAvisosDetallesContext";
import {
  setNombreAviso,
  setDescripcionAviso,
  setCategoriaAviso,
  addContenido,
} from "../../../../reducers/avisoData/avisoDataAction";
import { useDispatch, useSelector } from "react-redux";
import { selectAvisoData } from "../../../../reducers/avisoData/avisoDataSelector";
import { getCategoriasNotificaciones } from "../../../services/Api/CategoriaNotificaciones";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../services/user";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import useValidateForm from "../../../hooks/useValidateForm";
import LoadingScreen from "../../../fragments/LoadingScreen";

const INITIAL_VALUES_LISTA_DIFUSION = {
  nombre: "",
  categoria: "",
  descripcion: "",
};

function AvisoForm() {
  const { user, activeRole } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const { ver, setCount, count, editandoTexto } = useGestorAvisosDetalles();

  const { handleChange, errors } = useValidateForm(
    INITIAL_VALUES_LISTA_DIFUSION
  );

  const dispatch = useDispatch();
  const { nombre, categoria, descripcion, contenido } =
    useSelector(selectAvisoData);

  const contentTemplate = {
    bloque: {
      _id: null,
    },
    orden: count,
  };

  const handleAddContent = () => {
    setCount(count + 1);
    dispatch(addContenido(contentTemplate));
  };

  useEffect(() => {
    if (activeRole !== "") {
      obtenerCategoriasNotificaciones();
    }
  }, [activeRole]);

  const obtenerCategoriasNotificaciones = async () => {
    try {
      setIsLoading(true);
      let resp = await getCategoriasNotificaciones(user._id);
      const defaultCategory = resp.find(
        (element) => element.nombre.toLowerCase() === "general"
      );

      dispatch(setCategoriaAviso(defaultCategory._id));

      setCategories(resp);
    } catch (error) {
      console.error(error);
      toast.error(
        "Error al obtener las categorias de notificaciones",
        alertConfiguration
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingScreen />;
  return (
    <div className="flex flex-col justify-around px-4 lg:px-20 xl:px-60 mt-10 md:mt-0">
      <div className="flex flex-col md:grid grid-cols-12 gap-8">
        <div className="grid col-span-8">
          <InputText
            id={"nombreAviso"}
            name={"nombreAviso"}
            label={"Título del aviso"}
            placeholder={"Introduce el título del aviso"}
            value={nombre}
            onChange={(e) => {
              handleChange(e, "nombreAviso");
              dispatch(setNombreAviso(e.target.value));
            }}
            error={errors["nombreAviso"]}
            styles="!w-full disabled:!cursor-not-allowed"
            disabled={ver || editandoTexto}
          />
        </div>
        <div className="grid col-span-4 items-start">
          <InputSelect
            id={"categoria"}
            name={"categoria"}
            label={"Categoría"}
            customLabel="Seleccione una opción"
            value={categoria}
            options={categories}
            onChange={(e) => {
              handleChange(e, "categoriaAviso");
              dispatch(setCategoriaAviso(e.target.value));
            }}
            styles="!w-full disabled:!cursor-not-allowed"
            disabled={isLoading || ver || editandoTexto}
            disableFirstOption
          />
        </div>
        <div className="grid col-span-12">
          <label
            htmlFor="descripcionAviso"
            className="font-semibold text-base text-[#0D0C22] mb-2"
          >
            Descripción del aviso
          </label>
          <TextareaAutosize
            id="descripcionAviso"
            name="descripcionAviso"
            placeholder="Introduce la descripción del aviso"
            className={`resize-none peer border-2 min-h-[8rem] p-2 focus:outline-none border-gray-300 rounded-lg w-full disabled:!cursor-not-allowed ${
              errors["descripcionAviso"] ? "border-red-300" : "border-gray-300"
            }`}
            onChange={(e) => {
              handleChange(e, "descripcionAviso");
              dispatch(setDescripcionAviso(e.target.value));
            }}
            value={descripcion}
            autoCorrect={false}
            disabled={ver || editandoTexto}
            maxLength={200}
            required={descripcion === "" ? true : false}
          />
          <span className="ml-2 text-xs text-nimbusLight font-bold">
            {descripcion && descripcion.length + " / 200"}
          </span>
          <label htmlFor="descripcion" className={`text-red-400 text-xs`}>
            {errors["descripcionAviso"]}
          </label>
        </div>
        <div className="grid col-span-12 text-nimbus gap-4">
          <p className="font-semibold text-base text-[#0D0C22] mb-2">
            Contenido
          </p>
          <ContentBox />
          {contenido.length !== 5 && (
            <button
              className="flex items-center justify-center border-2 border-gray-300 font-bold p-3 text-lg disabled:cursor-not-allowed disabled:opacity-80"
              onClick={handleAddContent}
              disabled={ver || editandoTexto}
            >
              <Add className="inline mr-4 w-5 h-5" /> Agregar contenido
            </button>
          )}
        </div>
        <CalendarForm />
        <AvisoFormButtons user={user} activeRole={activeRole} errors={errors} />
      </div>
    </div>
  );
}

export default AvisoForm;
