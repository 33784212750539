import React from 'react';
import AsignarArticulosRow from './AsignarArticulosRow';

const AsignarArticulosTable = ({ estructuras, userRol }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th className='w-20'></th>
            <th className='w-[25rem]'>
              {userRol === 'DIR_EDITORIAL'
                ? 'Nombre de la distribución'
                : userRol === 'DISTRIBUIDOR'
                ? 'Nombre del corporativo'
                : userRol === 'DIR_CORPORATIVO'
                ? 'Nombre del plantel'
                : 'Nombre del grupo'}
            </th>
            <th>{userRol === 'DIR_PLANTEL' ? 'Nivel' : 'País'}</th>
            {userRol === 'DIR_PLANTEL' && <th>Grado</th>}
            <th className='w-20'></th>
          </tr>
        </thead>
        <tbody>
          {estructuras.map((estructura) => (
            <AsignarArticulosRow key={estructura._id} estructuraInfo={estructura} userRol={userRol} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AsignarArticulosTable;
