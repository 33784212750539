import CarritoCompra from "../../../../assets/icons/carritoCompra.svg";
import Completado from "../../../../assets/icons/faseCompletada.svg";
import DatosEnvioDeshabilitado from "../../../../assets/icons/datosEnvioDeshabilitado.svg";
import DatosEnvioHabilitado from "../../../../assets/icons/datosEnvioHabilitado.svg";
import PagarDeshabilitado from "../../../../assets/icons/pagoDeshabilitado.svg";
import PagarHabilitado from "../../../../assets/icons/pagoHabilitado.svg";
import React from "react";

const BarraProgresoCompra = ({ estatus }) => {
  return (
    <div className="flex items-start justify-between relative w-full mt-6">
      <div className="w-full h-2 bg-gray-300 absolute top-[30px] sm:top-[42px] xl:top-[50px]">
        <div
          className={`h-full bg-nimbusDark ${
            estatus === "Carrito" ? "w-0" : estatus === "DatosEnvio" ? "w-1/2" : "w-full"
          }`}
        ></div>
      </div>
      <div className="flex flex-col lg:flex-row items-center relative z-[1] bg-backgroundColor">
        <div
          className={`h-[68px] w-[68px] sm:h-[92px] sm:w-[92px] xl:h-[108px] xl:w-[108px] rounded-full border-2 flex justify-center items-center ${
            estatus === "Carrito" ? "border-sidebar" : "border-nimbusDark"
          }`}
        >
          <div
            className={`h-14 w-14 sm:h-20 sm:w-20 xl:h-24 xl:w-24 rounded-full flex items-center justify-center ${
              estatus === "Carrito" ? "bg-sidebar" : "bg-nimbusDark"
            }`}
          >
            <img
              className="h-8 w-8 sm:h-12 sm:w-12 xl:h-auto xl:w-auto"
              src={estatus === "Carrito" ? CarritoCompra : Completado}
              alt=""
            />
          </div>
        </div>
        <p
          className={`mt-2 lg:mt-0 text-sm sm:text-lg xl:text-xl w-16 sm:w-28 xl:w-32 text-center ${
            estatus === "Carrito" ? "text-sidebar " : "text-nimbusDark"
          }`}
        >
          Carrito de compra
        </p>
      </div>
      <div className="flex flex-col lg:flex-row items-center relative z-[1] bg-backgroundColor lg:pl-6">
        <div
          className={`h-[68px] w-[68px] sm:h-[92px] sm:w-[92px] xl:h-[108px] xl:w-[108px] rounded-full border-2  flex justify-center items-center ${
            estatus === "Carrito"
              ? "border-gray-300"
              : estatus === "DatosEnvio"
              ? "border-sidebar"
              : "border-nimbusDark"
          }`}
        >
          <div
            className={`h-14 w-14 sm:h-20 sm:w-20 xl:h-24 xl:w-24 rounded-full flex items-center justify-center ${
              estatus === "Carrito" ? "bg-gray-300" : estatus === "DatosEnvio" ? "bg-sidebar" : "bg-nimbusDark"
            }`}
          >
            <img
              className="h-8 w-8 sm:h-12 sm:w-12 xl:h-auto xl:w-auto"
              src={
                estatus === "Carrito"
                  ? DatosEnvioDeshabilitado
                  : estatus === "DatosEnvio"
                  ? DatosEnvioHabilitado
                  : Completado
              }
              alt=""
            />
          </div>
        </div>
        <p
          className={`mt-2 lg:mt-0 text-sm sm:text-lg xl:text-xl w-16 sm:w-28 xl:w-32 text-center ${
            estatus === "Carrito" ? "text-gray-300" : estatus === "DatosEnvio" ? "text-sidebar" : "text-nimbusDark"
          }`}
        >
          Datos de envío
        </p>
      </div>
      <div className="flex flex-col lg:flex-row items-center relative z-[1] bg-backgroundColor lg:pl-6">
        <div
          className={`h-[68px] w-[68px] sm:h-[92px] sm:w-[92px] xl:h-[108px] xl:w-[108px] rounded-full border-2  flex justify-center items-center ${
            estatus === "Carrito"
              ? "border-gray-300"
              : estatus === "DatosEnvio"
              ? "border-gray-300"
              : estatus === "Pago"
              ? "border-sidebar"
              : "border-nimbusDark"
          }`}
        >
          <div
            className={`h-14 w-14 sm:h-20 sm:w-20 xl:h-24 xl:w-24 rounded-full flex items-center justify-center ${
              estatus === "Carrito"
                ? "bg-gray-300"
                : estatus === "DatosEnvio"
                ? "bg-gray-300"
                : estatus === "Pago"
                ? "bg-sidebar"
                : "bg-nimbusDark"
            }`}
          >
            <img
              className="h-8 w-8 sm:h-12 sm:w-12 xl:h-auto xl:w-auto"
              src={
                estatus === "Carrito"
                  ? PagarDeshabilitado
                  : estatus === "DatosEnvio"
                  ? PagarDeshabilitado
                  : estatus === "Pago"
                  ? PagarHabilitado
                  : Completado
              }
              alt=""
            />
          </div>
        </div>
        <p
          className={`mt-2 lg:mt-0 text-sm sm:text-lg xl:text-xl w-16 sm:w-28 xl:w-32 text-center ${
            estatus === "Carrito"
              ? "text-gray-300"
              : estatus === "DatosEnvio"
              ? "text-gray-300"
              : estatus === "Pago"
              ? "text-sidebar"
              : "text-nimbusDark"
          }`}
        >
          Pagar
        </p>
      </div>
    </div>
  );
};

export default BarraProgresoCompra;
