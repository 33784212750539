import Modal from "react-modal";
import { ReactComponent as LogoModal } from "../../assets/icons/logoModal.svg";

const ConfirmationModal = ({ isOpen, setIsOpen, handleAction, action = "", messages = [] }) => {
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "30rem",
    },
    overlay:{
      zIndex: 25
    }
  };

  const handleConfirmation = () => {
    setIsOpen(false);
    handleAction();
  };

  return (
    <Modal isOpen={isOpen} style={modalStyle}>
      <div className="grid justify-items-center gap-y-5 p-5">
        <LogoModal />
        <div>
          <h1 className="text-nimbusDark font-bold text-lg">{action}</h1>
        </div>
        <div className="flex flex-col space-y-2 items-center">
          {messages.map((message, idx) => (
            <span key={idx} className="text-center">{message}</span>
          ))}
        </div>
        <div className="flex flex-nowrap gap-x-3">
          <button className="bg-gray-200 font-semibold text-nimbusDark p-2 w-28 rounded" onClick={() => setIsOpen(false)}>
            Cancelar
          </button>
          <button className="bg-nimbusDark font-semibold text-white p-2 w-28 rounded" onClick={handleConfirmation}>
            {action}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
