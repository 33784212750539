import { toast } from "react-toastify";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import alertConfiguration from "../../../utils/alertsUtils";
import { ReactComponent as Delete } from "../../../../assets/icons/remove.svg";

const DeleteButton = () => {
  const { usersAdded, setUsersAdded, setUsersAddedFiltered } =
    useListaDifusionDetalles();

  const handleRemoveSelectedUsers = () => {
    // Separar usuarios seleccionados y no seleccionados
    const nonSelectedUsers = usersAdded?.filter((user) => !user.esSeleccionado);

    // Actualizar usersAdded y usersAddedFiltered con usuarios no seleccionados
    setUsersAdded(nonSelectedUsers);
    setUsersAddedFiltered(nonSelectedUsers);

    toast.success(
      "Los usuarios han sido removidos de la lista exitosamente.",
      alertConfiguration
    );
  };

  return (
    <div className="w-full md:w-fit">
      <button
        type="button"
        className="w-full bg-[#18bafb] text-white py-2 p-4 rounded-lg flex items-end justify-center disabled:bg-[#18bafb]/40"
        onClick={handleRemoveSelectedUsers}
        disabled={
          usersAdded?.filter((user) => user.esSeleccionado).length === 0
        }
      >
        <Delete className="w-4 h-4 mr-2 mb-1 white-svg" />
        Quitar
      </button>
    </div>
  );
};

export default DeleteButton;
