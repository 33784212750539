import React from "react";

const BreadCrumb = ({ route, active = false, styles="", ...otherProps }) => {
  return (
    <div
      className={`${active ? "font-semibold" : "opacity-80 flex cursor-pointer"} text-nimbusDark text-base md:text-xl ${styles}`}
      {...otherProps}
    >
      {route}
    </div>
  );
};

export default BreadCrumb;
