import ComisionIcon from "../../../assets/icons/comisionMarketplace.svg";
import CuentaBancariaIcon from "../../../assets/icons/cuentaBancaria.svg";
import ConversionMonedasIcon from "../../../assets/icons/conversionMonedas.svg";
import PorcentajeGananciaIcon from "../../../assets/icons/porcentajeGanancia.svg";
import DatosEnvioIcon from "../../../assets/icons/datosEnvio.svg";
import { getVariablesConversionMonedas } from "../../services/Api/MarketPlace/Editoriales/VariablesConversionMonedas";
import { getCuentasBancariasEditorial } from "../../services/Api/MarketPlace/Editoriales/CuentasBancarias";
import { getCuentasBancariasDistribucion } from "../../services/Api/MarketPlace/Distribuciones/CuentasBancarias";
import { getCuentasBancariasCorporativo } from "../../services/Api/MarketPlace/Corporativos/CuentasBancarias";
import { getCuentasBancariasPlantel } from "../../services/Api/MarketPlace/Planteles/CuentasBancarias";
import { getDatosDeEnvioPlantel } from "../../services/Api/MarketPlace/Planteles/DatosEnvio";
import { getComisionGeneral } from "../../services/Api/MarketPlace/ComisionUsoPlataforma";
import { getVariablesConversionMonedasDistribucion } from "../../services/Api/MarketPlace/Distribuciones/VariablesConversionMonedas";

export const CONFIGURACION_MARKETPLACE_MENUS = [
  {
    roles: ["ADMIN"],
    icon: ComisionIcon,
    title: "Comisión de uso de la plataforma",
    onClick: ({ setIsOpenComision }) => setIsOpenComision(true),
    status: async () => {
      try {
        const { comision } = await getComisionGeneral();
        return comision ? true : false;
      } catch (e) {
        return false;
      }
    },
  },
  // {
  //   roles: ["ADMIN"],
  //   icon: MonedasIcon,
  //   title: "Configuración de monedas y países",
  //   status: () => false,
  // },
  {
    roles: ["DIR_EDITORIAL", "DISTRIBUIDOR", "DIR_CORPORATIVO", "DIR_PLANTEL", "ADMIN_OPERATIVO"],
    icon: CuentaBancariaIcon,
    title: "Cuenta bancaria",
    onClick: ({ navigate, user, activeRole }) => {
      let path = "/marketplace/cuentaBancaria/";
      switch (activeRole) {
        case "DIR_EDITORIAL":
          path += user.editorial._id;
          break;
        case "DISTRIBUIDOR":
          path += user.distribucion._id;
          break;
        case "DIR_CORPORATIVO":
          path += user.escuela._id;
          break;
        case "DIR_PLANTEL":
        case "ADMIN_OPERATIVO":
          path += user.escuela.campus._id;
          break;
        default:
          break;
      }
      navigate(path);
    },
    status: async ({ user, activeRole }) => {
      try {
        let cuentasBancariasResponse;
        switch (activeRole) {
          case "DIR_EDITORIAL":
            cuentasBancariasResponse = await getCuentasBancariasEditorial(user.editorial._id);
            break;
          case "DISTRIBUIDOR":
            cuentasBancariasResponse = await getCuentasBancariasDistribucion(user.distribucion._id);
            break;
          case "DIR_CORPORATIVO":
            cuentasBancariasResponse = await getCuentasBancariasCorporativo(user.escuela._id);
            break;
          case "DIR_PLANTEL":
          case "ADMIN_OPERATIVO":
            cuentasBancariasResponse = await getCuentasBancariasPlantel(user.escuela.campus._id);
            break;
          default:
            return false;
        }
        return cuentasBancariasResponse.length > 0;
      } catch (error) {
        return false;
      }
    },
  },
  {
    roles: ["DISTRIBUIDOR", "DIR_CORPORATIVO", "DIR_PLANTEL", "ADMIN_OPERATIVO"],
    icon: PorcentajeGananciaIcon,
    title: "Porcentaje de ganancia de los artículos de línea",
    onClick: ({ setIsOpenPorcentaje }) => setIsOpenPorcentaje(true),
    status: () => true,
  },
  //Comentado por cambio de proceso de conversión de monedas
  // {
  //   roles: ["DIR_EDITORIAL", "DISTRIBUIDOR"],
  //   icon: ConversionMonedasIcon,
  //   title: "Variables de conversión de monedas",
  //   onClick: ({ navigate }) => navigate("/marketplace/conversionMonedas"),
  //   status: async ({ user, activeRole }) => {
  //     try {
  //       switch (activeRole) {
  //         case "DIR_EDITORIAL":
  //           await getVariablesConversionMonedas(user.editorial._id);
  //           return true;
  //         case "DISTRIBUIDOR":
  //           await getVariablesConversionMonedasDistribucion(user.distribucion._id);
  //           return true;
  //         default:
  //           return false;
  //       }
  //     } catch (error) {
  //       return false;
  //     }
  //   },
  // },
  {
    roles: ["DIR_EDITORIAL"],
    icon: ConversionMonedasIcon,
    title: "Monedas y países",
    onClick: ({ navigate }) => navigate("/paises"),
    status: () => true,
  },
  {
    roles: ["DIR_PLANTEL", "ADMIN_OPERATIVO"],
    icon: DatosEnvioIcon,
    title: "Datos de envío",
    onClick: ({ navigate, user }) => navigate(`/marketplace/datosEnvio/${user.escuela.campus._id}`),
    status: async ({ user }) => {
      try {
        await getDatosDeEnvioPlantel(user.escuela.campus._id);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
];
