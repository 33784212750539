export const ROLES = {
    admin:'ADMIN',
    alumno:'ALUMNO',
    invitado:'INVITADO',
    profesor:'PROFESOR',
    padre:'PADRE_TUTOR',
    coordinador:'COOR_ACADEMICO',
    operativo:'ADMIN_OPERATIVO',
    plantel:'DIR_PLANTEL',
    corporativo:'DIR_CORPORATIVO',
    autor:'AUTOR_ESCOLAR',
    editor:'EDITOR_ESCOLAR',
    autorEditorial:'AUTOR_EDITORIAL',
    autorIndependiente:'AUTOR_INDEPENDIENTE',
    editorEditorial:'EDITOR_EDITORIAL',
    directorEditorial:'DIR_EDITORIAL',
    asesor:'ASESOR',
    distribuidor:'DISTRIBUIDOR',
}

const SCOPES = {
    canCreate: 'can-create',
    canEdit: 'can-edit',
    canDelete: 'can-delete',
    canView: 'can-view',
    canViewOwn : 'can-view-own'
}

const ROUTES = {
    escuelas:'escuelas',
    campus: 'campus',
    grupos: 'grupos',
    familias: 'familias',
    usuarios: 'usuarios',
    constructor: 'constructor',
    servidores:'servidores',
    reportes:'reportes',
    visor:'visor',
    corporativos:'corporativos',
    escaparate:'escaparate',
    usuariosEditorial:'usuariosEditorial',
    usuariosDistribucion:'usuariosDistribucion',
    distribuciones:'distribuciones'
}

const PERMISSIONS = {
    [ROLES.admin] : {
        [ROUTES.escuelas]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.campus]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.grupos]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.familias]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuarios]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.servidores]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.reportes]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.visor]:[
            SCOPES.canView
        ],
        [ROUTES.usuariosEditorial]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuariosDistribucion]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.corporativo]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.corporativos]:[
            SCOPES.canView
        ],
        [ROUTES.familias]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.grupos]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.reportes]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.servidores]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuarios]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.visor]:[
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canCreate,
            SCOPES.canView,
            SCOPES.canDelete
        ]
    },
    [ROLES.operativo]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canViewOwn,
            SCOPES.canEdit,
            SCOPES.canCreate
        ],
        [ROUTES.grupos]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.familias]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuarios]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.servidores]:[
            SCOPES.canView
        ],
        [ROUTES.reportes]:[
            SCOPES.canView
        ],
        [ROUTES.visor]:[
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.plantel]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canViewOwn,
            SCOPES.canEdit,
            SCOPES.canCreate
        ],
        [ROUTES.grupos]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.familias]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuarios]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.servidores]:[
            SCOPES.canView
        ],
        [ROUTES.reportes]:[
            SCOPES.canView
        ],
        [ROUTES.visor]:[
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.coordinador]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.grupos]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.familias]:[
            SCOPES.canView
        ],
        [ROUTES.usuarios]:[
            SCOPES.canView
        ],
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.servidores]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.reportes]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.visor]:[
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.profesor]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.grupos]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.familias]:[],
        [ROUTES.usuarios]:[],
        [ROUTES.constructor]:[],
        [ROUTES.servidores]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.reportes]:[],
        [ROUTES.visor]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.alumno] : {
        [ROUTES.escuelas]:[],
        [ROUTES.grupos]:[],
        [ROUTES.campus]:[],
        [ROUTES.familias]:[],
        [ROUTES.constructor]:[],
        [ROUTES.reportes]:[],
        [ROUTES.usuarios]:[],
        [ROUTES.servidores]:[],
        [ROUTES.visor]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.padre]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.grupos]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.familias]:[],
        [ROUTES.usuarios]:[],
        [ROUTES.constructor]:[],
        [ROUTES.servidores]:[],
        [ROUTES.reportes]:[],
        [ROUTES.visor]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.invitado]:{
        [ROUTES.escuelas]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.campus]:[
            SCOPES.canViewOwn
        ],
        [ROUTES.grupos]:[],
        [ROUTES.familias]:[],
        [ROUTES.usuarios]:[],
        [ROUTES.constructor]:[],
        [ROUTES.servidores]:[],
        [ROUTES.reportes]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.autor]:{
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.editor]:{
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.autorEditorial]:{
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.autorIndependiente]:{
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.directorEditorial]:{
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.distribuciones]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.usuariosDistribucion]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuariosEditorial]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.editorEditorial]:{
        [ROUTES.constructor]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView
        ]
    },
    [ROLES.asesor]:{
        [ROUTES.distribuciones]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.corporativos]:[
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ]
    },
    [ROLES.distribuidor]:{
        [ROUTES.corporativos]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.escaparate]:[
            SCOPES.canView,
            SCOPES.canCreate,
            SCOPES.canDelete
        ],
        [ROUTES.distribuciones]:[
            SCOPES.canCreate,
            SCOPES.canEdit,
            SCOPES.canDelete,
            SCOPES.canView
        ],
        [ROUTES.usuariosDistribucion]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ],
        [ROUTES.usuariosEditorial]:[
            SCOPES.canCreate,
            SCOPES.canDelete,
            SCOPES.canEdit,
            SCOPES.canView
        ]
    }
}

export const isAllowedTo = async (role,route) =>{
    return PERMISSIONS[role][route]
}