import { _get, _post, _delete, _put } from "../../../http";

//Endpoints para la gestión de productos

export const getProductosEstructura = (tipoDeEstructura, estructuraId) =>
  _get(`/productos/${tipoDeEstructura}/${estructuraId}`);

export const getProductoDetalle = (productoId) => _get(`/productos/${productoId}`);

export const postProducto = (body) => _post("/productos", body);

export const putProducto = (productoId, body) => _put(`/productos/${productoId}`, body);

export const putPrecioVentaProducto = (productoId, body) => _put(`/productos/${productoId}/actualizarPrecioVenta`, body);

export const deleteProducto = (productoId) => _delete(`/productos/${productoId}`);
