import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import Key from "../../assets/icons/key.svg";
import InputTextIcon from "../fragments/Inputs/InputTextIcon";
import useValidateForm from "../hooks/useValidateForm";
import { restablecerPassword } from "../services/api";

const ResetPassword = () => {
  const { handleChange, errors, values, validateEmpty, setErrors } = useValidateForm({
    password: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({});

  const params = useParams();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      id: params.idUsuario,
      codigo: params.codigo,
      contraseniaNueva: values.password,
    };
    let newErrors = await validateEmpty([]);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(passwordErrors).length === 0 &&
      Object.keys(newErrors).length === 0
    ) {
        let res = await restablecerPassword(data);
        if(res.error){
          if(res.error.message === "Este codigo de solicitud ya no esta disponible"){
            navigate("/token/expired");
          }else{
            setErrors({
              ...passwordErrors,
              password: res.error.message
            })
          }
        }else{
          navigate("/verify/password");
        }
        
    }else{
      setErrors({...passwordErrors, ...newErrors, ...errors})
    }
  };

  useEffect(() => {
    if (values.password !== values.confirmPassword) {
      setPasswordErrors({
        ...passwordErrors,
        confirmPassword: "Las contraseñas no coinciden",
      });
    } else {
      setPasswordErrors({});
    }
  }, [values]);

  return (
    <div className="w-full min-h-screen md:h-auto md:min-h-[100vh] bg-[#fcfcfc] flex flex-col justify-center items-center">
      <div className="w-[24rem] flex flex-col justify-center px-8 md:px-0">
        <div className="flex flex-col items-center mx-auto">
          <img className="h-auto w-18" src={Logo} alt="logo.png" />
          <h1 className="text-nimbusDark font-bold text-2xl md:text-3xl mt-8 text-center">
            Crear nueva contraseña
          </h1>
          <p className="text-[#3A404C] text-center mt-5 text-sm md:text-base">
            Tu nueva contraseña debe cumplir con los siguientes requisitos:
          </p>
          <ul className="mt-2 text-sm md:text-base">
            <li className="list-disc text-left">
              Tener al menos 6 caracteres.
            </li>
            <li className="list-disc text-left">
              Tener 14 caracteres como máximo.
            </li>
            <li className="list-disc text-left">No debe contener espacios.</li>
          </ul>
          <span className="mt-4 text-sm flex">
            <p className="font-bold mr-1">Nota:</p>
            <p className="text-[#3A404C] text-center">
              La contraseña es sensible a mayúsculas y minúsculas.
            </p>
          </span>
        </div>
        <InputTextIcon
          className="mt-4 flex flex-col"
          id="password"
          label="Nueva contraseña"
          type="password"
          placeholder="Tu nueva contraseña"
          name="password"
          value={values.password}
          onChange={(e) => handleChange(e, "password")}
          icon={Key}
          error={errors["password"]}
        />
        <InputTextIcon
          className="mt-4 flex flex-col mb-8 md:mb-11"
          id="confirmPassword"
          label="Confirma tu nueva contraseña"
          type="password"
          placeholder="Confirma tu nueva contraseña"
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={(e) => handleChange(e, "confirmPassword")}
          icon={Key}
          error={passwordErrors["confirmPassword"]}
        />
        <button
          className="bg-nimbusDark font-semibold text-white w-full rounded-lg py-2"
          onClick={handleSubmit}
        >
          Restablecer contraseña
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
