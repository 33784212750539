import React, { useEffect } from 'react'

import {ReactComponent as ArrowRightButton} from '../../assets/icons/arrowRightButton.svg'
import {ReactComponent as ArrowLeftButton} from '../../assets/icons/arrowLeftButton.svg'

const TablePagination = ({ next, prev, jump, data, currentPage, maxPage, styles ="" }) => {

    useEffect(() => {
        if(currentPage > maxPage) prev()
      }, [currentPage, data]);

  return (
    <div className={`flex justify-between bg-[#F5F5F5] rounded h-14 p-1 items-center mt-8 ${styles}`}>
                    <div className="ml-2">
                        <span className='text-sm sm:"text-base'>{currentPage} de {maxPage}</span>
                    </div>
                    <div className="flex items-center mr-2 space-x-2 sm:space-x-4">
                        <div className="flex items-center space-x-2 border-r-2 pr-2 sm:pr-4">
                            <span className='text-sm sm:"text-base sm:whitespace-nowrap'>Está en la página</span>
                            <select value={currentPage} name="alumnosP" id="alumnosP" className="rounded h-2/3 w-1/2" onChange={e=>jump(e.target.value)}>
                                {Array.from(Array(maxPage).keys()).map(page=>{
                                    return <option key={page+1} value={page+1}>{page+1}</option>
                                })}
                            </select>
                        </div>
                        {/* <div className='w-16 h-3/4 bg-red-500'></div> */}
                        <div className="flex items-center space-x-2">
                                {currentPage === 1? <ArrowLeftButton className='opacity-40'/>:<ArrowLeftButton className="cursor-pointer" onClick={prev}/>}
                                {currentPage === maxPage?<ArrowRightButton className='opacity-40'/>:<ArrowRightButton className="cursor-pointer" onClick={next}/>}
                        </div>
                    </div>
                </div>
  )
}

export default TablePagination