import React, { useEffect } from "react";
import Modal from "react-modal";

function GeneralModal({ isOpen, toggleModal, children }) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "468px",
      borderRadius: "1rem",
      border: "1px solid #dcdcdc",
    },
  };

  useEffect(() => {
    Modal.defaultStyles.overlay.backgroundColor = "rgb(84 84 84 / 0%)";
    Modal.defaultStyles.overlay.zIndex = "+99";
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      style={customStyles}
    >
      <div className="max-w-screen-xl mx-auto"></div>
      {children}
    </Modal>
  );
}

export default GeneralModal;
