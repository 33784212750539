import React, { useContext } from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import useFormSubmit from "../../../hooks/useFormSubmit";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { AsignarArticulosContext } from "../../../../contexts/AsignarProductosContext";
import { eliminarArticulo, habilitarBotonEstatus } from "../../../../reducers/asginarArticulos/asignarArticulosAction";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const EliminarArticuloAsignadoModal = ({ isOpen, setIsOpen, articuloId }) => {
  const { articulos, dispatch } = useContext(AsignarArticulosContext);

  const eliminarProductoSubmit = async () => {
    dispatch(eliminarArticulo(articulos, articuloId));
    dispatch(habilitarBotonEstatus(true))
    setIsOpen(false);
    toast.success("Artículo removido correctamente", alertConfiguration)
  };
  const [formStatus, handleSubmit] = useFormSubmit(eliminarProductoSubmit);
  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Quitar artículo</h1>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea quitar este artículo de está estructura?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default EliminarArticuloAsignadoModal;
