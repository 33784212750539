import React, { useState, useEffect, useLayoutEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SimplePlayer from "./ActividadesUtils/SimplePlayer";
import {v4 as uuid} from "uuid"
import { shuffle } from "./ActividadesUtils/ActividadesUtils";
import { useContext } from "react";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { colors } from "./ActividadesUtils/ActividadesUtils";
import Lightbox from "./ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "./ActividadesUtils/Lightbox/LightboxWrapper";
import { Trans } from "react-i18next";
import ImagenValidada from "./ActividadesUtils/RecursosValidados/ImagenValidada";

function ArrastrarAContenedores({contenedores, contenedorItems, evaluable, umaBloque, guardarCalificacion, instrucciones}) {
  const [iniciado, setIniciado] = useState(false)
  const contenedorItemsArr = [...contenedorItems].map((item,index)=>({...item,  background:colors[index]}))
  const columnsFromBackend = [{ texto: "", items: shuffle(contenedorItemsArr) , _id: uuid(), },...contenedores]
  const [columns, setColumns] = useState(columnsFromBackend);
  const [lightboxValue, setLightboxValue] = useState('')
  const activityContext = useContext(ActividadContext)

  const onDragEnd = (result, columns, setColumns) => {
    setIniciado(true)
    if (!result.destination) return;
    const { source, destination } = result;
  
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const currentArr = Object.keys(columns).map((key) => columns[key]);
  const respuestas = currentArr
    .map((item) => {
      return item.items.map((el) => {
        return el.parentId === item._id;
      });
    })
    .flat();
  const respuestasCorrectas = respuestas.filter(Boolean).length
  const calificacion = (respuestasCorrectas / contenedorItems.length) * 100

  const calificar = () =>{
    if(iniciado){
      activityContext.postCalificacionActividad(calificacion)
      guardarCalificacion(umaBloque, respuestasCorrectas, JSON.stringify(columns))
    }
    else if(!iniciado)activityContext.postCalificacionActividad(null)
  }

  const handleCalificar = () => {
    if(activityContext !== undefined){
      calificar()
    }
  };

  const autocompletarActividad = () =>{
    const respuestasCorrectas = columnsFromBackend.map((item)=>{
      const respuestas  = contenedorItemsArr.filter((el)=>(el.parentId === item._id))
      return {...item, ...{items:respuestas}}
    })
    setColumns(respuestasCorrectas)
  }

  useEffect(()=>{
    if(!evaluable){
      autocompletarActividad()
    }else if(evaluable){
      setColumns(columnsFromBackend)
    }
  },[evaluable])
  
  const handleLimpiar = () =>{
    setColumns(columnsFromBackend);
    setIniciado(false)
  }

  return (
    <>
      {instrucciones && <div className="act-instructions mt-4 max-w-activity">{instrucciones}</div>}
      <Lightbox lightboxValue={lightboxValue} closeLightbox={()=>setLightboxValue('')}/>
      <div className={`w-full max-w-activity py-10 grid grid-cols-2 md:grid-cols-2 justify-center h-full ${evaluable ? '' : 'pointer-events-none'}`}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                className={`flex flex-col items-center p-2 ${index === 0 ? 'outside-grid mb-5' : 'justify-end'}`}
                key={columnId}
              >
                {/* para contenedores */}
                {column.imagen ? 
                ( <>
                  <LightboxWrapper handleClick={()=>setLightboxValue(column.imagen)}>
                    <ImagenValidada 
                      src={column.imagen} 
                      className="w-16 h-16 rounded-full object-fill mr-4 block shadow-lg border"
                    />
                  </LightboxWrapper>
                  </>)
                : ("")}
                {column.audio ? (<SimplePlayer audio={column.audio} size={'text-xl !p-3 mr-2'}/>) :('')}
                {column.texto ? <span className="mt-1 text-center text-[#697d99] w-[150px] lg:w-[250px] text-sm md:text-base`">{column.texto}</span>: ''}
                <div className="m-2 w-full">
                  <Droppable
                    droppableId={columnId}
                    key={columnId}
                    direction={index === 0 ? 'horizontal' : 'vertical'}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={`p-1 md:p-2 overflow-auto border rounded-xl ${index === 0 ? 'flex h-fit w-full min-h-[70px] ' : 'h-auto w-[162px] md:w-[250px] min-h-[240px] lg:min-h-[400px] mx-auto'}`}
                          style={{background: snapshot.isDraggingOver ? "#e6e9ee" : "#ebecf0",}}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="mb-2 p-2 md:p-4 min-h-[35px] md:min-h-[50px] m-1 w-full min-w-[144px] max-w-[144px] md:min-w-[223px] md:max-w-[223px] text-white text-sm md:text-base relative aspect-video rounded-xl overflow-hidden flex"
                                      style={{
                                        userSelect: "none",
                                        backgroundColor:item.background,
                                        ...provided.draggableProps.style
                                      }}
                                    >
                                      {/* form items from backend */}
                                      <div className="flex items-center" style={{backgroundColor:item.background}}>
                                        {item.imagen ? 
                                        ( 
                                          <LightboxWrapper handleClick={()=>setLightboxValue(item.imagen)} wrapperStyles={'w-full h-full !absolute top-0 left-0'}>
                                            <ImagenValidada 
                                              src={item.imagen} 
                                              className="z-0 bg-white absolute w-full h-full top-0 right-0 object-fill block border brightness-75"
                                            />
                                          </LightboxWrapper>
                                        ) 
                                        : ("")}
                                        {item.audio ? (<SimplePlayer audio={item.audio} size={'text-xl !p-3 mr-2'}/>) :('')}
                                        {item.texto ? <span className="text-sm z-30 text-center">{item.texto}</span>: ''}
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      {evaluable ? 
      <div className="flex justify-center align-center w-full outside-grid mx-auto transform scale-75 md:scale-100 mt-8">
        <button className="m-5 py- px-10 rounded-full inline" onClick={handleLimpiar}>
          <Trans>Limpiar</Trans>
        </button>
        <button 
            onClick={handleCalificar}
            className='border bg-nimbus text-white m-5 py-2 md:py-3 px-5 md:px-10 rounded-full inline text-sm md:text-basee'
        >
          <Trans>{iniciado ? 'Enviar y cerrar' : 'Cerrar'}</Trans>
        </button>
      </div>
      :''}
      </div>
    </>
  );
}

ArrastrarAContenedores.defaultProps = {
  contenedorItems:[],
  contenedores:[],
  evaluable:true,
}

export default ArrastrarAContenedores;
