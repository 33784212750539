import { createAction } from "../../components/utils/reducerUtils";
import { ARTICULOS_ACTION_TYPES } from "./asignarArticulosTypes";

const agregarArticuloALaLista = (articulos, articuloAAgregar) => {
  const esPaquete = articuloAAgregar.paquete ? true : false;
  let nuevoArticulo = {
    articuloListaId: articuloAAgregar._id,
    precioVenta: articuloAAgregar.precioVenta,
    precioAsignacion: articuloAAgregar.precioVenta,
    ganancia: articuloAAgregar.precioVenta - articuloAAgregar.costo,
    cantidad: articuloAAgregar.cantidad,
    costo: articuloAAgregar.costo,
    esPaquete: esPaquete,
    origen: articuloAAgregar.origen,
    provieneDe: articuloAAgregar.provieneDe
  };
  let articulo = {};
  if (esPaquete) {
    articulo = {
      _id: articuloAAgregar.paquete._id,
      nombre: articuloAAgregar.paquete.nombre,
      estatus: articuloAAgregar.paquete.estatus,
      categoria: "Paquetes",
      nivelAcademico: articuloAAgregar.paquete.nivelAcademico,
      gradoAcademico: articuloAAgregar.paquete.gradoAcademico,
    };
  } else {
    articulo = {
      _id: articuloAAgregar.producto._id,
      nombre: articuloAAgregar.producto.nombre,
      estatus: articuloAAgregar.producto.estatus,
      categoria: articuloAAgregar.producto.categoria,
      nivelAcademico: articuloAAgregar.producto.nivelAcademico,
      gradoAcademico: articuloAAgregar.producto.gradoAcademico,
    };
  }

  nuevoArticulo.articulo = articulo;

  return [...articulos, nuevoArticulo];
};

const eliminarArticuloDeLaLista = (articulos, articuloAEliminar) => {
  const articuloEnLaLista = articulos.find((articulo) => articulo.articuloListaId === articuloAEliminar);

  if (!articuloEnLaLista) return articulos;

  return articulos.filter((articulo) => articulo.articuloListaId !== articuloAEliminar);
};

const seleccionarArticuloDeLaLista = (articulos, articuloASeleccionar) => {
  return articulos.map((articulo) => {
    if (articulo.articuloListaId === articuloASeleccionar.articuloListaId) {
      articulo.esSeleccionado = !articulo.esSeleccionado;
    }
    return articulo;
  });
};

const seleccionarTodosLosArticulos = (articulos, articulosASeleccionar, checked) => {
  const articulosASeleccionarIds = articulosASeleccionar.map((articulo) => articulo.articuloListaId);
  return articulos.map((articulo) => {
    if (articulosASeleccionarIds.includes(articulo.articuloListaId)) {
      articulo.esSeleccionado = checked;
    }
    return articulo;
  });
};

const agregarTodosLosArticulos = (articulos, nuevosArticulosAAgregar) => {
  let nuevosArticulos = nuevosArticulosAAgregar
    .filter((articulo) => articulo.esSeleccionado === true)
    .map((articulo) => {
      const esPaquete = articulo.paquete ? true : false;

      let nuevoArticulo = {
        articuloListaId: articulo._id,
        precioVenta: articulo.precioVenta,
        precioAsignacion: articulo.precioVenta,
        ganancia: articulo.precioVenta - articulo.costo,
        costo: articulo.costo,
        esPaquete: esPaquete,
        origen: articulo.origen,
        cantidad: articulo.cantidad,
        provieneDe: articulo.provieneDe
      };

      let articuloPropiedad = {};
      if (esPaquete) {
        articuloPropiedad = {
          _id: articulo.paquete._id,
          nombre: articulo.paquete.nombre,
          estatus: articulo.paquete.estatus,
          categoria: "Paquetes",
          nivelAcademico: articulo.paquete.nivelAcademico,
          gradoAcademico: articulo.paquete.gradoAcademico,
        };
      } else {
        articuloPropiedad = {
          _id: articulo.producto._id,
          nombre: articulo.producto.nombre,
          estatus: articulo.producto.estatus,
          categoria: articulo.producto.categoria,
          nivelAcademico: articulo.producto.nivelAcademico,
          gradoAcademico: articulo.producto.gradoAcademico,
        };
      }

      nuevoArticulo.articulo = articuloPropiedad;

      return nuevoArticulo;
    });

  return [...articulos, ...nuevosArticulos];
};

const asignarNuevoPrecioArticulo = (articulos, articuloAEditar, precioAsignacion) => {
  return articulos.map((articulo) => {
    if (articulo.articuloListaId === articuloAEditar) {
      articulo.precioAsignacion = precioAsignacion;
      articulo.ganancia = precioAsignacion - articulo.costo;
    }
    return articulo;
  });
};

const removerArticuloDeLaLista = (articulos) => {
  return articulos.filter((articulo) => !articulo.esSeleccionado);
};

const aplicarDescuentoAArticulosDeLaLista = (articulos, descuento) => {
  return articulos.map((articulo) => {
    if (articulo.esSeleccionado) {
      let nuevoPrecioAsignacion =
        Math.floor((articulo.precioAsignacion - (descuento * articulo.precioAsignacion) / 100) * 100) / 100;
      articulo.precioAsignacion = nuevoPrecioAsignacion;
      articulo.ganancia = nuevoPrecioAsignacion - articulo.costo;
      articulo.esSeleccionado = false;
    }

    return articulo;
  });
};

export const agregarArticulo = (articulos, articulo) => {
  const nuevosArticulos = agregarArticuloALaLista(articulos, articulo);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const removerArticulosLista = (articulos) => {
  const nuevosArticulos = removerArticuloDeLaLista(articulos);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const aplicarDescuentoArticulos = (articulos, articulosAAplicarDescuento, descuento) => {
  const nuevosArticulos = aplicarDescuentoAArticulosDeLaLista(articulos, articulosAAplicarDescuento, descuento);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const eliminarArticulo = (articulos, articulo) => {
  const nuevosArticulos = eliminarArticuloDeLaLista(articulos, articulo);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const asignarPrecioArticulo = (articulos, articulo, precioAsignacion) => {
  const nuevosArticulos = asignarNuevoPrecioArticulo(articulos, articulo, precioAsignacion);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const seleccionarArticuloLista = (articulos, articulo) => {
  const nuevosArticulos = seleccionarArticuloDeLaLista(articulos, articulo);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const seleccionarTodosArticulosLista = (articulos, articulosASeleccionar, checked) => {
  const nuevosArticulos = seleccionarTodosLosArticulos(articulos, articulosASeleccionar, checked);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const agregarTodosArticulos = (articulos, nuevosArticulosAAgregar) => {
  const nuevosArticulos = agregarTodosLosArticulos(articulos, nuevosArticulosAAgregar);

  return createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, nuevosArticulos);
};

export const esAgregarArticulosModalAbierto = (boolean) =>
  createAction(ARTICULOS_ACTION_TYPES.SET_ES_AGREGAR_ARTICULOS_ABIERTO, boolean);

export const habilitarBotonEstatus = (estatus) => {
  return createAction(ARTICULOS_ACTION_TYPES.SET_HABILITAR_BOTON, estatus);
};

export const inicializarArticulos = (articulos) => createAction(ARTICULOS_ACTION_TYPES.SET_ARTICULOS, articulos);
