import { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import Logo from "../../../assets/img/logo.svg";
import Menu from "../../../assets/icons/Hamburguesa.svg";
import ArrowUp from "../../../assets/icons/arrowDownSimple.svg";
import ArrowDown from "../../../assets/icons/arrowUpSimple.svg";
import Close from "../../../assets/icons/closeBlack.svg";
import InputBuscar from "../../fragments/Inputs/InputBuscar";
import SubHeader from "../../fragments/SubHeader";
import BreadCrumbContainer from "../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../fragments/BreadCrumb";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { navigation } from "../../utils/tiendaUtils";
import { useDispatch, useSelector } from "react-redux";
import { filtrarProductos } from "../../../reducers/marketplace/marketplaceAction";
import { selectBuscar } from "../../../reducers/marketplace/marketplaceSelector";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TiendaMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showBuscador = useSelector((state) => state.marketplace.showBuscador);
  const buscar = useSelector(selectBuscar);

  return (
    <>
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} />
          <BreadCrumb route={"Tienda"} active />
        </BreadCrumbContainer>
      </SubHeader>
      <div className="bg-white w-full relative md:z-10">
        {/* Mobile menu */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                  <div className="flex px-4 pb-2 pt-5">
                    <button
                      type="button"
                      className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <img className="h-5" src={Close} alt="" />
                      {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                    </button>
                  </div>

                  {/* Links */}
                  <Tab.Group as="div" className="mt-2">
                    <div className="border-b border-gray-200">
                      <Tab.List className="-mb-px flex space-x-8 px-4">
                        <div
                          className="px-1 py-4 mx-auto cursor-pointer"
                          onClick={() => {
                            navigate("/marketplace/tienda/ProductosDestacados");
                            setOpen(false);
                          }}
                        >
                          <span className="sr-only">Nimbus</span>
                          <img className="h-10 w-auto" src={Logo} alt="" />
                        </div>
                      </Tab.List>
                    </div>
                    <Tab.Panels as={Fragment}>
                      {navigation.categories.map((category) => (
                        <Tab.Panel key={category.name} className="px-4 pb-8 pt-10">
                          <span className="flex gap-4 items-center">
                            <img className="h-4" src={category.icon} alt="" />
                            <p className="-m-2 block p-2 font-medium text-nimbusDark">{category.name}</p>
                          </span>
                          {category.sections.map((section) => (
                            <div key={section.id}>
                              <ul className="mt-6 flex flex-col space-y-6">
                                {section.items.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <button
                                      onClick={() => {
                                        navigate(item.href);
                                        setOpen(false);
                                      }}
                                      className="-m-2 block p-2 text-gray-500"
                                    >
                                      {item.name}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                  <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                    {navigation.pages.map((page) => (
                      <div key={page.name} className="flow-root">
                        <span className="flex gap-4 items-center">
                          <img className="h-6" src={page.icon} alt="" />
                          <button
                            onClick={() => {
                              navigate(page.href);
                              setOpen(false);
                            }}
                            className="-m-2 block p-2 font-medium text-nimbusDark"
                          >
                            {page.name}
                          </button>
                        </span>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <header className="relative bg-white">
          <nav aria-label="Top" className="mx-auto px-4 md:px-8 border-b-4 border-sidebar">
            <div className="border-b border-gray-200">
              <div className="flex h-16 items-center">
                <button
                  type="button"
                  className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <img className="h-4" src={Menu} alt="" />
                </button>

                {/* Logo */}
                <div
                  className="ml-4 lg:ml-0 cursor-pointer"
                  onClick={() => navigate("/marketplace/tienda/ProductosDestacados")}
                >
                  <span className="sr-only">Nimbus</span>
                  <img className="h-7 w-auto" src={Logo} alt="" />
                </div>

                {/* Flyout menus */}
                <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    {navigation.categories.map((category) => (
                      <Popover key={category.name} className="flex">
                        {({ open, close }) => (
                          <>
                            <div className="relative flex">
                              <Popover.Button
                                className={classNames(
                                  open ? "text-sidebar" : "text-nimbusDark/80 hover:text-nimbusDark",
                                  "relative z-10 -mb-px flex items-center pt-px text-sm md:text-base transition-colors duration-200 ease-out"
                                )}
                                onClick={category.sections.length === 0 ? () => navigate(category.href) : null}
                              >
                                <img className="mr-4" src={category.icon} alt="" />
                                {category.name}
                                {category.sections.length !== 0 && (
                                  <img className="ml-4" src={open ? ArrowDown : ArrowUp} alt="" />
                                )}
                              </Popover.Button>
                            </div>
                            {category.sections.length !== 0 && (
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Popover.Panel className="absolute inset-x-0 top-full text-gray-500">
                                  <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                                  <div className="relative bg-white">
                                    <div className="max-w-7xl flex">
                                      <div className="bg-[#E5E7EB]/50 vertical-lr rotate-180 min-w-[70px] text-2xl flex items-center justify-center mr-8">
                                        Categorías
                                      </div>
                                      <div className="pt-5 pb-8">
                                        <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10">
                                          {category.sections.map((section) => (
                                            <div key={section.name}>
                                              <ul className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                                {section.items.map((item) => (
                                                  <li key={item.name} className="flex">
                                                    <button
                                                      onClick={() => {
                                                        navigate(item.href);
                                                        close();
                                                      }}
                                                      className="hover:text-nimbusDark"
                                                    >
                                                      {item.name}
                                                    </button>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            )}
                          </>
                        )}
                      </Popover>
                    ))}
                  </div>
                </Popover.Group>
                {showBuscador && (
                  <div className="ml-auto">
                    <InputBuscar
                      styles="xl:w-96"
                      placeholder="Hoy quiero comprar..."
                      onChange={(e) => dispatch(filtrarProductos(e.target.value))}
                      value={buscar}
                    />
                  </div>
                )}
              </div>
            </div>
          </nav>
        </header>
      </div>
      <Outlet />
    </>
  );
};

export default TiendaMenu;
