import React, { useContext, useMemo, useState } from "react";
import { formatCurrency } from "../../../utils/productoUtils";
import FavoritosIcon from "../../../../assets/icons/favoritosIcon.svg";
import FavoritosSeleccionadoIcon from "../../../../assets/icons/favoritosSeleccionadoIcon.svg";
import ProductoImage from "../../../../assets/img/producto.png";
import PaqueteImage from "../../../../assets/img/paquetesMarketplace.png";
import EtiquetaPaquete from "../../../../assets/icons/etiquetaPaquete.svg";
import { useNavigate, useParams } from "react-router-dom";
import { deleteProductoFavorito, postProductoFavorito } from "../../../services/Api/MarketPlace/Favoritos";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { useDispatch } from "react-redux";
import { fetchFavouritesAsync } from "../../../../reducers/marketplace/marketplaceAction";
import { PaisesContext } from "../../../../contexts/PaisesContext";
import { convertirMoneda } from "../../../utils/conversionMonedasUtils";
import { UserContext } from "../../../services/user";

const ProductoCard = ({ productoInfo, esPaquete, esFavorito = false, favoritoId = "" }) => {
  const dispatch = useDispatch();
  const { paises, paisDestino, setPaisDestino } = useContext(PaisesContext);
  const { user } = useContext(UserContext);
  const [ conversionMonedas, setConversionMonedas ] = useState({});
  const { _id, precioVenta, esEliminado, plantel } = productoInfo;
  const { nombre, estatus, imagen, carrusel, simboloMoneda } = useMemo(() => {
    setPaisDestino(user?.escuela?.campus?.dirFiscalPais?._id);
    let bodyPaises = {
      paisOrigen: plantel.dirFiscalPais,
      paisDestino: user?.escuela?.campus?.dirFiscalPais?._id,
      paises: paises,
    };
    setConversionMonedas(bodyPaises);
    if (esPaquete) 
      return {
        ...productoInfo.paquete, 
        simboloMoneda: paises.find(pais => pais._id === paisDestino)?.simbolo
      };
    
    return {
      ...productoInfo.producto, 
      simboloMoneda: paises.find(pais => pais._id === paisDestino)?.simbolo
    };
  }, [productoInfo, paises, paisDestino]);  
  
  const navigate = useNavigate();
  const params = useParams();

  const handleFavoritos = async (e) => {
    try {
      e.stopPropagation();
      const tipoDeProducto = esPaquete ? "paquete" : "producto";
      let body = {
        [tipoDeProducto]: {
          _id: _id,
        },
      };
      if (esFavorito) {
        await deleteProductoFavorito(favoritoId);
        dispatch(fetchFavouritesAsync(false));
        toast.success("El artículo se ha eliminado correctamente de sus favoritos", alertConfiguration);
      } else {
        await postProductoFavorito(body);
        dispatch(fetchFavouritesAsync(false));
        toast.success("El artículo se ha añadido correctamente a sus favoritos", alertConfiguration);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-[18rem] w-56 sm:w-full relative">
      <img
        className="absolute top-2 right-2 h-6 cursor-pointer z-[1]"
        src={esFavorito ? FavoritosSeleccionadoIcon : FavoritosIcon}
        alt=""
        onClick={handleFavoritos}
      />
      <div
        className={`w-full h-full border-2 rounded-xl flex flex-col items-center justify-center bg-white p-2 cursor-pointer ${
          esEliminado && "opacity-50 pointer-events-none"
        }`}
        onClick={() => navigate(`/marketplace/tienda/${params.categoria}/${esPaquete ? "paquete" : "producto"}/${_id}`)}
      >
        {esPaquete && (
          <>
            <img className="absolute top-0 left-0 h-20" src={EtiquetaPaquete} alt="" />
            <p className="absolute text-white top-5 left-0.5 -rotate-45 text-xs font-bold uppercase">Paquete</p>
          </>
        )}
        <div className="h-36 flex items-end">
          <img
            className="h-24"
            src={imagen === "" ? (carrusel.length > 0 ? carrusel[0] : (esPaquete ? carrusel.length > 0 ? carrusel[0] : PaqueteImage : ProductoImage)) : imagen}
            alt={`${nombre}.png`}
          />
        </div>
        <div className="flex-1 mt-2 w-full">
          <p className="break-words w-full text-black mt-2 font-bold text-center">{nombre}</p>
          <p className="my-4 font-bold text-center">{estatus ? `${simboloMoneda} ${formatCurrency(convertirMoneda(precioVenta, conversionMonedas))}` : "AGOTADO"}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductoCard;
