import { MARKETPLACE_ACTION_TYPES } from "./marketplaceTypes";

export const MARKETPLACE_INITIAL_STATE = {
  marketplace: {},
  isLoading: true,
  error: null,
  buscar: "",
  showBuscador: true,
  favourites: [],
  isLoadingFavourites: true,
  errorFavourites: null,
};

export const marketplaceReducer = (state = MARKETPLACE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_START:
      return { ...state, isLoading: payload };
    case MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_SUCCESS:
      return { ...state, marketplace: payload, isLoading: false };
    case MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_FAILED:
      return { ...state, error: payload, isLoading: false };
    case MARKETPLACE_ACTION_TYPES.FETCH_FAVOURITES_START:
      return { ...state, isLoadingFavourites: payload };
    case MARKETPLACE_ACTION_TYPES.FETCH_FAVOURITES_SUCCESS:
      return { ...state, favourites: payload, isLoadingFavourites: false };
    case MARKETPLACE_ACTION_TYPES.FETCH_FAVOURITES_FAILED:
      return { ...state, errorFavourites: payload, isLoadingFavourites: false };
    case MARKETPLACE_ACTION_TYPES.FETCH_MARKETPLACE_SEARCH:
      return { ...state, buscar: payload };
    case MARKETPLACE_ACTION_TYPES.FETCH_SHOW_BUSCADOR:
      return { ...state, showBuscador: payload };
    default:
      return state;
  }
};
