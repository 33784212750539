import { useHistorialAvisos } from "../HistorialAvisosContext";
import HistorialAvisosFilterForm from "./HistorialAvisosFilterForm";

const HistorialAvisosFilter = () => {
  const { avisosFiltro } = useHistorialAvisos();
  return (
    <div className="w-full md:flex md:flex-wrap md:justify-between space-y-5 md:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {avisosFiltro?.length}
          </p>
          <p className="text-black">Avisos</p>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-2 w-full md:w-auto">
        <HistorialAvisosFilterForm />
      </div>
    </div>
  );
};

export default HistorialAvisosFilter;
