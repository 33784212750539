import { useGestorAvisos } from "../GestorAvisosContext";
import GestorAvisosRow from "./GestorAvisosRow";
import SelectAll from "./SelectAll";

const GestorAvisosTable = ({ getAvisos }) => {
  const { currentData } = useGestorAvisos();

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th>
              <SelectAll />
            </th>
            <th className="text-center">Título del aviso</th>
            <th className="text-center">Fecha de creación</th>
            <th className="text-center">Fecha de envío</th>
            <th className="text-center">Categoría</th>
            <th className="text-center">Estatus</th>
            <th className="text-center">Visibilidad</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentData()?.map((aviso) => (
            <GestorAvisosRow
              key={aviso?._id}
              aviso={aviso}
              getAvisos={getAvisos}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GestorAvisosTable;
