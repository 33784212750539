import VideoValidado from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/VideoValidado";

export default function Video({ video, pieDeFoto, medidas }) {
  return (
    <>
      {video && video.includes(".mp4") && (
        <div className="w-full justify-center">
          <div className="my-10 mx-auto w-full max-w-[1280px]">
            <div className="video_bg">
              <VideoValidado
                src={video}
                className="rounded-lg w-full border border-[#dcdcdc] aspect-video"
              />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: pieDeFoto }}
              className="mt-2"
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
