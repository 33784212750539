import { _get, _post, _delete, _put } from "../../../../http";

//Endpoints para la gestión de variaciones dentro de un producto
export const getVariacionesProducto = (productoId) => _get(`/productos/${productoId}/variaciones`);

export const postVariacionProducto = (productoId, body) => _post(`/productos/${productoId}/variaciones`, body);

export const putVariacionProducto = (productoId, variacionId, body) =>
  _put(`/productos/${productoId}/variaciones/${variacionId}`, body);

export const deleteVariacionProducto = (productoId, variacionId) =>
  _delete(`/productos/${productoId}/variaciones/${variacionId}`);
