import React, { useEffect, useState } from "react";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import { use } from "i18next";

const FILTRO_ESTRUCTURAS_INITIAL_VALUES = {
  busqueda: "",
  pais: "",
  nivel: "",
  grado: "",
};

const AsignarArticulosFilter = ({ estructuras, estructurasFiltro, setEstructurasFiltro, userRol, paises, niveles }) => {
  const [filtro, setFiltro] = useState(FILTRO_ESTRUCTURAS_INITIAL_VALUES);
  const [grados, setGrados] = useState([]);

  const filterDataBusqueda = (estructuras, busqueda) => {
    if (busqueda === "") return estructuras;

    const newEstructurasFiltro = estructuras.filter((estructura) => estructura.nombre.toLowerCase().includes(busqueda));

    return newEstructurasFiltro;
  };

  const filterSelectPais = (estructuras, pais) => {
    if (pais === "") return estructuras;
    const newEstructurasFiltro = estructuras.filter(
      (estructura) => estructura.dirFiscalPais === pais || estructura.dirFiscalPais?._id === pais
    );

    return newEstructurasFiltro;
  };

  const filterSelectNivel = (estructuras, nivel) => {
    if (nivel === "") return estructuras;
    const newEstructurasFiltro = estructuras.filter((estructura) => estructura.nivelAcademico._id === nivel);

    return newEstructurasFiltro;
  };

  const filterSelectGrado = (estructuras, grado) => {
    if (grado === "") return estructuras;
    const newEstructurasFiltro = estructuras.filter((estructura) => estructura.gradosAcademicos[0]._id === grado);

    return newEstructurasFiltro;
  };

  useEffect(() => {
    if (filtro.nivel === "") {
      setGrados([]);
    } else {
      let nuevosGrados = niveles.find((nivel) => nivel._id === filtro.nivel).grados;
      setGrados(nuevosGrados);
    }
    setFiltro({ ...filtro, grado: "" });
  }, [filtro.nivel]);

  useEffect(() => {
    let newEstructurasFiltro = estructuras;
    newEstructurasFiltro = filterDataBusqueda(newEstructurasFiltro, filtro.busqueda.toLowerCase());
    newEstructurasFiltro = filterSelectPais(newEstructurasFiltro, filtro.pais);
    newEstructurasFiltro = filterSelectNivel(newEstructurasFiltro, filtro.nivel);
    newEstructurasFiltro = filterSelectGrado(newEstructurasFiltro, filtro.grado);
    setEstructurasFiltro(newEstructurasFiltro);
  }, [filtro, estructuras]);

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {estructurasFiltro.length}
          </p>
          <p className="text-black">
            {userRol === "DIR_EDITORIAL"
              ? "Distribuciones"
              : userRol === "DISTRIBUIDOR"
              ? "Corporativos"
              : userRol === "DIR_CORPORATIVO"
              ? "Planteles"
              : "Grupos"}
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className={`flex flex-col lg:grid gap-3 w-full ${userRol === "DIR_PLANTEL" && "lg:grid-cols-2"}`}>
          {userRol === "DIR_PLANTEL" && (
            <>
              <div className="w-full sm:flex sm:justify-end">
                <InputSelect
                  label={"Niveles"}
                  options={niveles}
                  topLabel={true}
                  styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
                  onChange={(e) => setFiltro({ ...filtro, nivel: e.target.value })}
                />
              </div>
              <div className="w-full sm:flex sm:justify-end">
                <InputSelect
                  label="Grados"
                  options={grados}
                  topLabel={true}
                  styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
                  onChange={(e) => setFiltro({ ...filtro, grado: e.target.value })}
                />
              </div>
            </>
          )}
          {userRol === "DIR_EDITORIAL" && (
            <div className="w-full sm:flex sm:justify-end">
              <InputSelect
                label={"Países"}
                options={paises}
                topLabel={true}
                styles="!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48"
                onChange={(e) => setFiltro({ ...filtro, pais: e.target.value })}
              />
            </div>
          )}
        </div>
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
          <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} value={filtro.busqueda}/>
        </div>
      </div>
    </div>
  );
};

export default AsignarArticulosFilter;
