import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";

export default function ModelViewer({ file }) {
  const [fileExists, setFileExists] = useState(true);
  useEffect(() => {
    document
      .getElementById("my-model-viewer")
      .addEventListener("error", (e) => {
        console.log(`Error: ${JSON.stringify(e)}`);
        setFileExists(false);
      });
    document
      .getElementById("my-model-viewer")
      .addEventListener("load", (e) =>
        console.log(`Load: ${JSON.stringify(e)}`)
      );
    document
      .getElementById("my-model-viewer")
      .addEventListener("ar-status", (e) =>
        console.log(`AR-Status: ${JSON.stringify(e)}`)
      );
  }, []);

  return (
    <>
      {!fileExists && (
        <div className="text-center font-bold text-nimbusLight !text-base p-2">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
      <Helmet>
        <script
          type="module"
          src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
        />
        <script
          nomodule
          src="https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js"
        />
      </Helmet>
      <model-viewer
        id="my-model-viewer"
        alt="3d-viewer"
        src={typeof file === "string" ? file : URL.createObjectURL(file)}
        auto-rotate
        camera-controls
      />
    </>
  );
}
