import { createContext, useContext, useState } from "react";

const initialContext = {
  avisos: [],
  setAvisos: () => {},
  avisosFiltro: [],
  setAvisosFiltro: () => {},
};

export const HistorialAvisosContext = createContext(initialContext);

export const HistorialAvisosContextProvider = ({ children }) => {
  const [avisos, setAvisos] = useState([]);
  const [avisosFiltro, setAvisosFiltro] = useState([]);

  return (
    <HistorialAvisosContext.Provider
      value={{
        avisos,
        setAvisos,
        avisosFiltro,
        setAvisosFiltro,
      }}
    >
      {children}
    </HistorialAvisosContext.Provider>
  );
};

export const useHistorialAvisos = () => {
  return useContext(HistorialAvisosContext);
};
