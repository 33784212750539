import {  _get, _post, _put} from "../../../http";

export const getVerificarActualizacionesLibrosDuplicados = (idPlantel) => _get(`/libros/actualizacionesLibrosDuplicados/plantel/${idPlantel}`);

export const getLibrosPlantel = (idCorporativo , idPlantel) => _get(`/escuelas/${idCorporativo}/campus/${idPlantel}/librosPlantel`);

export const getLibrosEditorial = (idEditorial) => _get(`/editoriales/${idEditorial}/librosEditorial`);

export const getCarpetasLibros = (idUsuario) => _get(`/carpetas/obtenerCarpetasLibrosCompletas/${idUsuario}`);

export const putLibroCarpeta = (idCarpeta, body) => _put(`/carpetas/putLibrosCarpeta/${idCarpeta}`, body);

export const getLibrosCarpetasPropias = (idUsuario) => _get(`/carpetas/getLibrosEnCarpetasPropias/${idUsuario}`);

export const obtenerInfoCarpetaLibro = (idCarpeta) => _get(`/carpetas/obtenerInfoCarpetaLibro/${idCarpeta}`);

export const getCarpetasLibrosSecundarias = (idCarpetaPadre) => _get(`/carpetas/obtenerCarpetasLibrosSecundarias/${idCarpetaPadre}`);

export const removeLibroCarpeta = (body) => _post(`/carpetas/removeLibroCarpeta`, body);

export const getLibrosEliminados = (activeRol, idEditorial) => _get(`/libros/papelera/${activeRol}/${idEditorial}`);

export const postMoverLibroPapelera = (body) => _post(`/libros/papelera`, body);

export const putRecuperarLibroPapelera = (idLibro) => _put(`/libros/papelera/recuperarLibro/${idLibro}`);