import { useState, useEffect } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../fragments/Inputs/InputSelect";

const TIPOS_CUENTAS_COMERCIALES = [
  {
    _id: "editorial",
    nombre: "Casa editorial",
  },
  {
    _id: "distribucion",
    nombre: "Distribución",
  },
  {
    _id: "corporativo",
    nombre: "Corporativo",
  },
  {
    _id: "plantel",
    nombre: "Plantel",
  },
];

const FILTRO_CUENTAS_COMERCIALES_INITIAL_VALUES = {
  busqueda: "",
  tipoEstructura: "",
};

export default function CuentasComercialesFilter({ cuentasComerciales, cuentasComercialesFiltro, setCuentasComercialesFiltro }) {
  const [filtro, setFiltro] = useState(FILTRO_CUENTAS_COMERCIALES_INITIAL_VALUES);

  const filterDataBusqueda = (cuentasComerciales, busqueda) => {
    if (busqueda === "") return cuentasComerciales;
    const newCuentasComercialesFiltro = cuentasComerciales.filter(
      (cuentaComercial) =>
        cuentaComercial?.editorial?.nombre.toLowerCase().includes(busqueda) ||
        cuentaComercial?.distribucion?.nombre.toLowerCase().includes(busqueda) ||
        cuentaComercial?.corporativo?.nombre.toLowerCase().includes(busqueda) ||
        cuentaComercial?.plantel?.nombre.toLowerCase().includes(busqueda)
    );

    return newCuentasComercialesFiltro;
  };

  const filterSelectTipoDeEstructura = (tipoEstructura) => {
    if (tipoEstructura === "") return cuentasComerciales;
    const newCuentasComercialesFiltro = cuentasComerciales.filter((cuentaComercial) => cuentaComercial[tipoEstructura]);

    return newCuentasComercialesFiltro;
  };

  useEffect(() => {
    let newCuentasComercialesFiltro = filterSelectTipoDeEstructura(filtro.tipoEstructura.toLowerCase());
    newCuentasComercialesFiltro = filterDataBusqueda(newCuentasComercialesFiltro, filtro.busqueda.toLowerCase());
    setCuentasComercialesFiltro(newCuentasComercialesFiltro);
  }, [filtro, cuentasComerciales]);

  return (
    <div className="w-full md:flex md:flex-wrap md:justify-between space-y-5 md:space-y-0 py-6">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {cuentasComercialesFiltro.length}
          </p>
          <p className="text-black">Cuentas comerciales</p>
        </div>
      </div>
      <div className="md:flex md:justify-between md:items-center md:space-x-3 space-y-3 md:space-y-0">
        <div className="w-full flex justify-end">
          <InputSelect
            label="Tipo de estructura"
            options={TIPOS_CUENTAS_COMERCIALES}
            topLabel={true}
            styles="!w-52"
            defaultValue="Todas"
            onChange={(e) => setFiltro({ ...filtro, tipoEstructura: e.target.value })}
          />
        </div>
        <div className="w-full flex justify-end">
          <InputBuscar
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
            value={filtro.busqueda}
          />
        </div>
      </div>
    </div>
  );
}
