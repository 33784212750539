import React, { useContext } from 'react';
import NimbusModal from '../../fragments/NimbusModal';
import CancelButton from '../../fragments/Buttons/CancelButton';
import SubmitButton from '../../fragments/Buttons/SubmitButton';
import { UserContext } from '../../services/user';
import { MESSAGES } from '../../utils/messagesUtils';

const ConfirmarVentaModal = ({
  isOpenConfirmar,
  setIsOpenConfirmar,
  handleSubmit,
  estatus,
}) => {
  const user = useContext(UserContext);
  return (
    <NimbusModal isOpen={isOpenConfirmar}>
      <h1 className='text-nimbusDark font-bold'>Atención</h1>
      <p className='w-96 px-4 sm:px-2'>
        {user.activeRole === 'DIR_PLANTEL' ||
        user.activeRole === 'ADMIN_OPERATIVO'
          ? estatus === 'PREPARANDO'
            ? MESSAGES.MESSAGES_VENTA_INFO_PREPARANDO_DIR_PLANTEL_ADMIN_OPERATIVO
            : estatus === 'COMPLETADO'
            ? MESSAGES.MESSAGES_VENTA_INFO_COMPLETADO
            : estatus === 'ENTREGADO' && MESSAGES.MESSAGES_VENTA_INFO_ENTREGADO
          : MESSAGES.MESSAGES_VENTA_INFO_PREPARANDO}
      </p>
      <div className='w-96 px-4 text-center mb-6'>
        <p className='text-nimbusDark font-bold'>
          ¿Está seguro de que desea continuar?
        </p>
      </div>
      <div className='flex justify-center gap-x-4'>
        <SubmitButton text='Continuar' onClick={handleSubmit} />
        <CancelButton
          text='Cancelar'
          bgColor='bg-divider'
          onClick={() => setIsOpenConfirmar(false)}
        />
      </div>
    </NimbusModal>
  );
};

export default ConfirmarVentaModal;
