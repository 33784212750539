import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import ArrowUp from "../../../../assets/icons/arrowDownSimple.svg";
import ArrowDown from "../../../../assets/icons/arrowUpSimple.svg";

const ProductoAtributos = ({ atributos, variaciones, atributosSeleccionados, setAtributosSeleccionados }) => {
  const [valoresDisponibles, setValoresDisponibles] = useState([Object.keys(atributos).map(() => [])]);

  const handleChangeValorSeleccionado = (atributoId, valor, atributo) => {
    let nuevosAtributos = atributosSeleccionados.map((atributo) =>
      atributo._id === atributoId ? { ...atributo, valorSeleccionado: {...valor }} : atributo
    );

    let nuevosValoresDisponibles = valoresDisponibles;

    for (let index = atributo + 1; index < valoresDisponibles.length; index++) {
      nuevosValoresDisponibles[index] = [];
      nuevosAtributos[index].valorSeleccionado._id = "";
    }

    let valoresSiguienteAtributo = variaciones;

    for (let index = 0; index < atributo + 1; index++) {
      if(atributo === index){
        valoresSiguienteAtributo = valoresSiguienteAtributo.filter(
          (variacion) => variacion.seleccionValores[index].valor._id === valor._id
        );
      }else{
        valoresSiguienteAtributo = variaciones.filter(
          (variacion) => variacion.seleccionValores[index].valor._id === atributosSeleccionados[index].valorSeleccionado._id
        );
      }

    }


    if (atributo + 1 < atributos.length) {
      nuevosValoresDisponibles[atributo + 1] = valoresSiguienteAtributo.map(
        (variacion) => variacion.seleccionValores[atributo + 1].valor._id
      );
    }

    setAtributosSeleccionados(nuevosAtributos);
    setValoresDisponibles(nuevosValoresDisponibles);
  };

  useEffect(() => {
    let atributosProducto = atributos.map((atributo) => ({ ...atributo, valorSeleccionado: { _id: "" } }));
    setAtributosSeleccionados(atributosProducto);
  }, [atributos]);

  useEffect(() => {
    let variacionesValores = variaciones.map((variacion) => variacion.seleccionValores[0].valor._id);
    let nuevosValoresDisponibles = Object.keys(atributos).map(() => []);
    nuevosValoresDisponibles[0] = variacionesValores;

    setValoresDisponibles(nuevosValoresDisponibles);
  }, [variaciones]);

  return (
    <div>
      {atributosSeleccionados.map((atributo, idx) => (
        <Disclosure as="div" key={atributo._id} className="border-t border-gray-300 py-6" defaultOpen={true}>
          {({ open }) => (
            <>
              <h3 className="-my-3 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between py-2 text-sm">
                  <div className="flex items-center gap-2">
                    <span className="font-bold">{atributo.variable}:</span>
                    {atributo?.valorSeleccionado?._id === "" ? (
                      <p className="text-xs text-red-500">Seleccione una opción</p>
                    ) : (
                      <p className="text-gray-400">{atributo?.valorSeleccionado?.valor}</p>
                    )}
                  </div>
                  <span className="ml-6 flex items-center">
                    <img className="ml-4" src={open ? ArrowDown : ArrowUp} alt="" />
                  </span>
                </Disclosure.Button>
              </h3>
              <Disclosure.Panel className="pt-6">
                <div className="space-y-4">
                  {atributo.valores.map((valor) => (
                    <div
                      key={valor._id}
                      className={`flex items-center gap-4 ${
                        valoresDisponibles[idx].includes(valor._id) ? "opacity-100" : "opacity-50"
                      }`}
                    >
                      <input
                        id={`atributo-${atributo.id}-${valor._id}`}
                        name={`${atributo._id}`}
                        value={valor.valor}
                        type="radio"
                        checked={atributo.valorSeleccionado._id === valor._id}
                        onChange={() => handleChangeValorSeleccionado(atributo._id, valor, idx)}
                        className={`${
                          valoresDisponibles[idx].includes(valor._id) && "cursor-pointer"
                        } border-2 border-gray-300`}
                        disabled={!valoresDisponibles[idx].includes(valor._id)}
                      />
                      {valor.imagen && <img src={valor.imagen} className="h-12 w-12" alt="" />}
                      <label htmlFor={`atributo-${atributo.id}-${valor._id}`} className="text-sm text-gray-600">
                        {valor.valor}
                      </label>
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default ProductoAtributos;
