import { useEffect, useState } from "react";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";

const FILTRO_LISTA_INITIAL_VALUES = {
  busqueda: "",
};

const SearchFilter = () => {
  const { usersAdded, setUsersAddedFiltered } = useListaDifusionDetalles();
  const [filtro, setFiltro] = useState(FILTRO_LISTA_INITIAL_VALUES);

  const { ver } = useListaDifusionDetalles();

  const filterDataBySearch = (usersAdded, search) => {
    if (search === "") return usersAdded;

    const newUsersAddedFiltered = usersAdded.filter(
      (user) =>
        user?.usuario?.nombres?.toLowerCase().includes(search) ||
        user?.usuario?.primerApellido?.toLowerCase().includes(search) ||
        user?.usuario?.segundoApellido?.toLowerCase().includes(search) ||
        user?.usuario?.correoElectronico?.toLowerCase().includes(search)
    );
    return newUsersAddedFiltered;
  };

  useEffect(() => {
    let newFilterdUsers = usersAdded;
    newFilterdUsers = filterDataBySearch(
      newFilterdUsers,
      filtro.busqueda.toLowerCase()
    );
    setUsersAddedFiltered(newFilterdUsers);
  }, [filtro, usersAdded]);

  return (
    <div className="w-full md:w-fit">
      <InputBuscar
        onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
      />
    </div>
  );
};

export default SearchFilter;
