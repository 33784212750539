import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { putAvisoDuplicado } from "../../../services/Api/GestorAvisos";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { useState } from "react";

function DuplicateAviso({ onCloseDuplicateModal, id, getAvisos }) {
  const [submitting, setSubmitting] = useState(false);
  const handleDuplicate = async () => {
    setSubmitting(true);
    try {
      await putAvisoDuplicado({ aviso: id });
      getAvisos();
      toast.success("Aviso duplicado correctamente.", alertConfiguration);
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setSubmitting(false);
      onCloseDuplicateModal();
    }
  };

  return (
    <div className="grid gap-y-4">
      <div className="w-full text-center">
        <h1 className="text-nimbusDark font-semibold text-2xl mb-3">
          Duplicar aviso
        </h1>
      </div>
      <div className="max-w-2xl text-lg md:px-10 w-full px-4 mb-2">
        <p>
          Al duplicar el aviso, se va a generar un aviso con el mismo contenido
          a excepción del título, y lo podrá modificar las veces que lo requiera
          para después poder enviarlo. ¿Está seguro que desea duplicar el
          aviso?.
        </p>
      </div>
      <div className="flex justify-center gap-x-4">
        <button
          className="flex items-center justify-center bg-nimbusDark w-32 h-10 rounded-lg text-white font-semibold cursor-pointer mr-5 disabled:bg-opacity-50"
          onClick={handleDuplicate}
          disabled={submitting}
        >
          <span>Confirmar</span>
        </button>

        <button
          className="flex items-center justify-center bg-gray-200 w-32 h-10 rounded-lg text-nimbusDark font-semibold cursor-pointer mr-5 disabled:bg-opacity-80"
          onClick={onCloseDuplicateModal}
          disabled={submitting}
        >
          <span>Cancelar</span>
        </button>
      </div>
    </div>
  );
}

export default DuplicateAviso;
