import React, { useContext, useEffect, useState } from 'react';
import InputSelect from '../../../fragments/Inputs/InputSelect';
import {
  ESTATUS_PEDIDO_FILTER
} from '../../../utils/MisVentasUtils';
import InputBuscar from '../../../fragments/Inputs/InputBuscar';
import { formatDate } from '../../../utils/dateFunctions';
import { UserContext } from '../../../services/user';

const FILTRO_MIS_VENTAS_INITIAL_VALUES = {
  busqueda: '',
  estatus: '',
};

const MisVentasFilter = ({
  misVentasList,
  misVentasFiltro,
  setMisVentasFiltro,
}) => {
  const user = useContext(UserContext);
  const [filtro, setFiltro] = useState(FILTRO_MIS_VENTAS_INITIAL_VALUES);

  const filterDataBusqueda = (ventas, busqueda) => {
    if (busqueda === '') return ventas;

    const newMisVentasFiltro = ventas.filter((venta) => {
      if (
        user.activeRole === 'DIR_PLANTEL' ||
        user.activeRole === 'ADMIN_OPERATIVO'
      ) {
        return (
          formatDate(new Date(venta.fechaCreacion))
            .toString()
            .includes(busqueda) || venta._id.toLowerCase().includes(busqueda)
        );
      } else {
        return (
          formatDate(new Date(venta.fechaCreacion))
            .toString()
            .includes(busqueda) || venta.compra.toLowerCase().includes(busqueda)
        );
      }
    });
    return newMisVentasFiltro;
  };

  const filterSelectEstatus = (ventas, estatus) => {
    if (estatus === '') return ventas;
    const newMisVentasFiltro = ventas.filter(
      (venta) => venta.estatus === estatus
    );
    return newMisVentasFiltro;
  };

  useEffect(() => {
    let newMisVentasFiltro = misVentasList;
    newMisVentasFiltro = filterDataBusqueda(
      newMisVentasFiltro,
      filtro.busqueda.toLowerCase()
    );
    newMisVentasFiltro = filterSelectEstatus(
      newMisVentasFiltro,
      filtro.estatus
    );
    setMisVentasFiltro(newMisVentasFiltro);
  }, [filtro, misVentasList]);

  return (
    <div className='w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start'>
      <div className='flex gap-x-5'>
        <div className='flex flex-wrap items-center'>
          <p className='mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center'>
            {misVentasFiltro.length}
          </p>
          <p className='text-black'>Pedidos</p>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row space-x-3 items-end lg:items-start'>
        <div className='flex flex-col gap-3 w-full'>
          <div className='w-full sm:flex sm:justify-end'>
            <InputSelect
              label='Estatus del pedido'
              topLabel={true}
              options={ESTATUS_PEDIDO_FILTER}
              styles='!w-full sm:!w-80 lg:!w-48 xl:!w-40 2xl:!w-48'
              onChange={(e) =>
                setFiltro({ ...filtro, estatus: e.target.value })
              }
            />
          </div>
        </div>
        <div className='flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit'>
          <InputBuscar
            onChange={(e) =>
              setFiltro({ ...filtro, busqueda: e.target.value})
            }
            value={filtro.busqueda}
          />
        </div>
      </div>
    </div>
  );
};

export default MisVentasFilter;
