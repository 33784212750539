export default function SubmitButton({
  text,
  onClick,
  disabled = false,
  bgColor = "bg-nimbusDark",
  styles = "",
  loading = false,
  loadingButton = false,
}) {
  return (
    <div
      className={`text-white font-semibold rounded-md w-1/3 flex items-center justify-center ${bgColor} ${styles} ${
        (disabled || loading) && "bg-opacity-90"
      }`}
    >
      {loadingButton && loading ? (
        <>
          <svg
            className="w-5 h-5 -ml-1 text-white animate-spin mr-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Cargando...
        </>
      ) : (
        <button className="font-semibold w-full h-full rounded-sm" disabled={disabled} onClick={onClick}>
          {text}
        </button>
      )}
    </div>
  );
}
