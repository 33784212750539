import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import SubHeaderAsignarProductos from "./fragments/SubHeaderAsignarProductos";
import AsignarProductosFilter from "./fragments/AsignarProductosFilter";
import AsignarProductosTable from "./fragments/AsignarProductosTable";
import AccionesMasivas from "./fragments/AccionesMasivas";
import { usePagination } from "../../hooks/usePagination";
import TablePagination from "../../fragments/TablePagination";
import NoDataMessage from "../../fragments/NoDataMessage";
import { MESSAGES } from "../../utils/messagesUtils";
import { AsignarArticulosContext } from "../../../contexts/AsignarProductosContext";
import {
  inicializarArticulos,
  seleccionarArticuloLista,
} from "../../../reducers/asginarArticulos/asignarArticulosAction";
import { UserContext } from "../../services/user";
import CancelButton from "../../fragments/Buttons/CancelButton";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../../fragments/Buttons/SubmitButton";
import useFormSubmit from "../../hooks/useFormSubmit";
import { obtenerUserEstructuraInfo } from "../../utils/userUtils";
import {
  getAsignarArticulos,
  getAsignarArticulosGrupos,
  postAsignarArticulos,
  postAsignarArticulosGrupos,
} from "../../services/Api/MarketPlace/AsignarArticulos";
import { toast } from "react-toastify";
import alertConfiguration from "../../utils/alertsUtils";
import LoadingScreen from "../../fragments/LoadingScreen";
import { getPaises } from "../../services/api";
import { PaisesContext } from "../../../contexts/PaisesContext";

const AsignarProductos = () => {
  const { articulos, habilitarBoton, dispatch } = useContext(AsignarArticulosContext);
  const [articulosFiltro, setArticulosFiltro] = useState(articulos);
  const [loading, setLoading] = useState(true);
  const { user, activeRole } = useContext(UserContext);
  const { obtenerPaises } = useContext(PaisesContext);
  const [tipoDeMoneda, setTipoDeMoneda] = useState(["Moneda", "del país"]);
  const navigate = useNavigate(); 
  const params = useParams();

  const [accionesMasivasShow, setAccionesMasivasShow] = useState(false);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(articulosFiltro, 20);

  const seleccionarArticulo = (articuloASeleccionar) => {
    dispatch(seleccionarArticuloLista(articulos, articuloASeleccionar));
  };

  const obtenerBodyRequestGrupos = () => {
    let productosRequest = [];
    let paquetesRequest = [];

    articulos.forEach((articulo) => {
      let articuloRequest = {
        ...articulo,
        costo: parseFloat(articulo.precioAsignacion),
      };

      if (articulo.esPaquete) {
        articuloRequest.paquete = articulo.articulo;
        articuloRequest.paquete._id = articulo.articuloListaId;
        paquetesRequest.push(articuloRequest);
      } else {
        articuloRequest.producto = articulo.articulo;
        articuloRequest.producto._id = articulo.articuloListaId;
        productosRequest.push(articuloRequest);
      }
    });

    let body = {
      productos: productosRequest,
      paquetes: paquetesRequest,
    };

    return body;
  };

  const obtenerBodyRequest = () => {
    const { tipoEstructuraRequest, estructuraId, siguienteEstructura } = obtenerUserEstructuraInfo(user, activeRole);
    let productosRequest = [];
    let paquetesRequest = [];

    articulos.forEach((articulo) => {
      let articuloRequest = {
        ...articulo,
        costo: parseFloat(articulo.precioAsignacion),
        provieneDe: {
          _id: articulo.articuloListaId,
        },
      };

      if (articulo.esPaquete) {
        articuloRequest.paquete = articulo.articulo;
        paquetesRequest.push(articuloRequest);
      } else {
        articuloRequest.producto = articulo.articulo;
        productosRequest.push(articuloRequest);
      }
    });

    let body = {
      entidad_origen: {
        [tipoEstructuraRequest]: { _id: estructuraId },
      },
      entidad_destino: {
        [siguienteEstructura]: { _id: params.estructuraId },
      },
      productos: productosRequest,
      paquetes: paquetesRequest,
    };

    return body;
  };

  const asignarProductosSubmit = async () => {
    try {
      let body = {};
      if (activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO") {
        body = await obtenerBodyRequestGrupos();
        const { estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
        await postAsignarArticulosGrupos(estructuraId, params.estructuraId, body);
      } else {
        body = await obtenerBodyRequest();
        await postAsignarArticulos(body);
      }
      toast.success("Artículos guardados correctamente", alertConfiguration);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(asignarProductosSubmit);

  const obtenerArticulosAsignados = useCallback(async () => {
    if (activeRole !== "") {
      try {
        const { estructura, estructuraId, siguienteEstructura } = obtenerUserEstructuraInfo(user, activeRole);
        let asignacionArticulosResponse = [];
        let nuevosArticulos = [];
        await obtenerPaises();

        if (activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO") {
          asignacionArticulosResponse = await getAsignarArticulosGrupos(estructuraId, params.estructuraId);
          nuevosArticulos = asignacionArticulosResponse.map((articulo) => {
            const esPaquete = articulo.paquete ? true : false;
            articulo.articuloListaId = esPaquete ? articulo.paquete._id : articulo.producto._id;
            articulo.articulo = esPaquete ? articulo.paquete.paquete : articulo.producto.producto;
            articulo.esPaquete = esPaquete;
            articulo.origen = esPaquete ? articulo.paquete.origen : articulo.producto.origen;
            articulo.precioVenta = esPaquete ? articulo.paquete.precioVenta : articulo.producto.precioVenta;
            articulo.precioAsignacion = articulo.costo;
            articulo.costo = esPaquete ? articulo.paquete.costo : articulo.producto.costo;
            articulo.ganancia = articulo.precioAsignacion - articulo.costo;
            if (esPaquete) articulo.articulo.categoria = "Paquetes";

            return articulo;
          });
        } else {
          asignacionArticulosResponse = await getAsignarArticulos(siguienteEstructura, params.estructuraId);
          asignacionArticulosResponse = [
            ...asignacionArticulosResponse.productos,
            ...asignacionArticulosResponse.paquetes,
          ];
          nuevosArticulos = asignacionArticulosResponse.map((articulo) => {
            const esNuevoArticulo = articulo.provieneDe.origen === "Nuevo" ? true : false;
            const esPaquete = articulo.paquete ? true : false;
            articulo.articuloListaId = articulo.provieneDe._id;
            articulo.articulo = esPaquete ? articulo.paquete : articulo.producto;
            articulo.esPaquete = esPaquete;
            articulo.origen = esNuevoArticulo ? "Nuevo" : "Linea";
            articulo.precioVenta = articulo.provieneDe.precioVenta;
            articulo.precioAsignacion = articulo.costo;
            articulo.costo = articulo.provieneDe.costo;
            articulo.ganancia = articulo.precioAsignacion - articulo.costo;
            if (esPaquete) articulo.articulo.categoria = "Paquetes";

            return articulo;
          });
        }

        dispatch(inicializarArticulos(nuevosArticulos));
        const paises = await getPaises();

        if (estructura.dirFiscalPais) {
          const simbolo =
            estructura.dirFiscalPais.nombre === "Estados Unidos"
              ? ["USD"]
              : estructura.dirFiscalPais.simbolo
              ? [estructura.dirFiscalPais.simbolo]
              : [paises.data.filter((pais) => pais._id === estructura.dirFiscalPais)[0]?.simbolo];
          setTipoDeMoneda(simbolo);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [activeRole]);

  useEffect(() => {
    obtenerArticulosAsignados();
  }, [obtenerArticulosAsignados]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderAsignarProductos userRole={activeRole} />
            <div
              className={`${articulos.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}
            >
              <div>
                <div className="text-[#6E6D7A] w-full mt-6">
                  {activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO" ? (
                    <p>
                      Seleccione los artículos que desea agregar al paquete escolar de este grupo, y que a los padres de
                      familia de los alumnos agregados en el grupo, les van a aparecer en la sección de Mi paquete
                      escolar.
                    </p>
                  ) : (
                    <p>
                      Seleccione los artículos que desea asignar a la estructura, y establezca el precio que requiere
                      para cada uno de ellos. El precio aquí establecido será único y exclusivo para esta estructura.
                    </p>
                  )}
                </div>
                <AsignarProductosFilter
                  articulosList={articulos}
                  articulosFiltro={articulosFiltro}
                  setArticulosFiltro={setArticulosFiltro}
                  accionesMasivasShow={accionesMasivasShow}
                  setAccionesMasivasShow={setAccionesMasivasShow}
                  activeRole={activeRole}
                />
                {accionesMasivasShow && (
                  <AccionesMasivas articulosMostrados={currentData()} setAccionesMasivasShow={setAccionesMasivasShow} />
                )}
                <AsignarProductosTable
                  articulosList={currentData()}
                  accionesMasivasShow={accionesMasivasShow}
                  seleccionarArticulo={seleccionarArticulo}
                  activeRole={activeRole}
                  tipoDeMoneda={tipoDeMoneda}    
                />
                {articulos.length !== 0 && articulosFiltro.length === 0 && (
                  <NoDataMessage message={MESSAGES.MESSAGES_FILTER_ARTICULOS} />
                )}
              </div>
              <div>
                {articulos.length === 0 ? (
                  <NoDataMessage message={MESSAGES.MESSAGES_ASIGNAR_PRODUCTOS_TABLE} />
                ) : (
                  <>
                    <TablePagination
                      next={next}
                      prev={prev}
                      jump={jump}
                      data={currentData()}
                      currentPage={currentPage}
                      maxPage={maxPage}
                    />
                  </>
                )}
                <div className="flex justify-center gap-x-4 mt-6">
                  <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                    <CancelButton text={"Cancelar"} bgColor="bg-divider" onClick={() => navigate(-1)} />
                    <SubmitButton
                      text="Guardar"
                      disabled={!habilitarBoton}
                      onClick={handleSubmit}
                      loading={formStatus === "submitting"}
                      loadingButton
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AsignarProductos;
