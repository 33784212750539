import React from 'react';
import MisVentasRow from './MisVentasRow';

const MisVentasTable = ({ misVentasList }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th className='w-[7rem]'></th>
            <th>ID del pedido</th>
            <th>Fecha del pedido</th>
            <th>Estatus del pedido</th>
            <th className='w-[3.5rem]'></th>
          </tr>
        </thead>
        <tbody>
          {misVentasList.map((venta) => (
            <MisVentasRow key={venta._id} ventaInfo={venta} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MisVentasTable;
