import React from 'react';
import NimbusModal from '../../fragments/NimbusModal';
import CancelButton from '../../fragments/Buttons/CancelButton';
import SubmitButton from '../../fragments/Buttons/SubmitButton';

const ConfirmarFacturaModal = ({
  isOpenConfirmar,
  setIsOpenConfirmar,
  handleSubmit,
}) => {
  return (
    <NimbusModal isOpen={isOpenConfirmar}>
      <h1 className='text-nimbusDark font-bold'>Generar factura</h1>
      <p className='w-96 px-4 sm:px-2'>
        Si la información que ha ingresado es incorrecta, la factura no podrá
        ser generada exitosamente, por lo cual, le invitamos a revisar
        cuidadosamente antes de confirmar el envío
      </p>
      <div className='w-96 px-4 text-center mb-6'>
        <p className='text-nimbusDark font-bold'>
          ¿Está seguro que la información es correcta?
        </p>
      </div>
      <div className='flex justify-center gap-x-4'>
        <CancelButton
          text='Cancelar'
          bgColor='bg-divider'
          onClick={() => setIsOpenConfirmar(false)}
        />
        <SubmitButton
          text='Aceptar'
          onClick={handleSubmit}
        />
      </div>
    </NimbusModal>
  );
};

export default ConfirmarFacturaModal;
