import React from "react";
import ProductosRow from "./ProductosRow";

const ProductosTable = ({ productos, tipoDeMoneda, ver, setIsEnableSubmitButton}) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            <th>Nombre del producto</th>
            <th className="flex gap-2 items-center justify-center">
              Precio de venta ({tipoDeMoneda[0]})
            </th>
            <th>Cantidad</th>
            <th className="flex gap-2 items-center justify-center">
              Precio total ({tipoDeMoneda[0]})
            </th>
            <th>Estatus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto) => (
            <ProductosRow key={producto.productoListaId} productoInfo={producto} ver={ver} setIsEnableSubmitButton={setIsEnableSubmitButton}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductosTable;
