import CategoriasIcon from "../../assets/icons/categoriasIcon.svg";
import ProductosDestacadosIcon from "../../assets/icons/productosDestacados.svg";
import PaquetesIcon from "../../assets/icons/paquetesIcon.svg";

export const navigation = {
  categories: [
    {
      id: "categorias",
      name: "Categorías",
      sections: [
        {
          id: "clothing",
          name: "dsx",
          items: [
            { name: "Libros impresos", href: "/marketplace/tienda/LibrosImpresos", icon: "" },
            { name: "Libros digitales", href: "/marketplace/tienda/LibrosDigitales", icon: "" },
            { name: "Uniformes", href: "/marketplace/tienda/Uniformes", icon: "" },
            { name: "Útiles escolares", href: "/marketplace/tienda/UtilesEscolares", icon: "" },
          ],
        },
        {
          id: "accessories",
          name: "dss",
          items: [
            { name: "Academias", href: "/marketplace/tienda/Academias", icon: "" },
            { name: "Hardware", href: "/marketplace/tienda/Hardware", icon: "" },
            { name: "Plataformas", href: "/marketplace/tienda/Plataformas", icon: "" },
            { name: "Servicios escolares", href: "/marketplace/tienda/ServiciosEscolares", icon: "" },
          ],
        },
        {
          id: "brands",
          name: "das",
          items: [
            { name: "Cursos y capacitaciones", href: "/marketplace/tienda/CursosYCapacitaciones", icon: "" },
            { name: "Eventos", href: "/marketplace/tienda/Eventos", icon: "" },
            { name: "Otros", href: "/marketplace/tienda/Otro", icon: "" },
          ],
        },
      ],
      icon: CategoriasIcon,
    },
    {
      id: "productosdestacados",
      name: "Artículos destacados",
      featured: [],
      sections: [],
      icon: ProductosDestacadosIcon,
      href: "/marketplace/tienda/ProductosDestacados"
    },
    {
      id: "paquetes",
      name: "Paquetes",
      featured: [],
      sections: [],
      icon: PaquetesIcon,
      href: "/marketplace/tienda/Paquetes"
    },
  ],
  pages: [
    { name: "Artículos destacados", href: "/marketplace/tienda/ProductosDestacados", icon: ProductosDestacadosIcon },
    { name: "Paquetes", href: "/marketplace/tienda/Paquetes", icon: PaquetesIcon },
  ],
};


export const RUTAS_MAP = {
  ProductosDestacados: "Artículos destacados",
  LibrosImpresos: "Libros impresos",
  LibrosDigitales: "Libros digitales",
  Uniformes: "Uniformes",
  UtilesEscolares: "Útiles escolares",
  Academias: "Academias",
  Hardware: "Hardware",
  Plataformas: "Plataformas",
  ServiciosEscolares: "Servicios escolares",
  CursosYCapacitaciones: "Cursos y capacitaciones",
  Eventos: "Eventos",
  Otro: "Otros",
  Paquetes: "Paquetes",
  favoritos:"Mis favoritos"
}