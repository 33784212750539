import { useState } from "react";
import ModalInfo from "../../fragments/Modals/ModalInfo";
import { getEditoresEditorial } from "../../services/Api/Editoriales";
import { useContext } from "react";
import { UserContext } from "../../services/user";
import InputSelect from "../../gestion/Roles/fragments/Inputs/InputSelect";
import { getFullUsername } from "../../utils/rolesUtils";
import { actualizarAutorUma, editPropietearioLibro } from "../../services/api";
import { errorToast, successToast } from "./ActividadesUtils/FormularioUtils";
import ReactLoading from "react-loading";
import UserIcon from "../../../assets/icons/userList.svg";
import TooltipButton from "../../fragments/Buttons/TooltipButton";

function TransferirPropiedad({ seleccionados, reload, contentType }) {
  const { user } = useContext(UserContext);
  const [modalValue, setModalValue] = useState(false);
  const [editores, setEditores] = useState([]);
  const [editorValue, setEditorValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFetchEditores = () => {
    if (!user?.editorial?._id) return;
    setLoading(true);
    setModalValue(true);
    getEditoresEditorial(user.editorial._id)
      .then((r) => {
        setLoading(false);
        if (!r.editoresEditorial) return;
        const editoresEditorial = [
          ...r?.editoresEditorial,
          ...r?.autoresEditorial,
        ].map((el) => ({
          ...el,
          nombre: getFullUsername(el),
        }));
        setEditores(editoresEditorial);
      })
      .catch((err) => {
        setLoading(false);
        errorToast("Hubo un error al hacer la búsqueda de editores y autores.");
      });
  };

  const toggleModal = () => {
    setModalValue(!modalValue);
  };

  const handleSave = () => {
    if (!editorValue) return;
    setLoading(true);
    const dataToSave = seleccionados.map((el) => {
      const body = {
        creador: editorValue,
      };
      if(contentType === 'LIBROS')return editPropietearioLibro(el, body);
      else if(contentType === "LECCIONES") return actualizarAutorUma(el, body)
    });

    Promise.allSettled(dataToSave)
      .then(() => {
        setLoading(false);
        setModalValue(false);
        successToast("Se han guardado tus cambios con éxito.");
        reload();
      })
      .catch(() => {
        setLoading(false);
        errorToast(
          "Hubo un problema al guardar tus cambios. Inténtalo de nuevo en unos momentos."
        );
      });
  };

  return (
    <>
      <TooltipButton
        icon={UserIcon}
        message={"Transferir propiedad"}
        handleClick={handleFetchEditores}
        styles={"w-auto h-10 ml-2"}
        tooltipText="Transferir propiedad"
      />
      <ModalInfo isOpen={modalValue} toggleModal={toggleModal} hideBtn>
        <div className="py-4 text-center text-nimbusDark">
          <div className="mb-4">
            Selecciona el nuevo propietario para{" "}
            {seleccionados.length === 1
              ? "el contenido seleccionado"
              : `los ${seleccionados.length} contenidos seleccionados`}
          </div>
          {loading ? (
            <ReactLoading
              type="spin"
              color="#1A3D82"
              height="80px"
              width="80px"
              className=" mr-auto ml-auto mt-10"
            />
          ) : (
            <>
              <InputSelect
                options={editores}
                onChange={(e) => setEditorValue(e.target.value)}
              />
              <div className="mt-10 flex items-center justify-center">
                <button
                  className="bg-gray-200 font-semibold text-nimbusDark p-2 w-28 rounded mx-1"
                  onClick={toggleModal}
                >
                  Cancelar
                </button>
                <button
                  className="bg-nimbusDark font-semibold text-white p-2 w-28 rounded block mx-1"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </>
          )}
        </div>
      </ModalInfo>
    </>
  );
}

export default TransferirPropiedad;
