import CheckedIcon from "./CheckedIcon";

const ReporteAvisosDetallesRow = ({ destinatario }) => {
  const fullname = `${destinatario?.usuario?.nombres} ${destinatario?.usuario?.primerApellido} ${destinatario?.usuario?.segundoApellido}`;

  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-2 rounded-l-lg border-r-0 font-sans text-left px-2">
          {fullname}
        </td>
        <td className="border-y-2 font-sans text-center">
          {destinatario?.usuario?.correoElectronico}
        </td>
        <td className="border-2 rounded-r-lg border-l-0 font-sans">
          <div className="flex justify-center items-center">
            <CheckedIcon isRead={destinatario?.esLeido} />
          </div>
        </td>
      </tr>
    </>
  );
};

export default ReporteAvisosDetallesRow;
