import React from "react";
import { ReactComponent as MisComprasCircleIcon } from "../../../../assets/icons/misComprasCircleIcon.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../utils/productoUtils";
import { formatDate } from "../../../utils/dateFunctions";
import { METODOS_DE_PAGO } from "../../../utils/misComprasUtils";

const MisComprasRow = ({ compraInfo, abreviatura }) => {
  const { _id, fechaCreacion, total, metodoDePago, estatus, esPagado } = compraInfo;

  const navigate = useNavigate();

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-lg border-r-0 text-left font-sans">
        <MisComprasCircleIcon className="mx-auto" />
      </td>
      <td className="border-y-2 font-sans text-left">{_id}</td>
      <td className="border-y-2 font-sans text-left">{formatDate(new Date(fechaCreacion))}</td>
      <td className="border-y-2 font-sans text-right pr-4">{formatCurrency(total)}</td>
      <td className="border-y-2 font-sans text-left">{METODOS_DE_PAGO[metodoDePago]}</td>
      <td
        className={`border-y-2 font-sans text-left ${
          estatus === "CANCELADA" || estatus === "PAGO_FALLIDO"
            ? "text-[#EB0000]"
            : esPagado
            ? "text-[#17A300]"
            : "text-[#B89A00]"
        }`}
      >
        {estatus === "CANCELADA" || estatus === "PAGO_FALLIDO" ? "CANCELADO" : esPagado ? "COMPLETADO" : "PENDIENTE"}
      </td>
      <td
        className={`border-y-2 font-sans text-left ${
          estatus === "ENTREGADO"
            ? "text-nimbusDark"
            : estatus === "COMPLETADO"
            ? "text-[#17A300]"
            : estatus === "PREPARANDO"
            ? "text-[#B89A00]"
            : "text-[#EB0000]"
        }`}
      >
        {estatus === "PAGO_FALLIDO" ? "CANCELADO" : estatus}
      </td>
      <td className="border-2 rounded-r-md border-l-0 min-h-[52px] text-left font-sans">
        <ViewIcon
          className="mx-auto cursor-pointer"
          title="Ver compra"
          onClick={() =>
            navigate(`/marketplace/misCompras/${_id}`, {
              state: {
                compraInfo: compraInfo,
                abreviatura: abreviatura,
              },
            })
          }
        />
      </td>
    </tr>
  );
};

export default MisComprasRow;
