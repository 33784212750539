import {postArchivos} from '../../services/api'

export class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
            return this.loader.file
            .then( uploadedFile => {
                if(uploadedFile.size <= 150000){
                    return new Promise( ( resolve, reject ) => {
                    let data = new FormData()
                    data.set('file',uploadedFile)
                    return postArchivos(data)
                    .then( response => {
                        if (response.data) {
                            resolve({default:response.data});
                        } else {
                            alert()
                            reject( response.data );
                        }
                    } ).catch( response => {
                        reject( 'Upload failed' );
                    } );
                    } );
                }else{
                    alert('Asegúrate de que tu archivo sea menor a 150kb. Te recomendamos comprimir tu archivo.')
                    return
                }
            } );
    }

    abort() {
    }
}