export default function NumeroActividad({imagen,texto, textoEditado}) {
    return(
        <div className="w-full grid justify-items-center my-6 bg-white rounded-md py-2">
            {/* <div className="">
                <p className="font-sans text-2xl text-nimbus">{texto}</p>
            </div> */}
            <div className='w-16 border-8 h-16 rounded-full flex justify-center items-center border-blue-700 mb-2'>
                <p className='font-sans text-2xl text-nimbus font-bold'>{texto}</p>
            </div>
            <div className="break-words max-width-[30rem] mx-auto">
                <p  dangerouslySetInnerHTML={{__html:textoEditado === undefined ? '<span style="color:#1a3d82;font-size:19px;"><strong>Actividad</strong></span>' : textoEditado}} className=""></p>
            </div>
        </div>
    )
}