function AddUsersDescription() {
  return (
    <>
      <h1 className="text-nimbus font-bold">Agregar destinatarios</h1>
      <p className="text-nimbusLight font-bold">
        Seleccione la estructura de la cual requiere consultar los usuarios
        existentes, y utilice el filtro para buscar usuarios específicos y
        añadirlos a la lista de difusión que se está creando. Puede añadir
        destinatarios de forma individual o masiva según lo requiera pulsando el
        botón correspondiente. Una vez que haya añadido todos los destinatarios
        que desee, debe cerrar el modal para continuar con la creación de su
        lista de difusión.
      </p>
    </>
  );
}

export default AddUsersDescription;
