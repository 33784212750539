import React, { useEffect, useState } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";

const FILTRO_ALUMNOS_INITIAL_VALUES = {
  busqueda: "",
};

const MiPaqueteEscolarFilter = ({ alumnosList, alumnosFiltro, setAlumnosFiltro }) => {
  const [filtro, setFiltro] = useState(FILTRO_ALUMNOS_INITIAL_VALUES);

  const filterDataBusqueda = (alumnos, busqueda) => {
    if (busqueda === "") return alumnos;

    const newAlumnosFiltro = alumnos.filter((alumno) => {
      let nombre = alumno.alumno.usuario.nombres;
      let primerApellido = alumno.alumno.usuario.primerApellido ?? "";
      let segundoApellido = alumno.alumno.usuario.segundoApellido ?? "";
      let nombreCompleto = nombre + " " + primerApellido + " " + segundoApellido;
      return nombreCompleto.toLowerCase().includes(busqueda);
    });

    return newAlumnosFiltro;
  };

  useEffect(() => {
    let newAlumnosFiltro = alumnosList;
    newAlumnosFiltro = filterDataBusqueda(newAlumnosFiltro, filtro.busqueda.toLowerCase());
    setAlumnosFiltro(newAlumnosFiltro);
  }, [filtro, alumnosList]);

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {alumnosFiltro.length}
          </p>
          <p className="text-black">Alumnos</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full lg:w-fit">
          <InputBuscar
            styles="lg:w-[30rem]"
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value})}
            value={filtro.busqueda}
          />
        </div>
      </div>
    </div>
  );
};

export default MiPaqueteEscolarFilter;
