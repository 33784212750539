import React, { useContext } from "react";
import validarAcceso from "../utils/checkUserAccess";
import { UserContext } from "../services/user";

const withUserAccesability = (WrappedComponent) => {
  const WithUserAccesability = (props) => {
    const { user } = useContext(UserContext);
    const isUserAllowed = validarAcceso(user.correoElectronico);

    return <WrappedComponent isUserAllowed={isUserAllowed} {...props} />;
  };

  return WithUserAccesability;
};

export default withUserAccesability;
