export const MESSAGES = {
  MESSAGE_USERS_TABLE:
    'Aún no tiene usuarios agregados, seleccione el botón "Agregar usuario"',
  MESSAGE_PACKAGE_TABLE:
    'Aún no tiene paquetes agregados, seleccione el botón "Crear paquete"',
  MESSAGE_EDITORIALES_TABLE:
    'Aún no tiene editoriales agregadas, seleccione el botón "Agregar editorial"',
  MESSAGE_DISTRIBUCIONES_TABLE:
    'Aún no tiene distribuciones agregadas, seleccione el botón "Agregar distribución"',
  MESSAGE_CORPORATIVOS_TABLE:
    'Aún no tiene corporativos agregados, seleccione el botón "Agregar corporativo"',
  MESSAGE_CORPORATIVOS_TABLE_ASESOR: "Aún no tiene corporativos agregados",
  MESSAGE_LIBROS: "Aún no existen libros en el escaparate",
  MESSAGE_LIBROS_FAVORITOS: "Aún no ha agregado ningún libro como favorito",
  MESSAGE_LIBROS_DISPONIBLES_PAQUETE: "No tienes libros disponibles",
  MESSAGE_LIBROS_AGREGADOS_PAQUETE: "Aún no tiene libros agregados al paquete",
  MESSAGE_RECURSOS_DISPONIBLES: "No tiene recursos disponibles para publicar",
  MESSAGE_RECURSOS_AGREGADOS_CORPORATIVO:
    "No tiene recursos publicados en este corporativo",
  MESSAGE_RECURSOS_AGREGADOS_DISTRIBUCION:
    "No tiene recursos publicados en esta distribución",
  MESSAGE_RECURSOS_AGREGADOS_PLANTEL:
    "No tiene recursos publicados en este plantel",
  MESSAGE_ELIMINAR_UMA: [
    "Al eliminar una lección se enviará a la papelera, posteriormente si lo desea puede recuperarla.",
    "¿Está seguro que desea eliminar esta lección?",
  ],
  MESSAGE_ELIMINAR_UMA_PAPELERA: [
    "Al eliminar la lección no podrá revertir la acción posteriormente.",
    "¿Está seguro que desea eliminar esta lección?",
  ],
  MESSAGE_COPIAR_UMA_PRIMIGENIO: [
    "Estas duplicando una lección primigenia por lo que pasa a ser propia y pierde la liga a la lección de origen, es decir, las actualizaciones que se hagan en la lección de origen ya no se verán reflejadas. Si quieres obtener las últimas actualizaciones, deberás volver a duplicar la lección primigenia, pero será totalmente independiente del duplicado anterior.",
    "¿Está seguro que desea duplicar esta lección?",
  ],
  MESSAGE_COPIAR_UMA: [
    "Al duplicar la lección, está creando otra lección con el mismo contenido de esta, la cual podrá modificar completamente para trabajarla como propia, pero la referencia del autor de la lección del cual duplicó se mantiene.",
    "¿Está seguro que desea duplicar esta lección?",
  ],
  MESSAGE_ELIMINAR_EVALUACION: [
    "Al eliminar una evaluación no podrá revertir la acción posteriormente.",
    "¿Está seguro que desea eliminar esta evaluación?",
  ],
  MESSAGE_ELIMINAR_EXAMEN: [
    "Al eliminar un examen no podrá revertir la acción posteriormente.",
    "¿Está seguro que desea eliminar este examen?",
  ],
  MESSAGE_COPIAR_EVALUACION_PRIMIGENIO: [
    "Estas duplicando una evaluación primigenia por lo que pasa a ser propia y pierde la liga a la evaluación de origen, es decir, las actualizaciones que se hagan en la evaluación de origen ya no se verán reflejadas. Si quieres obtener las últimas actualizaciones, deberás volver a duplicar la evaluación primigenia, pero será totalmente independiente del duplicado anterior.",
    "¿Está seguro que desea duplicar esta evaluación?",
  ],
  MESSAGE_COPIAR_EVALUACION: [
    "Al duplicar la evaluación, está creando otra evaluación con el mismo contenido de esta, la cual podrá modificar completamente para trabajarla como propia, pero la referencia del autor de la evaluación del cual duplicó se mantiene.",
    "¿Está seguro que desea duplicar esta evaluación?",
  ],
  MESSAGE_COPIAR_EXAMEN: [
    "Al duplicar el examen, está creando otro examen con el mismo contenido de este, el cual podrá modificar completamente para trabajarlo como propio, pero la referencia del autor del examen del cual duplicó se mantiene.",
    "¿Está seguro que desea duplicar este examen?",
  ],
  MESSAGE_FILTER_USERS_TABLE: "No se encontraron usuarios",
  MESSAGE_FILTER_ALUMNOS_TABLE: "No se encontraron alumnos",
  MESSAGE_FILTER_TUTORES_TABLE: "No se encontraron padres, madres o tutores",
  MESSAGE_FILTER_PACKAGE_TABLE: "No se encontraron paquetes",
  MESSAGE_FILTER_EDITORIALES_TABLE: "No se encontraron editoriales",
  MESSAGE_FILTER_DISTRIBUCIONES_TABLE: "No se encontraron distribuciones",
  MESSAGE_FILTER_CORPORATIVOS_TABLE: "No se encontraron corporativos",
  MESSAGE_FILTER_LIBROS: "No se encontraron libros",
  MESSAGE_FILTER_LIBROS_FAVORITOS: "No se encontraron libros favoritos",
  MESSAGE_FILTER_LIBROS_DISPONIBLES_PAQUETE:
    "No se encontraron libros disponibles",
  MESSAGE_FILTER_LIBROS_AGREGADOS_PAQUETE: "No se encontraron libros agregados",
  MESSAGE_FILTER_RECURSOS_DISPONIBLES: "No se encontraron recursos disponibles",
  MESSAGE_FILTER_RECURSOS_AGREGADOS: "No se encontraron recursos agregados",
  MESSAGE_ALUMNOS_GRUPO:
    "No se cuenta con alumnos disponibles para inscribir al grupo",
  MESSAGE_ALUMNOS_LOGROS: "No se encontraron resultados",
  MESSAGE_CONFIGURACION_MARKETPLACE_ADMIN:
    "Debe completar la configuración para habilitar el marketplace en toda la plataforma",
  MESSAGE_CONFIGURACION_MARKETPLACE_PLANTEL:
    "Debe completar la configuración del marketplace para habilitar las demás funciones del módulo",
  MESSAGE_CONFIGURACION_MARKETPLACE_USUARIOS:
    "Debe completar la configuración del marketplace para habilitar las demás secciones del módulo",
  MESSAGE_CUENTAS_COMERCIALES_TABLE:
    "Aún no existen cuentas comerciales registradas dentro de la plataforma",
  MESSAGE_PRODUCTOS_TABLE:
    'Aún no tiene productos agregados. De clic en "Agregar producto"',
  MESSAGE_FILTER_PRODUCTOS_TABLE: "No se encontraron productos",
  MESSAGE_PAQUETES_PRODUCTOS_TABLE:
    'Aún no tiene paquetes agregados en su estructura. De clic en "Crear paquete"',
  MESSAGE_FILTER_PAQUETES_PRODUCTOS_TABLE: "No se encontraron paquetes",
  MESSAGE_FILTER__PRODUCTOS_PAQUETE_TABLE:
    "No se encontraron productos existentes",
  MESSAGE_PAQUETE_SIN_PRODUCTOS_TABLE:
    'Aún no tiene productos agregados en este paquete. De clic en "Agregar productos"',
  MESSAGES_PRODUCTOS_FAVORITOS: "Aún no se han agregado artículos a favoritos",
  MESSAGES_FILTER_PRODUCTOS_FAVORITOS: "No se encontraron artículos favoritos",
  MESSAGES_PRODUCTOS_TIENDA:
    "No se encontraron artículos existentes dentro de la tienda",
  MESSAGES_PRODUCTOS_DESTACADOS_TIENDA:
    "No se encontraron artículos destacados existentes dentro de la tienda",
  MESSAGES_MIS_COMPRAS_TABLE: "Aún no ha realizado ninguna compra",
  MESSAGES_SIN_COMPRAS_TABLE: "No se encontraron compras",
  MESSAGES_ASIGNAR_ARTICULOS_TABLE:
    "Aún no hay artículos disponibles para asignar",
  MESSAGES_ARTICULOS_CARRITO: "No hay artículos en el carrito de compra",
  MESSAGES_VARIACIONES_PRODUCTO:
    'De momento no se han creado variaciones. De clic en "Agregar variación"',
  MESSAGES_ASIGNAR_PRODUCTOS_TABLE:
    'Aún no tiene artículos asignados en esta estructura, de clic en "Asignar artículos"',
  MESSAGES_ESTRUCTURAS_AGREGADAS: "Aún no se tienen estructuras agregadas",
  MESSAGES_FILTER_ESTRUCTURAS_AGREGADAS: "No se encontraron estructuras",
  MESSAGES_FILTER_ARTICULOS: "No se encontraron artículos",
  MESSAGES_PAQUETES_ASIGNADOS_TABLE:
    "Este alumno aún no tiene artículos asignados",
  MESSAGES_PAPELERA_VACIA: "La papelera está vacía",
  MESSAGES_FACTURACION_TABLE: "No hay facturas",
  MESSAGES_MIS_VENTAS_TABLE: "Actualmente no se tienen pedidos registrados",
  MESSAGES_VENTA_INFO_PREPARANDO:
    "Al marcar el pedido como completado, no podrá cambiar el estatus posteriormente de forma manual, y deberá contactar al plantel para cualquier situación.",
  MESSAGES_VENTA_INFO_PREPARANDO_DIR_PLANTEL_ADMIN_OPERATIVO:
    "Al marcar el pedido como completado, no podrá modificar el estatus hasta que se llegue al final del proceso.",
  MESSAGES_VENTA_INFO_COMPLETADO:
    "Al marcar el pedido como entregado, significa que el cliente ya recogió todos los artículos que conforman el pedido, y este estatus será reflejado en las estructuras superiores que hayan distribuido artículos, así como en el estatus del cliente, por lo que, cualquier situación, se deberá contactar directamente con ellos.",
  MESSAGES_VENTA_INFO_ENTREGADO:
    'Al reabrir el pedido, este pasará a estatus de "preparando" para el plantel y las estructuras que hayan distribuido algún artículo, por lo que deberán reiniciar el proceso. También para el cliente se deberá mostrar el pedido con estatus de "preparando". ',
  MESSAGES_LICENCIAS: "No se encontraron licencias",
  MESSAGES_SIN_RECURSOS: "No se encontraron recursos disponibles",
  MESSAGES_SIN_CARPETAS: "No existe ninguna carpeta creada",
  MESSAGES_SIN_MEDIOS: "Aún no tiene recursos cargados",
  MESSAGES_MATERIAS: "No se encontraron materias",
  MESSAGES_CATEGORIAS: "No se encontraron categorías",
  MESSAGES_PAISES_TABLE_ADMIN:
    "Aún no se han dado de alta países. De clic en agregar país",
  MESSAGES_PAISES_TABLE: "Aún no se han dado de alta países.",
  MESSAGES_AVISO_SUBIR_RECURSOS:
    "Este proceso puede tardar unos minutos debido a que se está realizando la compresión de los recursos que se están subiendo.\n Una vez que concluya el proceso, podrá ver los recursos cargados en su biblioteca de medios",
  MESSAGES_AVISO_ACTUALIZAR_RECURSO:
    "La actualización total del recurso puede llevar \n varios minutos en reflejarse en la plataforma.",
  MESSAGES_AVISO_CONFIGURACION_PRODUCTO:
    'Debe seleccionar la configuración del producto que se va a crear.\n La configuración de "Producto variable" es para aquellos productos que tienen distintas variaciones, tal como talla, color, etc.\n La configuración de "Producto simple" es para aquellos productos que no tienen variaciones y que pueden ser tanto físicos como digitales.\n La configuración de "Producto digital" es para aquellos productos que no tiene variaciones, y que es un producto digital.',
  MESSAGES_FORMADOR_TABLE: "No se encontraron autores",
  MESSAGES_RECUPERAR_LIBRO_PAPELERA: [
    "Al recuperar un libro de la papelera, este volverá a estar disponible en la tienda y en la biblioteca de los usuarios que lo tenían asignado.",
    "¿Está seguro que desea recuperar este libro?",
  ],
  MESSAGES_ELIMINAR_LIBRO: [
    "Este libro se moverá a la papelera de reciclaje.",
    "¿Está seguro que desea continuar con esta acción?",
  ],
  MESSAGE_GESTOR_AVISOS_TABLE:
    'No se encontraron avisos. De clic en "Crear aviso".',
  MESSAGES_CATEGORIAS_NOTIFICACIONES_TABLE:
    "No se encontraron categorías de notificaciones. De clic en Agregar categoría ",
  MESSAGES_LISTAS_DIFUSION_TABLE:
    'No se encontraron listas de difusión. De clic en "Crear lista de difusión".',
  MESSAGES_LISTA_DIFUSION_DESTINATARIOS:
    'Aún no tiene destinatarios agregados en la lista de difusión. De clic en "Agregar destinatarios".',
  MESSAGES_AGREGAR_USUARIOS_LISTA_DIFUSION_TABLE:
    "No se encontraron usuarios con los parámetros de búsqueda ingresados.",
  MESSAGES_HISTORIAL_AVISOS: "Aún no ha recibido ningún aviso.",
  MESSAGES_SELECTOR_CONTENIDO_NEW_PATCH_TABLE: 'No se encontraron contenidos',
  MESSAGES_ELIMINAR_LIBRO_NEW_PATCH: ['Al salir del libro actual sin contar con algún elemento como contenido (UMA/Hijo), el sistema procederá a eliminar el libro.',
    '¿Está seguro que desea continuar?'],
  MESSAGES_REPORTE_AVISOS_TABLE: "Por el momento no existen datos para poder generar el reporte de avisos.",
  MESSAGES_REPORTE_AVISOS_DESTINATARIOS_TABLE: "Por el momento no existen destinatarios para este aviso.",
};
