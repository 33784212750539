import { useState } from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import AddUsers from "../add-users/AddUsers";
import CounterTable from "./CounterTable";
import DeleteButton from "./DeleteButton";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import SearchFilter from "./SearchFilter";
import { ReactComponent as Add } from "../../../../assets/icons/add.svg";

const styles = {
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

const ListaDifusionDetallesTableHeader = () => {
  const { ver } = useListaDifusionDetalles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const classes = `grid grid-cols-12 grid-rows-${
    ver ? "2" : "4"
  } gap-4 sm:grid-rows-${ver ? "1" : "2"} mb-8`;

  return (
    <div className={classes}>
      <div className="col-span-12 sm:col-span-6">
        <CounterTable />
      </div>

      {!ver && (
        <div className="col-span-12 sm:col-span-6 justify-center sm:justify-self-end">
          <button
            type="button"
            className={`w-full bg-nimbusDark text-white py-2 p-4 rounded-lg flex items-center justify-center`}
            onClick={() => setIsOpenModal(true)}
            style={{ textWrap: "nowrap" }}
          >
            <Add className="w-5 h-5 mr-2" />
            Agregar destinatarios
          </button>
        </div>
      )}

      {!ver && (
        <>
          <div className="col-span-12 sm:col-span-11 mr-0 sm:justify-self-end sm:mr-16 md:mr-12 lg:mr-8">
            <SearchFilter />
          </div>

          <div className="col-span-12 sm:col-span-1 sm:justify-self-end">
            <DeleteButton />
          </div>
        </>
      )}
      <NimbusModal isOpen={isOpenModal} styles={styles}>
        <AddUsers onCloseModal={() => setIsOpenModal(false)} />
      </NimbusModal>
    </div>
  );
};

export default ListaDifusionDetallesTableHeader;
