import React, { useState } from "react";
import DefaultImage from "../../../../../../../src/assets/img/resourceNotFound.png";
function ImagenValidadaComparador({ src, className, onClick, slot }) {
  const [validada, setValidada] = useState(true);
  return (
    <>
      {validada ? (
        <img
          src={typeof src !== "string" ? URL.createObjectURL(src) : src}
          //src="zzz"
          className={className}
          onClick={onClick}
          onError={() => setValidada(false)}
          slot={slot}
          alt=""
        />
      ) : (
        <img src={DefaultImage} className={className} slot={slot} alt="" />
      )}
    </>
  );
}

ImagenValidadaComparador.defaultProps = {
  src: "",
  className: "",
  onClick: undefined,
  slot: "",
};

export default ImagenValidadaComparador;
