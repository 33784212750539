import Artboard_1 from '../../../src/assets/icons/icons-categorias/svg/Artboard 1.svg';
import Artboard_2 from '../../../src/assets/icons/icons-categorias/svg/Artboard 2.svg';
import Artboard_3 from '../../../src/assets/icons/icons-categorias/svg/Artboard 3.svg';
import Artboard_4 from '../../../src/assets/icons/icons-categorias/svg/Artboard 4.svg';
import Artboard_5 from '../../../src/assets/icons/icons-categorias/svg/Artboard 5.svg';
import Artboard_6 from '../../../src/assets/icons/icons-categorias/svg/Artboard 6.svg';
import Artboard_7 from '../../../src/assets/icons/icons-categorias/svg/Artboard 7.svg';
import Artboard_8 from '../../../src/assets/icons/icons-categorias/svg/Artboard 8.svg';
import Artboard_9 from '../../../src/assets/icons/icons-categorias/svg/Artboard 9.svg';
import Artboard_10 from '../../../src/assets/icons/icons-categorias/svg/Artboard 10.svg';
import Artboard_11 from '../../../src/assets/icons/icons-categorias/svg/Artboard 11.svg';
import Artboard_12 from '../../../src/assets/icons/icons-categorias/svg/Artboard 12.svg';
import Artboard_13 from '../../../src/assets/icons/icons-categorias/svg/Artboard 13.svg';
import Artboard_14 from '../../../src/assets/icons/icons-categorias/svg/Artboard 14.svg';
import Artboard_15 from '../../../src/assets/icons/icons-categorias/svg/Artboard 15.svg';
import Artboard_16 from '../../../src/assets/icons/icons-categorias/svg/Artboard 16.svg';
import Artboard_17 from '../../../src/assets/icons/icons-categorias/svg/Artboard 17.svg';
import Artboard_18 from '../../../src/assets/icons/icons-categorias/svg/Artboard 18.svg';
import Artboard_19 from '../../../src/assets/icons/icons-categorias/svg/Artboard 19.svg';
import Artboard_20 from '../../../src/assets/icons/icons-categorias/svg/Artboard 20.svg';
import Artboard_21 from '../../../src/assets/icons/icons-categorias/svg/Artboard 21.svg';
import Artboard_22 from '../../../src/assets/icons/icons-categorias/svg/Artboard 22.svg';
import Artboard_23 from '../../../src/assets/icons/icons-categorias/svg/Artboard 23.svg';
import Artboard_24 from '../../../src/assets/icons/icons-categorias/svg/Artboard 24.svg';
import Artboard_25 from '../../../src/assets/icons/icons-categorias/svg/Artboard 25.svg';

export const ICONOS_CATEGORIAS = {
    general : Artboard_1,
    Artboard_1 : Artboard_1,
    Artboard_2 : Artboard_2,
    Artboard_3 : Artboard_3,
    Artboard_4 : Artboard_4,
    Artboard_5 : Artboard_5,
    Artboard_6 : Artboard_6,
    Artboard_7 : Artboard_7,
    Artboard_8 : Artboard_8,
    Artboard_9 : Artboard_9,
    Artboard_10 : Artboard_10,
    Artboard_11 : Artboard_11,
    Artboard_12 : Artboard_12,
    Artboard_13 : Artboard_13,
    Artboard_14 : Artboard_14,
    Artboard_15 : Artboard_15,
    Artboard_16 : Artboard_16,
    Artboard_17 : Artboard_17,
    Artboard_18 : Artboard_18,
    Artboard_19 : Artboard_19,
    Artboard_20 : Artboard_20,
    Artboard_21 : Artboard_21,
    Artboard_22 : Artboard_22,
    Artboard_23 : Artboard_23,
    Artboard_24 : Artboard_24,
    Artboard_25 : Artboard_25,
};

export const OPTIONS_SELECT = [
    { _id: 'Artboard_1', nombre: 'Artboard 1' },
    { _id: 'Artboard_2', nombre: 'Artboard 2' },
    { _id: 'Artboard_3', nombre: 'Artboard 3' },
    { _id: 'Artboard_4', nombre: 'Artboard 4' },
    { _id: 'Artboard_5', nombre: 'Artboard 5' },
    { _id: 'Artboard_6', nombre: 'Artboard 6' },
    { _id: 'Artboard_7', nombre: 'Artboard 7' },
    { _id: 'Artboard_8', nombre: 'Artboard 8' },
    { _id: 'Artboard_9', nombre: 'Artboard 9' },
    { _id: 'Artboard_10', nombre: 'Artboard 10' },
    { _id: 'Artboard_11', nombre: 'Artboard 11' },
    { _id: 'Artboard_12', nombre: 'Artboard 12' },
    { _id: 'Artboard_13', nombre: 'Artboard 13' },
    { _id: 'Artboard_14', nombre: 'Artboard 14' },
    { _id: 'Artboard_15', nombre: 'Artboard 15' },
    { _id: 'Artboard_16', nombre: 'Artboard 16' },
    { _id: 'Artboard_17', nombre: 'Artboard 17' },
    { _id: 'Artboard_18', nombre: 'Artboard 18' },
    { _id: 'Artboard_19', nombre: 'Artboard 19' },
    { _id: 'Artboard_20', nombre: 'Artboard 20' },
    { _id: 'Artboard_21', nombre: 'Artboard 21' },
    { _id: 'Artboard_22', nombre: 'Artboard 22' },
    { _id: 'Artboard_23', nombre: 'Artboard 23' },
    { _id: 'Artboard_24', nombre: 'Artboard 24' },
    { _id: 'Artboard_25', nombre: 'Artboard 25' },
];

export const ICONOS_CATEGORIAS_VISUALIZACION = [
    {
        _id: 'Artboard_1',
        nombre: 'Artboard 1',
        icono: Artboard_1,
    },
    {
        _id: 'Artboard_2',
        nombre: 'Artboard 2',
        icono: Artboard_2,
    },
    {
        _id: 'Artboard_3',
        nombre: 'Artboard 3',
        icono: Artboard_3,
    },
    {
        _id: 'Artboard_4',
        nombre: 'Artboard 4',
        icono: Artboard_4,
    },
    {
        _id: 'Artboard_5',
        nombre: 'Artboard 5',
        icono: Artboard_5,
    },
    {
        _id: 'Artboard_6',
        nombre: 'Artboard 6',
        icono: Artboard_6,
    },
    {
        _id: 'Artboard_7',
        nombre: 'Artboard 7',
        icono: Artboard_7,
    },
    {
        _id: 'Artboard_8',
        nombre: 'Artboard 8',
        icono: Artboard_8,
    },
    {
        _id: 'Artboard_9',
        nombre: 'Artboard 9',
        icono: Artboard_9,
    },
    {
        _id: 'Artboard_10',
        nombre: 'Artboard 10',
        icono: Artboard_10,
    },
    {
        _id: 'Artboard_11',
        nombre: 'Artboard 11',
        icono: Artboard_11,
    },
    {
        _id: 'Artboard_12',
        nombre: 'Artboard 12',
        icono: Artboard_12,
    },
    {
        _id: 'Artboard_13',
        nombre: 'Artboard 13',
        icono: Artboard_13,
    },
    {
        _id: 'Artboard_14',
        nombre: 'Artboard 14',
        icono: Artboard_14,
    },
    {
        _id: 'Artboard_15',
        nombre: 'Artboard 15',
        icono: Artboard_15,
    },
    {
        _id: 'Artboard_16',
        nombre: 'Artboard 16',
        icono: Artboard_16,
    },
    {
        _id: 'Artboard_17',
        nombre: 'Artboard 17',
        icono: Artboard_17,
    },
    {
        _id: 'Artboard_18',
        nombre: 'Artboard 18',
        icono: Artboard_18,
    },
    {
        _id: 'Artboard_19',
        nombre: 'Artboard 19',
        icono: Artboard_19,
    },
    {
        _id: 'Artboard_20',
        nombre: 'Artboard 20',
        icono: Artboard_20,
    },
    {
        _id: 'Artboard_21',
        nombre: 'Artboard 21',
        icono: Artboard_21,
    },
    {
        _id: 'Artboard_22',
        nombre: 'Artboard 22',
        icono: Artboard_22,
    },
    {
        _id: 'Artboard_23',
        nombre: 'Artboard 23',
        icono: Artboard_23,
    },
    {
        _id: 'Artboard_24',
        nombre: 'Artboard 24',
        icono: Artboard_24,
    },
    {
        _id: 'Artboard_25',
        nombre: 'Artboard 25',
        icono: Artboard_25,
    },
];