import { _delete, _get, _post, _put } from '../../http';

export const postCategoriaNotificacion = (body) =>
  _post(`/categoriaNotificacion`, body);

export const putCategoriaNotificacion = (categoriaNotificacionId, body) =>
  _put(`/categoriaNotificacion/${categoriaNotificacionId}`, body);

export const getCategoriaNotificacionPorId = (categoriaNotificacionId) =>
  _get(`/categoriaNotificacion/${categoriaNotificacionId}`);

export const getCategoriasNotificaciones = (userId) => _get(`/categoriaNotificacion/${userId}/obtenerTodas`);

export const deleteCategoriaNotificacion = (categoriaNotificacionId) =>
  _delete(`/categoriaNotificacion/${categoriaNotificacionId}`);