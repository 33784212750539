import Modal from "react-modal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import {ReactComponent as Nimbus} from '../../../../assets/img/logo.svg'
import useValidateForm from "../../../hooks/useValidateForm"
import { putCarpeta, getCarpeta, postCarpeta } from "../../../services/api"
import { toast } from "react-toastify";
import InputText from "../../../fragments/Inputs/InputText";
import { useContext, useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import alertConfiguration from "../../../utils/alertsUtils";
import '../../../../colors.css';
import { UserContext } from "../../../services/user";


const info = {
    nombre: '',
    color: '',
    usuario: '',
    origen: 'UMA',
}

const customStyles = { 
content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 20,
    },
};

const excluidos = ['color']

export default function ModalCarpeta({edit, idCarpeta, nivelCarpeta, isOpen, closeModal, update, idCarpetaPadre, isLibro = false, isTarea=false, carpetaInfo = {}}) {
    //const params = useParams()
    const {user} = useContext(UserContext);
    const [carpeta, setCarpeta] = useState(info)
    const [loading, setLoading] = useState(true)
    const [colorSelected, setColor] = useState('azul')
    const [nombreCarpeta, setNombre] = useState('')

    const colors = [
        'azul', 'ladrillo', 'cardenal', 'fresas', 
        'naranja', 'taxi', 'menta', 'helecho', 
        'esparrago', 'limon', 'arena', 'macarrones', 
        'espuma', 'piscina', 'vaquero', 'cielo', 
        'terciopelo', 'dinosaurio', 'raton', 'montana', 
        'lombriz', 'chicle', 'lluvia', 'berenjena'
    ]


    useEffect(()=>{
        try{
            if(edit && idCarpeta) {
                if(isLibro){
                    setCarpeta(carpetaInfo)
                    setColor(carpetaInfo.color)
                    setNombre(carpetaInfo.nombre)
                    setLoading(false)
                } else {
                    getCarpeta(idCarpeta).then(res=>{
                        setCarpeta({...res.data})
                        setColor(res.data.color)
                        setNombre(res.data.nombre)
                        setLoading(false)
                    })
                }
            }else{
                setColor('azul')
                setNombre('')
                setCarpeta(info)
                setLoading(false)
            }
        }catch(error){
            console.log(error);
        }
    },[edit, idCarpeta, isOpen])


    const submitForm = async (e) =>{
        e.preventDefault()
        //setLoading(true)
        
        if(nivelCarpeta === 1) carpeta.padre = idCarpetaPadre;
        carpeta.nivel = nivelCarpeta;
        carpeta.usuario = user._id;
        carpeta.color = colorSelected;
        if(isLibro) carpeta.origen = 'LIBRO';
        else if(isTarea)carpeta.origen = "TAREA"
        else carpeta.origen= 'UMA'
        let valido = validarInformacion();

        if(valido){
                if(edit){
                    await putCarpeta(idCarpeta, carpeta).then(response=>{
                        closeModal()
                        toast.success('Carpeta actualizada correctamente', alertConfiguration)
                        setLoading(false)
                    }).catch(error=>{
                        console.log(error)
                    })
                }else{                  
                    await postCarpeta(carpeta).then(response=>{
                        if(response?.error?.status == 500){
                            toast.error(`${response.error.message}`, alertConfiguration)
                            //setLoading(false)
                        }else{
                            setCarpeta({})
                            closeModal()
                            toast.success('Carpeta creada correctamente', alertConfiguration)
                            setLoading(false)                            
                        }
                    })
                }
                update()
        }
    }

    const handleSetColor = (e) => {
        setColor(e)
      };

    const handleChange = (event) => {
        setNombre(event.target.value);
        carpeta.nombre = event.target.value;
    };	

    function validarInformacion() {
        if(carpeta.color == ''){
            toast.error("No has seleccionado ningún color", alertConfiguration);
            return false;
        } else if(carpeta.nombre == ''){
            toast.error("Debes agregar un nombre a la carpeta", alertConfiguration);
            return false;
        }
        return true;
    }

    return(
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
            <div className="grid text-center gap-y-6">
                <Nimbus className="justify-self-center"/>
                <h1 className="text-nimbus truncate font-bold">{edit ? 'Editar carpeta' : 'Agrega una nueva carpeta'}</h1>
                <div className="w-3/3">
                        <InputText
                            value={nombreCarpeta || ''}
                            onChange={handleChange}
                            name='nombre'
                            id='nombre'
                            placeholder='Ingrese nombre'
                            /></div>
                            <h2 className="text-nimbus">Selecciona un color</h2>
                        <div className="p-1">
                            <div className="grid grid-cols-8 gap-1">
                                {colors.map((e)=>(
                                    <button key={e} id={`${e}-nimbus`} className={`${e}-nimbus w-8 h-7 ${colorSelected == e? colorSelected + '-nimbus selected' : ''}`} onClick={() => handleSetColor(e)}/>
                                ))}
                            </div>        
                        </div>
                <div className="flex justify-center gap-x-4">
                    <CancelButton text='Cancelar' bgColor='bg-divider'  onClick={closeModal}/>
                    <SubmitButton text={edit ? 'Guardar' : 'Crear'} onClick={submitForm}/>
                </div>
            </div>
        </Modal>
    )
}