import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../fragments/SubHeader";
import { useGestorAvisosDetalles } from "./GestorAvisosDetallesContext";

const SubHeaderAvisosForm = () => {
  const { editar, ver } = useGestorAvisosDetalles();
  const navigate = useNavigate();
  return (
    <div className="flex align-middle justify-between items-center">
      <SubHeader styles="!bg-backgroundColor">
        <BreadCrumbContainer>
          <BreadCrumb
            route={"Gestor de avisos /"}
            onClick={() => navigate("/herramientas/gestor-avisos")}
          />
          <BreadCrumb
            route={ver ? "Ver aviso" : editar ? "Editar aviso" : "Crear aviso"}
            active
          />
        </BreadCrumbContainer>
      </SubHeader>
    </div>
  );
};

export default SubHeaderAvisosForm;
