import React, { Fragment, useContext, useEffect, useState } from 'react';
import { usePagination } from '../../hooks/usePagination';
import SubHeaderFacturacion from './fragments/SubHeaderFacturacion';
import FacturacionTable from './fragments/FacturacionTable';
import TablePagination from '../../fragments/TablePagination';
import FacturacionFilter from './fragments/FacturacionFilter';
import { MESSAGES } from '../../utils/messagesUtils';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../services/user';
import withUserAccesability from '../../hoc/withUserAccesability';
import NoDataMessage from '../../fragments/NoDataMessage';
import { getFacturasUsuario } from '../../services/api';
import GenerarFacturaModal from './GenerarFacturaModal';

const Facturacion = ({ isUserAllowed }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [facturas, setFacturas] = useState([]);
  const [facturacionFiltro, setFacturacionFiltro] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isUserAllowed) {
      navigate('/NotFound');
    }
  }, [user.activeRole]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    facturacionFiltro,
    20
  );

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const getFacturas = async () => {
    getFacturasUsuario(user.user._id).then((res) => {
      if (res.data !== undefined) {
        setFacturas(res.data);
        setFacturacionFiltro(res.data);
        return;
      }
      setFacturas([]);
    });
  };

  useEffect(() => {
    getFacturas();
  }, [isOpen]);

  return (
    <Fragment>
      <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
        <SubHeaderFacturacion handleModal={handleModal} />
        <div
          className={`${
            facturacionFiltro.length !== 0 && 'justify-between'
          } relative flex-1 flex flex-col px-4 sm:px-8`}
        >
          <div>
            <FacturacionFilter
              facturacionList={facturas}
              facturacionFiltro={facturacionFiltro}
              setFacturacionFiltro={setFacturacionFiltro}
            />
            <FacturacionTable facturacionList={currentData()} />
          </div>
          <div>
            <GenerarFacturaModal
              isOpen={isOpen}
              handleModal={handleModal}
              facturas={facturas}
            />
            {facturacionFiltro.length === 0 ? (
              <NoDataMessage message={MESSAGES.MESSAGES_FACTURACION_TABLE} />
            ) : (
              <TablePagination
                next={next}
                prev={prev}
                jump={jump}
                data={currentData()}
                currentPage={currentPage}
                maxPage={maxPage}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withUserAccesability(Facturacion);
