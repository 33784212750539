import { useNavigate } from "react-router-dom";
import { ReactComponent as PaqueteIcon } from "../../../../../assets/icons/paqueteMarketplace.svg";
import { ReactComponent as PaqueteEditorialIcon } from "../../../../../assets/icons/paqueteEditorialMarketplace.svg";
import { ReactComponent as PaqueteDistribucionIcon } from "../../../../../assets/icons/paqueteDistribucionMarketplace.svg";
import { ReactComponent as PaqueteCorporativoIcon } from "../../../../../assets/icons/paqueteCorporativoMarketplace.svg";
import { ReactComponent as PaquetePlantelIcon } from "../../../../../assets/icons/paquetePlantelMarketplace.svg";
import { ReactComponent as ViewIcon } from "../../../../../assets/icons/view.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/Edit.svg";
import { ReactComponent as EditarPrecioAsignacion } from "../../../../../assets/icons/editarPrecioAsignacion.svg";
import { useContext, useState, useMemo, useEffect } from "react";
import { UserContext } from "../../../../services/user";
import EliminarPaqueteModal from "./EliminarPaqueteModal";
import { formatCurrency } from "../../../../utils/productoUtils";
import { obtenerUserEstructuraInfo } from "../../../../utils/userUtils";
import EditarPrecioVentaPaqueteModal from "./EditarPrecioVentaPaqueteModal";
import { obtenerEstructuraYRelaciones } from "../../../../services/Api/Autorizacion";
import { PaisesContext } from "../../../../../contexts/PaisesContext";
import { convertirMoneda } from "../../../../utils/conversionMonedasUtils";

const ICON_COLOR = {
  "Casa editorial": PaqueteEditorialIcon,
  Distribución: PaqueteDistribucionIcon,
  Corporativo: PaqueteCorporativoIcon,
  Plantel: PaquetePlantelIcon,
};

const PaquetesProductosRow = ({ paqueteInfo, obtenerPaquetes, tipoDeMoneda }) => {
  const { permissions, activeRole, user } = useContext(UserContext);
  const { obtenerPaises, paises, estructuras } = useContext(PaisesContext); 
  const navigate = useNavigate();
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] = useState(false);
  const [isEditPriceProductModalOpen, setIsEditPriceProductModalOpen] = useState(false);
  const [conversionMonedas, setConversionMonedas] = useState({});

  const { _id, precioVenta, paquete, origen, provieneDe, costo } = paqueteInfo;
  const { nombre, estatus } = paquete;

  const ganancia = useMemo(() => {
    let nuevaGanancia = precioVenta - convertirMoneda(costo, conversionMonedas);
    nuevaGanancia = nuevaGanancia.toFixed(2);
    return nuevaGanancia === "-0.00" ? "0.00" : nuevaGanancia;
  }, [precioVenta, costo, conversionMonedas]);

  const IconEstructura = useMemo(() => {
    if (activeRole === "") return PaqueteIcon;

    if (provieneDe) {
      const iconoPaqueteLinea = provieneDe.editorial
        ? "Casa editorial"
        : provieneDe.distribucion
        ? "Distribución"
        : provieneDe.corporativo
        ? "Corporativo"
        : "Plantel";

      return ICON_COLOR[iconoPaqueteLinea];
    }

    const { tipoEstructura } = obtenerUserEstructuraInfo(user, activeRole);
    return ICON_COLOR[tipoEstructura];
  }, [activeRole]);

  const obtenerConversion = async () => {
    if (provieneDe) {
      const { editorial, distribucion, corporativo, dirFiscalPais } = estructuras;
      const paisOrigen = corporativo 
        ? corporativo.dirFiscalPais 
        : distribucion 
        ? distribucion.dirFiscalPais 
        : editorial.dirFiscalPais;
      const conversionBody = {
        paisOrigenId: paisOrigen,
        paisDestinoId: dirFiscalPais,
        paises: paises,
      };
      setConversionMonedas(conversionBody);   
    }
  };
  
  useEffect( () => {
      if(activeRole !== ""){
        obtenerConversion();
      }
    },[activeRole, paqueteInfo]);

  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-2 rounded-l-md border-r-0 text-left font-sans">
          <IconEstructura className="mx-auto" />
        </td>
        <td className="border-y-2 border-x-0 text-left font-sans">
          <p>{nombre}</p>
        </td>
        <td className="border-y-2 font-sans">
          <p className="text-right mr-4">{formatCurrency(convertirMoneda(costo, conversionMonedas))}</p>
        </td>
        <td className="border-y-2 font-sans">
          <p className="text-right mr-4">{formatCurrency(precioVenta)}</p>
        </td>
        <td className="border-y-2 font-sans">
          <p
            className={`font-sans text-right mr-4 ${
              ganancia < 0 ? "text-red-600" : ganancia > 0 ? "text-green-600" : "text-inherit"
            }`}
          >
            {formatCurrency(ganancia)}
          </p>
        </td>
        <td className="border-y-2 font-sans text-center">
          <p>{origen === "Linea" ? "De línea" : origen}</p>
        </td>
        <td className="border-y-2 font-sans text-center">
          <p>{estatus ? "Disponible" : "Agotado"}</p>
        </td>
        <td className="border-2 rounded-r-md border-l-0 min-h-[52px]">
          <div className="flex justify-evenly items-center py-6 md:py-3 space-x-2 px-2">
            {provieneDe ? (
              <>
                <div className="invisible w-6"></div>
                <div className="invisible w-6"></div>
                <EditarPrecioAsignacion
                  title="Editar precio de venta"
                  className="cursor-pointer"
                  onClick={() => setIsEditPriceProductModalOpen(true)}
                />
              </>
            ) : (
              <>
                {permissions.paquetesproductosPermits?.editar && (
                  <EditIcon
                    title="Editar paquete"
                    className="cursor-pointer"
                    onClick={() => navigate(`/marketplace/gestionProductos/paquetes/${_id}/editar`)}
                  />
                )}
                {permissions.paquetesproductosPermits?.ver && (
                  <ViewIcon
                    title="Ver paquete"
                    className="cursor-pointer"
                    onClick={() => navigate(`/marketplace/gestionProductos/paquetes/${_id}/ver`)}
                  />
                )}
                {permissions.paquetesproductosPermits?.eliminar && (
                  <DeleteIcon
                    title="Eliminar paquete"
                    className="cursor-pointer"
                    onClick={() => setIsDeleteProductModalOpen(true)}
                  />
                )}
              </>
            )}
          </div>
        </td>
      </tr>
      <EliminarPaqueteModal
        isOpen={isDeleteProductModalOpen}
        setIsOpen={setIsDeleteProductModalOpen}
        nombre={nombre}
        paqueteId={_id}
        obtenerPaquetes={obtenerPaquetes}
      />
      {
        isEditPriceProductModalOpen &&
        <EditarPrecioVentaPaqueteModal
          isOpen={isEditPriceProductModalOpen}
          setIsOpen={setIsEditPriceProductModalOpen}
          paqueteId={_id}
          paqueteInfo={paqueteInfo}
          obtenerPaquetes={obtenerPaquetes}
          tipoDeMoneda={tipoDeMoneda}
          conversionMonedas={conversionMonedas}
        />
      }
    </>
  );
};

export default PaquetesProductosRow;
