import React, { useContext, useMemo } from "react";
import { ReactComponent as AddIcon } from "../../../../assets/icons/addLg.svg";
import { AsignarArticulosContext } from "../../../../contexts/AsignarProductosContext";
import {
  agregarArticulo,
  esAgregarArticulosModalAbierto,
  habilitarBotonEstatus,
} from "../../../../reducers/asginarArticulos/asignarArticulosAction";
import { ReactComponent as ProductoIcon } from "../../../../assets/icons/productoIcon.svg";
import { ReactComponent as ProductoEditorialIcon } from "../../../../assets/icons/productoEditorialIcon.svg";
import { ReactComponent as ProductoDistribucionIcon } from "../../../../assets/icons/productoDistribucionIcon.svg";
import { ReactComponent as ProductoCorporativoIcon } from "../../../../assets/icons/productoCorporativoIcon.svg";
import { ReactComponent as ProductoPlantelIcon } from "../../../../assets/icons/productoPlantelIcon.svg";
import { ReactComponent as PaqueteIcon } from "../../../../assets/icons/paqueteMarketplace.svg";
import { ReactComponent as PaqueteEditorialIcon } from "../../../../assets/icons/paqueteEditorialMarketplace.svg";
import { ReactComponent as PaqueteDistribucionIcon } from "../../../../assets/icons/paqueteDistribucionMarketplace.svg";
import { ReactComponent as PaqueteCorporativoIcon } from "../../../../assets/icons/paqueteCorporativoMarketplace.svg";
import { ReactComponent as PaquetePlantelIcon } from "../../../../assets/icons/paquetePlantelMarketplace.svg";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { UserContext } from "../../../services/user";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const ICON_PRODUCTOS_COLOR = {
  "Casa editorial": ProductoEditorialIcon,
  Distribución: ProductoDistribucionIcon,
  Corporativo: ProductoCorporativoIcon,
  Plantel: ProductoPlantelIcon,
};

const ICON_PAQUETES_COLOR = {
  "Casa editorial": PaqueteEditorialIcon,
  Distribución: PaqueteDistribucionIcon,
  Corporativo: PaqueteCorporativoIcon,
  Plantel: PaquetePlantelIcon,
};

const ArticuloDisponibleRow = ({ articuloInfo, seleccionarArticulo }) => {
  const { producto, paquete, origen, esSeleccionado, provieneDe } = articuloInfo;
  const { nombre, categoria, estatus, nivelAcademico, gradoAcademico } = producto ? producto : paquete;

  const { articulos, dispatch } = useContext(AsignarArticulosContext);
  const { user, activeRole } = useContext(UserContext);

  const handleAgregarProducto = () => {
    dispatch(agregarArticulo(articulos, articuloInfo));
    dispatch(esAgregarArticulosModalAbierto(false));
    dispatch(habilitarBotonEstatus(true))
    toast.success("Artículo agregado correctamente", alertConfiguration)
  };

  const handleSeleccionarProducto = () => {
    seleccionarArticulo(articuloInfo);
  };

  const IconEstructura = useMemo(() => {
    if (activeRole === "") return producto ? ProductoIcon : PaqueteIcon;

    if (provieneDe) {
      const iconoProductoLinea = provieneDe.editorial
      ? "Casa editorial"
      : provieneDe.distribucion
      ? "Distribución"
      : provieneDe.corporativo
      ? "Corporativo"
      : "Plantel";
      
      return producto ?  ICON_PRODUCTOS_COLOR[iconoProductoLinea] : ICON_PAQUETES_COLOR[iconoProductoLinea]
    }

    const { tipoEstructura } = obtenerUserEstructuraInfo(user, activeRole);
    return producto ? ICON_PRODUCTOS_COLOR[tipoEstructura] : ICON_PAQUETES_COLOR[tipoEstructura];
  }, [activeRole]);

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-md border-r-0 text-center font-sans w-20">
        <input
          type="checkbox"
          name="selectProducto"
          id="selectProducto"
          className="rounded-md border-2 border-gray-300 cursor-pointer"
          checked={esSeleccionado ?? false}
          onChange={handleSeleccionarProducto}
        />
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans px-2">
        <IconEstructura className="mx-auto" />
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans">
        <p>{nombre}</p>
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans">
        <p>{paquete ? "Paquetes" : categoria === "otro" ? "Otros" : categoria}</p>
      </td>
      <td className="border-y-2 border-x-0 text-left font-sans">
        <p>{nivelAcademico.nombre}</p>
      </td>
      <td className="border-y-2 font-sans">
        <p>{gradoAcademico.nombre}</p>
      </td>
      <td className="border-y-2 font-sans">
        <p>{origen === "Linea" ? "De línea" : origen}</p>
      </td>
      <td className="border-y-2 font-sans">
        <p>{estatus ? "Disponible" : "Agotado"}</p>
      </td>
      <td className="border-2 rounded-r-md border-l-0 pt-3 pb-1">
        <div className="flex justify-center  ml-[14px]">
          <AddIcon title="Agregar producto" className="cursor-pointer h-8 " onClick={handleAgregarProducto} />
        </div>
      </td>
    </tr>
  );
};

export default ArticuloDisponibleRow;
