import React, { useEffect, useState } from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import InputText from "../../../fragments/Inputs/InputText";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import useFormSubmit from "../../../hooks/useFormSubmit";
import useValidateForm from "../../../hooks/useValidateForm";
import { getComisionGeneral, putComisionGeneral } from "../../../services/Api/MarketPlace/ComisionUsoPlataforma";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const COMISION_SCHEMA = {
  comision: "",
};

const ComisionModal = ({ isOpen, setIsOpen }) => {
  const handleSubmitComision = async () => {
    if (Object.keys(errors).length === 0) {
      try {
        await putComisionGeneral(values);
        toast.success("Comisión de uso guardada correctamente.", alertConfiguration);
        setIsOpen(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrors(errors);
      setIsEnableSubmitButton(true);
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(handleSubmitComision);
  const { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues, setErrors } =
    useValidateForm(COMISION_SCHEMA);

  useEffect(() => {
    getComisionGeneral().then((comisionGeneralResponse) => {
      let comisioneGeneral = { comision: comisionGeneralResponse.comision };
      setValues(comisioneGeneral);
    });

    if (!isOpen){
      setErrors({})
      setIsEnableSubmitButton(false)
    };
  }, [isOpen]);

  useEffect(() => {
    if (values.comision === "") setIsEnableSubmitButton(false);
  }, [values.comision]);

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Configurar la comisión de uso de la plataforma</h1>
      <p className="w-96 px-4">
        La comisión de uso de la plataforma es el porcentaje que se le va a cobrar a cada una de las estructuras por el
        total de las ganancias obtenidas durante cierto periodo. El porcentaje configurado por default será de 10%. Este
        valor se aplicará a todas las estructuras que no tengan activada la configuración personalizada.
      </p>
      <div className="w-3/3 px-4 text-left text-xs">
        <InputText
          name="comision"
          placeholder="Ingrese la comisión de uso"
          label="Comisión de uso de la plataforma (%)"
          value={values.comision}
          error={errors["comision"]}
          styles="!w-full"
          onChange={(e) => handleChange(e, "comision")}
        />
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton
          text="Guardar"
          disabled={formStatus === "submitting" || !isEnableSubmitButton}
          onClick={handleSubmit}
        />
      </div>
    </NimbusModal>
  );
};

export default ComisionModal;
