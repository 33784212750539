import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../fragments/SubHeader";

const SubHeaderHistorialAvisos = () => {
  const navigate = useNavigate();

  return (
    <div className="flex align-middle justify-between items-center">
      <SubHeader styles="!bg-backgroundColor">
        <BreadCrumbContainer>
          <BreadCrumb
            route={"Herramientas /"}
            onClick={() => navigate("/recursos/herramientas")}
          />
          <BreadCrumb route={"Historial de avisos"} active />
        </BreadCrumbContainer>
      </SubHeader>
    </div>
  );
};

export default SubHeaderHistorialAvisos;
