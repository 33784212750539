import React from 'react'
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';

const SubHeaderConversionMonedas = () => {
    const navigate = useNavigate();
    return (
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={"Configuración del marketplace /"} onClick={() => navigate(`/marketplace/configuracion`)} />
          <BreadCrumb route={"Variables de conversión de monedas"} active />
        </BreadCrumbContainer>
      </SubHeader>
    );
}

export default SubHeaderConversionMonedas