import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserContext } from '../components/services/user';
import { getPaises } from '../components/services/api';
import { toast } from 'react-toastify';
import alertConfiguration from '../components/utils/alertsUtils';
import { getPaisesEditorial } from '../components/services/Api/Gestion/Paises';
import {
  getEstructurasCorporativo,
  getEstructurasDistribucion,
  getEstructurasPlantel,
} from '../components/services/Api/MarketPlace/Estructuras';
import { obtenerUserEstructuraInfo } from '../components/utils/userUtils';
import { set } from 'date-fns';

const INITIAL_STATE = {
  paises: [],
};

export const PaisesContext = createContext({
  paises: [],
  paisOrigen: '',
  paisDestino: '',
  estructuras: {},
});

export const PaisesProvider = ({ children, args }) => {
  const { user, activeRole } = useContext(UserContext);
  const [paises, setPaises] = useState(INITIAL_STATE.paises);
  const [paisOrigen, setPaisOrigen] = useState('');
  const [paisDestino, setPaisDestino] = useState('');
  const [estructuras, setEstructuras] = useState(INITIAL_STATE.estructuras);  

  const obtenerPaises = useCallback(async () => {
    try {
      if (activeRole === '') {
        return;
      }
      if (activeRole === 'ADMIN') {
        await getPaises().then((res) => {
          let bodyPaises = res.data.map((registroPais) => {
            return {
              ...registroPais,
              configuracionEquivalencia: {},
            };
          });
          setPaises(bodyPaises);
        });
      } else {
        const estructurasInfo = await obtenerEstructuras();
        setEstructuras(estructurasInfo);
        getPaisesEditorial(estructurasInfo.editorial._id).then((res) => {
          let bodyPaises = res.map((registroPais) => {
            const { pais, configuracionEquivalencia } = registroPais;
            return {
              ...pais,
              configuracionEquivalencia: {
                ...configuracionEquivalencia,
              },
            };
          });
          setPaises(bodyPaises);
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Ha ocurrido un error.', alertConfiguration);
    }
  }, [activeRole]);

  const obtenerEstructuras = useCallback(async () => {
    const { estructuraId, tipoEstructuraRequest } = obtenerUserEstructuraInfo(user, activeRole);
    let estructura = {};
    if(tipoEstructuraRequest === 'editorial'){
      return{ editorial: { _id : estructuraId }};
    }
    if (tipoEstructuraRequest === 'distribucion') {
      await getEstructurasDistribucion(estructuraId).then((res) => {
        estructura = res;
      });
    } else if (tipoEstructuraRequest === 'corporativo') {
      await getEstructurasCorporativo(estructuraId).then((res) => { 
        estructura = res;       
      });
    } else if (tipoEstructuraRequest === 'plantel' || activeRole === 'PADRE_TUTOR') {
      await getEstructurasPlantel(estructuraId).then((res) => {    
        estructura = res;    
      });
    }
    return estructura;
  }, [activeRole])

  useEffect(() => {
    if (activeRole !== '') {
      obtenerPaises();
    }
  }, [activeRole]);

  const values = {
    paises,
    paisOrigen,
    paisDestino,
    estructuras,
    setPaisOrigen,
    setPaisDestino,
    obtenerPaises,
  };

  return (
    <PaisesContext.Provider value={values}>{children}</PaisesContext.Provider>
  );
};
