import React from "react";
import PaquetesAsignadosRow from "./PaquetesAsignadosRow";

const PaquetesAsignadosTable = ({ articulosList, cambiarEstatusArticuloAsignado, tipoDeMoneda }) => {
  return (
    <div className="overflow-x-auto py-10">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th className="w-[50px]"></th>
            <th className="w-13"></th>
            <th>Artículo</th>
            <th>Grupo</th>
            <th>Estatus</th>
            <th>
              <div className="flex gap-2 items-center justify-center">
                Precio ({tipoDeMoneda[0]})
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {articulosList.map((articulo) => (
            <PaquetesAsignadosRow
              key={articulo.asignacionarticulogrupo._id}
              articuloInfo={articulo}
              cambiarEstatusArticuloAsignado={cambiarEstatusArticuloAsignado}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaquetesAsignadosTable;
