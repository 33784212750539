import { shuffle } from "../../Actividades/ActividadesUtils/ActividadesUtils";
import TestContent from "./TestContent";

function Test({ preguntas, respuestas, enunciados, nombre, instrucciones }) {
  const activityData = preguntas.map((el) => {
    const respuestasList = shuffle(el.respuestas)
      .map((obj) => respuestas.find((o) => o._id === obj) || obj)
      .map((v) => ({ ...v, parentId: el._id }));
    return { ...el, respuestas: respuestasList };
  });

  return (
    <>
      <TestContent
        activityData={activityData}
        enunciados={enunciados}
        nombre={nombre}
        instrucciones={instrucciones}
      />
    </>
  );
}

export default Test;
