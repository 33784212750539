import React from "react";
import InputFileCard from "../../../../fragments/Inputs/InputFileCard";
import { ReactComponent as AddIcon } from "../../../../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/deleteMateriaGrupo.svg";

const PaqueteCarruselForm = ({ ver, carrusel, carruselMetodos, setIsEnableSubmitButton }) => {
  const { handleAgregarFotoCarrusel, handleEliminarFoto, handleActualizarFotoCarrusel, handleEliminarFotoCarrusel } =
    carruselMetodos;

  const handleAddFotoCarrusel = () => {
    handleAgregarFotoCarrusel();
    setIsEnableSubmitButton(true);
  };

  const handleDeleteFotoCarrusel = (fotoId) => {
    handleEliminarFotoCarrusel(fotoId);
    setIsEnableSubmitButton(true);
  };

  const handleChangeFoto = (e, fotoId) => {
    handleActualizarFotoCarrusel(e, fotoId);
    setIsEnableSubmitButton(true);
  };

  const handleDeleteFoto = (fotoId) => {
    handleEliminarFoto(fotoId);
    setIsEnableSubmitButton(true);
  };
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex sm:gap-3 flex-col sm:flex-row">
        <p className="text-black text-lg">Carrusel de fotos</p>
        {!ver && <p className="text-gray-400">(Puede agregar máximo 5 fotos)</p>}
      </div>
      {carrusel.length < 5 && !ver && (
        <button
          className="flex px-2 gap-x-2 items-center justify-between bg-nimbusDark text-white p-2 rounded cursor-pointer w-fit"
          onClick={handleAddFotoCarrusel}
        >
          <AddIcon />
          <p>Agregar imagen</p>
        </button>
      )}
      <div className="flex gap-6 flex-wrap justify-center sm:justify-start">
        {carrusel.map((foto) => (
          <div className="relative" key={foto._id}>
            <InputFileCard
              name={`imagen ${foto._id}`}
              id={`imagen ${foto._id}`}
              styles="my-0"
              imageStyles={foto.imagen !== "" && "rounded-none border-none !w-auto"}
              disabled={ver}
              img={foto.imagen}
              onChange={(e) => handleChangeFoto(e, foto._id)}
              deleteImage={() => handleDeleteFoto(foto._id)}
              allowMultipleExtensions
              accept=".jpg, .png"
            />
            {!ver && (
              <DeleteIcon
                className="absolute top-2 right-2 cursor-pointer"
                onClick={() => handleDeleteFotoCarrusel(foto._id)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaqueteCarruselForm;
