import React, { useEffect, useState } from "react";
import { ESTATUS, COSTO } from "../../../../utils/paquetesAsignadosUtils";
import InputSelect from "../../../../fragments/Inputs/InputSelect";

const FILTRO_PAQUETES_INITIAL_VALUES = {
  estatus: "",
  costo: "",
};

const PaquetesAsignadosFilter = ({ articulosList, articulosAsignadosFiltro, setArticulosAsignadosFiltro }) => {
  const [filtro, setFiltro] = useState(FILTRO_PAQUETES_INITIAL_VALUES);

  const filterSelectEstatus = (articulos, estatus) => {
    if (estatus === "") return articulos;

    let newAdquiridosFiltro = [];

    if (estatus === "adquirido") {
      newAdquiridosFiltro = articulos.filter((articuloInfo) => {
        const { articuloadquirido } = articuloInfo;
        if (!articuloadquirido) return false;

        return articuloadquirido.esAdquirido;
      });

      return newAdquiridosFiltro;
    }

    newAdquiridosFiltro = articulos.filter((articuloInfo) => {
      const { articuloadquirido } = articuloInfo;
      if (!articuloadquirido) return true;

      return !articuloadquirido.esAdquirido;
    });

    return newAdquiridosFiltro;
  };

  const filterSelectCosto = (articulos, costo) => {
    if (costo === "") return articulos;

    let newAdquiridosFiltro = [];

    switch (costo) {
      case "0 - 500":
        return articulos.filter((articuloInfo) => {
          const { asignacionarticulogrupo } = articuloInfo;

          const articulo =
            Object.keys(asignacionarticulogrupo.paquete).length === 0
              ? asignacionarticulogrupo.producto
              : asignacionarticulogrupo.paquete;
          return articulo.precioVenta >= 0 && articulo.precioVenta <= 500;
        });
      case "501 - 1000":
        return articulos.filter((articuloInfo) => {
          const { asignacionarticulogrupo } = articuloInfo;

          const articulo =
            Object.keys(asignacionarticulogrupo.paquete).length === 0
              ? asignacionarticulogrupo.producto
              : asignacionarticulogrupo.paquete;
          return articulo.precioVenta >= 501 && articulo.precioVenta <= 1000;
        });
      case "1001 - 5000":
        return articulos.filter((articuloInfo) => {
          const { asignacionarticulogrupo } = articuloInfo;

          const articulo =
            Object.keys(asignacionarticulogrupo.paquete).length === 0
              ? asignacionarticulogrupo.producto
              : asignacionarticulogrupo.paquete;
          return articulo.precioVenta >= 1001 && articulo.precioVenta <= 5000;
        });
      case "Más de 5001":
        return articulos.filter((articuloInfo) => {
          const { asignacionarticulogrupo } = articuloInfo;

          const articulo =
            Object.keys(asignacionarticulogrupo.paquete).length === 0
              ? asignacionarticulogrupo.producto
              : asignacionarticulogrupo.paquete;
          return articulo.precioVenta >= 5001;
        });

      default:
        return newAdquiridosFiltro;
    }
  };

  useEffect(() => {
    let newArticulosFiltro = articulosList;
    newArticulosFiltro = filterSelectEstatus(newArticulosFiltro, filtro.estatus);
    newArticulosFiltro = filterSelectCosto(newArticulosFiltro, filtro.costo);
    setArticulosAsignadosFiltro(newArticulosFiltro);
  }, [filtro, articulosList]);

  return (
    <div className="flex flex-col">
      <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
        <div className="flex gap-x-5">
          <div className="flex flex-wrap items-center">
            <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
              {articulosAsignadosFiltro.length}
            </p>
            <p className="text-black">Artículos asignados</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-x-3 items-end lg:items-start">
          <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
            <InputSelect
              label="Estatus"
              options={ESTATUS}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-44 xl:!w-44 2xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
            />
          </div>
          <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
            <InputSelect
              label="Precio"
              options={COSTO}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-44 xl:!w-44 2xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, costo: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaquetesAsignadosFilter;
