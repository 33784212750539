import { putVisibilidad } from "../../../services/Api/GestorAvisos";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import ReactSwitch from "react-switch";

const VisibilitySwitch = ({ avisoId, checked, getAvisos }) => {
  const handleChangeToggle = async (newValue) => {
    const body = { visibilidad: !newValue };
    try {
      await putVisibilidad(avisoId, body);
      getAvisos();
      toast.success(
        "Se cambió la visibilidad del aviso correctamente",
        alertConfiguration
      );
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    }
  };

  return (
    <ReactSwitch
      onChange={() => handleChangeToggle(checked)}
      id={avisoId + "_switch"}
      height={20}
      width={40}
      checked={checked}
      checkedIcon={false}
      uncheckedIcon={false}
      onColor="#18BAFB"
    />
  );
};

export default VisibilitySwitch;
