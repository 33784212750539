import { useState, useEffect } from "react"
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrowDownSimple.svg'
import {ReactComponent as ArrowUp} from '../../../assets/icons/arrowUpSimple.svg'
import { replaceTextLinks } from "../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils"
import ImagenValidada from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/ImagenValidada"

export default function Colapsable({texto1,texto2, imagen, tipo}) {
    const [dropDown,setDropDown] = useState(false)

    return(
        <div className="min-h-[15rem] visor shadow-lg overflow-hidden relative z-[2] border-divider border-[1px] grid w-full justify-items-start gap-x-6 bg-white rounded-md h-min p-6 my-4">
            <ImagenValidada 
                src={imagen} 
                className={`relative mx-auto md:absolute right-0 top-0 -z-[1] w-[14rem] h-[14rem] md:mt-2 md:mr-2 rounded-lg  ${tipo === "CORTADA" ? 'object-cover' : tipo === "COMPLETA" ? 'object-contain' : "object-fill"}`}
            />
            <div className="flex gap-x-6 px-3 w-full relative">
                <div dangerouslySetInnerHTML={{__html:replaceTextLinks(texto1)}} className="w-full  md:pr-[235px] min-h-[9rem] items-center pt-6 md:pt-12"></div>
                <div className="absolute border border-nimbus left-0 bottom-0 cursor-pointer bg-white rounded-full h-6 w-6 flex justify-center items-center hover:bg-[#f5f5f5]" onClick={()=>setDropDown(!dropDown)}>
                    {dropDown ? <ArrowUp/> : <ArrowDown/>}
                </div>
            </div>
            {dropDown && 
                <div className="md:pt-20 px-3 w-full">
                    <div dangerouslySetInnerHTML={{__html:replaceTextLinks(texto2)}}></div>
                </div>
            }
        </div>
    )
}