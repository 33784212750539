import {useState, useContext, useEffect} from 'react'
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import CustomPoly from './ActividadesUtils/CustomPoly';
import { Trans } from 'react-i18next';
import ImagenValidada from './ActividadesUtils/RecursosValidados/ImagenValidada';

function EncontrarDiferencias({img, img2, hotspots, evaluable, umaBloque, guardarCalificacion, instrucciones}) {
  const [encontrados, setEncontrados] = useState([])
  const [errores, setErrores] = useState(0)
  const activityContext = useContext(ActividadContext)

  const addEncontrado = (hotspotId) =>{
    if(!encontrados.includes(hotspotId)){
      setEncontrados([...encontrados, hotspotId])
    }
  }

  const addError = () =>{
    if(evaluable){
      setErrores(errores + 1)
    }
  }

  const calificar = () =>{
    if(errores.length === 0 || encontrados.length === 0){
      activityContext.postCalificacionActividad(null)
    }else{
      const calificacion = (encontrados.length / hotspots.length * 100) - (errores * 2)
      activityContext.postCalificacionActividad(calificacion)
      if(umaBloque)guardarCalificacion(umaBloque, encontrados.length, JSON.stringify(encontrados))
    }
  }

  useEffect(()=>{
    if(!evaluable){
      const hotspotsTotales = hotspots.map((el)=>(el._id))
      setEncontrados(hotspotsTotales)
    }else{
      setEncontrados([])
    }
  },[evaluable])

  return (
    <>
      {instrucciones && <div className='act-instructions mt-4'>{instrucciones}</div>}
      <div className="mt-8 mb-2 flex items-center w-full max-w-activity rounded-md text-[#627d92] text-sm md:text-base ">
        <div className='flex-1'></div>
        <div className='pl-6 flex items-center transition'>
            <FontAwesomeIcon className="text-sm md:text-lg mr-2" icon={faMagnifyingGlass}></FontAwesomeIcon>
            <Trans i18nKey='foundLabel'></Trans> {encontrados.length}/{hotspots.length}</div>
        <div className='pl-6 flex items-center transition'>
           <FontAwesomeIcon className="text-sm md:text-lg mr-2" icon={faCircleExclamation}></FontAwesomeIcon>
           <Trans i18nKey='errorsLabel'></Trans> {errores}
        </div>
      </div>
      <div className='relative w-full max-w-activity mx-auto shadow-lg rounded-md flex flex-col md:flex-row '>
            <div className='w-full relative mb-4 md:mb-0 md:mr-4'>
                <ImagenValidada 
                    src={img} 
                    className='w-full mx-auto shadow-lg rounded-md flex-1'
                    onClick={addError}
                />
                {hotspots.map((el)=>(
                    el.type === "POLIGONO" ? 
                    <CustomPoly 
                      onClick={()=>addEncontrado(el._id)}
                      points={el.points} 
                      encontrado={encontrados.includes(el._id) || !evaluable}
                      responsiveViewBox
                    />
                    : 
                    <div 
                      className={`absolute transition ${ el.type === "ELIPSE" ? 'rounded-full' : 'rounded' } ${evaluable ? '' : 'opacity-50 bg-[#008000] border border-white'} ${encontrados.includes(el._id) ? 'border-[3px] border-green-500 border-dashed bg-[#00800038]' : ""} ` }
                      style={{width:`${el.actWidth}%` , height:`${el.actHeight}%`, top:`${el.actY}%`,left:`${el.actX}%`}}
                      onClick={()=>addEncontrado(el._id)}
                    >
                    </div>
                ))}
            </div>
            <div className='relative w-full mb-4 md:mb-0'>
                <ImagenValidada
                    src={img2} 
                    className='w-full mx-auto shadow-lg rounded-md flex-1'
                    onClick={addError}
                />
                {hotspots.map((el)=>(
                    el.type === "POLIGONO" ? 
                    <CustomPoly 
                      onClick={()=>addEncontrado(el._id)}
                      points={el.points} 
                      encontrado={encontrados.includes(el._id) || !evaluable}
                      responsiveViewBox
                    />
                    : <div 
                    className={`absolute transition ${ el.type === "ELIPSE" ? 'rounded-full' : 'rounded' } ${evaluable ? '' : 'opacity-50 bg-[#008000] border border-white'} ${encontrados.includes(el._id) ? 'border-[3px] border-green-500 border-dashed bg-[#00800038]' : ""} ` }
                    style={{width:`${el.actWidth}%` , height:`${el.actHeight}%`, top:`${el.actY}%`,left:`${el.actX}%`}}
                    onClick={()=>addEncontrado(el._id)}
                    >
                    </div>
                ))}
            </div>
      </div>
      {evaluable ?
      <button 
        className='mt-4 md:mt-8 border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100 cursor-pointer w-fit'
        onClick={()=>calificar()}
        >
          <Trans>{(errores > 0) || (encontrados.length > 0) ? 'Calificar' : 'Cerrar' }</Trans>
      </button>:null
      }
    </>
  )
}

EncontrarDiferencias.defaultProps = {
  evaluable:true
}

export default EncontrarDiferencias