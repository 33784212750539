import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faDiamond } from "@fortawesome/free-solid-svg-icons";

const Cartas = ({ data, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <>
      <div className="absolute max-h-[9rem] md:max-h-[18rem]">
        <div
          className={"card " + (isFlipped ? "move" : "move-back")}
          style={{ zIndex: -index, top: index * 5 }}
        >
          <div className={"back-card " + (isFlipped ? "" : "flip")}>
            {data.imagen ? 
            <img 
              src={typeof data.imagen !== 'string' ? URL.createObjectURL(data.imagen) : data.imagen}
              alt=""
              className="rounded-lg mb-3 aspect-[16/10] object-cover"
            />
            :""}
            <span className={`${!data.imagen ? 'flex items-center h-full' : ''}`}>{data.texto}</span>
            {!data.imagen ?
            <div className="images">
              <FontAwesomeIcon icon={faDiamond} color="#00c0ff" className="a" />
              <FontAwesomeIcon icon={faDiamond} color="#00c0ff" className="b" />
              <FontAwesomeIcon icon={faHeart} color="#1c7ccc" className="c" />
              <FontAwesomeIcon icon={faHeart} color="#1c7ccc" className="d" />
            </div>:""}
          </div>
          <div className={"front-card " + (isFlipped ? "flip" : "")}></div>
        </div>
        {!isFlipped ? (
          <button
            onClick={() => setIsFlipped(true)}
            style={{ position: "relative", top: "2rem", index: -index }}
            className="cards-btn"
          >
            Revelar carta
          </button>
        ) : (
          <button
            style={{zIndex: -index }}
            onClick={() => setIsFlipped(false)}
            className="cards-btn top-[6rem] md:top-[5rem] relative"
          >
            Regresar carta
          </button>
        )}
      </div>
    </>
  );
};

export default Cartas;
