import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import LoadingScreen from "../../fragments/LoadingScreen";
import SubHeaderMarketPlace from "./SubHeaderMarketPlace";
import MenuCard from "../../fragments/MenuCard";
import ComisionModal from "./fragments/ComisionModal";
import { useNavigate } from "react-router-dom";
import ContentContainer from "../../fragments/ContentContainer";
import PorcentajeGananciaModal from "./fragments/PorcentajeGananciaModal";
import { UserContext } from "../../services/user";
import { CONFIGURACION_MARKETPLACE_MENUS } from "./ConfiguracionMarketplaceMenus";
import withUserAccesability from "../../hoc/withUserAccesability";

const ConfiguracionMarketplace = ({isUserAllowed}) => {
  const [loading, setLoading] = useState(true);
  const [isOpenComision, setIsOpenComision] = useState(false);
  const [isOpenPorcentaje, setIsOpenPorcentaje] = useState(false);
  const [configuracionMarketplaceMenus, setConfiguracionMarketPlaceMenus] = useState([]);
  const { user, activeRole } = useContext(UserContext);
  const navigate = useNavigate()

  const handleModuloClick = (onClick) => {
    if (onClick) {
      onClick({ navigate, setIsOpenComision, setIsOpenPorcentaje, user, activeRole });
    }
  };

  const obtenerEstatusModulos = useCallback(async () => {
    if (activeRole !== "") {
      let newModulosMarketplace = CONFIGURACION_MARKETPLACE_MENUS.filter((menu) => menu.roles.includes(activeRole));
      newModulosMarketplace = await Promise.all (newModulosMarketplace.map(async (modulo) => {
        const statusResponse = await modulo.status({ user, activeRole });
        modulo.completed = statusResponse
        return modulo;
      }))
      setConfiguracionMarketPlaceMenus(newModulosMarketplace);
      setLoading(false)
    }
  }, [activeRole]);

  useEffect(() => {
    if(activeRole !== ""){
      if(!isUserAllowed){
        navigate("/NotFound")
      }else{
        obtenerEstatusModulos();
      }
    }
  }, [obtenerEstatusModulos, activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderMarketPlace />
            <ContentContainer>
              <div className="flex flex-wrap gap-6 justify-center md:justify-start">
                {configuracionMarketplaceMenus.map((modulo, idx) => (
                  <MenuCard
                    key={idx}
                    icon={modulo.icon}
                    title={modulo.title}
                    onClick={() => handleModuloClick(modulo.onClick)}
                    status={modulo.completed}
                  />
                ))}
              </div>
            </ContentContainer>
            <ComisionModal isOpen={isOpenComision} setIsOpen={setIsOpenComision} />
            <PorcentajeGananciaModal isOpen={isOpenPorcentaje} setIsOpen={setIsOpenPorcentaje} />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withUserAccesability(ConfiguracionMarketplace);
