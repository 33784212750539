import { _delete, _get, _post, _put } from "../../http";

export const getHistorialAvisos = (userID) => {
  return _get(`/avisos/historial/usuario/${userID}`);
};

export const getAvisoByID = (avisoID) => {
  return _get(`/avisos/${avisoID}`);
};

export const markAvisoAsRead = (body) => {
  return _put("/avisos/historial/marcarLeido", body);
};

export const deleteAvisoFromHistory = (body) => {
  return _put("/avisos/historial/eliminarDeHistorial", body);
};
