import React, { Fragment, useCallback, useEffect, useState } from "react";
import LoadingScreen from "../../fragments/LoadingScreen";
import ContentContainer from "../../fragments/ContentContainer";
import SubHeaderCuentasComerciales from "./SubHeaderCuentasComerciales";
import { MESSAGES } from "../../utils/messagesUtils";
import NoDataMessage from "../../fragments/NoDataMessage";
import CuentasComercialesTable from "./fragments/CuentasComercialesTable";
import CuentasComercialesFilter from "./fragments/CuentasComercialesFilter";
import { getCuentasComerciales } from "../../services/Api/MarketPlace/CuentasComerciales";
import { usePagination } from "../../hooks/usePagination";
import TablePagination from "../../fragments/TablePagination";
import { getComisionGeneral } from "../../services/Api/MarketPlace/ComisionUsoPlataforma";

const CuentasComerciales = () => {
  const [loading, setLoading] = useState(true);
  const [cuentasComerciales, setCuentasComerciales] = useState([]);
  const [cuentasComercialesFiltro, setCuentasComercialesFiltro] = useState([]);
  const [comisionDeUsoGeneral, setComisionDeUsoGeneral] = useState(10);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(cuentasComercialesFiltro, 20);

  const obtenerCuentasComerciales = useCallback(async () => {
    try {
      const cuentasComercialesResponse = await getCuentasComerciales();
      setCuentasComerciales(cuentasComercialesResponse);
      setCuentasComercialesFiltro(cuentasComercialesResponse);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const obtenerComisionDeUsoGeneral = useCallback(async () => {
    try {
      const comisionDeUsoGeneralResponse = await getComisionGeneral();
      setComisionDeUsoGeneral(comisionDeUsoGeneralResponse.comision);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    obtenerCuentasComerciales();
    obtenerComisionDeUsoGeneral();
  }, [obtenerCuentasComerciales]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderCuentasComerciales />
            <div
              className={`${
                cuentasComerciales.length !== 0 && "justify-between"
              } relative flex-1 flex flex-col px-4 sm:px-8`}
            >
              <div>
                <CuentasComercialesFilter
                  cuentasComerciales={cuentasComerciales}
                  cuentasComercialesFiltro={cuentasComercialesFiltro}
                  setCuentasComercialesFiltro={setCuentasComercialesFiltro}
                />
                <CuentasComercialesTable
                  cuentasComerciales={currentData()}
                  comisionDeUsoGeneral={comisionDeUsoGeneral}
                  obtenerCuentasComerciales={obtenerCuentasComerciales}
                />
                {cuentasComerciales.length !== 0 && cuentasComercialesFiltro.length === 0 && (
                  <NoDataMessage message={MESSAGES.MESSAGE_CUENTAS_COMERCIALES_TABLE} />
                )}
              </div>
              <div>
                {cuentasComerciales.length === 0 ? (
                  <NoDataMessage message={MESSAGES.MESSAGE_CUENTAS_COMERCIALES_TABLE} />
                ) : (
                  <TablePagination
                    next={next}
                    prev={prev}
                    currentPage={currentPage}
                    data={currentData()}
                    jump={jump}
                    maxPage={maxPage}
                  />
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CuentasComerciales;
