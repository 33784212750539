import React from "react";
import NimbusModal from "../../../../fragments/NimbusModal";
import CancelButton from "../../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import { deleteVaciarCarritoDeCompras } from "../../../../services/Api/MarketPlace/CarritoDeCompras";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../utils/alertsUtils";
import useFormSubmit from "../../../../hooks/useFormSubmit";
import { useDispatch } from "react-redux";
import { fetchCarritoAsync } from "../../../../../reducers/carrito/carritoAction";

const VaciarCarritoCompraModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const vaciarCarritoSubmit = async () => {
    try {
      await deleteVaciarCarritoDeCompras();
      toast.success("Carrito vaciado correctamente.", alertConfiguration);
      dispatch(fetchCarritoAsync());
    } catch (error) {
      console.log(error);
    }
  };
  const [formStatus, handleSubmit] = useFormSubmit(vaciarCarritoSubmit);

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold">Vaciar carrito de compra</h1>
      <p className="w-96 px-4 sm:px-2">
        Está a punto de eliminar todos los artículos que se encuentran agregados en el carrito de compra, y vaciarlo
        completamente
      </p>
      <div className="w-96 px-4 text-center mb-6">
        <p className="text-nimbusDark font-bold">¿Está seguro que desea vaciar su carrito?</p>
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton text="Aceptar" disabled={formStatus === "submitting"} onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default VaciarCarritoCompraModal;
