const getStringMonth = (month) =>{
    switch(month){
        case 0: return 'Enero'
        case 1: return 'Febrero'
        case 2: return 'Marzo'
        case 3: return 'Abril'
        case 4: return 'Mayo'
        case 5: return 'Junio'
        case 6: return 'Julio'
        case 7: return 'Agosto'
        case 8: return 'Septiembre'
        case 9: return 'Octubre'
        case 10: return 'Noviembre'
        case 11: return 'Diciembre'
        default: break;
    }
}

const formatDate = (date) =>{
    return ((date.getMonth()+1)<10?'0'+(date.getMonth()+1):date.getMonth()+1)+'/'+(date.getDate()<10?'0'+date.getDate():date.getDate())+'/'+date.getFullYear()
}

const formatPostDate = (date) =>{
    return (date.getFullYear()+'-'+((date.getMonth()+1)<10?'0'+(date.getMonth()+1):date.getMonth()+1)+'-'+(date.getDate()<10?'0'+date.getDate():date.getDate()))
}

const formatShowDate = (date) =>{
    if (!isNaN(Date.parse(date)) && new Date(date).getFullYear() >= 1900) {
        return ((date.getDate()<10?'0'+date.getDate():date.getDate())+'-'+getStringMonth(Number(((date.getMonth())<10?'0'+(date.getMonth()):date.getMonth())))+'-'+date.getFullYear());
    }
}


const dateOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };

const addDays = (date, days) => {
date.setDate(date.getDate() + days);
return date;
};

const formatTime = (date) => {
    return (
        (date.getHours() < 10
        ? "0" + date.getHours()
        : date.getHours()) +
        ":" +
        (date.getMinutes() < 10
        ? "0" + date.getMinutes()
        : date.getMinutes())
    );
};

module.exports={
    'getStringMonth': getStringMonth,
    'formatDate':formatDate,
    'formatPostDate':formatPostDate,
    'formatShowDate':formatShowDate, 
    'dateOptions': dateOptions,
    'addDays': addDays,
    'formatTime': formatTime
}