import React from 'react';
import PaisesRow from './PaisesRow';

const PaisesTable = ({ paisesList }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th className='w-[7rem]'></th>
            <th>País</th>
            <th className='md:w-[10rem] lg:w-[15rem]'>Moneda nacional</th>
            <th>Símbolo moneda nacional</th>
            <th className='lg:w-[20rem]'>Valor moneda nacional respecto al dólar</th>
            <th className='w-[10rem]'></th>
          </tr>
        </thead>
        <tbody>
          {paisesList.map((pais) => (
            <PaisesRow key={pais._id} paisInfo={pais} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaisesTable;
