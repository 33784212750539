import React, { useState } from "react";
import ReactSlider from "react-slider";
import ActivityImage from "../ActividadesUtils/ActImage";
import ImagenValidada from "../ActividadesUtils/RecursosValidados/ImagenValidada";
import VideoValidado from "../ActividadesUtils/RecursosValidados/VideoValidado";

function BarSlider({ data, instrucciones }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const orderedData = [...data].sort((a, b) => (a.orden > b.orden ? 1 : -1));
  return (
    <>
      <div className="lg:mt-8"></div>
      {instrucciones && (
        <div className="block-instructions mt-10 mb-4">{instrucciones}</div>
      )}
      <div className="flex items-center justify-between mb-10">
        <div className="flex flex-1 justify-center align-center">
          <ReactSlider
            className="vertical-slider"
            marks
            markClassName="slider-mark"
            min={0}
            max={orderedData.length - 1}
            onAfterChange={(e) => setCurrentSlide(e)}
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            orientation="vertical"
            pearling
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow + 1}</div>
            )}
          />
        </div>
        <div className="w-full max-w-[50rem]">
          {orderedData[currentSlide]?.imagen && (
            <ImagenValidada
              className="w-full rounded-lg mb-4 aspect-video object-cover overflow-hidden bg-[#f6fcff]"
              src={orderedData[currentSlide]?.imagen}
            />
          )}
          {orderedData[currentSlide]?.video && (
            <VideoValidado
              className="w-full rounded-lg mb-4 aspect-video object-cover overflow-hidden bg-[#f6fcff]"
              src={orderedData[currentSlide]?.video}
              key={orderedData[currentSlide]?._id}
            />
          )}
          {orderedData[currentSlide]?.texto && (
            <div className="font-bold text-nimbusLight text-justify min-h-[120px]">
              {orderedData[currentSlide]?.texto}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

BarSlider.defaultProps = {
  slidesLength: 4,
};

export default BarSlider;
