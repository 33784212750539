import React, { useRef, useState } from "react";
import useOutsideClick from "../../../../utils/OutsideClick";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-resize-nimbus-build/build/ckeditor";
import TextEditorButtons from "../../../../constructor/umas/TextEditorButtons";
import { errorToast } from "../../../../constructor/umas/ActividadesUtils/FormularioUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectAvisoData } from "../../../../../reducers/avisoData/avisoDataSelector";
import { setContenidoPie } from "../../../../../reducers/avisoData/avisoDataAction";

const PieDeFoto = ({
  pieDeFoto,
  setPieDeFoto,
  ckEditorTable,
  setCkEditorTable,
  contenidoId,
}) => {
  //configuracion de la tabla
  const tableConfig = {
    table: {
      tableProperties: {
        // The default styles for tables in the editor.
        // They should be synchronized with the content styles.
        defaultProperties: {
          borderStyle: "dashed",
          borderColor: "hsl(90, 75%, 60%)",
          borderWidth: "3px",
          alignment: "left",
          width: "550px",
          height: "450px",
        },
        // The default styles for table cells in the editor.
        // They should be synchronized with the content styles.
        tableCellProperties: {
          defaultProperties: {
            horizontalAlignment: "center",
            verticalAlignment: "bottom",
            padding: "10px",
          },
        },
      },
    },
  };

  const [visible, setVisible] = useState(false);
  const [infoHolder, setInfoHolder] = useState("");
  const dispatch = useDispatch();
  const { contenido } = useSelector(selectAvisoData);

  const handleCancelarPie = () => {
    setCkEditorTable(true);
    setPieDeFoto(pieDeFoto);
  };

  const handleCerrarText = () => {
    setCkEditorTable(true);

    if (!ckEditorTable) {
      setPieDeFoto(infoHolder);
      dispatch(setContenidoPie(infoHolder, contenido, contenidoId));
    }
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (visible) setVisible(false);
  });

  const internetMessage =
    "Hubo un problema al guardar. Asegúrate de estar conectado a internet.";

  return (
    !ckEditorTable && (
      <div
        className={`fixed z-20 bottom-0 bg-slate-500 p-4 left-64 right-64`}
        id="ckEditor"
        ref={ref}
      >
        <div className={`bg-white p-4 max-h-[49rem]`}>
          <div class="editor-wrapper min-h-[150px]">
            {/* Editor para pies de página */}
            <CKEditor
              editor={ClassicEditor}
              data={pieDeFoto ? pieDeFoto : ""}
              config={{
                tableConfig,
                toolbar: ["bold", "italic", "fontColor", "fontBackgroundColor"],
                removePlugins: ["Title"],
                heading: {
                  options: [],
                },
              }}
              onReady={(editor) => {
                editor.focus();
                const data = editor.getData();
                setInfoHolder(data);
                if (data === "") {
                  editor.execute("fontColor", { value: "#697d99" });
                  editor.execute("alignment", { value: "center" });
                }
              }}
              onBlur={(event, editor) => {
                const data = editor.getData();
                setInfoHolder(data);
              }}
            />
            <TextEditorButtons
              handleCancelar={() => handleCancelarPie()}
              handleSave={
                window.navigator.onLine
                  ? () => handleCerrarText()
                  : () => errorToast(internetMessage)
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default PieDeFoto;
