import { CONFIGURACION_MARKETPLACE_MENUS } from "../comercial/ConfiguracionMarketplace/ConfiguracionMarketplaceMenus";
import {
  getModulosMarketplaceAdmin,
  getModulosMarketplaceCorporativo,
  getModulosMarketplaceDistribucion,
  getModulosMarketplaceEditorial,
  getModulosMarketplacePlantel,
} from "../services/Api/MarketPlace/ConfiguracionMarketplace";

const obtenerEstatusConfiguracionMarketplace = async (activeRole, user) => {
  let newModulosMarketplace = CONFIGURACION_MARKETPLACE_MENUS.filter((menu) => menu.roles.includes(activeRole));
  newModulosMarketplace = await Promise.all(
    newModulosMarketplace.map(async (modulo) => {
      return await modulo.status({ user, activeRole });
    })
  );

  return newModulosMarketplace;
};

const obtenerEstatusModulosConfiguracionMarketplace = async (activeRole, user) => {
  let newModulosMarketplace = { estatusModulo: true };
  //El atributo conversionDeMonedas se agrega manualmente debido a que el apartado utilizado anteriormente ya no se encuentra en uso
  switch (activeRole) {
    case "ADMIN":
      newModulosMarketplace = await getModulosMarketplaceAdmin()
      break;
    case "DIR_EDITORIAL":
      newModulosMarketplace = await getModulosMarketplaceEditorial(user.editorial._id);
      newModulosMarketplace = {
        ...newModulosMarketplace,
        conversionDeMonedas: true,
      }
      break;
    case "DISTRIBUIDOR":
      newModulosMarketplace = await getModulosMarketplaceDistribucion(user.distribucion._id);
      newModulosMarketplace = {
        ...newModulosMarketplace,
        conversionDeMonedas: true,
      }
      break;
    case "DIR_CORPORATIVO":
      newModulosMarketplace = await getModulosMarketplaceCorporativo(user.escuela._id);
      newModulosMarketplace = {
        ...newModulosMarketplace,
        conversionDeMonedas: true,
      }
      break;
    case "DIR_PLANTEL":
      newModulosMarketplace = await getModulosMarketplacePlantel(user.escuela.campus._id);
      newModulosMarketplace = {
        ...newModulosMarketplace,
        conversionDeMonedas: true,
      }
      break;
    default:
  }

  delete newModulosMarketplace.nombre;
  delete newModulosMarketplace._id;
  delete newModulosMarketplace.porcentajeDeGanancia;

  return newModulosMarketplace;
};

const obtenerEstatusModulos = async (activeRole, user) => {
  let adminCompleted = false;
  let userCompleted = false;

  let newModulosMarketplaceAdmin = await obtenerEstatusModulosConfiguracionMarketplace("ADMIN", user);

  adminCompleted =  Object.values(newModulosMarketplaceAdmin).every((modulo) => modulo === true);

  if (activeRole !== "ADMIN") {
    let newModulosMarketplace = await obtenerEstatusModulosConfiguracionMarketplace(activeRole, user);

    userCompleted = Object.values(newModulosMarketplace).every((modulo) => modulo === true);
  }

  return { adminCompleted, userCompleted };
};

const getTutorChildren = async (user) => {
  let hasChildren = false;
  if (user.escuela.campus.tutores[0].hijos.length !== 0) hasChildren = true;

  return hasChildren;
};

export const obtenerEstatusMarketplace = async (activeRole, user) => {
  let hasChildren = false;
  let { adminCompleted, userCompleted } = await obtenerEstatusModulos(activeRole, user);
  if (activeRole === "PADRE_TUTOR") hasChildren = await getTutorChildren(user);
  return { adminCompleted, userCompleted, hasChildren };
};
