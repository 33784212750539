import React from "react";

function ShowCount({ count, text = "Contenidos", styles }) {
  return (
    <div className={`flex items-center my-4 ${styles}`}>
      <div
        className={`mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center w-fit `}
      >
        {count ? count : 0}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default ShowCount;
