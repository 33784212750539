import React, { Fragment, useContext, useEffect, useState } from 'react';
import SubHeaderMisVentas from './fragments/SubHeaderMisVentas';
import { usePagination } from '../../hooks/usePagination';
import NoDataMessage from '../../fragments/NoDataMessage';
import withUserAccesability from '../../hoc/withUserAccesability';
import TablePagination from '../../fragments/TablePagination';
import { MESSAGES } from '../../utils/messagesUtils';
import MisVentasTable from './fragments/MisVentasTable';
import MisVentasFilter from './fragments/MisVentasFilter';
import { UserContext } from '../../services/user';
import { useNavigate } from 'react-router-dom';
import { obtenerUserEstructuraInfo } from '../../utils/userUtils';
import {
  getComprasPorEstructura,
  getPedidosPorEstructura,
} from '../../services/Api/MarketPlace/MisPedidos';
import { ESTATUS } from '../../utils/MisVentasUtils';
import LoadingScreen from '../../fragments/LoadingScreen';

const MisVentas = ({ isUserAllowed }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [misVentas, setMisVentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [misVentasFiltro, setMisVentasFiltro] = useState([]);

  useEffect(() => {
    if (user.activeRole !== '') {
      if (!isUserAllowed) {
        navigate('/NotFound');
      }
    }
  }, [user.activeRole]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    misVentasFiltro,
    20
  );

  const getData = async () => {
    setLoading(true);
    const { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(
      user.user,
      user.activeRole
    );
    let pedidos = [];
    if (
      user.activeRole === 'DIR_PLANTEL' ||
      user.activeRole === 'ADMIN_OPERATIVO'
    ) {
      await getComprasPorEstructura(tipoEstructuraRequest, estructuraId).then(
        (res) =>
          (pedidos = res.filter(
            (compra) => compra.esPagado && ESTATUS.includes(compra.estatus)
          ))
      );
    } else {
      await getPedidosPorEstructura(tipoEstructuraRequest, estructuraId).then(
        (res) =>
          (pedidos = res.filter((compra) =>
          ESTATUS.includes(compra.estatus)
          ))
      );
    }
    let ventasRecientes = pedidos.sort(
      (a, b) => new Date(b.fechaCreacion) - new Date(a.fechaCreacion)
    );
    setMisVentas(ventasRecientes);
    setMisVentasFiltro(ventasRecientes);
    setLoading(false);
  };

  useEffect(() => {
    if (user.activeRole !== '') {
      getData();
    }
  }, [user.activeRole]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderMisVentas />
          <div
            className={`${
              misVentasFiltro.length !== 0 && 'justify-between'
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <MisVentasFilter
                misVentasList={misVentas}
                misVentasFiltro={misVentasFiltro}
                setMisVentasFiltro={setMisVentasFiltro}
              />
              <MisVentasTable misVentasList={currentData()} />
            </div>
            <div>
              {misVentasFiltro.length === 0 ? (
                <NoDataMessage message={MESSAGES.MESSAGES_MIS_VENTAS_TABLE} />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withUserAccesability(MisVentas);
