import React from "react";
import ActiveIcon from "../../../../assets/icons/active.svg";

const ActiveWrapper = ({ children, active, handleClick, styles, checkMark=true, wrapperStyles = "" }) => {
  return (
    <div
      className={`relative hover:outline -outline-offset-1 hover:outline-2 outline-nimbusDark rounded-md cursor-pointer ${
        active && "outline outline-2"
      } ${wrapperStyles}`}
      onClick={handleClick}
    >
      {children}
      {checkMark && active && <img className={`absolute h-4 top-1 right-1 ${styles}`} src={ActiveIcon} alt="" />}
    </div>
  );
};

export default ActiveWrapper;
