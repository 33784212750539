import InputText from "../../../fragments/Inputs/InputText";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";

const ListaDifusionForm = ({ lista, errors, handleChange, setFormador }) => {
  const { ver, editar } = useListaDifusionDetalles();

  return (
    <div className="flex flex-col mt-8">
      <div className="w-full text-left">
        <InputText
          id={"nombreLista"}
          name={"nombreLista"}
          label={"Nombre de la lista de difusión"}
          placeholder={"Nombre"}
          value={lista.nombreLista}
          onChange={(e) => handleChange(e, "nombreLista")}
          error={errors["nombreLista"]}
          styles="!w-full"
          disabled={ver}
        />
      </div>
    </div>
  );
};

export default ListaDifusionForm;
