import { AddUsersContextProvider, useAddUsers } from "./AddUsersContext";
import { MESSAGES } from "../../../utils/messagesUtils";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { useListaDifusionDetalles } from "../ListaDifusionDetalles/ListaDifusionDetallesContext";
import { usePagination } from "../../../hooks/usePagination";
import { UserContext } from "../../../services/user";
import AddUsersDescription from "./AddUsersDescription";
import AddUsersFilters from "./AddUsersFilters";
import AddUsersTable from "./AddUsersTable";
import alertConfiguration from "../../../utils/alertsUtils";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import NoDataMessage from "../../../fragments/NoDataMessage";
import TablePagination from "../../../fragments/TablePagination";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import { ReactComponent as Add } from "../../../../assets/icons/addBlueSmall.svg";
import { ReactComponent as Close } from "../../../../assets/icons/closeRoundedIcon.svg";

const AddUsers = ({ onCloseModal }) => {
  return (
    <AddUsersContextProvider>
      <AddUsersComponents onCloseModal={onCloseModal} />
    </AddUsersContextProvider>
  );
};

const FILTRO_ADD_RECIPIENTS_INITIAL_VALUES = {
  busqueda: "",
  rol: "",
  nivel: "",
  grado: "",
  grupo: "",
};

const AddUsersComponents = ({ onCloseModal }) => {
  const user = useContext(UserContext);
  const {
    setDestinatarios,
    setDestinatariosFiltro,
    destinatariosFiltro,
    destinatarios,
    setUserRol,
  } = useAddUsers();
  const { setUsersAdded, usersAdded } = useListaDifusionDetalles();

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    destinatariosFiltro,
    20
  );

  const [usuariosList, setUsuariosList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtro, setFiltro] = useState(FILTRO_ADD_RECIPIENTS_INITIAL_VALUES);

  const deshabilitarBotonDestinatarios =
    destinatarios?.filter((user) => user.esSeleccionado).length === 0;

  useEffect(() => {
    // Quita los usuarios ya añadidos del array
    const newUsers = usuariosList.filter(
      (user) =>
        !usersAdded.some(
          (addedUser) => addedUser.usuario._id === user.usuario._id
        )
    );

    setDestinatarios((prevUsuarios) => [...prevUsuarios, ...newUsers]);
    setDestinatariosFiltro((prevUsuarios) => [...prevUsuarios, ...newUsers]);

    return () => {
      setDestinatarios([]);
      setDestinatariosFiltro([]);
    };
  }, [usuariosList]);

  useEffect(() => {
    if (user) {
      setUserRol(user.activeRole);
    }
  }, [user]);

  const handleAddSelectedUsers = () => {
    // Separar usuarios seleccionados y no seleccionados
    const selectedUsers = destinatarios?.filter((user) => user.esSeleccionado);
    const selectedUsersTransformed = selectedUsers.map((user) => ({
      ...user,
      esSeleccionado: false,
    }));
    const nonSelectedUsers = destinatarios?.filter(
      (user) => !user.esSeleccionado
    );

    // Añadir usuarios seleccionados a usersAdded
    setUsersAdded((added) => [...added, ...selectedUsersTransformed]);

    // Actualizar destinatarios y destinatariosFiltro con usuarios no seleccionados
    setDestinatarios(nonSelectedUsers);
    setDestinatariosFiltro(nonSelectedUsers);

    toast.success(
      "Los usuarios se han agregado a la lista exitosamente.",
      alertConfiguration
    );
  };

  return (
    <div className="relative flex flex-col text-center gap-y-4 max-w-[85dvw] max-h-[70dvh]">
      <button type="button" onClick={onCloseModal}>
        <Close className="absolute w-11 h-11 -top-14 -right-4" />
      </button>
      <AddUsersDescription />
      <p className="text-black py-4 font-bold">Estructura:</p>
      <AddUsersFilters
        estructuraUser={user.user}
        setUsuariosList={setUsuariosList}
        setIsLoading={setIsLoading}
        filtro={filtro}
        setFiltro={setFiltro}
      />
      <div
        className={`${
          destinatariosFiltro?.length !== 0 && "justify-between"
        } relative flex-1 flex flex-col px-4 sm:px-8 gap-y-5`}
      >
        <div className="flex items-center justify-center md:justify-start">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {destinatariosFiltro.length}
          </p>
          <p className="text-black">Usuarios</p>
        </div>
        <div className="flex flex-col md:flex-row gap-3 w-full md:w-fit p-3 self-end">
          <InputBuscar
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
          />
          <button
            type="button"
            className="w-full md:w-fit bg-[#18bafb] text-white px-2 py-2 p-4 rounded-lg flex items-center justify-center disabled:bg-[#18bafb]/40 disabled:bg-opacity-90 disabled:cursor-not-allowed"
            onClick={handleAddSelectedUsers}
            disabled={deshabilitarBotonDestinatarios}
          >
            <Add className="w-5 h-5 mr-2 white-svg" />
            Añadir destinatarios
          </button>
        </div>
        <AddUsersTable currentUsers={currentData()} isLoading={isLoading} />
        <div>
          {destinatariosFiltro?.length === 0 ? (
            <NoDataMessage
              message={MESSAGES.MESSAGES_AGREGAR_USUARIOS_LISTA_DIFUSION_TABLE}
            />
          ) : (
            <TablePagination
              next={next}
              prev={prev}
              jump={jump}
              data={currentData()}
              currentPage={currentPage}
              maxPage={maxPage}
            />
          )}
        </div>
      </div>

      <div className="flex justify-center">
        <CancelButton
          text="Cerrar"
          bgColor="bg-divider"
          onClick={onCloseModal}
          styles="mb-4"
        />
      </div>
    </div>
  );
};

export default AddUsers;
