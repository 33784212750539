import React from 'react'
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../../fragments/BreadCrumb';

const SubHeaderPaqueteProductos = ({ editar, ver }) => {
    const navigate = useNavigate();
    return (
      <SubHeader>
        <BreadCrumbContainer>
          <BreadCrumb route={"Paquetes /"} onClick={() => navigate(`/marketplace/gestionProductos/paquetes`)} />
          <BreadCrumb route={`${editar ? "Editar" : ver ? "Visualizar" : "Crear"} paquete`} active />
        </BreadCrumbContainer>
      </SubHeader>
    );
  };

export default SubHeaderPaqueteProductos