import React, { Fragment, useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../fragments/LoadingScreen";
import ContentContainer from "../../fragments/ContentContainer";
import withUserAccesability from "../../hoc/withUserAccesability";
import ProductosContainer from "./ProductosContainer";
import { useDispatch, useSelector } from "react-redux";
import { selectMarketplaceIsLoading } from "../../../reducers/marketplace/marketplaceSelector";
import { UserContext } from "../../services/user";
import { fetchShowBuscador } from "../../../reducers/marketplace/marketplaceAction";
import { PaisesContext } from "../../../contexts/PaisesContext";

const Tienda = ({ isUserAllowed }) => {
  const { user, activeRole } = useContext(UserContext);
  const { setPaisDestino, obtenerPaises } = useContext(PaisesContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(selectMarketplaceIsLoading)
  const [isLoading, setIsLoading] = useState(true)

  const obtenerMoneda = async () => {
    try {
      await obtenerPaises();
      setPaisDestino(user?.escuela?.campus?.dirFiscalPais?._id);      
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    if(activeRole !== ""){
      setIsLoading(false)
      if (!isUserAllowed) {
        navigate("/NotFound");
      }
      dispatch(fetchShowBuscador(true));
      obtenerMoneda();
    }
  }, [activeRole]);

  return (
    <Fragment>
      {loading || isLoading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor h-[calc(100vh-13.35rem)] sm:h-[calc(100vh-13.3rem)] md:h-[calc(100vh-13.9rem)] lg:h-[calc(100vh-13.85rem)] flex flex-col overflow-hidden">
            <ContentContainer styles="pl-0 pr-4 sm:pl-0 sm:pr-8 !mt-0">
              <div className="w-full">
                <ProductosContainer/>
              </div>
            </ContentContainer>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withUserAccesability(Tienda);
