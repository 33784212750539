import React from "react";
import Check from "../../assets/icons/activeIcon.svg";

const ConfirmResetPassword = () => {
  return (
    <div className="w-full min-h-screen md:h-auto md:min-h-[100vh] bg-[#fcfcfc] flex flex-col justify-center items-center">
      <div className="w-[22rem] flex flex-col justify-center px-8 md:px-0">
        <div className="flex flex-col items-center mx-auto mb-8">
          <img className="h-auto w-14" src={Check} alt="logo.svg" />

          <h1 className="text-nimbusDark font-bold text-2xl md:text-3xl mt-8 text-center">
            Se ha guardado tu nueva contraseña
          </h1>
          <p className="text-[#3A404C] text-center mt-5 text-sm md:text-base">
            Has creado tu nueva contraseña con éxito, ahora puedes iniciar
            sesión.
          </p>
        </div>
        <a href="/" className="bg-nimbusDark font-semibold text-white w-full rounded-lg py-2 text-center">
          Iniciar sesión
        </a>
      </div>
    </div>
  );
};

export default ConfirmResetPassword;
