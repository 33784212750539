import { _post, _get } from "../../../http";

// endpoints para la asignación de artículos

export const postAsignarArticulos = (body) => _post("/asigancionArticulos", body);

export const postAsignarArticulosGrupos = (plantelId, grupoId, body) =>
  _post(`/asigancionArticulos/plantel/${plantelId}/grupo/${grupoId}`, body);

export const getAsignarArticulos = (estructura, estructuraId) =>
  _get(`/asigancionArticulos/${estructura}/${estructuraId}`);

export const getAsignarArticulosGrupos = (plantelId, grupoId) =>
  _get(`/asigancionArticulos/plantel/${plantelId}/grupo/${grupoId}`);
