import { _get } from "../../../http";

export const getEstructurasRelacionadas = (estructura, estructuraId) => {
    return _get(`/listasDeDifucion/obtenerEstructurasRelacionadas/${estructura}/${estructuraId}`);
}

export const getUsuariosPorEstructura = (estructura, estructuraId) => {
    return _get(`/listasDeDifucion/obtenerUsuariosPorEstructura/${estructura}/${estructuraId}`);
}

export const getAllUsuariosPorEstructura = (estructura, estructuraId) => {
    return _get(`/listasDeDifucion/obtenerUsuariosTodasEstructurasRelacionadas/${estructura}/${estructuraId}`);
}

export const getPlantelNiveles = (plantelId) => {
    return _get(`/listasDeDifucion/plantel/${plantelId}/niveles`);
}

export const getGradosPorNivel = (nivelId) => {
    return _get(`/listasDeDifucion/nivel/${nivelId}/grados`);
}

export const getGruposFiltered = (nivelId, gradoId) => {
    return _get(`/listasDeDifucion/grupos/parametros?nivel=${nivelId}&grado=${gradoId}`);
}