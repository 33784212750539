import { useState,useEffect} from 'react';

export const GetData=(Api)=>{
    const [datos,setDatos]=useState({ load: true, data: null });
    useEffect(()=>{
        ObtenerD(Api);
    },[]);
    const ObtenerD=(Api)=>{
        const data=Api;
        data.then(valores => {
            setDatos({ load: false, data: valores.data })
            //console.log("veamos solo la data", valores.data)
        }).catch((error) => console.log(error));
    }
    
    return datos;
}