import React, {useState, useRef} from "react";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import CarouselSlide from './CarouselSlide'
import { Trans } from "react-i18next";

const CustomCarousel = ({ carouselData, instrucciones }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const orderedData = carouselData.sort((a, b)=>{
    if(a.orden > b.orden){
      return 1
    }else{
      return -1
    }
  })

  return (
    <>
    <div className="lg:mt-8"></div>
    {instrucciones && <div className="block-instructions mb-4">{instrucciones}</div>}
    {/* <div className="text-center text-nimbusLight  font-bold md:hidden mb-2">{`Diapositiva ${currentSlide + 1} de ${carouselData.length}`}</div> */}
    <div className="text-center text-nimbusLight  font-bold md:hidden mb-2">
      <Trans i18nKey="slide"></Trans>
      <div className="mx-1 inline-block">{currentSlide + 1}  </div>
      <Trans i18nKey="slideOf"></Trans>
      <div className="mx-1 inline-block">{carouselData.length}</div>
    </div>
      <Carousel
        ssr
        responsive={responsive}
        showDots={true}
        keyBoardControl={true}
        infinite={false}
        className="mb-[4rem] z-[5]"
        afterChange={(nextSlide, { currentSlide, onMove }) => {
          setCurrentSlide(currentSlide)
        }}
      >
        {orderedData.map((item, index) => (
          <>
          <CarouselSlide slideData={item} key={index} currentSlide={currentSlide} index={index}/>
          </>
        ))}
      </Carousel>
    </>
  );
};

export default CustomCarousel;
