import { getCarritoDeCompras } from "../../components/services/Api/MarketPlace/CarritoDeCompras";
import { createAction } from "../../components/utils/reducerUtils";
import { CARRITO_ACTION_TYPES } from "./carritoTypes";

export const fetchCarritoStart = (loading) => createAction(CARRITO_ACTION_TYPES.FETCH_CARRITO_START, loading);

export const fetchCarritoSuccess = (carrito) => createAction(CARRITO_ACTION_TYPES.FETCH_CARRITO_SUCCESS, carrito);

export const fetchCarritoFailed = (error) => createAction(CARRITO_ACTION_TYPES.FETCH_CARRITO_FAILED, error);

export const fetchCarritoAsync = (loading = true) => async (dispatch) => {
  dispatch(fetchCarritoStart(loading));
  try {
    const carrito = await getCarritoDeCompras();
    dispatch(fetchCarritoSuccess(carrito));
  } catch (error) {
    console.log(error);
    dispatch(fetchCarritoFailed(error));
  }
};
