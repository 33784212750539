import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CategoriasNotificacionesHome from '../../../components/Herramientas/CategoriasNotificaciones/CategoriasNotificacionesHome'
import CategoriasNotificacionesDetalles from '../../../components/Herramientas/CategoriasNotificaciones/CategoriasNotificacionesDetalles'

const CategoriasNotificacionesRoutes = () => {
  return (
    <Routes>
        <Route index element={<CategoriasNotificacionesHome />} />
        <Route path='agregarCategoria' element={<CategoriasNotificacionesDetalles />} />
        <Route path=':categoriaNotificacionId/ver' element={<CategoriasNotificacionesDetalles ver />} />
        <Route path=':categoriaNotificacionId/editar' element={<CategoriasNotificacionesDetalles editar />} />
    </Routes>
  )
}

export default CategoriasNotificacionesRoutes