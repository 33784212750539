import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubHeader from "../../../fragments/SubHeader";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../fragments/BreadCrumb";

const SubHeaderCompraDetalle = () => {
  const navigate = useNavigate();
  const params = useParams()

  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Mis compras /"} onClick={() => navigate(`/marketplace/misCompras`)} />
        <BreadCrumb route={params.compraId} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderCompraDetalle;
