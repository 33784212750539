import { useState, useEffect, useContext } from "react";
import InputBuscar from "../../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../../fragments/Inputs/InputSelect";
import { TIPOS_ESTATUS, TIPOS_ORIGEN } from "../../../../utils/productoUtils";
import { GANANCIA_CANTIDAD } from "../../../../utils/asignarProductosUtils";
import { PaqueteProductosContext } from "../../../../../contexts/PaqueteProductosContext";
import { setFiltrosPaquetes } from "../../../../../reducers/paquetesProductos/paquetesProductosAction";

const FILTRO_PRODUCTOS_INITIAL_VALUES = {
  busqueda: "",
  origen: "",
  estatus: "",
  ganancia: ""
};

export default function PaquetesProductosFilter({ paquetes, paquetesFiltro, setPaquetesFiltro }) {
  const { dispatch: dispatchPaqueteProductos, filtrosPaquetes } = useContext(PaqueteProductosContext);
  const [filtro, setFiltro] = useState(FILTRO_PRODUCTOS_INITIAL_VALUES);

  const filterDataBusqueda = (paquetes, busqueda) => {
    if (busqueda === "") return paquetes;
  
    const newPaquetesFiltros = paquetes.filter(
      (paquete) =>
        paquete.paquete.nombre.toLowerCase().includes(busqueda)||
        paquete.paquete._id.toLowerCase().includes(busqueda)||
        parseFloat(paquete.precioVenta ) === parseFloat(busqueda)
    );

    return newPaquetesFiltros;
  };

  const filterSelectOrigen = (origen) => {
    if (origen === "") return paquetes;
    const newPaquetesFiltros = paquetes.filter((paquete) => paquete.origen === origen);

    return newPaquetesFiltros;
  };

  const filterSelectEstatus = (paquetes, estatus) => {
    if (estatus === "") return paquetes;
    estatus = estatus === "true";
    const newPaquetesFiltros = paquetes.filter((paquete) => paquete.paquete.estatus === estatus);

    return newPaquetesFiltros;
  };

  const filterSelectGanancia = (paquetes, ganancia) => {
    if (ganancia === "") return paquetes;
    let newPaquetesFiltros = [];
    switch (ganancia) {
      case "Ninguna":
        newPaquetesFiltros = paquetes.filter((paquete) =>  paquete.precioVenta - paquete.costo === 0);
        break;
      case "Saldo positivo":
        newPaquetesFiltros = paquetes.filter((paquete) => paquete.precioVenta - paquete.costo > 0);
        break;
      case "Saldo negativo":
        newPaquetesFiltros = paquetes.filter((paquete) =>paquete.precioVenta - paquete.costo < 0);
        break;
      default:
        break;
    }

    return newPaquetesFiltros;
  };

  useEffect(() => {
    let newPaquetesFiltros = filterSelectOrigen(filtro.origen);
    newPaquetesFiltros = filterSelectEstatus(newPaquetesFiltros, filtro.estatus);
    newPaquetesFiltros = filterSelectGanancia(newPaquetesFiltros, filtro.ganancia);
    newPaquetesFiltros = filterDataBusqueda(newPaquetesFiltros, filtro.busqueda.toLowerCase());
    setPaquetesFiltro(newPaquetesFiltros);
    dispatchPaqueteProductos(setFiltrosPaquetes(filtro));
  }, [filtro, paquetes]);

  useEffect(() => {
    if(Object.keys(filtrosPaquetes).length > 0){
      setFiltro(filtrosPaquetes)
    } else {
      dispatchPaqueteProductos(setFiltrosPaquetes(filtro));
    }
  }, [])

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {paquetesFiltro.length}
          </p>
          <p className="text-black">Paquetes</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className="flex flex-col lg:grid lg:grid-cols-3  gap-3 w-full">
          <div className="w-full sm:flex sm:justify-end">
          <InputSelect
              label="Ganancia"
              options={GANANCIA_CANTIDAD}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-40 xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, ganancia: e.target.value })}
              value={filtro.ganancia}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Origen"
              options={TIPOS_ORIGEN}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-40 xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, origen: e.target.value })}
              value={filtro.origen}
            />
          </div>
          <div className="w-full sm:flex sm:justify-end">
            <InputSelect
              label="Estatus"
              options={TIPOS_ESTATUS}
              topLabel={true}
              styles="!w-full sm:!w-80 lg:!w-40 xl:!w-48"
              onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
              value={filtro.estatus}
            />
          </div>
        </div>
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
          <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} ocupaValue={true} value={filtro.busqueda} />
        </div>
      </div>
    </div>
  );
}
