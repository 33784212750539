import React from 'react';
import MiPaqueteEscolarRow from './MiPaqueteEscolarRow';

const MiPaqueteEscolarTable = ({alumnosList}) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th></th>
            <th>Nombre del alumno</th>
            <th>Artículos asignados</th>
            <th>Artículos adquiridos</th>
            <th>Artículos sin adquirir</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='mb-2'>
          {alumnosList.map((alumno) => (
            <MiPaqueteEscolarRow key={alumno.alumno._id} alumnoInfo={alumno} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiPaqueteEscolarTable;
