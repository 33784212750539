import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {v4 as uuid} from "uuid"
import { shuffle } from "./ActividadesUtils/ActividadesUtils";
import { useContext } from "react";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { repeatedColors } from "./ActividadesUtils/ActividadesUtils";

function EncontrarPalabraFaltante({actData, evaluable, palabrasIncorrectas, palabrasCorrectas, umaBloque, guardarCalificacion, instrucciones}) {
  const wordsArray = actData
  .filter((el)=>el.texto && el.texto !== '')
  .map((el)=>(el.texto.trim() + '/ESPACIO/'))
  .join('\n')
  .trim()
  .split(/\s+/) 

  const contenedorItems = wordsArray.map((el, index)=>({
     texto:el.replace('/ESPACIO/', ''),
     _id:uuid(),
     arrastrable:palabrasCorrectas.includes(index),
     items:[],
     orden:index,
     hasSpace:el && el.includes('/ESPACIO/')
  }))
  
  const contenedorItemsArr = [...contenedorItems].map((item,index)=>({...item,  background:repeatedColors[index]}))
  const palabrasIncorrectasArr = [...palabrasIncorrectas.map((el, index)=>({...el, background:repeatedColors[index]}))]
  const columnsFromBackend = [{arrastrable:true, texto: "Todos", items: contenedorItemsArr , _id: uuid()},...contenedorItems]
  const [columns, setColumns] = useState(columnsFromBackend);
  const [iniciado, setIniciado] = useState(false)
  const activityContext = useContext(ActividadContext)
  const currentArr = Object.keys(columns).map((key) => columns[key]);
  const respuestas = currentArr
    .filter((el)=>(el.arrastrable))
    .map((item) => {
      return item.items.map((el) => {
        return el.texto === item.texto;
      });
    })
    .flat();
  const respuestasCorrectas = respuestas.filter(Boolean).length
  const calificacion = (respuestasCorrectas / contenedorItems.filter((el)=>el.arrastrable).length) * 100

  const calificar = () =>{
    if(iniciado){
      activityContext.postCalificacionActividad(calificacion)
      if(umaBloque)guardarCalificacion(umaBloque, respuestasCorrectas, '')
    }
    else if(!iniciado)activityContext.postCalificacionActividad(null)

  }

  const handleCalificar = () => {
    if(activityContext !== undefined){
      calificar()
    }
  };

  const onDragEnd = (result, columns, setColumns) => {
    setIniciado(true)
    if (!result.destination) return;
    const { source, destination } = result;
  
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  useEffect(()=>{
    if(!evaluable){
      const respuestasCorrectas = columnsFromBackend.map((item)=>{
        const respuestas  = contenedorItemsArr.filter((el)=>(el.orden === item.orden))
        return {...item, ...{items:respuestas}}
      })
      setColumns(respuestasCorrectas)
    }else{
        const palabrasInicio = columnsFromBackend.map((item)=>{
            const respuestas  = contenedorItemsArr.filter((el)=>(el.orden === item.orden))
            const allArrastrablesArr = shuffle([...contenedorItemsArr.filter((el)=>(el.arrastrable)),...palabrasIncorrectasArr])
            if(item.texto === 'Todos'){
                return {...item,...{items:allArrastrablesArr}}
            }
            else if(!item.arrastrable){
                return {...item, ...{items:respuestas}}
            }
            else{
                return item
            }
        })
        setColumns(palabrasInicio)
    }
  },[evaluable])

  const handleLimpiar = () =>{
    if(typeof columns === 'object'){
      var objArr = Object.keys(columns).map((key) => [Number(key), columns[key]]).map((el)=>(el[1]))
      const test = objArr.map((el)=>{
        if(el.texto === "Todos"){
          return {...el, items:shuffle([...contenedorItemsArr.filter((el)=>(el.arrastrable)),...palabrasIncorrectasArr])}
        }
        else if(el.arrastrable){
          return {...el,items:[]}
        }else{
          return el
        }
      })
      setColumns(test)
    }
  }

  return (
    <>
      {instrucciones && <div className="max-w-activity act-instructions mb-6">{instrucciones}</div>}
      <div className={`max-w-activity w-full py-10 block justify-center h-full ${evaluable ? '' : 'pointer-events-none'}`}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <>
              <div className={`inline-block  p-1 ${index === 0 ? 'w-full' : '!p-0 mx-1'}`} key={columnId}>
                <Droppable
                  droppableId={columnId}
                  key={columnId}
                  direction={index === 0 ? 'horizontal' : 'vertical'}
                  isDropDisabled={!column.arrastrable || column.items.length > 0 && index !== 0}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`rounded min-h-[27px] ${index === 0 ? 'flex overflow-auto w-full min-h-10 mb-10 p-[20px]' : 'w-fit overflow-hidden mb-3'} ${column.arrastrable ? 'min-w-[4rem]' : ''}`}
                        style={{background: snapshot.isDraggingOver ? "#e6e9ee" : "#ebecf0",}}
                      >
                        {column.items.map((item, i) => {
                          return (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={i}
                              isDragDisabled={!item.arrastrable}
                            >
                              {/* Para usar despues */}
                              {/* {renderDraggable((provided, dragSnapshot) => (
                              <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{userSelect: "none",...provided.draggableProps.style}}
                                  className={`${item.arrastrable ? 'inline-block' : ''} ${column.texto === 'Todos' ? 'mx-1' : ''} ${dragSnapshot.isDragging ? '!m-0' : ''}`}
                                  >
                                  {item.texto ? 
                                    <div 
                                        className={`rounded text-xl z-30 text-center  ${item.arrastrable ? 'w-fit min-w-[4rem] opacity-50 px-2' : ''}`} 
                                        style={ item.arrastrable ? { background:item.background, color:'#fff'} : {background : '#f8fdff', color:'#6e6d7a'} }>
                                        {item.texto}
                                    </div>: ''
                                }
                              </div>
                               ))} */}
                                {(provided, dragSnapshot) => {
                                  return (
                                    <>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{userSelect: "none",...provided.draggableProps.style}}
                                        className={`${item.arrastrable ? 'inline-block' : ''} ${column.texto === 'Todos' ? 'mx-1' : ''} ${dragSnapshot.isDragging ? '!m-0' : ''}`}
                                        >
                                        {item.texto ? 
                                          <div 
                                              className={`rounded text-xl z-30 text-center  ${item.arrastrable ? 'w-fit min-w-[4rem] opacity-50 px-2' : ''}`} 
                                              style={ item.arrastrable ? { background:item.background, color:'#fff'} : {background : '#f8fdff', color:'#6e6d7a'} }>
                                              {item.texto}
                                          </div>: ''
                                      }
                                    </div>
                                    </>
                                  );
                                }}
                          </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
                
              </div>
                {column.hasSpace && <div className="block h-10 w-10"></div>}
              </>
            );
          })}
        </DragDropContext>
      {evaluable ? 
      <div className="flex justify-center align-center w-full outside-grid mx-auto transform scale-75 md:scale-100 mt-8">
        <button className="m-5 py- px-10 rounded-full inline" onClick={()=>handleLimpiar()}>Limpiar</button>
        <button onClick={handleCalificar} className='border bg-nimbus text-white m-5 py-3 px-10 rounded-full inline'>{iniciado ? 'Enviar y cerrar' : 'Cerrar'}</button>
      </div>:''}
      </div>
    </>
  );
}

EncontrarPalabraFaltante.defaultProps = {
  contenedorItems:[],
  contenedores:[],
  evaluable:true
}

export default EncontrarPalabraFaltante;
