import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TooltipIcon from "./TooltipIcon";
import ViewIcon from "../../../../../assets/icons/questionBlue.svg";
import Modal from "./Modal";
import ImagenValidada from "../ActividadesUtils/RecursosValidados/ImagenValidada";

function ClickAndLearn({ img, hotspots, instrucciones }) {
  const [modalValue, setmodalValue] = useState(null);
  const contenedorItems = hotspots.map((el, index) => ({
    ...el,
    orden: index,
    _id: uuidv4(),
    columnId: index,
  }));

  return (
    <div className="relative rounded-lg overflow-hidden mt-6 whitespace-pre-wrap">
      <Modal modalValue={modalValue} closeModal={() => setmodalValue(null)} />
      {instrucciones && (
        <div className="act-instructions px-4 mb-4">{instrucciones}</div>
      )}
      <div className="flex flex-col md:flex-row mb-6 md:mb-10 max-w-activity w-full">
        <div className="w-fit grid grid-cols-2 md:flex md:flex-col md:items-center flex-1` rounded-md text-sm md:text-base flex-0"></div>
        <div className="flex flex-1">
          <div className="relative w-full mx-auto h-fit">
            {Object.entries(contenedorItems).map(
              ([columnId, column], index) => {
                return (
                  <>
                    {column.texto && (
                      <div
                        onClick={() => setmodalValue(column)}
                        className={`bg-white inline-block p-[4px] absolute rounded-full min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px]`}
                        key={columnId}
                        style={{
                          width: `${column.actWidth}%`,
                          height: `${column.actHeight}%`,
                          top: `${column.actY}%`,
                          left: `${column.actX}%`,
                        }}
                      >
                        <div className="delayed-tooltip w-full h-7 cursor-pointer hover:scale-[1.25] font-bold text-lightBlue rounded-full flex items-center justify-center text-xl">
                          {column.orden + 1}
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            )}
            <ImagenValidada
              src={img}
              className="w-full mx-auto shadow-xl rounded-md border overflow-hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClickAndLearn;
