import React, { useState, useContext, useEffect } from "react";
import { colors } from "./ActividadesUtils/ActividadesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceSmile,
  faFaceMeh,
  faFaceAngry,
  faFaceLaughBeam,
  faFaceTired,
} from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import { ActividadContext } from "./ActividadesUtils/BlockWrapper";
import LoadingButton from "./ActividadesUtils/LoadingButton";

function Autoevaluación({ rubricas, descripcion, tipo }) {
  const [currentSelected, setCurrentSelected] = useState(rubricas);
  const activityContext = useContext(ActividadContext);

  const handleSelectRubrica = (selectedId, value) => {
    const newCurrentSelected = currentSelected.map((el) =>
      el._id === selectedId ? { ...el, value } : el
    );
    setCurrentSelected(newCurrentSelected);
  };

  const handleSave = () => {
    const dataToSave = currentSelected
      .filter((el) => el.value)
      .map((el) => ({ value: el.value, _id: el._id }));

    activityContext.postCalificacionActividad(100, JSON.stringify(dataToSave));
  };

  const cualquierCara = tipo === "TRES_CARAS" || tipo === "CINCO_CARAS";
  const cualquierNumero = tipo === "TRES_NUM" || tipo === "CINCO_NUM";

  useEffect(() => {
    const userResponses = activityContext?.userData;
    if (userResponses && userResponses !== "") {
      const parsedSelected = JSON.parse(userResponses);

      const selectedList = rubricas.map((el) => {
        const savedItems = parsedSelected.find((ans) => ans._id === el._id);
        if (savedItems && savedItems.value)
          return { ...el, value: savedItems.value };
        else return el;
      });
      setCurrentSelected(selectedList);
    }
  }, []);

  const isLoading = activityContext?.loading;

  const isAnswered = currentSelected.some((el) => el.value);

  return (
    <div
      /*       className={`my-7 mx-auto w-full ${
        tipo === "CINCO_CARAS" ? "max-w-[968px]" : "max-w-[768px]"
      }`} */
      className={`my-7 mx-auto w-full max-w-activity`}
    >
      <div className="text-2xl text-center bg-[#1a3d81] text-white rounded-xl py-4 font-bold">
        <Trans i18nKey={"autoevaluation"}></Trans>
      </div>
      <div className="text-left my-10 text-nimbusLight font-bold text-lg">
        {descripcion
          ? descripcion
          : "Lee los siguientes enunciados y selecciona la opción con la que te identifiques más"}
      </div>
      <div className="overflow-auto">
        <table className="w-full">
          <tr
            className="px-4 rounded-xl mb-2 flex text-nimbus font-bold text-lg"
            //style={{ backgroundColor: colors[0] + "b3" }}
          >
            <td className="flex-1">
              <Trans i18nKey={"autoevaluationAspects"}></Trans>
            </td>
            {/* Etiquetas por números */}
            {cualquierNumero && (
              <>
                <td className="text-center w-[62px]">1</td>
                <td className="text-center w-[62px]">2</td>
                <td className="text-center w-[62px]">3</td>
              </>
            )}
            {tipo === "CINCO_NUM" && (
              <>
                <td className="text-center w-[62px]">4</td>
                <td className="text-center w-[62px]">5</td>
              </>
            )}
            {/* Etiquetas por caras */}
            {tipo === "CINCO_CARAS" && (
              <td className="text-center w-[100px] text-sm">
                <Trans i18nKey={"veryUnsatisfied"}></Trans>
              </td>
            )}
            {cualquierCara && (
              <>
                <td className="text-center w-[100px] text-sm">
                  <Trans i18nKey={"unsatisfied"}></Trans>
                </td>
                <td className="text-center w-[100px] text-sm">
                  <Trans i18nKey={"neutral"}></Trans>
                </td>
                <td className="text-center w-[100px] text-sm">
                  <Trans i18nKey={"satisfied"}></Trans>
                </td>
              </>
            )}
            {tipo === "CINCO_CARAS" && (
              <td className="text-center w-[100px] text-sm">
                <Trans i18nKey={"verySatisfied"}></Trans>
              </td>
            )}
            {/* Fin etiquetas */}
          </tr>
          {currentSelected.map((el, i) => (
            <tr
              className="px-4 py-6 rounded-xl mb-2 flex"
              style={{ backgroundColor: colors[i + 1] + "b3" }}
            >
              <td className="text-white flex-1 mr-2">{el.texto}</td>
              {/* Autoevaluacion numerica */}

              {cualquierNumero && (
                <>
                  <td className="text-white px-4 flex items-center">
                    <input
                      type="checkbox"
                      style={{ border: "3px solid white", padding: 12 }}
                      className="rounded-full"
                      checked={el.value === 1}
                      onChange={() => handleSelectRubrica(el._id, 1)}
                    />
                  </td>
                  <td className="text-white px-4 flex items-center">
                    <input
                      type="checkbox"
                      style={{ border: "3px solid white", padding: 12 }}
                      className="rounded-full "
                      checked={el.value === 2}
                      onChange={() => handleSelectRubrica(el._id, 2)}
                    />
                  </td>
                  <td className="text-white px-4 flex items-center">
                    <input
                      type="checkbox"
                      style={{ border: "3px solid white", padding: 12 }}
                      className="rounded-full "
                      checked={el.value === 3}
                      onChange={() => handleSelectRubrica(el._id, 3)}
                    />
                  </td>
                </>
              )}
              {tipo === "CINCO_NUM" && (
                <>
                  <td className="text-white px-4 flex items-center">
                    <input
                      type="checkbox"
                      style={{ border: "3px solid white", padding: 12 }}
                      className="rounded-full "
                      checked={el.value === 4}
                      onChange={() => handleSelectRubrica(el._id, 4)}
                    />
                  </td>
                  <td className="text-white px-4 flex items-center">
                    <input
                      type="checkbox"
                      style={{ border: "3px solid white", padding: 12 }}
                      className="rounded-full "
                      checked={el.value === 5}
                      onChange={() => handleSelectRubrica(el._id, 5)}
                    />
                  </td>
                </>
              )}
              {/* Autoevaluacion por caras */}
              {tipo === "CINCO_CARAS" && (
                <td className="flex items-center justify center min-w-[100px] justify-center">
                  <FontAwesomeIcon
                    icon={faFaceTired}
                    color={el.value == "MUY_INSATISFECHO" ? "#1a3d81" : "#fff"}
                    className={`text-5xl custom-transition hover:scale-110 rounded-full   ${
                      el.value == "MUY_INSATISFECHO" && "bg-white"
                    }`}
                    onClick={() =>
                      handleSelectRubrica(el._id, "MUY_INSATISFECHO")
                    }
                  />
                </td>
              )}
              {cualquierCara && (
                <>
                  <td className="flex items-center justify center min-w-[100px] justify-center">
                    <FontAwesomeIcon
                      icon={faFaceAngry}
                      color={el.value == "INSATISFECHO" ? "#1a3d81" : "#fff"}
                      className={`text-5xl custom-transition hover:scale-110 rounded-full   ${
                        el.value == "INSATISFECHO" && "bg-white"
                      }`}
                      onClick={() =>
                        handleSelectRubrica(el._id, "INSATISFECHO")
                      }
                    />
                  </td>

                  <td className="flex items-center justify center min-w-[100px] justify-center">
                    <FontAwesomeIcon
                      icon={faFaceMeh}
                      color={el.value === "NEUTRAL" ? "#1a3d81" : "#fff"}
                      className={`text-5xl custom-transition hover:scale-110 rounded-full   ${
                        el.value == "NEUTRAL" && "bg-white"
                      }`}
                      onClick={() => handleSelectRubrica(el._id, "NEUTRAL")}
                    />
                  </td>
                  <td className="flex items-center justify center min-w-[100px] justify-center">
                    <FontAwesomeIcon
                      icon={faFaceSmile}
                      color={el.value === "SATISFECHO" ? "#1a3d81" : "#fff"}
                      className={`text-5xl custom-transition hover:scale-110 rounded-full   ${
                        el.value == "SATISFECHO" && "bg-white"
                      }`}
                      onClick={() => handleSelectRubrica(el._id, "SATISFECHO")}
                    />
                  </td>
                </>
              )}
              {tipo === "CINCO_CARAS" && (
                <td className="flex items-center justify center min-w-[100px] justify-center">
                  <FontAwesomeIcon
                    icon={faFaceLaughBeam}
                    color={el.value === "MUY_SATISFECHO" ? "#1a3d81" : "#fff"}
                    className={`text-5xl custom-transition hover:scale-110 rounded-full   ${
                      el.value == "MUY_SATISFECHO" && "bg-white"
                    }`}
                    onClick={() =>
                      handleSelectRubrica(el._id, "MUY_SATISFECHO")
                    }
                  />
                </td>
              )}

              {/* Fin */}
            </tr>
          ))}
        </table>
      </div>
      <div className={`custom-transition ${isAnswered ? "" : "opacity-50"}`}>
        <LoadingButton
          isLoading={isLoading}
          onClick={isAnswered ? handleSave : undefined}
          aditionalStyles={"!bg-[#1a3d81]"}
        />
      </div>
    </div>
  );
}

export default Autoevaluación;
