import React, { useEffect, useState } from "react";
import HtmlObject from "../fragments/HtmlObject";
import { fileExists } from "./ActividadesUtils/ActividadesUtils";
import { Trans } from "react-i18next";

function HTMLplayer({ interactivo, instrucciones }) {
  const [availableFile, setAvailableFile] = useState(true);
  //let path_origin = window.location.origin;
  let path_origin = "https://storage.googleapis.com/nimbus-interactivos/";

  useEffect(() => {
    setAvailableFile(fileExists());
    //drawData();
  }, []);
  let html_slpit = interactivo.split("/");
  let id_interactivo = html_slpit[0];
  let id_html = "html_" + id_interactivo;
  let flash_vars = "";
  flash_vars = "enlace=" + encodeURI("/" + "#!titulo/") + "&";
  flash_vars += "resolucionX=" + 700 + "&";
  flash_vars += "resolucionY=" + 400 + "&";
  flash_vars += "rutaServerAdmin=" + "";

  const isHTML = interactivo.toLowerCase().split(".").pop() === "html";
  return (
    <div className="relative w-full">
      {instrucciones && (
        <div className="act-instructions mb-6">{instrucciones}</div>
      )}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0 text-nimbusLight font-bold text-lg max-w-[500px]">
        {" "}
        interactivo {interactivo}
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0 text-nimbusLight font-bold text-lg max-w-[500px] mt-10">
        {" "}
        {!availableFile && (
          <div className="text-center font-bold text-nimbusLight !text-base p-2">
            <Trans i18nKey={"resourceNotFound"}></Trans>
          </div>
        )}
      </div>
      {isHTML == true ? (
        <iframe
          className="relative w-full mx-auto aspect-video my-[50px] z-10"
          //src={path_origin + "/interactivos/" + interactivo}
          src={path_origin + interactivo}
          title="activity-frame"
          id={id_html}
        />
      ) : (
        <HtmlObject
          dataRoute={interactivo}
          path_origin={path_origin}
          id_interactivo={id_interactivo}
        ></HtmlObject>
      )}
    </div>
  );
}

export default HTMLplayer;
