export default function InputSelect({value,onBlur,onChange,name,id,disabled = false,label,error='',options=[], topLabel = false}) {

    return(
        <div className="grid gap-y-2">
            {!topLabel && <p className="font-semibold text-base text-[#0D0C22] truncate">{label}</p>}
            <div className="relative">
                <select
                    className={`peer border-2 h-[40px] focus:outline-none rounded-lg p-2 w-50 lg:w-full ${error !== '' ? 'border-red-300' : 'border-gray-300'}`}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    name={name}
                    id={id}
                    disabled={disabled}
                >   
                    {topLabel? <option value="">Todos</option> : <option value="">Seleccione {label?.toLowerCase()}</option>}
                    {options.map(option=>{
                        return <option key={option._id} value={option._id}>{option.nombre}</option>
                    })}
                </select>
                {topLabel && <label className={`absolute left-6 -top-1.5 text-blue-400 text-xs z-100 bg-white transition-all peer-focus:absolute peer-focus:left-6 peer-focus:-top-1.5 peer-focus:text-blue-400 peer-focus:text-xs`} htmlFor={name}>{label}</label> }
                <p className="text-xs text-red-300">{error}</p>
            </div>
        </div>
    )
}