import React, { Fragment, useContext, useEffect, useState } from 'react';
import ResumenPedidoTable from './ResumenPedidoTable';
import CancelButton from '../../../fragments/Buttons/CancelButton';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../../fragments/Buttons/SubmitButton';
import ConfirmarVentaModal from '../ConfirmarVentaModal';
import { UserContext } from '../../../services/user';
import {
  getPedidosPorCompra,
  putCompletarCompra,
  putCompletarPedido,
  putCompraEntregada,
  putReabrirCompra,
} from '../../../services/Api/MarketPlace/MisPedidos';
import { toast } from 'react-toastify';
import alertConfiguration from '../../../utils/alertsUtils';

const ResumenPedido = ({ data, getData }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [isOpenConfirmar, setIsOpenConfirmar] = useState(false);
  const [disabledPedidoBtn, setDisabledPedidoBtn] = useState(false);
  const [pedidoInfo, setPedidoInfo] = useState([]);
  const handleSubmit = async () => {
    try {
      if (
        pedidoInfo.estatus === 'PREPARANDO' &&
        (user.activeRole === 'DIR_PLANTEL' ||
          user.activeRole === 'ADMIN_OPERATIVO')
      ) {
        await Promise.all(
          data.pedidoscompra.map(async (pedido) => {
            try {
              if (pedido.estatus !== 'PREPARANDO') return;
              if (
                pedido.articulos[0]?.producto?.plantel !== undefined ||
                pedido.articulos[0]?.paquete?.plantel !== undefined ||
                pedido.producto?.plantel !== undefined
              ) {
                return await putCompletarPedido(pedido._id);
              }
            } catch (error) {
              console.log(error);
              return error;
            }
          })
        );
        await putCompletarCompra(pedidoInfo._id);
      } else if (pedidoInfo.estatus === 'PREPARANDO') {
        await putCompletarPedido(pedidoInfo._id);
      } else if (pedidoInfo.estatus === 'COMPLETADO') {
        await putCompraEntregada(pedidoInfo._id);
      } else if (pedidoInfo.estatus === 'ENTREGADO') {
        await putReabrirCompra(pedidoInfo._id);
      }
      toast.success('Cambios guardados correctamente.', alertConfiguration);
      await getData();
      navigate(-1);
    } catch (error) {
      if (
        error.response.data.status === 500 &&
        pedidoInfo.estatus === 'PREPARANDO'
      ) {
        toast.error(
          'No puede marcar el pedido como completado porque no han sido despachados todos los artículos.',
          alertConfiguration
        );
      }
    }
    setIsOpenConfirmar(false);
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setPedidoInfo(data);
      if (
        user.activeRole === 'DIR_PLANTEL' ||
        user.activeRole === 'ADMIN_OPERATIVO'
      ) {
        setDisabledPedidoBtn(
          data.pedidoscompra.some(
            (pedido) =>
              pedido?.estatus === 'PREPARANDO' &&
              pedido?.articulos[0]?.paquete?.plantel === undefined &&
              pedido?.articulos[0]?.producto?.plantel === undefined
          )
        );
      } else {
        setDisabledPedidoBtn(
          Object.values(data.articulos).some(
            (articulo) => articulo?.producto?.origen !== 'Nuevo'
          )
        );
      }
    }
  }, [data]);
  return (
    <Fragment>
      <div className='w-full bg-[#F5F5F5]'>
        <h1 className='text-center text-[#9C9C9C] font-extrabold p-4 text-lg'>
          Resumen del pedido
        </h1>
      </div>
      <div className='bg-[#fff] p-5 flex flex-col gap-4 h-[22rem]'>
        <ResumenPedidoTable
          articulosList={
            user.activeRole === 'DIR_PLANTEL' ||
            user.activeRole === 'ADMIN_OPERATIVO'
              ? pedidoInfo.pedidoscompra
              : pedidoInfo.articulos
          }
        />
      </div>
      <div className='flex justify-center gap-5 flex-wrap mt-5'>
        <CancelButton
          text='Cancelar'
          bgColor='bg-divider'
          onClick={() => navigate(-1)}
          styles='w-[11rem]'
        />

        {pedidoInfo.estatus === 'PREPARANDO' ? (
          user.activeRole === 'DIR_PLANTEL' ||
          user.activeRole === 'ADMIN_OPERATIVO' ? (
            <SubmitButton
              text={'Marcar pedido como completado'}
              styles={`w-[11rem] p-2 ${disabledPedidoBtn && 'bg-opacity-50'}`}
              onClick={() => setIsOpenConfirmar(true)}
              disabled={disabledPedidoBtn}
            />
          ) : (
            <SubmitButton
              text={'Marcar pedido como completado'}
              styles='w-[11rem] p-2'
              onClick={() => setIsOpenConfirmar(true)}
            />
          )
        ) : (
          (pedidoInfo.estatus === 'COMPLETADO' ||
            pedidoInfo.estatus === 'ENTREGADO') &&
          (user.activeRole === 'DIR_PLANTEL' ||
            user.activeRole === 'ADMIN_OPERATIVO') && (
            <SubmitButton
              text={`${
                pedidoInfo.estatus === 'COMPLETADO'
                  ? 'Marcar pedido como entregado'
                  : 'Reabrir pedido'
              }`}
              styles='w-[11rem] p-2'
              onClick={() => setIsOpenConfirmar(true)}
            />
          )
        )}
      </div>
      <ConfirmarVentaModal
        isOpenConfirmar={isOpenConfirmar}
        setIsOpenConfirmar={setIsOpenConfirmar}
        handleSubmit={handleSubmit}
        estatus={pedidoInfo.estatus}
      />
    </Fragment>
  );
};

export default ResumenPedido;
