import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ActividadContext } from "../ActividadesUtils/ActWrapper";
import CerrarActButton from "../../ActividadesUtils/CerrarActButton";
import Lightbox from "../ActividadesUtils/Lightbox/Lightbox";
import { Trans } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { fileExists, shuffle } from "../ActividadesUtils/ActividadesUtils";

const Puzzle = ({
  data,
  evaluable,
  umaBloque,
  guardarCalificacion,
  instrucciones,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [lightboxesUsed, setLightboxesUsed] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [lightboxValue, setlightboxValue] = useState("");
  const [started, setStarted] = useState(false);
  const [availableFile, setAvailableFile] = useState(true);
  const activityContext = useContext(ActividadContext);
  const sidePieces = data[0].piezas ? parseFloat(data[0].piezas) : 3;
  const image = data[0].imagen;
  const piecesArray = Array.from(Array(sidePieces * sidePieces).keys());

  const pieces = shuffle(piecesArray).map((el, index) => {
    return {
      id: `${el}`,
      content: `${el}`,
      orderId: el,
    };
  });

  const draggableItems = piecesArray.map((el, index) => {
    return {
      name: `${el}`,
      items: [pieces[index]],
      type: "DRAGGABLE",
    };
  });

  const droppableItems = piecesArray.map((el, index) => {
    return {
      name: `${el}`,
      items: [],
      type: "DROPPABLE",
      orderId: index,
    };
  });

  const [columns, setColumns] = useState([
    ...draggableItems,
    ...droppableItems,
  ]);

  const onDragEnd = (result, columns, setColumns) => {
    setStarted(true);
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const finishActivity = () => {
    const totalPices = sidePieces * sidePieces;
    const correctPieces = Object.entries(columns).filter(
      ([columnId, column]) => {
        return (
          column.items.length > 0 &&
          column.type === "DROPPABLE" &&
          column.items[0].orderId === column.orderId
        );
      }
    );
    const score =
      (correctPieces.length / totalPices) * 100 - lightboxesUsed * 10;
    const scoreWithErrors = score >= 0 ? score : 0;
    activityContext.postCalificacionActividad(scoreWithErrors);
    if (umaBloque) {
      const correctPiecesSummary = JSON.stringify(
        correctPieces.map(([columnId, column]) => column.orderId)
      );
      guardarCalificacion(
        umaBloque,
        correctPieces.length,
        correctPiecesSummary
      );
    }
  };

  const handleCerrarAct = () => {
    activityContext.postCalificacionActividad(null);
  };
  {
    /* Deshabilitado lightbox por el momento */
  }
  /* const showLightbox = (image) => {
    setlightboxValue(image);
    setLightboxesUsed(lightboxesUsed + 1);
  }; */

  const getAspectRatio = () => {
    const getMeta = (url, cb) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };

    // Use like:
    getMeta(image, (err, img) => {
      const imageRatio = img.naturalHeight / img.naturalWidth;
      if (typeof aspectRatio === "number") setAspectRatio(imageRatio);
    });
  };

  useEffect(() => {
    getAspectRatio();
    setAvailableFile(fileExists(image));
  }, []);

  const pieceWidth =
    window.innerWidth < 1200 ? 300 / sidePieces : 500 / sidePieces;

  const RenderGrid = ({ datatype }) => {
    return (
      <div
        className={`grid rounded-xl overflow-hidden  w-fit mb-4 lg:mb-0 ${
          datatype === "DROPPABLE" && "border border-[#ededed] bg-white"
        }`}
        style={{
          gridTemplateColumns: `repeat(${sidePieces}, 1fr)`,
        }}
      >
        {Object.entries(columns)
          .filter(([columnID, column], el) => column.type === datatype)
          .map(([columnId, column], index) => {
            return (
              <div className="flex flex-col items-center" key={columnId}>
                <div
                  className={`rounded-md ${
                    datatype === "DRAGGABLE" &&
                    "border border-[#ededed] rounded-lg overflow-hidden m-1"
                  }`}
                >
                  <Droppable
                    droppableId={columnId}
                    key={columnId}
                    direction="horizontal"
                    isDropDisabled={column.items.length > 0}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="w-full h-full overflow-hidden"
                          style={{
                            background: snapshot.isDraggingOver
                              ? "#f5f5f5"
                              : "transparent",
                            minWidth: pieceWidth,
                            minHeight: pieceWidth * aspectRatio,
                            maxWidth: pieceWidth,
                            maxHeight: pieceWidth * aspectRatio,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                                isDragDisabled={isCompleted}
                              >
                                {(provided, snapshot) => {
                                  const piecePercentage =
                                    100 / (sidePieces - 1);
                                  const positionX = item.orderId % sidePieces;
                                  const positionY = Math.floor(
                                    item.orderId / sidePieces
                                  );
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="w-full h-full p-4 mb-2 min-h-[50px] text-white relative"
                                      style={{
                                        ...provided.draggableProps.style,
                                        userSelect: "none",
                                        backgroundColor: snapshot.isDragging
                                          ? "#263B4A"
                                          : "#456C86",
                                        backgroundImage: `url(${image})`,
                                        /* probar con imagenes muy estrechas */
                                        backgroundSize: `${100 * sidePieces}% ${
                                          100 * sidePieces
                                        }%`,
                                        backgroundPosition: `${
                                          positionX * piecePercentage
                                        }% ${positionY * piecePercentage}%`,
                                      }}
                                    ></div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  useEffect(() => {
    const isSolved = Object.entries(columns).filter(
      ([columnId, column], el) =>
        column.type === "DROPPABLE" &&
        column.items.length > 0 &&
        column.orderId === column.items[0].orderId
    );
    if (isSolved.length === sidePieces * sidePieces) setIsCompleted(true);
  }, [columns]);

  return (
    <>
      {instrucciones && (
        <div className="act-instructions mt-4">{instrucciones}</div>
      )}
      {!availableFile && (
        <div className="text-center font-bold text-nimbusLight !text-base p-2 mt-10">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
      <Lightbox
        lightboxValue={lightboxValue}
        closeLightbox={() => setlightboxValue("")}
      />
      <div className="w-full max-w-activity my-10">
        {data.map((item) => (
          <>
            {/* Deshabilitado lightbox por el momento */}
            {/* {item.piezas > 3 && evaluable && (
              <div
                onClick={() => showLightbox(item.imagen)}
                className="cursor-pointer flex justify-end text-nimbusLight hover:underline font-bold"
              >
                <TooltipLabel
                  title={"Ver imagen"}
                  tooltip={
                    "Nota: La visualización de la imagen afecta tu calificación final"
                  }
                  styles={
                    "flex flex-row-reverse mb-2 justify-center items-center"
                  }
                />
              </div>
            )} */}
            <div
              className={`flex flex-col lg:flex-row items-center ${
                isCompleted ? "justify-center" : "justify-between"
              } `}
            >
              {evaluable ? (
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {!isCompleted && <RenderGrid datatype={"DRAGGABLE"} />}
                  <RenderGrid datatype={"DROPPABLE"} />
                </DragDropContext>
              ) : (
                <img
                  src={image}
                  width={sidePieces * pieceWidth}
                  className="mx-auto border border-[#ededed] rounded-lg"
                />
              )}
            </div>
            {/* Deshabilitado lightbox por el momento */}
            {/* {true && (
              <div className="mx-auto w-fit text-lg text-nimbusLight mt-10 font-bold">
                <Trans>Obtuviste</Trans> {sidePieces * sidePieces}{" "}
                <Trans>aciertos de</Trans>{" "}
                {item.piezas ? item.piezas * item.piezas : 9}{" "}
                <Trans>totales</Trans>
              </div>
            )} */}
          </>
        ))}
      </div>
      <CerrarActButton
        onClick={started ? finishActivity : handleCerrarAct}
        text={started ? "Calificar" : "Cerrar"}
      />
    </>
  );
};

Puzzle.defaultProps = {
  evaluable: true,
  data: [],
};

export default Puzzle;
