import React, { useCallback, useContext, useEffect, useState } from "react";
import NimbusModal from "../../fragments/NimbusModal";
import TablePagination from "../../fragments/TablePagination";
import CancelButton from "../../fragments/Buttons/CancelButton";
import NoDataMessage from "../../fragments/NoDataMessage";
import { MESSAGES } from "../../utils/messagesUtils";
import AddIcon from "../../../assets/icons/addLg.svg";
import { UserContext } from "../../services/user";
import { obtenerUserEstructuraInfo } from "../../utils/userUtils";
import { getProductosEstructura } from "../../services/Api/MarketPlace/Productos";
import { usePagination } from "../../hooks/usePagination";
import ArticulosDisponiblesFilter from "./fragments/ArticulosDisponiblesFilter";
import ArticulosDisponiblesTable from "./fragments/ArticulosDisponiblesTable";
import { AsignarArticulosContext } from "../../../contexts/AsignarProductosContext";
import {
  agregarTodosArticulos,
  esAgregarArticulosModalAbierto,
  habilitarBotonEstatus,
} from "../../../reducers/asginarArticulos/asignarArticulosAction";
import { getPaquetesMarketplaceEstructura } from "../../services/Api/MarketPlace/Paquetes";
import { toast } from "react-toastify";
import alertConfiguration from "../../utils/alertsUtils";

const AgregarArticulosModal = ({ isOpen, setIsOpen }) => {
  const { user, activeRole } = useContext(UserContext);
  const [articulos, setArticulos] = useState([]);
  const [articulosFiltro, setArticulosFiltro] = useState([]);
  const { articulos: articulosLista, dispatch } = useContext(AsignarArticulosContext);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(articulosFiltro, 15);

  const obtenerArticulos = useCallback(async () => {
    if (activeRole !== "") {
      try {
        let { tipoEstructuraRequest, estructuraId } = obtenerUserEstructuraInfo(user, activeRole);
        let productosResponse = await getProductosEstructura(tipoEstructuraRequest, estructuraId);
        let paquetesResponse = await getPaquetesMarketplaceEstructura(tipoEstructuraRequest, estructuraId);
        const articulosIdEnLaLista = articulosLista.map((articulo) => articulo.articuloListaId);
        productosResponse = productosResponse.filter((articulo) => !articulosIdEnLaLista.includes(articulo._id));
        paquetesResponse = paquetesResponse.filter((articulo) => !articulosIdEnLaLista.includes(articulo._id));
        let articulosResponse = [...productosResponse, ...paquetesResponse]
        articulosResponse.sort((articuloA, articuloB) => articuloA.fechaCreacion.localeCompare(articuloB.fechaCreacion))
        setArticulos(articulosResponse);
        setArticulosFiltro(articulosResponse);
      } catch (error) {
        console.log(error);
      }
    }
  }, [isOpen, activeRole]);

  const seleccionarArticulo = (articuloASeleccionar) => {
    let nuevosArticulos = articulos.map((articulo) => {
      if (articulo._id === articuloASeleccionar._id) {
        articulo.esSeleccionado = !articulo.esSeleccionado;
      }
      return articulo;
    });

    setArticulos(nuevosArticulos);
  };

  const seleccionarTodosLosArticulos = (checked) => {
    const articulosSeleccionadosIds = currentData().map((articulo) => articulo._id);

    let nuevosArticulos = articulos.map((articulo) =>
      articulosSeleccionadosIds.includes(articulo._id) ? { ...articulo, esSeleccionado: checked } : articulo
    );

    setArticulos(nuevosArticulos);
  };

  const handleAgregarTodosLosArticulos = () => {
    dispatch(agregarTodosArticulos(articulosLista, articulos));
    dispatch(esAgregarArticulosModalAbierto(false));
    dispatch(habilitarBotonEstatus(true))
    let resetArticulos = articulos.map((articulo) => ({ ...articulo, esSeleccionado: false }));
    setArticulos(resetArticulos);
    toast.success("Artículos agregados correctamente", alertConfiguration)
  };

  useEffect(() => {
    obtenerArticulos();
  }, [obtenerArticulos]);

  return (
    <NimbusModal isOpen={isOpen}>
      <div className="h-[80vh] md:h-[75vh] min-w-[24rem] sm:min-w-[32rem] max-w-[90vw]  xl:max-w-[94vw]  2xl:max-w-[90vw] overflow-y-auto pb-1">
        <h1 className="text-nimbusDark font-bold">Asignación de artículos</h1>
        <p className="px-4 sm:px-2 mt-4 w-fit lg:w-[54rem] 2xl:w-[64rem] mx-auto">
          Utilice los filtros para buscar los artículos que desee asignar a la estructura. Posteriomente, podrá
          establecer el precio que necesite sobre los productos asignados para calcular las ganancias que se esperan
          obtener.
        </p>
        <div
          className={`${
            articulos.length !== 0 && "justify-between"
          } relative flex-1 flex flex-col px-4 text-center h-[calc(100%-6.25rem)]`}
        >
          <div>
            <ArticulosDisponiblesFilter
              articulos={articulos}
              articulosFiltro={articulosFiltro}
              setArticulosFiltro={setArticulosFiltro}
              seleccionarTodosLosArticulos={seleccionarTodosLosArticulos}
              handleAgregarTodosLosArticulos={handleAgregarTodosLosArticulos}
            />
            <ArticulosDisponiblesTable articulos={currentData()} seleccionarArticulo={seleccionarArticulo} />
            
            {articulos.length !== 0 && articulosFiltro.length === 0 && (
              <NoDataMessage
                message={MESSAGES.MESSAGES_FILTER_ARTICULOS}
                stylesWrapper="lg:my-5 xl:mt-12 xl:mb-6"
              />
            )}
          </div>
          <div className={`${articulos.length === 0 && "h-full flex flex-col justify-between"}`}>
            {articulos.length === 0 ? (
              <NoDataMessage
                message={MESSAGES.MESSAGE_FILTER__PRODUCTOS_PAQUETE_TABLE}
                stylesWrapper="lg:my-5 xl:mt-12 xl:mb-6"
              />
            ) : (
              <TablePagination
                next={next}
                prev={prev}
                currentPage={currentPage}
                data={currentData()}
                jump={jump}
                maxPage={maxPage}
              />
            )}
            <div className="flex justify-center gap-x-4 mt-8">
              <CancelButton text="Cerrar" bgColor="bg-divider" onClick={() => setIsOpen(false)} styles="!w-48" />
            </div>
          </div>
        </div>
      </div>
    </NimbusModal>
  );
};

export default AgregarArticulosModal;
