import React, { useCallback, useContext, useEffect, useState } from "react";
import { getNivelAcademico, getNivelesAcademico } from "../../../../services/api";
import InputFileCard from "../../../../fragments/Inputs/InputFileCard";
import InputText from "../../../../fragments/Inputs/InputText";
import InputSelect from "../../../../fragments/Inputs/InputSelect";
import { OPCIONES_SI_NO, TIPOS_ESTATUS, formatCurrency } from "../../../../utils/productoUtils";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info.svg";
import RichContentInput from "../../../../fragments/Inputs/RichContentInput";
import PaqueteImage from "../../../../../assets/img/paquetesMarketplace.png";
import { PaqueteProductosContext } from "../../../../../contexts/PaqueteProductosContext";
import InputMoneda from "../../../../fragments/Inputs/InputMoneda";
import { useParams } from "react-router-dom";
import { convertirMoneda } from "../../../../utils/conversionMonedasUtils";

const PaqueteInfoForm = ({
  paquete,
  errors,
  handleChange,
  setPaquete,
  editar,
  ver,
  deleteImage,
  tipoDeMoneda,
  precioVentaSugerido,
  disponibilidad,
  conversionMonedas,
}) => {
  let {
    _id,
    imagen,
    carrusel,
    nombrePaquete,
    precioVenta,
    nivelAcademico,
    gradoAcademico,
    notificar,
    correoNotificacion,
    esDestacado,
    descripcion,
  } = paquete;
  const [nivelesAcademicos, setNivelesAcademicos] = useState([]);
  const [gradosAcademicos, setGradosAcademicos] = useState([]);
  const params = useParams()
  const { productos } = useContext(PaqueteProductosContext);

  const getData = useCallback(async () => {
    const nivelesAcademicosResponse = await getNivelesAcademico();
    setNivelesAcademicos(nivelesAcademicosResponse.data);
  }, []);

  useEffect(() => {
    if (nivelAcademico !== "") {
      getNivelAcademico(nivelAcademico).then((valores) => {
        setGradosAcademicos(valores.data.gradosAcademicos);
      });
      setPaquete({ ...paquete });
    }
  }, [nivelAcademico]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="relative z-0 overflow-x-hidden">
      <div className="flex items-center mb-12">
        <h2 className="text-black text-lg basis-72 sm:basis-56 mr-2">Datos del paquete</h2>
        <span className="w-full h-[2px] bg-divider"></span>
      </div>
      <div className="mx-0 lg:mx-8 flex flex-col items-center lg:items-start lg:flex-row">
        <div className="mb-6 lg:mr-16 flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-col gap-6 w-full lg:w-fit">
          <InputFileCard
            name={"imagen"}
            id={"imagen"}
            styles="mt-2 mb-10 col-span-3 mx-auto lg:mx-0"
            imageStyles="rounded-none border-none !w-auto"
            img={imagen ? imagen : (carrusel?.length > 0 ? carrusel[0].imagen : '')}
            bgImage={PaqueteImage}
            deleteImage={deleteImage}
            onChange={(e) => handleChange(e, "imagen")}
            disabled={true}
            allowMultipleExtensions
            accept=".jpg, .png"
          />
          <div className="w-full lg:w-64">
            <InputText
              label={"ID del paquete"}
              disabled
              styles={`!w-full`}
              disabledStyles={!ver}
              value={_id ?? ""}
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-col xl:grid xl:grid-cols-2 md:items-start lg:items-stretch xl:items-start gap-3 sm:gap-6 flex-1 w-full lg:w-fit">
          <div className="col-span-2">
            <InputText
              name={"nombrePaquete"}
              label={"Nombre del paquete"}
              placeholder={"Ingrese el nombre del paquete"}
              styles="!w-full"
              value={nombrePaquete}
              onChange={(e) => handleChange(e, "nombrePaquete")}
              error={errors["nombrePaquete"]}
              disabled={ver}
            />
          </div>
          <InputSelect
            name={"estatus"}
            label={"Estatus"}
            placeholder={"el estatus"}
            styles={`!w-full`}
            value={disponibilidad}
            onChange={(e) => handleChange(e, "estatus")}
            error={errors["estatus"]}
            options={TIPOS_ESTATUS}
            disableFirstOption
            disabled
            disabledStyles={!ver}
          />
          <div className="relative">
            <InputSelect
              name={"esDestacado"}
              label={"¿Es artículo destacado?"}
              placeholder={"si es artículo destacado"}
              styles="!w-full"
              value={esDestacado}
              onChange={(e) => handleChange(e, "esDestacado")}
              error={errors["esDestacado"]}
              options={OPCIONES_SI_NO}
              disableFirstOption
              disabled={ver}
            />
            <InfoIcon
              className="absolute top-1 left-[12.5rem] cursor-pointer"
              title="Al seleccionar la opción de SÍ, este paquete se mostrará en la página principal de la tienda de los padres de familia del plantel, la cuál corresponde a los artículos destacados."
            />
          </div>
          <InputText
            name={"precioVentaSugerido"}
            label={"Precio de venta sugerido"}
            extraInfoLines={tipoDeMoneda}
            placeholder={""}
            styles={`!w-full`}
            wrapperStyles={`${tipoDeMoneda.length > 1 && "-translate-y-2"}`}
            value={formatCurrency(precioVentaSugerido)}
            onChange={(e) => handleChange(e, "precioVenta")}
            disabled
            disabledStyles={!ver}
          />
          <InputMoneda
            name={"precioVenta"}
            label={"Precio de venta"}
            extraInfoLines={tipoDeMoneda}
            placeholder={"Ingrese el precio de venta"}
            styles="!w-full"
            wrapperStyles={`${tipoDeMoneda.length > 1 && "-translate-y-2"}`}
            value={convertirMoneda(precioVenta, conversionMonedas) ?? ""}
            onChange={(e) => handleChange(e, "precioVenta")}
            error={errors["precioVenta"]}
            disabled={ver}
          />
          <div className="col-span-2 flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-col xl:grid xl:grid-cols-2 gap-3 sm:gap-6">
            <InputSelect
              name={"nivelAcademico"}
              label={"Nivel educativo"}
              placeholder={"el nivel educativo"}
              styles="!w-full"
              value={nivelAcademico}
              onChange={(e) => handleChange(e, "nivelAcademico")}
              error={errors["nivelAcademico"]}
              options={nivelesAcademicos}
              disableFirstOption
              disabled={ver}
            />
            <InputSelect
              name={"gradoAcademico"}
              label={"Grado académico"}
              placeholder={"el grado académico"}
              styles="!w-full"
              value={gradoAcademico}
              onChange={(e) => handleChange(e, "gradoAcademico")}
              error={errors["gradoAcademico"]}
              options={gradosAcademicos}
              disableFirstOption
              disabled={ver}
            />
          </div>
          <div className="col-span-2 flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-col xl:grid xl:grid-cols-2 gap-3 sm:gap-6">
            <div className="relative">
              <InputSelect
                name={"notificar"}
                label={"¿Se debe notificar?"}
                placeholder={"si se debe notificar"}
                styles="!w-full"
                value={notificar}
                onChange={(e) => handleChange(e, "notificar")}
                error={errors["notificar"]}
                options={OPCIONES_SI_NO}
                disableFirstOption
                disabled={ver}
              />
              <InfoIcon
                className="absolute top-1 left-40 cursor-pointer"
                title="Al seleccionar la opción de SÍ, cada vez que este paquete sea vendido, se le notificará a su correo."
              />
            </div>
            <div
              className={`${
                notificar ? "visible block" : "hidden md:block md:invisible lg:hidden lg:visible xl:block xl:invisible"
              } relative`}
            >
              <InputText
                name={"correoNotificacion"}
                label={"Correo de notificación adicional"}
                placeholder={ver ? "" : "Ingrese el correo electrónico adicional"}
                styles="!w-full"
                value={correoNotificacion ?? ""}
                onChange={(e) => handleChange(e, "correoNotificacion")}
                error={errors["correoNotificacion"]}
                disabled={ver}
              />
              <InfoIcon
                className="absolute top-1 left-64 cursor-pointer"
                title="Debe introducir un correo adicional solo si desea que se le notifique en un correo diferente al que tiene registrado en la plataforma, al cual, se notifica por defecto."
              />
            </div>
          </div>
        </div>
      </div>
      <RichContentInput
        name={"descripcion"}
        label={"Descripción del paquete"}
        value={descripcion}
        onChange={(e) => handleChange(e, "descripcion")}
        error={errors["descripcion"]}
        disabled={ver}
        placeholder="Escriba la descripción del paquete"
        styles="mt-6 mx-0 lg:mx-8"
      />
    </div>
  );
};

export default PaqueteInfoForm;
