import React, { Fragment, useCallback, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import ContentContainer from "../../../fragments/ContentContainer";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import SubHeaderDatosEnvio from "./SubHeaderDatosEnvio";
import DatosEnvioForm from "./DatosEnvioForm";
import useValidateForm from "../../../hooks/useValidateForm";
import useFormSubmit from "../../../hooks/useFormSubmit";
import {
  getDatosDeEnvioPlantel,
  postDatosDeEnvioPlantel,
  putDatosDeEnvioPlantel,
} from "../../../services/Api/MarketPlace/Planteles/DatosEnvio";
import alertConfiguration from "../../../utils/alertsUtils";
import { toast } from "react-toastify";

const INITIAL_VALUES_DATOS_ENVIO = {
  nombreDireccion: "",
  telefono: "",
  ubicacion: "",
  referencias: "",
};

const DatosEnvio = () => {
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const obtenerDatosDeEnvio = useCallback(async () => {
    try {
      let datosEnvioResponse = await getDatosDeEnvioPlantel(params.estructuraId);

      if(datosEnvioResponse.ubicacion){
        datosEnvioResponse.ubicacion.nombre = datosEnvioResponse.ubicacion.direccion
      }else{
        datosEnvioResponse.ubicacion = ""
      }

      delete datosEnvioResponse.plantel;
      setValues(datosEnvioResponse);
      setIsEdit(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [params]);

  const datosEnvioSubmit = async () => {
    try {
      if (Object.values(errors).length === 0) {
        if (isEdit) {
          await putDatosDeEnvioPlantel(params.estructuraId, values._id, values);
        } else {
          await postDatosDeEnvioPlantel(params.estructuraId, values);
        }
        toast.success("Datos de envío guardados correctamente.", alertConfiguration);
        navigate(-1);
      } else {
        setErrors(errors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues, setErrors } =
    useValidateForm(INITIAL_VALUES_DATOS_ENVIO);

  const [formStatus, handleSubmit] = useFormSubmit(datosEnvioSubmit);

  useEffect(() => {
    obtenerDatosDeEnvio();
  }, [obtenerDatosDeEnvio]);

  useEffect(() => {
    let hasEmptyFields = Object.keys(values).some((key) => {
      if (key === "referencias") return false;

      return values[key] === "";
    });

    if (hasEmptyFields) setIsEnableSubmitButton(false);
  }, [values]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
            <SubHeaderDatosEnvio />
            <ContentContainer>
              <div className="flex flex-col w-full space-y-6">
                <DatosEnvioForm
                  datosEnvio={values}
                  errors={errors}
                  handleChange={handleChange}
                  setDatosEnvio={setValues}
                  setIsEnableSubmitButton={setIsEnableSubmitButton}
                />
              </div>
            </ContentContainer>
            <div className="flex justify-center gap-x-4 mt-6">
              <div className="flex w-full sm:w-1/2 justify-center gap-x-4">
                <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => navigate(-1)} />
                <SubmitButton
                  text="Guardar"
                  disabled={formStatus === "submitting" || !isEnableSubmitButton}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DatosEnvio;
