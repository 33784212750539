import React, { useState, useEffect } from "react";
import {DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {v4 as uuidv4} from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import SimplePlayer from "../../../Actividades/ActividadesUtils/SimplePlayer";
import { useContext } from "react";
import { ActividadContext } from "../../../Actividades/ActividadesUtils/ActWrapper";
import { shuffle } from "../../../Actividades/ActividadesUtils/ActividadesUtils";
import Lightbox from "../../../Actividades/ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "../../../Actividades/ActividadesUtils/Lightbox/LightboxWrapper";
import { Trans } from "react-i18next";
import ImagenValidada from "../../../Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";

const colors = [
  "#3f297e",
  "#175fa9",
  "#169ed8",
  "#239b63",
  "#64b031",
  "#efe61f",
  "#f7a416",
  "#e6471d",
  "#dc0936",
  "#e5177b",
  "#be1180",
  "#871f7f",
  "#3f297e",
  "#175fa9",
  "#169ed8",
  "#239b63",
  "#64b031",
  "#efe61f",
  "#f7a416",
  "#e6471d",
  "#dc0936",
  "#e5177b",
  "#be1180",
  "#871f7f",
];

function ActividadRelacionarColumnas({columnas, f1, f2, evaluable, umaBloque, guardarCalificacion, instrucciones}) {
  const activityContext = useContext(ActividadContext)
  const generatedColumns = f2.map((item) => {
    return {
      texto: item.texto,
      imagen:item.imagen,
      audio:item.audio,
      items: [],
      _id: uuidv4(),
      orden: item.orden
    };
  });

  shuffle(f1);
  
  const mainColumn = [{
      name: "columna primera ",
      items: f1.map((item, index)=>({...item, background:colors[index]})),
      _id: "columna_principal",
      dropDisabled: false,
      orden: null
    }];

  const columnsFromBackend = [...mainColumn, ...generatedColumns];
  const [columns, setColumns] = useState(columnsFromBackend);
  const [calificado, setCalificado] = useState(false)
  const [iniciado, setIniciado] = useState(false)
  const [lightBoxImage, setLightboxImage] = useState('')

  const generarResumen = () =>{
    return JSON.stringify(columnas)
  }

  const calificar = () => {
    setCalificado(true)
    const columnsToCheck = Object.entries(columns).filter((el) => {
      return el[1]._id !== "columna_principal";
    });

    const calificacion = columnsToCheck.map((item) => {
      console.log(item[1])
      if(item[1].items.length > 0 ){
        return item[1].items[0].orden === item[1].orden;
      }else{
        return false
      }
    });

    setTimeout(()=>{
      if(iniciado){
        activityContext.postCalificacionActividad((calificacion.filter(Boolean).length/columnsToCheck.length)*100)
        const reactivosCorrectos = calificacion.filter((el)=>(el === true))
        if(umaBloque)guardarCalificacion(umaBloque, reactivosCorrectos.length, generarResumen())
      }
      else if(!iniciado)activityContext.postCalificacionActividad(null)
    },500)
  };

  const onDragEnd = (result, columns, setColumns) => {
    setIniciado(true)
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const autocompletarActividad = () =>{
    const respuestasCorrectas = columnsFromBackend.map((item)=>{
      const respuesta  = f1.map((item, index)=>({...item, background:colors[index], etiqueta:index})).filter((el)=>(el.orden === item.orden))
      return {...item, ...{items:respuesta}}
    })
    setColumns(respuestasCorrectas)
  }

  useEffect(()=>{
    if(!evaluable){
      autocompletarActividad()
    }else{
      setColumns(columnsFromBackend)
    }
  },[evaluable])

  return (
    <>
    {instrucciones && <div className="max-w-activity act-instructions mb-6">{instrucciones}</div>}
    <Lightbox 
      lightboxValue={lightBoxImage} 
      closeLightbox = {()=>setLightboxImage('')}
    />
    <div className={`w-full ${evaluable ? '' : 'pointer-events-none'}`}>
      <div className="flex justify-between items-baseline p-2 pt-8 w-full max-w-activity mx-auto">
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          <div>
            {Object.entries(columns)
              .slice(0, 1)
              .map(([columnId, column], index) => {
                return (
                  <div className="flex flex-col items-center" key={columnId}>
                    <h2 className="w-full text-left pl-3 text-[#697d99] text-base md:text-lg">
                      <Trans i18nKey='aColumnLabel'></Trans>
                    </h2>
                    <div className="m-2">
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{background: snapshot.isDraggingOver ? "#d1d4d9" : "#e6e9ee",}}
                              className='w-[125px] md:w-[200px] transition-all border overflow-auto max-h-[9999px] min-h-[350px] rounded-lg p-2'
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className={`rounded-lg flex min-h-[60px] mb-2 border text-white ${item.imagen ? '' : 'p-4'}`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            background:item.background,
                                            //background:'white',
                                            ...provided.draggableProps.style
                                          }}
                                        >
                                          <div className="flex items-center justify-center w-full">
                                            <span className="text-sm flex items-center justify-center md:text-base w-full">
                                              {item.imagen ? 
                                              <>
                                                <LightboxWrapper
                                                  handleClick={()=>{
                                                    setLightboxImage(item.imagen)
                                                  }}
                                                >
                                                  <ImagenValidada
                                                    className="h-full w-full aspect-video shadow rounded-xl mx-auto bg-white object-fill"  
                                                    src={item.imagen}
                                                  />
                                                </LightboxWrapper>
                                              </>
                                               : '' }
                                              {item.texto ? <span className="flex-1 ml-2 text-sm md:text-base">{item.texto}</span>: ''}
                                              {item.audio  ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white !w-[4.5rem]'} audio={item.audio}/> : ''}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
          </div>
          <div>
            <h2 className="text-base md:text-lg text-[#697d99] ml-2">
              <Trans i18nKey='bColumnLabel'></Trans>
            </h2>
            {Object.entries(columns)
              .slice(1)
              .map(([columnId, column], index) => {
                return (
                  <div className='mb-4 md:flex items-center relative bg-white border rounded-lg p-2 md:bg-[#f8fdff] md:border-none md:rounded-none md:p-0 shadow-lg md:shadow-none' key={columnId}>
                    {!evaluable ? 
                    (column.items.length > 0 && column.orden === column.items[0].orden 
                    ? <FontAwesomeIcon icon={faCircleCheck} color={"#4caf50"} className={`hidden md:block text-3xl absolute left-[-2.5rem] transition duration-500 delay-200  ${calificado ? "!opacity-100" : ''}`}></FontAwesomeIcon> 
                    : <FontAwesomeIcon icon={faCircleXmark} color={"#DC3545"} className={`hidden md:block text-3xl absolute left-[-2.5rem] transition duration-500 delay-200 opacity-0 ${calificado ? "!opacity-100" : ''}`}></FontAwesomeIcon>) 
                    : ''}
                    <div className="w-fit m-[5px]">
                      <Droppable
                        isDropDisabled={column.items.length >= 1 ? true : false}
                        droppableId={columnId}
                        key={columnId}
                        className="m-[10px]"
                        direction={"horizontal"}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{background: snapshot.isDraggingOver ? "#d1d4d9" : "#e6e9ee"}}
                              className='w-[120px] md-w-[150px] md:w-[200px] rounded-xl transition-all overflow-visible max-h-[999px] min-h-[80px] border p-2'
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                    isDragDisabled={calificado}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className={`rounded-lg flex min-h-[60px] border text-white transition-all ${item.imagen ? '' : 'p-4'}`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{userSelect: "none",background:item.background, ...provided.draggableProps.style}}
                                        >
                                          <div className="flex justify-center items-center w-full">
                                            <span className="text-sm flex items-center justify-center md:text-base w-full">
                                              {item.imagen ? 
                                              <LightboxWrapper
                                                handleClick={()=>setLightboxImage(item.imagen)}
                                              >
                                                <ImagenValidada 
                                                  className="h-full w-full aspect-video object-fill shadow rounded-xl mx-auto bg-white"  
                                                  src={item.imagen} 
                                                /> 
                                              </LightboxWrapper>
                                              : '' }
                                              {item.texto ? <span className="flex-1 ml-2">{item.texto}</span>: ''}
                                              {item.audio  ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white !w-[4.5rem]'} audio={item.audio}/> : ''}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                    <h6 className="text-sm md:text-base m-0 pl-2 w-full max-w-[20rem] ">
                          {column.imagen ? 
                          <LightboxWrapper handleClick={()=>setLightboxImage(column.imagen)}>
                            <ImagenValidada
                              className="md:h-[100px] aspect-video object-fill w-max-[8rem] shadow border rounded-lg max-w-[118px] md:max-w-[100rem]"  
                              src={column.imagen} 
                            />
                          </LightboxWrapper>
                          : '' }
                          {column.texto ? <span className="flex-1 ml-2">{column.texto}</span>: ''}
                          {column.audio  ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white !w-[4.5rem]'} audio={column.audio}/> : ''}
                    </h6>
                  </div>
                );
              })}
          </div>
        </DragDropContext>
      </div>
      {evaluable ? 
      <div className="flex align-items-center justify-center mt-10 mb-4 text-sm md:text-base">
        <button 
          className="m-5 py-3 px-10 rounded-full inline" 
          onClick={() => {setColumns(columnsFromBackend);setCalificado(false)}}
        >
          <Trans>Limpiar</Trans>
        </button>
        <button
         className="border bg-nimbus text-white m-5 md:py-3 md:px-10 rounded-full inline py-2 px-5" 
         onClick={calificar}>
          <Trans>{iniciado ? 'Enviar y cerrar' : 'Cerrar'}</Trans>
        </button>
      </div>
      :''}
    </div>
    </>
  );
}

ActividadRelacionarColumnas.defaultProps = {
  evaluable:true,
}

export default ActividadRelacionarColumnas;
