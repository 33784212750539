
const arreglosReducers = (state=[], action)=>{
    switch (action.type){
        case 'SET_ARREGLO':
            return state = [...state, action.payload]
        case 'GROW_ARREGLO':
            const insert = (arr, index, newItem) => [
                ...arr.slice(0, index),
                newItem,
                ...arr.slice(index)
            ]
            const result = insert(state, action.position + 1, action.payload)
            return result
        case 'REORDER_ARREGLO':
            return state = action.payload
        case 'EDIT_TEXT':
            return state = action.payload.map((element)=>{
                if(element.orden === action.index){
                    element.texto = action.texto
                }
                return element
            })
        case 'EDIT_PIE':
            return state = action.payload.map((element)=>{
                if(element.orden === action.index){
                    element.pieDeFoto = action.texto
                }
                return element
            })
        case 'EDIT_MEDIA':
            return state = action.payload.map((element)=>{
                if(element.orden === action.index){
                    element.texto = action.texto
                }
                return element
            })
        case 'DELETE_UMA':
            return state = action.payload.filter((e)=>{
                return e.orden !== action.index 
            })
        case 'LOAD_FILE':
            return state = action.payload.map((element)=>{
                if(element.orden == action.index){
                    element.imagen = action.url
                }
                return element
            })
        case 'LOAD_VIDEO':
            return state = action.payload.map((element)=>{
                if(element.orden == action.index){
                    element.path = action.url
                }
                return element
            })
        case 'RELOAD_VIDEO':
            return state = []
        default:
            return state;
    }
}
export default arreglosReducers;