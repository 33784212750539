import React, { useEffect, useState } from "react";
import InputText from "../../../fragments/Inputs/InputText";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash2.svg";
import { getPaises } from "../../../services/api";

const TIPO_CUENTA_OPTIONS = [
  {
    _id: false,
    nombre: "No",
  },
  {
    _id: true,
    nombre: "Sí",
  },
];

const CuentaBancariaForm = ({ cuentaBancaria, handleDeleteCuenta, handleChange, errors, index }) => {
  let { esExtranjera, pais, nombreBeneficiario, banco, numeroCuenta, swiftAba, esPrincipal } = cuentaBancaria[index];

  const [paises, setPaises] = useState([]);
  const [paisesCuentaExtranjera, setPaisesCuentaExtranjera] = useState([])

  useEffect(() => {
    getPaises().then((paisesResponse) => {
      let newPaisesCuentaExtranjera = paisesResponse.data.filter(pais => pais._id !== "6213a5815e1e2a212c63b6fc")
      setPaisesCuentaExtranjera(newPaisesCuentaExtranjera)
      setPaises(paisesResponse.data)
    });
  }, []);

  return (
    <div className="border-2 rounded-lg bg-white w-full p-6 relative">
      {cuentaBancaria.length > 1 && (
        <div className="absolute right-7 top-2 flex space-x-4 items-center">
          <input
            type="radio"
            name={`esPrincipal`}
            className="border-2 border-gray-300 cursor-pointer"
            checked={esPrincipal}
            onChange={(e) => handleChange(e, "esPrincipal", index)}
          />
          <DeleteIcon className="h-5 cursor-pointer" onClick={(e) => handleDeleteCuenta(e, index)} />
        </div>
      )}
      <div className="flex items-center">
        <h2 className="font-bold basis-44 mr-2">Datos de la cuenta</h2>
        <span className="w-full h-[2px] bg-divider"></span>
      </div>
      <div className="flex flex-col sm:grid sm:grid-cols-2 gap-3 sm:gap-6 lg:grid-cols-3 mt-6">
        <InputSelect
          name="esExtranjera"
          label={"¿Es extranjera?"}
          placeholder={"tipo de cuenta"}
          styles="!w-full"
          options={TIPO_CUENTA_OPTIONS}
          value={esExtranjera}
          onChange={(e) => handleChange(e, "esExtranjera", index)}
          error={errors[index]["esExtranjera"]}
          disableFirstOption
        />
        <InputSelect
          name="pais"
          label={"País"}
          styles="!w-full"
          options={esExtranjera ? paisesCuentaExtranjera : paises}
          value={pais._id}
          onChange={(e) => handleChange(e, "pais", index)}
          disabled={!esExtranjera ? true : false}
          error={errors[index]["pais"]}
          disableFirstOption
        />
        <InputText
          name="nombreBeneficiario"
          label={"Nombre del beneficiario"}
          placeholder={"Ingrese el nombre del beneficiario"}
          styles="!w-full"
          value={nombreBeneficiario}
          onChange={(e) => handleChange(e, "nombreBeneficiario", index)}
          error={errors[index]["nombreBeneficiario"]}
        />
        <InputText
          name="banco"
          label={"Banco"}
          placeholder={"Ingrese el nombre del banco"}
          styles="!w-full"
          value={banco}
          onChange={(e) => handleChange(e, "banco", index)}
          error={errors[index]["banco"]}
        />
        <div className="col-span-2">
          <InputText
            name="numeroCuenta"
            label={"Número de cuenta"}
            placeholder={"Ingrese el número de la cuenta"}
            styles="!w-full"
            value={numeroCuenta}
            onChange={(e) => handleChange(e, "numeroCuenta", index)}
            error={errors[index]["numeroCuenta"]}
          />
        </div>
        {esExtranjera && (
          <InputText
            name="swiftAba"
            label={"Código SWIFT/BIC o ABA"}
            placeholder={"Ingrese el código SWIFT/BIC o ABA"}
            styles="!w-full"
            value={swiftAba}
            onChange={(e) => handleChange(e, "swiftAba", index)}
            error={errors[index]["swiftAba"]}
          />
        )}
      </div>
    </div>
  );
};

export default CuentaBancariaForm;
