import React from "react";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";

const IconButton = ({ disabled = false, text = "", onClick, styles = "", Icon = Add, iconStyles, textStyles="", children }) => {
  return (
    <button
      type="button"
      className={`w-full sm:w-80 lg:w-fit bg-nimbusDark text-white py-2 p-4 rounded-lg flex items-center justify-center ${
        disabled && "opacity-70"
      } ${styles}`}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon className={`w-5 h-5 mr-2 ${iconStyles}`} />
      <p className={textStyles}>{text}</p>
      {children}
    </button>
  );
};

export default IconButton;
