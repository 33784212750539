import React from "react";
import SubHeader from "../../../fragments/SubHeader";
import { useNavigate } from "react-router-dom";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../fragments/BreadCrumb";

const SubHeaderCuentaBancaria = () => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Configuración del marketplace /"} onClick={() => navigate(`/marketplace/configuracion`)} />
        <BreadCrumb route={"Cuenta bancaria"} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderCuentaBancaria;
