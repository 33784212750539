import React, { useEffect, useState } from 'react';
import InputBuscar from '../../../fragments/Inputs/InputBuscar';

const FILTRO_PAISES_INITIAL_VALUES = {
  busqueda: '',
};

const PaisesFilter = ({ paisesList, paisesFiltro, setPaisesFiltro }) => {
  const [filtro, setFiltro] = useState(FILTRO_PAISES_INITIAL_VALUES);
  const filterDataBusqueda = (paises, busqueda) => {
    if (busqueda === '') return paises;

    const newPaisesFiltro = paises.filter((pais) =>
    pais.nombre.toLowerCase().includes(busqueda) ||
    pais.divisa.toLowerCase().includes(busqueda) ||
    pais.simbolo.toLowerCase().includes(busqueda) ||
    pais.equivalenciaDolar.toString().includes(busqueda)
    );
    return newPaisesFiltro;
  };

  useEffect(() => {
    let newPaisesFiltro = paisesList;
    newPaisesFiltro = filterDataBusqueda(newPaisesFiltro, filtro.busqueda.toLowerCase());
    setPaisesFiltro(newPaisesFiltro);
  }, [filtro, paisesList]);

  return (
    <div className='w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start'>
      <div className='flex gap-x-5'>
        <div className='flex flex-wrap items-center'>
          <p className='mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center'>
            {paisesFiltro.length}
          </p>
          <p className='text-black'>Países</p>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row space-x-3 items-end lg:items-start'>
        <div className='flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit'>
          <InputBuscar
            onChange={(e) =>
              setFiltro({ ...filtro, busqueda: e.target.value })
            }
            value={filtro.busqueda}
          />
        </div>
      </div>
    </div>
  );
};

export default PaisesFilter;
