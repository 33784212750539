import { _get, _post, _put } from '../../http';

export const postFormador = (body) => _post(`/formador`, body);

export const putFormador = (formadorId, body) =>
  _put(`/formador/${formadorId}`, body);

export const getFormadoresEstructura = (tipoEstructura, estructuraId) =>
  _get(`/formador/${tipoEstructura}/${estructuraId}`);

export const getFormadorPorId = (formadorId) => _get(`/formador/${formadorId}`);

export const postFormadoresLista = (autoresArr) => _post(`/formador/obtenerLista`, autoresArr);

export const getTodosFormadores = (editorialId, plantelId) => _get(`/formador/${editorialId}/${plantelId}/obtenerAutores`);