export const CATEGORIAS = [
    {
        _id: 'Curso',
        nombre: 'Curso',
    },
    {
        _id: 'Servicios escolares',
        nombre: 'Servicios escolares',
    },
    {
        _id: 'Paquete',
        nombre: 'Paquete',
    },
    {
        _id: 'Útiles escolares',
        nombre: 'Útiles escolares',
    },
 ];

 export const ORIGEN = [ 
    {
        _id: 'Origen 1',
        nombre: 'Origen 1',
    },
    {
        _id: 'Origen 2',
        nombre: 'Origen 2',
    },
 ];

 export const ESTATUS = [
    {
        _id: 'Disponible',
        nombre: 'Disponible',
    },
    {
        _id: 'Agotado',
        nombre: 'Agotado',
    },
 ];

 export const NIVEL_EDUCATIVO = [
    {
        _id: 'Primaria',
        nombre: 'Primaria',
    },
    {
        _id: 'Secundaria',
        nombre: 'Secundaria',
    },
    {
        _id: 'Preparatoria',
        nombre: 'Preparatoria',
    },
 ];

 export const GRADO_ACADEMICO = [
    {
        _id: '1°',
        nombre: '1°',
    },
    {
        _id: '2°',
        nombre: '2°',
    },
    {
        _id: '3°',
        nombre: '3°',
    },
 ];

 export const GANANCIA = [
    {
        _id: 'Ninguna',
        nombre: 'Ninguna',
    },
    {
        _id: 'Saldo positivo',
        nombre: 'Saldo positivo',
    },
    {
        _id: 'Saldo negativo',
        nombre: 'Saldo negativo',
    },
 ];
 export const GANANCIA_CANTIDAD = [
     {
         _id: 'Saldo positivo',
         nombre: 'Mayor a 0.00',
    },
    {
        _id: 'Saldo negativo',
        nombre: 'Menor a 0.00',
    },
    {
        _id: 'Ninguna',
        nombre: 'Igual a 0.00',
    },
 ];