import { useState } from "react";

export default function useValidateFormArray(initialValues) {
  const [values, setValues] = useState(initialValues);

  const [isEnableSubmitButton, setIsEnableSubmitButton] = useState(false);

  const [errors, setErrors] = useState(Array.from({ length: initialValues.length }, () => ({})));

  const handleChange = (event, inputType, index) => {
    event.persist();

    let name = event.target.name;
    let value = event.target.value;
    let type = event.target.type;

    validate(name, value, inputType, index);

    const newValues = [...values];

    switch (type) {
      case "password":
      case "email":
      case "text":
      case "date":
        newValues[index] = { ...newValues[index], [name]: value };
        break;
      case "checkbox":
        newValues[index] = { ...newValues[index], [name]: event.target.checked };
        break;
      case "radio":
        newValues[index] = { ...newValues[index], [name]: event.target.checked };
        break;
      case "file":
        newValues[index] = { ...newValues[index], [name]: event.target.files[0] };
        break;
      case "select-one":
        if (name === "esExtranjera") {
          newValues[index] = { ...newValues[index], [name]: value === "true" };
        } else {
          newValues[index] = { ...newValues[index], [name]: { _id: value } };
        }
        break;
      default:
        break;
    }

    setValues(newValues);
    setIsEnableSubmitButton(true);
  };

  const validate = (name, value, inputType, index) => {
    switch (inputType) {
      case "pais":
        let newValues = [...values];
        newValues[index].swiftAba = "";
        break;
      case "esExtranjera":
        if (value === "false") {
          let newValues = [...values];
          newValues[index].pais._id = "6213a5815e1e2a212c63b6fc";
          newValues[index].swiftAba = "";
          newValues[index].esExtranjera = false;
          removeError("swiftAba", index);
        }
        break;
      case "esPrincipal":
        if (values.length > 1) {
          let newValues = [...values];
          if (index === 0) {
            newValues[1].esPrincipal = !value;
          } else {
            newValues[0].esPrincipal = !value;
          }
        }
        break;
      case "nombreBeneficiario":
      case "banco":
        if (value.length <= 2) {
          addError(name, "Este campo debe tener por lo menos 3 caracteres.", index);
        } else if (value.length >= 50) {
          addError(name, "Este campo debe tener menos de 50 caracteres.", index);
        } else if (!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ _]+$/.test(value)) {
          addError(name, "Este campo sólo puede tener letras y espacios.", index);
        } else {
          removeError(name, index);
        }
        break;
      case "numeroCuenta":
        if (!/^\d+$/.test(value)) {
          addError(name, "El número de cuenta sólo puede contener números.", index);
        } else if (value.length < 5 || value.length > 35) {
          addError(name, "El número de cuenta debe tener entre 5 y 35 dígitos.", index);
        } else {
          removeError(name, index);
        }
        break;
      case "swiftAba":
        if (!/^[a-z0-9áéíóúü]+$/i.test(value)) {
          addError(name, "El código sólo puede contener valores alfanúmericos.", index);
        } else if (value.length < 8 || value.length > 11) {
          addError(name, "El código debe tener entre 8 y 11 caracteres.", index);
        } else {
          removeError(name, index);
        }
        break;

      default:
        if (value === undefined) {
          addError(name, "Este campo es obligatorio", index);
        } else {
          removeError(name, index);
        }
        break;
    }
  };

  const addError = async (name, error, index) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = {
        ...newErrors[index],
        [name]: error,
      };
      return newErrors;
    });
  };

  const removeError = (name, index) => {
    const { [name]: _, ...newErrors } = errors[index];
    setErrors((prevState) => {
      const newState = [...prevState];
      newState[index] = newErrors;
      return newState;
    });
  };

  return {
    values,
    errors,
    handleChange,
    setValues,
    addError,
    removeError,
    setErrors,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
  };
}
