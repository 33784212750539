import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/icons/viewIcon.svg';
import ExampleIcon from '../../../../assets/icons/visa.svg';
import { useNavigate } from 'react-router-dom';
import Mexico from '../../../../assets/icons/mexico.png';
import ElSalvador from '../../../../assets/icons/elsalvador.jpeg';
import Colombia from '../../../../assets/icons/colombia.png';
import Guatemala from '../../../../assets/icons/guatemala.jpeg';
import Peru from '../../../../assets/icons/peru.jpeg';
import Ecuador from '../../../../assets/icons/ecuador.jpeg';
import CostaRica from '../../../../assets/icons/costarica.jpeg';
import EstadosUnidos from '../../../../assets/icons/estadosunidos.jpeg';
import Panama from '../../../../assets/icons/panama.jpeg';
import Paraguay from '../../../../assets/icons/paraguay.jpeg';

const icons = {
  mexico: Mexico,
  elsalvador: ElSalvador,
  colombia: Colombia,
  guatemala: Guatemala,
  peru: Peru,
  ecuador: Ecuador,
  costarica: CostaRica,
  estadosunidos: EstadosUnidos,
  panama: Panama,
  paraguay: Paraguay,
};

const PaisesRow = ({ paisInfo }) => {
  const navigate = useNavigate();
  const {
    nombre,
    divisa,
    simbolo,
    abreviatura,
    equivalenciaDolar,
    bandera,
    configuracionEquivalencia,
    _id,
  } = paisInfo;
  return (
    <tr className='p-2 text-left bg-white'>
      <td className='border-2 rounded-l-lg border-r-0 text-left font-sans min-w-[52px] py-2'>
        <img
          src={bandera}
          alt='pais'
          className='h-10 w-10 object-cover mx-auto'
        />
      </td>
      <td className='border-y-2 font-sans text-left capitalize'>{nombre}</td>
      <td className='border-y-2 font-sans text-left'>{divisa}</td>
      <td className='border-y-2 font-sans text-left'>{simbolo}</td>
      <td className='border-y-2 font-sans text-right px-5'>
        {Object.keys(configuracionEquivalencia).length > 0
          ? configuracionEquivalencia.equivalenciaDolar.toLocaleString(undefined,{maximumFractionDigits: 5,})
          : equivalenciaDolar?.toLocaleString(undefined, {maximumFractionDigits: 5,})
        }
      </td>
      <td className='border-2 rounded-r-md border-l-0 min-h-[52px] text-left font-sans'>
        <div className='flex gap-2 justify-evenly'>
          <button>
            <EditIcon
              className='h-5 w-5'
              onClick={() => navigate(`/paises/${_id}/editar`)}
            />
          </button>
          <button>
            <ViewIcon
              className='h-5 w-5'
              onClick={() => navigate(`/paises/${_id}/ver`)}
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PaisesRow;
