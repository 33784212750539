import React, { useCallback, useContext, useEffect, useState } from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import InputText from "../../../fragments/Inputs/InputText";
import useFormSubmit from "../../../hooks/useFormSubmit";
import useValidateForm from "../../../hooks/useValidateForm";
import { UserContext } from "../../../services/user";
import {
  getPorcentajeDeGananciaDistribucion, putPorcentajeDeGananciaDistribucion,
} from "../../../services/Api/MarketPlace/Distribuciones/PorcentajeDeGanancia";
import {
  getPorcentajeDeGananciaCorporativo, putPorcentajeDeGananciaCorporativo,
} from "../../../services/Api/MarketPlace/Corporativos/PorcentajeDeGanancia";
import {
  getPorcentajeDeGananciaPlantel, putPorcentajeDeGananciaPlantel,
} from "../../../services/Api/MarketPlace/Planteles/PorcentajeDeGanancia";
import alertConfiguration from "../../../utils/alertsUtils";
import { toast } from "react-toastify";

const INITIAL_VALUE_PORCENTAJE_GANANCIA = {
  porcentajeDeGanancia: "10",
};

const PorcentajeGananciaModal = ({ isOpen, setIsOpen }) => {
  const { user, activeRole } = useContext(UserContext);

  const obtenerPorcentajeDeGanancia = useCallback(async () => {
    let porcentajeDeGananciaResponse;
    try {
      switch (activeRole) {
        case "DISTRIBUIDOR":
          porcentajeDeGananciaResponse = await getPorcentajeDeGananciaDistribucion(user.distribucion._id);
          break;
        case "DIR_CORPORATIVO":
          porcentajeDeGananciaResponse = await getPorcentajeDeGananciaCorporativo(user.escuela._id);
          break;
        case "DIR_PLANTEL":
        case "ADMIN_OPERATIVO":
          porcentajeDeGananciaResponse = await getPorcentajeDeGananciaPlantel(user.escuela.campus._id);
          break;
        default:
          break;
      }
      let porcentajeDeGanancia = { porcentajeDeGanancia: porcentajeDeGananciaResponse.porcentajeDeGanancia };
      setValues(porcentajeDeGanancia);
    } catch (error) {
      console.log(error);
    }

    if (!isOpen) {
      setErrors({})
      setIsEnableSubmitButton(false)
    };
  }, [isOpen]);

  const handleSubmitPorcentaje = async () => {
    if (Object.keys(errors).length === 0) {
      let body = { porcentajeDeGanancia: parseInt(values.porcentajeDeGanancia) };
      try {
        switch (activeRole) {
          case "DISTRIBUIDOR":
            await putPorcentajeDeGananciaDistribucion(user.distribucion._id, body);
            break;
          case "DIR_CORPORATIVO":
            await putPorcentajeDeGananciaCorporativo(user.escuela._id, body);
            break;
          case "DIR_PLANTEL":
          case "ADMIN_OPERATIVO":
            await putPorcentajeDeGananciaPlantel(user.escuela.campus._id, body);
            break;
          default:
            break;
        }
        toast.success("Porcentaje de ganancia guardado correctamente.", alertConfiguration);
        setIsOpen(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrors(errors);
      setIsEnableSubmitButton(true);
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(handleSubmitPorcentaje);

  const { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues, setErrors } =
    useValidateForm(INITIAL_VALUE_PORCENTAJE_GANANCIA);

  useEffect(() => {
    obtenerPorcentajeDeGanancia();
  }, [obtenerPorcentajeDeGanancia]);

  useEffect(() => {
    if (values.porcentajeDeGanancia === "") setIsEnableSubmitButton(false);
  }, [values.porcentajeDeGanancia]);

  return (
    <NimbusModal isOpen={isOpen}>
      <h1 className="text-nimbusDark font-bold whitespace- px-4">Porcentaje de ganancia de los artículos de línea</h1>
      <p className="w-96 px-4">
        Al configurar el porcentaje de ganancia de los artículos de línea, no se modifica el precio de venta ya
        establecido, únicamente se cambia el valor del precio de venta sugerido. Por defecto, el porcentaje de ganancia
        será 10%, pero podrá ser modificado cuando asi se requiera.
      </p>
      <div className="w-3/3 px-4 text-left text-xs">
        <InputText
          name="porcentajeDeGanancia"
          placeholder="Ingrese porcentaje de ganancia"
          label="Porcentaje de ganancia (%)"
          value={values.porcentajeDeGanancia}
          onChange={(e) => handleChange(e, "porcentajeDeGanancia")}
          error={errors["porcentajeDeGanancia"]}
          styles="!w-full"
        />
      </div>
      <div className="flex justify-center gap-x-4">
        <CancelButton text="Cancelar" bgColor="bg-divider" onClick={() => setIsOpen(false)} />
        <SubmitButton
          text="Guardar"
          disabled={formStatus === "submitting" || !isEnableSubmitButton}
          onClick={handleSubmit}
        />
      </div>
    </NimbusModal>
  );
};

export default PorcentajeGananciaModal;
