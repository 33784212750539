import React from "react";
import AsignarProductosRow from "./AsignarProductosRow";

const AsignarProductosTable = ({
  articulosList,
  accionesMasivasShow,
  seleccionarArticulo,
  activeRole,
  tipoDeMoneda,
}) => {
  return (
    <div className="overflow-x-auto py-10">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th></th>
            <th></th>
            <th>Artículo</th>
            <th>Categoría</th>
            {activeRole !== "DIR_PLANTEL" && activeRole !== "ADMIN_OPERATIVO" ? (
              <th>
                <div className="flex gap-2 items-center justify-center">
                  Costo ({tipoDeMoneda[0]})
                </div>
              </th>
            ) : (
              <>
                <th>Nivel educativo</th>
                <th>Grado académico</th>
              </>
            )}
            <th>
              <div className="flex gap-2 items-center justify-center">
                Precio de venta ({tipoDeMoneda[0]})
              </div>
            </th>
            {activeRole !== "DIR_PLANTEL" && activeRole !== "ADMIN_OPERATIVO" && (
              <>
                <th>
                  <div className="flex gap-2 items-center justify-center">
                    Precio de asignación ({tipoDeMoneda[0]})
                  </div>
                </th>
                <th>
                  <div className="flex gap-2 items-center justify-center">
                    Ganancia ({tipoDeMoneda[0]})
                  </div>
                </th>
              </>
            )}
            <th>Estatus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {articulosList.map((articulo) => (
            <AsignarProductosRow
              key={articulo.articuloListaId}
              articuloInfo={articulo}
              accionesMasivasShow={accionesMasivasShow}
              seleccionarArticulo={seleccionarArticulo}
              tipoDeMoneda={tipoDeMoneda}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AsignarProductosTable;
