import React, {useState, useEffect} from 'react'
import CerrarActButton from "../ActividadesUtils/CerrarActButton";
import { textWithoutSpaces } from './ActividadesUtils/ActividadesUtils';
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import ReactAudioPlayer from 'react-audio-player';
import TextareaAutosize from 'react-textarea-autosize';
import { Trans } from 'react-i18next';


function EscuchaEscribe({inputList, lang, audioSpeed, audioType, viewText, umaBloque, guardarCalificacion, instrucciones}) {
  const [writtenSentences, setWrittenSentences] = useState([])
  const [iniciado, setIniciado] = useState(false)
  const [finalizado, setFinalizado] = useState(false)
  const [audioPlaying, setAudioPlaying] = useState('')

  const playAudio = (audio) =>{
    synth.cancel()
    if(typeof audio === 'object'){
      const audioObject = URL.createObjectURL(audio)
      setAudioPlaying(audioObject)
    }else{
      setAudioPlaying(audio)
    }
  }

  const synth = window.speechSynthesis;

  const generateVoice = (texto) =>{
    synth.cancel();
    const speakText = new SpeechSynthesisUtterance(texto);
    const voices = synth.getVoices();
    const esVoice = voices.reverse().find((el)=>(el.lang === 'es-ES'));
    const enVoice = voices.find((el)=>(el.lang === 'en-GB'));

    if(lang !== 'es'){
      speakText.lang = 'en-GB';
      speakText.voice = enVoice
    }else{
      speakText.lang = 'es-ES';
      speakText.voice = esVoice
    }
    synth.speak(speakText);
  }

  const activityContext = useContext(ActividadContext)

  const handleInputChange = (e, index) => {
    if(!iniciado)setIniciado(true)
    const { name, value } = e.target;
    const list = [...writtenSentences];
    list[index][name] = value;
    setWrittenSentences(list);
  };

  const correctSentences = writtenSentences.filter((el)=>{
    return textWithoutSpaces(el.texto).toLowerCase() === textWithoutSpaces(el.textoEscrito).toLowerCase()
  })

  const handleCalificar = () =>{
    const score = (correctSentences.length / inputList.length) * 100
    if(iniciado){
      activityContext.postCalificacionActividad(score)
      if(umaBloque)guardarCalificacion(umaBloque,correctSentences.length,'')
    }else{
      activityContext.postCalificacionActividad(null)
    }
  }

  useEffect(()=>{
    const initialSentences = inputList.map((el)=>{
      return{
        ...el,
        textoEscrito:''
      }
    })
    setWrittenSentences(initialSentences)
    return () => {
      synth.cancel()
    }
  },[])

  return (
    <>
    {instrucciones && <div className='act-instructions md:my-6 '>{instrucciones}</div>}
    {audioPlaying ?
    <ReactAudioPlayer
      src={typeof audioPlaying === 'object' ? URL.createObjectURL(audioPlaying) : audioPlaying }
      autoPlay
      controls
      className="absolute -top-[9999px] -left-[9999px] opacity-0"
      onEnded={()=>setAudioPlaying(null)}
    />:null}
      <div className="md:flex items-center w-full max-w-activity mx-auto">
        <div className='bg-white p-4 md:p-6 md:pb-0 rounded-2xl border shadow-lg w-full md:mb-10'>
          <div className="text-lg md:text-lg !text-center act-instructions mb-10">
            <Trans>
              {!finalizado && writtenSentences.length > 1 ? 'Escucha y escribe dentro de los recuadros' : ''}
              {!finalizado && writtenSentences.length === 1 ? 'Escucha y escribe dentro del recuadro' : ''}
              {finalizado ? 'Compara tus respuestas' : ''}
            </Trans>
          </div>
          {writtenSentences.map((el, i)=>(
              <div key={el._id} className="flex items-center mb-8">
                <div>
                  <button 
                    className='bg-[#1c7ccc] text-white p-3 ml-4 rounded-full shadow-lg aspect-square border md:w-[60px] md:h-[60px] md:text-[25px] w-10 h-10 text-xl flex items-center justify-center' 
                    onClick={el.audio ? ()=> playAudio(el.audio) : ()=> generateVoice(el.texto)}>
                    <FontAwesomeIcon className="" icon={faVolumeHigh}></FontAwesomeIcon>
                    </button>
                </div>
                <div className='text-xl md:text-3xl text-nimbusLight ml-8'>{i + 1}.</div>
                <div className='flex-1 w-full ml-4 relative'>
                  {finalizado ? 
                    <>
                      <div className='p-3 rounded-lg w-full bg-[#f3f3f3] min-h-[60px] overflow-y-auto h-auto'>
                        {el.textoEscrito ?
                         el.textoEscrito.split(' ').map((item, i)=>(<div className={`transition-all inline-block text-lg mr-2 ${item === el.texto.split(' ')[i] ? 'text-green-500' : 'text-red-500'}`}>{item}</div>)) 
                        : null}
                      </div>
                      {viewText ? 
                      <div className='mt-4'>
                        <Trans>Correcta</Trans>
                        <div className='text-[#1c7ccc] font-bold inline-block ml-2'>{el.texto}</div>
                      </div>:null}
                    </>
                    :
                    <TextareaAutosize
                    className='w-full rounded-xl !border !border-solid !border-[#ededed] min-h-[4rem] text-sm md:text-base bg-[#f3f3f3] text-nimbusLight pt-2 resize-none' 
                    type='text'
                    placeholder=""
                    spellCheck={false}
                    onChange={finalizado ? undefined : (e) => handleInputChange(e, i)}
                    readOnly={finalizado}
                    name="textoEscrito"
                  />}
                </div>
            </div>
          ))}
          {finalizado ? 
            <div className='text-center text-nimbusLight mb-4'>
              <div className='mx-2 inline-block'>{correctSentences.length}</div>
              <Trans i18nKey={'slideLabel'}>de</Trans>
              <div className='mx-2 inline-block'>{writtenSentences.length}</div>
              <Trans>respuestas correctas</Trans>
            </div>
          : null}
          {finalizado || !iniciado ? 
            <CerrarActButton onClick={handleCalificar} text={iniciado ? 'Enviar calificación' : 'Cerrar'}/> :
            <CerrarActButton onClick={()=>setFinalizado(true)} text={"Siguiente"}/>
          }
        </div>
      </div>
    </>
  )
}

export default EscuchaEscribe