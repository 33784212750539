export default function InputTextIcon({className="", icon,value,onBlur,onChange,name,id,disabled = false,label,error='', type="text", placeholder=""}) {
    return(
        <div className={className}>
          <label
            className="text-sm text-[#3A404C] font-semibold"
            htmlFor={id}
          >
            {label}
          </label>
          <div className={`${error === "" ? "border-[#e5e7eb]" : "border-red-400 focus-within:border-red-400"} flex items-center border-2 input rounded-lg mt-2 bg-white focus-within:border-[#2563eb]`}>
            <img className="mx-2" src={icon} />
            <input
              className="pl-0 outline-none border-0 rounded-md rounded-l-none focus:outline-none w-full focus:ring-0"
              placeholder={placeholder}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              name={name}
              id={id}
              disabled={disabled}
            />
          </div>
          <p className="font-thin text-xs text-red-400 mt-1">{error}</p>
        </div>
    )
}