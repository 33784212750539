import { toast } from "react-toastify";
import { deleteAviso } from "../../../services/Api/GestorAvisos";
import alertConfiguration from "../../../utils/alertsUtils";

const DeleteAviso = ({ id, onCloseModal, getAvisos }) => {
  const onDeleteAviso = async () => {
    try {
      await deleteAviso(id);
      await getAvisos();
      toast.success("Se eliminó el aviso correctamente.", alertConfiguration);
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      onCloseModal();
    }
  };

  return (
    <div className="grid gap-y-4">
      <div className="w-full text-center">
        <h1 className="text-nimbusDark font-semibold text-2xl mb-3">
          Eliminar aviso
        </h1>
      </div>
      <div className="max-w-2xl text-lg md:px-10 w-full px-4">
        <p>
          Al confirmar la acción, se eliminará de forma permanente el o los
          avisos seleccionados, y ya no se podrán consultar ni modificar
          posteriormente, y tampoco se mostrarán en los reportes. De igual
          forma, si el aviso ya fue enviado o esta programado para envío, los
          usuarios receptores ya tampoco lo podrán consultar sin importar si ya
          lo marcaron o no como leído
        </p>
      </div>
      <div className="w-full text-center max-w-2xl">
        <h1 className="text-nimbusDark font-semibold text-2xl mb-3 sm:px-20">
          ¿Está seguro que desea continuar y confirmar la eliminación?
        </h1>
      </div>
      <div className="flex justify-center gap-x-4">
        <button
          className="flex items-center justify-center bg-nimbusDark w-32 h-10 rounded-lg text-white font-semibold cursor-pointer mr-5"
          onClick={onDeleteAviso}
        >
          <span>Confirmar</span>
        </button>

        <button
          className="flex items-center justify-center bg-gray-200 w-32 h-10 rounded-lg text-nimbusDark font-semibold cursor-pointer mr-5"
          onClick={onCloseModal}
        >
          <span>Cancelar</span>
        </button>
      </div>
    </div>
  );
};

export default DeleteAviso;
