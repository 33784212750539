import React, { useState } from "react";
import CarritoProducto from "./CarritoProducto";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import { useSelector } from "react-redux";
import { selectCarrito } from "../../../../reducers/carrito/carritoSelector";
import NoDataMessage from "../../../fragments/NoDataMessage";
import { MESSAGES } from "../../../utils/messagesUtils";
import VaciarCarritoCompraModal from "./fragments/VaciarCarritoCompraModal";

const Carrito = () => {
  const productos = useSelector(selectCarrito);
  const [vaciarCarritoModal, setVaciarCarritoModal] = useState(false);

  return (
    <div className="flex flex-col gap-6">
      <div className={`overflow-auto w-full ${productos.length > 5 && "max-h-[34rem]"}`}>
        <table className="w-full table-auto font-sans border-b-2">
          <thead className="bg-gray-100 w-full sticky top-0 z-[1]">
            <tr className="text-left text-gray-500 h-12 w-full">
              <th className="pl-4" colSpan={2}>
                Artículo
              </th>
              <th className="text-center">Precio</th>
              <th className="text-center">Cantidad</th>
              <th className="text-center">Total</th>
              <th className="font-normal"></th>
            </tr>
          </thead>
          <tbody className="bg-white overflow-y-auto max-h-[6rem]">
            {productos.map((producto) => (
              <CarritoProducto key={producto._id} producto={producto} esPaquete={producto.paquete ? true : false} />
            ))}
            {productos.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <NoDataMessage message={MESSAGES.MESSAGES_ARTICULOS_CARRITO} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center gap-4">
        <CancelButton
          text={"Vaciar carrito de compra"}
          disabled={productos.length === 0}
          onClick={() => setVaciarCarritoModal(true)}
          styles="h-10 !w-full xl:!w-1/2"
        />
      </div>
      <VaciarCarritoCompraModal isOpen={vaciarCarritoModal} setIsOpen={setVaciarCarritoModal} />
    </div>
  );
};

export default Carrito;
