import { roundTwoDecimals } from "../../../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";

export const getCalificacionFinal = (rubricasCalificadas) => {
  if (rubricasCalificadas.lenght) return 0;
  const calificacion = rubricasCalificadas
    .filter((el) => typeof el.value === "number")
    .map((el) => el.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const calificacionFinal = (calificacion / rubricasCalificadas.length) * 20;
  const calificacionRedondeada = roundTwoDecimals(calificacionFinal);

  return Number(calificacionRedondeada);
};
