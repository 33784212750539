import React, { useContext, useMemo, useState } from "react";
import ResumenCompraProducto from "./ResumenCompraProducto";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCarritoContador,
  selectResumenCompra,
  selectResumenCompraContador,
  selectResumenCompraPrecioTotal,
  selectResumenCompraProductosRequest,
} from "../../../../reducers/carrito/carritoSelector";
import { formatCurrency } from "../../../utils/productoUtils";
import NoDataMessage from "../../../fragments/NoDataMessage";
import { MESSAGES } from "../../../utils/messagesUtils";
import EliminarProductosNoDisponiblesModal from "./fragments/EliminarProductosNoDisponiblesModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { postCompras } from "../../../services/Api/MarketPlace/Compras";
import { UserContext } from "../../../services/user";
import { fetchCarritoAsync } from "../../../../reducers/carrito/carritoAction";
import { PaisesContext } from "../../../../contexts/PaisesContext";

const REFERENCIA_PAGO_EFECTIVO = "123455667890iuytr";
const CLABE_INTERBANCARIA = "123456789010wertt";

const ResumenCompra = ({
  estatus,
  setEstatus,
  metodoPago,
  habilitarBotonFinalizarCompra,
  contieneErrores,
  datosEnvio,
  informacionMetodoPago,
  productosComprados,
  setProductosComprados,
}) => {
  const productos = useSelector(selectResumenCompra);
  const cantidadArticulos = useSelector(selectResumenCompraContador);
  const precioTotal = useSelector(selectResumenCompraPrecioTotal);
  const carritoContador = useSelector(selectCarritoContador);
  const resumenCompra = useSelector(selectResumenCompraProductosRequest);
  const resumenCompraContador = useSelector(selectResumenCompraContador);
  const [eliminarProductosModal, setEliminarProductosModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { paises } = useContext(PaisesContext);
  const dispatch = useDispatch();

  const handleFinalizarCompra = async () => {
    try {
      let body = {
        usuario: {
          _id: user._id,
        },
        plantel: {
          _id: user.escuela.campus._id,
        },
        datosEnvio: {
          _id: datosEnvio._id,
        },
        estatus: "ENPROCESO",
        esPagado: true,
        factura: informacionMetodoPago.factura === "true",
        subTotal: precioTotal,
        total: precioTotal,
        carrito: resumenCompra,
      };
      if (metodoPago === "EFECTIVO") {
        body.detallePago = {
          opcionDePago: metodoPago,
          nombreCompleto: informacionMetodoPago.nombreCompleto,
          correo: informacionMetodoPago.email,
          referenciaPagoEfectivo: REFERENCIA_PAGO_EFECTIVO,
        };
      } else if (metodoPago === "TRANSFERENCIA") {
        body.detallePago = {
          opcionDePago: metodoPago,
          claveInterbancaria: CLABE_INTERBANCARIA,
        };
      } else {
        body.detallePago = {
          opcionDePago: metodoPago,
          numero: informacionMetodoPago.numero,
          fechaExpiracion: informacionMetodoPago.fechaExpiracion,
          cvv: informacionMetodoPago.cvv,
        };
      }
      await postCompras(body);
      toast.success("Compra realizada exitosamente", alertConfiguration);
      setProductosComprados({
        resumenCompra: productos,
        precioTotal: precioTotal,
        cantidadArticulos: cantidadArticulos,
      });
      return "Compra exitosa";
    } catch (error) {
      console.log(error);
      toast.error(
        "Error, no se puede procesar su compra debido a que alguno de los artículos seleccionados no está disponible o ya no existe, favor de revisar el contenido de su carrito y realizar las modificaciones necesarias",
        alertConfiguration
      );
      return "Carrito";
    }
  };

  const handleClickComprarAhora = async () => {
    let nuevoEstado = "Carrito";
    if (estatus === "Compra exitosa") {
      navigate("/marketplace/misCompras");
    } else {
      if (estatus === "Carrito") {
        nuevoEstado = "DatosEnvio";
      } else if (estatus === "DatosEnvio") {
        nuevoEstado = "Pago";
      } else if (estatus === "Pago") {
        if (contieneErrores) {
          toast.warning("Complete todos los campos requeridos", alertConfiguration);
          nuevoEstado = "Pago";
        } else {
          nuevoEstado = await handleFinalizarCompra();
          dispatch(fetchCarritoAsync(false));
        }
      }
      setEstatus(nuevoEstado);
    }
  };

  const hanldeClickRegresar = () => {
    let nuevoEstado = estatus;
    if (estatus === "DatosEnvio") {
      nuevoEstado = "Carrito";
    } else if (estatus === "Pago") {
      nuevoEstado = "DatosEnvio";
    } else if (estatus === "Compra exitosa") {
      nuevoEstado = "Pago";
    }
    setEstatus(nuevoEstado);
  };

  const { simboloMoneda } = useMemo(() => {
    let paisDestino = user?.escuela?.campus?.dirFiscalPais?._id;
    return {
      simboloMoneda: paises.find((pais) => pais._id === paisDestino)?.simbolo,
    };
  }, [paises, productos])

  return (
    <div className="w-full">
      <div className={`overflow-auto w-full ${productos.length > 5 && "max-h-[34rem]"}`}>
        <table className="w-full table-auto font-sans border-b-2">
          <thead className="bg-gray-100 w-full sticky top-0">
            <tr className="text-left text-gray-500 font-semibold h-12 w-ful">
              <th className="px-4" colSpan={3}>
                Resumen de compra
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {estatus === "Compra exitosa" ? (
              <>
                {productosComprados.resumenCompra.map((producto) => (
                  <ResumenCompraProducto
                    key={producto._id}
                    producto={producto}
                    esPaquete={producto.paquete ? true : false}
                  />
                ))}
              </>
            ) : (
              <>
                {productos.map((producto) => (
                  <ResumenCompraProducto
                    key={producto._id}
                    producto={producto}
                    esPaquete={producto.paquete ? true : false}
                  />
                ))}
                {productos.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <NoDataMessage message={MESSAGES.MESSAGES_ARTICULOS_CARRITO} />
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="py-2 px-4 flex flex-col gap-4 bg-white">
        <div className="flex justify-between">
          <p>
            Subtotal ({estatus === "Compra exitosa" ? productosComprados.cantidadArticulos : cantidadArticulos}{" "}
            artículos):
          </p>
          <p className="text-gray-400">
            {estatus === "Compra exitosa"
              ? `${simboloMoneda} ${formatCurrency(productosComprados.precioTotal)}`
              : `${simboloMoneda} ${formatCurrency(precioTotal)}`}
          </p>
        </div>
        <div className="flex justify-between">
          <p>Total:</p>
          <p>
            {estatus === "Compra exitosa"
              ? `${simboloMoneda} ${formatCurrency(productosComprados.precioTotal)}`
              : `${simboloMoneda} ${formatCurrency(precioTotal)}`}
          </p>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-4">
        {estatus !== "Carrito" && estatus !== "Compra exitosa" && (
          <CancelButton text={"Regresar"} styles="h-10 !w-full" onClick={hanldeClickRegresar} />
        )}
        <SubmitButton
          text={"Seguir comprando"}
          styles="h-10 !w-full"
          bgColor="bg-sidebar"
          onClick={() => navigate("/marketplace/tienda/ProductosDestacados")}
        />
        {estatus !== "Pago" && (
          <SubmitButton
            text={`${
              estatus === "Pago"
                ? "Finalizar compra"
                : estatus === "Compra exitosa"
                ? "Ir a mis compras"
                : estatus === "DatosEnvio"
                ? "Continuar compra"
                : "Comprar ahora"
            }`}
            styles="h-10 !w-full"
            bgColor={`${
              (
                estatus === "Carrito"
                  ? carritoContador === 0
                  : estatus === "Pago"
                  ? metodoPago === "" || !habilitarBotonFinalizarCompra
                  : false
              )
                ? "bg-nimbusDark/50"
                : "bg-nimbusDark"
            }`}
            onClick={
              resumenCompraContador < carritoContador
                ? () => setEliminarProductosModal(true)
                : () => handleClickComprarAhora()
            }
            disabled={
              estatus === "Carrito"
                ? carritoContador === 0
                : estatus === "Pago"
                ? metodoPago === "" || !habilitarBotonFinalizarCompra
                : false
            }
          />
        )}
      </div>
      <EliminarProductosNoDisponiblesModal isOpen={eliminarProductosModal} setIsOpen={setEliminarProductosModal} />
    </div>
  );
};

export default ResumenCompra;
