import React from "react";
import ReactPlayer from "react-player";
import ImagenValidada from "../ActividadesUtils/RecursosValidados/ImagenValidada";
import VideoValidado from "../ActividadesUtils/RecursosValidados/VideoValidado";

function FlexSlide({ slideData, currentSlide, index }) {
  return (
    <div className="p-1">
      <div className="relative">
        {slideData.imagen ? (
          <ImagenValidada
            src={slideData.imagen}
            className="mx-auto block w-full aspect-video object-cover rounded-md mb-[10px] border h-[25rem] md:h-[39rem]"
          />
        ) : (
          ""
        )}
        {slideData.video ? (
          <div className="video_bg">
            <VideoValidado
              src={slideData.video}
              className="mx-auto block w-full aspect-video object-cover rounded-xl mb-5 border min-h-[16rem]"
            />
          </div>
        ) : null}
        {slideData.texto && (
          <div
            style={{
              background: "rgb(2,0,36)",
              background: `linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0 0 0 / 66%),rgba(0, 0, 0, 0) 100%)`,
            }}
            className=" w-full h-fit absolute right-0 bottom-0 left-0 align-bottom flex justiy-end"
          >
            <div className="text-white text-justify p-4 rounded-md text-sm custom-transition relative overflow-hidden">
              <span>{slideData?.texto}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FlexSlide;
