import React,{useState,useEffect} from 'react'
import { v4 as uuidv4 } from "uuid";
import viewIcon from '../../assets/icons/viewIcon.svg'
import trashIcon from '../../assets/icons/trash2.svg'
import addIcon from '../../assets/icons/addblue.svg'
import PDFviewer from '../utils/PDFviewer';
import {postArchivos} from "../services/api"
import { Trans } from 'react-i18next';
import i18n from '../../i18n';
import { customToast } from '../constructor/umas/ActividadesUtils/FormularioUtils';

function FormularioActividadesImagen({inputList, setInputList, viewOnly, setLoading}) {
    const [modal, setModal] = useState(false)
    const [content, setContent] = useState('')

    const toggleModal = () =>{
        setModal(!modal)
    }

    //añadir archivo
    const handleAddClick = (e) =>{
        const newElement = {archivo:e.target.files[0], id:uuidv4}
        const extensionesValidas = ['pdf','docx', 'xlsx', 'pptx', 'png', 'jpeg', 'jpg']
        if(newElement && newElement.archivo.size > 10000000){
            customToast('evSizeLimit')
        }
        else if(!extensionesValidas.includes(newElement.archivo.name.split('.').pop())){
            customToast('evFileType')
        }
        else if(newElement){
            setLoading(true)
            const fileName = newElement.archivo.name
            let data = new FormData()
            data.set('file',e.target.files[0])
            postArchivos(data)
            .then(res=>{
                setLoading(false)
                setInputList([...inputList, {archivo: res.data, titulo:fileName, _id: uuidv4()}])
            })
        }
    }

    //remover archivo
    const handleRemoveClick = (item) => {   
        const newList = inputList.filter((el)=>(el._id !== item))
        setInputList(newList)
    };

    const showContent = (content) =>{
        toggleModal()
        setContent(content.archivo)
    }

  return (
    <div className='grid grid-cols-3 gap-3 md:grid-cols-5 md:gap-y-8'>   
        {inputList.map((x, index) => {
            return (
                <div className="inline-block w-full mx-auto">
                    <div 
                      className="w-16 h-16 rounded-xl bg-[#Eff1F5] flex items-center justify-center cursor-pointer"
                      onClick={()=>showContent(x)}
                    >
                        <img
                            src={viewIcon}
                            className="w-8 h-8 hover:brightness-110"
                        />
                    </div>
                    <div className="flex items-center">

                    <div className="inline-block text-left text-sm text-ellipsis overflow-hidden w-20 h-[1.2rem] whitespace-nowrap">
                        <div className="inline text-sm mt-2">{x && x.archivo && typeof x.archivo === 'string' ? x.titulo.replace(/^.*[\\\/]/, '') : x?.archivo?.name}</div>
                    </div>
                    {viewOnly ? '' : 
                        <button
                            className="inline"
                            onClick={() => handleRemoveClick(x._id)}
                        >
                            <img src={trashIcon} alt="" className='w-4 h-4 inline-block'/>
                        </button>
                    }
                    </div>
                </div>
            );
        })}
        {viewOnly ? "" :
        <div className="inline-block cursor-pointer">
            <div className="relative w-16 h-16 rounded-xl bg-white border flex items-center justify-center cursor-pointer">
                <img
                    src={addIcon}
                    className="w-8 h-8 hover:brightness-110"
                />
                <input className='w-full h-full absolute opacity-0 z-10' type={'file'} onChange={(e)=>handleAddClick(e)}/>
            </div>
            <div className="inline-block text-sm text-ellipsis overflow-hidden w-16 text-center h-[1.2rem] whitespace-nowrap">
                <div className="inline-block text-sm mt-1"><Trans>Añadir</Trans></div>
            </div>
        </div>
        }
        <PDFviewer content={content} modal={modal} toggleModal={toggleModal}/>
    </div>
  )
}

FormularioActividadesImagen.defaultProps = {
    viewOnly:false
}

export default FormularioActividadesImagen