import React from "react";
import SubHeader from "../../../fragments/SubHeader";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../fragments/BreadCrumb";
import { useNavigate } from "react-router-dom";

const SubHeaderAsignarArticulos = ({ userRole }) => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} />
        <BreadCrumb
          route={
            userRole === "DIR_PLANTEL" || userRole === "ADMIN_OPERATIVO"
              ? "Paquetes escolares"
              : "Asignación de artículos"
          }
          active
        />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderAsignarArticulos;
