import { postArchivos } from "../services/api";

function useUploadImage(values, setValues) {
  const verifyFiles = async () => {
    let newFiles = values;
    for (const value in values) {
      if (values[value] instanceof File) {
        const fileUploaded = await uploadFile(values[value]);
        newFiles[value] = fileUploaded;
        setValues((values) => {
          return { ...values, [value]: fileUploaded };
        });
      }
    }
    return newFiles;
  };
  const uploadFile = async (file) => {
    if (file instanceof File) {
      let data = new FormData();
      data.set("file", file);
      let fileUploaded = await postArchivos(data);
      return fileUploaded.data;
    }

    return file;
  };
  const deleteImage = () => {
    setValues({
      ...values,
      imagen: "",
    });
  };

  const deleteImageArray = (values, imageId) => {
    let newValues = values.map((image) => (image._id === imageId ? { ...image, imagen: "" } : image));
    return newValues;
  };

  const verifyFilesArray = async (values) => {
    let newFiles = values;
    for (let element of values) {
      for (const value in element) {
        if (element[value] instanceof File) {
          const fileUploaded = await uploadFile(element[value]);
          newFiles[value] = fileUploaded;
        }
      }
    }
    return newFiles;
  };

  return { verifyFiles, deleteImage, deleteImageArray, verifyFilesArray, uploadFile };
}

export default useUploadImage;
