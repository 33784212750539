import React, { useContext, useEffect, useState } from "react";
import NimbusModal from "../../../fragments/NimbusModal";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import useFormSubmit from "../../../hooks/useFormSubmit";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import InputMoneda from "../../../fragments/Inputs/InputMoneda";
import useValidateForm from "../../../hooks/useValidateForm";
import { AsignarArticulosContext } from "../../../../contexts/AsignarProductosContext";
import { asignarPrecioArticulo, habilitarBotonEstatus } from "../../../../reducers/asginarArticulos/asignarArticulosAction";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { convertirMoneda } from "../../../utils/conversionMonedasUtils";
import { formatCurrency } from "../../../utils/productoUtils";

const GANANCIA_MODAL_MESSAGE = `La ganancia para este producto es menor o igual a 0.00 debido a que el precio de asignación es menor o igual al
costo, por lo tanto, le recomendamos revisar de nuevo. En caso de que así se haya requerido, hacer caso omiso y
continuar con el proceso.`;

const EditarPrecioAsignacionModal = ({ isOpen, setIsOpen, articuloInfo, tipoDeMoneda, conversionMonedas }) => {
  const { articuloListaId, costo, precioVenta, ganancia, articulo, precioAsignacion } = articuloInfo;
  const { nombre } = articulo;

  const [nuevaGanancia, setNuevaGanancia] = useState(ganancia);
  const [gananciaBajaModal, setGananciaBajaModal] = useState(false);
  const { articulos, dispatch } = useContext(AsignarArticulosContext);

  let { handleChange, errors, values, isEnableSubmitButton, setIsEnableSubmitButton, setValues } = useValidateForm({
    precioAsignacion: precioAsignacion,
  });

  const AsignarPrecioSubmit = async () => {
    if (gananciaBajaModal) {
      dispatch(asignarPrecioArticulo(articulos, articuloListaId, values.precioAsignacion));
      dispatch(habilitarBotonEstatus(true))
      setGananciaBajaModal(false);
      setIsOpen(false);
      setIsEnableSubmitButton(false)
      toast.success("Se ha actualizado el precio de asignación", alertConfiguration)
    } else {
      if (nuevaGanancia > 0) {
        dispatch(asignarPrecioArticulo(articulos, articuloListaId, values.precioAsignacion));
        dispatch(habilitarBotonEstatus(true))
        setGananciaBajaModal(false);
        setIsOpen(false);
        setIsEnableSubmitButton(false)
        toast.success("Se ha actualizado el precio de asignación", alertConfiguration)
      } else {
        setGananciaBajaModal(true);
      }
    }
  };

  const [formStatus, handleSubmit] = useFormSubmit(AsignarPrecioSubmit);

  const handleCloseModal = () => {
    if (gananciaBajaModal) setGananciaBajaModal(false);
    else {
      setValues({precioAsignacion: precioAsignacion})
      setIsEnableSubmitButton(false)
      setIsOpen(false);
    }
  };
  
  useEffect(() => {
    let calcularNuevaGanancia = values.precioAsignacion - convertirMoneda(costo, conversionMonedas);
    if (!values.precioAsignacion) calcularNuevaGanancia = 0;
    calcularNuevaGanancia = calcularNuevaGanancia.toFixed(2);
    calcularNuevaGanancia = calcularNuevaGanancia === "-0.00" ? 0.00 : parseFloat(calcularNuevaGanancia);
    setNuevaGanancia(calcularNuevaGanancia);
    if (values.precioAsignacion === "" || values.precioAsignacion === undefined || Object.values(errors).length > 0) setIsEnableSubmitButton(false);
  }, [values]);

  useEffect(() => {
    if(isOpen && !gananciaBajaModal){
      setValues({precioAsignacion: precioAsignacion})
    }
  }, [isOpen])

  return (
    <>
      <NimbusModal isOpen={isOpen}>
        <h1 className="text-nimbusDark font-bold">
          {gananciaBajaModal ? "Ganancia demasiado baja" : "Editar precio de asignación"}
        </h1>
        <p className={`px-4 sm:px-2 ${gananciaBajaModal ? "text-inherit mx-auto w-96 md:w-[29.5rem]" : "text-gray-400 w-96"}`}>
          {gananciaBajaModal ? GANANCIA_MODAL_MESSAGE : nombre}
        </p>
        <div className={`w-96 px-4 mb-6 flex flex-col gap-4  ${gananciaBajaModal ? "text-center mx-auto" : "text-left"}`}>
          {gananciaBajaModal ? (
            <p className="text-nimbusDark font-bold">¿Está seguro que desea continuar?</p>
          ) : (
            <>
              <InputMoneda label={"Costo"} disabled disabledStyles value={convertirMoneda(costo, conversionMonedas)} extraInfoLines={tipoDeMoneda} />
              <InputMoneda
                label={"Precio de venta general"}
                disabled
                disabledStyles
                value={precioVenta}
                extraInfoLines={tipoDeMoneda}
              />
              <InputMoneda
                name={"precioAsignacion"}
                label={"Precio de asignación"}
                value={values.precioAsignacion}
                onChange={(e) => handleChange(e, "precioAsignacion")}
                error={errors["precioAsignacion"]}
                extraInfoLines={tipoDeMoneda}
              />
              <InputMoneda
                label={"Ganancia"}
                styles={nuevaGanancia < 0 ? "text-red-600" : nuevaGanancia > 0 ? "text-green-600" : "text-inherit"}
                extraInfoLines={tipoDeMoneda}
                disabled
                disabledStyles
                value={nuevaGanancia}
              />
            </>
          )}
        </div>
        <div className={`flex justify-center gap-4 flex-row-reverse ${gananciaBajaModal && "flex-col items-center sm:items-stretch sm:flex-row"}`}>
          <SubmitButton
            text={gananciaBajaModal ? "Continuar con los precios ya establecidos" : "Guardar"}
            disabled={formStatus === "submitting" || !isEnableSubmitButton}
            onClick={handleSubmit}
            styles={`${gananciaBajaModal && "!w-64 p-4 !text-nimbusDark bg-divider"}`}
          />
          <CancelButton
            text={gananciaBajaModal ? "Cancelar y revisar de nuevo los precios" : "Cancelar"}
            onClick={handleCloseModal}
            styles={`${gananciaBajaModal && "!w-64 !p-4 !text-white !bg-nimbusDark"}`}
          />
        </div>
      </NimbusModal>
    </>
  );
};

export default EditarPrecioAsignacionModal;
