import React, { useState, useEffect, useRef, Children } from "react";
import {DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {v4 as uuidv4} from 'uuid'
import SimplePlayer from "./ActividadesUtils/SimplePlayer";
import {useContext } from "react";
import {ActividadContext } from "./ActividadesUtils/ActWrapper";
import {repeatedColors } from "./ActividadesUtils/ActividadesUtils";
import Lightbox from "./ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "./ActividadesUtils/Lightbox/LightboxWrapper";
import { Trans } from "react-i18next";
import ImagenValidada from "./ActividadesUtils/RecursosValidados/ImagenValidada";

function ArrastrarMatriz({f1, f2, evaluable, contenedoresCompletos, umaBloque, guardarCalificacion, instrucciones}) {
  const [iniciado, setIniciado] = useState(false)
  const [width, setWidth] = useState(null)
  const [lightboxImage, setLightboxImage] = useState('')
  const activityContext = useContext(ActividadContext)
  const actividadSubtitulos = [...contenedoresCompletos[0].respuestas ]
  const columnasLength = f2.length
  const filasLength = f2[0].respuestas.length
  const matrizColumns = f1.slice(filasLength)
  const generatedColumns = matrizColumns.map((item, index) => {
      return {
        texto: item.texto,
        imagen:item.imagen,
        audio:item.audio,
        items: [],
        _id: uuidv4(),
        orden: item.orden,
        originalId:item._id
      }
  });

  const titulos = f2.map((item)=>({texto:item.texto}))

  const allItems = matrizColumns.map((item, index)=>({...item, background:repeatedColors[index], etiqueta:index}))
  
  const mainColumn = [{
      name: "columna primera ",
      items: allItems,
      _id: "columna_principal",
      dropDisabled: false,
      orden: null
 }];

  const columnsFromBackend = [...mainColumn, ...generatedColumns];
  const [columns, setColumns] = useState(columnsFromBackend);
  const [calificado, setCalificado] = useState(false)

  const calificar = () => {
    setCalificado(true)
    const columnsToCheck = Object.entries(columns).filter((el) => {
      return el[1]._id !== "columna_principal";
    });

    const calificacion = columnsToCheck.map((item) => {
      if(item[1].items.length > 0 ){
        return item[1].items[0]._id === item[1].originalId;
      }else{
        return false
      }
    });

    setTimeout(()=>{
      if(iniciado){
        const correctasLength = calificacion.filter(Boolean).length
        activityContext.postCalificacionActividad((correctasLength/columnsToCheck.length)*100)
        guardarCalificacion(umaBloque, correctasLength , JSON.stringify(columns))
      }
      if(!iniciado)activityContext.postCalificacionActividad(null)
    },500)
  };

  const onDragEnd = (result, columns, setColumns) => {
  setIniciado(true)
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
  };

  const MyComponent = ({children}) => {
    const ref = useRef(null);
    useEffect(() => {
      if(ref.current.offsetWidth !== 0 ){
        setWidth(ref.current.offsetWidth)
      }
    }, [ref.current]);
    return <div ref={ref} className="w-full max-w-activity">{children}</div>;
  };

  const autocompletarActividad = () =>{
    const respuestasCorrectas = columnsFromBackend.map((item, index)=>{
      const respuesta  = f1.filter((el)=>(el._id === item.originalId))
      return {...item, ...{items:respuesta.map((el)=>({...el, background:repeatedColors[index]}))}}
    })
    setColumns(respuestasCorrectas)
}

  useEffect(()=>{
    if(!evaluable){
      autocompletarActividad()
    }else if(evaluable){
      setColumns(columnsFromBackend)
    }
  },[evaluable])
  
  return (
    <>
    {instrucciones && <div className="act-instructions mb-6 mt-4">{instrucciones}</div>}
    <Lightbox closeLightbox={()=>setLightboxImage('')} lightboxValue={lightboxImage}/>
    <div className={`w-full ${evaluable ? '' : 'pointer-events-none'}`}>
      <div className="flex flex-col justify-around items-baseline w-full max-w-activity mx-auto">
        <MyComponent/>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns)
            .slice(0, 1)
            .map(([columnId, column], index) => {
              return (
                <div className="w-full flex flex-col items-center" key={columnId}>
                  <div className="m-2 w-full">
                    <Droppable droppableId={columnId} key={columnId} direction="horizontal">
                      {(provided, snapshot) => {
                        return (
                            <>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{background: snapshot.isDraggingOver ? "#d1d4d9" : "#e6e9ee",gridTemplateColumns: `repeat(${columnasLength}, 1fr)`}}
                                className={`w-full transition-all border overflow-auto max-h-[9999px] min-h-[100px] rounded-xl p-2 grid mb-10 gap-2`}
                            >
                                {column.items.map((item, index) => {
                                return (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                    {(provided, snapshot) => {
                                        return (
                                        <>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                            userSelect: "none",
                                            ...provided.draggableProps.style,
                                            ...provided.draggableProps.style,
                                            transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)',
                                            }}
                                            className={`h-full`}
                                        >
                                        <div 
                                            className={`relative text-center h-full rounded-xl overflow-hidden border text-white flex flex-col items-center justify-center min-h-[85px]`}
                                            style={{background:item.background}}
                                            >
                                            <span className="text-sm flex items-center justify-center md:text-sm w-full p-4">
                                                {item.imagen? 
                                                <LightboxWrapper handleClick={()=>setLightboxImage(item.imagen)} wrapperStyles="!absolute w-full h-full">
                                                  <ImagenValidada 
                                                    className={`absolute w-full h-full bg-white aspect-video object-fill mx-auto ${item.imagen && item.texto ? 'brightness-75' : ''}`}  
                                                    src={item.imagen}
                                                  />
                                                </LightboxWrapper>
                                                 : '' }
                                                {item.texto ? <span className="z-10 text-left w-full">{item.texto}</span> : ''}
                                                {item.audio ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white absolute'} audio={item.audio}/> : ''}
                                            </span>
                                            </div>
                                        </div>
                                        </>
                                        );
                                    }}
                                    </Draggable>
                                );
                                })}
                                {provided.placeholder}
                            </div>
                            </>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          <div className={`w-full grid text-[#697d99]`} style={{gridTemplateColumns: `repeat(${columnasLength}, 1fr)`}}>
            {titulos.map((item)=>(
                <span className="block text-center p-2">{item.texto}</span>
            ))}
          </div>
          <div className="w-full grid" style={{gridAutoFlow: 'column', gridTemplateRows: `repeat(${filasLength}, 1fr)`}}>
            {actividadSubtitulos.map((item)=>(<div style={{width:width/columnasLength,maxWidth:width/columnasLength,minWidth:width/columnasLength}} className="flex items-center text-center justify-center mr-2 text-[#697d99]">{item.texto}</div>))}
            {Object.entries(columns)
              .slice(1)
              .map(([columnId, column], index) => {
                return (
                  <>
                      <div className='relative test' key={columnId} style={{width:width/columnasLength,maxWidth:width/columnasLength,minWidth:width/columnasLength}}>
                        <div className="p-1 h-full w-full">
                          <Droppable
                            isDropDisabled={column.items.length >= 1 ? true : false}
                            droppableId={columnId}
                            key={columnId}
                            className="m-[10px]"
                            direction={"horizontal"}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{background: snapshot.isDraggingOver ? "#d1d4d9" : "#e6e9ee"}}
                                  className={`rounded-xl transition-all overflow-visible min-h-[85px] h-full flex items-center justify-center border w-full`}
                                >
                                  {column.items.map((item, index) => {
                                    return (
                                      <Draggable
                                        key={item._id}
                                        draggableId={item._id}
                                        index={index}
                                        isDragDisabled={calificado}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              className={`relative h-full rounded-xl flex border text-white transition-all p-3 w-full min-h-[70px] max-w-[100%] ${snapshot.isDragging ? '' : ''}`}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{userSelect: "none",background:item.background, ...provided.draggableProps.style}}
                                            >
                                              <div className="flex justify-center items-center w-full text-sm">
                                                {item.imagen? 
                                                  <LightboxWrapper wrapperStyles={'!absolute w-full h-full'} handleClick={()=>setLightboxImage(item.imagen)}>
                                                    <ImagenValidada 
                                                      className={`absolute w-full h-full bg-white aspect-video object-fill mx-auto ${item.imagen && item.texto ? 'brightness-75' : ''}`}  
                                                      src={item.imagen}
                                                    />
                                                  </LightboxWrapper>
                                                   : '' }
                                                {item.texto ? <span className="z-10 text-left w-full">{item.texto}</span> : ''}
                                                {item.audio ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white absolute'} audio={item.audio}/> : ''}
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      </div>
                  </>
                );
              })}
          </div>
        </DragDropContext>
      </div>
      {evaluable ? 
      <div className="flex align-items-center justify-center mt-10 mb-4">
        <button 
          className="m-5 py-3 px-10 rounded-full inline" 
          onClick={() => {setColumns(columnsFromBackend);setCalificado(false)}}
        >
          <Trans>Limpiar</Trans>
        </button>
        <button
         className="border bg-nimbus text-white m-5 py-3 px-10 rounded-full inline" 
         onClick={calificar}>
          <Trans>{iniciado ? 'Enviar y cerrar' : 'Cerrar'}</Trans>
        </button>
      </div>
      :''}
    </div>
    </>
  );
}

ArrastrarMatriz.defaultProps = {
  evaluable:true,
}

export default ArrastrarMatriz;
