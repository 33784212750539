import React, { useState } from "react";
import { Trans } from "react-i18next";
function AudioValidado({ src, className, onClick }) {
  const [validada, setValidada] = useState(true);
  return (
    <>
      {validada ? (
        <audio
          //src={"zzz"}
          src={src}
          className={className}
          onClick={onClick}
          onError={() => setValidada(false)}
          controls
        />
      ) : (
        <div className="text-center font-bold text-nimbusLight !text-base p-2 max-w-[9rem] bg-white rounded-lg mx-auto m-4">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
    </>
  );
}

AudioValidado.defaultProps = {
  src: "",
  className: "",
  onClick: undefined,
};

export default AudioValidado;
