import { ReactComponent as ArrowRightButton } from "../../../../../assets/icons/arrowRightButton.svg";
import { ReactComponent as ArrowLeftButton } from "../../../../../assets/icons/arrowLeftButton.svg";
import { useEffect } from "react";

export const TablePagination = ({ page, setPage, lastPage }) => {
  return (
    <div
      className={
        "flex justify-between bg-[#F5F5F5] rounded h-14 p-1 items-center mt-8"
      }
    >
      <div className="ml-2">
        <span className='text-sm sm:"text-base'>
          {page} de {lastPage}
        </span>
      </div>
      <div className="flex items-center mr-2 space-x-2 sm:space-x-4">
        <div className="flex items-center space-x-2 border-r-2 pr-2 sm:pr-4">
          <span className='text-sm sm:"text-base sm:whitespace-nowrap'>
            Está en la página
          </span>
          <select
            value={page}
            name="alumnosP"
            id="alumnosP"
            className="rounded h-2/3 w-1/2"
            onChange={(e) => setPage(Number(e.target.value))}
          >
            {Array.from(Array(lastPage).keys()).map((item) => {
              return (
                <option key={item + 1} value={item + 1}>
                  {item + 1}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          {page === 1 ? (
            <ArrowLeftButton className="opacity-40" />
          ) : (
            <ArrowLeftButton
              className="cursor-pointer"
              onClick={() => setPage(page - 1)}
            />
          )}
          {page === lastPage ? (
            <ArrowRightButton className="opacity-40" />
          ) : (
            <ArrowRightButton
              className="cursor-pointer"
              onClick={() => setPage(page + 1)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
