import { PACKAGE_ACTION_TYPES } from "./paquetesTypes";

const INITIAL_STATE = {
  packageBooks: [],
  availableBooks: [],
  packageErrors: {}
};

export const packageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PACKAGE_ACTION_TYPES.SET_PACKAGE_BOOKS:
      return {
        ...state,
        packageBooks: payload,
      };
    case PACKAGE_ACTION_TYPES.SET_AVAILABLE_BOOKS:
      return {
        ...state,
        availableBooks: payload,
      };
    case PACKAGE_ACTION_TYPES.SET_PACKAGE_ERRORS:
      return {
        ...state,
        packageErrors: payload,
      };

    default:
      return state;
  }
};
