import React, { useCallback, useContext, useEffect, useState } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import { GANANCIA } from "../../../utils/asignarProductosUtils";
import { TIPOS_DE_CATEGORIA, TIPOS_ESTATUS, TIPOS_ORIGEN } from "../../../utils/productoUtils";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import AddButton from "../../../fragments/Buttons/AddButton";
import AgregarArticulosModal from "../AgregarArticulosModal";
import { AsignarArticulosContext } from "../../../../contexts/AsignarProductosContext";
import {
  esAgregarArticulosModalAbierto,
  habilitarBotonEstatus,
  removerArticulosLista,
  seleccionarTodosArticulosLista,
} from "../../../../reducers/asginarArticulos/asignarArticulosAction";
import { getNivelAcademico, getNivelesAcademico } from "../../../services/api";
import IconButton from "../../../fragments/Buttons/IconButton";
import { ReactComponent as RemoveWhite } from "../../../../assets/icons/removeWhite.svg";
import EliminarArticulosAsignadosModal from "./EliminarArticulosAsignadosModal";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";

const CATEGORIAS_FILTRO = [
  ...TIPOS_DE_CATEGORIA,
  {
    _id: "Paquetes",
    nombre: "Paquetes",
  },
];

const FILTRO_ARTICULOS_INITIAL_VALUES = {
  busqueda: "",
  origen: "",
  estatus: "",
  categoria: "",
  nivelEducativo: "",
  gradoAcademico: "",
  ganancia: "",
};

const AsignarProductosFilter = ({
  articulosList,
  articulosFiltro,
  setArticulosFiltro,
  accionesMasivasShow,
  setAccionesMasivasShow,
  activeRole,
}) => {
  const [nivelesEducativos, setNivelesEducativos] = useState([]);
  const [gradosAcademicos, setGradosAcademicos] = useState([]);
  const [filtro, setFiltro] = useState(FILTRO_ARTICULOS_INITIAL_VALUES);
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [checked, setChecked] = useState(false);
  const { esAgregarArticulosAbierto, dispatch } = useContext(AsignarArticulosContext);
  const [eliminarArticulosModal, setEliminarArticulosModal] = useState(false);

  const handleChecked = () => {
    setChecked((state) => !state);
    dispatch(seleccionarTodosArticulosLista(articulosList, articulosFiltro, !checked));
  };

  const removerArticulos = () => {
    dispatch(removerArticulosLista(articulosList));
    dispatch(habilitarBotonEstatus(true))
    setChecked(false)
    setEliminarArticulosModal(false)
    setAccionesMasivasShow(false);
    toast.success("Artículos removidos correctamente", alertConfiguration)
  };

  const filterDataBusqueda = (articulos, busqueda) => {
    if (busqueda === "") return articulos;

    const newArticulosFiltro = articulos.filter((articulo) =>
      articulo.articulo.nombre.toLowerCase().includes(busqueda)
    );

    return newArticulosFiltro;
  };

  const filterSelectOrigen = (origen) => {
    if (origen === "") return articulosList;
    const newArticulosFiltro = articulosList.filter((articulo) => articulo.origen === origen);

    return newArticulosFiltro;
  };

  const filterSelectNivelEducativo = (articulos, nivelEducativo) => {
    if (nivelEducativo === "") return articulos;
    const newArticulosFiltro = articulos.filter((articulo) => articulo.articulo.nivelAcademico._id === nivelEducativo);

    return newArticulosFiltro;
  };

  const filterSelectGradoAcademico = (articulos, gradoAcademico) => {
    if (gradoAcademico === "") return articulos;
    const newArticulosFiltro = articulos.filter((articulo) => articulo.articulo.gradoAcademico._id === gradoAcademico);

    return newArticulosFiltro;
  };

  const filterSelectEstatus = (articulos, estatus) => {
    if (estatus === "") return articulos;
    estatus = estatus === "true";
    const newArticulosFiltro = articulos.filter((articulo) => articulo.articulo.estatus === estatus);

    return newArticulosFiltro;
  };

  const filterSelectCategoria = (articulos, categoria) => {
    if (categoria === "") return articulos;
    const newArticulosFiltro = articulos.filter((articulo) => articulo.articulo.categoria === categoria);

    return newArticulosFiltro;
  };

  const filterSelectGanancia = (articulos, ganancia) => {
    if (ganancia === "") return articulos;
    let newArticulosFiltro = [];
    switch (ganancia) {
      case "Ninguna":
        newArticulosFiltro = articulos.filter((articulo) => articulo.ganancia === 0);
        break;
      case "Saldo positivo":
        newArticulosFiltro = articulos.filter((articulo) => articulo.ganancia > 0);
        break;
      case "Saldo negativo":
        newArticulosFiltro = articulos.filter((articulo) => articulo.ganancia < 0);
        break;
      default:
        break;
    }

    return newArticulosFiltro;
  };

  const getNivelesEducativos = useCallback(async () => {
    const nivelesAcademicosResponse = await getNivelesAcademico();
    setNivelesEducativos(nivelesAcademicosResponse.data);
  }, []);

  useEffect(() => {
    let newArticulosFiltro = filterSelectOrigen(filtro.origen);
    newArticulosFiltro = filterSelectNivelEducativo(newArticulosFiltro, filtro.nivelEducativo);
    newArticulosFiltro = filterSelectGradoAcademico(newArticulosFiltro, filtro.gradoAcademico);
    newArticulosFiltro = filterSelectEstatus(newArticulosFiltro, filtro.estatus);
    newArticulosFiltro = filterSelectCategoria(newArticulosFiltro, filtro.categoria);
    newArticulosFiltro = filterSelectGanancia(newArticulosFiltro, filtro.ganancia);
    newArticulosFiltro = filterDataBusqueda(newArticulosFiltro, filtro.busqueda.toLowerCase());
    const enableButton = articulosList.some((producto) => producto.esSeleccionado);
    setArticulosFiltro(newArticulosFiltro);
    setHabilitarBoton(enableButton);
  }, [filtro, articulosList]);

  useEffect(() => {
    if (filtro.nivelEducativo !== "") {
      getNivelAcademico(filtro.nivelEducativo).then((valores) => {
        setGradosAcademicos(valores.data.gradosAcademicos);
      });
    } else {
      setGradosAcademicos([]);
    }
    setFiltro({ ...filtro, gradoAcademico: "" });
  }, [filtro.nivelEducativo]);

  useEffect(() => {
    getNivelesEducativos();
  }, [getNivelesEducativos]);

  return (
    <div className="flex flex-col">
      <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
        <div className="flex gap-x-5">
          <div className="flex flex-wrap items-center">
            <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
              {articulosFiltro.length}
            </p>
            <p className="text-black">Artículos asignados</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 items-end lg:items-start">
          {activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO" ? (
            <>
              <div className="w-[28rem] h-[40px] relative rounded-md border-[1px] border-gray-300 bg-white flex gap-3 items-center px-4 py-6 md:py-2 flex-1">
                <input
                  type="checkbox"
                  name={`selectArticulosLista`}
                  id={`selectArticulosLista`}
                  className="rounded-md border-2 border-gray-300  my-0"
                  checked={checked}
                  onChange={handleChecked}
                />
                <label
                  htmlFor={`selectArticulosLista`}
                  className="cursor-pointer select-none absolute w-full h-full flex gap-6 items-center -translate-x-4 pl-12"
                >
                  <p className="text-sm md:text-base text-left">
                    Seleccionar todos los artículos mostrados en pantalla
                  </p>
                </label>
              </div>
              <IconButton
                text={"Quitar artículos"}
                styles="bg-sidebar"
                disabled={!habilitarBoton}
                Icon={RemoveWhite}
                onClick={() => setEliminarArticulosModal(true)}
              />
            </>
          ) : (
            <>
              <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
                <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} value={filtro.busqueda}/>
              </div>
              <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
                <AddButton
                  text="Acciones masivas"
                  styles="bg-sidebar"
                  disabled={accionesMasivasShow}
                  onClick={setAccionesMasivasShow}
                />
              </div>
            </>
          )}
          <div className="flex-shrink-0 mt-3 lg:mt-0 w-full sm:w-80 lg:w-fit">
            <AddButton text="Asignar artículos" onClick={() => dispatch(esAgregarArticulosModalAbierto(true))} />
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-4">
        <InputSelect
          label="Categoría"
          options={CATEGORIAS_FILTRO}
          topLabel={true}
          styles="!w-full"
          onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
        />
        <InputSelect
          label="Origen"
          options={TIPOS_ORIGEN}
          topLabel={true}
          styles="!w-full"
          onChange={(e) => setFiltro({ ...filtro, origen: e.target.value })}
        />
        <InputSelect
          label="Estatus"
          options={TIPOS_ESTATUS}
          topLabel={true}
          styles="!w-full"
          onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
        />
        <InputSelect
          label="Nivel educativo"
          options={nivelesEducativos}
          topLabel={true}
          styles="!w-full"
          onChange={(e) => setFiltro({ ...filtro, nivelEducativo: e.target.value })}
        />
        <InputSelect
          label="Grado académico"
          options={gradosAcademicos}
          topLabel={true}
          styles="!w-full"
          onChange={(e) => setFiltro({ ...filtro, gradoAcademico: e.target.value })}
        />
        {activeRole === "DIR_PLANTEL" || activeRole === "ADMIN_OPERATIVO" ? (
          <InputBuscar onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })} value={filtro.busqueda}/>
        ) : (
          <InputSelect
            label="Ganancia"
            options={GANANCIA}
            topLabel={true}
            styles="!w-full"
            onChange={(e) => setFiltro({ ...filtro, ganancia: e.target.value })}
          />
        )}
      </div>
      <AgregarArticulosModal
        isOpen={esAgregarArticulosAbierto}
        setIsOpen={(value) => dispatch(esAgregarArticulosModalAbierto(value))}
      />
      <EliminarArticulosAsignadosModal
        isOpen={eliminarArticulosModal}
        setIsOpen={setEliminarArticulosModal}
        removerArticulos={removerArticulos}
      />
    </div>
  );
};

export default AsignarProductosFilter;
