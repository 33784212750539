import React from "react";
import VariacionesRow from "./VariacionesRow";

const VariacionesTable = ({ variables, variaciones, handleDeleteVariacion, handleActualizarValorVariable, ver, obtenerVariaciones }) => {

  return (
    <div className="overflow-x-auto mt-4">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            {[...Array(variables.length).keys()].map((variable) => (
              <th key={variable}></th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {variaciones.map((variacion, idx) => (
            <VariacionesRow
              key={variacion.variacionId}
              variacionIndex={idx}
              variacionInfo={variacion}
              handleDeleteVariacion={handleDeleteVariacion}
              variables={variables}
              ver={ver}
              handleActualizarValorVariable={handleActualizarValorVariable}
              obtenerVariaciones={obtenerVariaciones}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VariacionesTable;
