import { Route, Routes } from "react-router-dom";
import ListaDifusionDetalles from "../../../components/Herramientas/listasDifusion/ListaDifusionDetalles/ListaDifusionDetalles";
import ListasDeDifusionHome from "../../../components/Herramientas/listasDifusion/ListasDifusionHome";

const ListasDifusionRoutes = () => {
  return (
    <Routes>
      <Route index element={<ListasDeDifusionHome />} />
      <Route path="agregarListaDifusion" element={<ListaDifusionDetalles />} />
      <Route
        path=":listaDifusionID/ver"
        element={<ListaDifusionDetalles ver />}
      />
      <Route
        path=":listaDifusionID/editar"
        element={<ListaDifusionDetalles editar />}
      />
    </Routes>
  );
};

export default ListasDifusionRoutes;
