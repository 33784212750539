import { createSelector } from "reselect";

const selectAvisoDataReducer = (state) => state.avisoData;

export const selectAvisoData = createSelector(
  [selectAvisoDataReducer],
  (aviso) => aviso
);

export const selectCalendar = createSelector(
  [selectAvisoDataReducer],
  (aviso) => aviso.calendario
);

export const selectContent = createSelector(
  [selectAvisoDataReducer],
  (aviso) => aviso.contenido
);

export const selectProgress = createSelector(
  [selectAvisoDataReducer],
  (aviso) => aviso.saveProgress
);

export const selectLists = createSelector(
  [selectAvisoDataReducer],
  (aviso) => aviso.destinatarios
);

export const selectSendDate = createSelector(
  [selectAvisoDataReducer],
  (aviso) => aviso.envio
);
