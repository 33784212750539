import React, { useContext, useEffect } from 'react';
import ResumenPedidoRow from './ResumenPedidoRow';
import { UserContext } from '../../../services/user';

const ResumenPedidoTable = ({ articulosList }) => {
  const user = useContext(UserContext);
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th></th>
            <th></th>
            <th></th>
            {(user.activeRole === 'DIR_PLANTEL' ||
              user.activeRole === 'ADMIN_OPERATIVO') && (
              <>
                <th></th>
                <th className='w-[16rem]'></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {articulosList !== undefined &&
            (user.activeRole === 'DIR_PLANTEL' ||
            user.activeRole === 'ADMIN_OPERATIVO'
              ? articulosList.map((pedido) =>
                  pedido.articulos.map((venta) => (
                    <ResumenPedidoRow
                      key={venta._id}
                      ventaInfo={venta}
                      estatus={pedido.estatus}
                    />
                  ))
                )
              : articulosList.map((venta) => (
                  <ResumenPedidoRow key={venta._id} ventaInfo={venta} />
                )))}
        </tbody>
      </table>
    </div>
  );
};

export default ResumenPedidoTable;
