import React from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../../../fragments/SubHeader";
import BreadCrumbContainer from "../../../../fragments/BreadCrumbContainer";
import BreadCrumb from "../../../../fragments/BreadCrumb";

const SubHeaderVariacionesProducto = ({ nombreProducto }) => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <div>
        <BreadCrumbContainer>
          <BreadCrumb route={"Productos /"} onClick={() => navigate(`/marketplace/gestionProductos/productos`)} />
          <BreadCrumb route={"Variaciones"} active />
        </BreadCrumbContainer>
        <div className="flex text-base md:text-lg text-transparent bg-clip-text bg-gradient-to-r from-[#18BAFB] to-[#3D90E1] items-center">
          {nombreProducto}
        </div>
      </div>
    </SubHeader>
  );
};

export default SubHeaderVariacionesProducto;
