import React, { useEffect, useState } from "react";
import InputText from "../../../fragments/Inputs/InputText";
import InputNumber from "../../../fragments/Inputs/InputNumber";
import InputMoneda from "../../../fragments/Inputs/InputMoneda";

const FactorConversionForm = ({ conversionMonedas, handleChange, errors, monedas, incrementValue, decrementValue }) => {
  let { monedaNacional, monedaDeIntercambio, factorDeConversion } = conversionMonedas;

  const [monedasConversion, setMonedasConversion] = useState({
    monedaNacional: "",
    monedaDeIntercambio: "",
  });

  useEffect(() => {
    if (monedas.length !== 0 && monedaNacional && monedaDeIntercambio) {
      let newMonedaNacional = monedas.find((moneda) => moneda._id === monedaNacional).nombre;
      let newmonedaDeIntercambio = monedas.find((moneda) => moneda._id === monedaDeIntercambio).nombre;

      let newMonedasConversion = {
        monedaNacional: newMonedaNacional,
        monedaDeIntercambio: newmonedaDeIntercambio,
      };
      setMonedasConversion(newMonedasConversion);
    }
  }, [monedaNacional, monedaDeIntercambio, monedas]);

  return (
    <div className="flex flex-col items-center xl:items-start xl:grid xl:grid-cols-10 gap-6 mt-6 px-6 xl:px-0 xl:pl-6 xl:place-content-center">
      <div className="w-full sm:w-1/2 xl:w-full col-span-3 relative">
        <p className="absolute bottom-2 -translate-x-6 font-bold">1</p>
        <InputText
          label={"Moneda de intercambio internacional"}
          styles="!w-full "
          value={monedasConversion.monedaDeIntercambio ?? ""}
          disabled
        />
      </div>
      <p className="text-center xl:mt-10 font-bold">es igual a</p>
      <div className="w-full sm:w-1/2 xl:w-full col-span-3">
        <InputMoneda
          name={"factorDeConversion"}
          label={"Factor de conversión"}
          placeholder={"Ingrese un valor"}
          styles="!w-full"
          value={factorDeConversion ?? ""}
          onChange={(e) => handleChange(e, "factorDeConversion")}
          error={errors["factorDeConversion"]}
          disabled={monedaNacional && monedaNacional === "643ffac9cda25824d0453911"}
          enableStep
          incrementValue={incrementValue}
          decrementValue={decrementValue}
        />
      </div>
      <div className="w-full sm:w-1/2 xl:w-full col-span-3">
        <InputText label={"Moneda nacional"} styles="!w-full" value={monedasConversion.monedaNacional ?? ""} disabled />
      </div>
    </div>
  );
};

export default FactorConversionForm;
