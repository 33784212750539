import React, { useContext } from 'react';
import InputText from '../../../fragments/Inputs/InputText';
import InputFileCard from '../../../fragments/Inputs/InputFileCard';
import { PaisesContext } from '../../../../contexts/PaisesContext';
import { useParams } from 'react-router-dom';

const AgregarPaisForm = ({
  pais,
  errors,
  handleChange,
  setPais,
  setErrors,
  addError,
  deleteImage,
  ver,
  editar,
  puedeEditar,
}) => {
  const {
    nombrePais,
    nombreMoneda,
    abreviacionMoneda,
    simboloMoneda,
    valorMoneda,
    imagen,
  } = pais;

  const { paises } = useContext(PaisesContext);
  const params = useParams();

  const validarNombrePais = (e, paisNuevo) => {
    let paisRepetido = paises.find(
      (pais) => pais.nombre.toUpperCase() === paisNuevo.toUpperCase()
    );
    handleChange(e, 'nombrePais');
    if (paisRepetido !== undefined && paisRepetido._id !== params.paisId) {
      addError('nombrePais', 'El nombre del país ya existe');
    }
  };

  return (
    <form>
      <div className='grid grid-cols-1 md:flex gap-x-8 sm:gap-x-16 lg:gap-x-20'>
        <InputFileCard
          name={'imagen'}
          id={'imagen'}
          styles='mt-0 mb-10 col-span-2 mx-auto lg:mx-0'
          imageStyles={'!rounded-md'}
          img={imagen}
          onChange={(e) => handleChange(e, 'imagen')}
          error={errors['imagen']}
          errorStyles={'!ml-[12%]'}
          deleteImage={deleteImage}
          allowMultipleExtensions
          accept='.jpg, .png'
          disabled={ver || !puedeEditar}
        />
        <div className='flex-col space-y-8 justify-around flex-1 !ml-0'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 '>
            <InputText
              id={'nombrePais'}
              label={'País'}
              name={'nombrePais'}
              placeholder={'Ingrese el nombre del país'}
              styles={`!w-full ${((ver || editar) && nombrePais.length > 0) && '!capitalize'}`}
              value={nombrePais}
              onChange={(e) => validarNombrePais(e, e.target.value)}
              error={errors['nombrePais']}
              disabled={ver || !puedeEditar}
            />
            <InputText
              id={'nombreMoneda'}
              label={'Moneda nacional'}
              name={'nombreMoneda'}
              placeholder={'Ingrese el nombre de la moneda nacional'}
              styles={`!w-full`}
              value={nombreMoneda}
              onChange={(e) => handleChange(e, 'nombreMoneda')}
              error={errors['nombreMoneda']}
              disabled={ver || !puedeEditar}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16'>
            <InputText
              id={'abreviacionMoneda'}
              label={'Abreviación de la moneda nacional'}
              name={'abreviacionMoneda'}
              maxLength={40}
              placeholder={'Ingrese la abreviación de la moneda nacional'}
              styles='!w-full'
              value={abreviacionMoneda}
              onChange={(e) => handleChange(e, 'abreviacionMoneda')}
              error={errors['abreviacionMoneda']}
              disabled={ver || !puedeEditar}
            />
            <InputText
              id={'simboloMoneda'}
              label={'Símbolo de la moneda nacional'}
              name={'simboloMoneda'}
              placeholder={'Ingrese el símbolo de la moneda nacional'}
              styles='!w-full'
              value={simboloMoneda}
              onChange={(e) => handleChange(e, 'simboloMoneda')}
              error={errors['simboloMoneda']}
              disabled={ver || !puedeEditar}
            />
          </div>
        </div>
      </div>
      <p className='font-semibold text-base text-[#0D0C22]'>Tipo de cambio</p>
      <p className='text-base text-gray-500 my-5'>
        Ingrese el tipo de cambio respecto al dólar, el
        cual, corresponde a la moneda de intercambio internacional.
      </p>
      <div className='w-full md:w-[22rem] pr-8 lg:pr-0'>
        <InputText
          id={'valorMoneda'}
          name={'valorMoneda'}
          placeholder={'Ingrese el valor de la moneda nacional'}
          styles='!w-full'
          value={ver ? valorMoneda?.toLocaleString(undefined, { maximumFractionDigits: 5 }) : valorMoneda}
          onChange={(e) => handleChange(e, 'valorMoneda')}
          error={errors['valorMoneda']}
          disabled={ver}          
        />
      </div>
    </form>
  );
};

export default AgregarPaisForm;
