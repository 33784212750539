import { useAddUsers } from "./AddUsersContext";
import { useEffect, useState } from "react";

const AddUsersSelectItems = ({ currentUsers }) => {
  const { selectAllUsers, destinatarios } = useAddUsers();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const selectedUsers = currentUsers.filter((user) => user.esSeleccionado);

  useEffect(() => {
    const allSelected =
      currentUsers.length > 0 &&
      currentUsers.every((user) => user.esSeleccionado);
    setIsSelectedAll(allSelected);
  }, [currentUsers]);

  const handleChecked = () => {
    setIsSelectedAll((state) => !state);
    selectAllUsers(!isSelectedAll, currentUsers);
  };

  return (
    <div className="w-full border-solid border-2 rounded-lg p-1 flex flex-row justify-center gap-5 bg-white">
      <div className="rounded-md flex flex-row items-center gap-2">
        <input
          type="checkbox"
          name={`selectAllCheckbox`}
          id={`selectAllCheckbox`}
          className="rounded-md border-2 border-gray-300 my-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
          checked={isSelectedAll}
          onChange={handleChecked}
          disabled={destinatarios?.length === 0}
        />
        <label>
          <p className="text-sm md:text-base text-left font-semibold">Todos</p>
        </label>
      </div>
    </div>
  );
};

export default AddUsersSelectItems;
