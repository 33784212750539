import React from 'react';
import { ReactComponent as FacturacionCircleIcon } from '../../../../assets/icons/FacturacionCircleIcon.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/icons/view.svg';
import { useNavigate } from 'react-router-dom';

const FacturacionRow = ({ facturacionInfo }) => {
  const navigate = useNavigate();
  const { _id, compra, estatus } = facturacionInfo;
  return (
    <tr className='p-2 text-left bg-white'>
      <td className='border-2 rounded-l-lg border-r-0 text-left font-sans'>
        <FacturacionCircleIcon className='mx-auto' color='white' />
      </td>
      <td className='border-y-2 font-sans text-left'>{compra}</td>
      <td className='border-y-2 font-sans text-left'>{estatus}</td>
      <td className='border-2 rounded-r-md border-l-0 min-h-[52px] text-left font-sans'>
        <ViewIcon className='mx-auto' />
      </td>
    </tr>
  );
};

export default FacturacionRow;
