import React from "react";
import SubHeader from "../../../fragments/SubHeader";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";

const SubHeaderProcesoCompra = ({ estatus, setEstatus }) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <SubHeader styles="bg-transparent !px-0 min-h-fit">
      <BreadCrumbContainer>
        <BreadCrumb route={"Inicio /"} onClick={() => navigate(`/home`)} styles="text-black/70 !md:text-lg" />
        {estatus === "Compra exitosa" ? (
          <BreadCrumb route={`Compra exitosa`} styles="text-black !md:text-lg break-words" active />
        ) : (
          <>
            <BreadCrumb
              route={`${estatus === "Carrito" ? "Carrito de compra" : "Carrito de compra /"}`}
              styles={`!md:text-lg break-words ${estatus === "Carrito" ? "text-black " : "text-black/70"}`}
              active={estatus === "Carrito"}
              onClick={estatus !== "Carrito" ? () => setEstatus("Carrito") : null}
            />
            {estatus !== "Carrito" && (
              <BreadCrumb
                route={`${estatus === "DatosEnvio" ? "Datos de envío" : "Datos de envío /"}`}
                styles={`!md:text-lg break-words ${estatus === "DatosEnvio" ? "text-black " : "text-black/70"}`}
                active={estatus === "DatosEnvio"}
                onClick={estatus !== "DatosEnvio" ? () => setEstatus("DatosEnvio") : null}
              />
            )}
            {estatus !== "Carrito" && estatus !== "DatosEnvio" && (
              <BreadCrumb
                route={`Pagar`}
                styles="text-black !md:text-lg break-words"
                active
                onClick={estatus !== "Pago" ? () => setEstatus("Pago") : null}
              />
            )}
          </>
        )}
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderProcesoCompra;
