import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    ES: {
      translations: {
        //generales en actividades
        saving: "Guardando...",
        comenzarButton: "¡Comenzar!",
        score: "Puntaje",
        attemptsNumber: "Número de intentos:",
        highestScore: "Puntaje más alto:",
        lastScore: "Puntaje obtenido en el último intento:",
        insignsLabel: "Obtuviste las siguientes insignias",
        tryAgainLabel: "Intentar de nuevo",
        timeLabel: "Tiempo",
        activityLabel: "Actividad finalizada",
        slideOf: "de",
        Cerrar: "Cerrar",
        Calificar: "Calificar",
        Limpiar: "Limpiar",
        closeBook: "CERRAR LIBRO",
        //act preguntas
        preguntasTitle: "Preguntas",
        preguntasDesc: "Contesta de manera correcta las siguientes preguntas.",
        //act preguntas abiertas
        preguntasAbiertasTitle: "Preguntas Abiertas",
        preguntasAbiertasDesc: "Contesta las siguientes preguntas.",
        preguntasValidation: "Asegúrate de contestar todas las preguntas.",
        //relacionar
        relacionarTitle: "Relacionar",
        relacionarDesc:
          "Arrastre y suelte cada palabra clave junto a su definición correcta.",
        aColumnLabel: "Columna a",
        bColumnLabel: "Columna b",
        //ordenar
        ordenarTitle: "Ordenar",
        ordenarDesc: "Ordena las palabras correctamente.",
        ///rompecabezas
        puzzleclue: "Pista",
        rompecabezasTitle: "Rompecabezas",
        rompecabezasDesc: "Completa el rompecabezas.",
        "Ver imagen": "Ver imagen",
        Obtuviste: "Obtuviste",
        "aciertos de": "aciertos de",
        totales: "totales",
        "Nota: La visualización de la imagen afecta tu calificación final":
          "Nota: La visualización de la imagen afecta tu calificación final",
        //memorama
        memoramaTitle: "Memorama",
        memoramaDesc:
          "Recuerda las posiciones de todas las parejas de tarjetas.",
        //arrastrar
        arrastrarTitle: "Arrastrar",
        arrastrarDesc: "Arrastra los items dentro del contenedor correcto.",
        //sopa
        sopaTitle: "Sopa de letras",
        sopaDesc:
          "Encuentra las palabras de la lista y márcalas presionando la primera letra y arrastrar y soltar hasta la última letra de la palabra.",
        sopaMobileDesc:
          "Encuentra las palabras de la lista y márcalas presionando la primera letra y la última de la palabra.",
        //crucigrama
        crucigramaTitle: "Crucigrama",
        crucigramaDesc: "Encuentra todas las palabras con ayuda de las pistas.",
        //hotspot
        hotspotTitle: "HOTSPOT",
        hotspotDesc: "Selecciona las zonas correctas",
        instruccionesLabel: "Instrucciones",
        foundLabel: "Encontrados",
        errorsLabel: "Errores",
        //hotspot multiple
        hotspotMultipleTitle: "Hotspot multiple",
        hotspotMultipleDesc:
          "Arrastra todos los elementos hacia su respectiva zona",
        hotspotMultipleMobileDesc:
          "Coloca todos los elementos en su respectiva zona",
        //encontrar diferencias
        encontrarTitle: "Encontrar diferencias",
        encontrarDesc: "Encuentra las diferencias entre dos imágenes",
        //dibujar
        dibujarTitle: "Dibujo y coloreado",
        dibujarDesc: "Colorea o dibuja lo que se pide",
        drawingNameLabel: "Ingresa un nombre para tu dibujo",
        //expresion(palabras)
        lecturaPalabrasTitle: "Lectura",
        lecturaPalabrasDesc:
          "Prepara tu micrófono, selecciona la palabra u oración y pronúnciala.",
        retryLabel: "Parar",
        stopLabel: "Siguiente",
        listeningLabel: "Escuchando...",
        recordLabel: "Click para comenzar a grabar",
        verifyLabel: "Parar y verificar",
        nextLabel: "Parar y siguiente",
        myRecordingLabel: "Mi oración",
        sentenceLabel: "Oración:",
        wordLabel: "Palabra/Oración",
        correctWordLabel: "Acierto(s)",
        //expresion(pronunciacion)
        lecturaPronunciacionTitle: "Lectura de párrafo (pronunciación)",
        lecturaPronunciacionDesc:
          "Prepara tu micrófono, pulsa el botón iniciar y lee el texto en voz alta, el sistema calificará tu pronunciación, no la rapidez con que lo hagas. Una vez que termines de dictar, pulsa el botón verificar.",
        //expresion(velocidad)
        lecturaVelocidadTitle: "Lectura de párrafo (velocidad)",
        lecturaVelocidadDesc:
          "Prepara tu micrófono,  pulsa el botón iniciar y lee el texto en voz alta lo más rápido que puedas, el sistema te irá calificando cada palabra. Si te atoras en una palabra intenta pronunciarla hasta que logres pasar, entre más rápido lo hagas mejor será tu promedio de velocidad de lectura.",
        "Velocidad de lectura": "Velocidad de lectura",
        "palabras por minuto": "palabras por minuto",
        //dictados
        dictadosTitle: "Dictados",
        dictadosDesc:
          "Selecciona el audio que vas a escuchar, escribe lo que escuches y espera a que el sistema lo califique. Se te mostrará en rojo las diferencias entre el texto del audio original y el texto que escribiste. Revisa bien tu ortografía y gramática.",
        Siguiente: "Siguiente",
        "Enviar calificación": "Enviar calificación",
        "Escucha y escribe dentro de los recuadros":
          "Escucha y escribe dentro de los recuadros",
        "Escucha y escribe dentro del recuadro":
          "Escucha y escribe dentro del recuadro",
        "Compara tus respuestas": "Compara tus respuestas",
        Correcta: "Correcta",
        "respuestas correctas": "respuestas correctas",
        //lee y escucha
        escuchaTitle: "Texto a voz",
        escuchaDesc: "Pulsa en el audio que deseas escuchar",
        Pausar: "Pausar",
        Repetir: "Repetir",
        Resumir: "Resumir",
        Escuchar: "Escuchar",
        //contenedor de recursos
        recursosHeading: "Archivos incluidos en este bloque:",
        recursosDownload: "Descargar archivos",
        recursosInfo:
          "Los archivos se descargarán en la carpeta que tengas por default de descarga.",
        recursosLink: "¿Cómo descomprimir un archivo en formato zip?",
        //pdf
        pdfLabel: "Mostrar PDF",
        cerrarLabel: "Cerrar",
        //carousel
        slide: "diapositiva",
        carouselShowMore: "Mostrar más",
        carouselShowLess: "Mostrar menos",
        //evidencias
        Tarea: "Tarea",
        Título: "Título",
        Descripción: "Descripción",
        Criterios: "Criterios",
        Calificación: "Calificación",
        Observaciones: "Observaciones",
        "Archivos cargados": "Archivos cargados",
        "Añadir enlaces web": "Añadir enlaces web",
        "Añadir comentarios": "Añadir comentarios",
        Estatus: "Estatus",
        "Fecha de envio": "Fecha de envio",
        Sumatoria: "Sumatoria",
        "Enviar tarea": "Enviar tarea",
        "Cancelar envío": "Cancelar envío",
        "Guardar cambios": "Guardar cambios",
        "Ver tarea": "Ver",
        "Estatus de envío:": "Estatus de envío",
        Evidencia: "Evidencia",
        Evidencias: "Evidencias",
        Resultados: "Resultados",
        Rúbrica: "Rúbrica",
        Añadir: "Añadir",
        PENDIENTE: "PENDIENTE",
        ENVIADA: "ENVIADA",
        RECHAZADA: "RECHAZADA",
        CALIFICADA: "CALIFICADA",
        "Indica todos los puntos que se tomarán en cuenta para evaluar la tarea":
          "Indica todos los puntos que se tomarán en cuenta para evaluar la tarea",
        "El límite de los archivos subidos es de 10MB en formato png, jpg, pdf, docx, xlsx o pptx.Los archivos de tipo video deben ser referenciado a través de un enlace":
          "El límite de los archivos subidos es de 10MB en formato png, jpg, pdf, docx, xlsx o pptx.Los archivos de tipo video deben ser referenciado a través de un enlace",
        "La calificación es calculada con base al promedio de todos los puntos seleccionados en la rúbrica":
          "La calificación es calculada con base al promedio de todos los puntos seleccionados en la rúbrica",
        //evaluaciones
        instructionsLabel: "Instrucciones",
        step1:
          "Paso 1. Para iniciar la evaluación deberás colocar el código de acceso que te proporcione tu profesor. No podrás iniciar la evaluación hasta no tener un código correcto.",
        step2:
          "Paso 2. Revisa el tiempo que tienes para realizar la evaluación ya que cuando el tiempo se termine, la evaluación se enviará de forma automática y se cerrará. Puedes revisar dentro de la evaluación el tiempo que te queda indicado en minutos y segundos.",
        step3:
          "Paso 3.Revisa el número de intentos que tienes para contestar la evaluación, este dato se encuetra al final de las instrucciones. Si vuelves a intentar , tendrás que solicitar un nuevo código y el resultado de la evaluación será el del último intento.",
        step4:
          "Paso 4. Si finalizas antes de que se termine el tiempo, verifica que hayas guardado o enviado todas las respuestas y da clic en el botón “Finalizar Evaluación” que se encuentra al final.",
        step5:
          "Paso 5.Si pierdes la conexión a internet, cierras el navegador o cierras la evaluación no requieres de un nuevo código para continuar. Los ejercicios que hayas finalizado se conservarán y el tiempo que te queda será el mismo que cuando cerraste.",
        step6:
          'Cuando estés listo, coloca el código y pulsa el botón "Iniciar evaluación".',
        evaluationMinutesLabel: "min",
        usedCodesLabel: "Códigos usados:",
        previousScoresLabel: "Calificaciones previas:",
        evaluationLastScore: "calificacion del último intento",
        detectedEvaluationLabel:
          "Se ha detectado una evaluación que no fue completada. Termina tu evaluación para usar un nuevo código.",
        timeUpLabel:
          "El tiempo establecido para terminar esta evaluación se ha terminado. En cuanto cierres esta ventana se mandará la evaluación al módulo calificar.",
        isDoneLabel: "¿Aun así deseas terminar?",
        evaluationAnsweredLabel: "reactivos contestados correctamente",
        evaluationScoreLabel: "Tu calificación es",
        yes: "Sí",
        no: "No",
        endEvaluationButton: "Finalizar evaluación",
        writeCodeLabel: "Ingresa aquí tu código de acceso",
        attemptLabel: "Intento",
        "Ver códigos de acceso": "Ver códigos de acceso",
        "Comparte estos códigos con tus alumnos para que puedan iniciar la evaluación":
          "Comparte estos códigos con tus alumnos para que puedan iniciar la evaluación",
        "Ingresa un código de acceso.": "Ingresa un código de acceso.",
        "El autor de esta evaluación seleccionó que no se muestren los resultados, para conocer tu calificación consulta con tu profesor.":
          "El autor de esta evaluación seleccionó que no se muestren los resultados, para conocer tu calificación consulta con tu profesor.",
        "No todos los reactivos han sido contestados, revisa que no te falte alguno antes de terminar.":
          "No todos los reactivos han sido contestados, revisa que no te falte alguno antes de terminar.",
        "Estás eligiendo terminar la evaluación, lo cual mandará tus resultados al módulo calificar y ya no podrás corregir reactivos. Si aún tienes tiempo, revisa tus respuestas y cuando estés seguro presiona en confirmar.":
          "Estás eligiendo terminar la evaluación, lo cual mandará tus resultados al módulo calificar y ya no podrás corregir reactivos. Si aún tienes tiempo, revisa tus respuestas y cuando estés seguro presiona en confirmar.",
        "Ver evaluación": "Ver evaluación",
        "Iniciar evaluación": "Iniciar Evaluación",
        "Continuar evaluación": "Continuar evaluación",
        "El código de acceso no es válido. Asegúrate de haber ingresado el código correcto.":
          "El código de acceso no es válido. Asegúrate de haber ingresado el código correcto.",
        //Examenes
        step1Test:
          "Paso 1. Para iniciar el examen deberás colocar el código de acceso que te proporcione tu profesor. No podrás iniciar el examen hasta no tener un código correcto.",
        step2Test:
          "Paso 2. Revisa el tiempo que tienes para realizar el examen ya que cuando el tiempo se termine, el examen se enviará de forma automática y se cerrará. Puedes revisar dentro del examen el tiempo que te queda indicado en minutos y segundos.",
        step3Test:
          "Paso 3.Revisa el número de intentos que tienes para contestar el examen, este dato se encuetra al final de las instrucciones. Si vuelves a intentar, tendrás que solicitar un nuevo código y el resultado del examen será el del último intento.",
        step4Test:
          "Paso 4. Si finalizas antes de que se termine el tiempo, verifica que hayas guardado o enviado todas las respuestas y da clic en el botón “Finalizar examen que se encuentra al final.",
        step5Test:
          "Paso 5.Si pierdes la conexión a internet, cierras el navegador o cierras el examen no requieres de un nuevo código para continuar. Los ejercicios que hayas finalizado se conservarán y el tiempo que te queda será el mismo que cuando cerraste.",
        step6Test:
          'Cuando estés listo, coloca el código y pulsa el botón "Iniciar examen".',
        detectedEvaluationLabelTest:
          "Se ha detectado un examen que no fue completado. Termina tu examen para usar un nuevo código.",
        timeUpLabelTest:
          "El tiempo establecido para terminar este examen se ha terminado. En cuanto cierres esta ventana se mandará el examen al módulo calificar.",
        endEvaluationButtonTest: "Finalizar examen",
        "Comparte estos códigos con tus alumnos para que puedan iniciar el examen":
          "Comparte estos códigos con tus alumnos para que puedan iniciar el examen",
        "Estás eligiendo terminar el examen, lo cual mandará tus resultados al módulo calificar y ya no podrás corregir reactivos. Si aún tienes tiempo, revisa tus respuestas y cuando estés seguro presiona en confirmar.": `Estás eligiendo terminar el examen, lo cual mandará tus resultados al módulo calificar y ya no podrás corregir reactivos. Si aún tienes tiempo, revisa tus respuestas y cuando estés seguro presiona en confirmar`,
        "Ver examen": "Ver examen",
        "Iniciar examen": "Iniciar examen",
        "Continuar examen": "Continuar examen",
        //notifications
        lightboxToast:
          "Tip: Manten presionado cada item para ver su respectiva imagen",
        audioToast:
          "Tip: Manten presionado cada item para escuchar su respectivo audio",
        //evidencias notifications
        actionLabel: "Selecciona una opción",
        openLabel: "Abrir",
        deleteLabel: "Eliminar",
        evidenciaSaved: "Cambios guardados con éxito",
        evidenciaNotSaved:
          "Hubo un error al enviar tus cambios. Intentalo de nuevo en unos momentos",
        canceledEvidencia: "El envio de la tarea se ha cancelado con éxito",
        evidenciaValidation:
          "Asegúrate de añadir por lo menos un archivo o comentario.",
        cancelMessage:
          "Al cancelar tu envio el estatus de la tarea pasará a pendiente y se eliminaran los archivos y comentarios subidos.¿Deseas continuar?",
        evSizeLimit: "Sólo se aceptan archivos que pesen menos de 10mb",
        evFileType:
          "Sólo se aceptan archivos de tipo pdf, docx, pptx, png, jpeg y xlsx",
        //notification for evaluations
        evalutionNetworkIssue:
          "Se ha cerrado la evaluación. Asegurate de que tu conexión a internet sea estable.",
        closingEvalution:
          "Faltan 5 minutos para terminar la evaluación, prepárate para terminar.",
        //Autoevaluaciones
        autoevaluationAspects: "Aspectos a valorar",
        autoevaluation: "Autoevaluación",
        autoEvaluationDefault:
          "Lee los siguientes enunciados y selecciona la opción con la que te identifiques más",
        aspectsLabel: "Aspectos a valorar",
        verySatisfied: "Muy satisfecho",
        satisfied: "Satisfecho",
        neutral: "Neutral",
        unsatisfied: "Insatisfecho",
        veryUnsatisfied: "Muy instatisfecho",
        //Test
        singleSelected: "vez seleccionada",
        multipleSelected: "veces seleccionada",
        notSelected: "No se seleccionó",
        nextLabel: "Siguiente",
        previousLabel: "Anterior",
        questionLabel: "Pregunta",
        finishLabel: "Terminar",
        questionValidationLabel:
          "Asegúrate de contestar la pregunta actual antes de continuar",
        resourceNotFound: "No se encontró el recurso",
      },
    },
    EN: {
      translations: {
        //generales en actividades
        saving: "Saving...",
        comenzarButton: "Start!",
        score: "Score",
        attemptsNumber: "Attempts:",
        highestScore: "Highest score:",
        lastScore: "Score from the last attempt:",
        insignsLabel: "Earned the following badges",
        tryAgainLabel: "Try again",
        timeLabel: "Time",
        activityLabel: "Completed activity",
        slideOf: "of",
        Cerrar: "Close",
        Calificar: "Send",
        Limpiar: "Restart",
        closeBook: "CLOSE BOOK",
        //preguntas
        preguntasTitle: "Quiz",
        preguntasDesc: "Answer the following questions correctly.",
        //preguntas Abiertas
        preguntasAbiertasTitle: "Open Questions",
        preguntasAbiertasDesc: "Answer the following questions.",
        preguntasValidation: "Make sure to answer all the questions.",
        //relacionar
        relacionarTitle: "Match up",
        relacionarDesc:
          "Drag and drop each keyword next to its correct definition.",
        aColumnLabel: "Column a",
        bColumnLabel: "Column b",
        //ordenar
        ordenarTitle: "Sort",
        ordenarDesc: "Order the words correctly.",
        //rompecabezas
        puzzleClue: "Get a clue",
        rompecabezasTitle: "Puzzle",
        rompecabezasDesc: "Complete the puzzle.",
        "Ver imagen": "View image",
        Obtuviste: "You got",
        "aciertos de": "correct pieces out of",
        totales: "",
        "Nota: La visualización de la imagen afecta tu calificación final":
          "Note: Your score is reduced each time you see the image",
        //memorama
        memoramaTitle: "Memory game",
        memoramaDesc: "Remember the positions of all pairs of cards.",
        //arrastrar
        arrastrarTitle: "Group sort",
        arrastrarDesc: "Drag the items into the correct container.",
        //sopa
        sopaTitle: "Wordsearch",
        sopaDesc:
          "Find the words in the list by pressing the first letter and drag and drop to the last letter of the word.",
        sopaMobileDesc:
          "Find the words from the list and mark them by pressing the first letter and the last letter of the word.",
        //crucigrama
        crucigramaTitle: "Crossword",
        crucigramaDesc: "Find all the words using the clues.",
        //hotspot
        hotspotTitle: "Hotspot",
        hotspotDesc: "click on the correct areas.",
        instruccionesLabel: "Instructions",
        foundLabel: "Found",
        errorsLabel: "Errors",
        //hotspot multiple
        hotspotMultipleTitle: "Hotspot multiple",
        hotspotMultipleDesc:
          "Drag and drop the items into the correct container.",
        hotspotMultipleMobileDesc:
          "Place all the elements in their respective hotspot",
        //Econtrar diferencias
        encontrarTitle: "Find the differences",
        encontrarDesc: "find the differences between the images",
        //dibujar
        dibujarTitle: "Draw and coloring",
        dibujarDesc: "Read the instructions and start drawing!",
        drawingNameLabel: "Type a name for your drawing",
        //expresion(palabras)
        lecturaPalabrasTitle: "Reading",
        lecturaPalabrasDesc:
          "Prepare your microphone, select the word or sentence and pronounce it.",
        retryLabel: "Stop",
        stopLabel: "Next",
        listeningLabel: "Listening...",
        recordLabel: "Click here to start recording",
        verifyLabel: "Stop and verify",
        nextLabel: "Stop and next",
        myRecordingLabel: "My sentence",
        sentenceLabel: "Sentence:",
        wordLabel: "Word/Sentence",
        correctWordLabel: "Correct answers",
        //expresion(pronunciacion)
        lecturaPronunciacionTitle: "Sentence reading (pronunciation)",
        lecturaPronunciacionDesc:
          "Prepare your microphone, press the start button and read the text aloud, the system will rate your pronunciation (not how fast you do it). Once you finish dictating, press the verify button.",
        //expresion(velocidad)
        lecturaVelocidadTitle: "Sentence reading (speed)",
        lecturaVelocidadDesc:
          "Prepare your microphone, press the start button and read the text aloud as fast as you can, the system will rate each word for you. If you get stuck on a word, try to pronounce it until you pass it, the faster you do it, the better your average reading speed will be.",
        "Velocidad de lectura": "Lecture speed",
        "palabras por minuto": "Words per minute",
        //dictados
        dictadosTitle: "Dictation",
        dictadosDesc:
          "Select the audio you are going to listen to, write what you listen and wait for the system to rate it. Differences between the original audio text and the text you typed will be displayed in red. Double check your spelling and grammar.",
        Siguiente: "Next",
        "Enviar calificación": "Send results",
        "Escucha y escribe dentro de los recuadros":
          "Listen and write in the boxes",
        "Escucha y escribe dentro del recuadro": "Listen and write in the box",
        "Compara tus respuestas": "Check your answers",
        Correcta: "Correct answer",
        "respuestas correctas": "Correct answers",
        //lee y escucha
        escuchaTitle: "Text to speech",
        escuchaDesc: "Listen carefully to the audio",
        Pausar: "Pause",
        Repetir: "Repeat",
        Resumir: "Resume",
        Escuchar: "Play",
        //contenedor de recursos
        recursosHeading: "Included files:",
        recursosDownload: "download files",
        recursosInfo:
          "The files will be downloaded to your default download folder.",
        recursosLink: "How to unzip a file in zip format?",
        //pdf
        pdfLabel: "Show PDF",
        cerrarLabel: "Close",
        //carousel
        slide: "slide",
        carouselShowMore: "Show More",
        carouselShowLess: "Show less",
        //evidencias
        Tarea: "Assignment",
        Título: "Title",
        Descripción: "Description",
        Criterios: "Criteria",
        Calificación: "Score",
        Observaciones: "observations",
        "Archivos cargados": "Loaded files",
        "Añadir enlaces web": "Add web links",
        "Añadir comentarios": "Add comments",
        Estatus: "Status",
        "Fecha de envio": "Date",
        Sumatoria: "submission date",
        "Enviar tarea": "Send assignment",
        "Cancelar envío": "Remove changes",
        "Guardar cambios": "Save changes",
        "Ver tarea": "Show",
        "Estatus de envío:": "Status",
        Evidencia: "Assignment",
        Evidencias: "Submitted files",
        Resultados: "Results",
        Rúbrica: "Rubric",
        Añadir: "Add",
        PENDIENTE: "PENDING",
        ENVIADA: "SENT",
        RECHAZADA: "REJECTED",
        CALIFICADA: "Evaluated",
        "Indica todos los puntos que se tomarán en cuenta para evaluar la tarea":
          "Indicates all the points that will be taken into account to evaluate the assignment",
        "El límite de los archivos subidos es de 10MB en formato png, jpg, pdf, docx, xlsx o pptx.Los archivos de tipo video deben ser referenciado a través de un enlace":
          "The uploaded files limit is 10MB in png, jpg, pdf, docx, xlsx or pptx format. Video type files must be referenced through a link",
        "La calificación es calculada con base al promedio de todos los puntos seleccionados en la rúbrica":
          "The score is calculated based on the average of all the points selected in the rubric.",
        //evaluaciones
        instructionsLabel: "Instructions",
        step1:
          "Step 1. To start the evaluation you must enter the access code provided by your teacher. You will not be able to start the evaluation until you have a correct code.",
        step2:
          "Step 2. Check the time you have to complete the evaluation, because when the time is up, the evaluation will be automatically submitted and closed. You can review within the evaluation the time you have left indicated in minutes and seconds.",
        step3:
          "Step 3. Check the number of attempts you have to answer the evaluation, this information is found at the end of the instructions. If you try again, you will have to request a new code and the result of the evaluation will be that of the last attempt.",
        step4:
          'Step 4. If you finish before time is up, please check that you have saved or submitted all answers and click the "Finish and send" button at the end.',
        step5:
          "Step 5. If you lose your internet connection, close your browser or close the evaluation, you do not need a new code to continue. The activities that you have finished will be kept and the time you have left will be the same as when you closed.",
        step6: `When you're ready, enter the code and press the "Start Evaluation" button.`,
        evaluationMinutesLabel: "min",
        usedCodesLabel: "Used codes:",
        previousScoresLabel: "Previous scores:",
        evaluationLastScore: "Last attempt score:",
        detectedEvaluationLabel:
          "An evaluation has been detected that you have not completed. If you want to use a new code, you must first complete the evaluation.",
        timeUpLabel:
          "The time set for completing this evaluation has expired. As soon as you close this window, the evaluation will be closed.",
        isDoneLabel: "¿Are you sure you want to continue?",
        evaluationAnsweredLabel: "answered correctly",
        evaluationScoreLabel: "Your score is",
        yes: "Yes",
        no: "No",
        endEvaluationButton: "Close evaluation",
        writeCodeLabel: "Type here your access code",
        attemptLabel: "attempt",
        "Ver códigos de acceso": "See access codes",
        "Comparte estos códigos con tus alumnos para que puedan iniciar la evaluación":
          "Share these codes with your students so they can start the evaluation",
        "Ingresa un código de acceso.": "Type an access code.",
        "El autor de esta evaluación seleccionó que no se muestren los resultados, para conocer tu calificación consulta con tu profesor.":
          "The author of this evaluation selected not to show the results, if you want you check your score, consult your teacher.",
        "No todos los reactivos han sido contestados, revisa que no te falte alguno antes de terminar.":
          "Not all the activities have been completed, check that you are not missing any before finishing.",
        "Estás eligiendo terminar la evaluación, lo cual mandará tus resultados al módulo calificar y ya no podrás corregir reactivos. Si aún tienes tiempo, revisa tus respuestas y cuando estés seguro presiona en confirmar.": `You are choosing to end the evaluation, which will send you your results and you will not be able to make any further changes. If you still have time, review your answers and when you're sure, press confirm.`,
        "Ver evaluación": "See evaluation",
        "Iniciar evaluación": "Start evaluation",
        "Continuar evaluación": "Continue Evaluation",
        "El código de acceso no es válido. Asegúrate de haber ingresado el código correcto.":
          "This access code is not valid. Make sure you are using a valid code.",
        //Examenes
        step1Test:
          "Step 1. To start the test you must enter the access code provided by your teacher. You will not be able to start the test until you have a correct code.",
        step2Test:
          "Step 2. Check the time you have to complete the test, because when the time is up, the test will be automatically submitted and closed. You can review within the test the time you have left indicated in minutes and seconds.",
        step3Test:
          "Step 3. Check the number of attempts you have to answer the test, this information is found at the end of the instructions. If you try again, you will have to request a new code and the result of the test will be that of the last attempt.",
        step4Test:
          'Step 4. If you finish before time is up, please check that you have saved or submitted all answers and click the "Finish and send" button at the end.',
        step5Test:
          "Step 5. If you lose your internet connection, close your browser or close the test, you do not need a new code to continue. The activities that you have finished will be kept and the time you have left will be the same as when you closed.",
        step6Test: `When you're ready, enter the code and press the "Start test" button.`,
        detectedEvaluationLabelTest:
          "A test has been detected that you have not completed. If you want to use a new code, you must first complete the test.",
        timeUpLabelTest:
          "The time set for completing this test has expired. As soon as you close this window, the test will be closed.",
        endEvaluationButtonTest: "Finish test",
        "Comparte estos códigos con tus alumnos para que puedan iniciar el examen":
          "Share these codes with your students so they can start the test",
        "Estás eligiendo terminar el examen, lo cual mandará tus resultados al módulo calificar y ya no podrás corregir reactivos. Si aún tienes tiempo, revisa tus respuestas y cuando estés seguro presiona en confirmar.": `You are choosing to end the test, which will send you your results and you will not be able to make any further changes. If you still have time, review your answers and when you're sure, press confirm.`,
        "Ver examen": "See test",
        "Iniciar examen": "Start test",
        "Continuar examen": "Continue test",
        //notifications
        lightboxToast:
          "Tip: Touch and hold each item to see its respective image",
        audioToast:
          "Tip: Hold down each item to listen to its respective audio",
        //evidencias notifications
        actionLabel: "Select an option",
        openLabel: "Open",
        deleteLabel: "Delete",
        evidenciaSaved: "Changes saved successfully",
        evidenciaNotSaved:
          "There was an error submitting your changes. Try again in a few moments",
        canceledEvidencia:
          "Assignment submission has been canceled successfully",
        evidenciaValidation: "Make sure to add at least one file or comment.",
        cancelMessage:
          "If you cancel your assignment, the status will change to pending and the uploaded files and comments will be deleted. Do you want to continue?",
        evSizeLimit: "The file size should not be higher than 10mb",
        evFileType:
          "pdf, docx, pptx, png, jpeg and xlsx are the only accepted file types",
        //notification for evaluations
        evalutionNetworkIssue:
          "The evaluation was closed. Make sure your internet connection is stable.",
        closingEvalution: "You have 5 minutes left to finish the evaluation.",
        //Autoevaluaciones
        autoevaluationAspects: "Aspects to consider",
        autoevaluation: "Autoevaluation",
        autoEvaluationDefault:
          "Select the option with which you identify most.",
        aspectsLabel: "Aspects to evaluate",
        verySatisfied: "Very satisfied",
        satisfied: "Satisfied",
        neutral: "Neutral",
        unsatisfied: "Unsatisfied",
        veryUnsatisfied: "Very Unsatisfied",
        //Test
        singleSelected: "time selected",
        multipleSelected: "times selected",
        notSelected: "Not selected",
        nextLabel: "Next",
        previousLabel: "Previous",
        questionLabel: "Question",
        finishLabel: "Submit",
        questionValidationLabel: "Make sure to answer the current question",
        resourceNotFound: "Resource not found",
      },
    },
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
