import LoadingScreen from "../../../../fragments/LoadingScreen";
import AddListasDifusionRow from "./AddListasDifusionRow";

const AddListasDifusionTable = ({ listas, loadingLists }) => {
  if (loadingLists) return <LoadingScreen />;
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th />
            <th className="text-center">Nombre de la lista de difusión</th>
            <th className="text-center">Cantidad de usuarios</th>
            <th className="text-center">Acciones</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {listas.map((lista_difusion) => (
            <AddListasDifusionRow
              key={lista_difusion._id}
              lista_difusion={lista_difusion}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddListasDifusionTable;
