export const addBloque = (position, payload) =>(
    {
        type:"GROW_ARREGLO",
        position,
        payload,
    }
)

export const modificarArreglo = (payload) => (
    {
        type:'SET_ARREGLO',
        payload
    }
)

export const ordenarArreglo = (payload) => (
    {
        type:'REORDER_ARREGLO',
        payload
    }
)

export const EditText = (payload,texto,index) => (
    {
        type:'EDIT_TEXT',
        payload,
        texto,
        index
    }
)

export const EditPie = (payload,texto,index) => (
    {
        type:'EDIT_PIE',
        payload,
        texto,
        index
    }
)

export const EditMedia = (payload,texto,index) => (
    {
        type:'EDIT_MEDIA',
        payload,
        texto,
        index
    }
)

export const  deleteBloque= (payload,index) => (
    {
        type:'DELETE_UMA',
        payload,
        index

    }
)

export const  archivosLoad= (payload,index, url) => (
    {
        type:'LOAD_FILE',
        payload,
        index,
        url
    }
)
export const  videoLoad= (payload,index, url) => (
    {
        type:'LOAD_VIDEO',
        payload,
        index,
        url
    }
)
export const  reload= (payload) => (
    {
        type:'RELOAD_VIDEO',
        payload
    }
)

export const restart= (payload) => (
    {
        type:'RESTART_ARREGLO',
        payload
    }
)