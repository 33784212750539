import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ViewIcon } from '../../../../assets/icons/view.svg';
import { ReactComponent as VentaIcon } from '../../../../assets/icons/misComprasCircleIcon.svg';
import { formatDate } from '../../../utils/dateFunctions';
import { UserContext } from '../../../services/user';

const MisVentasRow = ({ ventaInfo }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { _id, compra, fechaCreacion, estatus } = ventaInfo;

  return (
    <tr className='p-2 text-left bg-white'>
      <td className='border-2 rounded-l-lg border-r-0 text-left font-sans'>
        <VentaIcon className='mx-auto' color='white' />
      </td>
      <td className='border-y-2 font-sans text-left'>
        {user.activeRole === 'DIR_PLANTEL' ||
        user.activeRole === 'ADMIN_OPERATIVO'
          ? _id
          : compra}
      </td>
      <td className='border-y-2 font-sans text-left'>
        {formatDate(new Date(fechaCreacion))}
      </td>
      <td className='border-y-2 font-sans text-left'>{estatus}</td>
      <td className='border-2 rounded-r-md border-l-0 min-h-[52px] text-left font-sans'>
        <ViewIcon
          className='mx-auto cursor-pointer'
          onClick={() => navigate(`/marketplace/misVentas/${_id}`)}
        />
      </td>
    </tr>
  );
};

export default MisVentasRow;
