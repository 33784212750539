import { useContext, useState, useMemo } from "react";
import { UserContext } from "../../../../../services/user";
import { ReactComponent as ProductoIcon } from "../../../../../../assets/icons/productoIcon.svg";
import { ReactComponent as ProductoEditorialIcon } from "../../../../../../assets/icons/productoEditorialIcon.svg";
import { ReactComponent as ProductoDistribucionIcon } from "../../../../../../assets/icons/productoDistribucionIcon.svg";
import { ReactComponent as ProductoCorporativoIcon } from "../../../../../../assets/icons/productoCorporativoIcon.svg";
import { ReactComponent as ProductoPlantelIcon } from "../../../../../../assets/icons/productoPlantelIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/icons/deleteIcon.svg";
import { ReactComponent as AddIcon } from "../../../../../../assets/icons/addIcon.svg";
import { ReactComponent as MinusIcon } from "../../../../../../assets/icons/minusIcon.svg";
import EliminarProductoDelPaqueteModal from "./EliminarProductoDelPaqueteModal";
import { PaqueteProductosContext } from "../../../../../../contexts/PaqueteProductosContext";
import {
  decrementarProducto,
  incrementarProducto,
} from "../../../../../../reducers/paquetesProductos/paquetesProductosAction";
import { formatCurrency } from "../../../../../utils/productoUtils";
import { obtenerUserEstructuraInfo } from "../../../../../utils/userUtils";

const ICON_COLOR = {
  "Casa editorial": ProductoEditorialIcon,
  Distribución: ProductoDistribucionIcon,
  Corporativo: ProductoCorporativoIcon,
  Plantel: ProductoPlantelIcon,
};

const ProductosRow = ({ productoInfo, ver, setIsEnableSubmitButton }) => {
  const { activeRole, user } = useContext(UserContext);
  const { productos, dispatch } = useContext(PaqueteProductosContext);
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] = useState(false);

  const { precioVenta, cantidad, total, producto, productoListaId, provieneDe, costo } = productoInfo;
  const { nombre, estatus } = producto;

  const handleIncrementarCantidad = () => {
    dispatch(incrementarProducto(productos, productoListaId));
    setIsEnableSubmitButton(true);
  };

  const handleDecrementarCantidad = () => {
    dispatch(decrementarProducto(productos, productoListaId));
    setIsEnableSubmitButton(true);
  };

  const IconEstructura = useMemo(() => {
    if (activeRole === "") return ProductoIcon;

    if (provieneDe) {
      const iconoProductoLinea = provieneDe.editorial
        ? "Casa editorial"
        : provieneDe.distribucion
        ? "Distribución"
        : provieneDe.corporativo
        ? "Corporativo"
        : "Plantel";

      return ICON_COLOR[iconoProductoLinea];
    }

    const { tipoEstructura } = obtenerUserEstructuraInfo(user, activeRole);
    return ICON_COLOR[tipoEstructura];
  }, [activeRole]);

  return (
    <>
      <tr className="p-2 text-left bg-white select-none">
        <td className="border-2 rounded-l-md border-r-0 text-left font-sans">
          <IconEstructura className="mx-auto" />
        </td>
        <td className="border-y-2 border-x-0 text-left font-sans">
          <p>{nombre}</p>
        </td>
        <td className="border-y-2 font-sans">
          <p className="text-right mr-4">{provieneDe ? formatCurrency(costo) : formatCurrency(precioVenta)}</p>
        </td>
        <td className="border-y-2 text-left font-sans">
          {ver ? (
            <p className="select-none">{cantidad}</p>
          ) : (
            <span className="flex items-center space-x-3 border-2 w-fit min-w-[6.5rem] px-2 py-1 rounded justify-evenly">
              <MinusIcon
                className={`${
                  cantidad > 1 ? "opacity-100 pointer-events-auto" : "opacity-80 pointer-events-none"
                } cursor-pointer`}
                onClick={handleDecrementarCantidad}
              />
              <p className="select-none">{cantidad}</p>
              <AddIcon
                className={`${
                  cantidad < 50 ? "opacity-100 pointer-events-auto" : "opacity-80 pointer-events-none"
                } cursor-pointer`}
                onClick={handleIncrementarCantidad}
              />
            </span>
          )}
        </td>
        <td className="border-y-2 font-sans">
          <p className="text-right mr-4">{formatCurrency(total)}</p>
        </td>
        <td className="border-y-2 font-sans">
          <p>{estatus ? "Disponible" : "Agotado"}</p>
        </td>
        <td className="border-2 rounded-r-md border-l-0">
          {!ver && (
            <div className="flex items-center justify-evenly space-x-2 px-2">
              <DeleteIcon
                title="Eliminar producto"
                className="cursor-pointer"
                onClick={() => setIsDeleteProductModalOpen(true)}
              />
            </div>
          )}
        </td>
      </tr>
      <EliminarProductoDelPaqueteModal
        isOpen={isDeleteProductModalOpen}
        setIsOpen={setIsDeleteProductModalOpen}
        productoInfo={productoInfo}
        setIsEnableSubmitButton={setIsEnableSubmitButton}
      />
    </>
  );
};

export default ProductosRow;
