import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReactComponent as AddIcon } from "../../../../assets/icons/addIcon.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/icons/minusIcon.svg";
import { ReactComponent as RemoverIcon } from "../../../../assets/icons/remover.svg";
import { formatCurrency } from "../../../utils/productoUtils";
import ProductoImage from "../../../../assets/img/producto.png";
import PaqueteImage from "../../../../assets/img/paquetesMarketplace.png";
import EliminarProductoCarritoModal from "./fragments/EliminarProductoCarritoModal";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { fetchCarritoAsync } from "../../../../reducers/carrito/carritoAction";
import { useDispatch } from "react-redux";
import { putProductoCarritoDeCompras } from "../../../services/Api/MarketPlace/CarritoDeCompras";
import InputNumber from "../../../fragments/Inputs/InputNumber";
import useValidateForm from "../../../hooks/useValidateForm";
import { useDebounce } from "../../../hooks/useDebounce";
import { PaisesContext } from "../../../../contexts/PaisesContext";
import { UserContext } from "../../../services/user";

const CarritoProducto = ({ producto, esPaquete }) => {
  const dispatch = useDispatch();
  const { paises } = useContext(PaisesContext);
  const { user } = useContext(UserContext);
  const [eliminarProductoModal, setEliminarProductoModal] = useState(false);
  const [formStatus, setFormStatus] = useState("idle");

  const obtenerEstatus = () => {
    if (esPaquete) {
      return !producto?.paquete?.esEliminado && producto?.paquete?.paquete?.estatus;
    } else {
      return (
        (!producto.variacion && !producto?.producto?.esEliminado && producto?.producto?.producto?.estatus) ||
        (producto.variacion &&
          !producto?.producto?.esEliminado &&
          producto?.producto?.producto?.estatus &&
          !producto?.variacion?.esEliminado)
      );
    }
  };

  const { cantidad, estatus, precio, imagen, carrusel, nombre, total, _id, simboloMoneda } = useMemo(() => {
    let paisDestino = user?.escuela?.campus?.dirFiscalPais?._id;
    if (esPaquete) {
      let paqueteInfo = {
        cantidad: producto.cantidad,
        precio: producto.paquete.precioVenta,
        estatus: obtenerEstatus(),
        imagen: producto.paquete.paquete.imagen,
        carrusel: producto.paquete.paquete.carrusel,
        nombre: producto.paquete.paquete.nombre,
        total: producto.cantidad * producto.paquete.precioVenta,
        _id: producto._id,
        simboloMoneda: paises.find((pais) => pais._id === paisDestino)?.simbolo,
      };

      return paqueteInfo;
    }

    let productoInfo = {
      cantidad: producto.cantidad,
      precio: producto.producto.precioVenta,
      estatus: obtenerEstatus(),
      imagen: producto.producto.producto.imagen,
      carrusel: producto.producto.producto.carrusel,
      nombre: producto.producto.producto.nombre,
      total: producto.cantidad * producto.producto.precioVenta,
      _id: producto._id,
      simboloMoneda: paises.find((pais) => pais._id === paisDestino)?.simbolo,
    };

    return productoInfo;
  }, [producto, paises]);

  const { handleChange, errors, values } = useValidateForm({ cantidadArticulo: cantidad ?? 0 });

  const debouncedValue = useDebounce(values.cantidadArticulo, 450);

  const handleActualizarCantidad = async (cantidad) => {
    setFormStatus("submitting");
    try {
      let body = {
        cantidad: cantidad,
      };
      await putProductoCarritoDeCompras(_id, body);
      dispatch(fetchCarritoAsync());
      toast.success("El carrito se ha actualizado correctamente", alertConfiguration);
      setFormStatus("submitted");
    } catch (error) {
      toast.error("Ha ocurrido un error", alertConfiguration);
      console.log(error);
      setFormStatus("submitted");
    }
  };

  useEffect(() => {
    if (Object.values(errors).length === 0 && parseInt(debouncedValue) !== cantidad) {
      handleActualizarCantidad(parseInt(debouncedValue));
    }
  }, [debouncedValue]);

  return (
    <>
      <tr className={`text-left bg-white select-none h-24`}>
        <td className={`min-w-[5rem] ${!estatus && "opacity-30"}`}>
          <img
            className="h-12 w-12 object-contain ml-4"
            src={imagen === "" ? (carrusel.length > 0 ? carrusel[0] : (esPaquete ? PaqueteImage : ProductoImage)) : imagen}
            alt=""
          />
        </td>
        <td className={`${!estatus && "opacity-30"}`}>
          <p>{nombre}</p>
          {producto.variacion && (
            <span>
              {producto.variacion.seleccionValores.map((valorSeleccionado) => (
                <p key={valorSeleccionado._id} className="mr-2 text-xs text-gray-400">
                  {valorSeleccionado.variable.variable}: {valorSeleccionado.valor.valor}
                </p>
              ))}
            </span>
          )}
          <p></p>
        </td>
        {estatus ? (
          <>
            <td>
              <p className="text-center">{`${simboloMoneda} ${formatCurrency(precio)}`}</p>
            </td>
            <td>
              <span className="flex items-center space-x-3 border-2 w-fit min-w-[6.5rem] px-2 py-1 rounded justify-evenly mx-auto">
                <MinusIcon
                  className={`${
                    cantidad > 1 ? "opacity-100 pointer-events-auto" : "opacity-80 pointer-events-none"
                  } cursor-pointer`}
                  onClick={formStatus !== "submitting" ? () => handleActualizarCantidad(cantidad - 1) : () => {}}
                />
                <InputNumber
                  styles="-mt-2 !w-24 !p-2 text-center"
                  name={"cantidadArticulo"}
                  value={values.cantidadArticulo ?? 0}
                  onChange={(e) => handleChange(e, "cantidadArticulo")}
                />
                <AddIcon
                  className={`${
                    cantidad < 1000 ? "opacity-100 pointer-events-auto" : "opacity-80 pointer-events-none"
                  } cursor-pointer`}
                  onClick={formStatus !== "submitting" ? () => handleActualizarCantidad(cantidad + 1) : () => {}}
                />
              </span>
              <p className="font-thin text-xs text-center text-red-400">{errors["cantidadArticulo"]}</p>
            </td>
            <td>
              <p className="text-center">{`${simboloMoneda} ${formatCurrency(total)}`}</p>
            </td>
          </>
        ) : (
          <td colSpan={3}>
            <p className="bg-[#8E8E8E]/70 text-white py-2 px-4 rounded-full text-center w-fit mx-auto">
              Artículo no disponible
            </p>
          </td>
        )}
        <td>
          <span className="flex items-center justify-center mx-2">
            <RemoverIcon className="cursor-pointer" onClick={() => setEliminarProductoModal(true)} />
          </span>
        </td>
      </tr>
      <EliminarProductoCarritoModal
        isOpen={eliminarProductoModal}
        setIsOpen={setEliminarProductoModal}
        nombreProducto={nombre}
        productoId={_id}
      />
    </>
  );
};

export default CarritoProducto;
