import { useEffect, useState } from "react";
import { useGestorAvisos } from "../GestorAvisosContext";

const SelectAll = () => {
  const { selectAllAvisos, currentData, avisos } = useGestorAvisos();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  useEffect(() => {
    const allSelected =
      currentData().length > 0 &&
      currentData().every((aviso) => aviso.esSeleccionado);
    setIsSelectedAll(allSelected);
  }, [currentData]);

  const handleChecked = () => {
    setIsSelectedAll((state) => !state);
    selectAllAvisos(!isSelectedAll, currentData());
  };

  return (
    <div className="w-full border-solid border-2 rounded-lg p-1 flex flex-row justify-center gap-5 bg-white">
      <div className="rounded-md flex flex-row items-center gap-2">
        <input
          type="checkbox"
          name={`selectAllCheckbox`}
          id={`selectAllCheckbox`}
          className="rounded-md border-2 border-gray-300 my-0 cursor-pointer"
          checked={isSelectedAll}
          onChange={handleChecked}
          disabled={avisos?.length === 0}
        />
        <label>
          <p className="text-sm md:text-base text-left font-semibold">Todos</p>
        </label>
      </div>
    </div>
  );
};

export default SelectAll;
