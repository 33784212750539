import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import VideoCover from "../../../../../assets/icons/video_bg.png";

function VideoWrapper({ children, aditionalStyles = "" }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      {isVisible ? (
        children
      ) : (
        <div
          onClick={() => setIsVisible(true)}
          className={`w-full aspect-viddeo cursor-pointer border rounded-lg overflow-hidden relative ${aditionalStyles}`}
        >
          <img src={VideoCover} alt="" className="w-full h-full object-cover" />
          <div className="absolute top-1/2 left-3/4 -translate-y-1/2 bg-white rounded-full p-8 border-[3px]  hover:scale-125 hover:brightness-125 custom-transition border-[#6cbdec]">
            <FontAwesomeIcon
              className="text-[3rem] cursor-pointer rounded-full px-2 text-[#6cbdec] relative left-[2px]"
              icon={faPlay}
            ></FontAwesomeIcon>
          </div>
        </div>
      )}
    </>
  );
}

export default VideoWrapper;
