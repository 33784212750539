import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../../fragments/LoadingScreen";
import ContentContainer from "../../../fragments/ContentContainer";
import Carrito from "./Carrito";
import ProcesoCompraLayout from "./fragments/ProcesoCompraLayout";
import DatosEnvioCompra from "./DatosEnvioCompra";
import MetodoDePago from "./MetodoDePago";
import PagoExitoso from "../../../../assets/icons/pagoExitoso.svg";
import AdvertenciaPago from "../../../../assets/icons/advertenciaPago.svg";
import { useNavigate } from "react-router-dom";
import withUserAccesability from "../../../hoc/withUserAccesability";
import { useDispatch, useSelector } from "react-redux";
import { selectCarritoIsLoading } from "../../../../reducers/carrito/carritoSelector";
import { UserContext } from "../../../services/user";
import { getDatosDeEnvioPlantel } from "../../../services/Api/MarketPlace/Planteles/DatosEnvio";
import { fetchCarritoAsync } from "../../../../reducers/carrito/carritoAction";
import { fetchShowBuscador } from "../../../../reducers/marketplace/marketplaceAction";

const ProcesoCompra = ({ isUserAllowed }) => {
  const [loading, setLoading] = useState(true);
  const [estatus, setEstatus] = useState("Carrito");
  const [metodoPago, setMetodoPago] = useState("");
  const [productosComprados, setProductosComprados] = useState([]);
  const [informacionMetodoPago, setInformacionMetodoPago] = useState({ data: {}, errores: {}, facturacion: "false" });
  const navigate = useNavigate();
  const loadingCarrito = useSelector(selectCarritoIsLoading);
  const { user, activeRole } = useContext(UserContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeRole !== "") {
      if (!isUserAllowed) {
        navigate("/NotFound");
      }
      dispatch(fetchShowBuscador(false));
    }
  }, [activeRole]);

  const [datosEnvio, setDatosEnvio] = useState({});

  const obtenerDatosDeEnvio = useCallback(async () => {
    if (Object.values(user).length !== 0) {
      try {
        let datosEnvioResponse = await getDatosDeEnvioPlantel(user.escuela.campus._id);

        if (datosEnvioResponse.ubicacion) {
          datosEnvioResponse.ubicacion.nombre = datosEnvioResponse.ubicacion.direccion;
        } else {
          datosEnvioResponse.ubicacion = "";
        }
        dispatch(fetchCarritoAsync());
        setDatosEnvio(datosEnvioResponse);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [user]);
  

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && document.referrer !== '') {
        window.location.reload();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    obtenerDatosDeEnvio();
  }, [obtenerDatosDeEnvio]);

  useEffect(() => {
    if (estatus === "DatosEnvio") setMetodoPago("");
  }, [estatus]);

  return (
    <Fragment>
      {loading || loadingCarrito ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <div className="bg-backgroundColor pb-8 min-h-[calc(100vh-13.85rem)] flex flex-col">
            <ContentContainer>
              <ProcesoCompraLayout
                estatus={estatus}
                setEstatus={setEstatus}
                metodoPago={metodoPago}
                datosEnvio={datosEnvio}
                productosComprados={productosComprados}
                setProductosComprados={setProductosComprados}
                informacionMetodoPago={informacionMetodoPago}
                setInformacionMetodoPago={setInformacionMetodoPago}
              >
                {estatus === "Carrito" ? (
                  <Carrito />
                ) : (
                  <div className="flex flex-col gap-6">
                    {estatus === "Compra exitosa" && (
                      <div
                        className={`flex flex-col gap-6 sm:flex-row justify-center sm:items-center p-6 ${
                          metodoPago === "TARJETA_CREDITO_DEBITO" ? "bg-[#D7F9E8B2]/70" : "bg-[#EAE18F80]/50"
                        }`}
                      >
                        <img
                          className="h-16"
                          src={metodoPago === "TARJETA_CREDITO_DEBITO" ? PagoExitoso : AdvertenciaPago}
                          alt=""
                        />
                        <div className="flex flex-col justify-center items-center w-full gap-3">
                          <p className="font-bold text-xl text-gray-500 text-center">
                            {metodoPago === "TARJETA_CREDITO_DEBITO"
                              ? "¡TU COMPRA SE HA REALIZADO DE MANERA EXITOSA!"
                              : "¡DEBES REALIZAR EL PAGO PARA COMPLETAR TU COMPRA DE MANERA EXITOSA!"}
                          </p>
                          <p className="font-bold text-gray-400 text-center">
                            {metodoPago === "TARJETA_CREDITO_DEBITO"
                              ? "Puedes consultar más detalles en la sección de Mis compras"
                              : "Ya puedes consultarla en la sección de “Mis compras”"}
                          </p>
                        </div>
                      </div>
                    )}
                    <DatosEnvioCompra estatus={estatus} datosEnvio={datosEnvio} />
                    {(estatus === "Pago" || estatus === "Compra exitosa") && (
                      <MetodoDePago
                        estatus={estatus}
                        metodoPago={metodoPago}
                        setMetodoPago={setMetodoPago}
                        datosEnvio={datosEnvio}
                      />
                    )}
                  </div>
                )}
              </ProcesoCompraLayout>
            </ContentContainer>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withUserAccesability(ProcesoCompra);
