import React, { useState, useEffect } from "react";
import {DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {v4 as uuidv4} from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleXmark, faSignalPerfect } from '@fortawesome/free-solid-svg-icons'
import SimplePlayer from "../../../Actividades/ActividadesUtils/SimplePlayer";
import { useContext } from "react";
import { ActividadContext } from "../../../Actividades/ActividadesUtils/ActWrapper";
import { shuffle, colors } from "../../../Actividades/ActividadesUtils/ActividadesUtils";
import Lightbox from "../../../Actividades/ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "../../../Actividades/ActividadesUtils/Lightbox/LightboxWrapper";
import { Trans } from "react-i18next";
import ImagenValidada from "../../../Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";

function ArrastrarEtiquetas({columnas, f1, f2, evaluable, umaBloque, guardarCalificacion, instrucciones}) {
  const activityContext = useContext(ActividadContext)

  const generatedColumns = f2.map((item) => {
    return {
      texto: item.texto,
      imagen:item.imagen,
      audio:item.audio,
      items: [],
      _id: uuidv4(),
      orden: item.orden
    };
  });
  
  const mainColumn = [{
      name: "columna primera ",
      items: f1.map((item, index)=>({...item, background:colors[index], etiqueta:index})),
      _id: "columna_principal",
      dropDisabled: false,
      orden: null
    }];

  const columnsFromBackend = [...mainColumn, ...generatedColumns];
  const columnsFromBackendClone = [...columnsFromBackend]
  const [columns, setColumns] = useState(columnsFromBackend);
  const [calificado, setCalificado] = useState(false)
  const [iniciado, setIniciado] = useState(false)
  const [lightboxImage, setLightboxImage] = useState('')

  const calificar = () => {
    setCalificado(true)
    const columnsToCheck = Object.entries(columns).filter((el) => {
      return el[1]._id !== "columna_principal";
    });

    const calificacion = columnsToCheck.map((item) => {
      if(item[1].items.length > 0 ){
        return item[1].items[0].orden === item[1].orden;
      }else{
        return false
      }
    });

    setTimeout(()=>{
      if(iniciado){
        const calificacionNumerica = (calificacion.filter(Boolean).length/columnsToCheck.length)*100
        activityContext.postCalificacionActividad(calificacionNumerica)
        guardarCalificacion(umaBloque, calificacion.filter(Boolean).length, JSON.stringify(columns))
      }
      else if(!iniciado)activityContext.postCalificacionActividad(null)
    },500)
  };

  const onDragEnd = (result, columns, setColumns) => {
    setIniciado(true)
    if (!result.destination) return;
    const { source, destination } = result;
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    if(destination.droppableId === source.droppableId){
      return
    }
    else if(destination.droppableId === '0' && source.droppableId !== destination.droppableId){
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: []
        },
        [destination.droppableId]: {
          ...destColumn
        }
      });
    }
    else if (source.droppableId !== destination.droppableId && source.droppableId === '0') {
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [destination.droppableId]: {
          ...destColumn,
          items: destItems.map((item)=>({...item, _id:uuidv4()})),
          etiqueta:source.orden
        }
      });
    } 
    else if(source.droppableId !== '0' && columns[destination.droppableId].items.length <= 1){
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    }
    else {
      return
    }
  };

  const autocompletarActividad = () =>{
      const respuestasCorrectas = columnsFromBackend.map((item)=>{
        const respuesta  = f1.map((item, index)=>({...item, background:colors[index], etiqueta:index})).filter((el)=>(el.orden === item.orden))
        return {...item, ...{items:respuesta}}
      })
      //.filter((el)=>(el._id !== 'columna_principal'))
      setColumns(respuestasCorrectas)
  }

  useEffect(()=>{
    if(!evaluable){
      autocompletarActividad()
    }else{
      setColumns(columnsFromBackendClone)
    }
  },[evaluable])

  return (
    <>
    {instrucciones && <div className="act-instructions mb-6">{instrucciones}</div>}
    <Lightbox lightboxValue={lightboxImage} closeLightbox={()=>setLightboxImage('')}/>
    <div className={`w-full ${evaluable ? '' : 'pointer-events-none'}`}>
      <div className="flex flex-col justify-around items-baseline p-2 w-full max-w-activity mx-auto">
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns)
            .slice(0, 1)
            .map(([columnId, column], index) => {
              return (
                <div className="w-full flex flex-col items-center" key={columnId}>
                  {/* <h2 className="w-full text-left pl-3 text-[#697d99] text-base md:text-lg">Columna A</h2> */}
                  <div className="m-2 w-full">
                    <Droppable droppableId={columnId} key={columnId} direction="horizontal">
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{background: snapshot.isDraggingOver ? "#d1d4d9" : "#e6e9ee",}}
                            className={`w-full transition-all border overflow-auto max-h-[9999px] min-h-[100px] rounded-xl p-2 grid mb-10 gap-4 ${f1.length === 4 ? 'grid-cols-3' : 'grid-cols-4'  }`}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <>
                                      <div
                                        //className={`rounded-xl flex min-h-[60px] mb-2 border p-4 text-white f-fit`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          ...provided.draggableProps.style,
                                          ...provided.draggableProps.style,
                                          transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)',
                                        }}
                                      >
                                      <div 
                                          className={`relative text-center h-full rounded-xl overflow-hidden border text-white flex flex-col items-center justify-center ${snapshot.isDragging ? 'w-[35px] h-[35px]' : ''}`}
                                          style={{background:item.background}}
                                          >
                                          <span className="text-sm flex items-center justify-center md:text-base w-full px-6 py-10 min-h-[100px]">
                                            {item.imagen && !snapshot.isDragging ? 
                                            <LightboxWrapper lightboxImage={item.imagen} wrapperStyles={'!absolute w-full h-full'} handleClick={()=>setLightboxImage(item.imagen)}>
                                              <ImagenValidada 
                                                className={`absolute w-full h-full bg-white aspect-video object-fill mx-auto ${item.imagen && item.texto ? 'brightness-75' : ''}`}  
                                                src={item.imagen}
                                              />
                                            </LightboxWrapper>
                                             : '' }
                                            {snapshot.isDragging ? '' : <span className={`w-[35px] max-w-[35px] h-[35px] max-h-35px] border absolute top-[3px] left-[3px] rounded-xl flex items-center justify-center bg-[#0000003b]`}>{item.orden}</span>}
                                            {snapshot.isDragging ? item.orden : <span className="z-10">{item.texto}</span>}
                                            {item.audio && !snapshot.isDragging  ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white absolute'} audio={item.audio}/> : ''}
                                          </span>
                                        </div>
                                      </div>
                                      {snapshot.isDragging &&
                                        <div 
                                          className="relative text-center h-full rounded-xl border text-white flex flex-col items-center justify-center" 
                                          style={{ transform: 'none !important',background:item.background,}}
                                        >
                                          <span className="text-sm flex items-center justify-center md:text-base w-full px-6 py-10 min-h-[100px]">
                                            {item.imagen ? <ImagenValidada className={`absolute w-full h-full bg-white aspect-video object-fill mx-auto ${item.imagen && item.texto ? 'brightness-75' : ''}`}  src={item.imagen}/>: '' }
                                            {<span className="z-10">{item.texto}</span>}
                                            {item.audio  ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white'} audio={item.audio}/> : ''}
                                          </span>
                                        </div>}
                                      </>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          <div className="grid grid-cols-2 gap-10 w-full">
            {Object.entries(columns)
              .slice(1)
              .map(([columnId, column], index) => {
                return (
                  <div className='mb-4 md:flex items-center relative' key={columnId}>
                    {!evaluable ? 
                    (column.items.length > 0 && column.orden === column.items[0].orden 
                    ? <FontAwesomeIcon icon={faCircleCheck} color={"#4caf50"} className={`hidden md:block text-3xl absolute left-[-2.5rem] transition duration-500 delay-200  ${calificado ? "!opacity-100" : ''}`}></FontAwesomeIcon> 
                    : <FontAwesomeIcon icon={faCircleXmark} color={"#DC3545"} className={`hidden md:block text-3xl absolute left-[-2.5rem] transition duration-500 delay-200 opacity-0 ${calificado ? "!opacity-100" : ''}`}></FontAwesomeIcon>) 
                    : ''}
                    <div className="w-fit m-[5px]">
                      <Droppable
                        isDropDisabled={column.items.length >= 1 ? true : false}
                        droppableId={columnId}
                        key={columnId}
                        className="m-[10px]"
                        direction={"horizontal"}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{background: snapshot.isDraggingOver ? "#d1d4d9" : "#e6e9ee"}}
                              className='w-[45px] rounded-xl transition-all overflow-visible max-h-[45px] min-h-[45px] flex items-center justify-center border p-2'
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                    isDragDisabled={calificado}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className="rounded-xl flex border text-white transition-all p-4 h-[35px] w-[35px]"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{userSelect: "none",background:item.background, ...provided.draggableProps.style}}
                                        >
                                          <div className="flex justify-center items-center w-full">
                                            <span className="text-sm flex items-center justify-center md:text-base w-full">
                                              <span className="flex-1 text-white">{item.orden}</span>
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                    <h6 className="text-sm md:text-base m-0 pl-2 w-full max-w-[20rem] text-[#697d99] flex items-center">
                          {column.imagen ? 
                          <LightboxWrapper handleClick={()=>setLightboxImage(column.imagen)}>
                           <ImagenValidada className="h-[100px] object-fill w-max-[8rem] shadow border rounded-xl aspect-square"  src={column.imagen}/>
                          </LightboxWrapper>
                           : '' }
                          {column.texto ? <span className="flex-1 ml-2">{column.texto}</span>: ''}
                          {column.audio  ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white'} audio={column.audio}/> : ''}
                    </h6>
                  </div>
                );
              })}
          </div>
        </DragDropContext>
      </div>
      {evaluable ? 
      <div className="flex align-items-center justify-center mt-10 mb-4">
        <button 
          className="m-5 py-3 px-10 rounded-full inline" 
          onClick={() => {setColumns(columnsFromBackend);setCalificado(false)}}
        >
          <Trans>Limpiar</Trans>
        </button>
        <button
         className="border bg-nimbus text-white m-5 py-3 px-10 rounded-full inline" 
         onClick={calificar}>
          <Trans>{iniciado ? 'Enviar y cerrar' : 'Cerrar'}</Trans>
        </button>
      </div>
      :''}
    </div>
    </>
  );
}

ArrastrarEtiquetas.defaultProps = {
  evaluable:true,
}

export default ArrastrarEtiquetas;
