import React, { Fragment, useContext, useEffect, useState } from "react";
import SubHeaderCompraDetalle from "./fragments/SubHeaderCompraDetalle";
import DatosCompra from "./fragments/DatosCompra";
import ResumenCompra from "./fragments/ResumenCompra";
import LoadingScreen from "../../fragments/LoadingScreen";
import { useLocation } from "react-router-dom";
import { PaisesContext } from "../../../contexts/PaisesContext";
import { UserContext } from "../../services/user";
import { obtenerUserEstructuraInfo } from "../../utils/userUtils";

const CompraDetalle = () => {
  const { state } = useLocation();
  const { compraInfo, abreviatura } = state;
  const { user, activeRole } = useContext(UserContext);
  const { paises } = useContext(PaisesContext);
  const [ paisUser, setPaisUser ] = useState({});

  useEffect(() => {
    if(activeRole !== "" && paises.length > 0){
      const { paisEstructura } = obtenerUserEstructuraInfo(user, activeRole);
      let pais = paises.find((pais) => pais._id === paisEstructura);
      setPaisUser(pais);
    }
  },[activeRole, paises]);

  return (
    <Fragment>
      <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
        <SubHeaderCompraDetalle />
        <div className={`relative flex-1 flex flex-col px-4 sm:px-8 mt-6`}>
          <div className="bg-backgroundColor">
            <DatosCompra compraInfo={compraInfo} abreviatura={abreviatura} detallesPais={paisUser}/>
          </div>
          <div className="bg-backgroundColor mt-10">
            <ResumenCompra compraInfo={compraInfo} detallesPais={paisUser}/>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CompraDetalle;
