import React from 'react';
import NimbusModal from '../../../fragments/NimbusModal';
import CancelButton from '../../../fragments/Buttons/CancelButton';
import SubmitButton from '../../../fragments/Buttons/SubmitButton';

const EliminarCategoriaNotificacionModal = ({
  openModal,
  setOpenModal,
  handleSubmit,
  categoriaNotificacion,
}) => {
  return (
    <NimbusModal isOpen={openModal}>
      <div className='w-96 px-4 text-center mb-6'>
        <h1 className='text-nimbusDark font-bold'>
          Eliminar categoría de notificación
        </h1>
        <p>
          Al eliminar la categoría de notificación, todas las notificaciones
          asociadas a esta categoría serán asignadas a la categoría general.
          ¿Está seguro de que desea eliminar la categoría "
          <b>{categoriaNotificacion.nombre}</b>"?
        </p>
      </div>
      <div className='flex justify-center gap-x-4'>
        <CancelButton
          text='Cancelar'
          bgColor='bg-divider'
          onClick={() => setOpenModal(false)}
        />
        <SubmitButton text='Aceptar' onClick={handleSubmit} />
      </div>
    </NimbusModal>
  );
};

export default EliminarCategoriaNotificacionModal;
