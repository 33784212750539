import React, { Fragment, useContext, useEffect, useState } from 'react';
import LoadingScreen from '../../fragments/LoadingScreen';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../services/user';
import SubHeaderCategoriaNotificacionesDetalles from './fragments/SubHeaderCategoriaNotificacionesDetalles';
import CategoriaNotificacionesForm from './fragments/CategoriaNotificacionesForm';
import useFormSubmit from '../../hooks/useFormSubmit';
import useValidateForm from '../../hooks/useValidateForm';
import alertConfiguration from '../../utils/alertsUtils';
import { toast } from 'react-toastify';
import { getCategoriaNotificacionPorId, postCategoriaNotificacion, putCategoriaNotificacion } from '../../services/Api/CategoriaNotificaciones';
import CancelButton from '../../fragments/Buttons/CancelButton';
import SubmitButton from '../../fragments/Buttons/SubmitButton';

const INITIAL_VALUES_CATEGORIA_NOTIFICACIONES = {
  nombre: '',
  icono: '',
};

const CategoriasNotificacionesDetalles = ({ editar, ver }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, activeRole } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const obtenerCategoriaNotificacion = async () => {
    try {
      let categoriaNotificacionRes = await getCategoriaNotificacionPorId(params.categoriaNotificacionId);
      setValues({
        nombre: categoriaNotificacionRes.nombre,
        icono: categoriaNotificacionRes.icono,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la categoria de notificaciones', alertConfiguration);
    }
  };

  const categoriaNotificacionSubmit = async () => {
    try {
      let body = {
        nombre: values.nombre,
        icono: values.icono,
        usuario: user._id,
      };
      if (editar) {
        await putCategoriaNotificacion(params.categoriaNotificacionId, body);
        toast.success('Categoria de notificaciones actualizada', alertConfiguration);
      } else {
        await postCategoriaNotificacion(body);
        toast.success('Categoria de notificaciones creada', alertConfiguration);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
      toast.error('Error al guardar la categoria de notificaciones', alertConfiguration);
    }
  };

  const {
    handleChange,
    errors,
    values,
    isEnableSubmitButton,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    areRequiredFieldsFulfilled,
  } = useValidateForm(INITIAL_VALUES_CATEGORIA_NOTIFICACIONES);

  const [formStatus, handleSubmit] = useFormSubmit(categoriaNotificacionSubmit);

  useEffect(() => {
    if (!areRequiredFieldsFulfilled() || Object.keys(errors).length > 0 || values.icono === '') {
      setIsEnableSubmitButton(false);
    } else {
      setIsEnableSubmitButton(true);
    }
  }, [values]);

  useEffect(() => {
    if (editar || ver) {
      obtenerCategoriaNotificacion();
    } else {
      setLoading(false);
    }
  }, [activeRole, params]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col'>
          <SubHeaderCategoriaNotificacionesDetalles ver={ver} editar={editar} />
          <div className='flex flex-col flex-1 justify-around px-4 sm:px-20 mt-8 md:mt-0'>
            <CategoriaNotificacionesForm
              categoriaNotificacion={values}
              errors={errors}
              handleChange={handleChange}
              setCategoriaNotificacion={setValues}
              ver={ver}
              editar={editar}
            />
            <div className='flex justify-center gap-3 mt-8 md:mt-0'>
              <CancelButton
                text={ver ? 'Regresar' : 'Cancelar'}
                styles='!w-[12rem]'
                onClick={() => navigate(-1)}
              />
              {!ver && (
                <SubmitButton
                  text={'Guardar'}
                  styles='!w-[12rem]'
                  disabled={!isEnableSubmitButton}
                  loadingButton
                  loading={formStatus === 'submitting'}
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CategoriasNotificacionesDetalles;
