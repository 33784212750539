import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import Cronometro from "./ActividadesUtils/Cronometro";
import { Trans } from "react-i18next";

const HerramientaLectorTexto = ({value, lang, viewSourceText, isButtonless, speechSpeed, instrucciones}) =>{
  const [listening, setListening] = useState(false)
  const [paused, setPaused] = useState(false)
  const [finished, setFinished] = useState(false)
  const [time, setTime] = useState(0)

  const synth = window.speechSynthesis;

    function speakfnc() {
    stopfnc()
    const speakText = new SpeechSynthesisUtterance(value);
    setListening(true)
    setFinished(false)
    setPaused(false)
    //al terminar audio
    speakText.onend = (e => {
      setFinished(true)
    });
    //config de voz
    const voices = synth.getVoices();
    const esVoice = voices.reverse().find((el)=>(el.lang === 'es-ES'));
    const enVoice = voices.find((el)=>(el.lang === 'en-GB'));
    speakText.rate = speechSpeed
    if(lang !== 'es'){
      speakText.lang = 'en-US';
      speakText.lang = 'en-GB';
      speakText.voice = enVoice
    }else{
      speakText.lang = 'es-ES';
      speakText.voice = esVoice
    }
    synth.speak(speakText);
  }

  function stopfnc() {
    synth.cancel();
  }

  function resumefnc() {
    synth.resume()
    setPaused(false)
  }

  function pausefnc(){ 
    synth.pause();
    setPaused(true)
  }

  const stopWhenLeaving = () =>{
    window.addEventListener("beforeunload", stopfnc);
    stopfnc()
  }

  //Ditiene la voz en caso de iniciar o terminar
  useEffect(()=>{
    stopWhenLeaving()
    return () => {
      stopWhenLeaving()
    }
  },[])

  function restartfnc(){
    synth.cancel()
    setTimeout(()=>{
      speakfnc()
    },500)
  }

  //Resuelve los bugs que chrome tiene con la api de speech
  useEffect(()=>{
    if(!paused && time % 10000 === 0 && time !== 0){
      synth.pause()
      synth.resume()
    }

    if(paused && time % 10000 === 0 && time !== 0){
      synth.resume()
      synth.pause()
    }
  },[time])

  return (
      <div className="parent my-5">
        {instrucciones && <div className="act-instructions mb-5">{instrucciones}</div>}
        <Cronometro time={time} running={true} setTime={setTime} style={'opacity-0'}/>
        <div className="btnparent">
        {viewSourceText ? 
          <div className="max-w-activity mb-10 flex flex-col mx-5 md:mx-auto">
              <div className={`block custom-transition md:text-xl whitespace-pre-wrap text-justify p-4`}>{value}</div>
          </div>:
          <div className={`${listening && !paused && !finished ? 'audio-animation' : ''}`}>
            <div className="aspct-square p-10 flex w-fit items-center justify-center rounded-full text-[5rem] transition hover:brightness-125 block mx-auto mb-6 border-[2px] cursor-pointer border-[#1c7ccc] bg-[#1c7ccc]">
              <FontAwesomeIcon onClick={speakfnc} className=" text-white w-[6.5rem] h-[6.5rem]" icon={faVolumeHigh}></FontAwesomeIcon>
            </div>
          </div>}
          {isButtonless ? null : 
          <div className="w-fit mx-auto">
            {listening ? 
            <>
              {finished ? null : 
                paused ? 
                <button className="bg-[#1c7ccc] text-white py-4 px-10 rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base" onClick={resumefnc}>
                <FontAwesomeIcon className="text-lg text-white mr-2" icon={faPlay}></FontAwesomeIcon>
                <Trans>Resumir</Trans>
                </button>:
                <button className="bg-[#1c7ccc] text-white py-4 px-10 rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base" onClick={pausefnc}>
                <FontAwesomeIcon className="text-lg text-white mr-2" icon={faPause}></FontAwesomeIcon>
                <Trans>Pausar</Trans>
                </button>
              }
              <button className="bg-[#1c7ccc] text-white py-4 px-10 rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base" onClick={restartfnc}>
              <FontAwesomeIcon className="text-lg text-white mr-2" icon={faRotateRight}></FontAwesomeIcon>
              <Trans>Repetir</Trans>
              </button>
            </>
            :
            <button className="bg-[#1c7ccc] text-white py-4 px-10 rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base" onClick={speakfnc}>
            <FontAwesomeIcon className="text-lg text-white mr-2" icon={faPlay}></FontAwesomeIcon>
            <Trans>Escuchar</Trans>
            </button>
            }
          </div>}
        </div>
      </div>
  );
  

}

export default HerramientaLectorTexto

