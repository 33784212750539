import { toast } from "react-toastify";
import alertConfiguration from "./alertsUtils";

export const estatusOptions = [
  { _id: "BORRADOR", nombre: "Borrador" },
  { _id: "ENVIADO", nombre: "Enviado" },
  { _id: "PROGRAMADO", nombre: "Programado" },
];

export const visibilidadOptions = [
  { _id: 1, nombre: "Oculto" },
  { _id: 2, nombre: "Visible" },
];

export const CONTENT = {
  texto: "61fb5f323f30e059b82c3da8",
  audio: "61fb5f323f30e059b82c3db4",
  video: "61fb5f323f30e059b82c3dae",
  imagen: "61fb5f323f30e059b82c3daa",
  archivo: "61fb5f323f30e059b82c3db2",
};

export const CONTENT_OPTIONS = [
  {
    _id: CONTENT.texto,
    nombre: "Texto",
  },
  {
    _id: CONTENT.audio,
    nombre: "Audio",
  },
  {
    _id: CONTENT.video,
    nombre: "Video",
  },
  {
    _id: CONTENT.imagen,
    nombre: "Imagen",
  },
  {
    _id: CONTENT.archivo,
    nombre: "Archivo",
  },
];

const nombreSeparado = (texto) => {
  if (texto.length <= 30) return texto;

  let extensionIdx = texto.lastIndexOf(".");

  return texto.substring(0, 19) + ".." + texto.substring(extensionIdx);
};

export const validateFileType = (file, componentLabel) => {
  if (
    componentLabel === "audio" &&
    file.type !== "audio/mp3" &&
    file.type !== "audio/mpeg"
  ) {
    toast.error(
      <span title={file.name}>
        {`El audio ${nombreSeparado(
          file.name
        )} no contiene la extensión permitida .mp3`}
      </span>,
      alertConfiguration
    );
    return false;
  } else if (componentLabel === "video" && file.type !== "video/mp4") {
    toast.error(
      <span title={file.name}>
        {`El video ${nombreSeparado(
          file.name
        )} no contiene la extensión permitida .mp4`}
      </span>,
      alertConfiguration
    );
    return false;
  } else if (
    componentLabel === "imagen" &&
    !file.type.match(/^image\/(jpg|jpeg|png)$/) &&
    file.name.includes(".jfif")
  ) {
    toast.error(
      <span title={file.name}>
        {`La imagen ${nombreSeparado(
          file.name
        )} no contiene las extensiones permitidas .jpg, .jpeg, .png`}
      </span>,
      alertConfiguration
    );
    return false;
  } else if (componentLabel === "archivo" && file.type !== "application/pdf") {
    toast.error(
      <span title={file.name}>
        {`El archivo ${nombreSeparado(
          file.name
        )} no contiene la extensión permitida .pdf`}
      </span>,
      alertConfiguration
    );
    return false;
  }
  return true;
};

export const validateFileSize = (file, componentLabel) => {
  if (componentLabel === "audio" && file.size > 20 * 1024 * 1024) {
    toast.error(
      <span title={file.name}>{`El audio ${nombreSeparado(
        file.name
      )} excede el tamaño máximo permitido de 20 MB`}</span>,
      alertConfiguration
    );
    return false;
  } else if (componentLabel === "video" && file.size > 30 * 1024 * 1024) {
    toast.error(
      <span title={file.name}>{`El video ${nombreSeparado(
        file.name
      )} excede el tamaño máximo permitido de 30 MB`}</span>,
      alertConfiguration
    );
    return false;
  } else if (componentLabel === "imagen" && file.size > 5 * 1024 * 1024) {
    toast.error(
      <span title={file.name}>{`La imagen ${nombreSeparado(
        file.name
      )} excede el tamaño máximo permitido de 5 MB`}</span>,
      alertConfiguration
    );
    return false;
  } else if (componentLabel === "archivo" && file.size > 5 * 1024 * 1024) {
    toast.error(
      <span title={file.name}>{`El archivo ${nombreSeparado(
        file.name
      )} excede el tamaño máximo permitido de 5 MB`}</span>,
      alertConfiguration
    );
    return false;
  }
  return true;
};
