import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useGestorAvisosDetalles } from "../GestorAvisosDetallesContext";
import editListIcon from "../../../../../assets/icons/editListIcon.svg";
import ReactLoading from "react-loading";
import IconVideo from "../../../../../assets/icons/icons-bloques/video_default.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAvisoData } from "../../../../../reducers/avisoData/avisoDataSelector";
import { uploadFileHandler } from "../../../../../reducers/avisoData/avisoDataAction";

function VideoComponent({ pieDeFoto, setCkEditorTable, contenidoId, path }) {
  const [video, setVideo] = useState(path ?? null);
  const { editandoTexto, ver } = useGestorAvisosDetalles();
  const dispatch = useDispatch();
  const { contenido, isUploadingFile: loading } = useSelector(selectAvisoData);
  const fileRef = useRef();

  return (
    <div
      className={`hover:border-[#c6c6c6] transition-all rounded-md pl-5 pb-5 pr-5 p-2 mb-4 border ${
        ver ? "cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <div className="flex justify-between pb-2">
        <button
          onClick={() => fileRef.current.click()}
          className={`border rounded-l-md rounded-b-md bg-nimbus text-white px-2 ${
            ver ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          disabled={ver}
        >
          Subir archivo
        </button>
        <div className="border rounded-l-md rounded-b-md bg-nimbus text-white px-2">
          Video
        </div>
        <input
          type="file"
          ref={fileRef}
          onChange={(e) =>
            dispatch(
              uploadFileHandler(
                e.target.files[0],
                setVideo,
                contenido,
                contenidoId,
                "video",
                video
              )
            )
          }
          hidden
          disabled={editandoTexto || ver}
          accept=".mp4"
        />
      </div>
      <div className="flex justify-center w-full max-w-[1280px] mx-auto">
        <div
          className={`p-2 w-full relative ${
            editandoTexto || ver ? "cursor-not-allowed" : ""
          }`}
        >
          {loading && (
            <div className="w-full h-full absolute bg-[#fff] flex flex-col items-center justify-center z-10">
              <ReactLoading
                type="spin"
                color="#1A3D82"
                height={"5%"}
                width={"5%"}
                className=""
              />
              <div className="block mt-5">Puede tardar algunos minutos</div>
            </div>
          )}
          <div className="w-full h-full overflow-hidden rounded-lg">
            <div className="w-full h-full aspect-video border rounded-lg overflow-hidden">
              {!video ? (
                <img
                  className={`h-full w-full rounded-lg !relative`}
                  src={IconVideo}
                  alt=""
                />
              ) : (
                <ReactPlayer
                  url={video}
                  width="100%"
                  height="100%"
                  controls={ver || video}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[960px] mx-auto">
        {pieDeFoto === "" || pieDeFoto === undefined ? (
          <div
            className={`text-[#697D99] cursor-pointer flex justify-center items-center ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
            onClick={() => {
              if (!editandoTexto && !ver) setCkEditorTable(false);
            }}
          >
            <img src={editListIcon} alt="" />
            <span>Editar pie</span>
          </div>
        ) : (
          <div
            onClick={() => {
              if (!editandoTexto && !ver) setCkEditorTable(false);
            }}
            className={`text-[#697D99] cursor-pointer items-center mt-2 flex justify-center ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: pieDeFoto }}
          />
        )}
      </div>
    </div>
  );
}

export default VideoComponent;
