import React from 'react'
import { AsignarArticulosProvider } from '../../../contexts/AsignarProductosContext';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../../../components/fragments/NotFound';
import AsignarProductos from '../../../components/comercial/AsignarProductos/AsignarProductos';

const AsignarArticulosRoutes = () => {
    return (
        <AsignarArticulosProvider>
          <Routes>
            <Route index path="/" element={<AsignarProductos />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AsignarArticulosProvider>
      );
}

export default AsignarArticulosRoutes