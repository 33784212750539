import { _delete, _get, _post, _put } from "../../http";

//Roles
export const getRoles = () => _get('/roles');
export const getRol = (rolId) => _get(`/roles/${rolId}`);
export const postRol = (body) => _post(`/roles`,body);
export const putRol = (rolId, body) => _put(`/roles/${rolId}`, body);
export const changeStatusRol = (rolId, body) => _put(`/roles/actualizarStatus/${rolId}`, body);
export const deleteRol = (rolId) => _delete(`/roles/${rolId}`);
export const getRolesFiltrados = (categoriaId) => _get(`/roles/obtenerPorCategoria/${categoriaId}`);
export const getPermisosRol = (rolNombre) => _get(`/roles/porNombre/${rolNombre}`);

//Permisos
export const getPermiso = (rutaId) => _get(`/permisos/${rutaId}`);
export const getPermisos = () => _get('/permisos');
export const postPermiso = (body) => _post(`/permisos`,body);
export const putPermiso = (permisoId, body) => _put(`/permisos/${permisoId}`, body);
export const changeStatusPermiso = (permisoId, body) => _put(`/permisos/actualizarStatus/${permisoId}`, body);
export const deletePermiso = (permisoId) => _delete(`/permisos/${permisoId}`);

//Categorias
export const postCategoria = (body) => _post(`/categorias`,body);
export const putCategoria = (categoriaId, body) => _put(`/categorias/${categoriaId}`, body);
export const getCategorias = () => _get('/categorias');
export const getCategoriaNombre = (categoriaNombre) => _get(`/categorias/porNombre/${categoriaNombre}`);
export const getCategoria = (categoriaId) => _get(`/categorias/${categoriaId}`);
export const changeStatusCategoria = (categoriaId, body) => _put(`/categorias/actualizarStatus/${categoriaId}`, body);
export const deleteCategoria = (categoriaId) => _delete(`/categorias/${categoriaId}`);