import React from 'react';
import InputText from '../../../fragments/Inputs/InputText';
import InputSelect from '../../../fragments/Inputs/InputSelect';

const OPTIONS_SELECT = [
  { _id: true, nombre: 'Activo' },
  { _id: false, nombre: 'Inactivo' },
];

const AgregarFormadorForm = ({
  formador,
  errors,
  handleChange,
  setFormador,
  ver,
  editar,
}) => {
  return (
    <div className='flex flex-col'>
      <div className='grid grid-cols-2 gap-2 w-full text-left mb-6'>
        <InputText
          id={'nombre'}
          name={'nombre'}
          label={'Nombre(s)'}
          placeholder={'Ingrese el/los nombres'}
          value={formador.nombre}
          onChange={(e) => handleChange(e, 'nombre')}
          error={errors['nombre']}
          styles='!w-full'
          disabled={ver}
        />
        <InputSelect
          id={'activo'}
          name={'activo'}
          label={'Estatus'}
          options={OPTIONS_SELECT}
          value={formador.esActivo}
          onChange={(e) =>setFormador({ ...formador, esActivo: e.target.value })}
          styles='!w-full'
          disableFirstOption={true}
          customLabel='Seleccione el estatus del formador'
          disabled={ver}
        />
      </div>
      <div className='grid grid-cols-2 gap-2 w-full text-left mb-6'>
        <InputText
          id={'primerApellido'}
          name={'primerApellido'}
          label={'Primer apellido'}
          placeholder={'Ingrese el primer apellido'}
          value={formador.primerApellido}
          onChange={(e) => handleChange(e, 'primerApellido')}
          error={errors['lastname1']}
          styles='!w-full'
          disabled={ver}
        />
        <InputText
          id={'segundoApellido'}
          name={'segundoApellido'}
          label={'Segundo apellido'}
          placeholder={'Ingrese el segundo apellido'}
          value={formador.segundoApellido}
          onChange={(e) => handleChange(e, 'segundoApellido')}
          error={errors['lastname2']}
          styles='!w-full'
          disabled={ver}
        />
      </div>
      <div className='grid grid-cols-2 gap-2 w-full text-left mb-6'>
        <InputText
          id={'email'}
          name={'email'}
          label={'Correo electrónico'}
          placeholder={'Ingrese un correo electrónico'}
          value={formador.email}
          onChange={(e) => handleChange(e, 'email')}
          error={errors['email']}
          styles='!w-full'
          disabled={ver}
        />
        <InputText
          id={'telefono'}
          name={'telefono'}
          label={'Teléfono'}
          placeholder={'Ingrese un teléfono'}
          value={formador.telefono}
          onChange={(e) => handleChange(e, 'telefono')}
          error={errors['telefono']}
          styles='!w-full'
          disabled={ver}
        />
      </div>
    </div>
  );
};

export default AgregarFormadorForm;
