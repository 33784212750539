import { createContext, useReducer } from "react";
import { packageProductsReducer } from "../reducers/paquetesProductos/paquetesProductosReducer";

const INITIAL_STATE = {
  productos: [],
  errores: [],
  isAddProductsOpen: false,
  filtrosProductos: {},
  filtrosPaquetes: {},
};

export const PaqueteProductosContext = createContext({
  productos: [],
  errores: [],
  isAddProductsOpen: false,
  filtrosProductos: {},
  filtrosPaquetes: {},
});

export const PaqueteProductosProvider = ({ children }) => {
  const [{ productos, isAddProductsOpen, errores, filtrosProductos, filtrosPaquetes }, dispatch] = useReducer(packageProductsReducer, INITIAL_STATE);

  const value = {
    productos,
    isAddProductsOpen,
    errores,
    filtrosProductos,
    filtrosPaquetes,
    dispatch,
  };

  return <PaqueteProductosContext.Provider value={value}>{children}</PaqueteProductosContext.Provider>;
};
