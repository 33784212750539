import { createContext, useReducer } from "react";
import { asignarArticulosReducer } from "../reducers/asginarArticulos/asignarArticulosReducer";

const INITIAL_STATE = {
  articulos: [],
  errores: [],
  esAgregarArticulosAbierto: false,
  habilitarBoton : false
};

export const AsignarArticulosContext = createContext({
  articulos: [],
  errores: [],
  esAgregarArticulosAbierto: false,
  habilitarBoton : false
});

export const AsignarArticulosProvider = ({ children }) => {
  const [{ articulos, esAgregarArticulosAbierto, errores, habilitarBoton }, dispatch] = useReducer(
    asignarArticulosReducer,
    INITIAL_STATE
  );

  const value = {
    articulos,
    esAgregarArticulosAbierto,
    errores,
    habilitarBoton,
    dispatch,
  };

  return <AsignarArticulosContext.Provider value={value}> {children} </AsignarArticulosContext.Provider>;
};
