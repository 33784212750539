import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as LibretaIcon } from '../../../../assets/icons/libretaIcon.svg';
import { UserContext } from '../../../services/user';
import Paquete from '../../../../assets/img/paquetesMarketplace.png';
import Producto from '../../../../assets/img/producto.png';

const ResumenPedidoRow = ({ ventaInfo, estatus }) => {
  const user = useContext(UserContext);
  const { _id, variacion, cantidad, paquete, producto, articulos } =
    ventaInfo;
  const [origen, setOrigen] = useState('');
  const [urlImagen, setUrlImagen] = useState('');
  const [productosPaquete, setProductosPaquete] = useState([]);
  const getOrigen = () => {
    if (producto?.plantel !== undefined || paquete?.plantel !== undefined) {
      setOrigen('Nuevo');
    } else {
      setOrigen('De linea');
    }
  };
  const getData = () => {
    if (articulos !== undefined) {
      if (articulos[0]?.producto !== undefined) {
        setUrlImagen(
          articulos[0]?.producto?.producto?.imagen !== ''
            ? articulos[0]?.producto?.producto?.imagen
            : Producto
        );
      } else {
        setUrlImagen(
          articulos[0]?.paquete?.paquete?.imagen !== ''
            ? articulos[0]?.paquete?.paquete?.imagen
            : Paquete
        );
      }
    } else if (producto !== undefined) {
      setUrlImagen(
        producto?.producto?.imagen !== '' ? producto?.producto?.imagen : (producto?.producto?.carrusel.length > 0 ? producto?.producto?.carrusel[0] : Producto)
      );
    } else if (paquete !== undefined) {
      setProductosPaquete(paquete?.paquete?.productos)
      setUrlImagen(
        paquete?.paquete?.imagen !== '' ? paquete?.paquete?.imagen : (paquete?.paquete?.carrusel.length > 0 ? paquete?.paquete?.carrusel[0] : Paquete)
      );
    }
  };

  useEffect(() => {
    if (
      user.activeRole === 'DIR_PLANTEL' ||
      user.activeRole === 'ADMIN_OPERATIVO'
    ) {
      getOrigen();
    }
    getData();
  }, []);
  return (
    <tr className='p-2 text-left bg-white'>
      <td className='border-b-4 rounded-bl-sm text-left font-sans p-2'>
        <img src={urlImagen} className='w-16 h-16 object-contain' />
      </td>
      <td className='border-b-4 font-sans text-left'>
        {producto?.producto?.nombre ||
          paquete?.paquete?.nombre ||
          articulos[0]?.paquete?.paquete?.nombre ||
          articulos[0]?.producto?.producto?.nombre}
      </td>
      <td className='border-b-4 font-sans text-left p-2'>
        <div className='flex gap-2'>
          <p className='text-[#808080]'>Id del artículo:</p>
          <p className='text-[#B3B3B3]'>
            {producto?.producto?._id || paquete?.paquete?._id}
          </p>
        </div>
        {articulos !== undefined
          ? articulos[0]?.variacion?.seleccionValores.map((variacion) => (
              <div className='flex gap-2 align-middle'>
                <p className='text-[#808080]'>
                  {variacion?.variable?.variable}:
                </p>
                <p className='text-[#B3B3B3]'>{variacion?.valor?.valor}</p>
              </div>
            ))
          : variacion !== undefined &&
            variacion?.seleccionValores.map((variacion) => (
              <div className='flex gap-2 align-middle' key={variacion._id}>
                <p className='text-[#808080]'>
                  {variacion?.variable?.variable}:
                </p>
                <p className='text-[#B3B3B3]'>{variacion?.valor?.valor}</p>
              </div>
            ))}
        {productosPaquete.length > 0 && productosPaquete.map((producto) => (
          <div key={producto._id} className='mt-2'>
            <div className='flex gap-2 align-middle'>
              <p className='text-[#808080]'>Nombre del producto:</p>
              <p className='text-[#B3B3B3]'>{producto.producto.producto.nombre}</p>
            </div>
            <div className='flex gap-2 align-middle'>
              <p className='text-[#808080]'>Cantidad:</p>
              <p className='text-[#B3B3B3]'>{producto.cantidad}</p>
            </div>
          </div>
        ))  
        }
      </td>
      <td className='border-b-4  font-sans text-left'>
        Cantidad: {cantidad || articulos[0]?.cantidad}
      </td>
      {(user.activeRole === 'DIR_PLANTEL' ||
        user.activeRole === 'ADMIN_OPERATIVO') && (
        <>
          <td className={`border-b-4 rounded-br-sm  font-sans text-center `}>
            <div>{origen}</div>
          </td>
          <td
            className={`border-b-4 rounded-br-sm  font-sans text-left ${
              producto?.producto?.estatus === 'PREPARANDO' ||
              estatus === 'PREPARANDO'
                ? 'text-[#CF9C36]'
                : 'text-[#20AC09]'
            }`}
          >
            <div>
              {producto?.producto?.estatus === 'PREPARANDO' ||
              estatus === 'PREPARANDO'
                ? 'Preparando'
                : 'Completado'}
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default ResumenPedidoRow;
