import { useEffect, useState } from "react"

export default function ImagenTexto({imagen,texto, invertir, pieDeFoto, background}) {
    /* const [cleanText, setCleanText] = useState('')
    useEffect(()=>{
        let newText = texto.replaceAll('<h1>','<h1 class="font-medium text-2xl text-[#1A3D82]">')
        newText = newText.replaceAll('<p>','<p class="text-xl text-[#3A404C] font-normal leading-[150%]">')
        setCleanText(newText)
    },[texto]) */
    return(
        <div className={`visor mb-4 flex justify-center w-full flex-col ${invertir ? 'md:flex-row-reverse' : 'md:flex-row'} `}>
            {/* <div className="flex  w-2/4 justify-start p-2" dangerouslySetInnerHTML={{__html:cleanText}}> */}
            <div className={`p-2 md:p-4 w-full md:w-2/4 rounded-lg my-4 h-fit ${background}`} dangerouslySetInnerHTML={{__html:texto}}></div>
            <div className="w-full md:w-2/4 mx-auto md:m-4">
                <img src={imagen} alt={texto} className='rounded-xl shadow-xl w-full h-auto'/>
                <div dangerouslySetInnerHTML={{__html:pieDeFoto}} className='mt-2'></div>
            </div>
        </div>
    )
}
