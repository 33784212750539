import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../fragments/SubHeader';
import BreadCrumbContainer from '../../../fragments/BreadCrumbContainer';
import BreadCrumb from '../../../fragments/BreadCrumb';

const SubHeaderVentaInfo = ({ ventaId }) => {
  const navigate = useNavigate();
  return (
    <SubHeader>
      <BreadCrumbContainer>
        <BreadCrumb
          route={'Mis pedidos /'}
          onClick={() => navigate(`/marketplace/misVentas`)}
        />
        <BreadCrumb route={ventaId} active />
      </BreadCrumbContainer>
    </SubHeader>
  );
};

export default SubHeaderVentaInfo;
