import React from "react";
import ArticuloDisponibleRow from "./ArticuloDisponibleRow";

const ArticulosDisponiblesTable = ({ articulos, seleccionarArticulo }) => {
  return (
    <div className="overflow-x-auto mt-6">
      <table className="w-full table-auto border-separate font-sans">
        <thead>
          <tr className="text-left text-black font-semibold">
            <th></th>
            <th></th>
            <th>Artículo</th>
            <th>Categoría</th>
            <th>Nivel educativo</th>
            <th>Grado académico</th>
            <th>Origen</th>
            <th>Estatus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {articulos.map((articulo) => (
            <ArticuloDisponibleRow
              key={articulo._id}
              articuloInfo={articulo}
              seleccionarArticulo={seleccionarArticulo}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArticulosDisponiblesTable;
