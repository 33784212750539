import { useEffect, useState } from "react";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";

const SelectItems = () => {
  const { selectAllUsers, usersAdded, currentData } =
    useListaDifusionDetalles();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const selectedUsers = currentData().filter((user) => user.esSeleccionado);

  useEffect(() => {
    const allSelected =
      currentData().length > 0 &&
      currentData().every((user) => user.esSeleccionado);
    setIsSelectedAll(allSelected);
  }, [currentData]);

  const handleChecked = () => {
    setIsSelectedAll((state) => !state);
    selectAllUsers(!isSelectedAll, currentData());
  };

  return (
    <div className="w-full border-solid border-2 rounded-lg p-1 flex flex-row justify-center gap-5 bg-white">
      <div className="rounded-md flex flex-row items-center gap-2">
        <input
          type="checkbox"
          name={`selectAllCheckbox`}
          id={`selectAllCheckbox`}
          className="rounded-md border-2 border-gray-300 my-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
          checked={isSelectedAll}
          onChange={handleChecked}
          disabled={usersAdded?.length === 0}
        />
        <label>
          <p className="text-sm md:text-base text-left font-semibold">Todos</p>
        </label>
      </div>
    </div>
  );
};

export default SelectItems;
