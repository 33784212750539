import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { ActividadContext } from "../ActividadesUtils/ActWrapper";
import useGrabar from "../ActividadesUtils/useGrabar";
import { toCleanText } from "../ActividadesUtils/ActividadesUtils";
import Microphone from "./Microphone";
import Cronometro from "../ActividadesUtils/Cronometro";
import { Trans } from "react-i18next";
import ImagenValidada from "../ActividadesUtils/RecursosValidados/ImagenValidada";

export default function HerramientaPronunciacion({
  lang,
  inputList,
  umaBloque,
  guardarCalificacion,
  instrucciones,
}) {
  const {
    isListening,
    transcript,
    stopHandle,
    handleReset,
    resetTranscript,
    handleListing,
  } = useGrabar({ lang });
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [generalScore, setGeneralScore] = useState(0);
  const [currentScore, setcurrentScore] = useState(0);
  const [iniciado, setIniciado] = useState(false);
  const [time, setTime] = useState(0);
  const [initialSlideTime, setInitialSlideTime] = useState(0);
  const [slidesCorrectas, setSlidesCorrectas] = useState(0); //usadas solo en umas de tipo evaluación
  const activityContext = useContext(ActividadContext);
  const currentText = toCleanText(inputList[currentWordIndex].texto);

  const transcriptArr = toCleanText(transcript).split(" ");

  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  useEffect(() => {
    //busca la palabra actual dentro de las últimas 5 palabras
    const textFirstEl = currentText.split(" ")[currentScore];
    const lastSpokenWord = transcriptArr[transcriptArr?.length - 1];
    if (!lastSpokenWord) return;
    if (!textFirstEl) return;
    const sim = similarity(
      transcriptArr[transcriptArr.length - 1],
      textFirstEl
    );
    if (sim > 0.8) {
      setcurrentScore(currentScore + 1);
    }
    if (!iniciado) setIniciado(true);
  }, [transcriptArr]);

  useEffect(() => {
    stopHandle();
  }, []);

  const showNextSentence = () => {
    setTimeout(() => {
      handleReset();
      if (currentWordIndex === inputList.length - 1) {
        handleCalificar();
      } else {
        setCurrentWordIndex(currentWordIndex + 1);
        setGeneralScore(generalScore + currentScore);
        resetTranscript();
        setInitialSlideTime(time);
      }
    }, 500);
  };

  useEffect(() => {
    setcurrentScore(0);
  }, [generalScore]);

  const handleCalificar = () => {
    const score = generalScore + currentScore;
    const allWordsLength = inputList
      .map((el) => toCleanText(el.texto))
      .map((el) => el.split(" ").length)
      .reduce((partialSum, a) => partialSum + a, 0);
    const calificacion = (score / allWordsLength) * 100;
    if (iniciado) {
      activityContext.postCalificacionActividad(calificacion);
      if (umaBloque) guardarCalificacion(umaBloque, slidesCorrectas, "");
    } else {
      activityContext.postCalificacionActividad(null);
    }
    const roundedTime = Math.round(time / 1000);
    if (score >= 50) {
      const wordsPerMinute = Math.round((score / roundedTime) * 60);
      activityContext.getAdditionalData([
        {
          type: "Velocidad de lectura",
          value: wordsPerMinute,
          label: "palabras por minuto",
        },
      ]);
    }
  };

  useEffect(() => {
    if (currentScore === currentText.split(" ").length - 1) {
      setSlidesCorrectas(slidesCorrectas + 1);
    }

    if (currentScore === currentText.split(" ").length) {
      setTimeout(() => {
        showNextSentence();
      }, 500);
    }
  }, [currentScore]);

  const tryAgain = () => {
    handleReset();
    setcurrentScore(0);
    setTime(initialSlideTime);
  };

  const synth = window.speechSynthesis;

  function speakfnc(value) {
    synth.cancel();
    const speakText = new SpeechSynthesisUtterance(value);

    //config de voz
    const voices = synth.getVoices();
    const esVoice = voices.reverse().find((el) => el.lang === "es-ES");
    const enVoice = voices.find((el) => el.lang === "en-GB");
    speakText.rate = 1;
    if (lang !== "es") {
      speakText.lang = "en-US";
      speakText.lang = "en-GB";
      speakText.voice = enVoice;
    } else {
      speakText.lang = "es-ES";
      speakText.voice = esVoice;
    }
    synth.speak(speakText);
  }

  return (
    <>
      {instrucciones && (
        <div className="act-instructions !max-w-[968px]">{instrucciones}</div>
      )}
      <Cronometro
        time={time}
        running={true}
        setTime={setTime}
        style={"opacity-0 absolute pointer-events-none"}
      />
      <div className="max-w-[968px] px-4 min-w-[340px] w-full mx-auto pt-10">
        {inputList.map((el, i) => (
          <div
            key={el._id}
            className={`w-full mx-auto text-xl text-[#627d92] custom-transition ${
              i === currentWordIndex
                ? "opacity-100"
                : "opacity-0 h-0 w-0 pointer-events-none"
            }`}
          >
            <div
              className={`flex flex-col sm:flex-row  mb-2 text-sm ${
                el.imagen ? "justify-between" : "justify-center"
              }`}
            >
              {el.imagen ? (
                <ImagenValidada
                  className="border mx-auto rounded-lg shadow-lg mb-4 w-full sm:w-1/2 max-h-[24rem] object-cover"
                  src={el.imagen}
                />
              ) : null}
              <div className="w-full sm:w-1/2">
                {/* <div className="flex justify-between mb-2 text-sm">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      className="text-sm mr-2 text-[#627d92]"
                      icon={faRectangleList}
                    ></FontAwesomeIcon>
                    <div className="inline-block">
                      {i + 1} <Trans i18nKey="slideOf"></Trans>{" "}
                      {inputList.length}
                    </div>
                  </div>
                  <div className=""></div>
                </div> */}
                <div className="text-left text-[#5f657e] mb-6 text-[21px] leading-[32px] sm:pl-8">
                  <div className="mr-2 mb-2 text-xl font-bold block">
                    {/* <Trans i18nKey="sentenceLabel"></Trans> */}
                  </div>
                  {el.texto.split(" ").map((item, index) => (
                    <span
                      onClick={() => speakfnc(item)}
                      className={`cursor-pointer hover:opacity-40 mr-2 custom-transition whitespace-pre ${
                        currentScore <= index ? "" : "text-lightBlue"
                      }`}
                    >
                      {item}
                    </span>
                  ))}
                  <Microphone
                    isListening={isListening}
                    lang={lang}
                    transcript={""}
                    stopHandle={showNextSentence}
                    handleListing={handleListing}
                    tryAgain={tryAgain}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
