export default function InputText({value,onBlur,onChange,name,id,disabled = false,label,placeholder,error='', styles = "", maxLength=undefined, wrapperStyles = "", labelStyles ="", extraInfoStyles = "", extraInfoLines, disabledStyles = false, password = false}) {
    return(
        <div className={`grid gap-y-2 ${wrapperStyles}`}>
            <div className="flex truncate space-x-3 items-center">
            <p className={`font-semibold text-base text-[#0D0C22] ${!extraInfoLines && "truncate"} ${labelStyles}`}>{label}</p>
            {
                extraInfoLines && 
                <span className={`flex gap-1 truncate justify-center before:content-['('] after:content-[')'] before:mb-0.5 after:mb-0.5  ${extraInfoStyles}`}>
                    <span className="flex flex-col truncate justify-center">
                    {extraInfoLines.map((text) => <p key={text} className={`font-semibold text-xs text-[#0D0C22] truncate ${labelStyles}`}>{text}</p>)}
                    </span>
                </span>
            }
            </div>
            <div className="relative">
                <input
                    className={`peer border-2 h-[40px] focus:outline-none rounded-lg p-2 w-80 lg:w-full ${styles} ${error === '' ? 'border-gray-300' : 'border-red-400'} ${disabledStyles && "bg-gray-200 opacity-70"}`}
                    type={`${password ? 'password' : 'text'}`}
                    value={value}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChange={onChange}
                    name={name}
                    id={id}
                    disabled={disabled}
                    maxLength={maxLength}
                />
                <p className="font-thin text-xs text-red-400">{error}</p>
            </div>
        </div>
    )
}