import { useAddUsers } from "./AddUsersContext";
import { useEffect, useState } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import {
  getAllUsuariosPorEstructura,
  getEstructurasRelacionadas,
  getGradosPorNivel,
  getGruposFiltered,
  getPlantelNiveles,
  getUsuariosPorEstructura,
} from "../../../services/Api/ListasDifusion/AddUsers";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { ROLES } from "../../../utils/roles";
import { getGrupos } from "../../../services/Api/Grupos";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import {
  DIR_EDITORIAL_SELECTED_ROL_OPTIONS,
  GENERAL_DISTRIBUIDOR_ROL_OPTIONS,
  DISTRIBUIDOR_ROL_OPTIONS,
  CORPORATIVO_ROL_OPTIONS,
  PLANTEL_ROL_OPTIONS,
  DIR_PLANTEL_ROL_OPTIONS,
  ADMIN_OPERATIVO_ROL_OPTIONS,
  PROFESOR_ROL_OPTIONS,
  TODOS_OPTION,
} from "../../../utils/listasDifusionUtils";

const AddUsersFilters = ({
  estructuraUser,
  setUsuariosList,
  setIsLoading,
  filtro,
  setFiltro,
}) => {
  const { destinatarios, setDestinatariosFiltro, userRol } = useAddUsers();

  const { tipoEstructuraRequest, estructuraId, siguienteEstructura } =
    obtenerUserEstructuraInfo(estructuraUser, userRol);

  const [distribucion, setDistribucion] = useState("");
  const [distribuciones, setDistribuciones] = useState(null);

  const [corporativo, setCorporativo] = useState("");
  const [corporativos, setCorporativos] = useState(null);

  const [plantel, setPlantel] = useState("");
  const [planteles, setPlanteles] = useState(null);

  const [rol, setRol] = useState("");
  const [roles, setRoles] = useState(null);

  const [nivel, setNivel] = useState("");
  const [niveles, setNiveles] = useState(null);

  const [grado, setGrado] = useState("");
  const [grados, setGrados] = useState(null);

  const [grupo, setGrupo] = useState("");
  const [grupos, setGrupos] = useState(null);

  const [loadingFilter, setLoadingFilter] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(true);

  const structureRolesValidation =
    userRol === ROLES.directorEditorial ||
    userRol === ROLES.distribuidor ||
    userRol === ROLES.corporativo;
  const schoolRolesValidation =
    userRol === ROLES.plantel ||
    userRol === ROLES.operativo ||
    userRol === ROLES.profesor;

  useEffect(() => {
    getEstructuras(tipoEstructuraRequest, estructuraId);
    setRol("");
    switch (userRol) {
      case ROLES.directorEditorial: {
        setRoles(DIR_EDITORIAL_SELECTED_ROL_OPTIONS);
        break;
      }
      case ROLES.distribuidor: {
        setRoles(DISTRIBUIDOR_ROL_OPTIONS);
        break;
      }
      case ROLES.corporativo: {
        setRoles([]);
        break;
      }
      case ROLES.plantel: {
        setRoles(DIR_PLANTEL_ROL_OPTIONS);
        break;
      }
      case ROLES.operativo: {
        setRoles(ADMIN_OPERATIVO_ROL_OPTIONS);
        break;
      }
      case ROLES.profesor: {
        setRoles(PROFESOR_ROL_OPTIONS);
        break;
      }
      default: {
        break;
      }
    }
    if (userRol !== ROLES.corporativo)
      getUsuarios(tipoEstructuraRequest, estructuraId);
  }, [userRol]);

  useEffect(() => {
    setCorporativos(null);
    if (distribucion !== "Todos" && distribucion !== "")
      getEstructuras(siguienteEstructura, distribucion);
    distribucion !== ""
      ? setRoles(
          userRol === ROLES.distribuidor
            ? DISTRIBUIDOR_ROL_OPTIONS
            : GENERAL_DISTRIBUIDOR_ROL_OPTIONS
        )
      : setRoles(DIR_EDITORIAL_SELECTED_ROL_OPTIONS);
    setPlantel("");
    setPlanteles(null);
    setCorporativo("");
    setRol("");
  }, [distribucion]);

  useEffect(() => {
    if (corporativo !== "Todos" && corporativo !== "")
      getEstructuras(
        siguienteEstructura === "corporativo"
          ? siguienteEstructura
          : "corporativo",
        corporativo
      );
    setPlantel("");
    setPlanteles(null);
    setRol("");
    if (distribucion !== "") {
      corporativo !== ""
        ? setRoles(CORPORATIVO_ROL_OPTIONS)
        : userRol === ROLES.corporativo
        ? setRoles([])
        : setRoles(
            userRol === ROLES.distribuidor
              ? DISTRIBUIDOR_ROL_OPTIONS
              : GENERAL_DISTRIBUIDOR_ROL_OPTIONS
          );
    } else {
      if (userRol === ROLES.distribuidor) setRoles(DISTRIBUIDOR_ROL_OPTIONS);
    }
  }, [corporativo]);

  useEffect(() => {
    if (userRol === ROLES.plantel) {
      if (!roles) setRoles(DIR_PLANTEL_ROL_OPTIONS);
    } else if (userRol === ROLES.operativo) {
      if (!roles) setRoles(ADMIN_OPERATIVO_ROL_OPTIONS);
    } else if (userRol === ROLES.profesor) {
      if (!roles) setRoles(PROFESOR_ROL_OPTIONS);
    }
    if (schoolRolesValidation) {
      if (rol === ROLES.alumno) {
        getNivelesFilter();
        if (nivel !== "") {
          getGradosFilter();
        }
        setGrado("");
        setGrupos(null);
        setGrupo("");
      }
    } else {
      if (plantel !== "") {
        setRoles(PLANTEL_ROL_OPTIONS);
      } else {
        if (corporativo !== "") {
          setRol("");
          setRoles(CORPORATIVO_ROL_OPTIONS);
        }
      }
    }
  }, [nivel, plantel, rol, userRol]);

  useEffect(() => {
    if (rol !== "") {
      setNivel("");
      setGrado("");
      setGrados(null);
      setGrupo("");
      setGrupos(null);
    }
  }, [rol]);

  useEffect(() => {
    if (
      userRol === ROLES.profesor ||
      (schoolRolesValidation && rol === ROLES.profesor) ||
      (rol === ROLES.alumno && grado !== "")
    ) {
      setGrupo("");
      getGruposFilter();
    }
  }, [grado, rol]);

  const getEstructuras = async (estructuraReq, _id) => {
    try {
      setLoadingFilter(true);
      if (userRol !== "") {
        let registros = await getEstructurasRelacionadas(estructuraReq, _id);
        if (estructuraReq === "editorial") {
          if (registros.length > 0)
            setDistribuciones(TODOS_OPTION.concat(registros));
        } else if (estructuraReq === "distribucion") {
          if (registros.length > 0)
            setCorporativos(TODOS_OPTION.concat(registros));
        } else {
          if (registros.length > 0)
            setPlanteles(TODOS_OPTION.concat(registros));
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoadingFilter(false);
    }
  };

  const getGruposFilter = async () => {
    try {
      if (userRol !== "") {
        setLoadingGroups(true);
        let gruposResponse;
        userRol === ROLES.profesor
          ? (gruposResponse = estructuraUser.escuela.campus.grupos.filter(
              (group) =>
                group.gradosAcademicos.some((gradoId) => gradoId === grado)
            ))
          : rol === ROLES.profesor
          ? (gruposResponse = await getGrupos(
              estructuraUser.escuela._id,
              estructuraId
            ))
          : (gruposResponse = await getGruposFiltered(nivel, grado));
        setGrupos(gruposResponse);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoadingGroups(false);
    }
  };

  const getNivelesFilter = async () => {
    try {
      if (userRol !== "") {
        setLoadingFilter(true);
        let nivelesResponse = await getPlantelNiveles(estructuraId);
        if (userRol === ROLES.profesor) {
          const nivelesProfesor = estructuraUser.escuela.campus.grupos.map(
            (grupo) => grupo.nivelAcademico
          );

          const newNivelesRes = nivelesResponse.filter((nivel) =>
            nivelesProfesor.some((newNivel) => nivel._id === newNivel)
          );
          setNiveles(newNivelesRes);
        } else {
          setNiveles(nivelesResponse);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoadingFilter(false);
    }
  };

  const getGradosFilter = async () => {
    try {
      setLoadingFilter(true);
      if (userRol !== "") {
        let gradosResponse = await getGradosPorNivel(nivel);
        if (userRol === ROLES.profesor) {
          const gradosProfesor = estructuraUser.escuela.campus.grupos
            .map((grupo) => grupo.gradosAcademicos)
            .flat(1);

          const newGradosRes = gradosResponse.filter((grado) =>
            gradosProfesor.some((newGrado) => grado._id === newGrado)
          );
          setGrados(newGradosRes);
        } else {
          setGrados(gradosResponse);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    let newDestinatariosFiltro = destinatarios;
    newDestinatariosFiltro = filterDataBusqueda(
      newDestinatariosFiltro,
      filtro.busqueda.toLowerCase()
    );
    newDestinatariosFiltro = filterSelectRol(
      newDestinatariosFiltro,
      filtro.rol
    );
    newDestinatariosFiltro = filterSelectNivel(
      newDestinatariosFiltro,
      filtro.nivel
    );
    newDestinatariosFiltro = filterSelectGrado(
      newDestinatariosFiltro,
      filtro.grado
    );
    newDestinatariosFiltro = filterSelectGrupo(
      newDestinatariosFiltro,
      filtro.grupo
    );
    setDestinatariosFiltro(newDestinatariosFiltro);
  }, [filtro, destinatarios]);

  const filterDataBusqueda = (destinatarios, busqueda) => {
    if (busqueda === "") return destinatarios;

    const newDestinatariosFiltro = destinatarios.filter(
      (destinatario) =>
        destinatario?.usuario?.nombres?.toLowerCase().includes(busqueda) ||
        destinatario?.usuario?.primerApellido
          ?.toLowerCase()
          .includes(busqueda) ||
        destinatario?.usuario?.segundoApellido
          ?.toLowerCase()
          .includes(busqueda) ||
        destinatario?.usuario?.correoElectronico
          ?.toLowerCase()
          .includes(busqueda)
    );
    return newDestinatariosFiltro;
  };

  const filterSelectRol = (destinatarios, rol) => {
    if (rol === "") return destinatarios;
    const newDestinatariosFiltro = destinatarios.filter((destinatario) =>
      destinatario.usuario.roles.includes(rol)
    );

    return newDestinatariosFiltro;
  };

  const filterSelectNivel = (destinatarios, nivel) => {
    if (nivel === "") return destinatarios;
    const newDestinatariosFiltro = destinatarios.filter(
      (destinatario) => destinatario.usuario.nivelAcademico._id === nivel
    );

    return newDestinatariosFiltro;
  };

  const filterSelectGrado = (destinatarios, grado) => {
    if (grado === "") return destinatarios;
    const newDestinatariosFiltro = destinatarios.filter(
      (destinatario) => destinatario.usuario.gradoAcademico._id === grado
    );

    return newDestinatariosFiltro;
  };

  const filterSelectGrupo = (destinatarios, grupo) => {
    if (grupo === "") return destinatarios;
    const newDestinatariosFiltro = destinatarios.filter((destinatario) => {
      if (destinatario.usuario?.grupos) {
        return destinatario.usuario.grupos.some(
          (grupoObj) => grupoObj?._id === grupo
        );
      }
    });

    return newDestinatariosFiltro;
  };

  const onClickSelectFilters = (event) => {
    event.preventDefault();
    if (siguienteEstructura === "distribucion") {
      if (distribucion !== "") {
        if (corporativo === "") {
          distribucion === "Todos"
            ? getAllUsuarios(tipoEstructuraRequest, estructuraId)
            : getUsuarios(siguienteEstructura, distribucion);
        } else {
          plantel === ""
            ? corporativo === "Todos"
              ? getAllUsuarios(siguienteEstructura, distribucion)
              : getUsuarios("corporativo", corporativo)
            : plantel === "Todos"
            ? getAllUsuarios("corporativo", corporativo)
            : getUsuarios("plantel", plantel);
        }
      } else {
        getUsuarios(tipoEstructuraRequest, estructuraId);
      }
    } else if (siguienteEstructura === "corporativo") {
      if (corporativo !== "") {
        plantel === ""
          ? corporativo === "Todos"
            ? getAllUsuarios(tipoEstructuraRequest, estructuraId)
            : getUsuarios(siguienteEstructura, corporativo)
          : plantel === "Todos"
          ? getAllUsuarios(siguienteEstructura, corporativo)
          : getUsuarios("plantel", plantel);
      } else {
        getUsuarios(tipoEstructuraRequest, estructuraId);
      }
    } else if (siguienteEstructura === "plantel") {
      plantel !== ""
        ? plantel === "Todos"
          ? getAllUsuarios(tipoEstructuraRequest, estructuraId)
          : getUsuarios(siguienteEstructura, plantel)
        : getUsuarios(tipoEstructuraRequest, estructuraId);
    } else if (tipoEstructuraRequest === "plantel") {
      getUsuarios(tipoEstructuraRequest, estructuraId);
    }
    setFiltro({
      ...filtro,
      rol: rol,
      nivel: nivel,
      grado: grado,
      grupo: grupo,
    });
  };

  const getUsuarios = async (estructura, _id) => {
    try {
      setIsLoading(true);
      if (userRol !== "") {
        let usersResponse = await getUsuariosPorEstructura(estructura, _id);
        setUsuariosList(usersResponse);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllUsuarios = async (estructura, _id) => {
    try {
      setIsLoading(true);
      if (userRol !== "") {
        let usersResponse = await getAllUsuariosPorEstructura(estructura, _id);
        setUsuariosList(usersResponse);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row flex-wrap gap-4 !justify-center">
        {userRol === ROLES.directorEditorial && (
          <InputSelect
            id={"casa_editorial"}
            name={"casa_editorial"}
            label={"Casa editorial"}
            styles="!w-full bg-[#EEEEEE] md:!w-[180px]"
            disabled
            customLabel={estructuraUser?.editorial?.nombre ?? ""}
          />
        )}
        {(userRol === ROLES.directorEditorial ||
          userRol === ROLES.distribuidor) && (
          <InputSelect
            id={"distribucion"}
            name={"distribucion"}
            label={"Distribución"}
            value={distribucion}
            options={distribuciones ?? []}
            onChange={(e) => setDistribucion(e.target.value)}
            styles={`!w-full md:!w-[180px] ${
              estructuraUser?.distribucion && "bg-[#EEEEEE]"
            }`}
            disabled={estructuraUser?.distribucion}
            customLabel={
              !estructuraUser?.distribucion
                ? "Seleccione la distribución"
                : estructuraUser?.distribucion?.nombre
            }
          />
        )}

        {(userRol === ROLES.directorEditorial ||
          userRol === ROLES.distribuidor) && (
          <InputSelect
            id={"corporativo"}
            name={"corporativo"}
            label={"Corporativo"}
            value={corporativo}
            options={corporativos ?? []}
            onChange={(e) => setCorporativo(e.target.value)}
            styles={`!w-full md:!w-[180px] ${
              (((distribucion === "" || distribucion === "Todos") &&
                !estructuraUser?.distribucion) ||
                loadingFilter) &&
              "bg-[#EEEEEE]"
            }`}
            customLabel="Seleccione el corporativo"
            disabled={
              ((distribucion === "" || distribucion === "Todos") &&
                !estructuraUser?.distribucion) ||
              loadingFilter
            }
          />
        )}

        {structureRolesValidation && (
          <InputSelect
            id={"plantel"}
            name={"plantel"}
            label={"Plantel"}
            value={plantel}
            options={planteles ?? []}
            onChange={(e) => setPlantel(e.target.value)}
            styles={`!w-full md:!w-[180px] ${
              (((corporativo === "" || corporativo === "Todos") &&
                !estructuraUser?.escuela) ||
                loadingFilter) &&
              "bg-[#EEEEEE]"
            }`}
            customLabel="Seleccione el plantel"
            disabled={
              ((corporativo === "" || corporativo === "Todos") &&
                !estructuraUser?.escuela) ||
              loadingFilter
            }
          />
        )}

        {(structureRolesValidation || schoolRolesValidation) && (
          <InputSelect
            id={"rol"}
            name={"rol"}
            label={"Rol de usuario"}
            value={rol}
            options={roles ?? []}
            onChange={(e) => setRol(e.target.value)}
            styles="!w-full md:!w-[144px]"
            customLabel="Rol"
          />
        )}

        {schoolRolesValidation && rol === ROLES.alumno && (
          <InputSelect
            id={"nivel_academico"}
            name={"nivel_academico"}
            label={"Nivel Académico"}
            value={nivel}
            options={niveles ?? []}
            onChange={(e) => setNivel(e.target.value)}
            styles={`!w-full md:!w-[180px] ${loadingFilter && "bg-[#EEEEEE]"}`}
            customLabel="Nivel Académico"
            disabled={loadingFilter}
          />
        )}

        {schoolRolesValidation && rol === ROLES.alumno && (
          <InputSelect
            id={"grado_academico"}
            name={"grado_academico"}
            label={"Grado Académico"}
            value={grado}
            options={grados ?? []}
            onChange={(e) => setGrado(e.target.value)}
            styles={`!w-full md:!w-[180px] ${
              (loadingFilter || nivel === "") && "bg-[#EEEEEE]"
            }`}
            customLabel="Grado Académico"
            disabled={loadingFilter || nivel === ""}
          />
        )}

        {schoolRolesValidation &&
          (rol === ROLES.alumno || rol === ROLES.profesor) && (
            <InputSelect
              id={"grupo"}
              name={"grupo"}
              label={"Grupo"}
              value={grupo}
              options={grupos ?? []}
              onChange={(e) => setGrupo(e.target.value)}
              styles={`!w-full md:!w-[180px] ${
                ((rol === ROLES.alumno && grado === "") || loadingGroups) &&
                "bg-[#EEEEEE]"
              }`}
              customLabel="Grupo"
              disabled={(rol === ROLES.alumno && grado === "") || loadingGroups}
            />
          )}

        <div className="flex items-end justify-center">
          <SubmitButton
            text={"Filtrar"}
            onClick={onClickSelectFilters}
            styles="min-h-[40px] min-w-[76px]"
          />
        </div>
      </div>
    </>
  );
};

export default AddUsersFilters;
