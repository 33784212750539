import React, {useState, useEffect} from 'react'
import AvanceBronce from '../../../../../assets/img/avance_bronce.png'
import AvancePlata from '../../../../../assets/img/avance_plata.png'
import AvanceOro from '../../../../../assets/img/avance_oro.png'
import Perseverancia from '../../../../../assets/img/perseverancia.png'
import Precision from '../../../../../assets/img/precision.png'
import { Trans } from 'react-i18next'

function PantallaFin({time,calificacion, calificado, intentarDeNuevo, esUMA, isDone, modoPrevisualizar, additionalActData, esEvaluacion, lang}) {
  const [visible, setVisible] = useState(false)

  useEffect(()=>{
    setTimeout(()=>{
      setVisible(true)
    },1000)
  },[])

  return (
      <div className={`min-h-[400px] md:min-h-[700px] w-full h-full bg-[#00000087] z-50 flex items-center justify-center transition duration-500 ease-in-out opacity-0 ${visible ? '!opacity-100' : ''}`}>
          <div className="w-[90%] mx-auto max-w-[30rem] bg-white p-4 rounded-lg text-center my-[7rem]">
              <div className="mb-4 text-nimbus text-xl font-bold">
                <Trans i18nKey='activityLabel'></Trans>
              </div>
              {esEvaluacion ? null : 
              <>
                {!modoPrevisualizar ? <p className='text-sm mb-3'><Trans i18nKey='score'></Trans> {isDone ? (Math.round(isDone.puntuacionUltimoIntento * 100) / 100).toFixed(2) : 0}</p> : ''}              
                {!modoPrevisualizar ? <p className='text-sm mb-3'><Trans i18nKey='highestScore'></Trans> {isDone ? isDone.puntuacionTop : 0}</p> : ''}              
                {!modoPrevisualizar ? <p className='text-sm mb-3'><Trans i18nKey='attemptsNumber'></Trans> {isDone ? isDone.intento : 0}</p> : ''}
              <p className='text-sm mb-3'>
                <span className='inline-block mr-2'>
                  <Trans i18nKey='timeLabel'></Trans>
                </span>
                <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
                <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>
                {additionalActData && additionalActData.map((el)=>{
                  return(
                    <div className='mt-1'>
                      <Trans>{el.type}</Trans>: {el.value} <Trans>{el.label}</Trans>
                    </div>
                    )
                  })}
              </p>
              </>
              }
              {/* <div className="flex justify-around mb-4">
                <div className="flex flex-col items-center justify-center">
                  <span className="text-sm md:text-lg">Puntaje</span>
                  <span className="text-sm md:text-lg max-w-[45px] overflow-hidden whitespace-nowrap">{calificacion}</span>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-sm md:text-lg">Tiempo</span>
                  <div className="numbers text-sm md:text-lg">
                    <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                    <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
                    <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-sm md:text-lg">Intentos</span>
                  <span className="text-sm md:text-lg ">{calificado ? calificado.calificacion.intento : ''}</span>
                </div>
              </div> */}
              {esUMA && <div className='grid gap-y-4'>
                <div className='flex place-self-center'><h1 className='text-nimbus text-xl'><Trans i18nKey="insignsLabel"></Trans> </h1></div>
                <div className='flex gap-x-2 justify-center'>
                  {(calificado && calificado.insigniaAvanceBronce !==0) &&
                    <div className='grid'>
                      <img src={AvanceBronce} alt="Medalla de avance de bronce"/>
                    </div>
                  }
                  {(calificado && calificado.insigniaAvancePlata !==0) && 
                    <div className='grid'>
                      <img src={AvancePlata} alt="Medalla de avance de plata"/>
                    </div>
                  }
                  {
                    (calificado && calificado.insigniaAvanceOro !==0)&& 
                    <div className='grid'>
                      <img src={AvanceOro} alt="Medalla de avance de oro"/>
                    </div>
                  }
                  {(calificado && calificado.insigniaPerseverancia !==0) && 
                    <div className='grid'>
                      <img src={Perseverancia} alt="Medalla de perserverancia"/>
                    </div>
                  }
                  {
                    (calificado && calificado.insigniaPrecision !==0) && 
                    <div className='grid'>
                      <img src={Precision} alt="Medalla de precision"/>
                    </div>
                  }
                </div>
              </div>}
              <div className="flex">
                {/* <button className="border bg-nimbus text-white m-4 py-2 px-6  rounded-full flex items-center justify-center mx-aut">Ver mis respuestas</button> */}
                <button className="border bg-nimbus text-white m-4 py-2 px-6  rounded-full flex items-center justify-center w-full" onClick={intentarDeNuevo}>
                  <Trans i18nKey='tryAgainLabel'></Trans>
                </button>
              </div>
          </div>
        </div>
  )
}

export default PantallaFin