import { useContext, useEffect } from "react";
import {useState} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { ActividadContext } from "../../../Actividades/ActividadesUtils/ActWrapper";
import SimplePlayer from "../../../Actividades/ActividadesUtils/SimplePlayer";
import Lightbox from "../../../Actividades/ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "../../../Actividades/ActividadesUtils/Lightbox/LightboxWrapper";
import {Trans} from 'react-i18next'
import ImagenValidada from "../../../Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";
import VideoValidado from "../../../Actividades/ActividadesUtils/RecursosValidados/VideoValidado";
import { shuffle } from "../../../Actividades/ActividadesUtils/ActividadesUtils";


export default function ActividadPreguntas({preguntas, evaluable, esMultiple, guardarCalificacion, umaBloque, preguntasVisibles, instrucciones, tipoOrden = 'ORDENAR'}) {
    const activityContext = useContext(ActividadContext)
    const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState([])
    const [lightboxImage, setLightboxImage] = useState('')
    const [preguntasActuales, setPreguntasActuales] = useState([])
    const respuestasCorrectas = preguntas.map((item)=>{
        return item.respuestas.filter((el)=>{
            return el.esCorrecta === true
        })
    }).flat()

    const ordenarPreguntas  = (preguntasArr) =>{
        if(preguntas.length === 0) return []
        
        const listadoPreguntas = [...preguntasArr]
        if(tipoOrden === "AZAR"){
            return shuffle(listadoPreguntas)
        }else if (tipoOrden === "ORDENAR"){
            return listadoPreguntas.sort((a,b)=>a.orden > b.orden ? 1 : -1)
        }else{
            return listadoPreguntas
        }
    }

    const generarPreguntas = () =>{
        const listado = preguntas.map((item)=>{
            const newRespuestas =  item.respuestas.map((el)=>({...el, parentId:item._id}))
            return {...item, respuestas:newRespuestas}
        })

        if(preguntasVisibles === null || preguntasVisibles === undefined){
            return ordenarPreguntas(listado)
        }else{
            return ordenarPreguntas(listado.slice(0, preguntasVisibles)    )
        }
    }

    useEffect(()=>{
        setPreguntasActuales(generarPreguntas)
    },[])


    const handleSelect = (respuesta) =>{
        const respuestasActuales = respuestasSeleccionadas.map((el)=>(el._id))
        if(respuestasActuales.includes(respuesta._id)){
            const newRespuestas = respuestasSeleccionadas.filter((el)=>(el._id !== respuesta._id))
            setRespuestasSeleccionadas(newRespuestas)
        }else if (esMultiple){
            setRespuestasSeleccionadas([...respuestasSeleccionadas,respuesta])
        }else if(!esMultiple){
            const newList = respuestasSeleccionadas.filter((item)=>{
                return item.parentId !== respuesta.parentId
            })
            setRespuestasSeleccionadas([...newList, respuesta])
        }
    }   

    //removes duplicates in arrays
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const generarResumen = () =>{
        return JSON.stringify(respuestasSeleccionadas)
    }

    const handleCalificar = () =>{
        const resumenCorrectas = respuestasCorrectas.map((item)=>(item._id))
        const resumenSeleccionadas = respuestasSeleccionadas.map((item)=>(item._id))
        const comparacionRespuestas = resumenCorrectas.map((item)=>{
            return resumenSeleccionadas.includes(item)
        })
        const seleccionadasIncorrectas = respuestasSeleccionadas.filter((el)=>(!el.esCorrecta))

        const removedIncorrectasRepeated = seleccionadasIncorrectas.filter(onlyUnique).map((el)=>(el.parentId));
        const seleccionadasCorrectas = respuestasSeleccionadas.filter((el)=>(!removedIncorrectasRepeated.includes(el.parentId )))
        
        if(esMultiple){
            const calificacionPreguntasMultiples = seleccionadasCorrectas.length / respuestasCorrectas.length * 100 
            if(respuestasSeleccionadas.length > 0){
                activityContext.postCalificacionActividad(calificacionPreguntasMultiples)
                //save evaluation activity
                if(umaBloque){
                    const evaluationScore =  preguntasActuales.map((el) => {
                        const correctAnswersArr = el.respuestas
                            .filter((el) => el.esCorrecta)
                            .map((el) => el._id)
                        const selectedAnswersArr = respuestasSeleccionadas
                            .filter((ans) => ans.parentId == el._id)
                            .map((el) => el._id)
    
                        return (
                            correctAnswersArr.sort().join(',') ===
                            selectedAnswersArr.sort().join(',')
                        )
                    })
                    guardarCalificacion(
                        umaBloque,
                        evaluationScore.filter((el)=>(el)).length,
                        generarResumen()
                    )
                }
            }
            else if(respuestasSeleccionadas.length === 0)activityContext.postCalificacionActividad(null)
        }else{
            const calificacion = (comparacionRespuestas.filter(Boolean).length /resumenCorrectas.length) *100
            if(respuestasSeleccionadas.length > 0){
                activityContext.postCalificacionActividad(calificacion)
                if(umaBloque)guardarCalificacion(umaBloque, seleccionadasCorrectas.length, generarResumen())
            }
            else if(respuestasSeleccionadas.length === 0)activityContext.postCalificacionActividad(null)
        }
    }

    return(
        <>
            {instrucciones && <div className="max-w-activity act-instructions mb-6">{instrucciones}</div>}
            <Lightbox lightboxValue={lightboxImage} closeLightbox={()=>setLightboxImage('')}/>
            {preguntas.length !== 0 ? 
            <div className={`w-full bg-white max-w-activity my-10 border rounded-2xl overflow-hidden shadow-lg ${evaluable ? '' : 'pointer-events-none'}`}>
                { preguntasActuales.map(pregunta=>{
                    return (
                        <div key={pregunta._id} className="flex flex-wrap md:p-4 bg-white">
                            <div className="bg-nimbus w-full h-2 rounded-full"></div>
                            <div className="w-full mb-3"><h1 className="py-10 pb-10 text-left text-nimbus">{pregunta.texto}</h1></div>
                            <div className="w-full flex flex-col lg:flex-row items-center justify-center border p-6 mb-7 ">
                                <div className="w-full lg:w-auto">

                                    {pregunta.imagen ? 
                                        <LightboxWrapper handleClick={()=>setLightboxImage(pregunta.imagen)}>
                                            <ImagenValidada src={pregunta.imagen} className="mx-auto w-full h-full lg:max-h-[265px] lg:max-w-[25rem] rounded-lg object-fill shadow-lg"/> 
                                        </LightboxWrapper>
                                    : ''}
                                    {pregunta.audio ? <SimplePlayer size={'text-6xl'} audio={pregunta.audio}/> : ''}
                                    {pregunta.video ? 
                                        <div className="video_bg">
                                            <VideoValidado
                                                src={pregunta.video} 
                                                className="max-w-[500px] pointer-events-auto mx-auto rounded-lg md:min-w-[23rem] overflow-hidden w-full"
                                            />
                                        </div> : ''
                                    }
                                </div>
                                <div className="w-full grid grid-cols-1 lg:grid-cols-2 justify-around gap-[1rem] py-4 lg:px-4 flex-1">
                                    {pregunta.respuestas.map(respuesta=>(
                                        <>
                                        {respuesta.texto ? (
                                            <div 
                                                key={respuesta._id} 
                                                className={`w-full min-h-[5.5rem] transition flex align-content-center items-center justify-between border-[5px] rounded-xl place-self-center hover:bg-gray-100 bg-white p-3 relative hover:-translate-y-2 ${respuestasSeleccionadas.map((el)=>(el._id)).includes(respuesta._id) ? 'border-[#162e5b]' : 'border-[#f5f5f6]'}`}
                                                onClick={()=>handleSelect(respuesta)}
                                                >
                                                {respuesta.audio ? <SimplePlayer size={'text-4xl'} audio={respuesta.audio}/> : ''}
                                                {respuesta.imagen ?
                                                    <LightboxWrapper handleClick={()=>setLightboxImage(respuesta.imagen)}>
                                                        <ImagenValidada
                                                            src={respuesta.imagen}
                                                            className=" aspect-square w-full max-w-[5.5rem] rounded-full object-fill shadow-lg mr-3"
                                                        />
                                                    </LightboxWrapper>
                                                :""}
                                                    <p className="font-sans pointer-events-none  bg-transparent text-nimbus flex-1 text-center">{respuesta.texto}</p>
                                                {evaluable ? '' :
                                                <>
                                                 <FontAwesomeIcon icon={faCircleCheck} color={"#4caf50"} className={`text-3xl ml-2 ${respuesta.esCorrecta ? '' : 'hidden'}`}></FontAwesomeIcon>
                                                 <FontAwesomeIcon icon={faCircleXmark} color={"#df4759"} className={`text-3xl ml-2 ${respuesta.esCorrecta ? 'hidden' : ''}`}></FontAwesomeIcon>
                                                </>
                                                }
                                            </div>
                                        )
                                        :''}
                                        </>
                                    ))}
                                </div>
                            </div> 
                        </div>
                    )
                })}
                {evaluable
                ?
                <button
                    className="border bg-nimbus text-white m-4 py-2 px-10 text-lg rounded-full flex items-center justify-center mx-auto"
                    onClick={()=>handleCalificar()}
                >
                    <Trans>
                        {respuestasSeleccionadas.length > 0 ? 'Calificar' : 'Cerrar'}
                    </Trans>
                </button>      
                :''
                }
            </div>
        :''}
        </>
    )
}

ActividadPreguntas.defaultProps = {
    evaluable:true,
    preguntas:[],
    esMultiple:true,
}