import React from 'react';
import ListasDifusionRow from './ListasDifusionRow';

const ListasDifusionTable = ({ listas, getListasDifusion }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th/>
            <th className="text-center">Nombre de la lista de difusión</th>
            <th className="text-center">Cantidad de usuarios</th>
            <th className="text-center">Acciones</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {listas.map((lista_difusion) => (
            <ListasDifusionRow key={lista_difusion._id} lista_difusion={lista_difusion} getListasDifusion={getListasDifusion} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListasDifusionTable;