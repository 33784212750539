import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import Correo from "../../assets/icons/correo.svg";
import ArrowLeft from "../../assets/icons/arrowLeftLarge.svg";
import InputTextIcon from "../fragments/Inputs/InputTextIcon";
import useValidateForm from "../hooks/useValidateForm";
import { solicitarPassword, verifyEmail } from "../services/api";
import { useState } from "react";
import { useEffect } from "react";

const ForgotPassword = () => {
  const { handleChange, errors, values, validateEmpty } = useValidateForm({
    correoElectronico: "",
  });
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false)

  const recoverPassword = async (event) => {
    event.preventDefault();
    let data = {
      correoElectronico: values.correoElectronico,
    };
    let newErrors = await validateEmpty([]);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(newErrors).length === 0
    ) {
      let res = await solicitarPassword(data)
      if(res.error?.status === 404){
        setErrorMessage(true)
      }
      else if (res.error?.status === 500){
        navigate("/permission/denied/password");
      }else{
        navigate("/verify/email");
      }
    }
  };
  
  useEffect(() => {
    setErrorMessage(false)
  }, [values])
  

  return (
    <div className="w-full min-h-screen md:h-auto md:min-h-[100vh] bg-[#fcfcfc] flex flex-col justify-center items-center">
      <a
        className="absolute top-12 left-11 md:top-20 md:left-28 cursor-pointer flex text-nimbusDark font-bold"
        href="/"
      >
        <img className="mr-3" src={ArrowLeft} alt="regresar"/>
        Regresar
      </a>
      <div className="w-[22rem] flex flex-col justify-center px-8 md:px-0">
        <div className="flex flex-col items-center mx-auto">
          <img className="h-auto w-18" src={Logo} alt="logo.png" />
          <h1 className="text-nimbusDark font-bold text-2xl md:text-3xl mt-8 text-center">
            Restablecer contraseña
          </h1>
          <p className="text-[#3A404C] text-center mt-5 text-sm md:text-base">
            Escribe tu correo electrónico y te enviarémos un correo con las
            instrucciones para crear una nueva contraseña.
          </p>
        </div>
        <InputTextIcon
          className="mt-9 md:mt-11 flex flex-col mb-8 md:mb-11"
          id="correoElectronico"
          label="Correo electrónico"
          type="email"
          placeholder="tucorreo@dominio.com"
          name="correoElectronico"
          value={values.correoElectronico}
          onChange={(e) => handleChange(e, "email")}
          icon={Correo}
          error={errors["correoElectronico"]}
        />
        {errorMessage && <p className="font-thin text-xs text-red-400 -translate-y-8 md:-translate-y-11">No existe una cuenta asociada con este correo.</p>}
        <button
          className="bg-nimbusDark font-semibold text-white w-full rounded-lg py-2"
          onClick={recoverPassword}
        >
          Enviar correo
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
