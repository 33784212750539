import React from "react";
import { Route, Routes } from "react-router-dom";
import GestionProductos from "../../../components/comercial/GestionProductos/GestionProductos";
import Productos from "../../../components/comercial/GestionProductos/Productos/Productos";
import ProductoForm from "../../../components/comercial/GestionProductos/Productos/ProductoForm";
import NotFound from "../../../components/fragments/NotFound";
import PaquetesProductos from "../../../components/comercial/GestionProductos/Paquetes/PaquetesProductos";
import PaqueteProductosForm from "../../../components/comercial/GestionProductos/Paquetes/Paquete/PaqueteProductosForm";
import { PaqueteProductosProvider } from "../../../contexts/PaqueteProductosContext";
import VariacionesProductoForm from "../../../components/comercial/GestionProductos/Productos/VariacionesProductoForm";
import VariacionesProducto from "../../../components/comercial/GestionProductos/Productos/fragments/VariacionesProducto";

const GestionProductosRoutes = () => {
  return (
    <PaqueteProductosProvider>
      <Routes>
        <Route index path="/" element={<GestionProductos />} />
        <Route path="productos" element={<Productos />} />
        <Route path="productos/agregar" element={<ProductoForm />} />
        <Route path="productos/:productoId/editar" element={<ProductoForm editar />} />
        <Route path="productos/:productoId/ver" element={<ProductoForm ver />} />
        <Route path="productos/:productoId/variaciones" element={<VariacionesProducto />} />
        <Route path="paquetes" element={<PaquetesProductos />} />
        <Route path="paquetes/agregar" element={<PaqueteProductosForm />} />
        <Route path="paquetes/:paqueteId/editar" element={<PaqueteProductosForm editar />} />
        <Route path="paquetes/:paqueteId/ver" element={<PaqueteProductosForm ver />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </PaqueteProductosProvider>
  );
};

export default GestionProductosRoutes;
